<template>
  <div class="container pt-0 px-0">
    <v-app class="space-top">
      <!-- <h3>Setup your company account</h3>
        <p class="lead text-center">Select preferred features</p> -->
      <!-- <v-form @submit.prevent="auth_request(formData)"> -->
      <v-form @submit.prevent="auth_request()">
        <v-container
          :class="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? 'pt-5'
              : null
          "
        >
          <v-row>
            <v-col cols="12">
              <h3 class="text-left">Your Services</h3>
            </v-col>
            <!-- <v-col cols="12" md="10" lg="10"> -->
            <v-col cols="12" md="12">
              <!-- <v-row v-if="!authSubscriptionExpired">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-switch
                    v-model="trialSelected"
                    @click="authSubscriptionExpired ? null : unselectServices()"
                    inset
                    label="Enable Trial Version"
                    color="#069a6"
                  ></v-switch>
                </v-col>
              </v-row> -->

              <v-row>
                <v-col cols="12" md="12" lg="10">
                  <h3
                    class="mb-8 mt-3"
                    :class="
                      $vuetify.breakpoint.name == 'xs' ||
                      $vuetify.breakpoint.name == 'sm'
                        ? 'text-center'
                        : 'text-left'
                    "
                  >
                    Human Resources
                  </h3>
                  <div
                    v-if="loading"
                    class="text-center"
                    style="height: 20px; width: 100%; background-color: #fff"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#069"
                    ></v-progress-circular>
                  </div>
                  <v-row v-else>
                    <v-col
                      class="py-5"
                      lg="4"
                      md="6"
                      cols="12"
                      v-for="service in freeHumanResourcesServices"
                      :key="service.service_id"
                    >
                      <v-row>
                        <v-col md="2" lg="2" cols="1" class="pt-5 my-auto">
                          <v-checkbox
                            v-model="service.checked"
                            color="#069"
                            :disabled="
                              service.service_name.toLowerCase() ==
                                'onboarding' /*|| service.service_name.toLowerCase() ==
                                'recruitment'*/ || trialSelected
                                ? true
                                : false
                            "
                            @click="
                              service.service_name.toLowerCase() ==
                                'onboarding' ||
                              service.service_name.toLowerCase() ==
                                'recruitment_' ||
                              (trialSelected &&
                                service.service_name.toLowerCase() ==
                                  'attendance_') ||
                              (trialSelected &&
                                service.service_name.toLowerCase() ==
                                  'absence or leave_') ||
                              (trialSelected &&
                                service.service_name.toLowerCase() ==
                                  'travel management_')
                                ? ''
                                : addService(service)
                            "
                          ></v-checkbox>
                        </v-col>

                        <v-col
                          md="10"
                          lg="10"
                          offset-lg="0"
                          offset-md="0"
                          cols="9"
                          offset="1"
                          :style="
                            service.checked /* || trialSelected */
                              ? 'opacity: 0.5'
                              : ''
                          "
                        >
                          <!-- <v-card
                                        elevation="2"
                                        outlined
                                        >
                                            <h2>Game</h2>
                                        </v-card> -->
                          <v-card
                            class="feature-card row"
                            :style="service.checked ? '' : ''"
                          >
                            <v-col
                              :class="
                                $vuetify.breakpoint.name == 'xs' ||
                                $vuetify.breakpoint.name == 'sm'
                                  ? 'pb-0'
                                  : null
                              "
                              class="text-center"
                              md="4"
                              lg="4"
                              cols="12"
                            >
                              <!-- <v-icon
                                                    x-large
                                                    color="#111D5E"
                                                    class="mdi-all-services"
                                                    :style="trialSelected && service.service_id % 2 === 0 ? 'opacity: 0.2;' : ''"
                                                    >
                                                    {{ service.icon }}
                                                    </v-icon> -->
                              <div>
                                <i
                                  style="color: #111d5e"
                                  :class="
                                    $vuetify.breakpoint.name == 'xs' ||
                                    $vuetify.breakpoint.name == 'sm'
                                      ? service.icon + ' fa-2x'
                                      : service.icon + ' fa-3x'
                                  "
                                ></i>
                              </div>
                            </v-col>
                            <v-col
                              md="7"
                              lg="7"
                              cols="12"
                              :class="
                                $vuetify.breakpoint.name == 'xs' ||
                                $vuetify.breakpoint.name == 'sm'
                                  ? 'pt-2'
                                  : null
                              "
                              class="pl-0 pr-0 my-auto"
                              style="padding-top: 15px"
                            >
                              <!-- <h3 class="card-h3 text-left">{{ feature.header }}</h3> -->
                              <p
                                :class="
                                  $vuetify.breakpoint.name == 'xs' ||
                                  $vuetify.breakpoint.name == 'sm'
                                    ? 'text-center'
                                    : null
                                "
                                class="card-p mb-0"
                              >
                                {{ service.service_name }}
                              </p>
                            </v-col>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  class="px-0"
                  offset-md="4"
                  md="4"
                  offset-lg="0"
                  lg="2"
                  cols="8"
                  offset="2"
                >
                  <v-row>
                    <v-col
                      class="animate__animated animate__fadeIn"
                      md="12"
                      sm="12"
                      v-if="selectedServices.length >= 1"
                    >
                      <v-card class="right-card pl-3 pr-3 pt-3 pb-3">
                        <h5>
                          <router-link
                            style="text-decoration: underline"
                            to="/request-demo"
                            >Request Demo
                            <i class="fas fa-arrow-up-right-from-square"></i
                          ></router-link>
                        </h5>
                        <h3
                          style="font-size: 16px !important"
                          class="text-left mb-3 mt-3"
                        >
                          Selected Apps: {{ selectedServices.length }}
                        </h3>
                        <v-row
                          v-for="service in selectedServices"
                          :key="service.service_id"
                        >
                          <v-col class="pt-0" md="2" lg="2" cols="2">
                            <div class="selected">
                              <i
                                style="color: #111d5e"
                                :class="getIcon(service.service_name)"
                              ></i>
                            </div>
                          </v-col>
                          <v-col
                            class="pt-0 mt-0 my-auto"
                            md="10"
                            lg="10"
                            cols="10"
                          >
                            <span style="font-size: 11px">{{
                              service.service_name
                            }}</span>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col class="pt-0" md="12">
                            <span>
                              <strong>Total Users:</strong>
                              {{ number_of_employees + 1 }}
                            </span>
                          </v-col>
                          <!-- <v-col v-if="!trialSelected" class="pt-0" md="12">
                            <span
                              ><strong>Subtotal:</strong> ₦{{
                                (amount * (number_of_employees + 1))
                                  | number_with_commas
                              }}</span
                            >
                          </v-col>
                          <v-col v-if="!trialSelected" class="pt-0" md="12">
                            <span
                              ><strong>VAT:</strong> ₦{{
                                VAT_FEE | number_with_commas
                              }}</span
                            >
                          </v-col>
                          <v-col v-if="!trialSelected" class="pt-0" md="12">
                            <span
                              ><strong>Total:</strong> ₦{{
                                (VAT_FEE + amount * (number_of_employees + 1))
                                  | number_with_commas
                              }}</span
                            >
                          </v-col>
                          <v-col v-if="selectedServices.length >= 2" md="12">
                            <div v-if="trialSelected" class="pt-2">
                              <p class="card-p text-center mb-0">You're on</p>
                              <h3
                                style="
                                  color: #111d5e !important;
                                  font-size: 20px !important;
                                "
                              >
                                7 days trial
                              </h3>
                              <v-btn
                                type="submit"
                                x-large
                                dark
                                class="create-submit2"
                                @click="changeStep(2)"
                              >
                                Continue
                              </v-btn>
                            </div>
                            <div v-else class="pt-2">
                              <p class="card-p text-center mb-0">You're about to</p>
                              <h3
                                style="
                                  color: #111d5e !important;
                                  font-size: 20px !important;
                                "
                              >
                                subscribe
                              </h3>
                              <v-btn
                                type="submit"
                                x-large
                                dark
                                class="create-submit2"
                                @click="changeStep(2)"
                              >
                                Continue
                              </v-btn>
                            </div>
                          </v-col> -->
                        </v-row>
                      </v-card>
                    </v-col>

                    <v-col md="12" sm="12" v-else>
                      <component
                        class="animate__animated animate__fadeIn"
                        :is="cardName"
                      ></component>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div>
                <h3 class="text-left">Welcome to KylianERP</h3>
                <p class="lead">
                  This solution engenders organizational efficiency
                  of your organization by managing and improving
                  how your company resources are utilized.
                </p>
                <v-row class="mt-7">
                  <v-col cols="12" md="4" offset-md="2">
                    <router-link
                      :to="{ name: 'RequestDemo' }"
                      class="mx-auto text-center" style="display: block;">
                      <v-btn type="button" x-large dark class="create-submit2">
                        Request Demo
                      </v-btn>
                    </router-link>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div
                      class="mx-auto text-center" style="display: block;">
                      <v-btn @click="addCompanyServices()" type="button" x-large dark class="create-submit2">
                        {{ addServicesBtnTxt }}
                        <v-icon :style="!addServicesBtnLoading ? 'display: none' : ''"
                          >fas fa-circle-notch fa-spin</v-icon
                        >
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'">
            <v-col
              class="pb-6 text-center"
              cols="12"
              sm="12"
              :style="loading ? 'padding-top: 50px' : ''"
            >
              <v-btn
                style="margin: auto;"
                type="submit"
                x-large
                dark
                class="create-submit d-md-none d-lg-flex"
                @click="selectedServices.length >= 1 ? changeStep(2) : ''"
              >
                {{ btnTxt }}
                <v-icon :style="!isLoading ? 'display: none' : ''"
                  >fas fa-circle-notch fa-spin</v-icon
                >
              </v-btn>
            </v-col>
          </v-row> -->
        </v-container>
      </v-form>

      <div class="text-center">
        <v-dialog v-model="noServicesDialog" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img
                  src="@/assets/graphics/attention.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block">
                Please select a service
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="noServicesDialog = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { BASE_URL } from "@/main";
import CardTrial from "@/components/CardTrial";
import CardPay from "@/components/CardPay";
import axios from "axios";
export default {
  data() {
    return {
      addServicesBtnTxt: 'Go to Clientarea',
      addServicesBtnLoading: false,
      noServicesDialog: false,
      loading: true,
      isLoading: false,
      btnTxt: "Save and Next",
      radioGroup: 1,
      // features: [
      //     {
      //         id: 1,
      //         icon: 'mdi-account-group-outline',
      //         header: 'Human Resources',
      //         checked: false
      //     },
      //     {
      //         id: 2,
      //         icon: 'mdi-cash',
      //         header: 'Accounts',
      //         checked: false
      //     },
      //     {
      //         id: 3,
      //         icon: 'mdi-headset',
      //         header: 'Customer Relations',
      //         checked: false
      //     }
      // ],
      accountingServices: [],
      humanResourcesServices: [],
      // isTrial: true,
      selectedServices: [],
      amount: 0,
      // trialSelected: false
    };
  },
  watch: {
    trialSelected(newVal) {
      if (newVal) {
        let _this = this;
        _this.selectedServices.forEach((s) => {
          s.checked = false;
        });
        _this.selectedServices = [];
        _this.amount = 0;
        let services1 = _this.humanResourcesServices.filter(
          (service) => service.service_name.toLowerCase() == "onboarding"/* || service.service_name.toLowerCase() == "recruitment"*/
        );
        // addService
        services1.forEach(service1 => {
          _this.selectedServices.push(service1);
          _this.amount += parseInt(service1.price) * 12;
          service1.subscription_type = _this.trialSelected ? 1 : 2;
          service1.checked = true;
        });

        let service2 = _this.humanResourcesServices.filter(
          (service) => service.service_name.toLowerCase() == "travel management"
        )[0];
        // addService
        this.selectedServices.push(service2);
        this.amount += parseInt(service2.price) * 12;
        service2.subscription_type = this.trialSelected ? 1 : 2;
        service2.checked = true;

        let service3 = _this.humanResourcesServices.filter(
          (service) => service.service_name.toLowerCase() == "attendance"
        )[0];
        // addService
        this.selectedServices.push(service3);
        this.amount += parseInt(service3.price) * 12;
        service3.subscription_type = this.trialSelected ? 1 : 2;
        service3.checked = true;

        let service4 = _this.humanResourcesServices.filter(
          (service) => service.service_name.toLowerCase() == "absence or leave"
        )[0];
        // addService
        this.selectedServices.push(service4);
        this.amount += parseInt(service4.price) * 12;
        service4.subscription_type = this.trialSelected ? 1 : 2;
        service4.checked = true;

        this.$emit("services", {
          services: this.selectedServices,
          amount: this.amount * (this.number_of_employees + 1),
          company_subscription: this.trialSelected ? 1 : 2,
        });
      } else {
        let _this = this;
        this.selectedServices.forEach((s) => {
          s.checked = false;
        });
        this.selectedServices = [];
        this.amount = 0;
        let services = this.humanResourcesServices.filter(
          (service) => service.service_name.toLowerCase() == "onboarding"/* || service.service_name.toLowerCase() == "recruitment"*/
        );
        services.forEach(service => {
          // addService
          _this.selectedServices.push(service);
          _this.amount += parseInt(service.price) * 12;
          service.subscription_type = _this.trialSelected ? 1 : 2;
          service.checked = true;
        });

        this.$emit("services", {
          services: this.selectedServices,
          amount: this.amount * (this.number_of_employees + 1),
          company_subscription: this.trialSelected ? 1 : 2,
        });
      }
    },
  },
  computed: {
    ...mapState(["VAT"]),
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authUserData",
      "number_of_employees",
      "authSubscriptionExpired",
      "authCompanyServices",
    ]),
    freeHumanResourcesServices() {
      let _this = this, result = [];
      result = _this.humanResourcesServices.filter(service => service.checked == true);
      return result;
    },
    VAT_FEE() {
      let _this = this,
        result = 0;
      result =
        (_this.VAT / 100) * (_this.amount * (_this.number_of_employees + 1));
      return result;
    },
    trialSelected() {
      return false;
    },
    cardName() {
      if (this.trialSelected) {
        return "card-trial";
      } else {
        return "card-pay";
      }
    },
  },
  methods: {
    ...mapActions([
      'refresh_company_data'
    ]),
    unselectServices() {
      // this.selectedServices = [];
      // this.amount = 0;
      // this.humanResourcesServices.forEach(item => {
      //     item.checked = false;
      // });
      // this.accountingServices.forEach(item => {
      //     item.checked = false;
      // });
      this.selectedServices.forEach((service) => {
        service.subscription_type = this.trialSelected ? 1 : 2;
      });
      this.$emit("services", {
        services: this.selectedServices,
        amount: this.amount * (this.number_of_employees + 1),
        company_subscription: this.trialSelected ? 1 : 2,
      });
    },
    addService(service) {
      if (service.checked === true) {
        if (this.selectedServices.indexOf(service) === -1) {
          this.selectedServices.push(service);
          this.amount += parseInt(service.price) * 12;
          service.subscription_type = this.trialSelected ? 1 : 2;
        } else {
          ("");
        }
      } else {
        this.selectedServices.splice(this.selectedServices.indexOf(service), 1);
        this.amount -= parseInt(service.price) * 12;
      }
      this.$emit("services", {
        services: this.selectedServices,
        amount: this.amount * (this.number_of_employees + 1),
        company_subscription: this.trialSelected ? 1 : 2,
      });
    },
    changeStep(step) {
      if (this.selectedServices.length >= 1) {
        this.$emit("step", step);
      } else {
        this.noServicesDialog = true;
      }
    },
    auth_request() {
      //console.log(2)
    },
    fetchServices() {
      let _this = this;
      axios
        .get(`${BASE_URL}fetchservices`)
        .then((response) => {
          _this.allFeatures = response.data;
          _this.loading = false;
          _this.accountingServices = this.allFeatures.accounting_services.map(
            (service) => {
              return {
                service_id: service.id,
                service_name: service.service_name,
                company_id: _this.authCompanyData.id,
                price: parseInt(service.amount),
                service_category_id: service.service_category_id,
                checked: false,
                icon: _this.getIcon(service.service_name),
                subscription_type: this.trialSelected ? 1 : 2,
              };
            }
          );
          _this.humanResourcesServices =
            this.allFeatures.human_resources_services.map((service) => {
              return {
                service_id: service.id,
                service_name: service.service_name,
                company_id: _this.authCompanyData.id,
                price: parseInt(service.amount),
                service_category_id: service.service_category_id,
                checked: false,
                icon: _this.getIcon(service.service_name),
                subscription_type: this.trialSelected ? 1 : 2
              };
            });
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // AFTER AXIOS CALL
          let services = _this.humanResourcesServices.filter(
            (service) => service.service_name.toLowerCase() == "onboarding"/* || service.service_name.toLowerCase() == "recruitment"*/
          );
          // addService
          services.forEach(service => {
            _this.selectedServices.push(service);
            _this.amount += parseInt(service.price) * 12;
            service.subscription_type = _this.trialSelected ? 1 : 2;
            service.checked = true;
          });

          if (_this.authSubscriptionExpired) {
            // _this.trialSelected = false;
            _this.humanResourcesServices.forEach((s) => {
              _this.authCompanyServices.forEach((cs) => {
                if (s.service_id == cs.service_id) {
                  if (
                    (s.service_name.toLowerCase() != "onboarding" /*|| s.service_name.toLowerCase() == "recruitment"*/) &&
                    _this.selectedServices.indexOf(s) == -1
                  ) {
                    _this.selectedServices.push(s);
                    _this.amount += parseInt(s.price) * 12;
                    s.subscription_type = 2;
                  }

                  s.checked = true;
                }
              });
            });
          }

          this.$emit("services", {
            services: this.selectedServices,
            amount: this.amount * (this.number_of_employees + 1),
            company_subscription: this.trialSelected ? 1 : 2,
          });
        });
    },
    addCompanyServices() {
      let _this = this;
      let letters = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ];
      let ref = "";
      for (let i = 0; i <= 10; i++) {
        if (i === 5) {
          ref += new Date().getTime();
          continue;
        }
        // ref += Math.floor(letters[Math.random() * letters.length])
        ref +=
          letters[Math.floor(Math.random() * letters.length)].toUpperCase();
      }
      _this.addServicesBtnTxt = "";
      _this.addServicesBtnLoading = true;
      axios
        .post(`${BASE_URL}addservice`, {
          payment_ref: ref,
          company_id: _this.authCompanyData.id,
          amount: 0,
          services: _this.freeHumanResourcesServices,
          company_subscription: 2, //_this.serviceSelections.company_subscription,
          user_id: _this.authUserData.id,
        })
        .then(function () {
          _this.refresh_company_data({
            user_id: _this.authUserData.id,
            company_id: _this.authCompanyData.id,
          }).then(() => {
            _this.$router.push({ name: 'ClientArea', params: { subdomain: _this.authCompanyData.subdomain }, query: { addon: 'first-time' } });
            _this.addServicesBtnLoading = false;
            _this.addServicesBtnTxt = "Go to Clientarea";
          })
        })
        .catch(function () {
          _this.$swal.fire(
            "Error!",
            "There was an error in setting up your account. Please contact the systems admin or try again.",
            "error"
          );
          _this.isLoading = false;
          _this.addServicesBtnLoading = false;
          _this.addServicesBtnTxt = "Go to Clientarea";
        })
        .then(function () {
          //
        });
    },
    getIcon(name) {
      if (name.toLowerCase() == "onboarding") {
        return "fal fa-users";
      } else if (name.toLowerCase() == "attendance") {
        return "fal fa-clipboard-user";
      } else if (name.toLowerCase() == "timesheets") {
        return "fal fa-file-lines";
      } else if (name.toLowerCase() == "absence or leave") {
        return "fal fa-island-tropical";
      } else if (name.toLowerCase() == "medicals") {
        return "fal fa-notes-medical";
      } else if (name.toLowerCase() == "assets") {
        return "fal fa-computer-speaker";
      } else if (name.toLowerCase() == "file management") {
        return "fal fa-paste";
      } else if (name.toLowerCase() == "payroll") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "payslip") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "travel management") {
        return "fal fa-plane-departure";
      } else if (name.toLowerCase() == "recruitment") {
        return "fal fa-handshake";
      } else if (name.toLowerCase() == "projects") {
        return "fal fa-tasks";
      } else if (name.toLowerCase() == "loan") {
        return "fal fa-coins";
      } else if (name.toLowerCase() == "tasksheets") {
        return "fal fa-file-spreadsheet";
      } else if (name.toLowerCase() == "requisitions") {
        return "fal fa-money-check";
      } else if (name.toLowerCase() == "dockets") {
        return "fal fa-scale-balanced";
      } else if (name.toLowerCase() == "performance") {
        return "fal fa-chart-line-up";
      } else {
        return "fal fa-question";
      }
    }
  },
  components: {
    "card-trial": CardTrial,
    "card-pay": CardPay,
  },
  mounted() {
    let _this = this;
    // setInterval(() => {
    //     _this.isTrial = !_this.isTrial
    // }, 10000);
    _this.$emit("services", {
      services: _this.selectedServices,
      amount: _this.amount * (_this.number_of_employees + 1),
      company_subscription: _this.trialSelected ? 1 : 2,
    });

    _this.fetchServices();

    if (_this.authSubscriptionExpired) {
      // _this.trialSelected = false;
      _this.humanResourcesServices.forEach((s) => {
        _this.authCompanyServices.forEach((cs) => {
          if (s.service_id == cs.service_id) {
            s.checked = true;
          }
        });
      });
    }
  },
};
</script>

<style lang="scss">
$primary-color: #069;
$text-field-color: #4e4e52;

.selected-icons {
  font-size: 24px !important;
}

.fa-eye,
.fa-eyeslash {
  color: $primary-color !important;
}

.company-name .v-text-field--single-line {
  .v-text-field__suffix,
  .v-text-field__prefix {
    color: $text-field-color !important;
    background-color: #00669914;
    padding: 10px;
    height: 38px;
  }
}

// .v-text-field__suffix, .v-text-field__prefix, input {
//     color: $text-field-color !important;
//     background-color: #00669914;
//     padding: 10px;
//     height: 48px;
// }
.v-text-field__suffix {
  border-radius: 0px 5px 5px 0px;
  padding-top: 8px;
  padding-left: 8px !important;
}

.v-text-field__prefix {
  border-radius: 5px 0px 0px 5px;
  padding-top: 8px;
  padding-right: 8px !important;
  text-decoration: underline;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
  label {
    margin-left: 2%;
  }

  input {
    text-indent: 3%;
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.v-text-field__details {
  padding-left: 0 !important;
}

.theme--light.v-messages {
  color: $primary-color !important;
}

.company-name
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}

h3 {
  font-family: Work Sans;
  font-size: 26px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: center;
  color: #111d5e;
}

label {
  color: #999 !important;
}

.v-input--switch__track {
  box-shadow: #0000005c 1px 5px 15px 0px !important;
}

.selected svg {
  height: 20px;
  width: 20px;
}
</style>
<style lang="scss" scoped>
i.fas {
  padding: 20px 0px;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: initial !important;
}

.v-form {
  margin: 0%;
}

$primary-color: #069;

#small-link {
  color: $primary-color !important;
}

.space-top {
  padding-top: 0px;
}

.create-submit {
  width: 35%;
  height: 60px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600;

  span {
    text-transform: initial;
  }
}

.create-submit2 {
  width: 100%;
  height: 50px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600;

  span {
    text-transform: initial;
  }
}

.lead {
  margin-bottom: 0;
  padding: 2% 0 0 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: left;
}

.card-h3 {
  font-size: 17px;
  margin-top: 10px;
}

.card-p {
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #111d5e !important;
}

.mdi-all-services {
  font-size: 50px !important;
}

.feature-card {
  box-shadow: 0px 0px 50px 0px #00000014 !important;
  border-radius: 5px !important;
  border: 5px solid #fff;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}

.v-messages {
  display: none !important;
}

.right-card {
  padding: 60px 0px;
  box-shadow: 0px 0px 50px 0px #00000005 !important;
  border-radius: 10px !important;
}
</style>
