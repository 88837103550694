<template>
    <div class="tab-pane fade" id="list-settings600" role="tabpanel" aria-labelledby="list-settings-list600">
        <div v-if="gradeLevelView == 0">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Grade Level Management
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div v-if="permissionIsAssigned('payroll-approve')" class="col-lg-7 offset-5 py-4">
                                <button @click="gradeLevelView = 1" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary ml-4 m-1 text-white btn-raised ripple">
                                    Create Grade Level
                                </button>
                                <v-text-field v-model="searchInputs.gradeLevelName" placeholder="Search grade levels" solo
                                    class="mt-0" dense></v-text-field>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Grade Level Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Pay Grade
                                    </th>
                                    <th class="text-left" scope="col">Salary</th>
                                    <th class="text-left" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="level in searchedGradeLevels" :key="level.id">
                                    <td class="text-left">
                                        {{ level.gradelevel_name }}
                                    </td>
                                    <td v-if="getPayGradeInfo(level.paygrade_id)" class="text-left">
                                        {{ getPayGradeInfo(level.paygrade_id).grade_name }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>Pay grade not found</i>
                                    </td>
                                    <td class="text-left">{{ level.salary }}</td>
                                    <td style="padding-top: 13px;" class="employee-options text-left">
                                        <div class="row ml-0">
                                            <i @click="launch_edit_grade_level(level)"
                                                class="py-4 text-center fal fa-edit mr-2"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                            <i @click="delete_grade_level(level)" class="py-4 text-center fal fa-trash ml-2"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="gradeLevelView == 1">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Create Grade Level
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="gradeLevelView = 0" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    View Grade Levels
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0 pt-0">
                <div>
                    <v-form ref='form1' @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Pay Grade <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select class="mt-2" :rules="[
                                        v => !!v || 'Pay grade is required'
                                    ]" :items="paygradeNames" placeholder="Select pay grade" solo dense
                                        :full-width="true" v-model="gradeLevelForm.paygrade"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Grade Level Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="gradeLevelForm.gradelevel_name" :rules="[
                                        v => !!v || 'Grade level is required'
                                    ]" placeholder="Enter grade level name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Salary <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="gradeLevelForm.salary" :rules="[
                                        v => !!v || 'Salary is required',
                                        v => v > 0 || 'Amount must be more than 0',
                                    ]" placeholder="Enter salary" solo type="number" class="mt-2" dense></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                                    <button @click="grade_level_create_submit ? null : paygrade_level_create()"
                                        style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ grade_level_create_text }}
                                        <v-icon
                                            :style="!grade_level_create_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Edit Pay Grade Level
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="gradeLevelView = 0" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    View Grade Levels
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form ref='form2' @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Grade Level Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="editGradeLevelForm.gradelevel_name"
                                        placeholder="Enter grade level name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Pay Grade <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select class="mt-2" :items="paygradeNames" placeholder="Select pay grade" solo dense
                                        :full-width="true" v-model="editGradeLevelForm.paygrade"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Salary <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="editGradeLevelForm.salary" placeholder="Enter salary" solo
                                        type="number" class="mt-2" dense></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                                    <button @click="grade_level_update_submit ? null : paygrade_level_update()"
                                        style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ grade_level_update_text }}
                                        <v-icon
                                            :style="!grade_level_update_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            searchInputs: {
                gradeLevelName: ""
            },
            gradeLevelView: 0,
            grade_level_create_text: "Submit",
            grade_level_create_submit: false,
            gradeLevelForm: {
                gradelevel_name: "",
                paygrade: "",
                salary: "",
            },
            grade_level_update_text: "Update",
            grade_level_update_submit: false,
            editGradeLevelForm: {
                id: "",
                gradelevel_name: "",
                paygrade: "",
                salary: "",
            },

        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanyPayGrades',
            'authCompanyGradeLevels'
        ]),
        searchedGradeLevels() {
            let _this = this,
                result = _this.authCompanyGradeLevels;
            if (_this.searchInputs.gradeLevelName) {
                result = result.filter((level) => level.gradelevel_name.toLowerCase().indexOf(_this.searchInputs.gradeLevelName.toLowerCase()) !== -1);
            }
            return result;
        },
        paygradeNames() {
            let _this = this,
                result = [];
            result = _this.authCompanyPayGrades.map((pg) => pg.grade_name);
            return result;
        },
        addLevelPayGrade() {
            let _this = this;
            if (_this.authCompanyPayGrades) {
                return _this.authCompanyPayGrades.filter((pg) => {
                    return _this.gradeLevelForm.paygrade == pg.grade_name;
                })[0];
            } else {
                return "";
            }
        },
        updateLevelPayGrade() {
            let _this = this;
            if (_this.authCompanyPayGrades) {
                return _this.authCompanyPayGrades.filter((pg) => {
                    return _this.editGradeLevelForm.paygrade == pg.grade_name;
                })[0];
            } else {
                return "";
            }
        },
    },
    methods: {
        ...mapActions([
            'fetch_company_grade_levels'
        ]),
        getPayGradeInfo(id) {
            let _this = this;
            if (_this.authCompanyPayGrades) {
                return _this.authCompanyPayGrades.filter((pg) => {
                    return pg.id == id;
                })[0];
            } else {
                return null;
            }
        },
        launch_edit_grade_level(level) {
            let paygradeInfo = this.getPayGradeInfo(level.paygrade_id);
            this.editGradeLevelForm.id = level.id;
            this.editGradeLevelForm.gradelevel_name = level.gradelevel_name;
            this.editGradeLevelForm.paygrade = paygradeInfo.grade_name;
            this.editGradeLevelForm.salary = level.salary;
            this.gradeLevelView = 2;
        },
        delete_grade_level(level) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to delete this grade level?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Delete`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                        axios.post(`${BASE_URL}deletegradelevel`, {
                            gradelevel_id: level.id,
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Grade level deleted successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Grade level couldn't be deleted",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_grade_levels();
                            });
                    }
                });
        },
        async paygrade_level_update() {
            const _this = this;
            const valid = await _this.$refs.form2.validate();
            if (!valid) {
                return;
            }
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (parseFloat(_this.editGradeLevelForm.salary) < parseFloat(_this.updateLevelPayGrade.minimum_salary)) {
                Toast.fire({
                    icon: "error",
                    title: "The salary can't be lower than the minimum salary for this pay grade",
                });
                return;
            }
            if (parseFloat(_this.editGradeLevelForm.salary) > parseFloat(_this.updateLevelPayGrade.maximum_salary)) {
                Toast.fire({
                    icon: "error",
                    title: "The salary can't be higher than the maximum salary for this pay grade",
                });
                return;
            }
            _this.grade_level_update_text = "";
            _this.grade_level_update_submit = true;
            axios.post(`${BASE_URL}editgradelevel`, {
                gradelevel_id: _this.editGradeLevelForm.id,
                gradelevel_name: _this.editGradeLevelForm.gradelevel_name,
                paygrade_id: _this.updateLevelPayGrade.id,
                salary: _this.editGradeLevelForm.salary,
            })
                .then(function () {
                    _this.fetch_company_grade_levels();
                    Toast.fire({
                        icon: "success",
                        title: "Pay grade level updated successfully",
                    });
                    _this.editGradeLevelForm.gradelevel_name = "";
                    _this.editGradeLevelForm.paygrade = "";
                    _this.editGradeLevelForm.salary = "";
                    _this.gradeLevelView = 0;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.gradelevel_name
                                    ? errors.gradelevel_name[0]
                                    : null || errors.salary
                                        ? errors.salary[0]
                                        : null
                            : null || error.response.data.error || error.response.data.message || "The pay grade level couldn't be updated",
                    });
                })
                .then(function () {
                    _this.grade_level_update_submit = false;
                    _this.grade_level_update_text = "Update";
                });
        },
        async paygrade_level_create() {
            const _this = this;
            const valid = await _this.$refs.form1.validate();
            if (!valid) {
                return;
            }
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (parseFloat(_this.gradeLevelForm.salary) < parseFloat(_this.addLevelPayGrade.minimum_salary)) {
                Toast.fire({
                    icon: "error",
                    title: "The salary can't be lower than the minimum salary for this pay grade",
                });
                return;
            }
            if (parseFloat(_this.gradeLevelForm.salary) > parseFloat(_this.addLevelPayGrade.maximum_salary)) {
                Toast.fire({
                    icon: "error",
                    title: "The salary can't be higher than the maximum salary for this pay grade",
                });
                return;
            }
            _this.grade_level_create_text = "";
            _this.grade_level_create_submit = true;
            axios.post(`${BASE_URL}addgradelevel`, {
                company_id: _this.authCompanyData.id,
                gradelevel_name: _this.gradeLevelForm.gradelevel_name,
                paygrade_id: _this.addLevelPayGrade.id,
                salary: _this.gradeLevelForm.salary,
            })
                .then(function () {
                    _this.fetch_company_grade_levels();
                    Toast.fire({
                        icon: "success",
                        title: "Pay grade level added successfully",
                    });
                    _this.gradeLevelForm.gradelevel_name = "";
                    _this.gradeLevelForm.paygrade = "";
                    _this.gradeLevelForm.salary = "";
                    _this.gradeLevelView = 0;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.gradelevel_name
                                    ? errors.gradelevel_name[0]
                                    : null || errors.salary
                                        ? errors.salary[0]
                                        : null
                            : null || error.response.data.error || error.response.data.message || "The pay grade level couldn't be added",
                    });
                })
                .then(function () {
                    _this.grade_level_create_submit = false;
                    _this.grade_level_create_text = "Submit";
                });
        },

    },
    mounted() {
        this.fetch_company_grade_levels()
    }
}
</script>

<style></style>