<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="$router.push({ name: 'Onboarding' })"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Candidates</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div class="dropdown animate__animated animate__fadeIn">
            <i @click="$router.push({ name: 'Onboarding' })"
              style="cursor: pointer; font-size: 120%; position: relative; top: 2px;" class="i-Close mr-1"></i>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Candidate Details <sup>(Uneditable)</sup></h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">First Name</span>
                <v-text-field v-model="formData.firstname" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.firstname"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Last Name</span>
                <v-text-field v-model="formData.lastname" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.lastname"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Date of Birth</span>
                <v-text-field v-model="formData.dob" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.dob"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Gender</span>
                <v-text-field v-model="formData.sex" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.sex"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">E-mail Address</span>
                <v-text-field v-model="formData.email" solo class="mt-2" :disabled="cannotEdit.email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Phone Number</span>
                <v-text-field v-model="formData.phone" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.phone"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Official Email</span>
                <v-text-field v-model="formData.officialEmail" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.officialEmail"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Address Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Address</span>
                <v-text-field v-model="formData.address" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.address"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">State</span>
                <v-text-field v-model="formData.state" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.state"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">City</span>
                <v-text-field v-model="formData.city" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.city"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Country</span>
                <v-text-field v-model="formData.country" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.country"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Professional Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Years of Experience</span>
                <v-text-field v-model="formData.experience" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.experience"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Current Salary</span>
                <v-text-field
                  v-model="formData.salary"
                  placeholder="Unspecified"
                  solo
                  class="mt-2"
                  :disabled="cannotEdit.salary"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Source of Hire</span>
                <v-text-field v-model="formData.sourceOfHire" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.address"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Highest Qualification</span>
                <v-text-field v-model="formData.highestQualification" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.highestQualification"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Skill Set</span>
                <v-textarea v-model="formData.skillSet" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.skillSet"></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Additional Information</span>
                <v-textarea v-model="formData.additionalInformation" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.additionalInformation"></v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Personal Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Father's Name</span>
                <v-text-field v-model="formData.fathers_name" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.fathers_name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Mother's Name</span>
                <v-text-field v-model="formData.mothers_name" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.mothers_name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Spouse's Name</span>
                <v-text-field v-model="formData.spouses_name" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.spouses_name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Blood Group</span>
                <v-text-field v-model="formData.blood_group" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.blood_group"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Guarantor Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor First Name</span>
                <v-text-field v-model="formData.guarantor_frist_Name" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.guarantor_frist_Name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Last Name</span>
                <v-text-field v-model="formData.guarantor_last_Name" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.guarantor_last_Name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor E-mail Address</span>
                <v-text-field v-model="formData.guarantor_email" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.guarantor_email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Address</span>
                <v-text-field v-model="formData.guarantor_address" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.guarantor_address"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Phone Number</span>
                <v-text-field v-model="formData.guarantor_phone_number" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.guarantor_phone_number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Candidate-Guarantor Relationship</span>
                <v-text-field v-model="formData.employee_grelationship" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.employee_grelationship"></v-text-field>
              </v-col>

            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Payment Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Basic Salary</span>
                <v-text-field v-model="formData.basic_salary" placeholder="Unspecified" solo class="mt-2" type="number"
                  :disabled="cannotEdit.basic_salary"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Account Number</span>
                <v-text-field v-model="formData.account_number" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.account_number" type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Bank Name</span>
                <v-text-field v-model="formData.bank_name" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.bank_name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Payment Method</span>
                <v-text-field v-model="formData.payment_method" placeholder="Unspecified" solo class="mt-2"
                  :disabled="cannotEdit.payment_method"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb row" style="margin-top: 1rem; margin-left: 1rem;">
          <h1 class="col-11 px-0 py-0">Education</h1>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="text-left">School Name</th>
                <th scope="col" class="text-left">Degree/Diploma</th>
                <th scope="col" class="text-left">Field(s) of Study</th>
                <th scope="col" class="text-left">Date of Completion</th>
                <th scope="col" class="text-left">Additional Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(education, index) in candidateSpecificEducation" :key="index">
                <td>
                  <v-text-field v-model="education.school_name" solo class="educationField mt-2" :disabled="true">
                  </v-text-field>
                </td>
                <td>
                  <v-text-field v-model="education.degree_diploma" solo class="educationField mt-2" :disabled="true">
                  </v-text-field>
                </td>
                <td>
                  <v-text-field v-model="education.field_of_study" solo class="educationField mt-2" :disabled="true">
                  </v-text-field>
                </td>
                <td>
                  <v-text-field v-model="education.date_of_completion" solo class="educationField mt-2"
                    :disabled="true"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="education.additional_notes" solo class="educationField mt-2" :disabled="true">
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="breadcrumb row" style="margin-top: 1rem; margin-left: 1rem;">
          <h1 class="col-11 px-0 py-0">Experience</h1>
        </div>
        <div class="table-responsive pb-4">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="text-left">Occupation</th>
                <th scope="col" class="text-left">Company</th>
                <th scope="col" class="text-left">Summary</th>
                <th scope="col" class="text-left">Duration</th>
                <th scope="col" class="text-left">Currently Work Here</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(experience, index) in candidateSpecificExperience" :key="index">
                <td>
                  <v-text-field v-model="experience.occupation" solo class="educationField mt-2" :disabled="true">
                  </v-text-field>
                </td>
                <td>
                  <v-text-field v-model="experience.company" solo class="educationField mt-2" :disabled="true">
                  </v-text-field>
                </td>
                <td>
                  <v-text-field v-model="experience.summary" solo class="educationField mt-2" :disabled="true">
                  </v-text-field>
                </td>
                <td>
                  <v-text-field v-model="experience.duration" solo class="educationField mt-2" :disabled="true">
                  </v-text-field>
                </td>
                <td>
                  <v-text-field v-model="experience.currently_work_here" solo class="educationField mt-2"
                    :disabled="true"></v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex-grow-1"></div>
      <div class="main-header pl-5" style="position: fixed; bottom: 0px; z-index: 60;">
        <div class="row">
          <div class="logo col-md-4 py-0">
            <div class="dropdown">
              <button type="button" class="btn btn-outline-secondary m-1 ripple"
                @click="$router.push({ name: 'Onboarding' })">
                Close
              </button>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        email: "",
        phone: "",
        firstname: "",
        lastname: "",
        dob: "",
        sex: "",
        officialEmail: "",
        address: "",
        state: "",
        city: "",
        country: "",
        experience: "",
        salary: "",
        sourceOfHire: "",
        skillSet: "",
        highestQualification: "",
        additionalInformation: "",
        fathers_name: "",
        mothers_name: "",
        spouses_name: "",
        blood_group: "",
        guarantor_frist_Name: "",
        guarantor_last_Name: "",
        guarantor_email: "",
        guarantor_address: "",
        guarantor_phone_number: "",
        employee_grelationship: "",
        basic_salary: "",
        account_number: "",
        bank_name: "",
        payment_method: ""
      },
      cannotEdit: {
        email: true,
        phone: true,
        firstname: true,
        lastname: true,
        dob: true,
        sex: true,
        officialEmail: true,
        streetAddress: true,
        state: true,
        city: true,
        country: true,
        experience: true,
        salary: true,
        sourceOfHire: true,
        skillSet: true,
        highestQualification: true,
        additionalInformation: true,
        fathers_name: true,
        mothers_name: true,
        spouses_name: true,
        blood_group: true,
        guarantor_frist_Name: true,
        guarantor_last_Name: true,
        guarantor_email: true,
        guarantor_address: true,
        guarantor_phone_number: true,
        employee_grelationship: true,
        basic_salary: true,
        account_number: true,
        bank_name: true,
        payment_method: true,
      },
      sourceOfHire: [
        "Direct",
        "Newspaper",
        "Advertisement",
        "Employee Referral",
        "External Referral"
      ],
      work_here: ["Yes", "No"],
      allEducation: [],
      allWorkExperience: [],
      candidateSpecificEducation: [],
      candidateSpecificExperience: []
    };
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authCompanyCandidates"
    ]),
    candidate() {
      let _this = this;
      return _this.authCompanyCandidates.filter(candidate => {
        return candidate.id == parseInt(_this.$route.params.id);
      })[0];
    }
  },
  methods: {
    ...mapActions(["fetch_candidates"]),
    fileTrigger() {
      document.querySelector(".staffUploadInput div div div input").click();
    },
    fileTrigger2() {
      document.querySelector(".staffUploadInput2 div div div input").click();
    }
  },
  mounted() {
    document.title = "Kylian ERP - View Candidate";
    let _this = this;
    this.formData.email = this.candidate.employee_email;
    this.formData.firstname = this.candidate.employee_fristname;
    this.formData.phone = this.candidate.employee_phone1;
    this.formData.lastname = this.candidate.employee_lastname;
    this.formData.dob = this.candidate.employee_date_of_birth;
    this.formData.sex = this.candidate.employee_sex;
    this.formData.officialEmail = this.candidate.employee_officialemail;
    this.formData.address = this.candidate.employee_address;
    this.formData.state = this.candidate.employee_state;
    this.formData.country = this.candidate.employee_nationality;
    this.formData.experience = this.candidate.experience;
    this.formData.salary = this.candidate.current_salary;
    this.formData.sourceOfHire = this.candidate.source_of_hire;
    this.formData.highestQualification = this.candidate.Highest_qualification;
    this.formData.skillSet = this.candidate.skill_set;
    this.formData.additionalInformation = this.candidate.paddtional_info;

    axios
      .post(`${BASE_URL}fetchcandidate`, {
        company_id: _this.authCompanyData.id
      })
      .then(function (response) {
        const RESPONSE = response.data;
        console.log(RESPONSE);
        _this.allWorkExperience = RESPONSE.Work_experience;
        _this.allEducation = RESPONSE.Education;
        let tempEducation = _this.allEducation.filter(edu => {
          return edu.employee_id == _this.candidate.id;
        });
        if (tempEducation.length) {
          _this.candidateSpecificEducation = tempEducation.map(edu => {
            return {
              id: edu.id,
              company_id: edu.company_id,
              company_unique_id: edu.company_unique_id,
              school_name: edu.school_name,
              degree_diploma: edu.degree_diploma,
              field_of_study: edu.field_of_study,
              date_of_completion: edu.date_of_completion,
              additional_notes: edu.additional_notes,
              employee_id: edu.employee_id,
              created_at: edu.created_at,
              updated_at: edu.updated_at,
              viewOption: false
            };
          });
        } else {
          _this.candidateSpecificEducation.push({
            id: null,
            company_id: "",
            company_unique_id: "",
            school_name: "",
            degree_diploma: "",
            field_of_study: "",
            date_of_completion: "",
            additional_notes: "",
            employee_id: "",
            created_at: "",
            updated_at: "",
            viewOption: false
          });
        }
        let tempExperience = _this.allWorkExperience.filter(exp => {
          return exp.employee_id == _this.candidate.id;
        });
        if (tempExperience.length) {
          _this.candidateSpecificExperience = tempExperience.map(exp => {
            return {
              company: exp.company,
              company_id: exp.company_id,
              company_unique_id: exp.company_unique_id,
              created_at: exp.created_at,
              currently_work_here: exp.currently_work_here,
              duration: exp.duration,
              employee_id: exp.employee_id,
              field_of_study: exp.field_of_study,
              id: exp.id,
              occupation: exp.occupation,
              summary: exp.summary,
              updated_at: exp.updated_at,
              viewOption: false
            };
          });
        } else {
          _this.candidateSpecificExperience.push({
            id: null,
            company: "",
            company_id: "",
            company_unique_id: "",
            created_at: "",
            currently_work_here: "",
            duration: "",
            employee_id: "",
            field_of_study: "",
            occupation: "",
            summary: "",
            updated_at: "",
            viewOption: false
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // context.commit('AUTH_FETCHED_EMPLOYEES', );
      });
  }
};
</script>

<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}
</style>
