<template>
    <div class="tab-pane fade" id="list-settings60" role="tabpanel" aria-labelledby="list-settings-list60">
        <h4>
            <div class="row">
                <div class="col-lg-6" style="align-self: center;">
                    Manage hospitals
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3 offset-9 py-4">
                            <button
                                @click="add_hospital_dialog = true"
                                style="float: right;"
                                type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                            >
                                Add Hospital
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </h4>
        <div class="main-content mt-0 px-0">
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left" scope="col">
                                    Hospital Name
                                </th>
                                <th
                                    v-if="permissionIsAssigned('medicals-approve') || permissionIsAssigned('medicals-create')"
                                    class="text-left"
                                    scope="col"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="h in authCompanyHospitals" :key="h.id">
                                <td class="text-left">{{ h.name }}</td>
                                <td
                                    v-if="permissionIsAssigned('medicals-approve') || permissionIsAssigned('medicals-create')"
                                    style="padding-top: 13px;"
                                    class="employee-options text-left"
                                >
                                    <div class="row ml-0">
                                        <i @click="launch_edit_hospital(h)" class="py-4 text-center fal fa-edit mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                        <i @click="delete_hospital(h)" class="py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <v-dialog v-model="add_hospital_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add hospital</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" class="remove-hint">
                                        <span style="color: #111d5e;">Hospital Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="add_hospital_form.name" placeholder="Enter the hospital name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button
                                            @click="add_hospital_button_loading ? null : add_hospital()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ add_hospital_button_text }}
                                            <v-icon
                                                :style="!add_hospital_button_loading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_hospital_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="edit_hospital_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Edit hospital</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" class="remove-hint">
                                        <span style="color: #111d5e;">Hospital Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="edit_hospital_form.name" placeholder="Enter the hospital name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button
                                            @click="edit_hospital_button_loading ? null : update_hospital()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ edit_hospital_button_text }}
                                            <v-icon
                                                :style="!edit_hospital_button_loading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="edit_hospital_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            add_hospital_dialog: false,
            add_hospital_form: {
                name: "",
            },
            add_hospital_button_text: "Submit",
            add_hospital_button_loading: false,
            edit_hospital_dialog: false,
            edit_hospital_form: {
                id: "",
                name: "",
            },
            edit_hospital_button_text: "Update",
            edit_hospital_button_loading: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanyHospitals'
        ])
    },
    methods: {
        ...mapActions([
            'fetch_all_company_hospitals'
        ]),
        launch_edit_hospital(hospital) {
            this.edit_hospital_form.id = hospital.id;
            this.edit_hospital_form.name = hospital.name;
            this.edit_hospital_dialog = true;
        },
        add_hospital() {
            const _this = this;
            _this.add_hospital_button_text = "";
            _this.add_hospital_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}addcompanyhospital`, {
                company_id: _this.authCompanyData.id,
                name: _this.add_hospital_form.name,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "The hospital was added successfully",
                });
                _this.add_hospital_dialog = false;
                _this.add_hospital_form.name = "";
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors ? (typeof errors === "string" ? errors : null || errors.name ? errors.name[0] : null) : null || error.response.data.error || error.response.data.message || "The hospital couldn't be added",
                });
            })
            .then(function () {
                _this.add_hospital_button_loading = false;
                _this.add_hospital_button_text = "Submit";
                _this.fetch_all_company_hospitals();
            });
        },
        update_hospital() {
            const _this = this;
            _this.edit_hospital_button_text = "";
            _this.edit_hospital_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updatecompanyhospital`, {
                hospital_id: _this.edit_hospital_form.id,
                name: _this.edit_hospital_form.name,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "The hospital was updated successfully",
                });
                _this.edit_hospital_dialog = false;
                _this.edit_hospital_button_loading = false;
                _this.edit_hospital_form.id = "";
                _this.edit_hospital_form.name = "";
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors ? (typeof errors === "string" ? errors : null || errors.name ? errors.name[0] : null) : null || error.response.data.error || error.response.data.message || "The hospital couldn't be updated",
                });
            })
            .then(function () {
                _this.edit_hospital_button_loading = false;
                _this.edit_hospital_button_text = "Update";
                _this.fetch_all_company_hospitals();
            });
        },
        delete_hospital(hospital) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to remove this hospital?",
                showCancelButton: true,
                confirmButtonText: `Yes, Remove`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${BASE_URL}deletecompanyhospital`, {
                        ids: [
                            {
                                id: hospital.id,
                            },
                        ],
                    })
                    .then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "Hospital removed successfully",
                        });
                    })
                    .catch(function (error) {
                        Toast.fire({
                            icon: "error",
                            title: "Hospital couldn't be removed",
                        });
                        console.log(error);
                    })
                    .then(function () {
                        _this.fetch_all_company_hospitals();
                    });
                }
            });
        }
    },
    mounted() {
        const _this = this;
        _this.fetch_all_company_hospitals();
    }
}
</script>

<style>

</style>