<template>
  <div>
    <v-row class="container pb-0">
      <v-col cols="6" md="6">
        <h3
          class="pt-0 text-left container"
          style="font-weight: 400; font-size: 20px;"
        >
          Applications
        </h3>
        <v-container v-for="(application, index) in applications" :key="index">
          <v-card class="feature-card">
            <v-row>
              <v-col cols="8" md="8" style="color: #111D5E;">
                <div class="pa-3">
                  <h4 style="font-size: 17px; font-weight: 600;">
                    {{ application.name }}
                  </h4>
                  <span
                    class="pt-3 pb-3"
                    style="font-size: 14px; display: block;"
                    >Duration: {{ application.duration }} days</span
                  >
                  <span style="font-size: 14px;"
                    >{{ application.from }} - {{ application.to }}</span
                  >
                </div>
              </v-col>
              <v-col cols="4" md="4" style="align-self: flex-end;">
                <v-btn
                  type="button"
                  x-large
                  dark
                  class="headButton"
                  style="height: 45px !important; font-size: 0.85rem !important; font-weight: 700;"
                >
                  Review
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="6" md="6">
        <h3 class="pt-0 text-left container" style="font-weight: 400;">
          Calendar
        </h3>
        <v-container>
          <v-date-picker
            class="change-day-padding"
            color="#069"
            v-model="dates"
            range
            full-width
            readonly
          ></v-date-picker>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      sex: ["Male", "Female", "Other"],
      dates: ["2020-11-02", "2020-11-12"],
      applications: [
        {
          id: 1,
          name: "Application for Annual Leave",
          duration: 10,
          from: "2 Nov 2020",
          to: "12 Nov 2020"
        },
        {
          id: 2,
          name: "Application for Sick Leave",
          duration: 5,
          from: "23 Dec 2020",
          to: "28 Dec 2020"
        },
        {
          id: 3,
          name: "Application for Emergency Leave",
          duration: 2,
          from: "7 July 2019",
          to: "9 July 2019"
        }
      ]
    };
  }
};
</script>

<style>
.change-day-padding {
  box-shadow: 0px 5px 10px 0px #00000014 !important;
}
.change-day-padding div div div {
  height: 100% !important;
}
.change-day-padding div div div table tbody tr td {
  padding: 14px 0px !important;
}
.change-day-padding div div div table thead tr th {
  padding: 14px 0px !important;
}
</style>
<style scoped>
.feature-card {
  box-shadow: 0px 5px 10px 0px #00000014 !important;
}
</style>
