<template>
    <div v-if="editChecklistForm && check_all_services('Onboarding')" class="tab-pane fade" id="list-checklists"
        role="tabpanel" aria-labelledby="list-checklists-list">
        <h4>
            <div class="row">
                <div class="col-lg-6" style="align-self: center;">
                    Edit Checklist Item
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div v-if="createChecklistForm || editChecklistForm" class="col-lg-3 offset-9 py-4 text-right">
                            <button @click="editChecklistForm = false" style="float: right;" type="button"
                                class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </h4>
        <div class="main-content mt-0 px-0">
            <div>
                <v-form @submit.prevent>
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Checklist Item Name <i class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field v-model="editFormData.item_name" placeholder="Enter the checklist item name"
                                    solo class="mt-2" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="remove-hint"></v-col>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <button @click="editChecklistSubmit ? null : updateChecklistItem()" style="width: 99%;"
                                    type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                    {{ editChecklistText }}
                                    <v-icon
                                        :style="!editChecklistSubmit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                        fal fa-circle-notch fa-spin
                                    </v-icon>
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
        </div>
    </div>

    <div v-else-if="!editChecklistForm && check_all_services('Onboarding')" class="tab-pane fade" id="list-checklists"
        role="tabpanel" aria-labelledby="list-checklists-list">
        <h4>
            <div class="row">
                <div class="col-lg-6" style="align-self: center;">
                    {{ createChecklistForm ? "Create Checklist Item" : "Checklist Items" }}
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div v-if="createChecklistForm" class="col-lg-3 offset-9 py-4 text-right">
                            <button @click="createChecklistForm = false" style="float: right;" type="button"
                                class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                Cancel
                            </button>
                        </div>
                        <div v-if="!createChecklistForm && permissionIsAssigned('employee-read')"
                            class="col-lg-3 offset-9 py-4">
                            <button @click="createChecklistForm = true" style="float: right;" type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                Create Checklist Item
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </h4>
        <div class="main-content mt-0 px-0">
            <div v-if="createChecklistForm">
                <v-form @submit.prevent>
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Checklist Item Name <i class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field v-model="formData.item_name" placeholder="Enter the checklist item name" solo
                                    class="mt-2" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="remove-hint"></v-col>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <button @click="createChecklistSubmit ? null : createChecklistItem()" style="width: 99%;"
                                    type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                    {{ createChecklistText }}
                                    <v-icon
                                        :style="!createChecklistSubmit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                        fal fa-circle-notch fa-spin
                                    </v-icon>
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
            <div v-else class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-left" scope="col">
                                Checklist Item
                            </th>
                            <th class="text-left" scope="col">
                                Date Added
                            </th>
                            <th v-if="permissionIsAssigned('employee-read')" scope="col" style="width: 10px;">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in authCompanyExitChecklists" :key="item.id">
                            <td class="text-left">
                                {{ item.item_name }}
                            </td>
                            <td class="text-left">
                                {{ new Date(item.created_at).toUTCString().substring(0, 16) }}
                            </td>
                            <td style="padding-top: 13px;" class="employee-options">
                                <div class="row">
                                    <i v-if="permissionIsAssigned('employee-read')" @click="editChecklistItem(item)"
                                        class="col-3 py-4 text-center fal fa-edit mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                    <i v-if="permissionIsAssigned('employee-read')" @click="deleteChecklistItem(item)"
                                        class="col-3 py-4 text-center fal fa-trash ml-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
    props: {},
    data() {
        return {
            createChecklistText: "Submit",
            createChecklistSubmit: false,
            editChecklistText: "Update",
            editChecklistSubmit: false,
            formData: {
                item_name: "",
            },
            editFormData: {
                exitchecklist_id: "",
                item_name: "",
            },
            createChecklistForm: false,
            editChecklistForm: false
        }
    },
    computed: {
        ...mapState([
            'userToken'
        ]),
        ...mapGetters([
            'authCompanyData',
            'authCompanyExitChecklists'
        ])
    },
    methods: {
        ...mapActions([
            'fetch_company_exit_checklists'
        ]),
        deleteChecklistItem(item) {
            const _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this checklist item [" + item.item_name + "]?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                        axios.post(`${BASE_URL}deleteexitchecklists`, {
                            ids: [
                                {
                                    id: item.id,
                                },
                            ],
                        }, {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`
                            }
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Item [" + item.item_name + "] deleted successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Item [" + item.item_name + "] couldn't be deleted",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_exit_checklists();
                            });
                    }
                });
        },
        createChecklistItem() {
            const _this = this;
            _this.createChecklistText = "";
            _this.createChecklistSubmit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}createchecklist`, {
                company_id: _this.authCompanyData.id,
                item_name: _this.formData.item_name
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Checklist item created successfully",
                    });
                    _this.formData.item_name = "";
                    _this.fetch_company_exit_checklists();
                    _this.createChecklistForm = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.item_name
                                    ? errors.item_name[0]
                                    : null
                            : null || error.response.data.error || error.response.data.message || "Checklist item couldn't be created",
                    });
                })
                .then(function () {
                    _this.createChecklistSubmit = false;
                    _this.createChecklistText = "Submit";
                });
        },
        editChecklistItem(item) {
            const _this = this;
            _this.editFormData.exitchecklist_id = item.id;
            _this.editFormData.item_name = item.item_name;
            _this.editChecklistForm = true;
        },
        updateChecklistItem() {
            const _this = this;
            _this.editChecklistText = "";
            _this.editChecklistSubmit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updateexitchecklist`, {
                company_id: _this.authCompanyData.id,
                exitchecklist_id: _this.editFormData.exitchecklist_id,
                item_name: _this.editFormData.item_name
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Checklist item updated successfully",
                    });
                    _this.editFormData.item_name = "";
                    _this.fetch_company_exit_checklists();
                    _this.editChecklistForm = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.id
                                    ? errors.id[0]
                                    : null || errors.item_name
                                        ? errors.item_name[0]
                                        : null
                            : null || error.response.data.error || error.response.data.message || "Checklist Item couldn't be updated",
                    });
                })
                .then(function () {
                    _this.editChecklistSubmit = false;
                    _this.editChecklistText = "Update";
                });
        },
    },
    mounted() {
        const _this = this;
        _this.fetch_company_exit_checklists();
    }
}
</script>

<style></style>