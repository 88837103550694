<template>
  <div>
    <v-row class="container pb-0">
      <v-col cols="12" md="12">
        <h3
          class="pt-0 text-left container mb-0"
          style="font-weight: 400; font-size: 20px;"
        >
          Performance
        </h3>
        <div>
          <v-col class="pb-0 pt-0" cols="3" md="3" offset="9">
            <v-select
              append-icon="mdi-calendar"
              dense
              item-color="#069"
              :items="sex"
              label="Provision Month"
              solo
              color="#069"
            ></v-select>
          </v-col>
        </div>
        <v-container> </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      sex: ["Male", "Female", "Other"]
    };
  }
};
</script>

<style>
.change-day-padding {
  box-shadow: 0px 5px 10px 0px #00000014 !important;
}
.change-day-padding div div div {
  height: 100% !important;
}
.change-day-padding div div div table tbody tr td {
  padding: 14px 0px !important;
}
.change-day-padding div div div table thead tr th {
  padding: 14px 0px !important;
}
</style>
<style scoped>
.feature-card {
  box-shadow: 0px 5px 10px 0px #00000014 !important;
}
</style>
