<template>
  <div class="">
    <v-app class="">
      <v-row style="margin-top: 60px;">
        <v-col cols="12" style="background: url(/img/contact-banner.png); background-repeat: round;">
          <div style="padding:100px 120px 20px;">
            <h2
              class="text-left"
              style="font-family: Poppins !important; font-size: 38px; line-height: 57px; font-weight: 600; color: #FFF !important;"
            >
              Appointment
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="margin-top: 30px;">
          <v-container fluid="true">
            <div class="main_container">
              <div
                class="appointment_bg"
                :style="
                  $vuetify.breakpoint.name == 'xs' ||
                  $vuetify.breakpoint.name == 'sm' ||
                  $vuetify.breakpoint.name == 'md'
                    ? 'width:80% !important;'
                    : ''
                "
              >
                <v-row
                  :style="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm' ||
                    $vuetify.breakpoint.name == 'md'
                      ? 'justify-content:center; align-items:center;'
                      : ''
                  "
                >
                  <v-col
                    cols="12"
                    lg="4"
                    :style="
                      $vuetify.breakpoint.name == 'xs' ||
                      $vuetify.breakpoint.name == 'sm' ||
                      $vuetify.breakpoint.name == 'md'
                        ? 'display: flex; justify-content: center; align-items: center;'
                        : ''
                    "
                  >
                    <div
                      class="appointment_calendar_left"
                      :style="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm' ||
                        $vuetify.breakpoint.name == 'md'
                          ? 'max-width:80%;'
                          : ''
                      "
                    >
                      <h2 class="appointment_left_title">
                        Schedule Appointment for a demo today
                      </h2>
                      <p>
                        Choose from any available date and time for the demo
                        session
                      </p>

                      <div class="appointment_label">
                        Date
                        <div class="appointment_highlight">
                          {{ selectedDate }} {{ currentMonth }}
                          {{ currentYear }}
                        </div>
                      </div>
                      <div class="appointment_label">
                        Time
                        <div class="appointment_highlight">
                          {{ selectedTime }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="8"
                    :style="
                      $vuetify.breakpoint.name == 'xs' ||
                      $vuetify.breakpoint.name == 'sm' ||
                      $vuetify.breakpoint.name == 'md'
                        ? 'display: flex; justify-content: center; align-items: center;'
                        : ''
                    "
                  >
                    <div
                      :style="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm' ||
                        $vuetify.breakpoint.name == 'md'
                          ? 'gap:30px !important; width:80% !important;flex-direction:column; align-items:center; justify-items:center; padding-right:0px'
                          : 'width: max-content !important; flex-direction:row; flex-wrap:wrap;gap:50px !important; '
                      "
                      v-if="appointment_action === 'pick_date'"
                      class="appointment_calendar_right"
                    >
                      <div
                        class="appointment_calendar"
                        :style="
                          $vuetify.breakpoint.name == 'xs' ||
                          $vuetify.breakpoint.name == 'sm' ||
                          $vuetify.breakpoint.name == 'md'
                            ? 'width: 444px; height: 421px;'
                            : 'width: 544px; height: 521px;'
                        "
                      >
                        <v-row>
                          <div class="cal_mounth ">
                            <div class="arrow_left">
                              <img
                                src="../assets/graphics/arrow_left.svg"
                                alt=""
                                srcset=""
                              />
                            </div>
                            <h3 class="cal_month_year">
                              {{ currentMonth }} {{ currentYear }}
                            </h3>
                            <div class="arrow_right">
                              <img
                                src="../assets/graphics/arrow_right.svg"
                                alt=""
                                srcset=""
                              />
                            </div>
                          </div>
                        </v-row>
                        <v-row>
                          <div class="cal_weeks">
                            <div
                              v-for="day in daysOfWeek"
                              :key="day"
                              class="cal_box"
                              :style="
                                $vuetify.breakpoint.name == 'xs' ||
                                $vuetify.breakpoint.name == 'sm' ||
                                $vuetify.breakpoint.name == 'md'
                                  ? 'width: 47.43px;  height: 35.38px;'
                                  : 'width: 67.43px;  height: 65.38px;'
                              "
                            >
                              {{ day }}
                            </div>
                          </div>
                        </v-row>
                        <v-row>
                          <div class="cal_body">
                            <div v-for="(week, index) in weeks" :key="index">
                              <div class="cal_week">
                                <div
                                  v-for="(date, weekIndex) in week"
                                  :key="weekIndex"
                                  class="cal_date"
                                  :style="
                                    $vuetify.breakpoint.name == 'xs' ||
                                    $vuetify.breakpoint.name == 'sm' ||
                                    $vuetify.breakpoint.name == 'md'
                                      ? 'width: 47.43px;  height: 45.38px;'
                                      : 'width: 67.43px;  height: 65.38px;'
                                  "
                                  :class="{
                                    selected_date: isSelectedDate(date)
                                  }"
                                  v-on:click="selectDate(date)"
                                >
                                  {{ date || " " }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-row>
                      </div>
                      <div
                        class="appointment_calendar_options"
                        :style="
                          $vuetify.breakpoint.name == 'xs' ||
                          $vuetify.breakpoint.name == 'sm' ||
                          $vuetify.breakpoint.name == 'md'
                            ? 'width: 100% !important;flex-direction:row; align-items:center;  justify-content:flex-start; padding:0px 30px;gap:20px; flex-wrap:wrap; margin-top:30px'
                            : ' flex-direction:column; width: 20px;'
                        "
                      >
                        <div
                          v-for="time in availableTimes"
                          :key="time"
                          class="time_option"
                          :class="{
                            time_selected: isSelectedTime(time)
                          }"
                          v-on:click="selectTime(time)"
                        >
                          {{ time }}
                        </div>
                        <div
                          class="to_form"
                          :style="
                            $vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm' ||
                            $vuetify.breakpoint.name == 'md'
                              ? 'margin-top:0px !important'
                              : ''
                          "
                          v-on:click="change_action"
                        >
                          Continue
                          <img src="../assets/graphics/arrow_white.svg" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="appointment_form_right"
                      v-if="appointment_action === 'fill_form'"
                    >
                      <div class="form_title">
                        Please enter your information
                      </div>
                      <div class="form" style="margin-top: 30px;">
                        <v-row>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              v-model="formData.fullname"
                              label="Full Name *"
                              outlined
                              placeholder="Enter Your Fullname"
                              class="remove-bottom-hint"
                            ></v-text-field>
                            <v-text-field
                              v-model="formData.company"
                              label="Company Name *"
                              outlined
                              placeholder="Enter Your Company Name "
                              class="remove-bottom-hint"
                            ></v-text-field>
                            <v-text-field
                              v-model="formData.designation"
                              label="Designation *"
                              outlined
                              placeholder="Enter Your Designation"
                              class="remove-bottom-hint"
                            ></v-text-field>
                            <v-text-field
                              v-model="formData.nos"
                              label="Phone Number *"
                              outlined
                              placeholder="Enter the phone number"
                              class="remove-bottom-hint"
                            ></v-text-field
                          ></v-col>
                          <v-col cols="12" lg="6">
                            <v-select
                              v-model="formData.industry"
                              label="Industry Type*"
                              outlined
                              :items="[
                                'California',
                                'Colorado',
                                'Florida',
                                'Georgia',
                                'Texas',
                                'Wyoming'
                              ]"
                              placeholder="Enter Your Fullname"
                              class="remove-bottom-hint"
                            ></v-select>
                            <v-text-field
                              v-model="formData.email"
                              label="Email Address *"
                              outlined
                              placeholder="Enter Your Email"
                              class="remove-bottom-hint"
                            ></v-text-field>
                            <v-text-field
                              v-model="formData.nos"
                              label="Number of Staff *"
                              outlined
                              placeholder="Enter Number of Staff"
                              class="remove-bottom-hint"
                            ></v-text-field>
                            <v-select
                              v-model="formData.about_us"
                              label="Industry Type*"
                              outlined
                              :items="['Instagram', 'Facebook', 'Youtube']"
                              placeholder="Enter Your Fullname"
                              class="remove-bottom-hint"
                            ></v-select
                          ></v-col>
                        </v-row>
                      </div>
                      <div class="form_footer" style="margin-top: 40px;">
                        <div class="button_return" v-on:click="change_action">
                          Back
                        </div>
                        <div
                          class="button_submit"
                          @click.prevent="
                            isLoading ? null : request_appointment()
                          "
                        >
                          {{ btnTxt }}
                          <v-icon :style="!isLoading ? 'display: none' : ''"
                            >fas fa-circle-notch fa-spin
                          </v-icon>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-col>

        <GuestNewsletter />
        <GuestFooter />
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL } from "@/main";
import axios from "axios";
import GuestNewsletter from "@/components/GuestNewsletter";
import GuestFooter from "@/components/GuestFooter";
export default {
  components: {
    GuestFooter,
    GuestNewsletter
  },
  methods: {
    decPage() {
      if (this.currentPage - 1 < 0) {
        return (this.currentPage = 1);
      }
      if (this.currentPage - 1 > 0) {
        return (this.currentPage = this.currentPage - 1);
      }
    },
    incPage() {
      if (this.currentPage + 1 > this.totalPages) {
        return;
      }
      if (this.currentPage + 1 < this.totalPages) {
        this.currentPage = this.currentPage + 1;
      }
      if (this.currentPage + 1 === this.totalPages) {
        this.currentPage = this.currentPage + 1;
      }
    },
    updateCalendar() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();

      this.currentMonth = this.getMonthName(month);
      this.currentYear = year;
      this.weeks = this.getCalendarWeeks(year, month);
    },
    getMonthName(month) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      return monthNames[month];
    },
    getDatesInMonth(year, month) {
      const numDays = new Date(year, month + 1, 0).getDate();
      const dates = [];
      for (let i = 1; i <= numDays; i++) {
        dates.push(i);
      }
      console.log(dates);
      return dates;
    },

    getCalendarWeeks(year, month) {
      const firstDay = new Date(year, month, 1).getDay();
      const numDays = new Date(year, month + 1, 0).getDate();
      const weeks = [[]];
      let weekIndex = 0;

      // Add placeholders for days of the previous month
      for (let i = 0; i < firstDay; i++) {
        weeks[weekIndex].push("");
      }

      // Add days of the current month
      for (let day = 1; day <= numDays; day++) {
        weeks[weekIndex].push(day);

        if (weeks[weekIndex].length === 7 && day < numDays) {
          // Start a new week
          weeks.push([]);
          weekIndex++;
        }
      }
      if (weeks[weekIndex].length < 7) {
        while (weeks[weekIndex].length < 7) {
          weeks[weekIndex].push("");
        }
      }

      return weeks;
    },
    isCurrentMonth(weekIndex) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const { currentYear: calendarYear, currentMonth: calendarMonth } = this;

      return (
        currentYear === calendarYear &&
        currentMonth === calendarMonth &&
        weekIndex === 0
      );
    },
    selectDate(date) {
      this.selectedDate = date;
    },
    isSelectedDate(date) {
      return this.selectedDate === date;
    },
    selectTime(time) {
      this.selectedTime = time;
    },
    isSelectedTime(time) {
      return this.selectedTime === time;
    },
    change_action() {
      this.appointment_action =
        this.appointment_action === "pick_date" ? "fill_form" : "pick_date";
    },
    request_appointment() {
      let _this = this;
      _this.btnTxt = "";
      _this.isLoading = true;
      axios
        .post(`${BASE_URL}demo`, {
          contact_person: _this.formData.name,
          organization: _this.formData.organization,
          email: _this.formData.email,
          phone: _this.formData.phone,
          // date: _this.formData.date,
          // source: _this.formData.source,
          // company_size: _this.formData.company_size,
          message: _this.formData.message
          // services: _this.productFocus
        })
        .then(function() {
          _this.responseForDialog =
            "Your contact message has been sent and received successfully. You'll be contacted shortly";
          _this.dialog = true;
          _this.formData.name = "";
          _this.formData.organization = "";
          _this.formData.email = "";
          _this.formData.phone = "";
          // _this.formData.date = '';
          // _this.formData.source = '';
          // _this.formData.company_size = '';
          _this.formData.message = "";
          // _this.formData.services = [];
          _this.isLoading = false;
          _this.btnTxt = "Submit";
        })
        .catch(function() {
          _this.isLoading = false;
          _this.btnTxt = "Submit";
          // let errors = error.response.data.error || null;
          _this.responseForDialog =
            /*errors ? (typeof errors === 'string' ? errors : null
              || error.response.data.error || error.response.data.message
              || errors.contact_person ? errors.contact_person[0] : null
                || errors.email ? errors.email[0] : null
                  || errors.phone ? errors.phone[0] : null
                    || errors.date ? errors.date[0] : null
                      || errors.company_size ? errors.company_size[0] : null
                        || errors.message ? errors.message[0] : null
                          || errors.services ? errors.services[0] : null
                            || errors.source ? errors.source[0] : null) : null
            || */ "Your request couldn't be sent";
          _this.dialog_2 = true;
        })
        .then(function() {
          _this.isLoading = false;
          _this.btnTxt = "Submit";
        });
    }
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      currentPage: 1,
      totalPages: 5,
      currentMonth: "",
      currentYear: "",
      daysOfWeek: ["SU", "M", "T", "W", "TH", "F", "S"],
      dates: [],
      selectedDate: 5,
      selectedTime: "11:00am",
      appointment_action: "pick_date",
      availableTimes: [
        "09:00am",
        "10:00am",
        "11:00am",
        "12:00am",
        "01:00pm",
        "02:00pm",
        "05:00pm"
      ],
      btnTxt: "Submit Request",
      formData: {
        fullname: "",
        company: "",
        designation: "",
        email: "",
        nos: "",
        phone: "",
        industry: "",
        about_us: ""
      }
    };
  },
  mounted() {
    this.updateCalendar();
    document.title = "Kylian ERP - Blog";
  }
};
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
$new-button-color: #2081ff;
$primary-color: #069;

.appointment_highlight {
  background: #0b54c0;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: max-content;
  line-height: 30px;
}
.form_title {
  font-style: normal;
  font-weight: 400;
  width: 225px;
  font-size: 20px;
  line-height: 30px;
}
.appointment_calendar_right {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  padding-right: 40px;
  align-items: center;
  justify-content: space-between;
}
.appointment_form_right {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
.appointment_calendar_options {
  display: flex;
  gap: 20px;
}

.cal_month_year {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #001026 !important;
}
.cal_mounth {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cal_box {
  width: 67.43px;
  height: 65.38px;
  display: flex;
  gap: 0;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #fff;
}
.cal_box {
  width: 67.43px;
  height: 65.38px;
  display: flex;
  gap: 0;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #fff;
}

.cal_weeks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.cal_week {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}
.cal_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cal_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.time_option {
  border: 2px solid black;
  font-weight: 400;
  height: max-content;
  width: max-content;
  font-size: 20px;
  padding: 6px 10px;

  line-height: 30px;
  width: 105px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  cursor: pointer;
}
.time_option:hover {
  color: #fff;
  background-color: #0b54c0;
  border: 2px solid #0b54c0;
}
.time_selected {
  color: #fff;
  background-color: #0b54c0;
  border: 2px solid #0b54c0;
}
.cal_date {
  width: 67.43px;
  height: 65.38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
}
.cal_date:hover {
  background: #0b54c0;
  color: #fff;
}

.selected_date {
  background: #0b54c0;
  color: #fff;
}
.button_return {
  width: max-content;
  height: 44px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  border-radius: 5px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  border: 1px solid #0b54c0;
  color: #0b54c0;
}
.button_return:hover {
  background-color: #0b54c0;
  color: #fff;
}
.form_footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  gap: 20px;
}
.button_submit {
  display: flex;
  cursor: pointer;

  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: max-content;
  height: 44px;
  align-items: center;
  color: #fff;
  background-color: #0b54c0;
  padding: 10px 20px;
}
.cal_date:hover {
  background: #0b54c0;
  color: #fff;
}
.appointment_calendar {
  padding: 40px;
  width: 544px;
  height: 521px;
  align-items: flex-end;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(15px);
  border-radius: 10px;
}
.to_form {
  display: flex;
  cursor: pointer;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 147px;
  height: 44px;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 10px 15px;
  color: #fff;
  background-color: #0b54c0;
  border: 2px solid #0b54c0;
}
.appointment_label {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  gap: 10px;
  display: flex;
  align-items: flex-end;
}
.appointment_left {
  font-family: "Poppins";
  font-style: normal;
  color: #000000;
}
.appointment_left_title {
  font-weight: 600;
  font-size: 38px;
  line-height: 50px;
  display: flex;
}
.appointment_left_body {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.appointment_left_label {
  font-size: 24px;
  line-height: 36px;
}
.main_container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.appointment_bg {
  padding: 50px 40px;
  width: 1293px;
  height: max-content;
  background: rgba(0, 98, 255, 0.07);
}

@media (max-width: 991px) {
}
</style>

<style lang="scss"></style>
