<template>
    <v-col cols="12">
        <v-row >
            <v-col class="text-center pr-0" lg="2" md="2" cols="4">
                <img 
                style="border-radius: 100%; width: 150px; height: 150px;"
                :src="current_person.profile_image_url ? (assets_url + current_person.profile_image_url) : '/assets/images/default_profile_img.png'"
                alt="" />
            </v-col>
            <v-col class="pl-0" lg="10" md="10" cols="8">
                <h4 class="mt-4">{{ current_person.employee_fristname + ' ' + current_person.employee_lastname }}</h4>
                <p class="mb-0 mt-3">
                {{
                    current_person.employee_designation ? current_person.employee_designation : 'No designation specified'
                }} - {{ current_person.department_id ? get_dept_name(current_person.department_id) : 'No assigned department' }}</p>
                <p>
                <a :href="'mail:' + current_person.employee_email" target="_blank">
                    {{ current_person.employee_email }}
                </a>
                </p>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { ASSETS_URL } from "@/main";
export default {
    props: {
        get_dept_name: Function
    },
    computed: {
        ...mapGetters([
            'authUserData',
            'authEmployeeDataForCompany',
            'adminEmployeeData',
            'userType'
        ]),
        assets_url() {
            return ASSETS_URL;
        },
        current_person() {
            let _this = this;
            if (_this.userType != 1) {
                return _this.mixinBranchEmployees(_this.$route.name).filter(person => {
                    return person.id == _this.authEmployeeDataForCompany.id;
                })[0];
            } else {
                // return _this.mixinBranchEmployees(_this.$route.name).filter(person => {
                //     return person.user_id == _this.authUserData.id;
                // })[0];
                return _this.adminEmployeeData;
            }
        },
    }
}
</script>

<style>

</style>