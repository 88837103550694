<template>
    <div class="tab-pane fade" id="list-settings-payments" role="tabpanel" aria-labelledby="list-settings-list-payments">
        <h4>
            <div class="row">
                <div class="col-12" style="align-self: center;">
                Payment Account
                </div>
            </div>
        </h4>
        <div v-if="companyHasSubaccount" class="main-content mt-0 px-0">
            <div class="row">

                <div class="col-12">
                    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                        <div class="card-header">
                            Account Balances
                        </div>
                        <div class="row card-body text-center py-5">
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>AVAILABLE BALANCE:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ subaccountCurrency }}{{ subaccountAvailableBalance | number_with_commas }}</div>

                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>LEDGER BALANCE:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ subaccountCurrency }}{{ subaccountLedgerBalance | number_with_commas }}</div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                        <div class="card-header">
                            Account Details
                        </div>
                        <div class="row card-body text-center py-5">
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>ACCOUNT NAME:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.account_name }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>BARTER ID:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.barter_id }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>EMAIL:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.email }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>MOBILE NUMBER:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.mobilenumber }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>COUNTRY:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.country }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>ACCOUNT NUMBER:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.nuban }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>BANK NAME:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.bank_name }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>BANK CODE:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.bank_code }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>STATUS:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.status }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>DATE CREATED:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ new Date(authCompanySubaccount.created_at).toUTCString().substring(0, 16) }}</div>
                            
                            <div class="col-12 col-sm-4 col-lg-2 text-left"><strong>ACCOUNT REFERENCE:</strong></div>
                            <div class="col-12 col-sm-8 col-lg-4 text-left">{{ authCompanySubaccount.account_reference }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main-content mt-0 px-0">
            <v-row>
                <v-col cols="12" md="3">
                    <button 
                    @click="subaccountButtonLoading ? null : generateSubaccount()" 
                    :disabled="subaccountButtonLoading"
                    type="button" 
                    class="btn btn-md btn-secondary m-1 text-white btn-raised ripple w-full">
                        {{ subaccountButtonText }}
                            <v-icon
                            :style="
                                !subaccountButtonLoading
                                ? 'display: none;'
                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                            "
                            >
                                fal fa-circle-notch fa-spin
                            </v-icon>
                    </button>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters, mapActions } from 'vuex';
export default {
    props: {},
    data() {
        return {
            FLBalanceInterval: null,
            subaccountCurrency: '#',
            subaccountAvailableBalance: 0,
            subaccountLedgerBalance: 0,
            subaccountButtonText: 'Generate Subaccount',
            subaccountButtonLoading: false,

        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanySubaccount',
            'adminEmployeeData'
        ]),
        companyHasSubaccount() {
            return this.authCompanySubaccount !== null;
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_subaccount'
        ]),
        startInterval() {
            const _this = this;
            _this.FLBalanceInterval = setInterval(() => {
                _this.fetchSubaccountBalance();
            }, 30000);
        },
        fetchSubaccountBalance() {
            const _this = this;
            if (_this.companyHasSubaccount) {
                axios.post(`${BASE_URL}fetchsubaccountbalance`, {
                    account_reference: _this.authCompanySubaccount.account_reference
                })
                .then(function (RESPONSE) {
                    _this.subaccountCurrency = RESPONSE.data.subaccount.data.currency;
                    _this.subaccountAvailableBalance = RESPONSE.data.subaccount.data.available_balance;
                    _this.subaccountLedgerBalance = RESPONSE.data.subaccount.data.ledger_balance;
                })
                .catch(function (/*error*/) {
                })
                .then(function () {
                    //
                });
            }
        },
        generateSubaccount() {
            const _this = this;
            _this.subaccountButtonText = 'Generating Subaccount ';
            _this.subaccountButtonLoading = true;
            let letters = [
                "a",
                "b",
                "c",
                "d",
                "e",
                "f",
                "g",
                "h",
                "i",
                "j",
                "k",
                "l",
                "m",
                "n",
                "o",
                "p",
                "q",
                "r",
                "s",
                "t",
                "u",
                "v",
                "w",
                "x",
                "y",
                "z",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9"
            ];
            let ref = "";
            for (let i = 0; i <= 10; i++) {
                if (i === 5) {
                ref += new Date().getTime();
                continue;
                }
                ref += letters[
                Math.floor(Math.random() * letters.length)
                ].toUpperCase();
            }
            ref.slice(0, 20);

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}createsubaccount`, {
                company_id: _this.authCompanyData.id,
                account_name: _this.authCompanyData.company_name,
                email: _this.adminEmployeeData.employee_email,
                mobilenumber: _this.authCompanyData.phone,
                account_reference: ref.slice(0,20)
            })
            .then(function (RESPONSE) {
                _this.saveSubaccount(RESPONSE.data.subaccount);
            })
            .catch(function (error) {
                Toast.fire({
                    icon: "error",
                    title: `The subaccount couldn't be generated. Try again.`,
                });
                _this.subaccountButtonLoading = false;
                _this.subaccountButtonText = 'Generate Subaccount';
                console.log(error)
            })
            .then(function () {
                //
            });
        },
        saveSubaccount(SUBACCOUNT_DATA) {
        const _this = this;
        const Toast = _this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
            },
        });
        axios.post(`${BASE_URL}storesubaccount`, {
            company_id: _this.authCompanyData.id,
            account_name: SUBACCOUNT_DATA.account_name,
            barter_id: SUBACCOUNT_DATA.barter_id,
            email: SUBACCOUNT_DATA.email,
            mobilenumber: SUBACCOUNT_DATA.mobilenumber,
            country: SUBACCOUNT_DATA.country,
            nuban: SUBACCOUNT_DATA.nuban,
            bank_name: SUBACCOUNT_DATA.bank_name,
            bank_code: SUBACCOUNT_DATA.bank_code,
            status: SUBACCOUNT_DATA.status,
            created_at: SUBACCOUNT_DATA.created_at,
            account_reference: SUBACCOUNT_DATA.account_reference
        })
        .then(function () {
            Toast.fire({
                icon: "success",
                title: `Subaccount generation successful.`,
            });
            _this.fetch_company_subaccount();
            _this.subaccountButtonLoading = false;
            _this.subaccountButtonText = 'Generate Subaccount';
        })
        .catch(function () {
            Toast.fire({
                icon: "error",
                title: `The subaccount couldn't be saved. Try again.`,
            });
            _this.subaccountButtonLoading = false;
            _this.subaccountButtonText = 'Generate Subaccount';
            _this.fetch_company_subaccount();
        })
        .then(function () {
            //
        });
        },
    },
    mounted() {
        const _this = this;
        _this.fetch_company_subaccount();
        _this.fetchSubaccountBalance();
        _this.startInterval();
    },
    beforeDestroy() {
        const _this = this;
        clearInterval(_this.FLBalanceInterval);
    }
}
</script>

<style>

</style>