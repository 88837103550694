<template>
    <div>
        <!-- <SideMenu /> -->
        <div class="main-content-wrap sidenav-open d-flex flex-column">
            <div class="main-header pl-4" style="z-index: 60;">
                <div class="row">
                    <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
                        <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
                            <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"></i>
                            <span class="ml-2" style="top: 4%; position: relative;">Back</span>
                        </div>
                    </div>
                </div>
                <div style="margin: auto"></div>
                <div class="header-part-right pr-5">
                    <div class="dropdown">
                        <button class="btn ripple m-1" type="button" @click="refresh_page()"
                            :class="refreshing_page ? 'rotate-spinner' : ''">
                            <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
                        </button>
                    </div>
                    <!-- Grid menu Dropdown -->
                    <div v-if="userType == 1" class="dropdown">
                        <button @click="$router.push({ name: 'BillingHistory' })" type="button"
                            class="btn btn-outline-secondary m-1">
                            Billing History
                        </button>
                    </div>
                    <!-- Notificaiton -->
                    <div class="dropdown">
                        <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
                            @click="$router.push({ name: 'LogReport' })">
                            Log Time
                        </button>
                        <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
                            @click="$router.push({ name: 'Attendance' })">
                            Log History
                        </button>
                    </div>
                    <!-- Notificaiton End -->
                    <!-- User avatar dropdown -->
                </div>
            </div>
            <!-- ======== Body content start ========= -->
            <div class="main-content pt-0">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-lg-5 col-md-5 col-12">
                                    <h1 class="my-0">
                                        <span>{{ committee.committee_name }} ({{ department.department_name }})</span>
                                    </h1>
                                </div>
                                <v-row v-if="userType == 1" class="col-md-7 col-lg-7 col-12" style="float: right;">
                                    <v-col offset-lg="7" offset-md="7" cols="12" lg="5" md="5" class="pt-0 text-right">
                                        <button @click="add_employees_dialog = true" type="button"
                                            class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                                            Add Employees
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                            <p class="my-0 col-md-12 pt-0 pl-0">{{ committee.description }}</p>
                        </div>

                        <div class="col-md-12">
                            <div class="card" style="border-radius: 10px;">
                                <div class="card-body px-1 py-1 pt-0 pr-0">
                                    <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                          overflow-y: scroll;
                                          padding-top: 0;
                                          padding-bottom: 0;">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr style="border: 2px solid #4440;">
                                                    <th class="text-left" scope="col">Name</th>
                                                    <th class="text-left" scope="col">Email</th>
                                                    <th class="text-left" scope="col">Designation</th>
                                                    <th class="text-left" scope="col">Phone</th>
                                                    <th v-if="userType == 1" class="text-left" scope="col">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in getCommitteeMembers($route.params.id)"
                                                    :key="'secondary-employee-' + index">
                                                    <td class="text-left">{{
                                                        employeeObject(item.employee_id).employee_fristname + ' ' +
                                                        employeeObject(item.employee_id).employee_lastname
                                                    }}</td>
                                                    <td class="text-left">{{
                                                        employeeObject(item.employee_id).employee_email
                                                    }}</td>
                                                    <td class="text-left">{{
                                                        employeeObject(item.employee_id).employee_designation
                                                    }}</td>
                                                    <td v-if="employeeObject(item.employee_id).employee_phone1"
                                                        class="text-left">{{
                                                            employeeObject(item.employee_id).employee_phone1
                                                        }}</td>
                                                    <td v-else class="text-left">
                                                        <i>No phone number added</i>
                                                    </td>
                                                    <td v-if="userType == 1" class="text-left">
                                                        <i @click="removeEmployee(item.id, item.employee_id)"
                                                            class="col-3 py-0 text-center fas fa-user-minus"
                                                            style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <v-dialog v-model="add_employees_dialog" max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Add Employees</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form @submit.prevent>
                                <div class="container-fluid">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" class="remove-hint">
                                            <v-select v-model="selectedEmployees" :items="availableEmployeeNames"
                                                :menu-props="{ maxHeight: '400' }" label="Select" multiple solo dense
                                                hint="Select Employees" persistent-hint></v-select>
                                        </v-col>
                                        <v-col cols="12" offset-md="3" offset-sm="3" sm="6" md="6" class="remove-hint">
                                            <button @click="add_employees_submit ? null : add_employees_to_committee()"
                                                style="width: 99%" type="button" class="
                                                btn btn-secondary
                                                m-1
                                                text-white
                                                btn-raised
                                                ripple
                                                ">
                                                {{ add_employees_text }}
                                                <v-icon :style="!add_employees_submit
                                                        ? 'display: none;'
                                                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                    ">
                                                    fal fa-circle-notch fa-spin
                                                </v-icon>
                                            </button>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="add_employees_dialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Footer Start -->
            <div class="flex-grow-1"></div>
            <!-- fotter end -->
        </div>
    </div>
</template>
  
<script>
import { BASE_URL } from "@/main";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            selectedEmployees: [],
            add_employees_dialog: false,
            refreshing_page: false,
            add_employees_submit: false,
            add_employees_text: 'Submit'
        };
    },
    computed: {
        ...mapGetters([
            "validEmployeeId",
            "userType",
            "authCompanyData",
            "authCompanyDepartments",
            "authCompanyCommittees",
            "authEmployeeCommittees",
        ]),
        employeesToAdd() {
            let _this = this, result = [];
            result = _this.selectedEmployees.map(se => {
                return {
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.get_employee_id(se),
                    committee_id: _this.$route.params.id
                }
            })
            return result;
        },
        availableEmployees() {
            let _this = this, result = [];
            result = _this.mixinBranchEmployees(_this.$route.name).filter(employee => {
                return _this.employeeIsMember(employee.id) === false;
            });
            return result;
        },
        availableEmployeeNames() {
            let _this = this, result = [];
            result = _this.availableEmployees.map(employee => `${employee.employee_fristname} ${employee.employee_lastname}`);
            return result;
        },
        department() {
            let _this = this, result = [];
            result = _this.authCompanyDepartments.filter(d => d.id == _this.$route.params.department_id);
            if (result.length) {
                return result[0]
            }
            return null;
        },
        committee() {
            let _this = this, result = [];
            result = _this.authCompanyCommittees.filter(committee => committee.id == _this.$route.params.id);
            if (result.length) {
                return result[0]
            }
            return null;
        }
    },
    methods: {
        ...mapActions([
            "fetch_company_departments",
            "fetch_active_company_employees",
            "fetch_company_employee_committees",
            "fetch_company_committees"
        ]),
        hasHistory: function () {
            return window.history.length > 2;
        },
        refresh_page() {
            //
        },
        removeEmployee(id, employee_id) {
            const _this = this;
            const employeeObject = _this.employeeObject(employee_id);
            const Toast = _this.$swal.mixin({
                toast: !0,
                position: "top-end",
                showConfirmButton: !1,
                timer: 2e3,
                timerProgressBar: !0,
                didOpen: function (t) {
                    t.addEventListener("mouseenter", _this.$swal.stopTimer), t.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.$swal
                .fire({
                    title: `Are you sure you want to remove ${employeeObject.employee_fristname} ${employeeObject.employee_lastname} from this committee?`,
                    showCancelButton: !0,
                    confirmButtonText: "Yes, Remove",
                })
                .then(function (result) {
                    result.isConfirmed &&
                        axios
                            .post(`${BASE_URL}deleteemployeecommitee`, {
                                id
                            })
                            .then(function () {
                                Toast.fire({ icon: "success", title: "Employee removed successfully" });
                            })
                            .catch(function () {
                                Toast.fire({ icon: "error", title: "Employee couldn't be removed" });
                            })
                            .then(function () {
                                _this.fetch_company_employee_committees();
                            });
                });
        },
        add_employees_to_committee() {
            let _this = this;
            _this.add_employees_text = "";
            _this.add_employees_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            axios
                .post(`${BASE_URL}addemployeetocommittee`, {
                    employeecommittees: _this.employeesToAdd
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Employees added successfully"
                    });
                    _this.fetch_company_employee_committees();
                    _this.add_employees_dialog = false;
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: 'Employees couldn\'t be added'
                    });
                })
                .then(function () {
                    _this.add_employees_submit = false;
                    _this.add_employees_text = "Submit";
                });
        },
        get_employee_id(employee_name) {
            let _this = this, result = [], value = null;
            result = _this.mixinBranchEmployees(_this.$route.name).filter(te => (te.employee_fristname + ' ' + te.employee_lastname) == employee_name);
            if (result.length) {
                value = result[0].id;
            }
            return value;
        },
        employeeIsMember(employee_id) {
            let _this = this, truth = false;
            _this.authEmployeeCommittees.forEach(item => {
                if (item.committee_id == _this.$route.params.id && item.employee_id == employee_id) {
                    truth = true;
                }
            });
            return truth;
        },
        employeeObject(id) {
            let _this = this, result = null;
            result = _this.mixinBranchEmployees(_this.$route.name).filter(e => e.id == id);
            if (result.length >= 1) {
                result = result[0];
            }
            return result;
        },
        getCommitteeMembers(committee_id) {
            let _this = this, result = [];
            result = _this.authEmployeeCommittees.filter(item => item.committee_id == committee_id);
            return result;
        }
    },
    mounted() {
        document.title = "Kylian ERP - Committee";
        this.fetch_company_departments();
        this.fetch_active_company_employees();
        this.fetch_company_committees();
        this.fetch_company_employee_committees();
    }
};
</script>
  
<style scoped>
.underline:hover {
    text-decoration: underline;
    cursor: pointer;
}

.lead {
    font-size: 130%;
}

th,
td {
    text-align: center;
}
</style>
  
<style>
.mdi-calendar-month-outline {
    margin-right: 10px;
    color: #069 !important;
}

.remove-mb div .v-text-field__details {
    margin-bottom: 0px !important;
}
</style>
  