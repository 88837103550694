import Swal from "sweetalert2";
// import router from "./router";
import Vue from "vue";
import VueRouter from "vue-router";
// import Error404 from "../views/Error404";
import Features from "../views/Features";
import Home from "../views/Home";
import RequestDemo from "../views/RequestDemo";
import NewRequestDemo from "../views/NewRequestDemo";
import ContactUs from "../views/ContactUs";
import CoreFeatures from "../views/CoreFeatures";
import Pricing from "../views/Pricing";
import Blog from "../views/Blog";
import BlogDetails from "../views/BlogDetails";
import Appointment from "../views/Appointment";
import Register from "../views/Auth/Register";
import Login from "../views/Auth/Login";
import Verify from "../views/Auth/Verify";
import ForgotPwd from "../views/Auth/ForgotPwd";
import RecoverPwd from "../views/Auth/RecoverPwd";
import store from "@/store";
import ClientArea from "../views/User/ClientArea";
import SetupCompany from "../views/User/SetupCompany";
import MainNotifications from "../views/User/MainNotifications";
// import HumanResources from "../views/User/HR/HumanResources";
// import Accounts from "../views/User/Accounts";
// import CustomerRelations from "../views/User/CustomerRelations";
import Welcome from "../views/User/Welcome";

import Services from "../components/dashboard/Services";
import AddServices from "../components/dashboard/AddServices";

//HR
import Onboarding from "../components/dashboard/Onboarding";
import AddCandidate from "../components/dashboard/AddCandidate";
import ViewCandidate from "../components/dashboard/ViewCandidate";
import ViewEmployee from "../components/dashboard/ViewEmployee";
import EditCandidate from "../components/dashboard/EditCandidate";
import EditEmployee from "../components/dashboard/EditEmployee";
import EmployeeEditProfile from "../components/dashboard/EmployeeEditProfile";
import LogReport from "../components/dashboard/LogReport";
import Attendance from "../components/dashboard/Attendance";
import Announcements from "../components/dashboard/Announcements";
import Settings from "../components/dashboard/Settings";
import Profile from "../components/dashboard/Profile";
import BillingHistory from "../components/dashboard/BillingHistory";
import Timesheets from "../components/dashboard/Timesheets";
import Tasksheets from "../components/dashboard/Tasksheets";
import AbsenceLeave from "../components/dashboard/AbsenceLeave";
import Requisitions from "../components/dashboard/Requisitions";
import LeaveApply from "../components/dashboard/LeaveApply";
import Assets from "../components/dashboard/Assets";
import Projects from "../components/dashboard/Projects";
import Recruitment from "../components/dashboard/Recruitment";
import Applicants from "../components/dashboard/Applicants";
import FileManager from "../components/dashboard/FileManager";
import Events from "../components/dashboard/Events";
import Medicals from "../components/dashboard/Medicals";
import TravelManager from "../components/dashboard/TravelManager";
import ExitManagement from "../components/dashboard/ExitManagement";
import Payroll from "../components/dashboard/Payroll";
import Payslip from "../components/dashboard/Payslip";
import LoanManagement from "../components/dashboard/LoanManagement";
import Reports from "../components/dashboard/Reports";
import Department from "../components/dashboard/Department";
import Committee from "../components/dashboard/Committee";
import Dockets from "../components/dashboard/Dockets";
import Trainings from "../components/dashboard/Trainings";
import Performance from "../components/dashboard/Performance";
import Outsourcing from "../components/dashboard/Outsourcing";


// import Recruitment from "../views/User/HR/Recruitment";
// import SingleJobOffer from "../views/User/HR/SingleJobOffer";
// import AllJobs from "../views/User/HR/AllJobs";
// import SingleApplicantJob from "../views/User/HR/SingleApplicantJob";
// import JobApplication from "../views/User/HR/JobApplication";

// import Performances from "../views/User/HR/Performances";

import SingleEmployee from "../views/User/HR/SingleEmployee";

import TestingRoute from "@/components/TestingRoute";

import SetupCompanyDone from "@/components/SetupCompanyDone";

Vue.use(VueRouter);

const permissionIsAssigned = permission_name => {
  let truth = false, permission = null, assignedPermissions = null;
  if (store.getters["authCompanyPermissions"].length >= 1) {
    permission = store.getters["authCompanyPermissions"].filter(cp => {
      return cp.name == permission_name
    });
    if (permission.length >= 1) {
      permission = permission[0];
      assignedPermissions = store.getters["authAssignedPermissions"].filter(ap => {
        return permission.id == ap.permission_id
      });
      if (assignedPermissions.length >= 1) {
        assignedPermissions.forEach(ap => {
          if (ap.role_id == store.getters["userType"]) {
            truth = true;
          }
        });
      }
    }
  }
  return truth;
}

const AUTH_SERVICE_SELECTED = (to, from, next) => {
  if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
    return next("/login");
  }
  if (
    store.getters["authCompanyData"].is_service_selected == 0 &&
    store.getters["authUserData"].user_type == 1
  ) {
    return next("/setup-company");
  }
  if (store.getters.authSubscriptionExpired) {
    if (store.getters["authUserData"].user_type == 1) {
      Swal.fire({
        title: "Subscription expired",
        text: "Click the button below to subscribe",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Subscribe"
      }).then(result => {
        if (result.isConfirmed) {
          router.push({ name: "SetupCompany" });
        }
      });
      return next({
        name: "ClientArea",
        params: { subdomain: store.getters["authCompanyData"].subdomain }
      })
    } else {
      store.dispatch('logout_request');
      return;
    }
  }
  return next();
};

const AUTH_SERVICE_SELECTED_ROLE_CHECK = (to, from, next) => {
  if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
    return next("/login");
  }
  if (store.getters["userType"] == 1) {
    if (
      store.getters["authCompanyData"].is_service_selected == 0 &&
      store.getters["authUserData"].user_type == 1
    ) {
      return next("/setup-company");
    }
    if (store.getters.authSubscriptionExpired) {
      if (store.getters["authUserData"].user_type == 1) {
        Swal.fire({
          title: "Subscription expired",
          text: "Click the button below to subscribe",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Subscribe"
        }).then(result => {
          if (result.isConfirmed) {
            router.push({ name: "SetupCompany" });
          }
        });
        return next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      } else {
        store.dispatch('logout_request');
        return;
      }
    }
    return next();
  } else if (store.getters["userType"] != 1) {
    console.log(router);
    return next({
      name: "ClientArea",
      params: { subdomain: store.getters["authCompanyData"].subdomain }
    })
  }
};

const AUTH_SERVICE_SELECTED2 = (to, from, next) => {
  if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
    return next("/login");
  } else if (
    (store.getters["isAuth"] &&
      store.getters["authCompanyData"].is_service_selected == 0 &&
      store.getters["authUserData"].user_type == 1) ||
    store.getters.authSubscriptionExpired
  ) {
    return next();
  }
  return next({
    name: "ClientArea",
    params: { subdomain: store.getters["authCompanyData"].subdomain }
  })
};

const IS_LOGGED_IN = (to, from, next) => {
  if (store.getters["isAuth"]) {
    return next({
      name: "ClientArea",
      params: { subdomain: store.getters["authCompanyData"].subdomain }
    })
  }
  // return next();
  return next('/')
};

const routes = [
  {
    path: "/request-demo",
    name: "RequestDemo",
    component: NewRequestDemo,
    beforeEnter: function (to, from, next) {
      // return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
      //   ? 
      //     next({
      //       name: "ClientArea",
      //       params: { subdomain: store.getters["authCompanyData"].subdomain }
      //     })
      //   : next();
      if (store.getters["isAuth"] && store.getters["two_fa_confirmed"]) {
        if (store.getters["authCompanyServices"].length === 0) {
          return next();
        } else {
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else {
        return next();
      }
    }
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    beforeEnter: function (to, from, next) {
      return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
        ? next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
        : next();
    }
  },
  {
    path: "/core-features",
    name: "CoreFeatures",
    component: CoreFeatures,
    beforeEnter: function (to, from, next) {
      return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
        ? next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
        : next();
    }
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
    beforeEnter: function (to, from, next) {
      return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
        ? next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
        : next();
    }
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog
  },
  {
    path: "/blog/:slug",
    name: "BlogDetails",
    component: BlogDetails
  },
  {
    path: "/appointment",
    name: "Appointment",
    component: Appointment
  },
  {
    path: "/testing-route",
    component: TestingRoute
  },
  {
    path: "/done",
    component: SetupCompanyDone
  },
  {
    path: "/features",
    name: "Features",
    component: Features,
    beforeEnter: IS_LOGGED_IN
  },
  {
    path: "/:subdomain/request-demo",
    name: "AuthRequestDemo",
    component: RequestDemo,
    beforeEnter: function (to, from, next) {
      // return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
      //   ? 
      //     next({
      //       name: "ClientArea",
      //       params: { subdomain: store.getters["authCompanyData"].subdomain }
      //     })
      //   : next();
      if (store.getters["isAuth"] && store.getters["two_fa_confirmed"]) {
        if (store.getters["authCompanyServices"].length === 0) {
          return next();
        } else {
          if (store.getters["authCompanyServices"].length === 2) {
            return next();
          }
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else {
        return next();
      }
    }
  },
  {
    path: "/:subdomain/services",
    name: "Services",
    component: Services,
    beforeEnter: function (t, e, a) {
      return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
        ? 1 != store.getters["userType"]
          ? a({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
          : 0 == store.getters["authCompanyData"].is_service_selected &&
            1 == store.getters["authUserData"].user_type
            ? a("/setup-company")
            : store.getters.authSubscriptionExpired
              ? (Swal.fire({
                title: "Subscription expired",
                text: "Click the button below to subscribe",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Subscribe"
              }).then(function (t) {
                t.isConfirmed && a({ name: "SetupCompany" });
              }),
                a({
                  name: "ClientArea",
                  params: { subdomain: store.getters["authCompanyData"].subdomain }
                }))
              : a()
        : a("/login");
    }
  },
  {
    path: "/:subdomain/add-services",
    name: "AddServices",
    component: AddServices,
    beforeEnter: function (t, e, a) {
      if (store.getters["trial_version"]) {
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      } else {
        return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
          ? 1 != store.getters["userType"]
            ? a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
            : 0 == store.getters["authCompanyData"].is_service_selected &&
              1 == store.getters["authUserData"].user_type
              ? a("/setup-company")
              : store.getters.authSubscriptionExpired
                ? (Swal.fire({
                  title: "Subscription expired",
                  text: "Click the button below to subscribe",
                  icon: "warning",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Subscribe"
                }).then(function (t) {
                  t.isConfirmed && a({ name: "SetupCompany" });
                }),
                  a({
                    name: "ClientArea",
                    params: { subdomain: store.getters["authCompanyData"].subdomain }
                  }))
                : a()
          : a("/login");
      }
    }
  },
  {
    path: "/:subdomain/notifications",
    name: "MainNotifications",
    component: MainNotifications,
    beforeEnter: AUTH_SERVICE_SELECTED
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
    beforeEnter: function (t, e, a) {
      return store.getters["isAuth"]
        ? store.getters["isAuth"] &&
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
          ? a("/setup-company")
          : a({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        : a("/login");
    }
  },
  {
    path: "/setup-company",
    name: "SetupCompany",
    component: SetupCompany,
    beforeEnter: AUTH_SERVICE_SELECTED2
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    beforeEnter: (to, from, next) => {
      if (store.getters["isAuth"] && store.getters["two_fa_confirmed"]) {
        return next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      }
      return store.state["liveVersion"] ? next() : next();//next("/login");
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters["isAuth"] && store.getters["two_fa_confirmed"]) {
        return next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      }
      return next();
    }
  },
  {
    path: "/verifytoken/:ver_token",
    // name: "VerifyToken",
    // component: Verify,
    name: "VerifyToken",
    component: Verify,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters["isAuth"] && store.getters["two_fa_confirmed"]) {
        return next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      }
      return store.state["liveVersion"] ? next() : next();//next("/login");
    }
  },
  {
    path: "/forgotpassword",
    name: "ForgotPwd",
    component: ForgotPwd,
    beforeEnter: (to, from, next) => {
      if (store.getters["isAuth"] && store.getters["two_fa_confirmed"]) {
        return next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      }
      return next();
    }
  },
  {
    path: "/recoverpassword/:recovery_code",
    name: "RecoverPwd",
    component: RecoverPwd,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters["isAuth"] && store.getters["two_fa_confirmed"]) {
        return next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      }
      return next();
    }
  },
  {
    path: "/:subdomain/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: AUTH_SERVICE_SELECTED
  },

  // SETTINGS
  {
    path: "/:subdomain/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        // if (store.getters["authCompanyServices"].length === 2) {
        //   return next({
        //     name: "ClientArea",
        //     params: { subdomain: store.getters["authCompanyData"].subdomain }
        //   })
        // }
        return next();
      } else if (store.getters["userType"] != 1) {
        if (store.getters["authCompanyServices"].length === 2) {
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
        return next();
      }
    }
  },
  {
    path: "/:subdomain/departments/:id",
    name: "Department",
    component: Department,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        return next();
      } else if (store.getters["userType"] != 1) {
        return next();
      }
    }
  },
  {
    path: "/:subdomain/departments/:department_id/:id",
    name: "Committee",
    component: Committee,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        return next();
      } else if (store.getters["userType"] != 1) {
        return next();
      }
    }
  },
  {
    path: "/:subdomain/reports",
    name: "Reports",
    component: Reports,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        if (store.getters["authCompanyServices"].length === 2) {
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
        return next();
      } else if (store.getters["userType"] != 1) {
        // if (
        //   permissionIsAssigned('leave-type-read')
        //   ||
        //   permissionIsAssigned('assets-read')
        //   ||
        //   permissionIsAssigned('vendor-read')
        //   ||
        //   permissionIsAssigned('department-read')
        //   ||
        //   permissionIsAssigned('designation-read')
        //   ||
        //   permissionIsAssigned('ip-address-read')
        //   ||
        //   permissionIsAssigned('attendance-config-read')
        // ) {
        //   return next();
        // } else {
        //   return next({
        //     name: "ClientArea",
        //     params: { subdomain: store.getters["authCompanyData"].subdomain }
        //   });
        // }
        if (store.getters["authCompanyServices"].length === 2) {
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
        return next();
      }
    }
  },
  {
    path: "/:subdomain/billing-history",
    name: "BillingHistory",
    component: BillingHistory,
    beforeEnter: AUTH_SERVICE_SELECTED_ROLE_CHECK
  },
  // HR
  {
    path: "/:subdomain/human-resources/medicals",
    name: "Medicals",
    component: Medicals,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Medicals" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('medicals-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Medicals" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/travel-management",
    name: "Travel Management",
    component: TravelManager,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Travel Management" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('travel-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Travel Management" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/exit-management",
    name: "ExitManagement",
    component: ExitManagement,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Onboarding" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            return a();
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Onboarding" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/payroll",
    name: "Payroll",
    component: Payroll,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Payroll" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('payroll-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Payroll" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            if (permissionIsAssigned('payroll-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/payslip",
    name: "Payslip",
    component: Payslip,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Payroll" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            // if (permissionIsAssigned('payroll-read')) {
            //   return a();
            // } else {
            //   Swal.fire({
            //     title: "Access denied",
            //     text: "You do not have access to view that page",
            //     icon: "warning",
            //     confirmButtonColor: "#3085d6",
            //   })
            // }
            return a({ name: 'Payroll' });
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Payroll" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  // COMMENT: THE LOAN MANAGEMENT MODULE SHOULD HAVE DEDICATED PERMISSIONS
  {
    path: "/:subdomain/human-resources/loan-management",
    name: "Loan",
    component: LoanManagement,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Loan" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            // if (permissionIsAssigned('payroll-read')) {
            //   return a();
            // } else {
            //   Swal.fire({
            //     title: "Access denied",
            //     text: "You do not have access to view that page",
            //     icon: "warning",
            //     confirmButtonColor: "#3085d6",
            //   })
            // }
            return a();
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Loan" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            // if (permissionIsAssigned('payroll-read')) {
            //   return a();
            // } else {
            //   Swal.fire({
            //     title: "Access denied",
            //     text: "You do not have access to view that page",
            //     icon: "warning",
            //     confirmButtonColor: "#3085d6",
            //   })
            // }
            return a();
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/absence-leave",
    name: "Absence or Leave",
    component: AbsenceLeave,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            1 == store.getters["userType"] &&
            "Absence or Leave" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('leave-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"])
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
      for (
        var i = 0;
        i < store.getters["authCompanyAllBilling"].companyservices.length;
        i++
      ) {
        if (
          1 != store.getters["userType"] &&
          "Absence or Leave" ==
          store.getters["authCompanyAllBilling"].companyservices[i]
            .service_name
        )
          if (permissionIsAssigned('leave-read')) {
            return a();
          } else {
            return a({ name: "LeaveApply" });
          }
        if (
          i ==
          store.getters["authCompanyAllBilling"].companyservices.length - 1
        )
          return a({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/requisitions",
    name: "Requisitions",
    component: Requisitions,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            1 == store.getters["userType"] &&
            "Requisitions" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            // if (permissionIsAssigned('requisition-read')) {
            //   return a();
            // } else {
            //   Swal.fire({
            //     title: "Access denied",
            //     text: "You do not have access to view that page",
            //     icon: "warning",
            //     confirmButtonColor: "#3085d6",
            //   })
            // }
            if (
              s ==
              store.getters["authCompanyAllBilling"].companyservices.length - 1
            ) {
              return a({
                name: "ClientArea",
                params: { subdomain: store.getters["authCompanyData"].subdomain }
              });
            }
          return a();
        }
      } else if (1 != store.getters["userType"])
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
      for (
        var i = 0;
        i < store.getters["authCompanyAllBilling"].companyservices.length;
        i++
      ) {
        if (
          1 != store.getters["userType"] &&
          "Requisitions" ==
          store.getters["authCompanyAllBilling"].companyservices[i]
            .service_name
        )
          // if (permissionIsAssigned('requisition-read')) {
          //   return a();
          // } else {
          //   return a({
          //     name: "ClientArea",
          //     params: { subdomain: store.getters["authCompanyData"].subdomain }
          //   });
          // }
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          ) {
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
          }

        return a();
      }
    }
  },
  {
    path: "/:subdomain/human-resources/dockets",
    name: "Dockets",
    component: Dockets,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            1 == store.getters["userType"] &&
            "Dockets" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          ) {
            // if (permissionIsAssigned('docket-read')) {
            //   return a();
            // } else {
            //   Swal.fire({
            //     title: "Access denied",
            //     text: "You do not have access to view that page",
            //     icon: "warning",
            //     confirmButtonColor: "#3085d6",
            //   })
            // }
            return a();
          }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"])
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
      for (
        var i = 0;
        i < store.getters["authCompanyAllBilling"].companyservices.length;
        i++
      ) {
        if (
          1 != store.getters["userType"] &&
          "Dockets" ==
          store.getters["authCompanyAllBilling"].companyservices[i]
            .service_name
        )
          // if (permissionIsAssigned('docket-read')) {
          //   return a();
          // } else {
          //   return a({
          //     name: "ClientArea",
          //     params: { subdomain: store.getters["authCompanyData"].subdomain }
          //   });
          // }
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          ) {
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
          }
        return a();
      }
    }
  },
  {
    path: "/:subdomain/human-resources/trainings",
    name: "Trainings",
    component: Trainings,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            1 == store.getters["userType"] &&
            "Trainings" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          ) {
            // if (permissionIsAssigned('docket-read')) {
            //   return a();
            // } else {
            //   Swal.fire({
            //     title: "Access denied",
            //     text: "You do not have access to view that page",
            //     icon: "warning",
            //     confirmButtonColor: "#3085d6",
            //   })
            // }
            return a();
          }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"])
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
      for (
        var i = 0;
        i < store.getters["authCompanyAllBilling"].companyservices.length;
        i++
      ) {
        if (
          1 != store.getters["userType"] &&
          "Trainings" ==
          store.getters["authCompanyAllBilling"].companyservices[i]
            .service_name
        )
          // if (permissionIsAssigned('docket-read')) {
          //   return a();
          // } else {
          //   return a({
          //     name: "ClientArea",
          //     params: { subdomain: store.getters["authCompanyData"].subdomain }
          //   });
          // }
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          ) {
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
          }
        return a();
      }
    }
  },
  {
    path: "/:subdomain/human-resources/outsourcing",
    name: "Outsourcing",
    component: Outsourcing,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            1 == store.getters["userType"] &&
            "Outsourcing" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          ) {
            // if (permissionIsAssigned('docket-read')) {
            //   return a();
            // } else {
            //   Swal.fire({
            //     title: "Access denied",
            //     text: "You do not have access to view that page",
            //     icon: "warning",
            //     confirmButtonColor: "#3085d6",
            //   })
            // }
            return a();
          }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"])
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
      for (
        var i = 0;
        i < store.getters["authCompanyAllBilling"].companyservices.length;
        i++
      ) {
        if (
          1 != store.getters["userType"] &&
          "Outsourcing" ==
          store.getters["authCompanyAllBilling"].companyservices[i]
            .service_name
        )
          // if (permissionIsAssigned('docket-read')) {
          //   return a();
          // } else {
          //   return a({
          //     name: "ClientArea",
          //     params: { subdomain: store.getters["authCompanyData"].subdomain }
          //   });
          // }
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          ) {
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
          }
        return a();
      }
    }
  },
  {
    path: "/:subdomain/human-resources/performance",
    name: "Performance",
    component: Performance,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            1 == store.getters["userType"] &&
            "Performance" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          ) {
            // if (permissionIsAssigned('docket-read')) {
            //   return a();
            // } else {
            //   Swal.fire({
            //     title: "Access denied",
            //     text: "You do not have access to view that page",
            //     icon: "warning",
            //     confirmButtonColor: "#3085d6",
            //   })
            // }
            return a();
          }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"])
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
      for (
        var i = 0;
        i < store.getters["authCompanyAllBilling"].companyservices.length;
        i++
      ) {
        if (
          1 != store.getters["userType"] &&
          "Performance" ==
          store.getters["authCompanyAllBilling"].companyservices[i]
            .service_name
        )
          // if (permissionIsAssigned('docket-read')) {
          //   return a();
          // } else {
          //   return a({
          //     name: "ClientArea",
          //     params: { subdomain: store.getters["authCompanyData"].subdomain }
          //   });
          // }
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          ) {
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
          }
        return a();
      }
    }
  },
  {
    path: "/:subdomain/human-resources/onboarding",
    name: "Onboarding",
    component: Onboarding,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        if (permissionIsAssigned('candidate-read') || permissionIsAssigned('employee-read')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else if (store.getters["userType"] != 1) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('candidate-read') || permissionIsAssigned('employee-read')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      }
    }
  },
  {
    path: "/:subdomain/human-resources/onboarding/add-candidate",
    name: "AddCandidate",
    component: AddCandidate,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        if (permissionIsAssigned('candidate-create')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else if (store.getters["userType"] != 1) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('candidate-create')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      }
    }
  },
  {
    path: "/:subdomain/human-resources/onboarding/view-candidate/:id",
    name: "ViewCandidate",
    component: ViewCandidate,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        if (permissionIsAssigned('candidate-read')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else if (store.getters["userType"] != 1) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('candidate-read')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      }
    }
  },
  {
    path: "/:subdomain/human-resources/onboarding/view-employee/:id",
    name: "ViewEmployee",
    component: ViewEmployee,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        if (permissionIsAssigned('employee-read')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else if (store.getters["userType"] != 1) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('employee-read')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      }
    }
  },
  {
    path: "/:subdomain/human-resources/onboarding/edit-candidate/:id",
    name: "EditCandidate",
    component: EditCandidate,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        if (permissionIsAssigned('candidate-update')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else if (store.getters["userType"] != 1) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('candidate-update')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      }
    }
  },
  {
    path: "/:subdomain/human-resources/onboarding/edit-employee/:id",
    name: "EditEmployee",
    component: EditEmployee,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (store.getters["userType"] == 1) {
        if (
          store.getters["authCompanyData"].is_service_selected == 0 &&
          store.getters["authUserData"].user_type == 1
        ) {
          return next("/setup-company");
        }
        if (store.getters.authSubscriptionExpired) {
          if (store.getters["authUserData"].user_type == 1) {
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(result => {
              if (result.isConfirmed) {
                router.push({ name: "SetupCompany" });
              }
            });
            return next({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          } else {
            store.dispatch('logout_request');
            return;
          }
        }
        if (permissionIsAssigned('employee-update')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else if (store.getters["userType"] != 1) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('employee-update')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      }
    }
  },
  {
    path: "/:subdomain/profile/edit/:id",
    name: "EmployeeEditProfile",
    component: EmployeeEditProfile,
    beforeEnter: AUTH_SERVICE_SELECTED
  },
  {
    path: "/:subdomain/human-resources/leave-application",
    name: "LeaveApply",
    component: LeaveApply,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Absence or Leave" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            return a("/:subdomain/human-resources/absence-leave");
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Absence or Leave" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/timesheets",
    name: "Timesheets",
    component: Timesheets,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Timesheets" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('timesheets-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Timesheets" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/tasksheets",
    name: "Tasksheets",
    component: Tasksheets,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Tasksheets" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('tasksheets-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Tasksheets" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/recruitment",
    name: "Recruitment",
    component: Recruitment,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Recruitment" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('recruitment-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('recruitment-read')) {
          return a();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          });
          return a({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          });
        }
        // for (
        //   var i = 0;
        //   i < store.getters["authCompanyAllBilling"].companyservices.length;
        //   i++
        // ) {
        //   if (
        //     "Recruitment" ==
        //     store.getters["authCompanyAllBilling"].companyservices[i]
        //       .service_name
        //   )
        //     return a();
        //   if (
        //     i ==
        //     store.getters["authCompanyAllBilling"].companyservices.length - 1
        //   )
        //     return a({
        //       name: "ClientArea",
        //       params: { subdomain: store.getters["authCompanyData"].subdomain }
        //     });
        // }
      }
    }
  },
  {
    path: "/:subdomain/human-resources/recruitment/:id",
    name: "Applicants",
    component: Applicants,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Recruitment" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('recruitment-read') && permissionIsAssigned('applicants-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('recruitment-read') && permissionIsAssigned('applicants-read')) {
          return a();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          });
          return a({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          });
        }
        // for (
        //   var i = 0;
        //   i < store.getters["authCompanyAllBilling"].companyservices.length;
        //   i++
        // ) {
        //   if (
        //     "Recruitment" ==
        //     store.getters["authCompanyAllBilling"].companyservices[i]
        //       .service_name
        //   )
        //     return a();
        //   if (
        //     i ==
        //     store.getters["authCompanyAllBilling"].companyservices.length - 1
        //   )
        //     return a({
        //       name: "ClientArea",
        //       params: { subdomain: store.getters["authCompanyData"].subdomain }
        //     });
        // }
      }
    }
  },
  {
    path: "/:subdomain/human-resources/assets",
    name: "Assets",
    component: Assets,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Assets" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('assets-read')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Assets" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/projects",
    name: "Projects",
    component: Projects,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Projects" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            return a();
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Projects" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/file-management",
    name: "File Management",
    component: FileManager,
    beforeEnter: function (t, e, a) {
      if (1 == store.getters["userType"]) {
        if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "File Management" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            return a();
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "File Management" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },
  {
    path: "/:subdomain/human-resources/events",
    name: "Events",
    component: Events,
    beforeEnter: AUTH_SERVICE_SELECTED
  },
  {
    path: "/:subdomain/human-resources/attendance",
    name: "Attendance",
    component: Attendance,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            1 == store.getters["userType"] &&
            "Attendance" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            if (permissionIsAssigned('attendance-view')) {
              return a();
            } else {
              Swal.fire({
                title: "Access denied",
                text: "You do not have access to view that page",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"])
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
      for (
        var i = 0;
        i < store.getters["authCompanyAllBilling"].companyservices.length;
        i++
      ) {
        if (
          1 != store.getters["userType"] &&
          "Attendance" ==
          store.getters["authCompanyAllBilling"].companyservices[i]
            .service_name
        )
          if (permissionIsAssigned('attendance-view')) {
            return a();
          } else {
            return a({ name: "LogReport" });
          }
        if (
          i ==
          store.getters["authCompanyAllBilling"].companyservices.length - 1
        )
          return a({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          });
      }
    }
  },

  {
    path: "/:subdomain/human-resources/announcements",
    name: "Announcements",
    component: Announcements,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (
        store.getters["authCompanyData"].is_service_selected == 0 &&
        store.getters["authUserData"].user_type == 1
      ) {
        return next("/setup-company");
      }
      if (store.getters.authSubscriptionExpired) {
        if (store.getters["authUserData"].user_type == 1) {
          Swal.fire({
            title: "Subscription expired",
            text: "Click the button below to subscribe",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Subscribe"
          }).then(result => {
            if (result.isConfirmed) {
              router.push({ name: "SetupCompany" });
            }
          });
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        } else {
          store.dispatch('logout_request');
          return;
        }
      }
      if (store.getters["authCompanyServices"].length === 2) {
        return next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      }
      if (permissionIsAssigned('announcement-read')) {
        return next();
      } else {
        Swal.fire({
          title: "Access denied",
          text: "You do not have access to view that page",
          icon: "warning",
          confirmButtonColor: "#3085d6",
        });
        return next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
      }
    }
  },

  {
    path: "/:subdomain/human-resources/employees/:employee_id",
    name: "SingleEmployee",
    component: SingleEmployee,
    beforeEnter: (to, from, next) => {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) {
        return next("/login");
      }
      if (
        store.getters["authCompanyData"].is_service_selected == 0 &&
        store.getters["authUserData"].user_type == 1
      ) {
        return next("/setup-company");
      }
      if (store.getters.authSubscriptionExpired) {
        if (store.getters["authUserData"].user_type == 1) {
          Swal.fire({
            title: "Subscription expired",
            text: "Click the button below to subscribe",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Subscribe"
          }).then(result => {
            if (result.isConfirmed) {
              router.push({ name: "SetupCompany" });
            }
          });
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        } else {
          store.dispatch('logout_request');
          return;
        }
      }
      if (store.getters["userType"] == 1) {
        if (permissionIsAssigned('employee-read')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      } else {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        if (permissionIsAssigned('employee-read')) {
          return next();
        } else {
          Swal.fire({
            title: "Access denied",
            text: "You do not have access to view that page",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          })
          return next({
            name: "ClientArea",
            params: { subdomain: store.getters["authCompanyData"].subdomain }
          })
        }
      }
    }
  },

  {
    path: "/:subdomain/log-report",
    name: "LogReport",
    component: LogReport,
    beforeEnter: function (t, e, a) {
      if (!store.getters["isAuth"] || !store.getters["two_fa_confirmed"]) return a("/login");
      if (1 == store.getters["userType"]) {
        if (
          0 == store.getters["authCompanyData"].is_service_selected &&
          1 == store.getters["authUserData"].user_type
        )
          return a("/setup-company");
        if (store.getters.authSubscriptionExpired)
          return (
            Swal.fire({
              title: "Subscription expired",
              text: "Click the button below to subscribe",
              icon: "warning",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe"
            }).then(function (t) {
              t.isConfirmed && a({ name: "SetupCompany" });
            }),
            a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            })
          );
        for (
          var s = 0;
          s < store.getters["authCompanyAllBilling"].companyservices.length;
          s++
        ) {
          if (
            "Attendance" ==
            store.getters["authCompanyAllBilling"].companyservices[s]
              .service_name
          )
            return a("/:subdomain/human-resources/attendance");
          if (
            s ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
      } else if (1 != store.getters["userType"]) {
        if (store.getters.authSubscriptionExpired) {
          store.dispatch('logout_request');
          return;
        }
        for (
          var i = 0;
          i < store.getters["authCompanyAllBilling"].companyservices.length;
          i++
        ) {
          if (
            "Attendance" ==
            store.getters["authCompanyAllBilling"].companyservices[i]
              .service_name
          )
            return a();
          if (
            i ==
            store.getters["authCompanyAllBilling"].companyservices.length - 1
          )
            return a({
              name: "ClientArea",
              params: { subdomain: store.getters["authCompanyData"].subdomain }
            });
        }
        return a({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        });
      }
    }
  },

  {
    path: "/:subdomain",
    alias: "/dashboard",
    name: "ClientArea",
    component: ClientArea,
    beforeEnter: function (t, e, a) {
      return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
        ? 1 == store.getters["authUserData"].user_type &&
          0 == store.getters["authCompanyData"].is_service_selected
          ? a("/setup-company")
          : t.params.subdomain == store.getters["authCompanyData"].subdomain
            ? a()
            : a({ name: "Error404" })
        : a("/login");
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: function (to, from, next) {
      return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
        ? next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
        : next();
    }
  },
  {
    path: "*",
    beforeEnter: function (to, from, next) {
      return store.getters["isAuth"] && store.getters["two_fa_confirmed"]
        ? next({
          name: "ClientArea",
          params: { subdomain: store.getters["authCompanyData"].subdomain }
        })
        : next({
          name: "Home"
        });
    }
  },
];

const router = new VueRouter({
  scrollBehavior: function () {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
