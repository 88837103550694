<template>
  <v-overlay v-if="overlay" :value="overlay">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <div v-else>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div
            style="align-self: center;"
            class="logo col-md-2 col-lg-2 col-12 py-0"
          >
            <div
              class="dropdown animate__animated animate__fadeIn"
              style="font-size: 120%;"
            >
              <i
                @click="
                  $router.push({
                    name: 'Onboarding',
                    query: { view: 'employee' }
                  })
                "
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"
              ></i>
              <span class="ml-2" style="top: 4%; position: relative;"
                >Employees</span
              >
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div class="dropdown animate__animated animate__fadeIn">
            <i
              @click="
                $router.push({
                  name: 'Onboarding',
                  query: { view: 'employee' }
                })
              "
              style="cursor: pointer; font-size: 120%; position: relative; top: 2px;"
              class="i-Close mr-1"
            ></i>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div
        class="main-content"
        :class="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? 'px-0'
            : null
        "
      >
        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <div class="d-block">
            <h1>Employee Details</h1>
          </div>
        </div>
        <v-row>
          <v-col class="text-center pr-0" lg="2" md="2" cols="4">
            <img
              style="border-radius: 100%;"
              class="w-75"
              :src="
                employee.profile_image_url
                  ? assets_url + employee.profile_image_url
                  : '/assets/images/default_profile_img.png'
              "
              alt=""
            />
          </v-col>
          <v-col class="pl-0" lg="10" md="10" cols="8">
            <h4 class="mt-4">
              {{
                employee.employee_fristname + " " + employee.employee_lastname
              }}
            </h4>
            <p class="mb-0 mt-3">
              {{
                employee.employee_designation
                  ? employee.employee_designation
                  : "No designation specified"
              }}
              -
              {{
                employee.department_id
                  ? get_dept_name(employee.department_id)
                  : "No assigned department"
              }}
            </p>
            <p>
              <a :href="'mail:' + employee.employee_email" target="_blank">
                {{ employee.employee_email }}
              </a>
            </p>
            <p class="mb-0 py-2 mr-5">
              <span
                style="cursor: pointer;"
                @click="
                  $router.push({
                    name: 'EditEmployee',
                    params: { id: employee.id }
                  })
                "
                ><i class="fas fa-edit"></i> Edit</span
              >
            </p>
          </v-col>
          <v-col lg="12" md="12" class="pt-5">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active show"
                  id="general-info-tab"
                  data-toggle="tab"
                  href="#generalInfoTab"
                  role="tab"
                  aria-controls="generalInfoTab"
                  aria-selected="true"
                  >General Info</a
                >
              </li>
              <!-- 
              <li v-if="permissionIsAssigned('leave-read') && check_all_services('Absence or Leave')" class="nav-item">
                <a class="nav-link" id="leave-tab" data-toggle="tab" href="#leaveTab" role="tab"
                  aria-controls="leaveTab" aria-selected="false">Leave</a>
              </li>
              <li v-if="permissionIsAssigned('assets-read') && check_all_services('Assets')" class="nav-item"><a
                  class="nav-link" id="assets-tab" data-toggle="tab" href="#assetsTab" role="tab"
                  aria-controls="assetsTab" aria-selected="false">Assets</a></li>
              <li v-if="permissionIsAssigned('timesheets-read') && check_all_services('Timesheets')" class="nav-item"><a
                  class="nav-link" id="timesheets-tab" data-toggle="tab" href="#timesheetsTab" role="tab"
                  aria-controls="timesheetsTab" aria-selected="false">Timesheets</a></li>
              <li v-if="permissionIsAssigned('payroll-read') && check_all_services('Payroll')" class="nav-item"><a
                  class="nav-link" id="payslip-tab" data-toggle="tab" href="#payslipTab" role="tab"
                  aria-controls="payslipTab" aria-selected="false">Payroll</a></li>
              <li v-if="permissionIsAssigned('attendance-view') && check_all_services('Attendance')" class="nav-item"><a
                  @click="run_attendance()" class="nav-link" id="attendance-tab" data-toggle="tab" href="#attendanceTab"
                  role="tab" aria-controls="attendanceTab" aria-selected="false">Attendance</a></li>
              -->
              <li
                v-if="
                  permissionIsAssigned('payroll-approve') &&
                    check_all_services('Payroll')
                "
                class="nav-item"
              >
                <a
                  class="nav-link"
                  id="paygrade-tab"
                  data-toggle="tab"
                  href="#paygradeTab"
                  role="tab"
                  aria-controls="paygradeTab"
                  aria-selected="false"
                  >Pay Grade</a
                >
              </li>
              <li
                v-if="
                  (permissionIsAssigned('payroll-create') ||
                    permissionIsAssigned('payroll-approve')) &&
                    check_all_services('Payroll')
                "
                class="nav-item"
              >
                <a
                  class="nav-link"
                  id="accountdetails-tab"
                  data-toggle="tab"
                  href="#accountdetailsTab"
                  role="tab"
                  aria-controls="accountdetailsTab"
                  aria-selected="false"
                  >Account Details</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="documents-tab"
                  data-toggle="tab"
                  href="#documentsTab"
                  role="tab"
                  aria-controls="documentsTab"
                  aria-selected="false"
                  >Documents</a
                >
              </li>
              <li v-if="hasValidExitRequest" class="nav-item">
                <a
                  class="nav-link"
                  id="exit-checklists-tab"
                  data-toggle="tab"
                  href="#exitChecklistsTab"
                  role="tab"
                  aria-controls="exitChecklistsTab"
                  aria-selected="false"
                  >Exit Checklists</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="payroll-allowance-tab"
                  data-toggle="tab"
                  href="#payrollAllowanceTab"
                  role="tab"
                  aria-controls="payrollAllowanceTab"
                  aria-selected="false"
                  >Payroll Allowance</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="generalInfoTab"
                role="tabpanel"
                aria-labelledby="general-info-tab"
              >
                <div class="row">
                  <div class="col-md-4 col-lg-4 col-12">
                    <div class="card mb-4" style="border-top: 5px solid #069;">
                      <div class="card-body py-3">
                        <h5 class="card-title" style="font-weight: bold;">
                          Basic Info
                        </h5>
                        <table
                          class="table"
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'table-responsive'
                              : null
                          "
                        >
                          <thead>
                            <tr>
                              <td scope="col">Employee ID</td>
                              <td scope="col">{{ employee.employee_id }}</td>
                            </tr>
                            <tr>
                              <td scope="col">Last Name</td>
                              <td scope="col">
                                {{ employee.employee_lastname }}
                              </td>
                            </tr>
                            <tr v-if="employee.user_type != 1">
                              <td scope="col">Added By</td>
                              <td scope="col">Admin</td>
                            </tr>
                            <!-- <tr>
                                              <td scope="col">Added Time</td>
                                              <td scope="col">{{ new Date(authEmployeeDataForCompany.created_at).toUTCString().substring(0, 16) }}</td>
                                            </tr> -->
                            <tr>
                              <td scope="col">First Name</td>
                              <td scope="col">
                                {{ employee.employee_fristname }}
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Email ID</td>
                              <td scope="col">{{ employee.employee_email }}</td>
                            </tr>
                            <!-- <tr>
                                    <td scope="col">Nick Name</td>
                                    <td v-if="employee.employee_nickname" scope="col">
                                      {{ employee.employee_nickname }}
                                    </td>
                                    <td v-else>
                                      <i>No nickname specified</i>
                                    </td>
                                  </tr> -->
                            <tr>
                              <td scope="col">Sex</td>
                              <td scope="col">
                                {{ employee.employee_sex }}
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Employment Type</td>
                              <td
                                v-if="employee.employment_type == 1"
                                scope="col"
                              >
                                Full-time staff
                              </td>
                              <td v-else>
                                Intern
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4 col-12">
                    <div class="card mb-4" style="border-top: 5px solid #069;">
                      <div class="card-body py-3">
                        <h5 class="card-title" style="font-weight: bold;">
                          Work
                        </h5>
                        <table
                          class="table"
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'table-responsive'
                              : null
                          "
                        >
                          <thead>
                            <tr v-if="employee.user_type != 1">
                              <td scope="col">Department</td>
                              <td v-if="employee.department_id" scope="col">
                                {{ get_dept_name(employee.department_id) }}
                              </td>
                              <td v-else>
                                <i>
                                  Not assigned a department
                                </i>
                              </td>
                            </tr>
                            <tr v-if="employee.user_type != 1">
                              <td scope="col">Designation</td>
                              <td
                                v-if="employee.employee_designation"
                                scope="col"
                              >
                                {{ employee.employee_designation }}
                              </td>
                              <td v-else>
                                <i>
                                  No designation
                                </i>
                              </td>
                            </tr>
                            <tr v-if="employee.user_type != 1">
                              <td scope="col">Source of Hire</td>
                              <td v-if="employee.source_of_hire" scope="col">
                                {{ employee.source_of_hire }}
                              </td>
                              <td v-else>
                                <i>
                                  No specified source of hire
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Date of joining</td>
                              <td scope="col">
                                {{
                                  new Date(employee.created_at)
                                    .toUTCString()
                                    .substring(0, 16)
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left" scope="col">Pay Grade</td>
                              <td
                                v-if="currentPayGrade"
                                class="text-left"
                                scope="col"
                              >
                                {{ currentPayGrade.grade_name }}
                              </td>
                              <td v-else class="text-left" scope="col">
                                <i>No specified pay grade</i>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left" scope="col">Grade Level</td>
                              <td
                                v-if="currentGradeLevel"
                                class="text-left"
                                scope="col"
                              >
                                {{ currentGradeLevel.gradelevel_name }}
                              </td>
                              <td v-else class="text-left" scope="col">
                                <i>No specified grade level</i>
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Work phone</td>
                              <td scope="col">
                                {{ employee.employee_phone1 }}
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Role</td>
                              <td scope="col">
                                {{
                                  employee.user_type == 1
                                    ? "Admin"
                                    : employee.user_type == 2
                                    ? "Staff"
                                    : "HR"
                                }}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4 col-12">
                    <div class="card mb-4" style="border-top: 5px solid #069;">
                      <div class="card-body py-3">
                        <h5 class="card-title" style="font-weight: bold;">
                          Personal
                        </h5>
                        <table
                          class="table"
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'table-responsive'
                              : null
                          "
                        >
                          <thead>
                            <tr>
                              <td scope="col">Mobile Phone</td>
                              <td scope="col">
                                {{ employee.employee_phone1 }}
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Other Email</td>
                              <td
                                v-if="employee.employee_officialemail"
                                scope="col"
                              >
                                {{ employee.employee_officialemail }}
                              </td>
                              <td v-else>
                                <i>
                                  No other email
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Birth Date</td>
                              <td
                                v-if="employee.employee_date_of_birth"
                                scope="col"
                              >
                                {{ employee.employee_date_of_birth }}
                              </td>
                              <td v-else>
                                <i>
                                  No date of birth
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Marital Status</td>
                              <td
                                v-if="employee.employee_maritalstatus"
                                scope="col"
                              >
                                {{ employee.employee_maritalstatus }}
                              </td>
                              <td v-else>
                                Single
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Address</td>
                              <td v-if="employee.employee_address" scope="col">
                                {{ employee.employee_address }}
                              </td>
                              <td v-else>
                                <i>
                                  No address specified
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">Country</td>
                              <td
                                v-if="employee.employee_nationality"
                                scope="col"
                              >
                                {{ employee.employee_nationality }}
                              </td>
                              <td v-else>
                                <i>
                                  No address specified
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">State</td>
                              <td v-if="employee.employee_state" scope="col">
                                {{ employee.employee_state }}
                              </td>
                              <td v-else>
                                <i>
                                  No address specified
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td scope="col">City/LGA</td>
                              <td
                                v-if="employee.employee_local_government"
                                scope="col"
                              >
                                {{ employee.employee_local_government }}
                              </td>
                              <td v-else>
                                <i>
                                  No address specified
                                </i>
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card mb-4" style="border-top: 5px solid #069;">
                      <div class="card-body py-3">
                        <h5 class="card-title" style="font-weight: bold;">
                          Education
                        </h5>
                        <table
                          class="table"
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'table-responsive'
                              : null
                          "
                        >
                          <thead>
                            <tr>
                              <th scope="col">School Name</th>
                              <th scope="col">Degree/Diploma</th>
                              <th scope="col">Field(s) of Study</th>
                              <th scope="col">Date of Completion</th>
                              <th scope="col">Additional Notes</th>
                            </tr>
                            <tr
                              v-for="(education,
                              index) in candidateSpecificEducation"
                              :key="index"
                            >
                              <td scope="col">{{ education.school_name }}</td>
                              <td scope="col">
                                {{ education.degree_diploma }}
                              </td>
                              <td scope="col">
                                {{ education.field_of_study }}
                              </td>
                              <td scope="col">
                                {{ education.date_of_completion }}
                              </td>
                              <td scope="col">
                                {{ education.additional_notes }}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="employee.user_type != 1">
                    <div class="card mb-4" style="border-top: 5px solid #069;">
                      <div class="card-body py-3">
                        <h5 class="card-title" style="font-weight: bold;">
                          Work experience
                        </h5>
                        <table
                          class="table"
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'table-responsive'
                              : null
                          "
                        >
                          <thead>
                            <tr>
                              <th scope="col">Occupation</th>
                              <th scope="col">Company</th>
                              <th scope="col">Summary</th>
                              <th scope="col">Duration</th>
                              <th scope="col">Currently Work Here</th>
                            </tr>
                            <tr
                              v-for="(experience,
                              index) in candidateSpecificExperience"
                              :key="index"
                            >
                              <td scope="col">{{ experience.occupation }}</td>
                              <td scope="col">{{ experience.company }}</td>
                              <td scope="col">{{ experience.summary }}</td>
                              <td scope="col">{{ experience.duration }}</td>
                              <td scope="col">
                                {{ experience.currently_work_here }}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="permissionIsAssigned('leave-read')"
                class="tab-pane fade"
                id="leaveTab"
                role="tabpanel"
                aria-labelledby="leavetab"
              >
                <div class="card" style="border-radius: 10px;">
                  <div class="card-body px-1 py-1 pt-0 pr-0">
                    <div
                      class="table-responsive"
                      style="border-radius: 10px; height: 75vh;
                                                overflow-y: scroll;
                                                padding-top: 0;
                                                padding-bottom: 0;"
                    >
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="text-left" scope="col">Employee Name</th>
                            <th class="text-left" scope="col">Start Date</th>
                            <th class="text-left" scope="col">End Date</th>
                            <th class="text-left" scope="col">Days Left</th>
                            <th class="text-left" scope="col">Leave Type</th>
                            <th class="text-center" scope="col">Status</th>
                            <th class="text-left" scope="col">Comments</th>
                            <th
                              class="text-left"
                              v-if="filter == 1"
                              scope="col"
                              style="width:"
                            >
                              Actions
                            </th>
                            <th
                              v-else-if="filter == 2"
                              class="text-left"
                              scope="col"
                              style="width:"
                            >
                              Disapprove
                            </th>
                            <th
                              v-else
                              class="text-left"
                              scope="col"
                              style="width:"
                            >
                              Approve
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="leave in employee_leave" :key="leave.id">
                            <td class="text-left">
                              {{
                                employee.employee_fristname +
                                  " " +
                                  employee.employee_lastname
                              }}
                            </td>
                            <td class="text-left">{{ leave.leave_start }}</td>
                            <td class="text-left">{{ leave.leave_end }}</td>
                            <td class="text-left">
                              {{ fetch_days_left(leave) }}
                            </td>
                            <td class="text-left">
                              {{ get_leave_type_name(leave.leave_type) }}
                            </td>
                            <td class="text-center" v-if="leave.status == 0">
                              <span
                                style="width: 60px;"
                                class="badge badge-danger text-white"
                                >DENIED</span
                              >
                            </td>
                            <td
                              class="text-center"
                              v-else-if="leave.status == 1"
                            >
                              <span
                                style="width: 60px;"
                                class="badge badge-success"
                                >APPROVED</span
                              >
                            </td>
                            <td class="text-center" v-else>
                              <span
                                style="width: 60px;"
                                class="badge badge-warning text-white"
                                >PENDING</span
                              >
                            </td>
                            <td
                              class="text-left"
                              v-if="leave.additional_comments"
                            >
                              {{ leave.additional_comments }}
                            </td>
                            <td class="text-left" v-else><i>No comment</i></td>
                            <td
                              style="padding-top: 13px;"
                              class="employee-options text-left"
                            >
                              <div class="row">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <i
                                      v-bind="attrs"
                                      v-on="on"
                                      class="col-3 py-0 text-center fas fa-check mr-2 mt-3"
                                      @click="approve_single_leave(leave)"
                                      style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                  </template>
                                  <span>Approve request</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <i
                                      v-bind="attrs"
                                      v-on="on"
                                      class="col-3 py-0 text-center fas fa-times ml-2 mt-3"
                                      @click="disapprove_single_leave(leave)"
                                      style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                  </template>
                                  <span>Deny request</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="permissionIsAssigned('assets-read')"
                class="tab-pane fade"
                id="assetsTab"
                role="tabpanel"
                aria-labelledby="assets-tab"
              >
                <div class="card w-100" style="border-radius: 10px;">
                  <div class="card-body px-1 py-1 pt-0 pr-0">
                    <div
                      class="table-responsive"
                      style="border-radius: 10px; height: 75vh;
                                                overflow-y: scroll;
                                                padding-top: 0;
                                                padding-bottom: 0;"
                    >
                      <table class="table">
                        <thead>
                          <tr>
                            <th style="border-top: none;" scope="col">
                              Asset Name
                            </th>
                            <th style="border-top: none;" scope="col">
                              Description
                            </th>
                            <th style="border-top: none;" scope="col">
                              Details
                            </th>
                            <th style="border-top: none;" scope="col">
                              Asset Type
                            </th>
                            <th style="border-top: none;" scope="col">
                              Given Date
                            </th>
                            <th style="border-top: none;" scope="col">
                              Return Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="asset in personal_assets" :key="asset.id">
                            <td>{{ asset.asset_name }}</td>
                            <td>{{ asset.asset_description }}</td>
                            <td v-if="asset.asset_details">
                              {{ asset.asset_details }}
                            </td>
                            <td v-else><i>No details</i></td>
                            <td>{{ get_type_name(asset.assettype_id) }}</td>
                            <td>{{ asset.given_date }}</td>
                            <td>{{ asset.return_date }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="tab-pane fade" id="filesTab" role="tabpanel" aria-labelledby="files-tab">
                      <div class="card w-100" style="border-radius: 10px;">
                        <div class="card-body px-1 py-1 pt-0 pr-0">
                          <div
                            class="table-responsive"
                            style="border-radius: 10px; height: 75vh;
                                                overflow-y: scroll;
                                                padding-top: 0;
                                                padding-bottom: 0;"
                          >
                            <table class="table">
                              <thead>
                                <tr>
                                  <th style="border-top: none;" scope="col">File Name</th>
                                  <th style="border-top: none;" scope="col">Description</th>
                                  <th style="border-top: none;" scope="col">Details</th>
                                  <th style="border-top: none;" scope="col">File Type</th>
                                  <th style="border-top: none;" scope="col">Given Date</th>
                                </tr>
                              </thead>
                              <tbody>
                              </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                  </div> -->
              <div
                v-if="permissionIsAssigned('timesheets-read')"
                class="tab-pane fade"
                id="timesheetsTab"
                role="tabpanel"
                aria-labelledby="timesheets-tab"
              >
                <div
                  class="row"
                  :style="
                    $vuetify.breakpoint.name == 'xs'
                      ? 'width: 105%;'
                      : 'width: 100%;'
                  "
                >
                  <h1 class="my-0 row pb-0">
                    <span class="col-12 col-lg-4 col-md-4"></span>
                    <v-row
                      class="col-lg-8 col-md-8 col-12"
                      style="float: right;"
                    >
                      <v-col
                        :offset-md="
                          filtered_timesheets.length >= 1 &&
                          yearSelected &&
                          monthSelected
                            ? '4'
                            : '6'
                        "
                        :offset-lg="
                          filtered_timesheets.length >= 1 &&
                          yearSelected &&
                          monthSelected
                            ? '4'
                            : '6'
                        "
                        md="3"
                        lg="3"
                        cols="6"
                        class="pt-0"
                      >
                        <v-select
                          class="remove-mb"
                          :items="allYears"
                          label="Year"
                          dense
                          solo
                          messages=" *Filter by year"
                          :full-width="true"
                          v-model="yearSelected"
                        ></v-select>
                      </v-col>
                      <v-col lg="3" md="3" cols="6" class="pt-0">
                        <v-select
                          class="remove-mb"
                          :items="monthNames"
                          label="Month"
                          dense
                          solo
                          messages="*Filter by month"
                          :full-width="true"
                          v-model="monthSelected"
                        ></v-select>
                      </v-col>
                      <v-col
                        v-if="
                          filtered_timesheets.length >= 1 &&
                            yearSelected &&
                            monthSelected
                        "
                        cols="2"
                        class="pt-0"
                      >
                        <download-excel
                          :data="json_data"
                          :fields="json_fields"
                          :worksheet="
                            employee.employee_fristname +
                              ' ' +
                              employee.employee_lastname +
                              ' - ' +
                              monthSelected +
                              ' ' +
                              yearSelected
                          "
                          :name="
                            'Timesheet for ' +
                              employee.employee_fristname +
                              ' ' +
                              employee.employee_lastname +
                              ' (' +
                              monthSelected +
                              ' ' +
                              yearSelected +
                              ').xls'
                          "
                          class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                        >
                          Export to Excel
                        </download-excel>
                      </v-col>
                    </v-row>
                  </h1>
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="card"
                        :style="
                          monthSelected &&
                          yearSelected &&
                          monthly_status == 'approved'
                            ? 'border-radius: 10px; border: 4px solid #4caf50d4;'
                            : monthSelected &&
                              yearSelected &&
                              monthly_status == 'disapproved'
                            ? 'border-radius: 10px; border: 4px solid #f44336e0;'
                            : monthSelected &&
                              yearSelected &&
                              monthly_status == 'pending'
                            ? 'border-radius: 10px; border: 4px solid #ffc107db;'
                            : ''
                        "
                      >
                        <div
                          v-if="monthSelected && yearSelected"
                          class="card-header bg-white"
                        >
                          <span class="">
                            Status:
                            <span
                              :class="
                                monthly_status == 'approved'
                                  ? 'badge badge-square badge-square-opacity-success m-1 sm'
                                  : monthly_status == 'disapproved'
                                  ? 'badge badge-square badge-square-opacity-danger m-1 sm'
                                  : 'badge badge-square badge-square-opacity-warning m-1 sm'
                              "
                            >
                              <i
                                v-if="monthly_status == 'approved'"
                                class="fas fa-check"
                              ></i>
                              <i
                                v-else-if="monthly_status == 'disapproved'"
                                class="fas fa-times"
                              ></i>
                              <i v-else class="fas fa-hourglass"></i>
                            </span>
                          </span>
                          <span
                            class=""
                            style="top: 0px; position: relative; margin-left: 10px;"
                          >
                            Days Worked: {{ filtered_timesheets.length }}
                          </span>
                          <div class="float-right">
                            <button
                              v-if="monthly_status == 'disapproved'"
                              type="button"
                              @click="
                                approve_timesheet_submit
                                  ? null
                                  : approve_timesheets()
                              "
                              class="btn btn-raised ripple btn-raised-secondary m-1 text-white float-right"
                            >
                              {{ approve_timesheet_text }}
                              <v-icon
                                :style="
                                  !approve_timesheet_submit
                                    ? 'display: none;'
                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                "
                              >
                                fas fa-circle-notch fa-spin
                              </v-icon>
                            </button>
                            <button
                              v-if="monthly_status == 'approved'"
                              type="button"
                              @click="
                                disapprove_timesheet_submit
                                  ? null
                                  : disapprove_timesheets()
                              "
                              class="btn btn-raised ripple btn-raised-secondary m-1 text-white float-right"
                            >
                              {{ disapprove_timesheet_text }}
                              <v-icon
                                :style="
                                  !disapprove_timesheet_submit
                                    ? 'display: none;'
                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                "
                              >
                                fas fa-circle-notch fa-spin
                              </v-icon>
                            </button>
                            <button
                              class="btn btn-outline-secondary m-1 float-right"
                              type="button"
                              @click="timesheet_comment = true"
                            >
                              Comment
                            </button>
                          </div>
                        </div>
                        <div class="card-body px-1 py-1 pt-0 pr-0">
                          <div
                            class="table-responsive"
                            style="border-radius: 10px; height: 75vh;
                                                      overflow-y: scroll;
                                                      padding-top: 0;
                                                      padding-bottom: 0;"
                          >
                            <table class="table table-hover">
                              <thead>
                                <tr style="border: 2px solid #4440;">
                                  <th class="text-left" scope="col">Date</th>
                                  <th class="text-left" scope="col">
                                    Employee Name
                                  </th>
                                  <th class="text-left" scope="col">Task</th>
                                  <!-- <th scope="col">Task Details</th> -->
                                  <th class="text-left" scope="col">
                                    Work Done
                                  </th>
                                  <th class="text-left" scope="col">
                                    Place of Performance
                                  </th>
                                  <th class="text-left" scope="col">
                                    Claimed Expenses
                                  </th>
                                  <th class="text-left" scope="col">
                                    Advanced Payment
                                  </th>
                                  <th class="text-left" scope="col">
                                    Reports Submitted
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="timesheet in filtered_timesheets"
                                  :key="timesheet.id"
                                >
                                  <th
                                    @click="set_date(timesheet.date)"
                                    class="underline text-left"
                                    scope="row"
                                  >
                                    {{
                                      new Date(timesheet.date)
                                        .toUTCString()
                                        .substring(0, 16)
                                    }}
                                  </th>
                                  <th class="underline text-left" scope="row">
                                    {{
                                      timesheet.employee_fristname +
                                        " " +
                                        timesheet.employee_lastname
                                    }}
                                  </th>

                                  <td class="text-left">
                                    {{ timesheet.project_title }}
                                  </td>

                                  <!-- <td>{{ timesheet.details }}</td> -->

                                  <td class="text-left">
                                    {{ timesheet.work_done }}
                                  </td>

                                  <td class="text-left">
                                    {{ timesheet.place_performance }}
                                  </td>

                                  <td
                                    class="text-left"
                                    v-if="timesheet.claimed_expenses"
                                  >
                                    ₦{{
                                      timesheet.claimed_expenses
                                        | number_with_commas
                                    }}
                                  </td>
                                  <td class="text-left" v-else>
                                    <i>No claimed expenses</i>
                                  </td>

                                  <td
                                    class="text-left"
                                    v-if="timesheet.payment_advanced"
                                  >
                                    ₦{{
                                      timesheet.payment_advanced
                                        | number_with_commas
                                    }}
                                  </td>
                                  <td class="text-left" v-else>
                                    <i>No advance payment</i>
                                  </td>

                                  <td
                                    class="text-left"
                                    v-if="timesheet.report_submitted"
                                  >
                                    {{ timesheet.report_submitted }}
                                  </td>
                                  <td class="text-left" v-else>
                                    <i>No reports</i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="permissionIsAssigned('payroll-read')"
                class="tab-pane fade"
                id="payslipTab"
                role="tabpanel"
                aria-labelledby="payslip-tab"
              >
                <div class="card w-100" style="border-radius: 10px;">
                  <div class="card-body px-1 py-1 pt-0 pr-0">
                    <div
                      class="table-responsive"
                      style="border-radius: 10px; height: 75vh;
                                                overflow-y: scroll;
                                                padding-top: 0;
                                                padding-bottom: 0;"
                    >
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="text-left" scope="col">Name</th>
                            <th class="text-left" scope="col">Amount</th>
                            <th class="text-left" scope="col">Type</th>
                            <th class="text-left" scope="col">Action</th>
                            <th class="text-right py-1">
                              <button
                                @click="add_item_dialog = true"
                                type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                :class="
                                  $vuetify.breakpoint.name == 'sm' ||
                                  $vuetify.breakpoint.name == 'xs'
                                    ? 'w-100'
                                    : null
                                "
                              >
                                Add Item
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="fa in currentEmployeeFixedAllowances"
                            :key="fa.id + '-' + fa.created_at"
                          >
                            <td class="text-left">{{ fa.allowance_name }}</td>
                            <td class="text-left">
                              ₦{{ fa.allowance_amount | number_with_commas }}
                            </td>
                            <td class="text-left">Allowance</td>
                            <td
                              style="padding-top: 13px;"
                              class="employee-options text-left"
                            >
                              <div class="row ml-0">
                                <i
                                  @click="launch_edit_fixed_allowance(fa)"
                                  class="py-0 text-center fas fa-edit mr-2 mt-2"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                ></i>
                                <i
                                  @click="delete_fixed_allowance(fa)"
                                  class="py-0 text-center fas fa-trash ml-2 mt-2"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                ></i>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-for="fd in currentEmployeeFixedDeductions"
                            :key="fd.id + '-' + fd.created_at"
                          >
                            <td class="text-left">{{ fd.deduction_name }}</td>
                            <td class="text-left">
                              ₦{{ fd.deduction_amount | number_with_commas }}
                            </td>
                            <td class="text-left">Deduction</td>
                            <td
                              style="padding-top: 13px;"
                              class="employee-options text-left"
                            >
                              <div class="row ml-0">
                                <i
                                  @click="launch_edit_fixed_deduction(fd)"
                                  class="py-0 text-center fas fa-edit mr-2 mt-2"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                ></i>
                                <i
                                  @click="delete_fixed_deduction(fd)"
                                  class="py-0 text-center fas fa-trash ml-2 mt-2"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                ></i>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-for="t in currentEmployeeTax"
                            :key="t.id + '-' + t.created_at"
                          >
                            <td class="text-left">{{ t.tax }}</td>
                            <td class="text-left">
                              ₦{{ t.amount | number_with_commas }}
                            </td>
                            <td class="text-left">Tax</td>
                            <td
                              style="padding-top: 13px;"
                              class="employee-options text-left"
                            >
                              <div class="row ml-0">
                                <i
                                  @click="launch_edit_tax(t)"
                                  class="py-0 text-center fas fa-edit mr-2 mt-2"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                ></i>
                                <i
                                  @click="delete_tax(t)"
                                  class="py-0 text-center fas fa-trash ml-2 mt-2"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                ></i>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="permissionIsAssigned('attendance-view')"
                class="tab-pane fade"
                id="attendanceTab"
                role="tabpanel"
                aria-labelledby="attendance-tab"
              >
                <div class="row">
                  <h1 class="my-0 col-md-12 pb-0">
                    <span></span>
                    <v-row style="float: right;">
                      <v-col offset="4" cols="4" class="pt-0">
                        <v-select
                          class="remove-mb"
                          :items="allYears"
                          label="Year"
                          dense
                          solo
                          messages=" *Filter by year"
                          :full-width="true"
                          v-model="yearSelected2"
                          @change="update_single_checking_details()"
                        ></v-select>
                      </v-col>
                      <v-col cols="4" class="pt-0">
                        <v-select
                          class="remove-mb"
                          :items="monthNames"
                          label="Month"
                          dense
                          solo
                          messages="*Filter by month"
                          :full-width="true"
                          v-model="monthSelected2"
                          @change="update_single_checking_details()"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </h1>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <div class="card-body py-0">
                        <div id="basicPie" style="height: 500px;"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card" style="border-radius: 10px;">
                      <div class="card-body px-1 py-1 pt-0 pr-0">
                        <div
                          class="table-responsive"
                          style="border-radius: 10px; height: 75vh;
                                                    overflow-y: scroll;
                                                    padding-top: 0;
                                                    padding-bottom: 0;"
                        >
                          <table class="table table-hover">
                            <thead>
                              <tr style="border: 2px solid #4440;">
                                <th scope="col">Date</th>
                                <th scope="col">Check-in</th>
                                <th scope="col">Check-out</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody v-if="finalCheckingSingleDetails.length">
                              <tr
                                v-for="detail in finalCheckingSingleDetails"
                                :key="detail.day"
                              >
                                <th scope="row">{{ detail.day }}</th>
                                <td v-if="detail.check_in">
                                  {{
                                    detail.check_in.split(" ").length == 1
                                      ? detail.check_in
                                      : detail.check_in.split(" ")[1]
                                  }}
                                </td>
                                <td v-else>
                                  <i>No check in</i>
                                </td>
                                <td v-if="detail.check_out">
                                  {{
                                    detail.check_out.split(" ").length == 1
                                      ? detail.check_out
                                      : detail.check_out.split(" ")[1]
                                  }}
                                </td>
                                <td v-else>
                                  <i>No check out</i>
                                </td>
                                <td v-if="detail.check_out">
                                  {{ detail.duration }}
                                </td>
                                <td v-else>
                                  <i>No check out</i>
                                </td>
                                <td>
                                  <span
                                    v-if="detail.status == 'early'"
                                    class="badge badge-round-success sm"
                                    style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;"
                                  >
                                    <span></span>
                                  </span>
                                  <span
                                    v-if="detail.status == 'grace'"
                                    class="badge badge-round-warning sm"
                                    style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;"
                                  >
                                    <span></span>
                                  </span>
                                  <span
                                    v-if="detail.status == 'late'"
                                    class="badge badge-round-warning sm"
                                    style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #ffc107;"
                                  >
                                    <span></span>
                                  </span>
                                  <span
                                    v-if="detail.status == 'on leave'"
                                    class="badge badge-round-info sm"
                                    style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #003473;"
                                  >
                                    <span></span>
                                  </span>
                                  <span
                                    v-if="detail.status == 'absent'"
                                    class="badge badge-round-danger sm"
                                    style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #f44336;"
                                  >
                                    <span></span>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="permissionIsAssigned('payroll-approve')"
                class="tab-pane fade"
                id="paygradeTab"
                role="tabpanel"
                aria-labelledby="paygrade-tab"
              >
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-12">
                    <div
                      class="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                    >
                      <div class="card-header">
                        Pay grade details
                      </div>
                      <div class="row card-body text-center py-3 mt-0">
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Grade Name: </strong>
                          <span v-if="currentPayGrade">
                            {{ currentPayGrade.grade_name }}
                          </span>
                          <i v-else>No specified pay grade</i>
                        </p>
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Currency: </strong>
                          <span v-if="currentPayGrade">
                            {{ currentPayGrade.currency }}
                          </span>
                          <i v-else>No specified pay grade</i>
                        </p>
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Minimum Salary: </strong>
                          <span v-if="currentPayGrade">
                            {{
                              (currentPayGrade.minimum_salary || 0)
                                | number_with_commas
                            }}
                          </span>
                          <i v-else>No specified pay grade</i>
                        </p>
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Maximum Salary: </strong>
                          <span v-if="currentPayGrade">
                            {{
                              (currentPayGrade.maximum_salary || 0)
                                | number_with_commas
                            }}
                          </span>
                          <i v-else>No specified pay grade</i>
                        </p>
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Actual Salary: </strong>
                          <span v-if="currentGradeLevel">
                            {{
                              (currentGradeLevel.salary || 0)
                                | number_with_commas
                            }}
                          </span>
                          <i v-else>No specified salary</i>
                        </p>
                      </div>
                      <div
                        v-if="permissionIsAssigned('payroll-approve')"
                        class="card-footer py-1 text-right row"
                      >
                        <div class="col-lg-6 offset-lg-6 py-0 pb-3 px-0">
                          <button
                            @click="paygrade_dialog = true"
                            type="button"
                            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-6 col-12">
                    <div
                      class="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                    >
                      <div class="card-header">
                        Pay grade history
                      </div>
                      <div class="row card-body text-center py-0 mt-0">
                        <table
                          class="table"
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'table-responsive'
                              : null
                          "
                        >
                          <thead>
                            <tr>
                              <th scope="col">Grade Name</th>
                              <th scope="col">Currency</th>
                              <th scope="col">Minimum Salary</th>
                              <th scope="col">Maximum Salary</th>
                              <th scope="col">Actual Salary</th>
                              <th scope="col">Date Added</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="history in employeePayGradeHistory"
                              :key="history.id"
                            >
                              <td scope="col">
                                {{
                                  (getPayGradeDetails(history.paygrade_id)
                                    ? getPayGradeDetails(history.paygrade_id)
                                        .grade_name
                                    : "...") | number_with_commas
                                }}
                              </td>
                              <td scope="col">
                                {{
                                  (getPayGradeDetails(history.paygrade_id)
                                    ? getPayGradeDetails(history.paygrade_id)
                                        .currency
                                    : "...") | number_with_commas
                                }}
                              </td>
                              <td scope="col">
                                {{
                                  (getPayGradeDetails(history.paygrade_id)
                                    ? getPayGradeDetails(history.paygrade_id)
                                        .minimum_salary
                                    : "...") | number_with_commas
                                }}
                              </td>
                              <td scope="col">
                                {{
                                  (getPayGradeDetails(history.paygrade_id)
                                    ? getPayGradeDetails(history.paygrade_id)
                                        .maximum_salary
                                    : "...") | number_with_commas
                                }}
                              </td>
                              <td scope="col">
                                {{
                                  (getGradeLevelDetails(history.gradelevel_id)
                                    ? getGradeLevelDetails(
                                        history.gradelevel_id
                                      ).salary
                                    : "...") | number_with_commas
                                }}
                              </td>
                              <td scope="col">
                                {{
                                  new Date(history.created_at)
                                    .toUTCString()
                                    .substring(0, 16)
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  permissionIsAssigned('payroll-create') ||
                    permissionIsAssigned('payroll-approve')
                "
                class="tab-pane fade"
                id="accountdetailsTab"
                role="tabpanel"
                aria-labelledby="accountdetails-tab"
              >
                <div class="row">
                  <div class="col-12 col-lg-5">
                    <div
                      class="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                    >
                      <div class="card-header">
                        Account details
                      </div>
                      <div class="row card-body text-center py-3 mt-0">
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Bank Account Name: </strong>
                          <span v-if="employee.bank_name">
                            {{ employee.bank_name }}
                          </span>
                          <i v-else>No specified bank</i>
                        </p>
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Account Number: </strong>
                          <span v-if="employee.account_number">
                            {{ employee.account_number }}
                          </span>
                          <i v-else>No specified bank</i>
                        </p>
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Account Status: </strong>
                          <span v-if="employee.account_status == 1">
                            Verified
                          </span>
                          <i v-else>Unverified</i>
                        </p>
                      </div>
                      <div
                        v-if="
                          (permissionIsAssigned('payroll-create') ||
                            permissionIsAssigned('payroll-approve')) &&
                            employee.account_status != 1
                        "
                        class="card-footer py-1 text-right row"
                      >
                        <div class="col-lg-6 offset-lg-6 py-0 pb-3 px-0">
                          <button
                            @click="
                              verify_account_submit ? null : verifyAccount()
                            "
                            :disabled="verify_account_submit"
                            type="button"
                            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                          >
                            <!-- BLOCK CLICK ON LOADING -->
                            {{ verify_account_text }}
                            <v-icon
                              :style="
                                !verify_account_submit
                                  ? 'display: none;'
                                  : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                              "
                            >
                              fal fa-circle-notch fa-spin
                            </v-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="documentsTab"
                role="tabpanel"
                aria-labelledby="accountdetails-tab"
              >
                <PolicyDocuments :employee="employee" />
              </div>
              <div
                class="tab-pane fade"
                id="exitChecklistsTab"
                role="tabpanel"
                aria-labelledby="exit-checklists-tab"
              >
              <div class="card mb-4" style="border-top: 5px solid #069;">
                      <div class="card-body py-3">
                        <h5 class="card-title mb-2" style="font-weight: bold;">
                          Checklists
                        </h5>
                        <table
                          class="table"
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'table-responsive'
                              : null
                          "
                        >
                          <tbody>
                            <tr v-for="item in authCompanyExitChecklists" :key="item.id">
                              <td class="text-left">
                                  {{ item.item_name }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
              </div>
              <div
                v-if="permissionIsAssigned('payroll-approve')"
                class="tab-pane fade"
                id="payrollAllowanceTab"
                role="tabpanel"
                aria-labelledby="payroll-allowance-tab"
              >
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-12">
                    <div
                      class="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                    >
                      <div class="card-header">
                        Payroll Allowance
                      </div>
                      <div class="row card-body text-center py-3 mt-0">
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Allowance Name: </strong>
                          <span v-if="employee.allowance">
                            {{ employee.allowance }}
                          </span>
                          <i v-else>No specified allowance</i>
                        </p>
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Allowance Amount: </strong>
                          <span v-if="employee.allowance_amount">
                            {{ (employee.allowance_amount || 0)
                                | number_with_commas }}
                          </span>
                          <i v-else>No specified allowance amount</i>
                        </p>
                        <p class="col-12 text-left py-1 mb-1">
                          <strong>Allowance Note: </strong>
                          <span v-if="employee.allowance_note">
                            {{ employee.allowance_note }}
                          </span>
                          <i v-else>No specified allowance note</i>
                        </p>
                        <p class="col-md-6 text-left py-1 mb-1">
                          <strong>Status: </strong>
                          <span v-if="employee.allowance_status == 1"
                              class="badge text-center badge-success">ACTIVE</span>
                              <span v-else
                              class="badge text-center badge-warning">INACTIVE</span>
                        </p>
                        <p class="col-md-6 text-right py-1 mb-1">
                          <label class="switch switch-secondary mr-3">
                              <span v-if="allowanceFormData.allowance_status">Enabled</span>
                              <span v-else>Disabled</span>
                              <input @change="switchAllowanceStatus()" type="checkbox" :checked="allowanceFormData.allowance_status" :disabled="switchingAllowanceStatus"
                                  v-model="allowanceFormData.allowance_status">
                              <span class="slider"></span>
                          </label>
                        </p>
                      </div>
                      <div
                        v-if="permissionIsAssigned('payroll-approve')"
                        class="card-footer py-1 text-right row"
                      >
                        <div class="col-lg-6 offset-lg-6 py-0 pb-3 px-0">
                          <button
                            @click="launchAllowanceUpdate()"
                            type="button"
                            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="flex-grow-1"></div>
      <div
        class="main-header pl-5"
        style="position: fixed; bottom: 0px; z-index: 60;"
      >
        <div class="row">
          <div class="logo col-md-4 py-0">
            <div class="dropdown">
              <button
                type="button"
                class="btn btn-outline-secondary m-1 ripple"
                @click="
                  $router.push({
                    name: 'Onboarding',
                    query: { view: 'employee' }
                  })
                "
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- fotter end -->
    </div>
    <v-dialog v-model="timesheet_comment" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Timesheet Comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <v-textarea
                      v-model="comment_to_add"
                      :label="
                        userType == 1
                          ? 'Comment for ' +
                            employee.employee_fristname +
                            ' ' +
                            employee.employee_lastname +
                            ' (' +
                            monthSelected +
                            ')'
                          : ''
                      "
                      solo
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="
                        add_timesheet_comment_submit ? null : add_comment()
                      "
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ add_timesheet_comment_text }}
                      <v-icon
                        :style="
                          !add_timesheet_comment_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="timesheet_comment = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="add_item_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span v-if="itemSelected == 'Allowance'" class="headline"
            >Add Fixed Allowance</span
          >
          <span v-else-if="itemSelected == 'Deduction'" class="headline"
            >Add Fixed Deduction</span
          >
          <span v-else class="headline">Add Fixed Tax</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Type
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="['Allowance', 'Deduction', 'Tax']"
                      solo
                      dense
                      placeholder="Select Allowance, Deduction or Tax"
                      :full-width="true"
                      v-model="itemSelected"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    v-if="itemSelected == 'Allowance'"
                    cols="6"
                    class="remove-hint"
                  >
                    <span style="color: #111d5e;"
                      >Allowance Name
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="companyAllowances"
                      placeholder="Enter allowance name"
                      solo
                      dense
                      :full-width="true"
                      v-model="itemName"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-else-if="itemSelected == 'Deduction'"
                    cols="6"
                    class="remove-hint"
                  >
                    <span style="color: #111d5e;"
                      >Deduction Name
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="companyDeductions"
                      placeholder="Enter deduction name"
                      solo
                      dense
                      :full-width="true"
                      v-model="itemName"
                    ></v-select>
                  </v-col>
                  <v-col v-else cols="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Tax Name
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="companyTaxes"
                      placeholder="Enter tax name"
                      solo
                      dense
                      :full-width="true"
                      v-model="itemName"
                    ></v-select>
                  </v-col>

                  <v-col
                    v-if="itemSelected == 'Allowance'"
                    cols="6"
                    class="remove-hint"
                  >
                    <span style="color: #111d5e;"
                      >Allowance Amount
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="itemAmount"
                      placeholder="Enter allowance amount"
                      solo
                      dense
                      type="number"
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-else-if="itemSelected == 'Deduction'"
                    cols="6"
                    class="remove-hint"
                  >
                    <span style="color: #111d5e;"
                      >Deduction Amount
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="itemAmount"
                      placeholder="Enter deduction amount"
                      solo
                      dense
                      type="number"
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Tax Amount
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="itemAmount"
                      placeholder="Enter tax amount"
                      solo
                      dense
                      type="number"
                      class="mt-2"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="itemSubmitting ? null : submitItem()"
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ itemText }}
                      <v-icon
                        :style="
                          !itemSubmitting
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="add_item_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_fixed_allowance_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Fixed Allowance</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="6">
                    <span style="color: #111d5e;"
                      >Allowance Name
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="companyAllowances"
                      placeholder="Select allowance name"
                      solo
                      dense
                      :full-width="true"
                      v-model="editItemName"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Allowance Amount
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="editItemAmount"
                      placeholder="Enter allowance amount"
                      solo
                      dense
                      type="number"
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="
                        editItemSubmitting ? null : update_fixed_allowance()
                      "
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ editItemText }}
                      <v-icon
                        :style="
                          !editItemSubmitting
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="edit_fixed_allowance_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_fixed_deduction_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Fixed Deduction</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="6">
                    <span style="color: #111d5e;"
                      >Deduction Name
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="companyDeductions"
                      placeholder="Select deduction name"
                      solo
                      dense
                      :full-width="true"
                      v-model="editItemName"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Deduction Amount
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="editItemAmount"
                      placeholder="Enter deduction amount"
                      solo
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="
                        editItemSubmitting ? null : update_fixed_deduction()
                      "
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ editItemText }}
                      <v-icon
                        :style="
                          !editItemSubmitting
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="edit_fixed_deduction_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_tax_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Fixed Tax</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="6">
                    <span style="color: #111d5e;"
                      >Tax Name
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="companyTaxes"
                      placeholder="Select tax name"
                      solo
                      dense
                      :full-width="true"
                      v-model="editItemName"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Tax Amount
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="editItemAmount"
                      placeholder="Enter tax amount"
                      solo
                      dense
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="editItemSubmitting ? null : update_tax()"
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ editItemText }}
                      <v-icon
                        :style="
                          !editItemSubmitting
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="edit_tax_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="paygrade_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Pay Grade</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col class="pb-0" cols="12">
                    <span style="color: #111d5e;"
                      >Pay Grade
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="selectablePayGrades"
                      placeholder="Select pay grade"
                      solo
                      dense
                      :full-width="true"
                      v-model="selectedPayGrade"
                    ></v-select>
                  </v-col>
                  <v-col class="pt-0" cols="12">
                    <span style="color: #111d5e;"
                      >Grade Level
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-select
                      class="mt-2"
                      :items="selectableGradeLevelNames"
                      placeholder="Select grade level"
                      solo
                      dense
                      :full-width="true"
                      v-model="selectedGradeLevel"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="paygrade_update_loading ? null : updatePayGrade()"
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ paygrade_update_text }}
                      <v-icon
                        :style="
                          !paygrade_update_loading
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="paygrade_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="allowance_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Payroll Allowance</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col class="pb-0" cols="12">
                    <span style="color: #111d5e;"
                      >Allowance Name
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="allowanceFormData.allowance"
                      placeholder="Enter allowance name"
                      solo
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12">
                    <span style="color: #111d5e;"
                      >Allowance Amount
                      <i
                        class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="allowanceFormData.allowance_amount"
                      placeholder="Enter allowance amount"
                      solo
                      type="number"
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12">
                    <span style="color: #111d5e;"
                      >Allowance Note
                      </span>
                    <v-text-field
                      v-model="allowanceFormData.allowance_note"
                      placeholder="Enter allowance note"
                      solo
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="allowance_update_loading ? null : updateAllowance()"
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ allowance_update_text }}
                      <v-icon
                        :style="
                          !allowance_update_loading
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="allowance_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="update_document_dialog" max-width="600px">
      <v-card
        ><v-card-title>
          <div style="display:flex; justify-content:space-between; width:100%">
            <v-col cols="6">
              <span class="headline">Update Document</span>
            </v-col>
            <v-col cols="2"> </v-col>
          </div>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Document Name
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="document_to_update.document_title"
                      placeholder="Enter name"
                      solo
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Select File
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>

                    <v-file-input
                      v-model="document_to_update.file"
                      label="Choose a file"
                      accept=".pdf, .doc, .docx"
                      solo
                      class="mt-2"
                      dense
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Document Name
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="document_to_update.document_title"
                      placeholder="Enter name"
                      solo
                      class="mt-2"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="
                        update_document_submit ? null : updateEmployeeDocument()
                      "
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ update_document_text }}
                      <v-icon
                        :style="
                          !update_document_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="update_document_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, ASSETS_URL } from "@/main";
import { mapActions, mapState, mapGetters } from "vuex";
import PolicyDocuments from "../PolicyDocuments";

export default {
  components: { PolicyDocuments },
  data() {
    return {
      allowanceFormData: {
        allowance: '',
        allowance_amount: '',
        allowance_note: '',
        allowance_status: false,
      },
      switchingAllowanceStatus: false,
      hasValidExitRequest: false,
      filter: 0,
      update_document_dialog: false,
      document_to_update: { name: "", file: null },
      upload_document_text: "Upload Document",
      update_document_text: "Update Document",
      upload_document_submit: false,
      update_document_submit: false,
      document_upload_data: { name: "", file: null },
      verify_account_text: "Verify Account",
      upload_document_form: false,
      verify_account_submit: false,
      paygrade_dialog: false,
      allowance_dialog: false,
      paygrade_update_text: "Update",
      paygrade_update_loading: false,
      allowance_update_text: "Update",
      allowance_update_loading: false,
      selectedPayGrade: "",
      selectedGradeLevel: "",
      itemSelected: "Allowance",
      itemName: "",
      itemAmount: "",
      editItemId: "",
      editItemName: "",
      editItemAmount: "",
      itemText: "Submit",
      itemSubmitting: "",
      editItemText: "Update",
      editItemSubmitting: false,
      edit_fixed_allowance_dialog: false,
      edit_fixed_deduction_dialog: false,
      edit_tax_dialog: false,
      add_item_dialog: false,
      overlay: true,
      checkingSingleDetails: [],
      monthSelected: "",
      yearSelected: "",
      monthSelected2: "",
      yearSelected2: "",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      firstYear: "",
      all_leave_remaining: [],
      employee_assets: [],
      formData: {
        email: "",
        phone: "",
        firstname: "",
        lastname: "",
        dob: "",
        sex: "",
        officialEmail: "",
        address: "",
        state: "",
        city: "",
        country: "",
        experience: "",
        salary: "",
        sourceOfHire: "",
        skillSet: "",
        highestQualification: "",
        additionalInformation: ""
      },
      sourceOfHire: [
        "Direct",
        "Newspaper",
        "Advertisement",
        "Employee Referral",
        "External Referral"
      ],
      work_here: ["Yes", "No"],
      candidateSpecificEducation: [],
      candidateSpecificExperience: [],
      comment_to_add: "",
      add_timesheet_comment_text: "Submit",
      add_timesheet_comment_submit: false,
      timesheet_comment: false,
      disapprove_timesheet_text: "Disapprove",
      disapprove_timesheet_submit: false,
      approve_timesheet_text: "Approve",
      approve_timesheet_submit: false,
      comments: []
    };
  },
  watch: {
    selectedPayGrade(newVal) {
      if (newVal) {
        this.selectedGradeLevel = "";
      }
    },
    checkingSingleDetails(new_value) {
      // let _this = this;
      if (new_value) {
        //
      }
    },
    timesheet_comment: function(t) {
      0 == t && (this.comment_to_add = this.comment_for_month[0].comments);
    },
    comment_for_month: function(t) {
      t && t.length >= 1
        ? (this.comment_to_add = t[0].comments)
        : (this.comment_to_add = "");
    },
    monthSelected: function(t) {
      1 == this.userType
        ? t &&
          this.employee &&
          this.yearSelected &&
          this.fetch_timesheet_comment()
        : t &&
          this.authEmployeeDataForCompany.id &&
          this.yearSelected &&
          this.fetch_timesheet_comment();
    },
    authCompanyAllLeave(new_value) {
      let _this = this;
      if (new_value) {
        _this.authCompanyAllLeave.forEach(lv => {
          axios
            .post(`${BASE_URL}leaveremain`, {
              company_id: _this.authCompanyData.id,
              employee_id: lv.employee_id,
              leave_type: lv.leave_type
            })
            .then(function(response) {
              _this.all_leave_remaining.push({
                id: lv.id,
                message: response.data.message
              });
            })
            .catch(function() {
              _this.all_leave_remaining.push({
                id: lv.id,
                message: "Error in evaluation"
              });
            })
            .then(function() {
              //
            });
        });
      }
    },
    employee(new_value) {
      if (new_value) {
        let _this = this;
        axios
          .post(`${BASE_URL}fetchcandidate`, {
            company_id: _this.authCompanyData.id
          })
          .then(function(response) {
            const RESPONSE = response.data;
            console.log(RESPONSE);
            _this.allWorkExperience = RESPONSE.Work_experience;
            _this.allEducation = RESPONSE.Education;
            let tempEducation = _this.allEducation.filter(edu => {
              return edu.employee_id == _this.employee.id;
            });
            if (tempEducation.length) {
              _this.candidateSpecificEducation = tempEducation.map(edu => {
                return {
                  id: edu.id,
                  company_id: edu.company_id,
                  company_unique_id: edu.company_unique_id,
                  school_name: edu.school_name,
                  degree_diploma: edu.degree_diploma,
                  field_of_study: edu.field_of_study,
                  date_of_completion: edu.date_of_completion,
                  additional_notes: edu.additional_notes,
                  employee_id: edu.employee_id,
                  created_at: edu.created_at,
                  updated_at: edu.updated_at,
                  viewOption: false
                };
              });
            } else {
              _this.candidateSpecificEducation.push({
                id: null,
                company_id: "",
                company_unique_id: "",
                school_name: "",
                degree_diploma: "",
                field_of_study: "",
                date_of_completion: "",
                additional_notes: "",
                employee_id: "",
                created_at: "",
                updated_at: "",
                viewOption: false
              });
            }
            let tempExperience = _this.allWorkExperience.filter(exp => {
              return exp.employee_id == _this.employee.id;
            });
            if (tempExperience.length) {
              _this.candidateSpecificExperience = tempExperience.map(exp => {
                return {
                  company: exp.company,
                  company_id: exp.company_id,
                  company_unique_id: exp.company_unique_id,
                  created_at: exp.created_at,
                  currently_work_here: exp.currently_work_here,
                  duration: exp.duration,
                  employee_id: exp.employee_id,
                  field_of_study: exp.field_of_study,
                  id: exp.id,
                  occupation: exp.occupation,
                  summary: exp.summary,
                  updated_at: exp.updated_at,
                  viewOption: false
                };
              });
            } else {
              _this.candidateSpecificExperience.push({
                id: null,
                company: "",
                company_id: "",
                company_unique_id: "",
                created_at: "",
                currently_work_here: "",
                duration: "",
                employee_id: "",
                field_of_study: "",
                occupation: "",
                summary: "",
                updated_at: "",
                viewOption: false
              });
            }
            _this.overlay = false;
          })
          .catch(function(error) {
            console.log(error);
          })
          .then(function() {
            // context.commit('AUTH_FETCHED_EMPLOYEES', );
          });
      }
    }
  },
  computed: {
    ...mapState([
      "weekDays",
      "userToken"
    ]),
    ...mapGetters([
      "authCompanyExitChecklists",
      "authEmployeeDataForCompany",
      "authEmployeeDocuments",
      "authUserData",
      "validEmployeeId",
      "authCompanyData",
      "authCompanyAllAssetType",
      "authCompanyDepartments",
      "authCompanyAllLeave",
      "authCompanyAllLeaveType",
      "authCompanyAllTimesheets",
      "authCompanyAllCheckin",
      "userType",
      "authFixedAllowances",
      "authFixedDeductions",
      "authEmployeeTaxes",
      "authPayrollConfig",
      "authShifts",
      "authCompanyHolidays",
      "authCompanyAttendanceConfig",
      "authCompanyAllLeave",
      "allEmployeeShifts",
      "authCompanyPayGradeHistories",
      "authCompanyPayGrades",
      "authCompanyGradeLevels",
      "allBanks"
    ]),
    bankCode() {
      let _this = this,
        bankCode = null,
        result = [];
      result = _this.allBanks.filter(
        bank => bank.bank_name == _this.employee.bank_name
      );
      if (result.length > 0) {
        bankCode = result[0].bank_code.slice(0, 3);
      }
      return bankCode;
    },
    selectableGradeLevels() {
      let _this = this,
        result = [];
      if (_this.authCompanyGradeLevels) {
        result = _this.authCompanyGradeLevels.filter(level => {
          return level.paygrade_id == _this.selectedPayGradeId;
        });
      }
      return result;
    },
    selectableGradeLevelNames() {
      let _this = this,
        result = [];
      if (_this.selectableGradeLevels) {
        result = _this.selectableGradeLevels.map(level => {
          return level.gradelevel_name;
        });
      }
      return result;
    },
    currentPayGrade() {
      let _this = this,
        result = null;
      result = _this.authCompanyPayGrades.filter(pg => {
        return _this.employee.paygrade_id == pg.id;
      });
      if (result.length) {
        return result[0];
      }
      return null;
    },
    currentGradeLevel() {
      let _this = this,
        result = null;
      result = _this.authCompanyGradeLevels.filter(level => {
        return _this.employee.gradelevel_id == level.id;
      });
      if (result.length) {
        return result[0];
      }
      return null;
    },
    employeePayGradeHistory() {
      let _this = this,
        result;
      result = _this.authCompanyPayGradeHistories.filter(history => {
        return history.employee_id == _this.employee.id;
      });
      return result;
    },
    selectablePayGrades() {
      let _this = this,
        result = [];
      if (_this.authCompanyPayGrades) {
        result = _this.authCompanyPayGrades.map(pg => {
          return pg.grade_name;
        });
      }
      return result;
    },
    selectedPayGradeId() {
      let _this = this;
      if (_this.selectedPayGrade) {
        return _this.authCompanyPayGrades.filter(pg => {
          return _this.selectedPayGrade == pg.grade_name;
        })[0].id;
      } else {
        return "";
      }
    },
    selectedGradeLevelId() {
      let _this = this;
      if (_this.selectedGradeLevel) {
        return _this.authCompanyGradeLevels.filter(level => {
          return (
            _this.selectedGradeLevel == level.gradelevel_name &&
            _this.selectedPayGradeId == level.paygrade_id
          );
        })[0].id;
      } else {
        return "";
      }
    },
    employeeApprovedLeave() {
      let _this = this,
        result = [];
      if (!_this.employee) {
        return result;
      }
      if (_this.authCompanyAllLeave.length >= 1) {
        result = _this.authCompanyAllLeave.filter(
          lv => _this.employee.employee_id == lv.employee_id && lv.status == 1
        );
      }
      return result;
    },
    companyWorkDays() {
      if (this.authCompanyAttendanceConfig.working_days) {
        return this.authCompanyAttendanceConfig.working_days.split(",");
      }
      return [];
    },
    finalCheckingSingleDetails() {
      let _this = this;
      let checkInDays = _this.checkingSingleDetails.map(c =>
        c.ms_time.toString().substring(0, 15)
      );
      let dateToCheck,
        daysToGet,
        result = [],
        holidays = [],
        leaveDays = [];

      // BELOW IS TO GET DAYS ON LEAVE

      if (_this.employeeApprovedLeave.length >= 1) {
        _this.employeeApprovedLeave.forEach(lv => {
          _this
            .getDatesBetweenDates(
              new Date(lv.leave_start),
              new Date(lv.leave_end + " 01:00:01")
            )
            .forEach(d => {
              leaveDays.splice(
                leaveDays.length,
                0,
                new Date(d).toString().substring(0, 15)
              );
            });
        });
      }

      // END DAYS ON LEAVE CALCULATION

      dateToCheck = new Date(
        Date.UTC(
          parseInt(_this.yearSelected2),
          _this.monthNames.indexOf(_this.monthSelected2)
        )
      );
      daysToGet = [];
      while (
        dateToCheck.getUTCMonth() ===
        _this.monthNames.indexOf(_this.monthSelected2)
      ) {
        if (dateToCheck.getTime() > new Date().getTime()) {
          break;
        }
        daysToGet.push(new Date(dateToCheck).toString().substring(0, 15));
        dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
      }

      _this.authCompanyHolidays.forEach(h => {
        _this
          .getDatesBetweenDates(
            new Date(h.start),
            new Date(h.end.replace("00:00:00", "01:00:00"))
          )
          .forEach(d => {
            holidays.splice(
              holidays.length,
              0,
              new Date(d).toString().substring(0, 15)
            );
          });
      });

      daysToGet.forEach(day => {
        if (
          holidays.indexOf(day) == -1 &&
          leaveDays.indexOf(day) != -1 &&
          _this.companyWorkDays.indexOf(
            _this.getDayOfWeek(new Date(day).getDay())
          ) != -1
        ) {
          if (checkInDays.indexOf(day) == -1) {
            result.splice(result.length, 0, {
              day: day,
              status: "on leave"
            });
          } else {
            result.splice(
              result.length,
              0,
              _this.checkingSingleDetails[checkInDays.indexOf(day)]
            );
          }
        } else if (
          holidays.indexOf(day) == -1 &&
          leaveDays.indexOf(day) == -1 &&
          _this.companyWorkDays.indexOf(
            _this.getDayOfWeek(new Date(day).getDay())
          ) != -1
        ) {
          // THIS IS IF THE DAY IS NOT A HOLIDAY
          if (checkInDays.indexOf(day) == -1) {
            result.splice(result.length, 0, {
              day: day,
              status: "absent"
            });
          } else {
            result.splice(
              result.length,
              0,
              _this.checkingSingleDetails[checkInDays.indexOf(day)]
            );
          }
        } else {
          //
        }
      });

      return result;
    },
    companyAllowances() {
      let _this = this,
        results = [];
      if (_this.authPayrollConfig.length >= 1) {
        results = _this.authPayrollConfig.filter(
          pc => pc.payment_type.toLowerCase() == "allowance"
        );
        results = results.map(r => {
          return r.payment_item;
        });
      }
      return results;
    },
    companyDeductions() {
      let _this = this,
        results = [];
      if (_this.authPayrollConfig.length >= 1) {
        results = _this.authPayrollConfig.filter(
          pc => pc.payment_type.toLowerCase() == "deduction"
        );
        results = results.map(r => {
          return r.payment_item;
        });
      }
      return results;
    },
    companyTaxes() {
      let _this = this,
        results = [];
      if (_this.authPayrollConfig.length >= 1) {
        results = _this.authPayrollConfig.filter(
          pc => pc.payment_type.toLowerCase() == "tax"
        );
        results = results.map(r => {
          return r.payment_item;
        });
      }
      return results;
    },
    currentEmployeeTax() {
      let _this = this,
        result = _this.authEmployeeTaxes;
      if (result.length >= 1 && _this.employee.id) {
        result = result.filter(fa => {
          return fa.employee_id == _this.employee.id;
        });
      }
      return result;
    },
    currentEmployeeFixedAllowances() {
      let _this = this,
        result = _this.authFixedAllowances;
      if (result.length >= 1 && _this.employee.id) {
        result = result.filter(fa => {
          return fa.employee_id == _this.employee.id;
        });
      }
      return result;
    },
    currentEmployeeFixedDeductions() {
      let _this = this,
        result = _this.authFixedDeductions;
      if (result.length >= 1 && _this.employee.id) {
        result = result.filter(fa => {
          return fa.employee_id == _this.employee.id;
        });
      }
      return result;
    },
    json_fields: function() {
      return {
        Date: "date",
        Task: "project_title",
        "Place of Performance": "place_performance",
        "Brief Summary of Work Done": "work_done",
        "Claimed Expenses (NGN)": "claimed_expenses",
        "Advanced Payment (NGN)": "payment_advanced",
        "Reports Submitted": "report_submitted"
      };
    },
    json_data: function() {
      return this.filtered_timesheets;
    },
    monthly_status: function() {
      var t,
        e,
        a = this;
      return 0 == a.filtered_timesheets.length
        ? "pending"
        : a.filtered_timesheets &&
          ((t = a.filtered_timesheets.filter(function(t) {
            return 1 == t.status;
          })),
          t.length == a.filtered_timesheets.length)
        ? "approved"
        : a.filtered_timesheets &&
          ((e = a.filtered_timesheets.filter(function(t) {
            return 0 == t.status;
          })),
          e.length == a.filtered_timesheets.length)
        ? "disapproved"
        : "pending";
    },
    filtered_timesheets: function() {
      if (this.authCompanyAllTimesheets) {
        var t = this;
        if (t.yearSelected && t.monthSelected) {
          return this.authCompanyAllTimesheets.filter(function(ts) {
            return (
              ts.month_name == t.monthSelected &&
              ts.year == t.yearSelected &&
              ts.employee_id == t.employee.id
            );
          });
        } else if (t.yearSelected) {
          return this.authCompanyAllTimesheets.filter(function(ts) {
            return ts.year == t.yearSelected && ts.employee_id == t.employee.id;
          });
        } else {
          return this.authCompanyAllTimesheets.filter(function(ts) {
            return ts.employee_id == t.employee.id;
          });
        }
      }
      return [];
    },
    all_filtered_ts_ids: function() {
      var t = this;
      return t.filtered_timesheets.map(function(t) {
        return t.id;
      });
    },
    comment_for_month: function() {
      var t = this;
      return t.comments.length >= 1
        ? t.comments.filter(function(e) {
            return (
              e.year == t.yearSelected &&
              e.month == t.monthSelected &&
              e.employee_id == t.employee.id
            );
          })
        : [];
    },
    allYears: function() {
      var t = [],
        e = new Date().getFullYear();
      t.push(this.firstYear);
      for (var a = this.firstYear; a <= e; a++) {
        if (t.indexOf(a) == -1) {
          t.push(a.toString());
        }
      }
      return t.reverse();
    },
    employee_leave() {
      let _this = this;
      if (_this.authCompanyAllLeave) {
        return _this.authCompanyAllLeave.filter(lv => {
          return lv.employee_id == _this.employee.id;
        });
      }
      return [];
    },
    assets_url() {
      return ASSETS_URL;
    },
    personal_assets() {
      let _this = this;
      return _this.employee_assets.filter(asset => {
        return asset.employee_id == parseInt(_this.$route.params.id);
      });
    },
    employee() {
      let _this = this;
      return _this.mixinBranchEmployees(_this.$route.name).filter(employee => {
        return employee.id == parseInt(_this.$route.params.id);
      })[0];
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_employee_documents",
      "fetch_active_company_employees",
      "fetch_all_timesheets",
      "fetch_all_fixed_allowances",
      "fetch_all_fixed_deductions",
      "fetch_all_employees_tax",
      "fetch_all_employee_shifts",
      "fetch_all_shifts",
      "fetch_all_leave",
      "fetch_company_pay_grade_histories",
      "fetch_company_pay_grades",
      "fetch_company_grade_levels",
      "fetch_company_exit_checklists"
    ]),
    launchAllowanceUpdate() {
      const _this = this;
      _this.allowanceFormData.allowance = _this.employee.allowance;
      _this.allowanceFormData.allowance_amount = _this.employee.allowance_amount;
      _this.allowanceFormData.allowance_note = _this.employee.allowance_note;
      _this.allowance_dialog = true;
    },
    verifyAccount() {
      let _this = this;
      _this.verify_account_text = "";
      _this.verify_account_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}verifyaccountnumber`, {
          account_number: _this.employee.account_number,
          bank_code: _this.bankCode,
          employee_id: _this.employee.id
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "Account details verified successfully"
          });
          _this.fetch_active_company_employees();
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "Account details couldn't be verified"
          });
        })
        .then(function() {
          _this.verify_account_submit = false;
          _this.verify_account_text = "Verify Account";
        });
    },

    getGradeLevelDetails(level_id) {
      let _this = this;
      if (_this.authCompanyGradeLevels) {
        return _this.authCompanyGradeLevels.filter(level => {
          return level.id == level_id;
        })[0];
      }
      return null;
    },
    getPayGradeDetails(paygrade_id) {
      let _this = this;
      if (_this.authCompanyPayGrades) {
        return _this.authCompanyPayGrades.filter(pg => {
          return pg.id == paygrade_id;
        })[0];
      }
      return null;
    },
    leaveByEmployee(employee_id) {
      let _this = this,
        result = [];
      if (_this.authCompanyAllLeave.length >= 1) {
        result = _this.authCompanyAllLeave.filter(
          lv => lv.employee_id == employee_id && lv.status == 1
        );
      }
      return result;
    },
    getDayOfWeek(day) {
      return this.weekDays[day];
    },
    getDatesBetweenDates(startDate, endDate) {
      let dates = [];
      //to avoid modifying the original date
      const theDate = new Date(startDate);
      while (theDate < endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
      }
      return dates;
    },
    getShiftDetails(employee_id) {
      let _this = this;
      if (_this.allEmployeeShifts) {
        return _this.allEmployeeShifts.filter(e => {
          return e.employee_id == employee_id;
        })[0];
      }
      return null;
    },
    updatePayGrade() {
      let _this = this;
      _this.paygrade_update_text = "";
      _this.paygrade_update_loading = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updateemployeegradelevel`, {
          company_id: _this.authCompanyData.id,
          employee_id: _this.employee.id,
          paygrade_id: _this.selectedPayGradeId,
          gradelevel_id: _this.selectedGradeLevelId
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "Pay grade updated successfully"
          });
          _this.paygrade_dialog = false;
          _this.fetch_company_pay_grades();
          _this.fetch_company_grade_levels();
          _this.fetch_company_pay_grade_histories();
          _this.fetch_active_company_employees();
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "Pay grade couldn't be updated"
          });
        })
        .then(function() {
          _this.paygrade_update_loading = false;
          _this.paygrade_update_text = "Update";
        });
    },
    updateAllowance() {
      let _this = this;
      _this.allowance_update_text = "";
      _this.allowance_update_loading = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updateallowance`, {
          employee_id: _this.employee.id,
          allowance_name: _this.allowanceFormData.allowance,
          allowance_amount: _this.allowanceFormData.allowance_amount,
          allowance_note: _this.allowanceFormData.allowance_note
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "Employee payroll allowance updated successfully"
          });
          _this.allowance_dialog = false;
          _this.allowanceFormData.allowance = '';
          _this.allowanceFormData.allowance_amount = '';
          _this.allowanceFormData.allowance_note = '';
          _this.fetch_active_company_employees();
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "Employee payroll allowance couldn't be updated"
          });
        })
        .then(function() {
          _this.allowance_update_loading = false;
          _this.allowance_update_text = "Update";
        });
    },
    switchAllowanceStatus() {
      let _this = this;
      _this.switchingAllowanceStatus = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updateallowancestatus`, {
          employee_id: _this.employee.id
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "Employee payroll allowance status updated successfully"
          });
          _this.fetch_active_company_employees().then(() => {
            _this.allowanceFormData.allowance_status = _this.employee.allowance_status == 1
          });
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "Employee payroll allowance status couldn't be updated"
          });
        })
        .then(function() {
          _this.switchingAllowanceStatus = false;
        });
    },
    update_single_checking_details() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchemployeechecking`, {
          company_id: _this.authCompanyData.id,
          employee_id: _this.employee.id,
          month: _this.monthSelected2,
          year: _this.yearSelected2
        })
        .then(function(response) {
          _this.checkingSingleDetails = response.data.user_attendance;
          // let firstYear = response.data.year_object.day.split("-")[0];
          // _this.yearSelected = firstYear;

          const basicPieElem = document.getElementById("basicPie");
          let early,
            late,
            absent = 0,
            onLeave = 0,
            checkInDays,
            dateToCheck,
            daysToGet,
            holidays = [],
            leaveDays = [];
          if (
            _this.checkingSingleDetails.length >= 1 ||
            new Date(response.data.year_object.day).getTime() <
              new Date().getTime() ||
            new Date().getTime() - 34716000000 < new Date().getTime()
          ) {
            early = _this.finalCheckingSingleDetails.filter(
              check => check.status == "early"
            ).length;
            late = _this.finalCheckingSingleDetails.filter(
              check => check.status == "late"
            ).length;

            // BELOW IS TO GET DAYS ABSENT
            checkInDays = _this.checkingSingleDetails.map(c =>
              c.ms_time.toString().substring(0, 15)
            );

            // console.log(checkInDays);

            dateToCheck = new Date(
              Date.UTC(
                parseInt(_this.yearSelected),
                _this.monthNames.indexOf(_this.monthSelected)
              )
            );
            daysToGet = [];
            while (
              dateToCheck.getUTCMonth() ===
              _this.monthNames.indexOf(_this.monthSelected)
            ) {
              if (dateToCheck.getTime() > new Date().getTime()) {
                break;
              }
              daysToGet.push(new Date(dateToCheck).toString().substring(0, 15));
              dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
            }

            // BELOW IS TO GET DAYS ON LEAVE

            _this.employeeApprovedLeave.forEach(lv => {
              _this
                .getDatesBetweenDates(
                  new Date(lv.leave_start),
                  new Date(lv.leave_end + " 01:00:01")
                )
                .forEach(d => {
                  leaveDays.splice(
                    leaveDays.length,
                    0,
                    new Date(d).toString().substring(0, 15)
                  );
                });
            });

            daysToGet.forEach(day => {
              if (
                checkInDays.indexOf(day) == -1 &&
                leaveDays.indexOf(day) != -1 &&
                holidays.indexOf(day) == -1 &&
                _this.companyWorkDays.indexOf(
                  _this.getDayOfWeek(new Date(day).getDay())
                ) != -1
              ) {
                // console.log(day);
                onLeave += 1;
              }
            });

            // END DAYS ON LEAVE CALCULATION

            _this.authCompanyHolidays.forEach(h => {
              _this
                .getDatesBetweenDates(
                  new Date(h.start),
                  new Date(h.end.replace("00:00:00", "01:00:00"))
                )
                .forEach(d => {
                  holidays.splice(
                    holidays.length,
                    0,
                    new Date(d).toString().substring(0, 15)
                  );
                });
            });

            daysToGet.forEach(day => {
              if (
                checkInDays.indexOf(day) == -1 &&
                holidays.indexOf(day) == -1 &&
                leaveDays.indexOf(day) == -1 &&
                _this.companyWorkDays.indexOf(
                  _this.getDayOfWeek(new Date(day).getDay())
                ) != -1
              ) {
                // console.log(day);
                absent += 1;
              }
            });

            // absent = 0;

            // END DAYS ABSENT CALCULATION
          } else {
            early = 0;
            late = 0;
            absent = 0;
            onLeave = 0;
          }
          if (basicPieElem) {
            var basicPie = window.echarts.init(basicPieElem);
            basicPie.setOption({
              color: ["#4caf50", "#ebcb37", "#003473", "#c13018"],
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              yAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              series: [
                {
                  name: "Time Logs",
                  type: "pie",
                  radius: "70%",
                  center: ["50%", "50%"],
                  data: [
                    {
                      value: early,
                      name:
                        early == 1
                          ? "EARLY (" + early + " day)"
                          : "EARLY (" + early + " days)"
                    },
                    {
                      value: late,
                      name:
                        late == 1
                          ? "LATE (" + late + " day)"
                          : "LATE (" + late + " days)"
                    },
                    _this.check_all_services("Absence or Leave")
                      ? {
                          value: onLeave,
                          name:
                            onLeave == 1
                              ? "ON LEAVE (" + onLeave + " day)"
                              : "ON LEAVE (" + onLeave + " days)"
                        }
                      : null,
                    {
                      value: absent,
                      name:
                        absent == 1
                          ? "ABSENT (" + absent + " day)"
                          : "ABSENT (" + absent + " days)"
                    }
                  ],
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)"
                    }
                  }
                }
              ]
            });
            window.$(window).on("resize", function() {
              setTimeout(function() {
                basicPie.resize();
              }, 500);
            });
          }
        })
        .catch(function(error) {
          console.log(error.response);
        })
        .then(function() {
          //
        });
    },
    load_employee(employee) {
      let _this = this;
      _this.overlay = true;
      // _this.employee = employee;
      _this.monthSelected = _this.monthNames[new Date().getMonth()];
      _this.yearSelected = new Date().getFullYear().toString();
      _this.view = "employee";
      axios
        .post(`${BASE_URL}fetchemployeechecking`, {
          company_id: _this.authCompanyData.id,
          employee_id: employee.employee_id,
          month: _this.monthSelected,
          year: _this.yearSelected
        })
        .then(function(response) {
          _this.overlay = false;
          setTimeout(() => {
            _this.checkingSingleDetails = response.data.user_attendance;
            // let firstYear = response.data.year_object.day.split("-")[0];
            // _this.yearSelected = firstYear;
            const basicPieElem = document.getElementById("basicPie");
            let early,
              late,
              absent = 0,
              onLeave = 0,
              checkInDays,
              dateToCheck,
              daysToGet,
              holidays = [],
              leaveDays = [];
            if (
              _this.checkingSingleDetails.length >= 1 ||
              new Date(response.data.year_object.day).getTime() <
                new Date().getTime() ||
              new Date().getTime() - 1 < new Date().getTime()
            ) {
              early = _this.finalCheckingSingleDetails.filter(
                check => check.status == "early"
              ).length;
              late = _this.finalCheckingSingleDetails.filter(
                check => check.status == "late"
              ).length;

              // BELOW IS TO GET DAYS ABSENT
              checkInDays = _this.checkingSingleDetails.map(c =>
                c.ms_time.toString().substring(0, 15)
              );

              // console.log(checkInDays);

              dateToCheck = new Date(
                Date.UTC(
                  parseInt(_this.yearSelected),
                  _this.monthNames.indexOf(_this.monthSelected)
                )
              );
              daysToGet = [];
              while (
                dateToCheck.getUTCMonth() ===
                _this.monthNames.indexOf(_this.monthSelected)
              ) {
                if (dateToCheck.getTime() > new Date().getTime()) {
                  break;
                }
                daysToGet.push(
                  new Date(dateToCheck).toString().substring(0, 15)
                );
                dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
              }

              // BELOW IS TO GET DAYS ON LEAVE

              _this.employeeApprovedLeave.forEach(lv => {
                _this
                  .getDatesBetweenDates(
                    new Date(lv.leave_start),
                    new Date(lv.leave_end + " 01:00:01")
                  )
                  .forEach(d => {
                    leaveDays.splice(
                      leaveDays.length,
                      0,
                      new Date(d).toString().substring(0, 15)
                    );
                  });
              });

              daysToGet.forEach(day => {
                if (
                  checkInDays.indexOf(day) == -1 &&
                  leaveDays.indexOf(day) != -1 &&
                  holidays.indexOf(day) == -1 &&
                  _this.companyWorkDays.indexOf(
                    _this.getDayOfWeek(new Date(day).getDay())
                  ) != -1
                ) {
                  // console.log(day);
                  onLeave += 1;
                }
              });

              // END DAYS ON LEAVE CALCULATION

              _this.authCompanyHolidays.forEach(h => {
                _this
                  .getDatesBetweenDates(
                    new Date(h.start),
                    new Date(h.end.replace("00:00:00", "01:00:00"))
                  )
                  .forEach(d => {
                    holidays.splice(
                      holidays.length,
                      0,
                      new Date(d).toString().substring(0, 15)
                    );
                  });
              });

              daysToGet.forEach(day => {
                if (
                  checkInDays.indexOf(day) == -1 &&
                  holidays.indexOf(day) == -1 &&
                  leaveDays.indexOf(day) == -1 &&
                  _this.companyWorkDays.indexOf(
                    _this.getDayOfWeek(new Date(day).getDay())
                  ) != -1
                ) {
                  // console.log(day);
                  absent += 1;
                }
              });

              // absent = 0;

              // END DAYS ABSENT CALCULATION
            } else {
              early = 0;
              late = 0;
              absent = 0;
              onLeave = 0;
            }
            if (basicPieElem) {
              var basicPie = window.echarts.init(basicPieElem);
              basicPie.setOption({
                color: ["#4caf50", "#ebcb37", "#003473", "#c13018"],
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true
                },
                xAxis: [
                  {
                    axisLine: {
                      show: false
                    },
                    splitLine: {
                      show: false
                    }
                  }
                ],
                yAxis: [
                  {
                    axisLine: {
                      show: false
                    },
                    splitLine: {
                      show: false
                    }
                  }
                ],
                series: [
                  {
                    name: "Time Logs",
                    type: "pie",
                    radius: "70%",
                    center: ["50%", "50%"],
                    data: [
                      {
                        value: early,
                        name:
                          early == 1
                            ? "EARLY (" + early + " day)"
                            : "EARLY (" + early + " days)"
                      },
                      {
                        value: late,
                        name:
                          late == 1
                            ? "LATE (" + late + " day)"
                            : "LATE (" + late + " days)"
                      },
                      _this.check_all_services("Absence or Leave")
                        ? {
                            value: onLeave,
                            name:
                              onLeave == 1
                                ? "ON LEAVE (" + onLeave + " day)"
                                : "ON LEAVE (" + onLeave + " days)"
                          }
                        : null,
                      {
                        value: absent,
                        name:
                          absent == 1
                            ? "ABSENT (" + absent + " day)"
                            : "ABSENT (" + absent + " days)"
                      }
                    ],
                    itemStyle: {
                      emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)"
                      }
                    }
                  }
                ]
              });
              window.$(window).on("resize", function() {
                setTimeout(function() {
                  basicPie.resize();
                }, 500);
              });
            }
          }, 1000);
        })
        .catch(function(error) {
          _this.overlay = false;
          console.log(error.response);
        })
        .then(function() {
          //
        });
    },
    update_tax() {
      let _this = this;
      _this.editItemText = "";
      _this.editItemSubmitting = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updateemployeetax`, {
          tax_id: _this.editItemId,
          tax: _this.editItemName,
          amount: _this.editItemAmount
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "The fixed tax has been updated successfully"
          });
          _this.fetch_all_employees_tax();
          _this.editItemId = "";
          _this.editItemName = "";
          _this.editItemAmount = "";
          _this.edit_tax_dialog = false;
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.tax
                ? errors.tax[0]
                : null || errors.amount
                ? errors.amount[0]
                : null || errors.employee_id
                ? errors.employee_id[0]
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "The fixed deduction details couldn't be updated"
          });
        })
        .then(function() {
          _this.editItemSubmitting = false;
          _this.editItemText = "Update";
        });
    },
    update_fixed_deduction() {
      let _this = this;
      _this.editItemText = "";
      _this.editItemSubmitting = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updateemployeefixeddeduction`, {
          deduction_id: _this.editItemId,
          deduction_name: _this.editItemName,
          deduction_amount: _this.editItemAmount
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "The fixed deduction has been updated successfully"
          });
          _this.fetch_all_fixed_deductions();
          _this.editItemId = "";
          _this.editItemName = "";
          _this.editItemAmount = "";
          _this.edit_fixed_deduction_dialog = false;
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.deduction_name
                ? errors.deduction_name[0]
                : null || errors.deduction_amount
                ? errors.deduction_amount[0]
                : null || errors.employee_id
                ? errors.employee_id[0]
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "The fixed deduction details couldn't be updated"
          });
        })
        .then(function() {
          _this.editItemSubmitting = false;
          _this.editItemText = "Update";
        });
    },
    update_fixed_allowance() {
      let _this = this;
      _this.editItemText = "";
      _this.editItemSubmitting = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updateemployeefixedallowance`, {
          allowance_id: _this.editItemId,
          allowance_name: _this.editItemName,
          allowance_amount: _this.editItemAmount
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "The fixed allowance has been updated successfully"
          });
          _this.fetch_all_fixed_allowances();
          _this.editItemId = "";
          _this.editItemName = "";
          _this.editItemAmount = "";
          _this.edit_fixed_allowance_dialog = false;
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.allowance_name
                ? errors.allowance_name[0]
                : null || errors.allowance_amount
                ? errors.allowance_amount[0]
                : null || errors.employee_id
                ? errors.employee_id[0]
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "The fixed allowance details couldn't be updated"
          });
        })
        .then(function() {
          _this.editItemSubmitting = false;
          _this.editItemText = "Update";
        });
    },
    launch_edit_tax(t) {
      this.editItemId = t.id;
      this.editItemName = t.tax;
      this.editItemAmount = t.amount;
      this.edit_tax_dialog = true;
    },
    launch_edit_fixed_deduction(fd) {
      this.editItemId = fd.id;
      this.editItemName = fd.deduction_name;
      this.editItemAmount = fd.deduction_amount;
      this.edit_fixed_deduction_dialog = true;
    },
    launch_edit_fixed_allowance(fa) {
      this.editItemId = fa.id;
      this.editItemName = fa.allowance_name;
      this.editItemAmount = fa.allowance_amount;
      this.edit_fixed_allowance_dialog = true;
    },
    delete_fixed_allowance(fa) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title: "Are you sure you want to delete this fixed allowance?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}removeemployeefixedallowance`, {
                allowance_id: fa.id
              })
              .then(function() {
                Toast.fire({
                  icon: "success",
                  title: "Fixed allowance deleted successfully"
                });
              })
              .catch(function(error) {
                Toast.fire({
                  icon: "error",
                  title: "Fixed allowance couldn't be deleted"
                });
                console.log(error);
              })
              .then(function() {
                _this.fetch_all_fixed_allowances();
              });
          }
        });
    },
    delete_tax(t) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title: "Are you sure you want to delete this fixed tax?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deleteemployeetax`, {
                tax_id: t.id
              })
              .then(function() {
                Toast.fire({
                  icon: "success",
                  title: "Fixed tax deleted successfully"
                });
              })
              .catch(function(error) {
                Toast.fire({
                  icon: "error",
                  title: "Fixed tax couldn't be deleted"
                });
                console.log(error);
              })
              .then(function() {
                _this.fetch_all_employees_tax();
              });
          }
        });
    },
    delete_fixed_deduction(fd) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title: "Are you sure you want to delete this fixed deduction?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}removeemployeefixeddeduction`, {
                deduction_id: fd.id
              })
              .then(function() {
                Toast.fire({
                  icon: "success",
                  title: "Fixed deduction deleted successfully"
                });
              })
              .catch(function(error) {
                Toast.fire({
                  icon: "error",
                  title: "Fixed deduction couldn't be deleted"
                });
                console.log(error);
              })
              .then(function() {
                _this.fetch_all_fixed_deductions();
              });
          }
        });
    },
    submitItem() {
      let _this = this;
      _this.itemText = "";
      _this.itemSubmitting = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      if (_this.itemSelected == "Allowance") {
        axios
          .post(`${BASE_URL}createemployeefixedallowance`, {
            company_id: _this.authCompanyData.id,
            employee_id: _this.employee.id,
            allowance_name: _this.itemName,
            allowance_amount: _this.itemAmount
          })
          .then(function() {
            Toast.fire({
              icon: "success",
              title: "Fixed allowance added successfully"
            });
            _this.fetch_all_fixed_allowances();
            _this.itemName = "";
            _this.itemAmount = "";
            _this.add_item_dialog = false;
          })
          .catch(function(error) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors
                ? typeof errors === "string"
                  ? errors
                  : null || errors.allowance_name
                  ? errors.allowance_name[0]
                  : null || errors.allowance_amount
                  ? errors.allowance_amount[0]
                  : null || errors.employee_id
                  ? errors.employee_id[0]
                  : null
                : null ||
                  error.response.data.error ||
                  error.response.data.message ||
                  "The fixed allowance details couldn't be added"
            });
          })
          .then(function() {
            _this.itemSubmitting = false;
            _this.itemText = "Submit";
          });
      } else if (_this.itemSelected == "Deduction") {
        const Toast = _this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", _this.$swal.stopTimer);
            toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
          }
        });
        axios
          .post(`${BASE_URL}createemployeefixeddeduction`, {
            company_id: _this.authCompanyData.id,
            employee_id: _this.employee.id,
            deduction_name: _this.itemName,
            deduction_amount: _this.itemAmount
          })
          .then(function() {
            Toast.fire({
              icon: "success",
              title: "Fixed deduction added successfully"
            });
            _this.fetch_all_fixed_deductions();
            _this.itemName = "";
            _this.itemAmount = "";
            _this.add_item_dialog = false;
          })
          .catch(function(error) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors
                ? typeof errors === "string"
                  ? errors
                  : null || errors.deduction_name
                  ? errors.deduction_name[0]
                  : null || errors.deduction_amount
                  ? errors.deduction_amount[0]
                  : null || errors.employee_id
                  ? errors.employee_id[0]
                  : null
                : null ||
                  error.response.data.error ||
                  error.response.data.message ||
                  "The fixed deduction details couldn't be added"
            });
          })
          .then(function() {
            _this.itemSubmitting = false;
            _this.itemText = "Submit";
          });
      } else {
        axios
          .post(`${BASE_URL}createemployeetax`, {
            company_id: _this.authCompanyData.id,
            employee_id: _this.employee.id,
            tax: _this.itemName,
            amount: _this.itemAmount
          })
          .then(function() {
            Toast.fire({
              icon: "success",
              title: "Tax added successfully"
            });
            _this.fetch_all_employees_tax();
            _this.itemName = "";
            _this.itemAmount = "";
            _this.add_item_dialog = false;
          })
          .catch(function(error) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors
                ? typeof errors === "string"
                  ? errors
                  : null || errors.tax
                  ? errors.tax[0]
                  : null || errors.amount
                  ? errors.amount[0]
                  : null || errors.employee_id
                  ? errors.employee_id[0]
                  : null
                : null ||
                  error.response.data.error ||
                  error.response.data.message ||
                  "The fixed deduction details couldn't be added"
            });
          })
          .then(function() {
            _this.itemSubmitting = false;
            _this.itemText = "Submit";
          });
      }
    },
    run_attendance() {
      let _this = this;
      if (_this.yearSelected2 && _this.monthSelected2) {
        // this.update_single_checking_details();
        setTimeout(() => {
          console.log("Can fetch");
          // _this.update_single_checking_details();
          // _this.load_employee(_this.employee);
        }, 500);
      }
    },
    add_comment: function() {
      var t = this;
      (t.add_timesheet_comment_text = ""),
        (t.add_timesheet_comment_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function(e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer),
            e.addEventListener("mouseleave", t.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}addcomment`, {
          employee_id: t.employee.id,
          month: t.monthSelected,
          year: t.yearSelected,
          comment: t.comment_to_add
        })
        .then(function() {
          t.fetch_timesheet_comment(),
            (t.comment_to_add = ""),
            (t.timesheet_comment = false),
            e.fire({
              icon: "success",
              title: "Timesheet comment added successfully."
            });
        })
        .catch(function(t) {
          e.fire({
            icon: "error",
            title:
              t.response.data.error.employee_id ||
              t.response.data.error.year ||
              t.response.data.error.comment ||
              t.response.data.error.month ||
              "Timesheet comment couldn't be added"
          });
        })
        .then(function() {
          (t.add_timesheet_comment_submit = false),
            (t.add_timesheet_comment_text = "Submit");
        });
    },
    fetch_timesheet_comment: function() {
      var t = this;
      axios
        .post(`${BASE_URL}fetchtimesheetcomment`, {
          employee_id: t.employee.id
        })
        .then(function(e) {
          t.comments = e.data.comments;
        })
        .catch(function() {})
        .then(function() {});
    },
    disapprove_timesheets: function() {
      var t = this;
      (t.disapprove_timesheet_text = ""),
        (t.disapprove_timesheet_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function(e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer),
            e.addEventListener("mouseleave", t.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}disapprovetimesheet`, { ids: t.all_filtered_ts_ids })
        .then(function() {
          e.fire({
            icon: "success",
            title:
              "The Timesheet for " +
              t.employee.employee_fristname +
              " " +
              t.employee.employee_lastname +
              " for the month of " +
              t.monthSelected +
              " has been disapproved"
          }),
            t.fetch_all_timesheets();
        })
        .catch(function() {
          e.fire({ icon: "error", title: "Timesheet couldn't be disapproved" });
        })
        .then(function() {
          (t.disapprove_timesheet_submit = false),
            (t.disapprove_timesheet_text = "Disapprove");
        });
    },
    approve_timesheets: function() {
      console.log(2);
      var t = this;
      (t.approve_timesheet_text = ""), (t.approve_timesheet_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function(e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer),
            e.addEventListener("mouseleave", t.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}approvetimesheet`, { ids: t.all_filtered_ts_ids })
        .then(function() {
          e.fire({
            icon: "success",
            title:
              "The Timesheet for " +
              t.employee.employee_fristname +
              " " +
              t.employee.employee_lastname +
              " for the month of " +
              t.monthSelected +
              " has been approved"
          }),
            t.fetch_all_timesheets();
        })
        .catch(function() {
          e.fire({ icon: "error", title: "Timesheet couldn't be approved" });
        })
        .then(function() {
          (t.approve_timesheet_submit = false),
            (t.approve_timesheet_text = "Approve");
        });
    },
    approve_single_leave(leave) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        company_id: _this.authCompanyData.id,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to approve this leave request from " +
            _this.employee.employee_fristname +
            " " +
            _this.employee.employee_lastname +
            "?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Approve`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}approveleave`, {
                ids: [
                  {
                    id: leave.id
                  }
                ]
              })
              .then(function() {
                Toast.fire({
                  icon: "success",
                  title: "Leave approval successful"
                });
              })
              .catch(function(error) {
                Toast.fire({
                  icon: "error",
                  title: "Leave approval failed"
                });
                console.log(error);
              })
              .then(function() {
                _this.fetch_all_leave();
                _this.fetch_all_approved_leave();
              });
          }
        });
    },
    disapprove_single_leave(leave) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to disapprove this leave request from " +
            _this.employee.employee_fristname +
            " " +
            _this.employee.employee_lastname +
            "?",
          showCancelButton: true,
          confirmButtonText: `Yes, Disapprove`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}disapproveleave`, {
                ids: [
                  {
                    id: leave.id
                  }
                ]
              })
              .then(function() {
                Toast.fire({
                  icon: "success",
                  title: "Leave disapproval successful"
                });
              })
              .catch(function(error) {
                Toast.fire({
                  icon: "error",
                  title: "Leave disapproval failed"
                });
                console.log(error);
              })
              .then(function() {
                _this.fetch_all_leave();
                _this.fetch_all_approved_leave();
              });
          }
        });
    },
    fetch_days_left(leave) {
      let _this = this,
        tmpResult = null;
      if (_this.all_leave_remaining) {
        tmpResult = _this.all_leave_remaining.filter(lr => {
          return lr.id == leave.id;
        });
        if (tmpResult.length >= 1) {
          return tmpResult[0].message;
        } else {
          return "Error in evaluation";
        }
      } else {
        return "Error in evaluation";
      }
    },
    get_dept_name(dept_id) {
      let _this = this;
      let dept = _this.authCompanyDepartments.filter(dept => {
        return dept.id == dept_id;
      });
      return dept[0].department_name;
    },
    get_leave_type_name(type_id) {
      let _this = this;
      let type = _this.authCompanyAllLeaveType.filter(
        type => type.id == type_id
      );
      // console.log(type[0].leave_name);
      return type[0].leave_name;
    },
    get_type_name(type_id) {
      let _this = this;
      if (type_id && _this.authCompanyAllAssetType) {
        let type = _this.authCompanyAllAssetType.filter(
          type => type.id == type_id
        );
        // console.log(type[0].leave_name);
        return type[0].assettype_name;
      }
    },
    fetch_employee_assets() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchemployeeasset`, {
          company_id: _this.authCompanyData.id
        })
        .then(function(response) {
          console.log(RESPONSE);
          const RESPONSE = response.data;
          RESPONSE.Employees.forEach(asset => {
            _this.employee_assets.push(asset);
          });
        })
        .catch(function() {})
        .then(function() {
          //
        });
    },
    fetch_employee_exit_requests() {
      let _this = this;
      axios
        .get(`${BASE_URL}fetchexitrequestbyemployee/${_this.$route.params.id}`, {
            headers: {
              Authorization: `Bearer ${_this.userToken}`
            }
          })
        .then(function(response) {
          response.data.exitrequests.foreach(request => {
            if (request.status != 1) {
              _this.hasValidExitRequest = true;
            }
          })
          //exitrequests
        })
        .catch(function() {})
        .then(function() {
          //
        });
    },
  },
  mounted() {
    let _this = this;
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    _this.load_employee(_this.employee);
    _this.yearSelected = new Date().getFullYear().toString();
    _this.monthSelected = monthNames[new Date().getMonth()];
    _this.yearSelected2 = new Date().getFullYear().toString();
    _this.monthSelected2 = monthNames[new Date().getMonth()];
    _this.fetch_active_company_employees();
    document.title = "Kylian ERP - View Employee";
    this.fetch_employee_assets();
    this.formData.email = this.employee.employee_email;
    this.formData.firstname = this.employee.employee_fristname;
    this.formData.phone = this.employee.employee_phone1;
    this.formData.lastname = this.employee.employee_lastname;
    this.formData.dob = this.employee.employee_date_of_birth;
    this.formData.sex = this.employee.employee_sex;
    this.formData.officialEmail = this.employee.employee_officialemail;
    this.formData.address = this.employee.employee_address;
    this.formData.state = this.employee.employee_state;
    this.formData.country = this.employee.employee_nationality;
    this.formData.experience = this.employee.experience;
    this.formData.salary = this.employee.current_salary;
    this.formData.sourceOfHire = this.employee.source_of_hire;
    this.formData.highestQualification = this.employee.Highest_qualification;
    this.formData.skillSet = this.employee.skill_set;
    this.formData.additionalInformation = this.employee.paddtional_info;
    this.allowanceFormData.allowance_status = this.employee.allowance_status == 1

    if (_this.employee) {
      axios
        .post(`${BASE_URL}fetchcandidate`, {
          company_id: _this.authCompanyData.id
        })
        .then(function(response) {
          const RESPONSE = response.data;
          console.log(RESPONSE);
          _this.allWorkExperience = RESPONSE.Work_experience;
          _this.allEducation = RESPONSE.Education;
          let tempEducation = _this.allEducation.filter(edu => {
            return edu.employee_id == _this.employee.id;
          });
          if (tempEducation.length) {
            _this.candidateSpecificEducation = tempEducation.map(edu => {
              return {
                id: edu.id,
                company_id: edu.company_id,
                company_unique_id: edu.company_unique_id,
                school_name: edu.school_name,
                degree_diploma: edu.degree_diploma,
                field_of_study: edu.field_of_study,
                date_of_completion: edu.date_of_completion,
                additional_notes: edu.additional_notes,
                employee_id: edu.employee_id,
                created_at: edu.created_at,
                updated_at: edu.updated_at,
                viewOption: false
              };
            });
          } else {
            _this.candidateSpecificEducation.push({
              id: null,
              company_id: "",
              company_unique_id: "",
              school_name: "",
              degree_diploma: "",
              field_of_study: "",
              date_of_completion: "",
              additional_notes: "",
              employee_id: "",
              created_at: "",
              updated_at: "",
              viewOption: false
            });
          }
          let tempExperience = _this.allWorkExperience.filter(exp => {
            return exp.employee_id == _this.employee.id;
          });
          if (tempExperience.length) {
            _this.candidateSpecificExperience = tempExperience.map(exp => {
              return {
                company: exp.company,
                company_id: exp.company_id,
                company_unique_id: exp.company_unique_id,
                created_at: exp.created_at,
                currently_work_here: exp.currently_work_here,
                duration: exp.duration,
                employee_id: exp.employee_id,
                field_of_study: exp.field_of_study,
                id: exp.id,
                occupation: exp.occupation,
                summary: exp.summary,
                updated_at: exp.updated_at,
                viewOption: false
              };
            });
          } else {
            _this.candidateSpecificExperience.push({
              id: null,
              company: "",
              company_id: "",
              company_unique_id: "",
              created_at: "",
              currently_work_here: "",
              duration: "",
              employee_id: "",
              field_of_study: "",
              occupation: "",
              summary: "",
              updated_at: "",
              viewOption: false
            });
          }
          _this.overlay = false;
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          // context.commit('AUTH_FETCHED_EMPLOYEES', );
        });
    }
    if (_this.authCompanyAllLeave) {
      _this.authCompanyAllLeave.forEach(lv => {
        axios
          .post(`${BASE_URL}leaveremain`, {
            company_id: _this.authCompanyData.id,
            employee_id: lv.employee_id,
            leave_type: lv.leave_type
          })
          .then(function(response) {
            _this.all_leave_remaining.push({
              id: lv.id,
              message: response.data.message
            });
          })
          .catch(function() {
            _this.all_leave_remaining.push({
              id: lv.id,
              message: "Error in evaluation"
            });
          })
          .then(function() {
            //
          });
      });
    }
    var t = this;
    t.firstYear = new Date(t.authCompanyData.created_at)
      .getFullYear()
      .toString();

    console.log(t.authEmployeeDocuments);

    t.fetch_all_employee_documents();
    t.fetch_all_fixed_allowances();
    t.fetch_all_fixed_deductions();
    t.fetch_all_employees_tax();
    t.fetch_all_employee_shifts();
    t.fetch_all_shifts();
    t.fetch_all_leave();
    t.fetch_company_pay_grade_histories();
    t.fetch_company_pay_grades();
    t.fetch_company_grade_levels();
    t.fetch_company_exit_checklists();
    t.fetch_employee_exit_requests();
  }
};
</script>

<style scoped>
#basicPie div {
  bottom: 50px;
}

.table td,
.table th {
  text-align: left;
}
</style>
<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}
</style>
