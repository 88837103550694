<template>
  <div>
    <!-- <SideMenu /> -->
    <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('tasksheets-read'))"
      class="main-content-wrap sidenav-open d-flex flex-column"
      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
      <div class="main-header pl-4"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null"
        style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('tasksheets-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                    employeeView ? "Your Tasksheets" : "All Employees' Tasksheets"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('tasksheets-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Tasksheets" : "Your Tasksheets" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' ? 'pr-0' : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 105%;' : 'width: 80%;'">
            <h1 class="my-2 row pb-0">
              <span class="col-12 col-lg-4 col-md-4">Employee Tasksheets</span>
              <v-row class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col :offset="filtered_tasksheets.length >= 1 ? 1 : 3" cols="6" lg="2" md="2" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo
                    messages=" *Filter by year" :full-width="true" v-model="yearSelected"></v-select>
                </v-col>
                <v-col cols="6" lg="3" md="3" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                    messages="*Filter by month" :full-width="true" v-model="monthSelected"></v-select>
                </v-col>
                <v-col cols="6" lg="3" md="3" class="pt-0">
                  <v-select class="remove-mb" :items="allEmployees" placeholder="Employee" dense solo
                    messages="*Select Employee" :full-width="true" v-model="employee_name"></v-select>
                </v-col>
                <v-col cols="6" lg="1" md="1" class="pt-0">
                  <button @click="reset()" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                    :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null">
                    Reset
                  </button>
                </v-col>
                <v-col v-if="filtered_tasksheets.length >= 1" cols="2" class="pt-0">
                  <download-excel :data="json_data" :fields="json_fields"
                    :worksheet="employee_name ? (employee_name + ' - ' + monthSelected + ' ' + yearSelected) : 'Employees\' tasksheets ' + (monthSelected + ' ' + yearSelected)"
                    :name="'Tasksheet for ' + (employee_name ? employee_name : 'all employees') + ' (' + monthSelected + ' ' + yearSelected + ').xls'"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Export to Excel
                  </download-excel>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" :style="
        employee_name && monthSelected && yearSelected && monthly_status == 'approved'
          ? 'border-radius: 10px; border: 4px solid #4caf50d4;'
          : employee_name && monthSelected && yearSelected && monthly_status == 'disapproved'
            ? 'border-radius: 10px; border: 4px solid #f44336e0;'
            : employee_name && monthSelected && yearSelected && monthly_status == 'pending'
              ? 'border-radius: 10px; border: 4px solid #ffc107db;'
              : ''">

                <div v-if="employee_name && monthSelected && yearSelected" class="card-header bg-white">
                  <span class="">
                    Status: <span :class="monthly_status == 'approved'
                  ? 'badge badge-square badge-square-opacity-success m-1 sm'
                  : monthly_status == 'disapproved'
                    ? 'badge badge-square badge-square-opacity-danger m-1 sm'
                    : 'badge badge-square badge-square-opacity-warning m-1 sm'">
                      <i v-if="monthly_status == 'approved'" class="fal fa-check"></i>
                      <i v-else-if="monthly_status == 'disapproved'" class="fal fa-times"></i>
                      <i v-else class="fal fa-hourglass"></i>
                    </span>
                  </span>
                  <span class="" style="top: 0px; position: relative; margin-left: 10px;">
                    Days Worked: {{ filtered_tasksheets.length }}
                  </span>
                  <div
                    v-if="(employee_name && monthSelected && yearSelected && monthly_status == 'pending') || (employee_name && monthSelected && yearSelected && monthly_status == 'disapproved')"
                    class="float-right">
                    <button
                      v-if="permissionIsAssigned('tasksheets-approve') && (monthly_status == 'disapproved' || (monthly_status == 'pending' && filtered_tasksheets.length >= 1))"
                      type="button" @click="approve_tasksheet_submit ? null : approve_tasksheets()"
                      class="btn btn-raised ripple btn-raised-secondary m-1 text-white float-right">
                      {{ approve_tasksheet_text }}
                      <v-icon :style="
                        !approve_tasksheet_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                    <button
                      v-if="permissionIsAssigned('tasksheets-approve') && (monthly_status == 'approved' || (monthly_status == 'pending' && filtered_tasksheets.length >= 1))"
                      type="button" @click="disapprove_tasksheet_submit ? null : disapprove_tasksheets()"
                      class="btn btn-raised ripple btn-raised-secondary m-1 text-white float-right">
                      {{ disapprove_tasksheet_text }}
                      <v-icon :style="
                        !disapprove_tasksheet_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                    <!-- <button class="btn btn-outline-secondary m-1 float-right" type="button">
                      Comment
                    </button> -->
                  </div>
                </div>
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th class="text-left" scope="col">Date</th>
                          <th class="text-left" scope="col">
                            Name of Counsel
                          </th>
                          <th class="text-left" scope="col">Status</th>
                          <th class="text-left" scope="col">Service Title</th>
                          <th class="text-left" scope="col">Service Description</th>
                          <th class="text-left" scope="col">Time Spent in Hour(s)/Min(s)</th>
                          <th class="text-left" scope="col">Time Spent in Min(s)</th>
                          <th class="text-left" scope="col">Hourly Rate ($)</th>
                          <th class="text-left" scope="col">Amount Due ($)</th>
                          <!-- <th class="text-left" scope="col">File Attachment</th> -->
                          <!-- <th class="text-left" scope="col">Check-in</th>
                          <th class="text-left" scope="col">Check-out</th>
                          <th class="text-left" scope="col">Duration</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="tasksheet in filtered_tasksheets" :key="tasksheet.id">
                          <th @click="set_date(tasksheet.date)" class="underline text-left" scope="row">
                            {{
                                new Date(tasksheet.date)
                                  .toUTCString()
                                  .substring(0, 16)
                            }}
                          </th>
                          <th @click="
                            employee_name =
                            tasksheet.employee_fristname +
                            ' ' +
                            tasksheet.employee_lastname
                          " class="underline text-left" scope="row">
                            {{
                                tasksheet.employee_fristname +
                                " " +
                                tasksheet.employee_lastname
                            }}
                          </th>

                          <td v-if="getDesignationById(tasksheet.designation_id)" class="text-left">{{
                              getDesignationById(tasksheet.designation_id).title
                          }}</td>
                          <td v-else class="text-left"><i>Unspecified designation</i></td>

                          <td class="text-left">
                            {{ tasksheet.project_title }}
                          </td>

                          <td class="text-left">
                            {{ tasksheet.work_done }}
                          </td>

                          <td class="text-left">{{ tasksheet.hour }}</td>

                          <td class="text-left">{{ tasksheet.minutes }}</td>

                          <td class="text-left" v-if="getDesignationById(tasksheet.designation_id)">
                            ${{
                                (getDesignationById(tasksheet.designation_id).rate || 0) |
                                number_with_commas
                            }}
                          </td>
                          <td class="text-left" v-else><i>No hourly rate specified</i></td>

                          <td class="text-left" v-if="getDesignationById(tasksheet.designation_id)">
                            ${{
                                (getAmountDue(getDesignationById(tasksheet.designation_id).rate,
                                  tasksheet.minutes) || 0) |
                                number_with_commas
                            }}
                          </td>
                          <td class="text-left" v-else><i>No hourly rate specified</i></td>

                          <!-- <td class="text-left" v-if="tasksheet.fileurl">
                            <a :href="assets_url + tasksheet.fileurl" target="_blank" rel="noopener noreferrer">
                              View Attachment
                            </a>
                          </td>
                          <td class="text-left" v-else><i>No file attachment</i></td> -->

                          <!-- <td class="text-left" v-if="tasksheet.check_in && tasksheet.check_in != 'No checkin'">
                            {{ tasksheet.check_in.split(' ').length == 1 ? tasksheet.check_in :
                                tasksheet.check_in.split(' ')[1]
                            }}
                          </td>
                          <td class="text-left" v-else><i>No checkin</i></td>
                          <td class="text-left" v-if="tasksheet.check_out && tasksheet.check_out != 'No checkout'">
                            {{ tasksheet.check_out.split(' ').length == 1 ? tasksheet.check_out :
                                tasksheet.check_out.split(' ')[1]
                            }}
                          </td>
                          <td class="text-left" v-else><i>No checkout</i></td>
                          <td class="text-left" v-if="tasksheet.check_duration != 'No checkout'">
                            {{ tasksheet.check_duration }}
                          </td>
                          <td class="text-left" v-else><i>No checkout</i></td> -->
                        </tr>
                        <tr v-if="filtered_tasksheets.length">
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td style="font-weight: bold;" class="text-left">
                            TOTAL PROFESSIONAL FEES
                          </td>
                          <td style="font-weight: bold;" class="text-left">
                            ${{ totalAmountDue || 0 | number_with_commas }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-dialog v-model="tasksheet_comment" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ approval ? 'Approve' : 'Disapprove' }} Tasksheet with Comment</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" class="remove-hint">
                        <span style="color: #111d5e;">Comment for {{ employee_name }} ({{ monthSelected }})</span>
                        <v-textarea v-model="comment_to_add" placeholder="Comment" solo class="mt-2"
                          :disabled="userType != 1 && !permissionIsAssigned('tasksheets-approve') ? true : false">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                        <button @click="add_tasksheet_comment_submit ? null : add_comment()" style="width: 99%;"
                          type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                          {{ add_tasksheet_comment_text }}
                          <v-icon :style="
                            !add_tasksheet_comment_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fal fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="tasksheet_comment = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column"
      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('tasksheets-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                    employeeView ? "Your Tasksheets" : "All Employees' Tasksheets"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('tasksheets-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Tasksheets" : "Your Tasksheets" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' ? 'pr-0' : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 105%;' : 'width: 80%;'">
            <h1 class="my-0 row pb-0">
              <span class="col-12 col-lg-4 col-md-4">Your Tasksheets</span>
              <v-row v-if="userType == 1" class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col offset-md="1" offset-lg="1" md="3" lg="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo
                    messages=" *Filter by year" :full-width="true" v-model="yearSelected"></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                    messages="*Filter by month" :full-width="true" v-model="monthSelected"></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="allEmployees" placeholder="Employee" dense solo
                    messages="*Select Employee" :full-width="true" v-model="employee_name"></v-select>
                </v-col>
                <v-col lg="2" md="2" cols="12" class="pt-0">
                  <button
                    v-if="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved')"
                    @click="add_tasksheet_dialog = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Add Tasksheet
                  </button>
                </v-col>
              </v-row>
              <v-row v-else class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col
                  :offset-md="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved') ? '4' : '6'"
                  :offset-lg="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved') ? '4' : '6'"
                  md="3" lg="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo
                    messages=" *Filter by year" :full-width="true" v-model="yearSelected"></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                    messages="*Filter by month" :full-width="true" v-model="monthSelected"></v-select>
                </v-col>
                <v-col
                  v-if="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved')"
                  lg="2" md="2" cols="12" class="pt-0">
                  <button @click="add_tasksheet_dialog = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Add Tasksheet
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" :style="
        authEmployeeDataForCompany.id && monthSelected && yearSelected && monthly_status == 'approved'
          ? 'border-radius: 10px; border: 4px solid #4caf50d4;'
          : authEmployeeDataForCompany.id && monthSelected && yearSelected && monthly_status == 'disapproved'
            ? 'border-radius: 10px; border: 4px solid #f44336e0;'
            : authEmployeeDataForCompany.id && monthSelected && yearSelected && monthly_status == 'pending'
              ? 'border-radius: 10px; border: 4px solid #ffc107db;'
              : ''">

                <div class="card-header bg-white">
                  <span class="">
                    Status: <span :class="monthly_status == 'approved'
                  ? 'badge badge-square badge-square-opacity-success m-1 sm'
                  : monthly_status == 'disapproved'
                    ? 'badge badge-square badge-square-opacity-danger m-1 sm'
                    : 'badge badge-square badge-square-opacity-warning m-1 sm'">
                      <i v-if="monthly_status == 'approved'" class="fal fa-check"></i>
                      <i v-else-if="monthly_status == 'disapproved'" class="fal fa-times"></i>
                      <i v-else class="fal fa-hourglass"></i>
                    </span>
                  </span>
                  <span class="" style="top: 0px; position: relative; margin-left: 10px;">
                    Days Worked: {{ filtered_tasksheets.length }}
                  </span>
                  <div class="float-right" v-if="filtered_tasksheets.length >= 1 && submitted == false">
                    <button
                      v-if="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved')"
                      class="btn btn-secondary m-1 float-right" type="button"
                      @click="submit_tasksheet_submit ? null : submit_tasksheet()">
                      {{ submit_tasksheet_text }}
                      <v-icon :style="
                        !submit_tasksheet_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </div>
                  <div class="float-right" v-if="filtered_tasksheets.length >= 1 && submitted == true">
                    <button style="cursor: not-allowed;" class="btn btn-secondary m-1 float-right disabled"
                      type="button">
                      Submitted
                    </button>
                  </div>
                  <div class="float-right" v-if="comment_for_month.length >= 1">
                    <button class="btn btn-outline-secondary m-1 float-right" type="button"
                      @click="tasksheet_comment = true">
                      View Comment
                    </button>
                  </div>
                </div>
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th class="text-left" scope="col">Date</th>
                          <th class="text-left" v-if="userType == 1" scope="col">
                            Name of Counsel
                          </th>
                          <th class="text-left" scope="col">Status</th>
                          <th class="text-left" scope="col">Service Title</th>
                          <th class="text-left" scope="col">Service Description</th>
                          <th class="text-left" scope="col">Time Spent in Hour(s)/Min(s)</th>
                          <th class="text-left" scope="col">Time Spent in Min(s)</th>
                          <th class="text-left" scope="col">Hourly Rate ($)</th>
                          <th class="text-left" scope="col">Amount Due ($)</th>
                          <!-- <th class="text-left" scope="col">File Attachment</th> -->
                          <!-- <th
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            scope="col">Add Attachment</th> -->
                          <th
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            scope="col">Edit</th>
                          <th
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="tasksheet in filtered_tasksheets" :key="tasksheet.id">
                          <td class="text-left" @click="set_date(tasksheet.date)" scope="row">
                            {{
                                new Date(tasksheet.date)
                                  .toUTCString()
                                  .substring(0, 16)
                            }}
                          </td>

                          <td v-if="getDesignationById(tasksheet.designation_id)" class="text-left">{{
                              getDesignationById(tasksheet.designation_id).title
                          }}</td>
                          <td v-else class="text-left"><i>Unspecified designation</i></td>

                          <td class="text-left">
                            {{ tasksheet.project_title }}
                          </td>

                          <td class="text-left">
                            {{ tasksheet.work_done }}
                          </td>

                          <td class="text-left">{{ tasksheet.hour }}</td>

                          <td class="text-left">{{ tasksheet.minutes }}</td>

                          <td class="text-left" v-if="getDesignationById(tasksheet.designation_id)">
                            ${{
                                (getDesignationById(tasksheet.designation_id).rate || 0) |
                                number_with_commas
                            }}
                          </td>
                          <td class="text-left" v-else><i>No hourly rate specified</i></td>

                          <td class="text-left" v-if="getDesignationById(tasksheet.designation_id)">
                            ${{
                                (getAmountDue(getDesignationById(tasksheet.designation_id).rate,
                                  tasksheet.minutes) || 0) |
                                number_with_commas
                            }}
                          </td>
                          <td class="text-left" v-else><i>No hourly rate specified</i></td>

                          <!-- <td class="text-left" v-if="tasksheet.fileurl">
                            <a :href="assets_url + tasksheet.fileurl" target="_blank" rel="noopener noreferrer">
                              View Attachment
                            </a>
                          </td>
                          <td class="text-left" v-else><i>No file attachment</i></td> -->

                          <!-- <td
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            class="pl-0 text-left">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <i v-bind="attrs" v-on="on"
                                  @click="(attachment_tasksheet_id = tasksheet.id, fileAttachmentDialog = true)"
                                  class="col-3 offset-3 py-0 text-center fal fa-file-plus"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              </template>
                              <span>Add Attachment</span>
                            </v-tooltip>
                          </td> -->

                          <td
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            class="pl-0 text-center">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <i v-bind="attrs" v-on="on" @click="launch_edit_tasksheet(tasksheet)"
                                  class="py-0 text-center fal fa-edit"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              </template>
                              <span>Edit Tasksheet</span>
                            </v-tooltip>
                          </td>

                          <td
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            class="pl-0 text-left">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <i v-bind="attrs" v-on="on" @click="delete_tasksheet(tasksheet)"
                                  class="col-3 offset-3 py-0 text-center fal fa-trash"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              </template>
                              <span>Delete Tasksheet</span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr v-if="filtered_tasksheets.length">
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            class="text-left"></td>
                          <td
                            v-if="userType != 1 && ((monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved'))"
                            class="text-left"></td>
                          <td style="font-weight: bold;" class="text-left">
                            TOTAL PROFESSIONAL FEES
                          </td>
                          <td style="font-weight: bold;" class="text-left">
                            ${{ totalAmountDue || 0 | number_with_commas }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-dialog v-model="add_tasksheet_dialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Add Tasksheet</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Date <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                          offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                              v-model="formData.date" placeholder="Select a date" solo class="mt-2" dense readonly
                              v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker color="#069" ref="picker" v-model="formData.date" :min="
                            new Date(firstDayOfLastMonth.getTime())
                              .toISOString()
                              .substring(0, 10)
                          " :max="
  new Date(new Date().getTime())
    .toISOString()
    .substring(0, 10)
" @change="save"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Project Title <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="formData.project_title" placeholder="Enter project title" solo
                          class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Place of Performance <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="formData.place_performance" placeholder="Enter place of performance" solo
                          class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Designation <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-select v-model="formData.employee_designation" item-color="#069" :items="trueDesignations"
                          placeholder="Designation" solo dense class="mt-2" color="#069"></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="remove-hint">
                        <span style="color: #111d5e;">Work Done <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-textarea v-model="formData.work_done" placeholder="Enter work done" solo class="mt-2">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <h2>Start:</h2>
                        <v-time-picker v-model="formData.start"></v-time-picker>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <h2>End:</h2>
                        <v-time-picker v-model="formData.end"></v-time-picker>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                        <button @click="add_tasksheet_submit ? null : add_tasksheet()" style="width: 99%;" type="button"
                          class="btn btn-secondary m-1 text-white btn-raised ripple">
                          {{ add_tasksheet_text }}
                          <v-icon :style="
                            !add_tasksheet_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fal fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="add_tasksheet_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="edit_tasksheet_dialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Tasksheet</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Date <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                              v-model="editFormData.date" placeholder="Date" :disabled="false" dense solo class="mt-2"
                              readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker color="#069" ref="picker2" v-model="editFormData.date" :disabled="false" :min="
                            new Date(firstDayOfLastMonth.getTime())
                              .toISOString()
                              .substring(0, 10)
                          " :max="
  new Date(new Date().getTime())
    .toISOString()
    .substring(0, 10)
" @change="save2"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Project Title <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="editFormData.project_title" placeholder="Project Title" solo class="mt-2"
                          dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Place of Performance <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="editFormData.place_performance" placeholder="Place of Performance" solo
                          class="mt-2" dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Designation <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-select v-model="editFormData.employee_designation" item-color="#069"
                          :items="trueDesignations" placeholder="Designation" solo dense class="mt-2"
                          color="#069"></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="remove-hint">
                        <span style="color: #111d5e;">Work Done <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-textarea v-model="editFormData.work_done" placeholder="Work Done" solo class="mt-2">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <h2>Start:</h2>
                        <v-time-picker v-model="editFormData.start"></v-time-picker>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <h2>End:</h2>
                        <v-time-picker v-model="editFormData.end"></v-time-picker>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                        <button @click="update_tasksheet_submit ? null : update_tasksheet()" style="width: 99%;"
                          type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                          {{ update_tasksheet_text }}
                          <v-icon :style="
                            !update_tasksheet_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fal fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="edit_tasksheet_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="tasksheet_comment" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ approval ? 'Approve' : 'Disapprove' }} Tasksheet with Comment</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" class="remove-hint">
                        <v-textarea v-model="comment_to_add"
                          :placeholder="userType == 1 || permissionIsAssigned('tasksheets-approve') ? 'Comment for ' + employee_name + ' (' + monthSelected + ')' : ''"
                          solo class="mt-2"
                          :disabled="userType != 1 && !permissionIsAssigned('tasksheets-approve') ? true : false">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                        <button v-if="userType == 1 || permissionIsAssigned('tasksheets-approve')"
                          @click="add_tasksheet_comment_submit ? null : add_comment()" style="width: 99%;" type="button"
                          class="btn btn-secondary m-1 text-white btn-raised ripple">
                          {{ add_tasksheet_comment_text }}
                          <v-icon :style="
                            !add_tasksheet_comment_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fal fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="tasksheet_comment = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="fileAttachmentDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">File Attachment</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint">
                        <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                          label="Upload File Attachment" solo style="text-indent: 5px;" color="#069"
                          class="fileAttachment" messages="File Attachment">
                        </v-file-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="fileAttachmentBtnSubmit ? null : upload_file_attachment()" style="width: 100%;"
                          type="button" class="btn btn-secondary m-1 ripple">
                          {{ fileAttachmentBtnText }}
                          <v-icon :style="
                            !fileAttachmentBtnSubmit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fas fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="fileAttachmentDialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      attachment_tasksheet_id: null,
      fileAttachmentBtnSubmit: false,
      fileAttachmentBtnText: 'Upload',
      fileAttachmentDialog: false,
      employeeView: true,
      approval: true,
      submit_tasksheet_text: "Submit",
      submit_tasksheet_submit: false,
      comment_to_add: "",
      formData: {
        date: "",
        project_title: "",
        place_performance: "",
        start: "",
        end: "",
        work_done: "",
        employee_designation: ""
      },
      editFormData: {
        tasksheet_id: "",
        date: "",
        project_title: "",
        place_performance: "",
        start: "",
        end: "",
        work_done: "",
        employee_designation: ""
      },
      tasksheet_comment: false,
      add_tasksheet_comment_text: "Submit",
      add_tasksheet_comment_submit: false,
      approve_tasksheet_text: "Approve",
      approve_tasksheet_submit: false,
      disapprove_tasksheet_text: "Disapprove",
      disapprove_tasksheet_submit: false,
      add_tasksheet_text: "Submit",
      add_tasksheet_submit: false,
      update_tasksheet_text: "Update",
      update_tasksheet_submit: false,
      add_tasksheet_dialog: false,
      edit_tasksheet_dialog: false,
      employee_name: "",
      employee: null,
      menu: false,
      menu2: false,
      day_selected: "",
      view: "date", // also day and employee
      day_attendance: [],
      checkingSingleDetails: [],
      monthSelected: "",
      yearSelected: "",
      checkingDetails: null,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      firstYear: "",
      allEmployees: [],
      comments: [],
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  watch: {
    tasksheet_comment: function (t) {
      0 == t && (this.comment_to_add = this.comment_for_month[0].comments);
    },
    comment_for_month: function (t) {
      t && t.length >= 1 ? (this.comment_to_add = t[0].comments) : (this.comment_to_add = "");
    },
    employee_name: function (t) {
      t && this.fetch_tasksheet_comment();
    },
    monthSelected: function (t) {
      1 == this.userType ? t && this.employee_name && this.yearSelected && this.fetch_tasksheet_comment() : t && this.authEmployeeDataForCompany.id && this.yearSelected && this.fetch_tasksheet_comment();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authCompanyAllCheckin",
      "authCompanyTaskSheets",
      "authEmployeeTaskSheets",
      "authEmployeeDataForCompany",
      "authDesignations",
      "authCheckinAllTime"
    ]),
    assets_url() {
      return ASSETS_URL;
    },
    json_fields: function () {
      return {
        Date: "date",
        "Name of Counsel": "name_of_counsel",
        "Status": "status",
        "Service Title": "project_title",
        "Service Description": "work_done",
        "Time Spent in Hour(s)/Min(s)": "hour",
        "Time Spent in Min(s)": "minutes",
        "Hourly Rate ($)": "hourly_rate",
        "Amount Due ($)": "amount_due"
      };
    },
    firstDayOfLastMonth() {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth()-1);
      return date;
    },
    trueDesignations() {
      let _this = this, designations = [];
      if (_this.authDesignations) {
        designations = _this.authDesignations.map(d => {
          return d.title;
        })
      }
      return designations;
    },
    totalAmountDue() {
      let _this = this, value = 0;
      _this.filtered_tasksheets.forEach(tasksheet => {
        value += _this.getAmountDue(_this.designationObject(_this.employeeObject(tasksheet.employee_id).employee_designation).rate, tasksheet.minutes);
      });
      return value;
    },
    currentDateToUse() {
      return new Date().toLocaleDateString();
    },
    addTasksheetDurationInMinutes() {
      let _this = this, startDate, endDate, diffInMilli, value = 0;
      if (_this.formData.start && _this.formData.end) {
        startDate = new Date(`${_this.currentDateToUse} ${_this.formData.start}`);
        endDate = new Date(`${_this.currentDateToUse} ${_this.formData.end}`);
        diffInMilli = endDate.getTime() - startDate.getTime();
        if (diffInMilli < 1) {
          return value;
        }
        value = diffInMilli / 1000 / 60;
      }
      return value;
    },
    addTasksheetDurationInHours() {
      let _this = this, value = 0, remainder = 0, result = '';
      if (_this.addTasksheetDurationInMinutes == 0) {
        return value;
      }
      value = Math.floor(_this.addTasksheetDurationInMinutes / 60);
      remainder = _this.addTasksheetDurationInMinutes % 60;
      if (value >= 1) {
        if (remainder) {
          result = `${value} ${value == 1 ? 'hour' : 'hours'} ${remainder} ${remainder == 1 ? 'minute' : 'minutes'}`;
        } else {
          result = `${value} ${value == 1 ? 'hour' : 'hours'}`;
        }
      } else {
        result = `${remainder} ${remainder == 1 ? 'minute' : 'minutes'}`
      }
      return result;
    },
    editTasksheetDurationInMinutes() {
      let _this = this, startDate, endDate, diffInMilli, value = 0;
      if (_this.editFormData.start && _this.editFormData.end) {
        startDate = new Date(`${_this.currentDateToUse} ${_this.editFormData.start}`);
        endDate = new Date(`${_this.currentDateToUse} ${_this.editFormData.end}`);
        diffInMilli = endDate.getTime() - startDate.getTime();
        if (diffInMilli < 1) {
          return value;
        }
        value = diffInMilli / 1000 / 60;
      }
      return value;
    },
    editTasksheetDurationInHours() {
      let _this = this, value = 0, remainder = 0, result = '';
      if (_this.editTasksheetDurationInMinutes == 0) {
        return value;
      }
      value = Math.floor(_this.editTasksheetDurationInMinutes / 60);
      remainder = _this.editTasksheetDurationInMinutes % 60;
      if (value >= 1) {
        if (remainder) {
          result = `${value} ${value == 1 ? 'hour' : 'hours'} ${remainder} ${remainder == 1 ? 'minute' : 'minutes'}`;
        } else {
          result = `${value} ${value == 1 ? 'hour' : 'hours'}`;
        }
      } else {
        result = `${remainder} ${remainder == 1 ? 'minute' : 'minutes'}`
      }
      return result;
    },
    attendance() {
      if (!this.checkingDetails) {
        return this.authCompanyAllCheckin.attendance;
      } else {
        return this.checkingDetails;
      }
    },
    json_data: function () {
      let _this = this, tasksheets_data = [];
      tasksheets_data = _this.filtered_tasksheets.map(tasksheet => {
        return {
          date: new Date(tasksheet.date).toUTCString().substring(0, 16),
          name_of_counsel: tasksheet.employee_fristname + ' ' + tasksheet.employee_lastname,
          status: _this.getDesignationById(tasksheet.designation_id) ? _this.getDesignationById(tasksheet.designation_id).title : null,
          project_title: tasksheet.project_title,
          work_done: tasksheet.work_done,
          hour: tasksheet.hour,
          minutes: tasksheet.minutes,
          hourly_rate: _this.designationObject(_this.employeeObject(tasksheet.employee_id).employee_designation) ? (_this.designationObject(_this.employeeObject(tasksheet.employee_id).employee_designation).rate || 0) : '',
          amount_due: _this.designationObject(_this.employeeObject(tasksheet.employee_id).employee_designation) ? (_this.getAmountDue(_this.designationObject(_this.employeeObject(tasksheet.employee_id).employee_designation).rate, tasksheet.minutes) || 0) : ''
        }
      });
      tasksheets_data.splice(
        tasksheets_data.length,
        0,
        {
          date: '',
          name_of_counsel: '',
          status: '',
          work_done: '',
          hour: '',
          minutes: '',
          hourly_rate: 'TOTAL PROFESSIONAL FEES',
          amount_due: _this.totalAmountDue || 0,
        }
      );
      return tasksheets_data;
    },
    comment_for_month: function () {
      var t = this;
      return t.comments.length >= 1
        ? t.comments.filter(function (e) {
          return e.year == t.yearSelected && e.month == t.monthSelected && e.employee_id == (t.employee_id || t.authEmployeeDataForCompany.id);
        })
        : [];
    },
    monthly_status: function () {
      var t,
        e,
        a = this;
      return 0 == a.filtered_tasksheets.length
        ? "pending"
        : a.filtered_tasksheets &&
          ((t = a.filtered_tasksheets.filter(function (t) {
            return 1 == t.status;
          })),
            t.length == a.filtered_tasksheets.length)
          ? "approved"
          : a.filtered_tasksheets &&
            ((e = a.filtered_tasksheets.filter(function (t) {
              return 2 == t.status;
            })),
              e.length == a.filtered_tasksheets.length)
            ? "disapproved"
            : "pending";
    },
    filtered_tasksheets: function () {
      var t = this;
      if (t.authCompanyTaskSheets.length || t.authEmployeeTaskSheets.length) {
        if (t.authCompanyTaskSheets) {
          if (t.userType != 1 && t.employeeView) {
            return t.authEmployeeTaskSheets.filter(function (ts) {
              return ts.month_name == t.monthSelected && ts.year == t.yearSelected && ts.employee_id == t.authEmployeeDataForCompany.id
            })
          }
          if (t.day_selected && t.employee_name) {
            t.day_selected = "";
            var e = t.authCompanyTaskSheets.filter(function (e) {
              return e.month_name == t.monthSelected && e.year == t.yearSelected && t.employee_name == e.employee_fristname + " " + e.employee_lastname;
            });
            e = e.map(ts => {
              return {
                claimed_expenses: ts.claimed_expenses || 'No claimed expenses',
                comment: ts.comment,
                company_id: ts.company_id,
                created_at: ts.created_at,
                date: ts.date,
                day: ts.day,
                details: ts.details,
                employee_fristname: ts.employee_fristname,
                employee_id: ts.employee_id,
                employee_lastname: ts.employee_lastname,
                fileurl: ts.fileurl,
                id: ts.id,
                is_submitted: ts.is_submitted,
                month: ts.month,
                month_name: ts.month_name,
                payment_advanced: ts.payment_advanced || 'No advance payment',
                place_performance: ts.place_performance,
                project_title: ts.project_title,
                report_submitted: ts.report_submitted || 'No reports',
                status: ts.status,
                updated_at: ts.updated_at,
                work_done: ts.work_done,
                year: ts.year,
                hour: ts.hour,
                minutes: ts.minutes,
                check_in: t.get_check_in(ts.employee_id, ts.date) != null ? (t.get_check_in(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_in(ts.employee_id, ts.date) : t.get_check_in(ts.employee_id, ts.date).split(' ')[1]) : 'No checkin' || 'No checkin',
                check_out: t.get_check_out(ts.employee_id, ts.date) != null ? (t.get_check_out(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_out(ts.employee_id, ts.date) : t.get_check_out(ts.employee_id, ts.date).split(' ')[1]) : 'No checkout' || 'No checkout',
                check_duration: t.get_check_duration(ts.employee_id, ts.date) || 'No checkout'
              }
            })
            return e;
          }
          if (t.day_selected) {
            var a = t.authCompanyTaskSheets.filter(function (e) {
              return e.date == t.day_selected;
            });
            a = a.map(ts => {
              return {
                claimed_expenses: ts.claimed_expenses || 'No claimed expenses',
                comment: ts.comment,
                company_id: ts.company_id,
                created_at: ts.created_at,
                date: ts.date,
                day: ts.day,
                details: ts.details,
                employee_fristname: ts.employee_fristname,
                employee_id: ts.employee_id,
                employee_lastname: ts.employee_lastname,
                fileurl: ts.fileurl,
                id: ts.id,
                is_submitted: ts.is_submitted,
                month: ts.month,
                month_name: ts.month_name,
                payment_advanced: ts.payment_advanced || 'No advance payment',
                place_performance: ts.place_performance,
                project_title: ts.project_title,
                report_submitted: ts.report_submitted || 'No reports',
                status: ts.status,
                updated_at: ts.updated_at,
                work_done: ts.work_done,
                year: ts.year,
                hour: ts.hour,
                minutes: ts.minutes,
                check_in: t.get_check_in(ts.employee_id, ts.date) != null ? (t.get_check_in(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_in(ts.employee_id, ts.date) : t.get_check_in(ts.employee_id, ts.date).split(' ')[1]) : 'No checkin' || 'No checkin',
                check_out: t.get_check_out(ts.employee_id, ts.date) != null ? (t.get_check_out(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_out(ts.employee_id, ts.date) : t.get_check_out(ts.employee_id, ts.date).split(' ')[1]) : 'No checkout' || 'No checkout',
                check_duration: t.get_check_duration(ts.employee_id, ts.date) || 'No checkout'
              }
            })
            return a;
          }
          if (t.employee_name) {
            var s = t.authCompanyTaskSheets.filter(function (e) {
              return e.month_name == t.monthSelected && e.year == t.yearSelected && t.employee_name == e.employee_fristname + " " + e.employee_lastname;
            });
            s = s.map(ts => {
              return {
                claimed_expenses: ts.claimed_expenses || 'No claimed expenses',
                comment: ts.comment,
                company_id: ts.company_id,
                created_at: ts.created_at,
                date: ts.date,
                day: ts.day,
                details: ts.details,
                employee_fristname: ts.employee_fristname,
                employee_id: ts.employee_id,
                employee_lastname: ts.employee_lastname,
                fileurl: ts.fileurl,
                id: ts.id,
                is_submitted: ts.is_submitted,
                month: ts.month,
                month_name: ts.month_name,
                payment_advanced: ts.payment_advanced || 'No advance payment',
                place_performance: ts.place_performance,
                project_title: ts.project_title,
                report_submitted: ts.report_submitted || 'No reports',
                status: ts.status,
                updated_at: ts.updated_at,
                work_done: ts.work_done,
                year: ts.year,
                hour: ts.hour,
                minutes: ts.minutes,
                check_in: t.get_check_in(ts.employee_id, ts.date) != null ? (t.get_check_in(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_in(ts.employee_id, ts.date) : t.get_check_in(ts.employee_id, ts.date).split(' ')[1]) : 'No checkin' || 'No checkin',
                check_out: t.get_check_out(ts.employee_id, ts.date) != null ? (t.get_check_out(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_out(ts.employee_id, ts.date) : t.get_check_out(ts.employee_id, ts.date).split(' ')[1]) : 'No checkout' || 'No checkout',
                check_duration: t.get_check_duration(ts.employee_id, ts.date) || 'No checkout'
              }
            })
            return s;
          }
          var i = t.authCompanyTaskSheets.filter(function (e) {
            return e.month_name == t.monthSelected && e.year == t.yearSelected;
          });
          console.log('THIS IS IT');
          i = i.map(ts => {
            return {
              claimed_expenses: ts.claimed_expenses || 'No claimed expenses',
              comment: ts.comment,
              company_id: ts.company_id,
              created_at: ts.created_at,
              date: ts.date,
              day: ts.day,
              details: ts.details,
              employee_fristname: ts.employee_fristname,
              employee_id: ts.employee_id,
              employee_lastname: ts.employee_lastname,
              fileurl: ts.fileurl,
              id: ts.id,
              is_submitted: ts.is_submitted,
              month: ts.month,
              month_name: ts.month_name,
              payment_advanced: ts.payment_advanced || 'No advance payment',
              place_performance: ts.place_performance,
              project_title: ts.project_title,
              report_submitted: ts.report_submitted || 'No reports',
              status: ts.status,
              updated_at: ts.updated_at,
              work_done: ts.work_done,
              year: ts.year,
              hour: ts.hour,
              minutes: ts.minutes,
              check_in: t.get_check_in(ts.employee_id, ts.date) != null ? (t.get_check_in(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_in(ts.employee_id, ts.date) : t.get_check_in(ts.employee_id, ts.date).split(' ')[1]) : 'No checkin' || 'No checkin',
              check_out: t.get_check_out(ts.employee_id, ts.date) != null ? (t.get_check_out(ts.employee_id, ts.date).split(' ').length == 1 ? t.get_check_out(ts.employee_id, ts.date) : t.get_check_out(ts.employee_id, ts.date).split(' ')[1]) : 'No checkout' || 'No checkout',
              check_duration: t.get_check_duration(ts.employee_id, ts.date) || 'No checkout'
            }
          })
          return i;
        }
        var o = this;
        if (o.day_selected) {
          var n = o.authEmployeeTaskSheets.filter(function (t) {
            return t.date == o.day_selected;
          });
          return n;
        }
        if (o.employee_name) {
          var l = o.authEmployeeTaskSheets.filter(function (t) {
            return t.month_name == o.monthSelected && t.year == o.yearSelected && o.employee_name == t.employee_fristname + " " + t.employee_lastname;
          });
          return l;
        }
        var r = o.authEmployeeTaskSheets.filter(function (t) {
          return t.month_name == o.monthSelected && t.year == o.yearSelected;
        });
        return r;
      } else {
        return [];
      }
    },
    month: function () {
      var t = new Date();
      return this.monthNames[t.getMonth()];
    },
    allYears: function () {
      var t = [],
        e = new Date().getFullYear();
      t.push(this.firstYear);
      for (var a = this.firstYear; a <= e; a++) if (t.indexOf(a) == -1) { t.push(a.toString()); }
      return t.reverse();
    },
    all_filtered_ts_ids: function () {
      var t = this;
      return t.filtered_tasksheets.map(function (t) {
        return { id: t.id };
      });
    },
    employee_id: function () {
      var t = this;
      return t.mixinBranchEmployees(t.$route.name) && t.employee_name
        ? t.mixinBranchEmployees(t.$route.name).filter(function (e) {
          return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
        })[0].id
        : null;
    },
    submitted() {
      let _this = this, all_submitted = [];
      if (_this.monthly_status == 'disapproved') {
        return false;
      }
      if (_this.filtered_tasksheets) {
        all_submitted = _this.filtered_tasksheets.filter(ft => {
          return ft.is_submitted == 1;
        });
        if (all_submitted.length == _this.filtered_tasksheets.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return true
      }
    },
  },
  methods: {
    ...mapActions([
      "fetch_employee_tasksheets",
      "fetch_company_tasksheets",
      "fetch_all_check_in_all_time",
      "fetch_all_designations"
    ]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    getDesignationById(id) {
      let _this = this, result = null;
      result = _this.authDesignations.filter(d => d.id == id);
      if (result.length) {
        return result[0];
      }
      return null;
    },
    getAmountDue(rate, minutes) {
      let value = 0;
      value = (parseFloat(minutes) / 60) * parseFloat(rate);
      return value;
    },
    designationObject(designation) {
      let _this = this,
        result = null;
      if (_this.authDesignations) {
        result = _this.authDesignations.filter((d) => {
          return d.title === designation;
        });
        if (result.length) {
          result = result[0];
        }
      }
      return result;
    },
    employeeObject(id) {
      let _this = this, result = null;
      result = _this.mixinBranchEmployees(_this.$route.name).filter(e => e.id == id);
      if (result.length >= 1) {
        result = result[0];
      }
      return result;
    },
    upload_file_attachment() {
      let _this = this;
      _this.fileAttachmentBtnText = "";
      _this.fileAttachmentBtnSubmit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let formData = new FormData();
      formData.append(
        "tasksheet_id",
        _this.attachment_tasksheet_id
      );
      formData.append(
        "file",
        document.querySelector(".fileAttachment div div div input").files[0]
      );
      formData.append(
        "company_id",
        _this.authCompanyData.id
      );
      formData.append(
        "employee_id",
        _this.validEmployeeId
      );

      axios
        .post(`${BASE_URL}tasksheetattachment`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Tasksheet attachment uploaded successfully"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.fetch_company_tasksheets();
          _this.fetch_employee_tasksheets();
          _this.fileAttachmentDialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.file ? errors.file[0] : null) : null
              || error.response.data.error || error.response.data.message || 'File attachment could not be uploaded'
          });
        })
        .then(function () {
          _this.fileAttachmentBtnSubmit = false;
          _this.fileAttachmentBtnText = "Upload";
        });
    },
    get_check_in(employee_id, day) {
      let _this = this, data;
      if (_this.authCheckinAllTime) {
        data = _this.authCheckinAllTime.filter(checkin => {
          return checkin.employee_id == employee_id && checkin.day == day
        });
        if (data.length >= 1) {
          let result;
          result = data[0].check_in;
          return result;
        }
      }
      return null;
    },
    get_check_out(employee_id, day) {
      let _this = this, data;
      if (_this.authCheckinAllTime) {
        data = _this.authCheckinAllTime.filter(checkin => {
          return checkin.employee_id == employee_id && checkin.day == day
        });
        if (data.length >= 1) {
          let result;
          result = data[0].check_out;
          return result;
        }
      }
      return null;
    },
    get_check_duration(employee_id, day) {
      let _this = this, data;
      if (_this.authCheckinAllTime) {
        data = _this.authCheckinAllTime.filter(checkin => {
          return checkin.employee_id == employee_id && checkin.day == day
        });
        if (data.length >= 1) {
          return data[0].duration;
        }
      }
      return null;
    },
    submit_tasksheet() {
      var t = this;
      t.submit_tasksheet_text = "";
      t.submit_tasksheet_submit = true;
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      t.$swal.fire({ title: "Are you sure you want to submit this tasksheet?", showCancelButton: true, confirmButtonText: "Yes, Submit" }).then(function (s) {
        if (s.isConfirmed) {
          axios
            .post(`${BASE_URL}submittasksheet`, {
              company_id: t.authEmployeeDataForCompany.company_id,
              employee_id: t.authEmployeeDataForCompany.id,
              month: t.monthSelected,
              year: t.yearSelected
            })
            .then(function () {
              t.fetch_employee_tasksheets();
              t.fetch_company_tasksheets();
              e.fire({
                icon: "success",
                title: "Tasksheets for " + t.monthSelected + " submitted successfully."
              });
            })
            .catch(function (error) {
              e.fire({
                icon: "error",
                title: error.response.data.error || error.response.data.message || 'Tasksheet couldn\'t be submitted'
              });
            })
            .then(function () {
              t.submit_tasksheet_submit = false;
              t.submit_tasksheet_text = "Submit";
            });
        } else {
          t.submit_tasksheet_submit = false;
          t.submit_tasksheet_text = "Submit";
        }
      })
    },
    add_comment: function () {
      var t = this;
      (t.add_tasksheet_comment_text = ""), (t.add_tasksheet_comment_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      if (t.approval) {
        // (t.approve_tasksheet_text = ""), (t.approve_tasksheet_submit = true);
        axios
          .post(`${BASE_URL}approvetasksheet`, {
            company_id: t.authCompanyData.id,
            employee_id: t.employee_id,
            month: t.monthSelected,
            ids: t.all_filtered_ts_ids,
            comment: t.comment_to_add
          })
          .then(function () {
            t.fetch_tasksheet_comment(), (t.comment_to_add = ""), (t.tasksheet_comment = false);
            e.fire({ icon: "success", title: "The Tasksheet for " + t.employee_name + " for the month of " + t.monthSelected + " has been approved and the comment has been added." }), t.fetch_company_tasksheets();
          })
          .catch(function (error) {
            let errors = error.response.data.error || null;
            e.fire({
              icon: "error",
              title: errors ? (typeof errors === 'string' ? errors : null
                || errors.comment ? errors.comment[0] : null
                  || errors.monthSelected ? errors.monthSelected[0] : null) : null
                  || error.response.data.error || error.response.data.message || 'Tasksheet couldn\'t be approved'
            });
          })
          .then(function () {
            // (t.approve_tasksheet_submit = false), (t.approve_tasksheet_text = "Approve");
            (t.add_tasksheet_comment_submit = false), (t.add_tasksheet_comment_text = "Submit");
          });
      } else {
        // (t.disapprove_tasksheet_text = ""), (t.disapprove_tasksheet_submit = true);
        axios
          .post(`${BASE_URL}disapprovetasksheet`, {
            company_id: t.authCompanyData.id,
            employee_id: t.employee_id,
            month: t.monthSelected,
            ids: t.all_filtered_ts_ids,
            comment: t.comment_to_add
          })
          .then(function () {
            t.fetch_tasksheet_comment(), (t.comment_to_add = ""), (t.tasksheet_comment = false);
            e.fire({ icon: "success", title: "The Tasksheet for " + t.employee_name + " for the month of " + t.monthSelected + " has been disapproved and the comment has been added." }), t.fetch_company_tasksheets();
          })
          .catch(function (error) {
            let errors = error.response.data.error || null;
            e.fire({
              icon: "error",
              title: errors ? (typeof errors === 'string' ? errors : null
                || errors.comment ? errors.comment[0] : null
                  || errors.monthSelected ? errors.monthSelected[0] : null) : null
                  || error.response.data.error || error.response.data.message || 'Tasksheet couldn\'t be disapproved'
            });
          })
          .then(function () {
            // (t.disapprove_tasksheet_submit = false), (t.disapprove_tasksheet_text = "Disapprove");
            (t.add_tasksheet_comment_submit = false), (t.add_tasksheet_comment_text = "Submit");
          });
      }
    },
    fetch_tasksheet_comment: function () {
      var t = this;
      axios
        .post(`${BASE_URL}fetchtasksheetcomment`, {
          employee_id: t.employee_id || t.authEmployeeDataForCompany.id,
          company_id: t.authCompanyData.id
        })
        .then(function (e) {
          t.comments = e.data.comments;
        })
        .catch(function () { })
        .then(function () { });
    },
    disapprove_tasksheets: function () {
      var t = this;
      t.approval = false;
      t.tasksheet_comment = true;
    },
    approve_tasksheets: function () {
      var t = this;
      t.approval = true;
      t.tasksheet_comment = true;
    },
    delete_tasksheet: function (t) {
      var e = this,
        a = e.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2e3,
          timerProgressBar: true,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.$swal.fire({ title: "Are you sure you want to delete this tasksheet?", showCancelButton: true, confirmButtonText: "Yes, Delete" }).then(function (s) {
        s.isConfirmed &&
          axios
            .post(`${BASE_URL}deletetasksheet`, {
              ids: [{ id: t.id }],
              company_id: e.authCompanyData.id,
              employee_id: e.validEmployeeId
            })
            .then(function () {
              a.fire({ icon: "success", title: "Tasksheet deleted successfully" });
            })
            .catch(function (t) {
              a.fire({ icon: "error", title: "Tasksheet couldn't be deleted" }), console.log(t);
            })
            .then(function () {
              e.fetch_employee_tasksheets();
            });
      });
    },
    launch_edit_tasksheet: function (t) {
      var e = this;
      (e.editFormData.tasksheet_id = t.id),
        (e.editFormData.employee_designation = e.getDesignationById(t.designation_id) ? e.getDesignationById(t.designation_id).title : null),
        (e.editFormData.date = t.date),
        (e.editFormData.project_title = t.project_title),
        (e.editFormData.place_performance = t.place_performance),
        (e.editFormData.work_done = t.work_done),
        (e.edit_tasksheet_dialog = true);
    },
    set_date: function (t) {
      (this.day_selected = t), (this.yearSelected = t.substring(0, 4)), (this.monthSelected = this.monthNames[parseInt(t.substring(5, 7)) - 1]), (this.employee_name = "");
    },
    viewAll: function () {
      (this.monthSelected = this.monthNames[new Date().getMonth()]), (this.yearSelected = new Date().getFullYear().toString()), (this.checkingDetails = null), (this.view = "date");
    },
    save: function (t) {
      this.$refs.menu.save(t);
    },
    save2: function (t) {
      this.$refs.menu2.save(t);
    },
    load_day: function (t) {
      var e = this;
      axios
        .post(`${BASE_URL}fetchcheckingbyday`, { company_id: e.authCompanyData.id, day: t })
        .then(function (a) {
          (e.day_attendance = a.data.attendance), (e.view = "day"), (e.day_selected = t);
        })
        .catch(function (t) {
          console.log(t.response);
        })
        .then(function () { });
    },
    update_tasksheet: function () {
      var t = this;
      (t.update_tasksheet_text = ""), (t.update_tasksheet_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      if (t.editTasksheetDurationInMinutes == 0) {
        (e.fire({ icon: "error", title: "Please enter correct start and end times" }), (t.update_tasksheet_submit = false), (t.update_tasksheet_text = "Update"));
        return;
      }
      axios
        .post(`${BASE_URL}edittasksheet`, {
          tasksheet_id: t.editFormData.tasksheet_id,
          date: t.editFormData.date,
          project_title: t.editFormData.project_title,
          place_performance: t.editFormData.place_performance,
          work_done: t.editFormData.work_done,
          hour: t.editTasksheetDurationInHours,
          minutes: `${t.editTasksheetDurationInMinutes}`,
          company_id: t.authCompanyData.id,
          employee_id: t.validEmployeeId,
          designation_id: t.designationObject(t.editFormData.employee_designation).id
        })
        .then(function () {
          e.fire({ icon: "success", title: "Tasksheet for has been updated" }),
            (t.editFormData.tasksheet_id = ""),
            (t.editFormData.date = ""),
            (t.editFormData.project_title = ""),
            (t.editFormData.place_performance = ""),
            (t.editFormData.work_done = ""),
            (t.editFormData.start = ""),
            (t.editFormData.end = ""),
            (t.editFormData.employee_designation = ""),
            t.fetch_employee_tasksheets(),
            (t.edit_tasksheet_dialog = false);
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          e.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.date ? errors.date[0] : null
                || errors.project_title ? errors.project_title[0] : null
                  || errors.place_performance ? errors.place_performance[0] : null
                    || errors.work_done ? errors.work_done[0] : null) : null
                    || error.response.data.error || error.response.data.message || 'Tasksheet couldn\'t be updated'
          });
        })
        .then(function () {
          (t.update_tasksheet_submit = false), (t.update_tasksheet_text = "Update");
        });
    },
    add_tasksheet: function () {
      var t = this;
      (t.add_tasksheet_text = ""), (t.add_tasksheet_submit = true);
      var e = t.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: true,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      if (t.addTasksheetDurationInMinutes == 0) {
        (e.fire({ icon: "error", title: "Please enter correct start and end times" }), (t.add_tasksheet_submit = false), (t.add_tasksheet_text = "Submit"));
        return;
      }
      t.formData.date
        ? t.formData.project_title
          ? t.formData.place_performance
            ? t.formData.work_done
              ? axios
                .post(`${BASE_URL}addtasksheet`, {
                  tasksheets: [
                    {
                      company_id: t.authEmployeeDataForCompany.company_id,
                      employee_id: t.authEmployeeDataForCompany.id,
                      date: t.formData.date,
                      project_title: t.formData.project_title,
                      place_performance: t.formData.place_performance,
                      work_done: t.formData.work_done,
                      hour: t.addTasksheetDurationInHours,
                      minutes: `${t.addTasksheetDurationInMinutes}`,
                      designation_id: t.designationObject(t.formData.employee_designation).id
                    }
                  ],
                })
                .then(function () {
                  e.fire({ icon: "success", title: "Tasksheet for " + new Date(t.formData.date).toUTCString().substring(0, 16) + " has been added" }),
                    (t.formData.date = ""),
                    (t.formData.project_title = ""),
                    (t.formData.place_performance = ""),
                    (t.formData.work_done = ""),
                    (t.formData.start = ""),
                    (t.formData.end = ""),
                    (t.formData.employee_designation = ""),
                    t.fetch_employee_tasksheets(),
                    (t.add_tasksheet_dialog = false);
                })
                .catch(function (error) {
                  let errors = error.response.data.error || null;
                  e.fire({
                    icon: "error",
                    title: errors ? (typeof errors === 'string' ? errors : null
                      || errors.date ? errors.date[0] : null
                        || errors.project_title ? errors.project_title[0] : null
                          || errors.place_performance ? errors.place_performance[0] : null
                            || errors.work_done ? errors.work_done[0] : null) : null
                            || error.response.data.error || error.response.data.message || 'Tasksheet couldn\'t be added'
                  });
                })
                .then(function () {
                  (t.add_tasksheet_submit = false), (t.add_tasksheet_text = "Submit");
                })
              : (e.fire({ icon: "error", title: "Please enter your work done" }), (t.add_tasksheet_submit = false), (t.add_tasksheet_text = "Submit"))
            : (e.fire({ icon: "error", title: "Please enter your place of performance" }), (t.add_tasksheet_submit = false), (t.add_tasksheet_text = "Submit"))
          : (e.fire({ icon: "error", title: "Please enter a project title" }), (t.add_tasksheet_submit = false), (t.add_tasksheet_text = "Submit"))
        : (e.fire({ icon: "error", title: "Please enter a valid date" }), (t.add_tasksheet_submit = false), (t.add_tasksheet_text = "Submit"));
    },
    reset: function () {
      (this.day_selected = ""), (this.monthSelected = this.monthNames[new Date().getMonth()]), (this.yearSelected = new Date().getFullYear().toString()), (this.employee_name = "");
    },
  },
  mounted() {
    if (this.userType != 1) {
      let fname = this.authEmployeeDataForCompany.employee_fristname;
      let lname = this.authEmployeeDataForCompany.employee_lastname;
      this.employee_name = `${fname} ${lname}`;
    }
    this.fetch_employee_tasksheets();
    this.fetch_company_tasksheets();
    this.fetch_all_check_in_all_time();
    this.fetch_all_designations();
    document.title = "Kylian ERP - Tasksheets";
    1 != this.userType && this.fetch_tasksheet_comment(), (document.title = "Kylian ERP - Tasksheets"), (this.yearSelected = new Date().getFullYear().toString());
    var t = this;
    t.mixinBranchEmployees(t.$route.name).forEach(function (e) {
      e.user_id != t.authCompanyData.id && t.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
    });
    this.firstYear = new Date(this.authCompanyData.created_at).getFullYear().toString();
    this.reset();

    this.yearSelected = new Date().getFullYear().toString();
  },
  created() {
    var t = this;
    if (this.userType == 1 || this.permissionIsAssigned('tasksheets-read')) {
      t.$route.query.name &&
        (console.log("Route name found"),
          t.$watch("allEmployees", function () {
            if (t.allEmployees.indexOf(unescape(t.$route.query.name)) >= 0) {
              console.log("Route name found in array");
              var a = setInterval(function () {
                console.log("Interval"), (t.employee_name = unescape(t.$route.query.name)), t.employee_name == unescape(t.$route.query.name) && clearInterval(a);
              }, 500);
            }
          }));
    }
  }
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.fileAttachment div div div .v-file-input__text {
  cursor: pointer;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
