<template>
    <div class="tab-pane fade" id="list-2-signature" role="tabpanel" aria-labelledby="list-2-signature-list">
        <div v-if="update_signature_form">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Update Signature
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4 text-right">
                                <button @click="update_signature_form = false" style="float: right;" type="button"
                                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col style="cursor: pointer;" cols="12" class="remove-hint">
                                    <span style="color: #111d5e;">Signature</span>
                                    <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                                        label="Upload Signature" solo dense style="text-indent: 5px;" color="#069"
                                        class="fileAttachment" messages="Attaching Document">
                                    </v-file-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" offset-md="3" class="remove-hint">
                                    <button @click="updateSignatureLoading ? null : update_signature()"
                                        style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ updateSignatureText }}
                                        <v-icon
                                            :style="!updateSignatureLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Signature
                    </div>
                    <!-- <div class="col-lg-6" v-if="permissionIsAssigned('designation-create')"> -->
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="update_signature_form = true" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Update Signature
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Signature
                            </th>
                            <!-- <th
                                v-if="permissionIsAssigned('designation-update') || permissionIsAssigned('designation-delete')"
                                scope="col"
                                style="width: 10px;"
                            > -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-left">
                                <img :src="assets_url + authCompanyData.signature" alt="" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { ASSETS_URL, BASE_URL } from "@/main";
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            update_signature_form: false,
            updateSignatureText: "Create",
            updateSignatureLoading: false,
            course_category_edit_dialog: false,
        }
    },
    computed: {
        ...mapState([
            'userToken'
        ]),
        ...mapGetters([
            'authCompanyData'
        ]),
        assets_url() {
            return ASSETS_URL;
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_data'
        ]),
        update_signature() {
            const _this = this;
            _this.updateSignatureText = "";
            _this.updateSignatureLoading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            let formData = new FormData();
            formData.append("company_id", _this.authCompanyData.id);
            const fileAdded =
                document.querySelector(".fileAttachment div div div input").files
                    .length > 0;
            if (fileAdded) {
                formData.append(
                    "signature",
                    document.querySelector(".fileAttachment div div div input").files[0]
                );
            }

            axios.post(`${BASE_URL}companysignature`, formData, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`,
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The signature has been updated successfully",
                    });
                    _this.fetch_company_data();
                    _this.update_signature_form = false;
                })
                .catch(function (error) {
                    const errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : "The signature couldn't be updated"
                            : null || error.response.data.error || error.response.data.message || "The course category couldn't be updated",
                    });
                })
                .then(function () {
                    _this.updateSignatureLoading = false;
                    _this.updateSignatureText = "Update";
                });
        }
    }
}
</script>

<style></style>