<template>
  <v-app>
    <v-stepper v-model="steps" class="container custom-stepper-catcher">
      <!-- <v-stepper-header class="row ripple-stepper">
        <v-stepper-step
          class="col-md-3 col-6"
          edit-icon="$complete"
          :complete="steps > 1"
          step="1"
          color="#069"
          :editable="
            (parseInt(steps) == 1 ||
              parseInt(steps) == 2 ||
              parseInt(steps) == 3) &&
            serviceSelections.services.length >= 1
              ? true
              : false
          "
        >
          {{
            $vuetify.breakpoint.name == "xs" || $vuetify.breakpoint.name == "sm"
              ? "Features"
              : "Select Preferred Features"
          }}
        </v-stepper-step>

        <v-stepper-step
          class="col-md-3 col-6"
          edit-icon="$complete"
          :complete="steps > 2"
          step="2"
          color="#069"
          :editable="
            (parseInt(steps) == 1 ||
              parseInt(steps) == 2 ||
              parseInt(steps) == 3) &&
            serviceSelections.services.length >= 1
              ? true
              : false
          "
        >
          {{
            $vuetify.breakpoint.name == "xs" || $vuetify.breakpoint.name == "sm"
              ? "Package"
              : "Choose Package"
          }}
        </v-stepper-step>

        <v-stepper-step
          v-if="isTrial"
          class="col-md-3 col-6"
          edit-icon="$complete"
          :complete="steps > 3"
          step="3"
          color="#069"
        >
          {{
            $vuetify.breakpoint.name == "xs" || $vuetify.breakpoint.name == "sm"
              ? "Confirm"
              : "Confirm Trial"
          }}
        </v-stepper-step>

        <v-stepper-step
          v-else
          class="col-md-3 col-6"
          edit-icon="$complete"
          :complete="steps > 3"
          step="3"
          color="#069"
          :editable="paymentPick != '' && steps != 4 ? true : false"
        >
          {{
            $vuetify.breakpoint.name == "xs" || $vuetify.breakpoint.name == "sm"
              ? "Payment"
              : "Make Payment"
          }}
        </v-stepper-step>
      </v-stepper-header> -->

      <v-stepper-items>
        <v-stepper-content
          :class="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? 'px-0'
              : null
          "
          step="1"
        >
          <SetupCompanyFeatures @services="setServices" @step="step" />
        </v-stepper-content>

        <v-stepper-content
          :class="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? 'px-0'
              : null
          "
          step="2"
        >
          <SetupCompanyPackage @goBack="goBack" @packageStep="packageStep" />
        </v-stepper-content>

        <v-stepper-content
          :class="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? 'px-0 mt-5'
              : null
          "
          step="3"
        >
          <SetupCompanyConfirmTrial
            v-if="isTrial"
            :company_subscription="serviceSelections.company_subscription"
            @goBack="goBack"
            @paid="paid"
            @step="step"
            :amount="serviceSelections.amount"
            :selectedServices="serviceSelections.services"
          />
          <SetupCompanyPayment
            v-else
            :serviceSelections="serviceSelections"
            :company_subscription="serviceSelections.company_subscription"
            @goBack="goBack"
            @paid="paid"
            @paymentPick="paymentPickFunc"
            @step="step"
            :amount="serviceSelections.amount"
            :selectedServices="serviceSelections.services"
          />
        </v-stepper-content>

        <!-- <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0 mt-5' : null" step="4">
        <SetupCompanyDone />
      </v-stepper-content> -->
      </v-stepper-items>
    </v-stepper>

    <!-- <v-stepper v-model="steps">
  <v-stepper-header>
    <v-stepper-step
      :complete="steps > 1"
      step="1"
    >
      Name of step 1
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step
      :complete="steps > 2"
      step="2"
    >
      Name of step 2
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step step="3">
      Name of step 3
    </v-stepper-step>
  </v-stepper-header>

  <v-stepper-items>
    <v-stepper-content step="1">
      <v-card
        class="mb-12"
        color="grey lighten-1"
        height="200px"
      ></v-card>

      <v-btn
        color="primary"
        @click="steps = 2"
      >
        Continue
      </v-btn>

      <v-btn text>
        Cancel
      </v-btn>
    </v-stepper-content>

    <v-stepper-content step="2">
      <v-card
        class="mb-12"
        color="grey lighten-1"
        height="200px"
      ></v-card>

      <v-btn
        color="primary"
        @click="steps = 3"
      >
        Continue
      </v-btn>

      <v-btn text>
        Cancel
      </v-btn>
    </v-stepper-content>

    <v-stepper-content step="3">
      <v-card
        class="mb-12"
        color="grey lighten-1"
        height="200px"
      ></v-card>

      <v-btn
        color="primary"
        @click="steps = 1"
      >
        Continue
      </v-btn>

      <v-btn text>
        Cancel
      </v-btn>
    </v-stepper-content>
  </v-stepper-items>
</v-stepper> -->
    <v-overlay :value="overlay" style="z-index: 5555 !important" opacity="0.9">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <div class="text-center">
      <v-dialog v-model="dialogSuccess" width="500">
        <v-card>
          <v-card-text class="pt-5">
            <div class="col-md-8 mx-auto text-center pb-5">
              <img src="@/assets/graphics/check.png" alt="" class="img-fluid" />
            </div>
            <h3 class="msg pl-5 pr-5 text-center" style="display: block">
              {{
                isTrial
                  ? "You have successfully activated your trial version. You'll be redirected to your dashboard now."
                  : "Your payment is successful and your services activated. You'll be redirected to your dashboard now."
              }}
            </h3>
          </v-card-text>

          <v-divider class="mb-0"></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#069" text @click="closeDialogSuccess">
              <strong>Close</strong>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { BASE_URL } from "@/main";
import SetupCompanyFeatures from "@/components/SetupCompanyFeatures";
import SetupCompanyPackage from "@/components/SetupCompanyPackage";
import SetupCompanyConfirmTrial from "@/components/SetupCompanyConfirmTrial";
import SetupCompanyPayment from "@/components/SetupCompanyPayment";
// import SetupCompanyDone from "@/components/SetupCompanyDone";
export default {
  data() {
    return {
      steps: 1,
      dialogSuccess: false,
      serviceSelections: {
        payment_ref: "",
        company_id: "",
        amount: 0,
        services: [],
        company_subscription: 2,
      },
      packagePick: "",
      paymentPick: "",
      overlay: false,
    };
  },
  watch: {
    dialogSuccess(newValue) {
      if (newValue == false) {
        this.closeDialogSuccess();
      }
    },
  },
  computed: {
    ...mapGetters([
      "authUserData",
      "authCompanyData",
      "authSubscriptionExpired",
    ]),
    isTrial() {
      return this.serviceSelections.company_subscription == 1 ? true : false;
    },
  },
  components: {
    SetupCompanyFeatures,
    SetupCompanyPackage,
    SetupCompanyPayment,
    // SetupCompanyDone,
    SetupCompanyConfirmTrial,
  },
  methods: {
    ...mapActions([
      "refresh_company_data",
      "renew_subscription",
      "fetch_all_company_billing",
    ]),
    closeDialogSuccess() {
      this.dialogSuccess = false;
      this.$router.push({
        name: "ClientArea",
        params: { subdomain: this.authCompanyData.subdomain },
      });
    },
    step(newStep) {
      this.steps = newStep;
    },
    goBack(step) {
      this.steps = step.step;
    },
    packageStep(packageStep) {
      (this.steps = packageStep.step), (this.packagePick = packageStep.pick);
    },
    paymentPickFunc(paymentPick) {
      this.paymentPick = paymentPick;
    },
    setServices(services) {
      this.serviceSelections.services = services.services;
      this.serviceSelections.amount = services.amount;
      // this.serviceSelections.company_subscription = services.company_subscription
      // console.log('Emmited: ' + services.company_subscription)
      // console.log('Current Subscription Type: ' + this.serviceSelections.company_subscription)
      this.serviceSelections.company_subscription =
        services.company_subscription;
    },
    paid(payment_ref) {
      let _this = this;
      _this.overlay = true;
      this.serviceSelections.payment_ref = payment_ref;
      if (_this.authSubscriptionExpired) {
        axios
          .post(`${BASE_URL}addpaidservices`, {
            payment_ref: _this.serviceSelections.payment_ref,
            company_id: _this.serviceSelections.company_id,
            amount: _this.serviceSelections.amount,
            services: _this.serviceSelections.services,
          })
          .then(function () {
            // console.log(response.data);
            _this.refresh_company_data({
              user_id: _this.authUserData.id,
              company_id: _this.authCompanyData.id,
            });
            _this.renew_subscription();
            _this.fetch_all_company_billing();
            setTimeout(() => {
              _this.overlay = false;
              _this.dialogSuccess = true;
            }, 5000);
          })
          .catch(function (error) {
            console.log(error);
            _this.$swal.fire(
              "Error!",
              "There was an error in confirming your payment. Please contact the systems admin",
              "error"
            );
          })
          .then(function () {
            //
          });
      } else {
        axios
          .post(`${BASE_URL}addservice`, {
            payment_ref: _this.serviceSelections.payment_ref,
            company_id: _this.serviceSelections.company_id,
            amount: _this.serviceSelections.amount,
            services: _this.serviceSelections.services,
            company_subscription: _this.serviceSelections.company_subscription,
          })
          .then(function () {
            _this.refresh_company_data({
              user_id: _this.authUserData.id,
              company_id: _this.authCompanyData.id,
            });
            setTimeout(() => {
              _this.overlay = false;
              _this.dialogSuccess = true;
            }, 5000);
          })
          .catch(function (error) {
            console.log(error);
            if (_this.isTrial) {
              _this.$swal.fire(
                "Error!",
                "There was an error in setting up your trial account. Please contact the systems admin or try again.",
                "error"
              );
            } else {
              _this.$swal.fire(
                "Error!",
                "There was an error in confirming your payment. Please contact the systems admin.",
                "error"
              );
            }
          })
          .then(function () {
            //
          });
      }
    },
  },
  mounted() {
    document.title = "Kylian ERP - Setup Company Account";
    this.serviceSelections.company_id = this.authCompanyData.id;
  },
};
</script>

<style>
.custom-stepper-catcher div .v-stepper__step--complete .v-stepper__label {
  position: relative;
  bottom: 5px;
}
</style>
<style lang="scss" scoped>
.v-stepper {
  box-shadow: none !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.theme--light.v-stepper .v-stepper__step--editable:hover {
  background-color: #0066991a !important;
  border-radius: 50px;
}

.v-stepper__step--active {
  background-color:
  /*#06905*/ #0066991a !important;
  border-radius: 50px;
}
</style>
<style lang="scss">
@media (max-width: 991px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: inline-block !important;
    margin-left: 10px;
  }

  .v-stepper__step {
    padding: 15px !important;
    display: block !important;
  }
}

.ripple-stepper .v-stepper__step .v-ripple__container {
  background-color:
  /*#06905*/ #0066991a !important;
  display: none;
}

.ripple-stepper .v-stepper__step--active .v-stepper__label {
  color: #069 !important;
}
</style>
