<template>
  <v-app>
    <v-overlay v-if="overlay" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-else>
      <!-- <SideMenu /> -->
      <div class="main-content-wrap sidenav-open d-flex flex-column">
        <div class="main-header pl-4" style="z-index: 60;">
          <div class="row">
            <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
              <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
                <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                  style="cursor: pointer; position: relative; top: 2px; color: #069;"
                  class="fal fa-arrow-alt-circle-left mr-1"></i>
                <span class="ml-2" style="top: 4%; position: relative;">Back</span>
              </div>
            </div>
          </div>
          <div style="margin: auto"></div>
          <div :class="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'pr-5' : null"
            class="header-part-right">
            <!-- <div class="dropdown">
          <button class="btn ripple m-1" type="button">
            <span class="ul-btn__icon"
              ><i style="font-weight: bold;" class="i-Repeat-21"></i
            ></span>
          </button>
        </div> -->
            <!-- Grid menu Dropdown -->
            <div class="dropdown">
              <button v-if="userType == 1 && check_all_services('Attendance')" type="button"
                class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'LogReport' })">
                Log History
              </button>
            </div>
            <!-- Notificaiton End -->
            <!-- User avatar dropdown -->
          </div>
        </div>
        <!-- ======== Body content start ========= -->
        <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-1' : null"
          class="main-content">
          <div class="breadcrumb" style="margin-top: 1rem;">
            <h1>Click to select services</h1>
          </div>
          <div class="row">
            <div v-if="hr_services_to_add.length" class="row col-lg-9 col-md-9 col-sm-12">
              <!-- ICON BG-->
              <div v-for="service in hr_services_to_add" :key="service.service_id" class="col-lg-2 col-md-3 col-sm-6"
                @click="add_to_list(service)" :style="selected_services.indexOf(service) != -1
        ? 'opacity: 0.2;'
        : ''
      ">
                <span :style="selected_services.indexOf(service) == -1
        ? 'cursor: pointer;'
        : 'cursor: default;'
      ">
                  <div class="card card-icon-big mb-4">
                    <div class="card-body text-center">
                      <i :class="'nav-icon ' + getIcon(service.service_name)" style="font-size: 300%;"></i>
                      <p class="lead text-18 mt-2 mb-0">
                        {{ service.service_name }}
                      </p>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <div class="card card-icon-big mb-4">
                <div class="pt-4 pb-3 card-body text-center">
                  <h4 class="pb-3">Selected services</h4>
                  <v-row v-if="selected_services.length == 0">
                    <div class="pt-0 col-md-12 col">
                      <div class="selected">
                        <p>No service selected</p>
                      </div>
                    </div>
                  </v-row>
                  <v-row v-for="service in selected_services" :key="service.service_id">
                    <v-col class="pt-0" md="2">
                      <div class="selected">
                        <v-icon style="color: #069;">
                          {{ getIcon(service.service_name) }}
                        </v-icon>
                      </div>
                    </v-col>
                    <v-col class="pt-0 mt-0 text-left" md="8">
                      <span v-if="trial_version" style="font-size: 13.5px;">
                        {{ service.service_name }} (Free)
                      </span>
                      <span v-else style="font-size: 13.5px;">
                        {{ service.service_name }} (₦{{
      (service.amount * (months_left == 0 ? 1 : (authCompanyServices.length === 2 ? 12 : months_left))
        * (true_employees.length >= number_of_employees ? true_employees.length : number_of_employees))
      |
      number_with_commas
    }})
                      </span>
                    </v-col>
                    <v-col class="pt-0" md="2">
                      <div class="selected">
                        <i @click="remove_service(service)" style="cursor: pointer;" class="far fa-times-circle"></i>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="selected_services.length">
                    <v-col class="pt-0" md="2">
                      <div class="selected">
                        <!-- AN ICON CAN COME HERE -->
                      </div>
                    </v-col>
                    <v-col class="pt-0 mt-0 text-left" md="8">
                      <span style="font-size: 13.5px;">
                        Sub-total (₦{{ total_amount | number_with_commas }})
                      </span>
                    </v-col>
                    <v-col class="pt-0" md="2">
                      <div class="selected">
                        <!---->
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="selected_services.length">
                    <v-col class="pt-0" md="2">
                      <div class="selected">
                        <!-- AN ICON CAN COME HERE -->
                      </div>
                    </v-col>
                    <v-col class="pt-0 mt-0 text-left" md="8">
                      <span style="font-size: 13.5px;">
                        VAT (₦{{ VAT_FEE | number_with_commas }})
                      </span>
                    </v-col>
                    <v-col class="pt-0" md="2">
                      <div class="selected">
                        <!---->
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="selected_services.length">
                    <v-col class="pt-0" md="2">
                      <div class="selected">
                        <!-- AN ICON CAN COME HERE -->
                      </div>
                    </v-col>
                    <v-col class="pt-0 mt-0 text-left" md="8">
                      <span style="font-size: 13.5px;">
                        Total (₦{{ total_amount + VAT_FEE | number_with_commas }})
                      </span>
                    </v-col>
                    <v-col class="pt-0" md="2">
                      <div class="selected">
                        <!---->
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="selected_services.length >= 1">
                    <v-col class="pt-0" md="2">
                      <div class="selected"></div>
                    </v-col>
                    <v-col class="pt-0 mt-0 text-left" md="8">
                      <button @click="trial_version ? addAsTrial() : payWithPaystack()" type="button"
                        class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                        <i class="fas fa-lock mr-1"></i>
                        <span v-if="trial_version">Add Services</span>
                        <span v-else>Pay ₦{{ total_amount + VAT_FEE | number_with_commas }} securely</span>
                      </button>
                    </v-col>
                    <v-col class="pt-0" md="2">
                      <div class="selected"></div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Footer Start -->
        <div class="flex-grow-1"></div>
        <!-- fotter end -->
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      overlay: false,
      selected_services: [],
      payment_ref: ""
    };
  },
  computed: {
    ...mapState([
      "paystackKey",
      "VAT"
    ]),
    ...mapGetters([
      "validEmployeeId",
      "authUserData",
      "humanResources",
      "accounts",
      "userType",
      "authAllAvailableServices",
      "months_left",
      "authCompanyData",
      "number_of_employees",
      "trial_version",
      "true_employees",
      "authCompanyServices"
    ]),
    final_selected_services() {
      let _this = this;
      return _this.selected_services.map(service => {
        return {
          company_id: _this.authCompanyData.id,
          service_id: service.id,
          service_name: service.service_name,
          price: service.amount
        };
      });
    },
    VAT_FEE() {
      let _this = this, result = 0
      result = (_this.VAT / 100) * _this.total_amount;
      return result;
    },
    total_amount() {
      let x = 0,
        _this = this;
      _this.selected_services.forEach(service => {
        x += parseInt(service.amount) * (_this.true_employees.length >= _this.number_of_employees ? _this.true_employees.length : _this.number_of_employees) * (_this.months_left == 0 ? 1 : (_this.authCompanyServices.length === 2 ? 12 : _this.months_left));
      });
      return x;
    },
    hr_services_to_add() {
      let arr = [],
        _this = this,
        temp_all = [..._this.authAllAvailableServices.human_resources_services];

      temp_all.forEach(x => {
        _this.humanResources.forEach(y => {
          if (x.id == y.service_id) {
            arr.push(x);
          }
        });
      });

      let arr2 = _this.authAllAvailableServices.human_resources_services.map(
        service => {
          return service;
        }
      );

      let dummy = [...arr2];

      arr2.forEach(item => {
        arr.forEach(item2 => {
          if (item == item2) {
            dummy[arr2.indexOf(item)] = null;
          }
        });
      });

      // return dummy;
      dummy = dummy.filter(service => {
        return service != null;
      });

      return dummy;
    }
  },
  methods: {
    ...mapActions([
      "fetch_available_services",
      "refresh_company_data",
      "fetch_all_company_billing",
      "fetch_available_services"
    ]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    addAsTrial() {
      let letters = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9"
      ];
      let ref = "";
      for (let i = 0; i <= 10; i++) {
        if (i === 5) {
          ref += new Date().getTime();
          continue;
        }
        // ref += Math.floor(letters[Math.random() * letters.length])
        ref += letters[
          Math.floor(Math.random() * letters.length)
        ].toUpperCase();
      }
      let _this = this;
      _this.overlay = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.payment_ref = ref;
      axios
        .post(`${BASE_URL}addmoreservice`, {
          payment_ref: _this.payment_ref,
          company_id: _this.authCompanyData.id,
          amount: _this.total_amount + _this.VAT_FEE,
          services: _this.final_selected_services
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "The company services have been added successfully"
          });
          _this
            .refresh_company_data({
              user_id: _this.authUserData.id,
              company_id: _this.authCompanyData.id
            })
            .then(() => {
              _this.fetch_all_company_billing();
              _this.fetch_available_services();
            });
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title:
              "The company services couldn't be added. Kindly contact the developers"
          });
        })
        .then(function () {
          _this.selected_services = [];
          _this.overlay = false;
        });
    },
    payWithPaystack() {
      let letters = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9"
      ];
      let ref = "";
      for (let i = 0; i <= 10; i++) {
        if (i === 5) {
          ref += new Date().getTime();
          continue;
        }
        // ref += Math.floor(letters[Math.random() * letters.length])
        ref += letters[
          Math.floor(Math.random() * letters.length)
        ].toUpperCase();
      }
      let _this = this;
      let handler = window.PaystackPop.setup({
        key: _this.paystackKey, // Replace with your public key
        email: _this.authUserData.email,
        amount: (_this.total_amount + _this.VAT_FEE) * 100, //document.getElementById("amount").value * 100,
        ref, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        // label: "Optional string that replaces customer email"
        callback: function (response) {
          _this.overlay = true;
          const Toast = _this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", _this.$swal.stopTimer);
              toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
            }
          });
          setTimeout(function () {
            _this.payment_ref = response.reference;
            axios
              .post(`${BASE_URL}addmoreservice`, {
                payment_ref: _this.payment_ref,
                company_id: _this.authCompanyData.id,
                amount: _this.total_amount + _this.VAT_FEE,
                services: _this.final_selected_services
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "The company services have been added successfully"
                });
                _this
                  .refresh_company_data({
                    user_id: _this.authUserData.id,
                    company_id: _this.authCompanyData.id
                  })
                  .then(() => {
                    _this.fetch_all_company_billing();
                    _this.fetch_available_services();
                  });
              })
              .catch(function () {
                Toast.fire({
                  icon: "error",
                  title:
                    "The company services couldn't be added. Kindly contact the developers"
                });
              })
              .then(function () {
                _this.selected_services = [];
                _this.overlay = false;
              });
          }, 500);
        }
      });
      handler.openIframe();
    },
    add_to_list(service) {
      let _this = this;
      if (_this.selected_services.indexOf(service) == -1) {
        _this.selected_services.push(service);
      }
    },
    remove_service(service) {
      let _this = this;
      if (_this.selected_services.indexOf(service) != -1) {
        _this.selected_services.splice(
          _this.selected_services.indexOf(service),
          1
        );
      }
    },
    getIcon(name) {
      if (name.toLowerCase() == "onboarding") {
        return "fal fa-users";
      } else if (name.toLowerCase() == "attendance") {
        return "fal fa-clipboard-user";
      } else if (name.toLowerCase() == "timesheets") {
        return "fal fa-file-lines";
      } else if (name.toLowerCase() == "absence or leave") {
        return "fal fa-island-tropical";
      } else if (name.toLowerCase() == "medicals") {
        return "fal fa-notes-medical";
      } else if (name.toLowerCase() == "assets") {
        return "fal fa-computer-speaker";
      } else if (name.toLowerCase() == "file management") {
        return "fal fa-paste";
      } else if (name.toLowerCase() == "payroll") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "payslip") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "travel management") {
        return "fal fa-plane-departure";
      } else if (name.toLowerCase() == "recruitment") {
        return "fal fa-handshake";
      } else if (name.toLowerCase() == "projects") {
        return "fal fa-tasks";
      } else if (name.toLowerCase() == "loan") {
        return "fal fa-coins";
      } else if (name.toLowerCase() == "tasksheets") {
        return "fal fa-file-spreadsheet";
      } else if (name.toLowerCase() == "requisitions") {
        return "fal fa-money-check";
      } else if (name.toLowerCase() == "dockets") {
        return "fal fa-scale-balanced";
      } else if (name.toLowerCase() == "performance") {
        return "fal fa-chart-line-up";
      } else if (name.toLowerCase() == "trainings") {
        return "fal fa-book";
      } else if (name.toLowerCase() == "outsourcing") {
        return "fal fa-buildings";
      } else {
        return "fal fa-question";
      }
    }
  },
  mounted() {
    document.title = "Kylian ERP - Add Services";
    this.refresh_company_data({
      user_id: this.authUserData.id,
      company_id: this.authCompanyData.id
    });
    if (!this.authAllAvailableServices) {
      this.fetch_available_services();
    }
  }
};
</script>

<style scoped>
.lead {
  font-size: 100%;
}
</style>
