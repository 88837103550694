var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.fetching)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_c('v-app',{},[_c('v-row',{staticStyle:{"margin-top":"60px"}},[_c('v-col',{staticStyle:{"background":"url(/img/contact-banner.png)","background-repeat":"round"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"padding":"60px 140px"},style:(_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm' ||
            _vm.$vuetify.breakpoint.name == 'md'
              ? 'padding :60px 38px;'
              : 'padding :60px 140px;')},[_c('h2',{staticClass:"text-left",staticStyle:{"font-family":"Poppins !important","font-size":"38px","line-height":"57px","font-weight":"600","color":"#FFF !important"}},[_vm._v(" Blog ")])])])],1),(_vm.fetching === false)?_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"30px"},style:(_vm.$vuetify.breakpoint.name == 'xs' ||
          _vm.$vuetify.breakpoint.name == 'sm' ||
          _vm.$vuetify.breakpoint.name == 'md'
            ? ''
            : 'display:flex;justify-content:center; flex-direction:row;'),attrs:{"cols":"12"}},[_c('div',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm' ||
            _vm.$vuetify.breakpoint.name == 'md'
              ? 'max-width: 100vw; width: 100vw; display:flex; flex-direction:column; justify-content:center; align-items:flex-start;'
              : 'max-width: 1300px; width: 100vw; display:flex;justify-content:center; flex-direction:row; align-items:flex-start;')},[_c('v-col',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
              _vm.$vuetify.breakpoint.name == 'sm' ||
              _vm.$vuetify.breakpoint.name == 'md'
                ? 'display: flex; width:100%; gap: 10px; justify-content: center;  flex-wrap:wrap;  align-items:center;'
                : ''),attrs:{"cols":"12","lg":"8"}},[_c('div',{staticClass:"blogContainer"},[(_vm.searchInput && _vm.filteredBlogPosts.length === 0)?_c('h2',{staticStyle:{"font-style":"normal","font-weight":"600","font-size":"38px","color":"#0E0B33","margin-bottom":"20px"}},[_vm._v(" No Search Results ")]):(_vm.searchInput)?_c('h2',{staticStyle:{"font-style":"normal","font-weight":"600","font-size":"38px","color":"#0E0B33","margin-bottom":"20px"}},[_vm._v(" Search Results ")]):_c('h2',{staticStyle:{"font-style":"normal","font-weight":"600","font-size":"38px","color":"#0E0B33","margin-bottom":"20px"}},[_vm._v(" Featured Articles ")]),_c('div',{staticClass:"blogsBox"},_vm._l((_vm.filteredBlogPosts),function(post){return _c('div',{key:post.title,staticClass:"blogItem"},[_c('div',{staticClass:"blogContent"},[_c('router-link',{attrs:{"to":{ name: 'BlogDetails', params: { slug: post.slug } }}},[_c('div',{staticClass:"blogImg",style:(("background-image: url('" + (_vm.blog_url + post.featured_img) + "')"))}),_c('div',{staticClass:"blogTitle"},[_vm._v(" "+_vm._s(post.title)+" ")])]),_c('div',{staticClass:"blogBody",staticStyle:{"color":"#0e0b33"},domProps:{"innerHTML":_vm._s(post.body.slice(0, 300) + '...')}}),_c('router-link',{attrs:{"to":{ name: 'BlogDetails', params: { slug: post.slug } }}},[_vm._v("Read More")]),_c('div',{staticClass:"blogSub",staticStyle:{"margin-top":"10px"}},[_vm._v(" "+_vm._s(post.editor)+" • "+_vm._s(_vm._f("blogDateFilter")(post.created_at))+" ")])],1)])}),0),_c('div',{staticClass:"pagination-box",style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                  _vm.$vuetify.breakpoint.name == 'sm' ||
                  _vm.$vuetify.breakpoint.name == 'md'
                    ? ''
                    : ' display: flex; justify-content: center; align-items: center; gap:60px; margin-top: 40px;')},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.paginateCount !== 1 && _vm.paginateBlogs(_vm.paginateCount-1)}}},[_c('img',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                      _vm.$vuetify.breakpoint.name == 'sm' ||
                      _vm.$vuetify.breakpoint.name == 'md'
                        ? 'width:10px'
                        : ''),attrs:{"src":require("../assets/graphics/arrow.svg"),"alt":""}})]),_vm._l((_vm.paginationNumbers),function(number,index){return _c('div',{key:index,staticClass:"pagination",style:(number === _vm.paginateCount
                    ? 'background: #0B54C0;  color: #fff;'
                    : 'background: #fff;  color: #0B54C0; '),on:{"click":function($event){return _vm.paginateBlogs(number)}}},[_vm._v(" "+_vm._s(number)+" ")])}),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.paginateCount !== _vm.paginationMaxNumber && _vm.paginateBlogs(_vm.paginateCount+1)}}},[_c('img',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                      _vm.$vuetify.breakpoint.name == 'sm' ||
                      _vm.$vuetify.breakpoint.name == 'md'
                        ? 'width:10px'
                        : ''),attrs:{"src":require("../assets/graphics/rightArrow.svg"),"alt":""}})])],2)])]),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"articlesContainer"},[_c('div',{staticClass:"popular-articles-title"},[_vm._v("Popular Articles")]),_c('div',{staticClass:"search-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInput),expression:"searchInput"}],staticClass:"searchInput",staticStyle:{"width":"90%","padding":"6px","padding-right":"20px","font-size":"16px"},attrs:{"placeholder":"Search","type":"text"},domProps:{"value":(_vm.searchInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchInput=$event.target.value}}}),_c('div',{staticStyle:{"cursor":"pointer","text-align-last":"center","border-top-right-radius":"20px","border-bottom-right-radius":"20px","top":"0","background":"#0B54C0","width":"60px","padding-left":"20px","padding":"10px"},on:{"click":function($event){return _vm.searchBlog()}}},[_c('img',{attrs:{"src":require("../assets/graphics/search.svg"),"alt":""}})])]),_c('div',{staticClass:"popular-articles",style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                  _vm.$vuetify.breakpoint.name == 'sm' ||
                  _vm.$vuetify.breakpoint.name == 'md'
                    ? ''
                    : 'display: flex;justify-content: center;align-items: center;flex-direction: column;')},_vm._l((_vm.topFivePosts),function(post){return _c('router-link',{key:post.title,attrs:{"to":{ name: 'BlogDetails', params: { slug: post.slug } }}},[_c('div',{staticClass:"articleItem"},[_c('img',{staticClass:"articlesImg",attrs:{"src":("" + (_vm.blog_url + post.featured_img)),"alt":""}}),_c('div',{staticStyle:{"padding":"10px","margin-top":"10px"}},[_c('v-row',{staticClass:"articlesTitle"},[_vm._v(" "+_vm._s(post.title)+" ")]),_c('v-row',{staticClass:"articlesSub",staticStyle:{"margin-top":"10px"}},[_vm._v(" "+_vm._s(post.editor)+"•"+_vm._s(_vm._f("blogDateFilter")(post.created_at))+" ")])],1)])])}),1)])])],1)]),_c('GuestNewsletter'),_c('GuestFooter')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }