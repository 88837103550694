var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.fetching)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_c('v-app',{},[_c('v-row',{staticStyle:{"margin-top":"60px"}},[_c('v-col',{staticStyle:{"background":"url(/img/contact-banner.png)","background-size":"cover","background-repeat":"round"},style:(_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm' ||
            _vm.$vuetify.breakpoint.name == 'md'
            ? ''
            : ''),attrs:{"cols":"12"}},[_c('div',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm' ||
            _vm.$vuetify.breakpoint.name == 'md'
            ? 'padding:100px 60px 20px; display:flex; justify-content:center; align-items:center;'
            : 'padding:100px 60px 20px; display:flex; justify-content:center; align-items:center;')},[_c('h2',{staticClass:"text-left",staticStyle:{"font-family":"Poppins !important","font-size":"38px","line-height":"57px","font-weight":"600","color":"#FFF !important","max-width":"1300px"}},[_vm._v(" Blog Post ")])])])],1),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"20px","height":"max-content"},style:(_vm.$vuetify.breakpoint.name == 'xs' ||
          _vm.$vuetify.breakpoint.name == 'sm' ||
          _vm.$vuetify.breakpoint.name == 'md'
          ? 'width: 100vw; display:flex; flex-direction:column; justify-content:center; align-items:flex-start;'
          : 'padding:0px 50px; width: 100vw; display:flex;justify-content:center; flex-direction:row; align-items:flex-start;'),attrs:{"cols":"12"}},[_c('div',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm' ||
            _vm.$vuetify.breakpoint.name == 'md'
            ? 'max-width: 100vw; width: 100vw; display:flex; flex-direction:column; justify-content:center; align-items:center'
            : 'max-width: 1500px; width: 100vw; display:flex;justify-content:space-between; flex-direction:row; align-items:flex-start;')},[_c('div',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
              _vm.$vuetify.breakpoint.name == 'sm' ||
              _vm.$vuetify.breakpoint.name == 'md'
              ? 'display:flex; flex-direction:column; justify-content:center; align-items:center;'
              : 'display:flex; flex-direction:row; justify-content:center; gap:80px; align-items:flex-start;')},[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-row',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                  _vm.$vuetify.breakpoint.name == 'sm'// ||
                  // $vuetify.breakpoint.name == 'md'
                  ? ''
                  : 'margin: 0px 0px 30px 0px; padding:0px 40px; display:flex; flex-direction:column; justify-content:center; align-items:flex-start;')},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"color":"#2D2D2D","font-style":"normal","font-weight":"600","font-size":"38px","margin-bottom":"20px"}},[_vm._v(" "+_vm._s(_vm.post.title)+" ")]),_c('div',{staticStyle:{"height":"auto","margin-top":"13px"}},[_c('div',[_c('img',{attrs:{"src":("" + (_vm.blog_url + _vm.post.featured_img))}})]),_c('div',{staticStyle:{"margin-top":"30px","padding":"0 30px"}},[_c('v-row',[_c('div',{staticClass:"blogBody",staticStyle:{"color":"#0e0b33","margin-bottom":"10px","margin-top":"10px","font-size":"16px","line-height":"24px"},style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                                  _vm.$vuetify.breakpoint.name == 'sm' ||
                                  _vm.$vuetify.breakpoint.name == 'md'
                                  ? 'width: 100%;'
                                  : 'width: 100%;'),domProps:{"innerHTML":_vm._s(_vm.post.body + '<br /><i>Written by ' + _vm.post.editor + '</i>')}})])],1)])]),_c('v-col',{staticClass:"ml-lg-5",attrs:{"lg":"8"}},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","lg":"3"}},[_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v("Share on ")])]),_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"2"}},[_c('a',{attrs:{"href":("https://twitter.com/intent/tweet?url=https://kylianerp.com/blog/" + _vm.currentPostSlug + "&text=" + (_vm.post.title)),"target":"_blank"}},[_c('img',{attrs:{"src":"/img/twitter-footer.svg","alt":""}})])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"2"}},[_c('a',{attrs:{"href":("https://www.facebook.com/sharer/sharer.php?u=https://kylianerp.com/blog/" + _vm.currentPostSlug),"target":"_blank"}},[_c('img',{attrs:{"src":"/img/facebook-footer.svg","alt":""}})])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"2"}},[_c('a',{attrs:{"href":("https://www.linkedin.com/sharing/share-offsite/?url=https://kylianerp.com/blog/" + _vm.currentPostSlug),"target":"_blank"}},[_c('img',{attrs:{"src":"/img/linkedin-footer.svg","alt":""}})])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"2"}},[_c('a',{attrs:{"href":("https://api.whatsapp.com/send?text=New blog post: " + (_vm.post.title) + " https://kylianerp.com/blog/" + _vm.currentPostSlug),"target":"_blank"}},[_c('img',{attrs:{"src":"/img/whatsapp-footer.svg","alt":""}})])])],1)],1)],1)],1)],1)],1),_c('v-col',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm' ||
                _vm.$vuetify.breakpoint.name == 'md'
                ? 'padding:0px 40px;  display:flex; flex-direction:column; justify-content:center; gap:20px;'
                : ''),attrs:{"cols":"12","lg":"3"}},[_c('div',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                  _vm.$vuetify.breakpoint.name == 'sm' ||
                  _vm.$vuetify.breakpoint.name == 'md'
                  ? 'padding-left:20px; border-radius: 20px; display: flex; justify-content: space-between;align-items: center; margin-bottom: 16px; border: 2px solid #e8e8e8; '
                  : 'padding-left:20px; border-radius: 20px; display: flex; justify-content: center;align-items: center; margin-bottom: 16px; border: 2px solid #e8e8e8; ')},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInput),expression:"searchInput"}],staticClass:"searchInput",staticStyle:{"width":"90%","padding":"6px","padding-right":"20px","font-size":"16px"},attrs:{"placeholder":"Search","type":"text"},domProps:{"value":(_vm.searchInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchInput=$event.target.value}}}),_c('div',{staticStyle:{"cursor":"pointer","text-align-last":"center","border-top-right-radius":"20px","border-bottom-right-radius":"20px","top":"0","background":"#0B54C0","width":"60px","padding-left":"20px","padding":"10px"},on:{"click":function($event){return _vm.searchBlog()}}},[_c('img',{attrs:{"src":require("../assets/graphics/search.svg"),"alt":""}})])]),_c('div',{style:(_vm.$vuetify.breakpoint.name == 'xs' ||
                  _vm.$vuetify.breakpoint.name == 'sm'
                  ? 'padding:0px 40px;  display:flex; flex-direction:column; justify-content:center; gap:40px; align-items:center;'
                  : _vm.$vuetify.breakpoint.name == 'md'
                    ? 'padding:0px 40px;  display:flex; flex-direction:row; justify-content:center; gap:40px;'
                    : '')},[_c('div',{staticClass:"recent-articles",staticStyle:{"background":"rgba(218, 218, 218, 0.38)","border-radius":"10px","padding":"30px"},style:(_vm.$vuetify.breakpoint.name == 'xs'
                      ? 'width: 100%;'
                      : _vm.$vuetify.breakpoint.name == 'sm' ||
                        _vm.$vuetify.breakpoint.name == 'md'
                        ? ' margin-top: 0px;'
                        : ' margin-top: 42px; width: 303px;')},[_c('h3',{staticStyle:{"font-size":"24px","padding":"0","line-height":"36px","text-transform":"capitalize","color":"#1A3F78","text-align":"left"}},[_vm._v(" Recent Post ")]),_c('ul',{staticStyle:{"padding":"0","display":"flex","flex-direction":"column","gap":"6px"}},_vm._l((_vm.topFivePosts),function(post,index){return _c('li',{key:index,style:(post.slug == _vm.currentPostSlug && 'color: red;')},[_c('router-link',{style:(post.slug == _vm.currentPostSlug && 'color: red;'),attrs:{"to":{ name: 'BlogDetails', params: { slug: post.slug } }}},[_vm._v(" "+_vm._s(post.title)+" ")])],1)}),0)])])])],1)])])],1),_c('div',[_c('GuestNewsletter'),_c('GuestFooter')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }