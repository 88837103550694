<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div v-if="userType == 1" class="dropdown animate__animated animate__fadeIn">
            <button v-if="create_file == 1 || create_file == 2" @click="tweak_display()" style="float: right;"
              type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              {{
                  create_file == 1
                    ? "View Files"
                    : create_file == 2
                      ? "Upload File"
                      : ""
              }}
            </button>
            <button v-else @click="create_file = 2" style="float: right;" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              {{ "View Files" }}
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <template v-if="userType == 1">
        <div v-if="create_file == 1" class="main-content">
          <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
            <h1>
              <span class="col-4">Upload a File</span>
            </h1>
          </div>
          <v-form @submit.prevent>
            <div class="container-fluid">
              <v-row>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <v-text-field v-model="formData.companyfile_name" label="File Name" solo></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <v-select v-model="formData.companyfile_filetype" item-color="#069" :items="file_type_names"
                    label="Select File Type" solo color="#069"></v-select>
                </v-col>
                <v-col style="cursor: pointer;" cols="12" sm="6" md="6" class="remove-hint">
                  <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270" label="Upload a File"
                    solo style="text-indent: 5px;" color="#069" class="upload-company-file">
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <v-text-field v-model="formData.description" label="Description (Optional)" solo></v-text-field>
                </v-col>
                <v-col cols="12" class="remove-hint text-center">
                  <button @click="create_file_submit ? null : add_file()" type="button"
                    class="btn btn-secondary m-1 ripple">
                    {{ create_file_text }}
                    <v-icon :style="
                      !create_file_submit
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                    ">
                      fas fa-circle-notch fa-spin
                    </v-icon>
                  </button>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </div>
        <div v-else-if="create_file == 2" class="main-content">
          <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
            <h1>
              <span class="col-4">Company Files</span>
            </h1>
          </div>
          <div class="main-content mt-0 row">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">File Type</th>
                    <th scope="col">Description</th>
                    <th scope="col">Download</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="file in auth_company_files" :key="file.id">
                    <td>{{ file.companyfile_name }}</td>
                    <td>{{ file.filetype_name }}</td>
                    <td v-if="file.description">{{ file.description }}</td>
                    <td v-else>
                      <i>No description</i>
                    </td>
                    <td>
                      <a :href="assets_url + file.companyfile_url" download="download">Download</a>
                    </td>
                    <td>
                      <div class="row">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <i v-bind="attrs" v-on="on" class="col-3 py-0 text-center fas fa-edit"
                              @click="edit_file(file)"
                              style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                          </template>
                          <span>Edit {{ file.companyfile_name }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <i v-bind="attrs" v-on="on" class="col-3 py-0 text-center fas fa-trash"
                              @click="delete_file(file)"
                              style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                          </template>
                          <span>Delete {{ file.companyfile_name }}</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else class="main-content">
          <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
            <h1>
              <span>Edit file [{{ editFormData.companyfile_name }}]</span>
            </h1>
          </div>
          <v-form @submit.prevent>
            <div class="container-fluid">
              <v-row>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <v-text-field v-model="editFormData.companyfile_name" label="File Name" solo></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <v-select v-model="editFormData.companyfile_filetype" item-color="#069" :items="file_type_names"
                    label="Select File Type" solo color="#069"></v-select>
                </v-col>
                <v-col style="cursor: pointer;" cols="12" sm="6" md="6" class="remove-hint">
                  <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                    label="Update the File" solo style="text-indent: 5px;" color="#069" class="update-company-file">
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <v-text-field v-model="editFormData.description" label="Description (Optional)" solo></v-text-field>
                </v-col>
                <v-col cols="12" class="remove-hint text-center">
                  <button @click="update_file_submit ? null : update_file()" type="button"
                    class="btn btn-secondary m-1 ripple">
                    {{ update_file_text }}
                    <v-icon :style="
                      !update_file_submit
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                    ">
                      fas fa-circle-notch fa-spin
                    </v-icon>
                  </button>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </div>
        <div class="flex-grow-1"></div>
      </template>
      <template v-if="userType != 1">
        <div class="main-content"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
          <div class="breadcrumb" style="margin-top: 1rem;"
            :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null">
            <h1>
              <span class="col-4">Your Files</span>
            </h1>
          </div>
          <div class="main-content mt-0 row"
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : 'px-3'">
            <div class="card w-100" style="border-radius: 10px;">
              <div class="card-body px-1 py-1 pt-0 pr-0">
                <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                  <table class="table">
                    <thead>
                      <tr>
                        <th style="border-top: none;" scope="col">File Name</th>
                        <th style="border-top: none;" scope="col">Description</th>
                        <th style="border-top: none;" scope="col">Details</th>
                        <th style="border-top: none;" scope="col">File Type</th>
                        <th style="border-top: none;" scope="col">Given Date</th>
                        <!-- <th style="border-top: none;" scope="col">Return Date</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr v-for="asset in employee_assets" :key="asset.id">
                          <td>{{ asset.asset_name }}</td>
                          <td>{{ asset.asset_description }}</td>
                          <td v-if="asset.asset_details">
                            {{ asset.asset_details }}
                          </td>
                          <td v-else><i>No details</i></td>
                          <td>{{ get_type_name(asset.assettype_id) }}</td>
                          <td>{{ asset.given_date }}</td>
                          <td>{{ asset.return_date }}</td>
                        </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <template v-if="userType == 2">
        <div class="main-content">
          <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
            <h1>
              <span class="col-4">Your Files</span>
            </h1>
          </div>
          <div class="main-content mt-0 row">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Asset Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Details</th>
                    <th scope="col">Asset Type</th>
                    <th scope="col">Given Date</th>
                    <th scope="col">Return Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="asset in employee_assets" :key="asset.id">
                    <td>{{ asset.asset_name }}</td>
                    <td>{{ asset.asset_description }}</td>
                    <td v-if="asset.asset_details">
                      {{ asset.asset_details }}
                    </td>
                    <td v-else><i>No details</i></td>
                    <td>{{ get_type_name(asset.assettype_id) }}</td>
                    <td>{{ asset.given_date }}</td>
                    <td>{{ asset.return_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template> -->
    </div>
    <!-- <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{
              (
                "Assign " +
                assignFormData.name +
                " to an employee"
              )
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-text-field
                        :disabled="true"
                        dense
                        v-model="assignFormData.name"
                        label="Asset Name"
                        solo
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-text-field
                        :disabled="true"
                        dense
                        v-model="assignFormData.type_name"
                        label="Select Asset Type"
                        solo
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-text-field
                        dense
                        v-model="assignFormData.details"
                        label="Details"
                        solo
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-select
                        dense
                        v-model="assignFormData.employee"
                        item-color="#069"
                        :items="allEmployees"
                        label="Select Employee"
                        solo
                        color="#069"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            color="#069"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            v-model="assignFormData.given_date"
                            label="Given Date"
                            solo
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="#069"
                          ref="picker3"
                          v-model="assignFormData.given_date"
                          :min="
                            new Date(new Date().getTime() + 86400000)
                              .toISOString()
                              .substr(0, 10)
                          "
                          :max="
                            new Date(new Date().getTime() + 86400000000)
                              .toISOString()
                              .substr(0, 10)
                          "
                          @change="save3"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            color="#069"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            v-model="assignFormData.return_date"
                            label="Return Date"
                            solo
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="#069"
                          ref="picker4"
                          v-model="assignFormData.return_date"
                          :min="
                            new Date(new Date().getTime() + 86400000)
                              .toISOString()
                              .substr(0, 10)
                          "
                          :max="
                            new Date(new Date().getTime() + 86400000000)
                              .toISOString()
                              .substr(0, 10)
                          "
                          @change="save4"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      offset-md="3"
                      offset-sm="3"
                      class="remove-hint"
                    >
                      <button
                        @click="assign_asset_to_employee()"
                        style="width: 100%;"
                        type="button"
                        class="btn btn-secondary m-1 ripple"
                      >
                        {{ assign_asset_text }}
                        <v-icon
                          :style="
                            !assign_asset_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          "
                        >
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row> -->
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, ASSETS_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      // all_leave: [],
      dialog: false,
      create_file: 2,
      create_file_text: "Submit",
      create_file_submit: false,
      update_file_text: "Update",
      update_file_submit: false,
      assign_asset_text: "Assign",
      assign_asset_submit: false,
      employee_assets: [],
      assignFormData: {
        id: "",
        type_name: "",
        name: "",
        given_date: "",
        return_date: "",
        details: "",
        employee: ""
      },
      formData: {
        companyfile_name: "",
        companyfile_filetype: "",
        description: ""
      },
      editFormData: {
        id: "",
        companyfile_name: "",
        companyfile_filetype: "",
        description: ""
      },
      allEmployees: []
    };
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "authUserData",
      "authCompanyAssets",
      "userType",
      "authCompanyAllAssetType",
      "authCompanyAllVendors",
      "auth_file_types",
      "auth_company_files"
    ]),
    assets_url() {
      return ASSETS_URL;
    },
    employee_id() {
      let _this = this;
      if (_this.assignFormData.employee) {
        return _this.mixinBranchEmployees(_this.$route.name).filter(person => {
          return (
            _this.assignFormData.employee ==
            person.employee_fristname + " " + person.employee_lastname
          );
        })[0].id;
      } else {
        return "";
      }
    },
    file_type_id() {
      let _this = this;
      if (_this.auth_file_types && _this.formData.companyfile_filetype) {
        return _this.auth_file_types.filter(type => {
          return _this.formData.companyfile_filetype == type.filetype_name;
        })[0].id;
      } else {
        return "";
      }
    },
    edit_file_type_id() {
      let _this = this;
      if (_this.auth_file_types && _this.editFormData.companyfile_filetype) {
        return _this.auth_file_types.filter(type => {
          return _this.editFormData.companyfile_filetype == type.filetype_name;
        })[0].id;
      } else {
        return "";
      }
    },
    file_type_names() {
      let _this = this,
        all = [];
      if (_this.auth_file_types) {
        _this.auth_file_types.forEach(type => {
          all.push(type.filetype_name);
        });
      }
      return all;
    },
    assign_asset_type_id() {
      let _this = this;
      if (_this.assignFormData.type_name) {
        return _this.authCompanyAllAssetType.filter(type => {
          return _this.assignFormData.type_name == type.assettype_name;
        })[0].id;
      } else {
        return "";
      }
    },
    asset_type_id() {
      let _this = this;
      if (_this.formData.type_name) {
        return _this.authCompanyAllAssetType.filter(type => {
          return _this.formData.type_name == type.assettype_name;
        })[0].id;
      } else {
        return "";
      }
    }
  },
  methods: {
    ...mapActions([
      "fetch_company_assets",
      "fetch_all_asset_types",
      "fetch_company_file_types",
      "fetch_all_company_files"
    ]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    tweak_display() {
      if (this.create_file == 1) {
        this.create_file = 2;
      } else if (this.create_file == 2) {
        this.create_file = 1;
      } else {
        ("");
      }
    },
    get_type_name(type_id) {
      let _this = this;
      if (type_id && _this.auth_file_types) {
        let type = _this.auth_file_types.filter(type => type.id == type_id);
        return type[0].filetype_name;
      }
    },
    // assign_asset_launcher(asset) {
    //   let _this = this;
    //   _this.assignFormData.id = asset.id;
    //   _this.assignFormData.type_name = _this.get_type_name(
    //     asset.assettype_name
    //   );
    //   _this.assignFormData.name = asset.asset_name;
    //   _this.dialog = true;
    // },
    // fetch_employee_assets() {
    //   let _this = this;
    //   axios
    //     .post(`${BASE_URL}fetchemployeeasset`, {
    //       company_id: _this.authEmployeeDataForCompany.company_id
    //     })
    //     .then(function(response) {
    //       console.log(RESPONSE);
    //       const RESPONSE = response.data;
    //       RESPONSE.Employees.forEach(asset => {
    //         if (asset.employee_id != _this.authEmployeeDataForCompany.id) {
    //           ("");
    //         } else {
    //           _this.employee_assets.push(asset);
    //         }
    //       });
    //     })
    //     .catch(function(error) {
    //       console.log(error.response);
    //     })
    //     .then(function() {
    //       //
    //     });
    // },
    edit_file(file) {
      let _this = this;
      _this.create_file = 3;
      _this.editFormData.id = file.id;
      _this.editFormData.companyfile_name = file.companyfile_name;
      _this.editFormData.companyfile_filetype = file.companyfile_filetype;
      _this.editFormData.description = file.description;
    },
    delete_file(file) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        company_id: _this.authCompanyData.id,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to delete [" +
            file.asset_name +
            "] from company file?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}deleteasset`, {
                ids: [
                  {
                    id: file.id
                  }
                ]
              })
              .then(function () {
                _this.fetch_all_asset_types();
                _this.fetch_company_assets();
                Toast.fire({
                  icon: "success",
                  title: "Asset deleted successfully"
                });
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title: file.asset_name + " couldn't be deleted"
                });
                console.log(error);
              })
              .then(function () {
                //
              });
          }
        });
    },
    assign_asset_to_employee() {
      let _this = this;
      _this.assign_asset_text = "";
      _this.assign_asset_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}createemployeeasset`, {
          company_id: _this.authCompanyData.id,
          asset_id: _this.assignFormData.id,
          employee_id: _this.employee_id,
          employee_name: _this.assignFormData.employee,
          assettype_id: _this.assign_asset_type_id,
          given_date: _this.assignFormData.given_date,
          return_date: _this.assignFormData.return_date,
          asset_details: _this.assignFormData.details
        })
        .then(function () {
          _this.fetch_all_asset_types();
          _this.fetch_company_assets();

          Toast.fire({
            icon: "success",
            title: "Asset has been assigned to " + _this.assignFormData.employee
          });
          _this.assignFormData.id = "";
          _this.assignFormData.employee = "";
          _this.assign_asset_type_id = "";
          _this.assignFormData.given_date = "";
          _this.assignFormData.return_date = "";
          _this.assignFormData.details = "";
          _this.dialog = false;
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: "Asset could not be assigned"
          });
          console.log(error.response);
        })
        .then(function () {
          _this.assign_asset_submit = false;
          _this.assign_asset_text = "Assign";
        });
    },
    update_file() {
      let _this = this;
      _this.update_file_text = "";
      _this.update_file_submit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let file_upload_data = new FormData();
      file_upload_data.append("company_id", _this.authCompanyData.id);
      file_upload_data.append("id", _this.editFormData.id);
      file_upload_data.append(
        "companyfile_name",
        _this.editFormData.companyfile_name
      );
      file_upload_data.append("companyfile_filetype", _this.edit_file_type_id);
      file_upload_data.append("description", _this.editFormData.description);
      file_upload_data.append(
        "companyfile_url",
        document.querySelector(".update-company-file div div div input")
          .files[0]
      );

      axios
        .post(`${BASE_URL}editcompanyfile`, file_upload_data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "File upload successful"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.fetch_all_company_files();
          _this.create_file = 2;
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "File upload failed"
          });
        })
        .then(function () {
          _this.update_file_submit = false;
          _this.update_file_text = "Update";
        });
    },
    add_file() {
      let _this = this;
      _this.create_file_text = "";
      _this.create_file_submit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let file_upload_data = new FormData();
      file_upload_data.append("company_id", _this.authCompanyData.id);
      file_upload_data.append(
        "companyfile_name",
        _this.formData.companyfile_name
      );
      file_upload_data.append("companyfile_filetype", _this.file_type_id);
      file_upload_data.append("description", _this.formData.description);
      file_upload_data.append(
        "companyfile_url",
        document.querySelector(".upload-company-file div div div input")
          .files[0]
      );

      axios
        .post(`${BASE_URL}createcompanyfile`, file_upload_data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "File upload successful"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.fetch_all_company_files();
          _this.create_file = 2;
        })
        .catch(function () {
          Toast.fire({
            icon: "error",
            title: "File upload failed"
          });
        })
        .then(function () {
          _this.create_file_submit = false;
          _this.create_file_text = "Submit";
        });
    }
  },
  mounted() {
    document.title = "Kylian ERP - File Manager";
    let _this = this;
    // if (_this.auth_file_types) {
    //   _this.auth_file_types.forEach(file_type => {
    //     _this.file_type_names.push(
    //       file_type.filetype_name
    //     );
    //   });
    // }
    if (_this.userType) {
      _this.mixinBranchEmployees(_this.$route.name).forEach(person => {
        _this.allEmployees.push(
          person.employee_fristname + " " + person.employee_lastname
        );
      });
    } else {
      _this.fetch_company_file_types();
    }
  }
};
</script>

<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}

.upload-company-file div div div .v-file-input__text {
  cursor: pointer;
}

.update-company-file div div div .v-file-input__text {
  cursor: pointer;
}
</style>
