<template>
  <div @click="isFilter = false">
    <div
      id="default-overlay-job-create"
      style="
        width: 100%;
        height: 100%;
        background: #000;
        position: fixed;
        z-index: 1;
        opacity: 0;
        display: none;
        top: 0;
        left: 0;"
      @click="closeNav()"
    ></div>
    <div id="mySidenavJobCreate" class="sidenavJobCreate">
      <h3 class="h3-header pt-0">Add New Benefit</h3>
      <br />
      <v-form @submit.prevent="console.log('Submitted')">
        <v-container>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12" sm="12">
              <v-text-field
                v-model="formData.job_title"
                label="Job Title"
                solo
              ></v-text-field>
            </v-col>

            <v-col class="pb-0 pt-0" cols="12" sm="12">
              <v-select
                v-model="formData.department"
                item-color="#069"
                :items="allDepts"
                label="Select Department"
                solo
                color="#069"
                hint="*Select the department"
              ></v-select>
            </v-col>

            <v-col class="pb-0 pt-0" cols="12" sm="12">
              <v-select
                v-model="formData.employment"
                item-color="#069"
                :items="allEmployments"
                label="Select Employment"
                solo
                color="#069"
                hint="*Select the employment type"
              ></v-select>
            </v-col>

            <v-col class="pb-0 pt-0" cols="12" sm="12">
              <v-textarea
                v-model="formData.description"
                class="textarea-for-package-type"
                solo
                name="input-7-4"
                label="Description"
                hint="*Job Description"
              ></v-textarea>
            </v-col>

            <v-col class="pb-0 pt-0" cols="12" sm="12">
              <v-textarea
                v-model="formData.requirements"
                class="textarea-for-package-type"
                solo
                name="input-7-4"
                label="Requirements"
                hint="*Job Requirements"
              ></v-textarea>
            </v-col>

            <v-col class="pb-6 pt-0" cols="12" sm="12">
              <v-btn type="submit" x-large dark class="create-submit-package">
                {{ btnTxt2 }}
                <v-icon :style="!isLoading ? 'display: none' : ''"
                  >fas fa-circle-notch fa-spin</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <v-row>
      <v-col cols="4" md="4">
        <v-row>
          <v-col cols="2" md="2">
            <v-checkbox
              class="mt-2"
              v-model="allCheckboxes"
              @click="allCheckboxesFunc()"
              color="#069"
            ></v-checkbox>
          </v-col>
          <v-col cols="7" md="7">
            <v-select
              item-color="#069"
              :items="action"
              label="Select Action"
              solo
              color="#069"
            ></v-select>
          </v-col>
          <v-col cols="3" md="3">
            <v-btn type="button" dark class="headButton">
              Apply
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5" md="5">
        <v-row>
          <v-col cols="8" md="8" offset="1">
            <v-text-field
              style="text-indent: 5px;"
              v-model="search"
              solo
              prepend-inner-icon="mdi-magnify"
              type="text"
              name="input-10-2"
              class="input-group--focused search-magnify"
              color="#D0C9D6"
              label="Search benefits"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="2">
            <v-menu offset-y :value="isFilter">
              <template v-slot:activator="{ on, attrs }">
                <svg
                  v-bind="attrs"
                  v-on="on"
                  @click="isFilter = true"
                  style="cursor: pointer; background: #F2F2F2; border-radius: 5px; padding: 5px;"
                  width="47"
                  height="47"
                  viewBox="0 0 37 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.2679 11.8123H3.54367C3.12044 11.8123 2.71454 11.6345 2.41527 11.318C2.116 11.0015 1.94788 10.5723 1.94788 10.1248C1.94788 9.6772 2.116 9.24798 2.41527 8.93151C2.71454 8.61505 3.12044 8.43726 3.54367 8.43726H32.2679C32.6911 8.43726 33.097 8.61505 33.3963 8.93151C33.6955 9.24798 33.8637 9.6772 33.8637 10.1248C33.8637 10.5723 33.6955 11.0015 33.3963 11.318C33.097 11.6345 32.6911 11.8123 32.2679 11.8123Z"
                    fill="#111D5E"
                  />
                  <path
                    d="M27.0718 19.6873H9.1772C8.75844 19.6873 8.35683 19.5095 8.06073 19.193C7.76462 18.8765 7.59827 18.4473 7.59827 17.9998C7.59827 17.5522 7.76462 17.123 8.06073 16.8065C8.35683 16.4901 8.75844 16.3123 9.1772 16.3123H27.0718C27.4905 16.3123 27.8922 16.4901 28.1883 16.8065C28.4844 17.123 28.6507 17.5522 28.6507 17.9998C28.6507 18.4473 28.4844 18.8765 28.1883 19.193C27.8922 19.5095 27.4905 19.6873 27.0718 19.6873Z"
                    fill="#111D5E"
                  />
                  <path
                    d="M21.0706 27.5623H15.9231C15.5136 27.5623 15.1208 27.3845 14.8312 27.068C14.5416 26.7516 14.3789 26.3223 14.3789 25.8748C14.3789 25.4272 14.5416 24.998 14.8312 24.6815C15.1208 24.3651 15.5136 24.1873 15.9231 24.1873H21.0706C21.4802 24.1873 21.873 24.3651 22.1626 24.6815C22.4522 24.998 22.6149 25.4272 22.6149 25.8748C22.6149 26.3223 22.4522 26.7516 22.1626 27.068C21.873 27.3845 21.4802 27.5623 21.0706 27.5623Z"
                    fill="#111D5E"
                  />
                </svg>
              </template>
              <v-list class="pa-0">
                <v-list-item
                  v-for="(item, index) in items1"
                  :key="index"
                  style="cursor: pointer;"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" md="3">
        <v-row>
          <v-col cols="12" md="12">
            <v-btn
              type="button"
              dark
              class="create-submit-notifications"
              @click.stop="openNav()"
            >
              Add New Benefit
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(benefit, index) in benefits"
        :key="index"
        cols="6"
        md="6"
        sm="12"
        class="pb-8"
      >
        <v-card class="feature-card">
          <v-row>
            <v-col cols="9" md="9" style="color: #111D5E;">
              <div class="pl-4 pt-4">
                <h4 class="mb-0" style="font-size: 17px; font-weight: 600;">
                  {{ benefit.name }}
                </h4>
                <span
                  class="pt-3 pb-3"
                  style="font-size: 14px; display: block;"
                  >{{ benefit.details }}</span
                >
              </div>
            </v-col>
            <v-col cols="3" md="3" style="align-self: center;">
              <v-row>
                <v-col cols="3" md="3">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.2315 4.83008H3.31152V6.27591H12.2315V4.83008Z"
                      fill="#111D5E"
                    />
                    <path
                      d="M16.6006 15.7011C17.2921 14.4796 17.0916 12.9233 16.0858 11.9185C15.4907 11.3235 14.6995 10.9951 13.8552 10.9951C13.0109 10.9951 12.2186 11.3235 11.6236 11.9185C11.0286 12.5136 10.7002 13.3048 10.7002 14.1502C10.7002 14.9923 11.0286 15.7846 11.6236 16.3818C12.2186 16.9746 13.0098 17.303 13.8531 17.303C14.4026 17.303 14.9347 17.1632 15.4073 16.8966L18.9428 20.4321L20.1361 19.2377L16.6006 15.7011ZM15.1829 15.4768C14.4763 16.1834 13.2364 16.1834 12.5297 15.4768C11.7981 14.7441 11.7981 13.5551 12.5297 12.8225C12.883 12.4691 13.3556 12.274 13.8574 12.274C14.3592 12.274 14.8296 12.4691 15.184 12.8225C15.5384 13.1758 15.7335 13.6483 15.7335 14.1502C15.7335 14.652 15.5374 15.1224 15.1829 15.4768Z"
                      fill="#111D5E"
                    />
                    <path
                      d="M14.8227 18.0981H2.38567C2.18841 18.0981 2.02909 17.9377 2.02909 17.7426V2.20696C2.02909 2.0097 2.18841 1.85038 2.38567 1.85038H14.8227C15.0199 1.85038 15.1782 2.0097 15.1782 2.20696V10.132C15.693 10.2447 16.1731 10.4647 16.598 10.779V2.20696C16.598 1.22826 15.8025 0.431641 14.8227 0.431641H2.38567C1.40697 0.431641 0.610352 1.22826 0.610352 2.20696V17.7426C0.610352 18.7203 1.40697 19.5169 2.38567 19.5169H14.8227C15.5651 19.5169 15.9163 19.3413 16.1807 18.6932L15.5369 18.0494C15.2996 18.1491 14.9246 18.0981 14.8227 18.0981Z"
                      fill="#111D5E"
                    />
                    <path
                      d="M12.2315 8.90527H3.31152V10.3511H12.2315V8.90527Z"
                      fill="#111D5E"
                    />
                  </svg>
                </v-col>
                <v-col cols="3" md="3">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1315 7.8584C12.8728 7.8584 12.6631 8.06807 12.6631 8.32678V17.1792C12.6631 17.4378 12.8728 17.6476 13.1315 17.6476C13.3902 17.6476 13.5999 17.4378 13.5999 17.1792V8.32678C13.5999 8.06807 13.3902 7.8584 13.1315 7.8584Z"
                      fill="#111D5E"
                      stroke="#111D5E"
                      stroke-width="0.3"
                    />
                    <path
                      d="M7.6051 7.8584C7.34639 7.8584 7.13672 8.06807 7.13672 8.32678V17.1792C7.13672 17.4378 7.34639 17.6476 7.6051 17.6476C7.86381 17.6476 8.07349 17.4378 8.07349 17.1792V8.32678C8.07349 8.06807 7.86381 7.8584 7.6051 7.8584Z"
                      fill="#111D5E"
                      stroke="#111D5E"
                      stroke-width="0.3"
                    />
                    <path
                      d="M3.57638 6.5665V18.1065C3.57638 18.7885 3.82649 19.4291 4.2634 19.8887C4.6983 20.3496 5.30354 20.6112 5.93695 20.6123H14.7989C15.4325 20.6112 16.0377 20.3496 16.4724 19.8887C16.9094 19.4291 17.1595 18.7885 17.1595 18.1065V6.5665C18.028 6.33596 18.5908 5.4969 18.4746 4.60569C18.3582 3.71467 17.5991 3.04814 16.7004 3.04796H14.3023V2.46248C14.3051 1.97013 14.1104 1.49735 13.7619 1.14954C13.4133 0.801915 12.9398 0.608341 12.4475 0.612366H8.28838C7.79603 0.608341 7.32252 0.801915 6.97398 1.14954C6.62544 1.49735 6.43077 1.97013 6.43351 2.46248V3.04796H4.03543C3.13672 3.04814 2.37761 3.71467 2.26125 4.60569C2.14507 5.4969 2.70786 6.33596 3.57638 6.5665ZM14.7989 19.6755H5.93695C5.13613 19.6755 4.51314 18.9876 4.51314 18.1065V6.60766H16.2227V18.1065C16.2227 18.9876 15.5997 19.6755 14.7989 19.6755ZM7.37028 2.46248C7.36717 2.21859 7.46304 1.98385 7.63612 1.81168C7.80902 1.63951 8.04431 1.54492 8.28838 1.54913H12.4475C12.6915 1.54492 12.9268 1.63951 13.0997 1.81168C13.2728 1.98367 13.3687 2.21859 13.3656 2.46248V3.04796H7.37028V2.46248ZM4.03543 3.98472H16.7004C17.1661 3.98472 17.5435 4.36217 17.5435 4.82781C17.5435 5.29345 17.1661 5.6709 16.7004 5.6709H4.03543C3.56979 5.6709 3.19234 5.29345 3.19234 4.82781C3.19234 4.36217 3.56979 3.98472 4.03543 3.98472Z"
                      fill="#111D5E"
                      stroke="#111D5E"
                      stroke-width="0.3"
                    />
                    <path
                      d="M10.3688 7.8584C10.1101 7.8584 9.90039 8.06807 9.90039 8.32678V17.1792C9.90039 17.4378 10.1101 17.6476 10.3688 17.6476C10.6275 17.6476 10.8372 17.4378 10.8372 17.1792V8.32678C10.8372 8.06807 10.6275 7.8584 10.3688 7.8584Z"
                      fill="#111D5E"
                      stroke="#111D5E"
                      stroke-width="0.3"
                    />
                  </svg>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2" md="2" offset="5" class="pt-5">
        <v-btn
          type="button"
          x-large
          dark
          class="headButton"
          style="height: 45px !important; font-size: 12px !important;"
        >
          Load More
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFilter: false,
      items1: [{ title: "Date Posted" }, { title: "Job Role" }],
      allCheckboxes: false,
      search: "",
      isLoading: false,
      action: [
        "1 - 9",
        "10 - 19",
        "20 - 29",
        "30 - 39",
        "40 - 49",
        "50 - above"
      ],
      formData: {
        job_title: "",
        department: "",
        employment: "",
        description: "",
        requirements: ""
      },
      allDepts: [
        "1 - 9",
        "10 - 19",
        "20 - 29",
        "30 - 39",
        "40 - 49",
        "50 - above"
      ],
      allEmployments: [
        "1 - 9",
        "10 - 19",
        "20 - 29",
        "30 - 39",
        "40 - 49",
        "50 - above"
      ],
      show: false,
      btnTxt2: "Submit",
      benefits: [
        {
          id: 1,
          name: "General Staff Health Insurance",
          details: "Deducting 5% of Salary for Tier 1 Hospitals"
        },
        {
          id: 2,
          name: "Pension Payment",
          details: "Discussions from basic salary"
        },
        {
          id: 3,
          name: "Travel Allowance/Bonus",
          details: "Allowances for festive"
        }
      ]
    };
  },
  methods: {
    allCheckboxesFunc() {
      if (this.allCheckboxes) {
        this.recruitments.forEach(item => {
          item.selected = true;
        });
      } else {
        this.recruitments.forEach(item => {
          item.selected = false;
        });
      }
    },
    checkForAll() {
      let allSelected = this.recruitments.filter(item => {
        return item.selected === true;
      });
      if (allSelected.length === this.recruitments.length) {
        this.allCheckboxes = true;
      } else {
        this.allCheckboxes = false;
      }
    },
    openNav() {
      this.isFilter = false;
      this.navDraw = true;
      if (screen.width > 450) {
        document.getElementById("mySidenavJobCreate").style.width = "40%";
        document.getElementById("mySidenavJobCreate").style.paddingLeft =
          "30px";
        document.getElementById("mySidenavJobCreate").style.paddingRight =
          "30px";
        document.getElementById("default-overlay-job-create").style.opacity =
          "0.1";
        document.getElementById("default-overlay-job-create").style.display =
          "block";
      } else {
        document.getElementById("mySidenavJobCreate").style.width = "80%";
        document.getElementById("default-overlay-job-create").style.opacity =
          "0.1";
        document.getElementById("default-overlay-job-create").style.display =
          "block";
      }
    },
    closeNav() {
      console.log("close");
      document.getElementById("mySidenavJobCreate").style.width = "0";
      document.getElementById("mySidenavJobCreate").style.paddingLeft = "0px";
      document.getElementById("mySidenavJobCreate").style.paddingRight = "0px";
      document.getElementById("default-overlay-job-create").style.opacity = "0";
      document.getElementById("default-overlay-job-create").style.display =
        "none";
    }
  }
};
</script>

<style lang="scss">
.search-magnify div div div div i {
  color: #d0c9d6 !important;
  top: 10%;
}
.search-magnify div div div label {
  color: #d0c9d6 !important;
}
</style>
<style lang="scss" scoped>
.feature-card {
  width: 90%;
  box-shadow: 0px 5px 10px 0px #00000014 !important;
}
.textarea-for-package-type div div {
  padding-right: 7px !important;
}
.create-submit-package {
  width: 100%;
  height: 60px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;
  span {
    text-transform: initial !important;
  }
}
.h3-header {
  font-size: 20px !important;
}
.sidenavJobCreate {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  padding-right: 0;
  padding-left: 0;
}

.sidenavJobCreate a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
}

.sidenavJobCreate a:hover {
  color: #f1f1f1;
}

.sidenavJobCreate .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenavJobCreate {
    padding-top: 15px;
  }
  .sidenavJobCreate a {
    font-size: 18px;
  }
}
$primary-color: #069;
.right-card {
  box-shadow: 0px 5px 10px 0px #00000014 !important;
}
.create-submit-notifications {
  font-size: 15px !important;
  font-weight: 600;
  width: 90%;
  margin-right: 5px;
  height: 47px !important;
  border-radius: 7px !important;
  background-color: #069 !important;
  text-transform: initial;
}
button.headButton {
  background-color: #fff !important;
  color: $primary-color;
  border: 1px solid $primary-color;
  width: 100%;
  height: 47px !important;
  border-radius: 7px;
  font-size: 15px !important;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: capitalize;
  box-shadow: none !important;
}
p {
  color: #111d5e;
  font-weight: 400;
}
</style>
