<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pl-3 pr-1' : 'pl-4'"
        class="main-header" style="z-index: 60;">
        <div class="row">
          <div v-if="!authSubscriptionExpired && check_all_services('Attendance')"
            class="logo col-md-3 col-lg-3 py-0 px-0"
            :class="userType != 1 && check_all_services('Attendance') && ($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') && permissionIsAssigned('attendance-view') ? 'col-4' : 'col-12'">
            <button @click.prevent="checkOutLoading ? null : check_in()" v-if="checkedIn" type="button"
              style="background-color: #c70039 !important;"
              class="btn btn-rounded btn-raised ripple btn-raised-primary m-1 ml-3 text-white">
              {{ checkOutText }}
              <v-icon :style="
  !checkOutLoading
    ? 'display: none;'
    : 'color: #fff; padding: 0px 35px; font-size: 14px;'
">
                fas fa-circle-notch fa-spin
              </v-icon>
              <span v-if="!checkOutLoading" class="ul-btn__icon"><i class="i-Clock-Forward"></i></span>
            </button>
            <button @click.prevent="checkInLoading ? null : check_in()" v-else type="button"
              class="btn btn-rounded btn-raised ripple btn-raised-secondary m-1 ml-3 text-white">
              {{ checkInText }}
              <v-icon :style="
  !checkInLoading
    ? 'display: none;'
    : 'color: #fff; padding: 0px 30px; font-size: 14px;'
">
                fas fa-circle-notch fa-spin
              </v-icon>
              <span v-if="!checkInLoading" class="ul-btn__icon"><i class="i-Clock-Forward"></i></span>
            </button>
            <span
              v-if="!(userType != 1 && permissionIsAssigned('attendance-view') && ($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'))"
              class="ml-2" style="top: 4%; position: relative;">{{ timeSpent }} hrs</span>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('attendance-view')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span>{{
    employeeView ? "Your Attendance History" : "All Employees' Attendance"
}}</span>
            </button>
            <div v-if="permissionIsAssigned('attendance-view')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView, employeeView == false ? $router.push({ name: 'Attendance' }) : null"
                class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Attendance" : "Your Attendance History" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-0' : 'pr-4'"
          class="header-part-right">
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType == 1 && check_all_services('Attendance')" type="button"
              class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
            <button v-if="userType != 1 && check_all_services('Attendance')" type="button"
              class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row"
            :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'width: 110%;' : null">
            <h1 class="my-0 col-md-12 pb-0">
              <span
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'd-block mb-6' : 'mt-4'">Log
                Report</span>
              <v-row
                :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'float: right;'">
                <v-col offset-lg="4" offset-md="4" lg="4" md="4" cols="4" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" label="Year" dense solo messages=" *Filter by year"
                    :full-width="true" v-model="yearSelected" @change="update_checking_details()"></v-select>
                </v-col>
                <v-col lg="4" md="4" cols="5" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" label="Month" dense solo messages="*Filter by month"
                    :full-width="true" v-model="monthSelected" @change="update_checking_details()"></v-select>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-6 col-lg-6 col-12">
              <div class="mb-4">
                <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null"
                  class="card-body py-0">
                  <div id="basicPie" class="basicPie1" style="height: 500px;"></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-12"
              :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'top: -200px;' : null">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table
                      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'table-responsive' : null"
                      class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col">Date</th>
                          <th scope="col">Check-in</th>
                          <th scope="col">Check-out</th>
                          <th scope="col">Duration</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody v-if="finalCheckingDetails.length">
                        <tr v-for="detail in finalCheckingDetails" :key="detail.id">
                          <th @click="(currentDetails = detail, commentDialog = true)" class="underline" scope="row">{{
    new Date(detail.day).toDateString()
}}</th>
                          <td v-if="detail.check_in">
                            {{ detail.check_in.split(' ').length == 1 ? detail.check_in : detail.check_in.split(' ')[1]
}}
                          </td>
                          <td v-else>
                            <i>No check in</i>
                          </td>
                          <td v-if="detail.check_out">
                            {{
    detail.check_out.split(' ').length == 1
      ?
      detail.check_out
      :
      detail.check_out.split(' ')[1]
}}
                          </td>
                          <td v-else>
                            <i>No check out</i>
                          </td>
                          <td v-if="detail.check_out">
                            {{ detail.duration }}
                          </td>
                          <td v-else>
                            <i>No duration</i>
                          </td>
                          <td>
                            <span v-if="detail.status == 'early'" class="badge badge-round-success sm"
                              style="width: 10px; height: 10px;">
                              <span></span>
                            </span>
                            <span v-if="detail.status == 'late'" class="badge badge-round-warning sm"
                              style="width: 10px; height: 10px;">
                              <span></span>
                            </span>
                            <span v-if="detail.status == 'on leave'" class="badge badge-round-info sm"
                              style="width: 10px; height: 10px;">
                              <span></span>
                            </span>
                            <span v-if="detail.status == 'absent'" class="badge badge-round-danger sm"
                              style="width: 10px; height: 10px;">
                              <span></span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-row justify="center">
          <v-dialog v-model="commentDialog" max-width="900px">
            <v-card>
              <v-card-title>
                <span class="headline">Punch in/out comments</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <h5>Check-in Note: {{ currentDetails ? currentDetails.punch_in_note : null }}</h5>
                    </v-col>
                    <v-col cols="12">
                      <h5>Check-out Note: {{ currentDetails ? currentDetails.punch_out_note : null }}</h5>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="commentDialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/main";
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      geo_location_latitude: '',
      geo_location_longitude: '',
      geo_location_address: '',
      currentDetails: null,
      commentDialog: false,
      employeeView: true,
      refreshing_page: false,
      monthSelected: "",
      yearSelected: "",
      checkingDetails: [],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      formData: {
        checkInNote: ""
      },
      firstYear: "",
      timeSpent: "",
      checkInText: "Check-in",
      checkInLoading: false,
      checkOutLoading: false,
      checkOutText: "Check-out"
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "checkedIn",
      "userType",
      "authEmployeeDataForCompany",
      "checkInMs",
      "authCompanyHolidays",
      "authCompanyAttendanceConfig",
      "authCompanyAllLeave",
      "authSubscriptionExpired"
    ]),
    employeeApprovedLeave() {
      let _this = this, result = [];
      if (_this.authCompanyAllLeave.length >= 1) {
        result = _this.authCompanyAllLeave.filter(lv => _this.authEmployeeDataForCompany.id == lv.employee_id && lv.status == 1)
      }
      return result;
    },
    finalCheckingDetails() {
      let _this = this;
      let checkInDays = _this.checkingDetails.map(c => c.ms_time.toString().substring(0, 15));
      let dateToCheck, daysToGet, result = [], holidays = [], leaveDays = [];

      // BELOW IS TO GET DAYS ON LEAVE

      if (_this.employeeApprovedLeave.length >= 1) {
        _this.employeeApprovedLeave.forEach(lv => {
          _this.getDatesBetweenDates(new Date(lv.leave_start), new Date(lv.leave_end + ' 01:00:01')).forEach(d => {
            leaveDays.splice(leaveDays.length, 0, new Date(d).toString().substring(0, 15))
          });
        });
      }

      // END DAYS ON LEAVE CALCULATION

      dateToCheck = new Date(Date.UTC(parseInt(_this.yearSelected), _this.monthNames.indexOf(_this.monthSelected)));
      daysToGet = [];
      while (dateToCheck.getUTCMonth() === _this.monthNames.indexOf(_this.monthSelected)) {
        if (dateToCheck.getTime() > new Date().getTime()) {
          break;
        }
        daysToGet.push(new Date(dateToCheck).toString().substring(0, 15));
        dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
      }

      _this.authCompanyHolidays.forEach(h => {
        _this.getDatesBetweenDates(new Date(h.start), new Date(h.end.replace('00:00:00', '01:00:00'))).forEach(d => {
          holidays.splice(holidays.length, 0, new Date(d).toString().substring(0, 15))
        });
      });


      daysToGet.forEach(day => {
        if (holidays.indexOf(day) == -1 && leaveDays.indexOf(day) != -1 && _this.mixinCompanyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) {
          if (checkInDays.indexOf(day) == -1) {
            result.splice(result.length, 0, {
              day: day,
              status: 'on leave'
            });
          } else {
            result.splice(result.length, 0, _this.checkingDetails[checkInDays.indexOf(day)]);
          }
        } else if (holidays.indexOf(day) == -1 && leaveDays.indexOf(day) == -1 && _this.mixinCompanyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) { // THIS IS IF THE DAY IS NOT A HOLIDAY
          if (checkInDays.indexOf(day) == -1) {
            result.splice(result.length, 0, {
              day: day,
              status: 'absent'
            });
          } else {
            result.splice(result.length, 0, _this.checkingDetails[checkInDays.indexOf(day)]);
          }
        } else {
          //
        }
      });

      return result;
    },
    month() {
      let d = new Date();
      return this.monthNames[d.getMonth()];
    },
    allYears() {
      let yearsArray = [],
        currentYear = new Date().getFullYear();
      yearsArray.push(this.firstYear);
      for (let yearX = this.firstYear; yearX <= currentYear; yearX++) {
        if (yearsArray.indexOf(yearX) == -1) {
          yearsArray.push(yearX.toString());
        }
      }
      return yearsArray.reverse();
    }
  },
  watch: {
    checkedIn(new_value) {
      if (new_value) {
        this.check_is_today()
      }
    },
  },
  methods: {
    ...mapActions([
      "refresh_checkin"
    ]),
    ...mapMutations([
      "GET_CHECK_IN_STATUS",
      "SET_CHECK_IN_TIME"
    ]),
    check_is_today() {
      let _this = this;
      if (_this.checkedIn) {
        let is_today_interval = setInterval(() => {
          if (new Date(_this.checkInMs).toDateString() != new Date().toDateString() && _this.checkInMs != '') {
            let _this = this;
            if (!_this.checkOutLoading) {
              const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
              });
              _this.checkOutText = "";
              _this.checkOutLoading = true;
              axios
                .post(`${BASE_URL}checkout`, {
                  company_id: _this.authEmployeeDataForCompany.company_id,
                  employee_id: _this.authEmployeeDataForCompany.id,
                  check_out: '00:00:00',
                  punch_out_note: 'Failed to checkout',
                  day: localStorage.getItem('last_day'),
                  duration: '00:00:00'
                })
                .then(function () {
                  Toast.fire({
                    icon: "success",
                    title: "Check-out successful"
                  });
                  localStorage.setItem("msTime", null);
                  _this.SET_CHECK_IN_TIME(null);
                  _this.timeSpent = "00:00:00";
                  localStorage.setItem("checkInStatus", 0);
                  _this.GET_CHECK_IN_STATUS(0);
                  _this.formData.checkInNote = "";
                  localStorage.setItem('last_day', null)
                  clearInterval(is_today_interval)
                })
                .catch(function (error) {
                  Toast.fire({
                    icon: "error",
                    title: "Could not check-out"
                  });
                  console.log(error);
                })
                .then(function () {
                  _this.checkOutLoading = false;
                  _this.checkOutText = "Check-out";
                });
            }
          }
        }, 1000);
      }
    },
    refresh_page() {
      this.refreshing_page = true;
      this.fetch_checking_details();
      let _this = this;
      _this.timeSpentCalculator();
      setInterval(() => {
        _this.timeSpentCalculator();
      }, 1000);
      this.refreshing_page = false;
    },
    timeSpentCalculator() {
      let _this = this;
      if (_this.checkInMs && _this.checkedIn) {
        let duration = new Date().getTime() - _this.checkInMs;
        let /*milliseconds = parseInt((duration % 1000) / 100),*/
          seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        // console.log(hours + ":" + minutes + ":" + seconds + "." + milliseconds)
        // return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
        _this.timeSpent = hours + ":" + minutes + ":" + seconds;
      } else {
        _this.timeSpent = "00:00";
      }
    },
    realCheckin(time, today, msTime) {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}checkin`, {
          company_id: _this.authEmployeeDataForCompany.company_id,
          employee_id: _this.authEmployeeDataForCompany.id,
          check_in: time,
          punch_in_note: _this.formData.checkInNote,
          day: today,
          email: _this.authEmployeeDataForCompany.employee_email,
          ms_time: msTime,
          latitude: _this.geo_location_latitude,
          longitude: _this.geo_location_longitude,
          address: _this.geo_location_address
        })
        .then(function (response) {
          console.log("BELOW IS THE RESPONSE DATA FOR CHECK-IN");
          console.log(response.data);
          if (response.data.error) {
            Toast.fire({
              icon: "error",
              title: response.data.message
            });
            _this.refresh_checkin();
          } else {
            Toast.fire({
              icon: "success",
              title: "Check-in successful"
            });
            localStorage.setItem('last_day', today);
            localStorage.setItem(
              "msTime",
              new Date(response.data.attendant.ms_time).getTime()
            );
            _this.SET_CHECK_IN_TIME(
              new Date(response.data.attendant.ms_time).getTime()
            );
            localStorage.setItem("checkInStatus", 1);
            _this.GET_CHECK_IN_STATUS(1);
          }
          _this.formData.checkInNote = "";
          _this.fetch_checking_details();
        })
        .catch(function (error) {
          _this.refresh_checkin();
          let errors = error.response.data || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.check_in ? errors.check_in[0] : null
                || errors.day ? errors.day[0] : null
                  || errors.ms_time ? errors.ms_time[0] : null
            || error.response.data.message) : 'Could not check-in'
          });
          _this.checkInLoading = false;
          _this.checkInText = "Check-in";
        })
        .then(function () {
          _this.checkInLoading = false;
          _this.checkInText = "Check-in";
        })
    },
    realCheckout(time, today) {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.$swal
        .fire({
          title: "Are you sure you want to checkout?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Checkout`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}checkout`, {
                company_id: _this.authEmployeeDataForCompany.company_id,
                employee_id: _this.authEmployeeDataForCompany.id,
                check_out: time,
                punch_out_note: _this.formData.checkInNote,
                day: today,
                duration: _this.timeSpent
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "Check-out successful"
                });
                localStorage.setItem("msTime", null);
                _this.SET_CHECK_IN_TIME(null);
                _this.timeSpent = "00:00:00";
                localStorage.setItem("checkInStatus", 0);
                _this.GET_CHECK_IN_STATUS(0);
                _this.formData.checkInNote = "";
                _this.fetch_checking_details();
              })
              .catch(function (error) {
                let errors = error.response.data.error || null;
                if (typeof errors == 'string') {
                  Toast.fire({
                    icon: "error",
                    title: error.response.data.message || 'Could not check-out'
                  });
                } else {
                  Toast.fire({
                    icon: "error",
                    title: errors ? (typeof errors === 'string' ? errors : null
                      || errors.check_out ? errors.check_out[0] : null
                        || errors.day ? errors.day[0] : null) : null
                        || error.response.data.message || 'Could not check-out'
                  });
                }
                _this.checkOutLoading = false;
                _this.checkOutText = "Check-out";

                // localStorage.setItem("msTime", null);
                // _this.SET_CHECK_IN_TIME(null);
                // _this.timeSpent = "00:00:00";
                // localStorage.setItem("checkInStatus", 0);
                // _this.GET_CHECK_IN_STATUS(0);
                // _this.formData.checkInNote = "";
              })
              .then(function () {
                _this.checkOutLoading = false;
                _this.checkOutText = "Check-out";
              });
          } else {
            _this.checkOutLoading = false;
            _this.checkOutText = "Check-out";
          }
        });
    },
    check_in() {
      let _this = this;
      // const Toast = _this.$swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 2000,
      //   timerProgressBar: true,
      //   didOpen: toast => {
      //     toast.addEventListener("mouseenter", _this.$swal.stopTimer);
      //     toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
      //   }
      // });

      if (_this.checkedIn) {
        _this.checkOutText = "";
        _this.checkOutLoading = true;


        let exDate = new Date();
        let offset = '+1', utc = exDate.getTime() + (exDate.getTimezoneOffset() * 60000);
        let mainDate = new Date(utc + (3600000 * offset));

        let d = new Date(mainDate);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        month = month <= 9 ? "0" + month.toString() : month;
        let day = d.getDate();
        day = day <= 9 ? "0" + day.toString() : day;

        let today = `${year.toString()}-${month.toString()}-${day.toString()}`;

        let hour = d.getHours();
        hour = hour <= 9 ? "0" + hour.toString() : hour;
        let minutes = d.getMinutes();
        minutes = minutes <= 9 ? "0" + minutes.toString() : minutes;
        let seconds = d.getSeconds();
        seconds = seconds <= 9 ? "0" + seconds.toString() : seconds;

        let time = `${today} ${hour.toString()}:${minutes.toString()}:${seconds.toString()}`;

        _this.realCheckout(time, today);

      } else {
        _this.checkInText = "";
        _this.checkInLoading = true;


        let exDate = new Date();
        let offset = '+1', utc = exDate.getTime() + (exDate.getTimezoneOffset() * 60000);
        let mainDate = new Date(utc + (3600000 * offset));

        let d = new Date(mainDate);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        month = month <= 9 ? "0" + month.toString() : month;
        let day = d.getDate();
        day = day <= 9 ? "0" + day.toString() : day;

        let today = `${year.toString()}-${month.toString()}-${day.toString()}`;

        let hour = d.getHours();
        hour = hour <= 9 ? "0" + hour.toString() : hour;
        let minutes = d.getMinutes();
        minutes = minutes <= 9 ? "0" + minutes.toString() : minutes;
        let seconds = d.getSeconds();
        seconds = seconds <= 9 ? "0" + seconds.toString() : seconds;

        let time = `${today} ${hour.toString()}:${minutes.toString()}:${seconds.toString()}`;

        let timeForMs = new Date(d.constructor().substring(0, 25))
        let msTime = timeForMs.getTime();
        msTime = new Date(msTime).toString();

        _this.locateAndCheckin(time, today, msTime);

      }
    },
    locateAndCheckin(time, today, msTime) {
      let _this = this;
      // document.getElementById("btnAction").disabled = true;
      if ("geolocation" in window.navigator) {
        window.navigator.geolocation.getCurrentPosition(function (position) {
          let currentLatitude = position.coords.latitude;
          let currentLongitude = position.coords.longitude;

          _this.geo_location_latitude = currentLatitude;
          _this.geo_location_longitude = currentLongitude;
          _this.getReverseGeocodingData(currentLatitude, currentLongitude, time, today, msTime)

          // var infoWindowHTML = "Latitude: " + currentLatitude + "<br>Longitude: " + currentLongitude;
          // var infoWindow = new google.maps.InfoWindow({map: map, content: infoWindowHTML});
          // var currentLocation = { lat: currentLatitude, lng: currentLongitude };
          // infoWindow.setPosition(currentLocation);
          // document.getElementById("btnAction").style.display = 'none';
        }, function () {
          _this.realCheckin(time, today, msTime);
        });
      }
    },
    getReverseGeocodingData(lat, lng, time, today, msTime) {
      let _this = this;
      let latlng = new window.google.maps.LatLng(lat, lng);
      // This is making the Geocode request
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ 'latLng': latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          // alert(status);
          // Toast.fire({
          //   icon: "error",
          //   title: status
          // });
          _this.realCheckin(time, today, msTime);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == window.google.maps.GeocoderStatus.OK) {
          //console.log(results);
          let address = (results[0].formatted_address);
          _this.geo_location_address = address;

          _this.realCheckin(time, today, msTime);
        }


      });
    },
    fetch_checking_details() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchemployeechecking`, {
          company_id: _this.authEmployeeDataForCompany.company_id,
          employee_id: _this.authEmployeeDataForCompany.id,
          month: _this.month,
          year: new Date().getFullYear()
        })
        .then(function (response) {
          _this.checkingDetails = response.data.user_attendance;
          _this.firstYear = new Date(_this.authCompanyData.created_at).getFullYear().toString();
          if (response.data.user_attendance.length >= 1) {
            _this.yearSelected = response.data.user_attendance[0].year;
          } else {
            // _this.yearSelected = new Date().getFullYear().toString();
          }
          const basicPieElem = document.getElementById("basicPie");

          let early, late, absent = 0, onLeave = 0, checkInDays, dateToCheck, daysToGet, holidays = [], leaveDays = [];
          if (_this.checkingDetails.length >= 1 || new Date(response.data.year_object.day).getTime() < new Date().getTime() || new Date().getTime() - 1 < new Date().getTime()) {
            early = _this.finalCheckingDetails.filter(
              check => check.status == "early"
            ).length;
            late = _this.finalCheckingDetails.filter(
              check => check.status == "late"
            ).length;


            checkInDays = _this.checkingDetails.map(c => c.ms_time.toString().substring(0, 15));

            dateToCheck = new Date(Date.UTC(parseInt(_this.yearSelected), _this.monthNames.indexOf(_this.monthSelected)));
            daysToGet = [];
            while (dateToCheck.getUTCMonth() === _this.monthNames.indexOf(_this.monthSelected)) {
              if (dateToCheck.getTime() > new Date().getTime()) {
                break;
              }
              daysToGet.push(new Date(dateToCheck).toString().substring(0, 15));
              dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
            }

            // BELOW IS TO GET DAYS ON LEAVE

            _this.employeeApprovedLeave.forEach(lv => {
              _this.getDatesBetweenDates(new Date(lv.leave_start), new Date(lv.leave_end + ' 01:00:01')).forEach(d => {
                leaveDays.splice(leaveDays.length, 0, new Date(d).toString().substring(0, 15))
              });
            });

            daysToGet.forEach(day => {
              if (checkInDays.indexOf(day) == -1 && leaveDays.indexOf(day) != -1 && holidays.indexOf(day) == -1 && _this.mixinCompanyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) {
                // console.log(day);
                onLeave += 1;
              }
            });

            // END DAYS ON LEAVE CALCULATION


            // BELOW IS TO GET DAYS ABSENT

            _this.authCompanyHolidays.forEach(h => {
              _this.getDatesBetweenDates(new Date(h.start), new Date(h.end.replace('00:00:00', '01:00:00'))).forEach(d => {
                holidays.splice(holidays.length, 0, new Date(d).toString().substring(0, 15))
              });
            });

            daysToGet.forEach(day => {
              if (checkInDays.indexOf(day) == -1 && holidays.indexOf(day) == -1 && leaveDays.indexOf(day) == -1 && _this.mixinCompanyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) {
                // console.log(day);
                absent += 1;
              }
            });

            // absent = 0;

            // END DAYS ABSENT CALCULATION

          } else {
            early = 0;
            late = 0;
            absent = 0;
            onLeave = 0;
          }

          if (basicPieElem) {
            var basicPie = window.echarts.init(basicPieElem);
            basicPie.setOption({
              color: ["#4caf50", "#ebcb37", "#003473", "#c13018"],
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              yAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              series: [
                {
                  name: "Time Logs",
                  type: "pie",
                  radius: "70%",
                  center: ["50%", "50%"],
                  data: [
                    {
                      value: early,
                      name: early == 1 ? "EARLY (" + early + " day)" : "EARLY (" + early + " days)"
                    },
                    {
                      value: late,
                      name: late == 1 ? "LATE (" + late + " day)" : "LATE (" + late + " days)"
                    },
                    _this.check_all_services('Absence or Leave') ? {
                      value: onLeave,
                      name: onLeave == 1 ? "ON LEAVE (" + onLeave + " day)" : "ON LEAVE (" + onLeave + " days)"
                    } : null,
                    {
                      value: absent,
                      name: absent == 1 ? "ABSENT (" + absent + " day)" : "ABSENT (" + absent + " days)"
                    }
                  ],
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)"
                    }
                  }
                }
              ]
            });
            window.$(window).on("resize", function () {
              setTimeout(function () {
                basicPie.resize();
              }, 500);
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          //
        });
    },
    update_checking_details() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchemployeechecking`, {
          company_id: _this.authEmployeeDataForCompany.company_id,
          employee_id: _this.authEmployeeDataForCompany.id,
          month: _this.monthSelected,
          year: _this.yearSelected
        })
        .then(function (response) {
          _this.checkingDetails = response.data.user_attendance;
          _this.firstYear = new Date(_this.authCompanyData.created_at).getFullYear().toString();
          if (response.data.user_attendance.length >= 1) {
            _this.yearSelected = response.data.user_attendance[0].year;
          } else {
            // _this.yearSelected = new Date().getFullYear().toString();
          }
          const basicPieElem = document.getElementById("basicPie");

          let early, late, absent = 0, onLeave = 0, checkInDays, dateToCheck, daysToGet, holidays = [], leaveDays = [];
          if (_this.checkingDetails.length >= 1 || new Date(response.data.year_object.day).getTime() < new Date().getTime() || new Date().getTime() - 1 < new Date().getTime()) {
            early = _this.finalCheckingDetails.filter(
              check => check.status == "early"
            ).length;
            late = _this.finalCheckingDetails.filter(
              check => check.status == "late"
            ).length;


            checkInDays = _this.checkingDetails.map(c => c.ms_time.toString().substring(0, 15));

            dateToCheck = new Date(Date.UTC(parseInt(_this.yearSelected), _this.monthNames.indexOf(_this.monthSelected)));
            daysToGet = [];
            while (dateToCheck.getUTCMonth() === _this.monthNames.indexOf(_this.monthSelected)) {
              if (dateToCheck.getTime() > new Date().getTime()) {
                break;
              }
              daysToGet.push(new Date(dateToCheck).toString().substring(0, 15));
              dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
            }

            // BELOW IS TO GET DAYS ON LEAVE

            _this.employeeApprovedLeave.forEach(lv => {
              _this.getDatesBetweenDates(new Date(lv.leave_start), new Date(lv.leave_end + ' 01:00:01')).forEach(d => {
                leaveDays.splice(leaveDays.length, 0, new Date(d).toString().substring(0, 15))
              });
            });

            daysToGet.forEach(day => {
              if (checkInDays.indexOf(day) == -1 && leaveDays.indexOf(day) != -1 && holidays.indexOf(day) == -1 && _this.mixinCompanyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) {
                // console.log(day);
                onLeave += 1;
              }
            });

            // END DAYS ON LEAVE CALCULATION


            // BELOW IS TO GET DAYS ABSENT

            _this.authCompanyHolidays.forEach(h => {
              _this.getDatesBetweenDates(new Date(h.start), new Date(h.end.replace('00:00:00', '01:00:00'))).forEach(d => {
                holidays.splice(holidays.length, 0, new Date(d).toString().substring(0, 15))
              });
            });

            daysToGet.forEach(day => {
              if (checkInDays.indexOf(day) == -1 && holidays.indexOf(day) == -1 && leaveDays.indexOf(day) == -1 && _this.mixinCompanyWorkDays.indexOf(_this.getDayOfWeek(new Date(day).getDay())) != -1) {
                // console.log(day);
                absent += 1;
              }
            });

            console.log('absent', absent)

            // absent = 0;

            // END DAYS ABSENT CALCULATION

          } else {
            early = 0;
            late = 0;
            absent = 0;
            onLeave = 0;
          }

          if (basicPieElem) {
            var basicPie = window.echarts.init(basicPieElem);
            basicPie.setOption({
              color: ["#4caf50", "#ebcb37", "#003473", "#c13018"],
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              xAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              yAxis: [
                {
                  axisLine: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  }
                }
              ],
              series: [
                {
                  name: "Time Logs",
                  type: "pie",
                  radius: "70%",
                  center: ["50%", "50%"],
                  data: [
                    {
                      value: early,
                      name: early == 1 ? "EARLY (" + early + " day)" : "EARLY (" + early + " days)"
                    },
                    {
                      value: late,
                      name: late == 1 ? "LATE (" + late + " day)" : "LATE (" + late + " days)"
                    },
                    _this.check_all_services('Absence or Leave') ? {
                      value: onLeave,
                      name: onLeave == 1 ? "ON LEAVE (" + onLeave + " day)" : "ON LEAVE (" + onLeave + " days)"
                    } : null,
                    {
                      value: absent,
                      name: absent == 1 ? "ABSENT (" + absent + " day)" : "ABSENT (" + absent + " days)"
                    }
                  ],
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)"
                    }
                  }
                }
              ]
            });
            window.$(window).on("resize", function () {
              setTimeout(function () {
                basicPie.resize();
              }, 500);
            });
          }
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .then(function () {
          //
        });
    }
  },
  mounted() {
    let _this = this;
    _this.fetch_checking_details();
    document.title = "Kylian ERP - Log Report";
    _this.monthSelected = _this.monthNames[new Date().getMonth()];
    _this.yearSelected = new Date().getFullYear().toString();
    _this.timeSpentCalculator();
    setInterval(() => {
      _this.timeSpentCalculator();
    }, 1000);
  }
};
</script>

<style>
@media (max-width: 991px) {
  .basicPie1 div canvas {
    top: -100px !important;
  }
}

@media (min-width: 992px) {
  .basicPie1 div canvas {
    top: -40px !important;
  }
}
</style>

<style scoped>
.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
