<template>
  <div class="tab-pane fade" id="list-settings11" role="tabpanel" aria-labelledby="list-settings-list11">
    <h4>
      <div class="row">
        <div class="col-lg-6" style="align-self: center;">
          User Roles
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-4">
              <v-row>
                <v-col cols="12" class="remove-hint">
                  <v-select 
                  v-model="filters.department" 
                  item-text="department_name" 
                  item-value="id" 
                  :items="[
                    {
                      department_name: 'All departments', 
                      id: ''
                    }, 
                    ...authCompanyDepartments
                  ]" 
                  label="Selected Departments" 
                  solo 
                  dense
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <div class="col-lg-4">
              <v-row>
                <v-col cols="12" class="remove-hint">
                  <v-select 
                  v-model="filters.committee" 
                  item-text="committee_name" 
                  item-value="id" 
                  :items="[
                    {
                      committee_name: 'All committees', 
                      id: ''
                    }, 
                    ...authCompanyCommittees
                  ]" 
                  label="Selected Committees" 
                  solo 
                  dense
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <div class="col-lg-4">
              <v-row>
                <v-col cols="12" class="remove-hint">
                  <v-text-field
                    v-model="filters.name"
                    label="Search..."
                    solo
                    dense
                    style="cursor:pointer;"
                    hide-details="true"
                    placeholder="Search..."
                    class="px-2 pe-auto"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </h4>
    <div class="main-content mt-0 px-0">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="text-left">
                  Employee Name
                </th>
                <th scope="col" class="text-left">Role</th>
                <th scope="col" class="text-left" style="width: 110px;">
                  Update Role
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="employee in filteredEmployees" :key="employee.id">
                <td style="vertical-align: middle;" class="text-left">
                  {{ employee.employee_fristname }}
                  {{ employee.employee_lastname }}
                </td>
                <td style="vertical-align: middle;" class="text-left">
                  {{ getRoleName(employee.user_type) }}
                </td>
                <td class="employee-options text-center">
                  <i @click="
                employee.user_type != 1
                  ? launch_update_role(employee)
                  : null
                " class="col-3 py-4 text-center fal fa-edit" :style="employee.user_type != 1
                ? 'cursor: pointer;'
                : 'opacity: 0.5;'
                " style="color: #069 !important; font-size: 125%;"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <v-dialog v-model="update_role_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Update User Role</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="remove-hint">
                    <v-select v-model="change_role_to" item-color="#069" :items="role_names" placeholder="New Role" solo
                      dense color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" offset-md="3" offset-sm="3" sm="6" md="6" class="remove-hint">
                    <button @click="update_role_submit ? null : update_role()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ update_role_text }}
                      <v-icon :style="!update_role_submit
                ? 'display: none;'
                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="update_role_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {},
  data() {
    return {
      filters: {
        name: '',
        department: '',
        committee: '',
        branch: '',
      },
      update_role_dialog: false,
      change_user_role_id: null,
      change_role_to: null,
      role_employee_id: null,
      update_role_text: "Update",
      update_role_submit: false,
    };
  },
  computed: {
    ...mapGetters([
      "authCompanyData",
      "authCompanyRoles",
      "authCompanyDepartments",
      "authCompanyCommittees",
      "authEmployeeCommittees",
      "authAllRoles"
    ]),
    departmentId() {
      return this.filters.department;
    },
    filteredEmployees() {
      const _this = this; 
      let employees = [..._this.mixinBranchEmployees(_this.$route.name)];
      if (_this.filters.department) {
        employees = employees.filter(e => e.department_id == _this.filters.department);
      }
      if (_this.filters.committee) {
        _this.authEmployeeCommittees.forEach(ec => {
          if (ec.committee_id == _this.filters.committee) {
            employees = employees.filter(e => e.id == ec.employee_id);
          }
        });
      }
      if (_this.filters.name) {
        employees = employees.filter(e => e.employee_fristname.toLowerCase().includes(_this.filters.name.toLowerCase()) || e.employee_lastname.toLowerCase().includes(_this.filters.name.toLowerCase()));
      }
      return employees;
    },
    filteredCommittees() {
      const _this = this;
      return _this.authCompanyDepartments.filter(d => d.id == _this.filters.department)
    },
    role_names() {
      let _this = this;
      let result = [];
      _this.authAllRoles.forEach((role) => {
        if (role.id != 1) {
          result.push(role.name);
        }
      });
      _this.authCompanyRoles.forEach((role) => {
        if (role.id != 1) {
          result.push(role.name);
        }
      });
      return result;
    },
    get_role_id() {
      let _this = this;
      if (_this.change_role_to) {
        return _this.authAllRoles
          .concat(_this.authCompanyRoles)
          .filter((role) => {
            return _this.change_role_to == role.name;
          })[0].id;
      } else {
        return "";
      }
    }
  },
  watch: {
    departmentId() {
      this.filters.committee = '';
    },
  },
  methods: {
    ...mapActions(["fetch_active_company_employees"]),
    launch_update_role(employee) {
      this.update_role_dialog = true;
      this.role_employee_id = employee.id;
      this.change_user_role_id = employee.user_id;
      this.change_role_to = this.getRoleName(employee.user_type);
    },
    getRoleName(role) {
      let companyCustomRoles = this.authCompanyRoles;

      let person_role = this.authAllRoles
        .concat(companyCustomRoles)
        .filter((item) => {
          return item.id == role;
        })[0]
        ? this.authAllRoles.concat(companyCustomRoles).filter((item) => {
          return item.id == role;
        })[0].name
        : "No Role";

      return person_role;
    },
    update_role() {
      let _this = this;
      _this.update_role_text = "";
      _this.update_role_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updateuserrole`, {
          employee_id: _this.role_employee_id,
          user_id: _this.change_user_role_id,
          role_id: _this.get_role_id
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "The user role has been updated successfully"
          });
          _this.change_user_role_id = null;
          _this.change_role_to = "";
          _this.fetch_active_company_employees();
          _this.update_role_dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.user_id
                  ? errors.user_id[0]
                  : null || errors.role_id
                    ? errors.role_id[0]
                    : null
              : null ||
              error.response.data.error ||
              error.response.data.message ||
              "The user's role couldn't be updated"
          });
        })
        .then(function () {
          _this.update_role_submit = false;
          _this.update_role_text = "Update";
        });
    }
  },
  mounted() {
    const _this = this;
    _this.fetch_active_company_employees();
  }
};
</script>

<style></style>
