<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-2 col-lg-2 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="$router.push({ name: 'Onboarding' })"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Add Candidate</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div class="dropdown animate__animated animate__fadeIn">
            <i @click="$router.push({ name: 'Onboarding' })"
              style="cursor: pointer; font-size: 120%; position: relative; top: 2px;" class="i-Close mr-1"></i>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>
            <span>Add Candidate</span>
          </h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">First Name <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field v-model="formData.firstname" placeholder="Enter first name" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Last Name <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field v-model="formData.lastname" placeholder="Enter last name" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Date of Birth</span>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="formData.dob"
                      placeholder="Enter date of birth" solo class="mt-2" readonly v-bind="attrs" v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" v-model="formData.dob" :max="
                    new Date(new Date().getTime() + 86400000)
                      .toISOString()
                      .substr(0, 10)
                  " @change="save"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Gender</span>
                <v-select v-model="formData.sex" item-color="#069" :items="sex" placeholder="Select gender" solo
                  class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">E-mail Address <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field v-model="formData.email" placeholder="Enter email" solo class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Phone Number</span>
                <vue-tel-input-vuetify style="box-shadow: 0px 0px 50px 0px #00000014 !important;"
                  v-model="formData.phone" filled light shaped flat background-color="white" class="mt-2">
                </vue-tel-input-vuetify>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6" class="remove-hint">
                <v-text-field
                  v-model="formData.officialEmail"
                  placeholder="Official Email"
                  solo
                  class="mt-2"
                ></v-text-field>
              </v-col> -->
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Address Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Address</span>
                <v-text-field v-model="formData.address" placeholder="Enter address" solo class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Country</span>
                <v-select v-model="formData.country" item-color="#069" :items="all_countries"
                  placeholder="Select country" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">State</span>
                <v-select v-model="formData.state" item-color="#069" :items="all_states" :disabled="!formData.country"
                  placeholder="Select state" solo class="mt-2" color="#069" @change="get_cities()"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">City</span>
                <v-select v-model="formData.city" item-color="#069" :items="cities" :disabled="!formData.state"
                  placeholder="Select LGA/city" solo class="mt-2" color="#069"></v-select>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Professional Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Years of Experience</span>
                <v-text-field v-model="formData.experience" placeholder="Enter years of experience" solo class="mt-2"
                  type="number"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Current Salary</span>
                <v-text-field
                  v-model="formData.salary"
                  placeholder="Enter current salary"
                  solo
                  class="mt-2"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Source of Hire</span>
                <v-select v-model="formData.sourceOfHire" item-color="#069" :items="sourceOfHire"
                  placeholder="Select source of hire" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Highest Qualification</span>
                <v-select v-model="formData.highestQualification" item-color="#069"
                  :items="['Ph.D', 'M.Sc', 'B.Sc', 'HND', 'OND', 'O-Level', 'Forst School Leaving Certificate']"
                  placeholder="Enter highest qualification" solo class="mt-2" color="#069"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Skill Set</span>
                <v-textarea v-model="formData.skillSet" placeholder="Enter skill set" solo class="mt-2"></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Additional Information</span>
                <v-textarea v-model="formData.additionalInformation" placeholder="Enter additional information" solo
                  class="mt-2"></v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Personal Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Father's Name</span>
                <v-text-field v-model="formData.fathers_name" placeholder="Enter father's name" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Mother's Name</span>
                <v-text-field v-model="formData.mothers_name" placeholder="Enter mother's name" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Marital Status</span>
                <v-select v-model="formData.marital_status" item-color="#069"
                  :items="['Single', 'Married', 'Separated', 'Divorced']" placeholder="Select marital status" solo
                  class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Spouse's Name</span>
                <v-text-field v-model="formData.spouses_name" placeholder="Enter spouse's name" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Blood Group</span>
                <v-select v-model="formData.blood_group" item-color="#069"
                  :items="['A', 'B', 'O', 'AB', 'A+', 'B+', 'AB+', 'O+', 'A-', 'B-', 'AB-', 'O-']"
                  placeholder="Select blood group" solo class="mt-2" color="#069"></v-select>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Guarantor Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor First Name</span>
                <v-text-field v-model="formData.guarantor_frist_Name" placeholder="Enter guarantor's first name" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Last Name</span>
                <v-text-field v-model="formData.guarantor_last_Name" placeholder="Enter guarantor's last name" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor E-mail Address</span>
                <v-text-field v-model="formData.guarantor_email" placeholder="Enter guarantor's e-mail address" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Address</span>
                <v-text-field v-model="formData.guarantor_address" placeholder="Enter guarantor's address" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Phone Number</span>
                <v-text-field v-model="formData.guarantor_phone_number" placeholder="Enter guarantor's phone number"
                  solo class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Candidate-Guarantor Relationship</span>
                <v-text-field v-model="formData.employee_grelationship"
                  placeholder="Enter guarantor's relationship with the candidate" solo class="mt-2"></v-text-field>
              </v-col>

            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Payment Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Basic Salary</span>
                <v-text-field v-model="formData.basic_salary" placeholder="Enter basic salary" solo class="mt-2"
                  type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Account Number</span>
                <v-text-field v-model="formData.account_number" placeholder="Enter account number" solo class="mt-2"
                  type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Bank Name</span>
                <v-select v-model="formData.bank_name" item-color="#069" :items="banks" placeholder="Enter bank name"
                  solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Payment Method</span>
                <v-text-field v-model="formData.payment_method" placeholder="Select payment method" solo class="mt-2">
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb row" style="margin-top: 1rem; margin-left: 1rem;">
          <h1 class="col-11 px-0 py-0">Education</h1>
          <i @click="addEducation()" class="i-Add col-1 py-0" style="
    font-size: 120%;
    font-weight: bold;
    text-align: right;
    padding-right: 2.5%;
    cursor: pointer;
"></i>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="text-left" scope="col">School Name</th>
                <th class="text-left" scope="col">Degree/Diploma</th>
                <th class="text-left" scope="col">Field(s) of Study</th>
                <th class="text-left" scope="col">Date of Completion</th>
                <th class="text-left" scope="col">Additional Notes</th>
                <th class="text-left" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(education, index) in formData.education" :key="index" @mouseenter="showOptions(index, false)"
                @mouseleave="showOptions(index, true)">
                <td>
                  <v-text-field v-model="education.school_name" solo class="educationField"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="education.degree_diploma" solo class="educationField"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="education.field_of_study" solo class="educationField"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="education.date_of_completion" solo class="educationField"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="education.additional_notes" solo class="educationField"></v-text-field>
                </td>
                <td style="padding-top: 2.5%; padding-right: 1.5%;">
                  <i :style="education.viewOption ? '' : 'opacity: 0;'" @click="removeEducation(index)" class="i-Remove"
                    style="
                                    font-size: 120%;
                                    font-weight: bold;
                                    text-align: right;
                                    padding-right: 2.5%;
                                    cursor: pointer;
                                "></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="breadcrumb row" style="margin-top: 1rem; margin-left: 1rem;">
          <h1 class="col-11 px-0 py-0">Experience</h1>
          <i @click="addExperience()" class="i-Add col-1 py-0" style="
    font-size: 120%;
    font-weight: bold;
    text-align: right;
    padding-right: 2.5%;
    cursor: pointer;
"></i>
        </div>
        <div class="table-responsive pb-4">
          <table class="table">
            <thead>
              <tr>
                <th class="text-left" scope="col">Occupation</th>
                <th class="text-left" scope="col">Company</th>
                <th class="text-left" scope="col">Summary</th>
                <th class="text-left" scope="col">Duration</th>
                <th class="text-left" scope="col">Currently Work Here</th>
                <th class="text-left" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(experience, index) in formData.experienceList" :key="index"
                @mouseenter="showOptions2(index, false)" @mouseleave="showOptions2(index, true)">
                <td>
                  <v-text-field v-model="experience.occupation" solo class="educationField"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="experience.company" solo class="educationField"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="experience.summary" solo class="educationField"></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="experience.duration" solo class="educationField"></v-text-field>
                </td>
                <td>
                  <v-select v-model="experience.currently_work_here" item-color="#069" :items="work_here"
                    placeholder="Select" solo color="#069" class="educationField"></v-select>
                </td>
                <td style="padding-top: 2.5%; padding-right: 1.5%;">
                  <i :style="experience.viewOption ? '' : 'opacity: 0;'" @click="removeExperience(index)"
                    class="i-Remove" style="
                                    font-size: 120%;
                                    font-weight: bold;
                                    text-align: right;
                                    padding-right: 2.5%;
                                    cursor: pointer;
                                "></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <div class="main-header pl-5" style="position: fixed; bottom: 0px; z-index: 60;">
        <div class="row">
          <div class="logo col-md-4 py-0">
            <div class="dropdown">
              <button @click="add_candidate_submit ? null : addCandidate()" type="button"
                class="btn btn-secondary m-1 ripple">
                {{ add_candidate_text }}
                <v-icon :style="
                  !add_candidate_submit
                    ? 'display: none;'
                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                ">
                  fal fa-circle-notch fa-spin
                </v-icon>
              </button>
              <!-- <button type="button" class="btn btn-outline-secondary m-1 ripple"> Submit and New </button> -->
              <button type="button" class="btn btn-outline-secondary m-1 ripple"
                @click="$router.push({ name: 'Onboarding' })">
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
export default {
  data() {
    return {
      add_candidate_text: 'Submit',
      add_candidate_submit: false,
      menu: false,
      formData: {
        email: "",
        phone: "",
        firstname: "",
        lastname: "",
        dob: "",
        sex: "",
        officialEmail: "",
        address: "",
        state: "",
        city: "",
        country: "",
        experience: "",
        salary: "",
        sourceOfHire: "",
        skillSet: "",
        highestQualification: "",
        additionalInformation: "",

        fathers_name: "",
        mothers_name: "",
        marital_status: "",
        spouses_name: "",
        blood_group: "",
        guarantor_frist_Name: "",
        guarantor_last_Name: "",
        guarantor_email: "",
        guarantor_address: "",
        guarantor_phone_number: "",
        employee_grelationship: "",
        basic_salary: "",
        account_number: "",
        bank_name: "",
        payment_method: "",

        education: [
          {
            school_name: "",
            degree_diploma: "",
            field_of_study: "",
            date_of_completion: "",
            additional_notes: "",
            viewOption: false
          }
        ],
        experienceList: [
          {
            occupation: "",
            company: "",
            summary: "",
            duration: "",
            currently_work_here: "",
            viewOption: false
          }
        ]
      },
      sourceOfHire: [
        "Direct",
        "Newspaper",
        "Advertisement",
        "Employee Referral",
        "External Referral"
      ],
      work_here: ["Yes", "No"],
      sex: ["Male", "Female"],
      cities: []
    };
  },
  watch: {
    authCompanyCandidates() {
      let _this = this;
      _this.$router.push({ name: "Onboarding" });
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authCompanyCandidates",
      "auth_countries",
      "auth_states",
      "banks"
    ]),
    all_countries() {
      let _this = this;
      if (_this.auth_countries) {
        return _this.auth_countries.map(country => {
          return country.countryName
        });
      }
      return null;
    },
    all_states() {
      let _this = this;
      if (_this.formData.country && _this.all_countries) {
        let country_obj = _this.auth_countries.filter(country => {
          return country.countryName == _this.formData.country
        })[0];
        let states = _this.auth_states.filter(state => {
          return state.countryID == country_obj.countryID
        });
        return states.map(state => {
          return state.stateName
        });
      }
      return [];
    },
    educationForSubmission() {
      let _this = this;
      return this.formData.education.map(data => {
        return {
          company_id: _this.authCompanyData.id,
          school_name: data.school_name,
          degree_diploma: data.degree_diploma,
          date_of_completion: data.date_of_completion,
          field_of_study: data.field_of_study,
          additional_notes: data.additional_notes
        };
      });
    },
    experienceForSubmission() {
      let _this = this;
      return this.formData.experienceList.map(data => {
        return {
          company_id: _this.authCompanyData.id,
          occupation: data.occupation,
          company: data.company,
          summary: data.summary,
          duration: data.duration,
          currently_work_here: data.currently_work_here
        };
      });
    }
  },
  methods: {
    ...mapActions(["fetch_candidates"]),
    save(date) {
      this.$refs.menu.save(date);
    },
    get_cities() {
      let _this = this;
      let state_id = _this.auth_states.filter(state => {
        return state.stateName == _this.formData.state
      })[0].stateID;

      axios
        .post(`${BASE_URL}fetchcities`, {
          state_id
        })
        .then(response => {
          _this.cities = response.data.cities.map(city => {
            return city.cityName
          });
        })
        .catch(() => {
          //
        })
        .then(() => {
          // AFTER AXIOS CALL
        });
    },
    showOptions(index, truth) {
      if (truth) {
        this.formData.education.splice(index, 1, {
          school_name: this.formData.education[index].school_name,
          degree_diploma: this.formData.education[index].degree_diploma,
          field_of_study: this.formData.education[index].field_of_study,
          date_of_completion: this.formData.education[index].date_of_completion,
          additional_notes: this.formData.education[index].additional_notes,
          viewOption: false
        });
      } else {
        this.formData.education.splice(index, 1, {
          school_name: this.formData.education[index].school_name,
          degree_diploma: this.formData.education[index].degree_diploma,
          field_of_study: this.formData.education[index].field_of_study,
          date_of_completion: this.formData.education[index].date_of_completion,
          additional_notes: this.formData.education[index].additional_notes,
          viewOption: true
        });
      }
    },
    removeEducation(index) {
      if (this.formData.education.length >= 2) {
        this.formData.education.splice(index, 1);
      }
    },
    addEducation() {
      this.formData.education.push({
        school_name: "",
        degree_diploma: "",
        field_of_study: "",
        date_of_completion: "",
        additional_notes: "",
        viewOption: false
      });
    },
    showOptions2(index, truth) {
      if (truth) {
        this.formData.experienceList.splice(index, 1, {
          occupation: this.formData.experienceList[index].occupation,
          company: this.formData.experienceList[index].company,
          summary: this.formData.experienceList[index].summary,
          duration: this.formData.experienceList[index].duration,
          currently_work_here: this.formData.experienceList[index]
            .currently_work_here,
          viewOption: false
        });
      } else {
        this.formData.experienceList.splice(index, 1, {
          occupation: this.formData.experienceList[index].occupation,
          company: this.formData.experienceList[index].company,
          summary: this.formData.experienceList[index].summary,
          duration: this.formData.experienceList[index].duration,
          currently_work_here: this.formData.experienceList[index]
            .currently_work_here,
          viewOption: true
        });
      }
    },
    removeExperience(index) {
      if (this.formData.experienceList.length >= 2) {
        this.formData.experienceList.splice(index, 1);
      }
    },
    addExperience() {
      this.formData.experienceList.push({
        occupation: "",
        company: "",
        summary: "",
        duration: "",
        currently_work_here: "",
        viewOption: false
      });
    },
    addCandidate() {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.add_candidate_text = '';
      _this.add_candidate_submit = true;

      axios
        .post(`${BASE_URL}createcandidate`, {
          company_id: _this.authCompanyData.id,
          company_unique_id: _this.authCompanyData.company_unique_id,
          employee_fristname: _this.formData.firstname,
          employee_lastname: _this.formData.lastname,
          employee_date_of_birth: _this.formData.dob,
          employee_email: _this.formData.email,
          employee_phone1: _this.formData.phone,
          city: _this.formData.city,
          employee_state: _this.formData.state,
          employee_nationality: _this.formData.country,
          employee_address: _this.formData.address,
          experience: _this.formData.experience,
          current_salary: _this.formData.salary,
          source_of_hire: _this.formData.sourceOfHire,
          skill_set: _this.formData.skillSet,
          Highest_qualification: _this.formData.highestQualification,
          padditional_info: _this.formData.additionalInformation,
          employee_sex: _this.formData.sex,
          employee_education: _this.educationForSubmission,
          employee_workexperience: _this.experienceForSubmission,
          fathers_name: _this.formData.fathers_name,
          mothers_name: _this.formData.mothers_name,
          spouses_name: _this.formData.spouses_name,
          blood_group: _this.formData.blood_group,
          guarantor_frist_Name: _this.formData.guarantor_frist_Name,
          guarantor_last_Name: _this.formData.guarantor_last_Name,
          guarantor_email: _this.formData.guarantor_email,
          guarantor_address: _this.formData.guarantor_address,
          guarantor_phone_number: _this.formData.guarantor_phone_number,
          employee_grelationship: _this.formData.employee_grelationship,
          basic_salary: _this.formData.basic_salary,
          account_number: _this.formData.account_number,
          bank_name: _this.formData.bank_name,
          payment_method: _this.formData.payment_method,
          employee_maritalstatus: _this.formData.marital_status
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Candidate added"
          });
          _this.fetch_candidates();
        })
        .catch(function (error) {
          _this.add_candidate_submit = false;
          _this.add_candidate_text = 'Submit';
          if (window.navigator.onLine) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors ? (typeof errors === 'string' ? errors : null
                || errors.employee_fristname ? errors.employee_fristname[0] : null
                  || errors.employee_lastname ? errors.employee_lastname[0] : null
                    || errors.employee_email ? errors.employee_email[0] : null
                      || errors.employee_phone1 ? errors.employee_phone1[0] : null) : null
                      || error.response.data.error || error.response.data.message || 'There was an error in adding the candidate'
            });
            console.log(error.response);
          } else {
            Toast.fire({
              icon: "error",
              title: "Please check your internet connectivity"
            });
          }
        })
        .then(function () {
          _this.add_candidate_submit = false;
          _this.add_candidate_text = 'Submit';
          // context.commit('AUTH_FETCHED_EMPLOYEES', );
        });
    }
  },
  components: {
    VueTelInputVuetify
  },
  mounted() {
    document.title = "Kylian ERP - Add Candidate";
  }
};
</script>

<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}

.v-input__control .v-input__slot {
  margin-bottom: 0;
}

.theme--light.v-text-field:not(.v-input--has-state):hover>.v-input__control>.v-input__slot:before {
  border-color: rgb(0 0 0 / 0%) !important;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: rgb(0 0 0 / 0%) !important;
}
</style>
