<template>
    <div v-if="permissionIsAssigned('ip-address-read') && check_all_services('Attendance')" class="tab-pane fade"
        id="list-home" role="tabpanel" aria-labelledby="list-home-list">
        <h4 v-if="create_ip_form">
            <div class="row">
                <div class="col-lg-6 pt-0" style="align-self: center;">
                    IP Address Management
                </div>
                <div class="col-lg-6 pt-2">
                    <div class="row" style="justify-content: flex-end;">
                        <div class="col-lg-6 offset-lg-1 py-4">
                            <v-text-field v-model="ip_to_create" placeholder="Enter IP Address Here" solo dense
                                class="ip-address"></v-text-field>
                        </div>
                        <div class="col-lg-4 py-4">
                            <button style="margin-top: 1px;" type="button" @click="createLoading ? null : create_ip()"
                                class="btn btn-md btn-secondary text-white btn-raised ripple">
                                {{ createText }}
                                <v-icon
                                    :style="!createLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                    fal fa-circle-notch fa-spin
                                </v-icon>
                            </button>
                            <button @click="create_ip_form = false" style="margin-top: 1px; float: right;" type="button"
                                class="btn btn-md btn-danger text-white btn-raised ripple">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </h4>
        <h4 v-else>
            <div class="row">
                <div class="col-lg-6" style="align-self: center;">
                    IP Address Management
                </div>
                <div v-if="permissionIsAssigned('ip-address-create')" class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3 offset-9 py-4">
                            <button @click="create_ip_form = true" style="float: right;" type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                Create IP
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </h4>

        <div class="row">
            <!-- ICON BG-->
            <template v-if="ips.length >= 1">
                <div v-for="(ip, index) in ips" :key="ip.id" class="col-lg-4 col-md-6 col-sm-6">
                    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4" :style="getStyleByPattern(index)">
                        <div class="row card-body text-center py-2">
                            <i class="i-Wifi-2 col-lg-4" style="color: #f4433685;"></i>
                            <div class="col-lg-8">
                                <p class="text-muted mt-2 mb-0">Active</p>
                                <p class="lead text-primary text-24 mb-2">
                                    {{ ip.ip }}
                                </p>
                            </div>
                        </div>
                        <div class="card-footer py-1 text-right row"
                            v-if="ip.edit_mode && permissionIsAssigned('ip-address-update')">
                            <v-text-field v-model="ip.ip" placeholder="IP Address" solo dense
                                class="ip-address col-lg-7"></v-text-field>
                            <div class="col-lg-5 row py-4">
                                <div class="col-lg-6 py-4">
                                    <button @click="ip.saveLoading ? null : update_ip(ip)" type="button"
                                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                        {{ ip.saveText }}
                                        <v-icon
                                            :style="!ip.saveLoading ? 'display: none;' : 'color: #fff; padding: 0px 4px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </div>
                                <div class="col-lg-6 py-4">
                                    <button @click="ip.edit_mode = false" type="button"
                                        class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer py-1 text-right" v-else>
                            <button v-if="permissionIsAssigned('ip-address-update')" type="button"
                                @click="ip.edit_mode = true"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                Edit
                            </button>
                            <button v-if="permissionIsAssigned('ip-address-delete')"
                                @click="ip.deleteLoading ? null : delete_ip(ip)" type="button"
                                class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                {{ ip.deleteText }}
                                <v-icon
                                    :style="!ip.deleteLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                    fal fa-circle-notch fa-spin
                                </v-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </template>
            <div v-else class="col-lg-5 col-md-5 col-sm-5">
                <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                    <div class="row card-body text-center py-2">
                        <i class="i-Wifi-2 col-lg-4" style="color: #f4433685;"></i>
                        <div class="col-lg-8">
                            <p v-if="permissionIsAssigned('ip-address-create')" class="text-muted mt-2 mb-0">
                                Click the "Create IP" button to add an IP
                            </p>
                            <p class="lead text-primary text-24 mb-2 mt-3">
                                No IP added
                            </p>
                        </div>
                    </div>
                    <div class="card-footer py-1 text-right">
                        <button v-if="permissionIsAssigned('ip-address-update')" type="button"
                            class="d-none btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                            Edit
                        </button>
                        <button v-if="permissionIsAssigned('ip-address-delete')" type="button"
                            class="d-none btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters } from 'vuex';
export default {
    props: {
        ips: Array,
        fetch_ip_details: Function
    },
    data() {
        return {
            create_ip_form: false,
            ip_to_create: "",
            createLoading: false,
            createText: "Create",
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData'
        ])
    },
    methods: {
        create_ip() {
            let _this = this;
            _this.createText = "";
            _this.createLoading = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addip`, {
                    company_id: _this.authCompanyData.id,
                    ip_address: _this.ip_to_create,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "IP creation successful",
                    });
                    _this.ip_to_create = "";
                    _this.fetch_ip_details();
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors ? (typeof errors === "string" ? errors : null || errors.ip_address ? errors.ip_address[0] : null) : null || error.response.data.error || error.response.data.message || "IP creation failed",
                    });
                    _this.fetch_ip_details();
                })
                .then(function () {
                    _this.createLoading = false;
                    _this.createText = "Create";
                });
        },
        update_ip(ip) {
            let _this = this;
            ip.saveText = "";
            ip.saveLoading = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}editip  `, {
                    id: ip.id,
                    ip_address: ip.ip,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "IP Address update successful",
                    });
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors ? (typeof errors === "string" ? errors : null || errors.ip_address ? errors.ip_address[0] : null) : null || error.response.data.error || error.response.data.message || "IP Address update failed",
                    });
                })
                .then(function () {
                    ip.saveLoading = false;
                    ip.saveText = "Save";
                    _this.fetch_ip_details();
                });
        },
        delete_ip(ip) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete the IP address details of " + ip.ip + "?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        ip.deleteText = "";
                        ip.deleteLoading = true;

                        axios
                            .post(`${BASE_URL}deleteip `, {
                                id: ip.id,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "IP address details deleted successfully",
                                });
                                _this.fetch_ip_details();
                                ip.deleteLoading = false;
                                ip.deleteText = "Delete";
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Could not delete IP address details",
                                });
                                ip.deleteLoading = false;
                                ip.deleteText = "Delete";
                                _this.fetch_ip_details();
                                console.log(error);
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
    }
}
</script>

<style></style>