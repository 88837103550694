<template>
    <div class="card-body row py-4">
        <div class="col-sm-4" style="align-self: center;">
            <h4>Manage Appraisals</h4>
        </div>
        <div class="col-sm-8">
            <v-row>
                <v-col md="7">
                </v-col>
                <v-col :md="usertype == 1 || permissionIsAssigned('appraisal-create') ? 3 : 5">
                    <v-select
                        v-model="filters.appraisalName"
                        :items="availableAppraisalNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select appraisal"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col v-if="usertype == 1 || permissionIsAssigned('appraisal-create')" md="2">
                    <button
                    @click="add_appraisal_dialog = true"
                    style="float: right;"
                    type="button"
                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                    :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                    >
                        Add Appraisal
                    </button>
                </v-col>
            </v-row>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Appraisal Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Duration
                                    </th>
                                    <th class="text-left" scope="col">
                                        Multi-rater
                                    </th>
                                    <th class="text-left" scope="col">
                                        Questionnaire URL
                                    </th>
                                    <th class="text-center" scope="col">
                                        Questionnaires
                                    </th>
                                    <th class="text-center" scope="col">
                                        Assignees
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                    <th class="text-left" scope="col">Guided Document</th>
                                    <th class="text-center" scope="col">Add Guided Document</th>
                                    <th class="text-center" scope="col">Start/Stop</th>
                                    <th class="text-left" scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="appraisal in authCompanyAppraisals" :key="appraisal.id">
                                    <td class="text-left">{{ appraisal.appraisal_name }}</td>
                                    <td class="text-left">
                                        {{ 
                                            new Date(appraisal.start_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                            + ' - ' + 
                                            new Date(appraisal.end_date)
                                            .toUTCString()
                                            .substring(0, 16)  
                                        }}
                                    </td>
                                    <td class="text-left">{{ appraisal.multi_rater == 1 ? 'Yes' : 'No' }}</td>
                                    <td v-if="appraisal.appraisal_url" class="text-left">{{ appraisal.appraisal_url }}</td>
                                    <td v-else class="text-left">
                                        <i>No URL added</i>
                                    </td>

                                    <td v-if="userType == 1 || permissionIsAssigned('questionnaire-read')" class="text-center">
                                        <button v-if="appraisal.appraisal_url === null" @click="(current_appraisal_id = appraisal.id, manage_questionnaires_dialog = true)" type="button" class="btn btn-secondary btn-sm ripple">
                                            Manage
                                        </button>
                                        <i v-else>
                                            Unavailable
                                        </i>
                                    </td>
                                    <td v-else class="text-center">
                                        <i>Permission denied</i>
                                    </td>
                                    <td class="text-center">
                                        <button @click="(current_appraisal_id = appraisal.id, view_appraisal_assignees_dialog = true)" type="button" class="btn btn-secondary btn-sm ripple">
                                            Manage
                                        </button>
                                    </td>

                                    <td class="text-center" v-if="appraisal.status == 0">
                                        <span class="badge badge-warning text-white">READY TO START</span>
                                    </td>
                                    <td class="text-center" v-else>
                                        <span class="badge badge-success text-white">STARTED</span>
                                    </td>

                                    <td class="text-left" v-if="appraisal.guided_document_url">
                                        <a :href="assets_url + appraisal.guided_document_url" target="_blank" rel="noopener noreferrer">
                                            View Guided Document
                                        </a>
                                    </td>
                                    <td class="text-left" v-else><i>No guided document</i></td>

                                    <td class="text-center">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <i
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="(current_appraisal_id = appraisal.id, file_attachment_dialog = true)"
                                                    class="py-0 text-center fal fa-file-plus"
                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                                ></i>
                                            </template>
                                            <span>Add Guided Document</span>
                                        </v-tooltip>
                                    </td>
                                    <td v-if="userType == 1 || permissionIsAssigned('appraisal-start')" class="text-center">
                                        <button v-if="appraisal.status == 0" @click="start_appraisal(appraisal.id, true)" type="button" class="btn btn-secondary btn-sm ripple">
                                            Start Appraisal
                                        </button>
                                        <button v-else @click="start_appraisal(appraisal.id, false)" type="button" class="btn btn-danger text-white btn-sm ripple">
                                            Stop Appraisal
                                        </button>
                                    </td>
                                    <td v-else class="text-center">
                                        <i>Permission denied</i>
                                    </td>

                                    <td style="padding-top: 13px;" class=" px-2 employee-options text-left">
                                        <div class="row ml-0">
                                            <i
                                                @click="preview_appraisal(appraisal)"
                                                class="py-4 px-0 text-center fal fa-eye col-4"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                            ></i>
                                            <i
                                                v-if="usertype == 1 || permissionIsAssigned('appraisal-update')"
                                                @click="launch_edit_appraisal(appraisal)"
                                                class="py-4 px-0 text-center fal fa-edit col-4"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                            ></i>
                                            <i
                                                v-if="usertype == 1 || permissionIsAssigned('appraisal-delete')"
                                                @click="delete_appraisal(appraisal)"
                                                class="py-4 px-0 text-center fal fa-trash col-4"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                            ></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <!-- Dialog for managing questionnaires -->
        <v-dialog v-model="manage_questionnaires_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Manage Questionnaires</span>
                    <span v-if="userType == 1 || permissionIsAssigned('questionnaire-create')" class="headline float-right">
                        <button @click="add_questionnaire_dialog = true" style="float: right;" type="button"
                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                            Add Questionnaire
                        </button>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="card-body row py-4">
                            <div class="col-sm-12 py-0">
                                <div class="main-content mt-0 px-0 py-0">
                                    <div>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" scope="col">
                                                            Appraisal
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Question
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="q in filteredQuestionnaires" :key="q.id">
                                                        <td class="text-left">{{ getAppraisalName(q.appraisal_id) }}</td>
                                                        <td class="text-left">{{ q.question }}</td>
                                                        <td style="padding-top: 13px;" class="employee-options text-left">
                                                            <div v-if="
                                                                userType == 1 
                                                                || 
                                                                permissionIsAssigned('questionnaire-update')
                                                                ||
                                                                permissionIsAssigned('questionnaire-delete')
                                                            " class="row ml-0">
                                                                <i v-if="userType == 1 || permissionIsAssigned('questionnaire-update')" @click="launch_edit_questionnaire(q)" class="py-4 text-center fal fa-edit mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                                <i v-if="userType == 1 || permissionIsAssigned('questionnaire-delete')" @click="delete_questionnaire(q)" class="py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            </div>
                                                            <i v-else>
                                                                Permission denied
                                                            </i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="manage_questionnaires_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for managing questionnaires -->

        <!-- Dialog for adding questionnaires -->
        <v-dialog v-model="add_questionnaire_dialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add Questionnaire</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Question <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="add_questionnaire_form.question" placeholder="Enter the question" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="add_questionnaire_button_loading ? null : add_questionnaire()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ add_questionnaire_button_text }}
                                            <v-icon
                                                :style="
                                                !add_questionnaire_button_loading
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_questionnaire_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding questionnaires -->

        <!-- Dialog for editing questionnaires -->
        <v-dialog v-model="edit_questionnaire_dialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Edit Questionnaire</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Questionnaire <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="edit_questionnaire_form.question" placeholder="Enter the question" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="edit_questionnaire_button_loading ? null : update_questionnaire()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ edit_questionnaire_button_text }}
                                            <v-icon
                                                :style="
                                                !edit_questionnaire_button_loading
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="edit_questionnaire_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for editing questionnaires -->

        <!-- Dialog for viewing appraisal assignees -->
        <v-dialog v-model="view_appraisal_assignees_dialog" max-width="900px">
            <v-card>
                <v-card-title class="text-left d-block">
                    <span class="headline">Appraisal Assignees</span>
                    <button v-if="userType == 1 || permissionIsAssigned('add-employee-to-appraisal')" @click="(add_appraisal_assignees_dialog = true)" style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                        Add Assignee
                    </button>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-left">Assignee</th>
                                    <th class="text-left">Remove Assignee</th>
                                    <th class="text-left">Reviewers</th>
                                    <th v-if="/* Should be based on permissions */userType == 1 && (currentAppraisal ? currentAppraisal.multi_rater == 1 : false)" class="text-left">Add Reviewer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(employee, index) in employeesInAppraisal(current_appraisal_id)" :key="'employee' + index">
                                    <td class="text-left">
                                        {{ get_employee_name(employee.id) }}
                                    </td>
                                    <td v-if="userType == 1 || permissionIsAssigned('remove-employee-from-appraisal')" class="text-left">
                                        <div class="row">
                                            <i
                                                @click="remove_appraisal_assignee(current_appraisal_id, employee.id)"
                                                class="col-sm-12 py-4 text-left fas fa-user-minus ml-2"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                            ></i>
                                        </div>
                                    </td>
                                    <td v-else class="text-left">
                                        <i>Permission denied</i>
                                    </td>
                                    <td class="text-left">
                                        <ul v-if="getEmployeeReviewers(current_appraisal_id, employee.id).length >= 1">
                                            <li v-for="reviewer in getEmployeeReviewers(current_appraisal_id, employee.id)" :key="reviewer.id">
                                                <v-row v-if="reviewer.id">
                                                    <v-col cols="6">
                                                        {{ reviewer.employee_fristname + ' ' + reviewer.employee_lastname }}
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <i
                                                            @click="remove_appraisal_reviewer(reviewer.id)"
                                                            class="col-sm-12 py-1 text-left fas fa-user-minus ml-0 px-0"
                                                            style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                                        ></i>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-else>
                                                    <v-col cols="12">
                                                        {{ reviewer.employee_fristname + ' ' + reviewer.employee_lastname }}
                                                    </v-col>
                                                </v-row>
                                            </li>
                                        </ul>
                                        <div v-else>
                                            <i>No reporting manager or reviewers</i>
                                        </div>
                                    </td>
                                    <td v-if="/* Should be based on permissions */userType == 1 && (currentAppraisal ? currentAppraisal.multi_rater == 1 : false)" class="text-left">
                                        <div class="row">
                                            <i
                                                @click="(current_employee_id = employee.id, add_appraisal_reviewers_dialog = true)"
                                                class="col-sm-12 py-4 text-left fas fa-user-plus ml-2"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                            ></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="view_appraisal_assignees_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for viewing appraisal assignees -->

        <!-- Dialog for adding appraisal assignees -->
        <v-dialog v-model="add_appraisal_assignees_dialog" max-width="600px">
            <v-card>
                <v-card-title class="text-left d-block">
                    <span class="headline">Assign employees to appraisal</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingAppraisalFilter.departmentName"
                                            :items="availableDepartmentsNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select department"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingAppraisalFilter.designationName"
                                            :items="availableDesignationNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select designation"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingAppraisalFilter.committeeName"
                                            :items="availableCommitteeNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select committee"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="selectedEmployeesForAppraisal"
                                            :items="filteredAvailableEmployeeNamesForAppraisal"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            multiple
                                            solo
                                            dense
                                            hint="Select Employees"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" offset-md="3" offset-sm="3" sm="6" md="6" >
                                        <button @click="add_employees_to_appraisal_submit ? null : add_employees_to_appraisal()" style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                            {{ add_employees_to_appraisal_text }}
                                            <v-icon
                                                :style="
                                            !add_employees_to_appraisal_submit
                                                ? 'display: none;'
                                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                        "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_appraisal_assignees_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding appraisal assignees -->

        <!-- Dialog for adding guided document -->
        <v-dialog v-model="file_attachment_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Guided Document</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col style="cursor: pointer;" cols="12" sm="12" md="12" >
                                        <v-file-input
                                            prepend-icon=""
                                            prepend-inner-icon="mdi-attachment mdi-rotate-270"
                                            label="Upload Guided Document"
                                            solo
                                            style="text-indent: 5px;"
                                            color="#069"
                                            class="file-attachment"
                                            messages="Guided Document"
                                        >
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button @click="file_attachment_submit ? null : upload_file_attachment()" style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ file_attachment_text }}
                                            <v-icon
                                                :style="
                                                !file_attachment_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fas fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="file_attachment_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding guided document -->

        <!-- Dialog for previewing appraisal -->
        <v-dialog v-model="preview_appraisal_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Appraisal Preview</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-left">Item</th>
                                    <th class="text-left">Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">
                                        Appraisal Name
                                    </td>
                                    <td class="text-left">{{ edit_appraisal_form.appraisal_name }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        Multi-Rater
                                    </td>
                                    <td class="text-left">{{ edit_appraisal_form.multi_rater == 1 ? 'Yes' : 'No' }}</td>
                                </tr>
                                <tr v-if="edit_appraisal_form.multi_rater == 1">
                                    <td class="text-left">
                                        Selected Reviewers
                                    </td>
                                    <td class="text-left">{{ edit_appraisal_form.reviewer_selected_by == 1 ? 'Employees/Reporting Manager' : 'Reporting Manager' }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        Start Date
                                    </td>
                                    <td class="text-left">{{ new Date(edit_appraisal_form.start_date).toUTCString().substring(0, 16) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        End Date
                                    </td>
                                    <td class="text-left">{{ new Date(edit_appraisal_form.end_date).toUTCString().substring(0, 16) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        Questionnaire URL
                                    </td>
                                    <td class="text-left">{{ edit_appraisal_form.appraisal_url }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        Goals
                                    </td>
                                    <td class="text-left">
                                        <ol>
                                            <li v-for="(goal, index) in editSelectedGoalsForAppraisal" :key="'goal-' + index">
                                                {{ goal }}
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        Questionnaires
                                    </td>
                                    <td class="text-left">
                                        <ol>
                                            <li v-for="(questionnaire, index) in getQuestionnaireByAppraisalId(edit_appraisal_form.id)" :key="'questionnaire-' + index">
                                                {{ questionnaire.question }}
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr v-if="edit_appraisal_form.multi_rater == 1">
                                    <td class="text-left">
                                        Reviewees
                                    </td>
                                    <td class="text-left">
                                        <ol>
                                            <li v-for="(employee, index) in employeesInAppraisal(edit_appraisal_form.id)" :key="'goal-' + index">
                                                {{ get_employee_name(employee.id) }}
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="preview_appraisal_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for previewing appraisal -->

        <!-- Dialog for adding appraisal -->
        <v-dialog v-model="add_appraisal_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add Appraisal</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Appraisal Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field class="mt-2" dense v-model="add_appraisal_form.appraisal_name" placeholder="Enter the appraisal name" solo></v-text-field>
                                    </v-col>
                                    <v-col v-if="add_appraisal_form.multi_rater" cols="12" sm="6" md="6" >
                                        <span style="color: #111d5e;">Selected Reviewers <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-radio-group row class="mt-2" v-model="add_appraisal_form.reviewer_selected_by">
                                            <v-radio
                                                label="Employees/Reporting Manager"
                                                :value="1"
                                            ></v-radio>
                                            <v-radio
                                                label="Reporting Manager"
                                                :value="2"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" >
                                        <span style="color: #111d5e;">Start Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="add_appraisal_form.start_date"
                                                    placeholder="Select appraisal start date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker1" v-model="add_appraisal_form.start_date" @change="save1"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" >
                                        <span style="color: #111d5e;">End Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="add_appraisal_form.end_date"
                                                    placeholder="Select appraisal end date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker2" v-model="add_appraisal_form.end_date" @change="save2"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Questionnaire URL</span>
                                        <v-text-field class="mt-2" dense v-model="add_appraisal_form.appraisal_url" placeholder="Enter the questionnaire URL" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Assign Goals <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-select
                                            v-model="selectedGoalsForAppraisal"
                                            :items="availableGoalNamesForAppraisal"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            multiple
                                            class="mt-2"
                                            solo
                                            dense
                                            hint="Select Goals"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="add_appraisal_button_loading ? null : add_appraisal()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ add_appraisal_button_text }}
                                            <v-icon
                                                :style="
                                                !add_appraisal_button_loading
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_appraisal_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding appraisal -->
        
        <!-- Dialog for editing appraisal -->
        <v-dialog v-model="edit_appraisal_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Edit Appraisal</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Appraisal Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field class="mt-2" dense v-model="edit_appraisal_form.appraisal_name" placeholder="Enter the appraisal name" solo></v-text-field>
                                    </v-col>
                                    <v-col v-if="edit_appraisal_form.multi_rater" cols="12" sm="6" md="6" >
                                        <span style="color: #111d5e;">Selected Reviewers <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-radio-group row class="mt-2" v-model="edit_appraisal_form.reviewer_selected_by">
                                            <v-radio
                                                label="Employees/Reporting Manager"
                                                :value="1"
                                            ></v-radio>
                                            <v-radio
                                                label="Reporting Manager"
                                                :value="2"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" >
                                        <span style="color: #111d5e;">Start Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="edit_appraisal_form.start_date"
                                                    placeholder="Select appraisal start date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker3" v-model="edit_appraisal_form.start_date" @change="save3"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" >
                                        <span style="color: #111d5e;">End Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="edit_appraisal_form.end_date"
                                                    placeholder="Select appraisal end date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker4" v-model="edit_appraisal_form.end_date" @change="save4"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Questionnaire URL</span>
                                        <v-text-field class="mt-2" dense v-model="edit_appraisal_form.appraisal_url" placeholder="Enter the questionnaire URL" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Assign Goals <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-select
                                            v-model="editSelectedGoalsForAppraisal"
                                            :items="availableGoalNamesForAppraisal"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            multiple
                                            class="mt-2"
                                            solo
                                            dense
                                            hint="Select Goals"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="edit_appraisal_button_loading ? null : update_appraisal()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ edit_appraisal_button_text }}
                                            <v-icon
                                                :style="
                                                !edit_appraisal_button_loading
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="edit_appraisal_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for editing appraisal -->

        <!-- Dialog for adding employee appraisal reviewers -->
        <v-dialog v-model="add_appraisal_reviewers_dialog" max-width="600px">
            <v-card>
                <v-card-title class="text-left d-block">
                    <span class="headline">Assign reviewers to appraisal</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingReviewerFilter.departmentName"
                                            :items="availableDepartmentsNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select department"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingReviewerFilter.designationName"
                                            :items="availableDesignationNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select designation"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols='12' sm='6'>
                                        <v-select
                                            v-model="addingReviewerFilter.committeeName"
                                            :items="availableCommitteeNames"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            solo
                                            dense
                                            hint="Select committee"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="selectedReviewersForAppraisal"
                                            :items="filteredAvailableReviewerNamesForAppraisal"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Select"
                                            multiple
                                            solo
                                            dense
                                            hint="Select Employees"
                                            persistent-hint
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" offset-md="3" offset-sm="3" sm="6" md="6" >
                                        <button @click="add_reviewers_to_appraisal_submit ? null : add_reviewers_to_appraisal()" style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                            {{ add_reviewers_to_appraisal_text }}
                                            <v-icon
                                                :style="
                                            !add_reviewers_to_appraisal_submit
                                                ? 'display: none;'
                                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                        "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_appraisal_reviewers_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding employee appraisal reviewers -->
    </div>
</template>

<script>
import { BASE_URL, ASSETS_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios';
export default {
    props: {
        get_employee_name: Function,
        getReportingManagerName: Function,
        singleEmployeeObject: Function,
        get_employee_id: Function,
        employeeObject: Function,
        designationObject: Function,
        goalIsInAppraisal: Function,
        getEmployeeByName: Function,
        get_dept_name: Function,
        getEmployeeCommitteeNames: Function,
        getEmployeeReviewers: Function,
        availableDepartmentsNames: Array,
        availableDesignationNames: Array,
        availableCommitteeNames: Array
    },
    data() {
        return {
            current_appraisal_id: null,
            current_employee_id: null,

            manage_questionnaires_dialog: false,

            add_questionnaire_dialog: false,
            add_questionnaire_button_text: "Submit",
            add_questionnaire_button_loading: false,
            add_questionnaire_form: {
                question: ''
            },

            edit_questionnaire_dialog: false,
            edit_questionnaire_button_text: "Update",
            edit_questionnaire_button_loading: false,
            edit_questionnaire_form: {
                id: '',
                question: ''
            },

            view_appraisal_assignees_dialog: false,
            preview_appraisal_dialog: false,
            add_appraisal_assignees_dialog: false,
            file_attachment_dialog: false,
            add_appraisal_reviewers_dialog: false,
            
            selectedEmployeesForAppraisal: [],
            selectedGoalsForAppraisal: [],
            selectedReviewersForAppraisal: [],
            editSelectedGoalsForAppraisal: [],
            
            add_employees_to_appraisal_text: "Submit",
            add_employees_to_appraisal_submit: false,
            file_attachment_text: "Upload",
            file_attachment_submit: false,

            add_appraisal_dialog: false,
            add_appraisal_form: {
                appraisal_name: "",
                start_date: "",
                end_date: "",
                multi_rater: true,
                reviewer_selected_by: 1,
                appraisal_url: ''
            },
            add_appraisal_button_text: "Submit",
            add_appraisal_button_loading: false,

            edit_appraisal_dialog: false,
            edit_appraisal_form: {
                id: "",
                appraisal_name: "",
                start_date: "",
                end_date: "",
                multi_rater: true,
                reviewer_selected_by: '',
                appraisal_url: ''
            },
            edit_appraisal_button_text: "Update",
            edit_appraisal_button_loading: false,
            
            add_reviewers_to_appraisal_text: "Submit",
            add_reviewers_to_appraisal_submit: false,

            addingAppraisalFilter: {
                designationName: 'All',
                departmentName: 'All',
                committeeName: 'All',
            },

            addingReviewerFilter: {
                designationName: 'All',
                departmentName: 'All',
                committeeName: 'All',
            },

            filters: {
                appraisalName: ''
            },

            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyAppraisals',
            'authCompanyQuestionnaires',
            'authCompanyData',
            'authCompanyGoals',
            'authCompanyEmployeesInAppraisals',
            'authCompanyReviewers',
            'validEmployeeId',
            'authCompanyDepartmentEmployees',
            'userType'
        ]),
        assets_url() {
            return ASSETS_URL;
        },
        availableReviewersInAppraisal() {
            let _this = this,
                result = [];
            result = _this.mixinBranchEmployees(_this.$route.name).filter((employee) => {
                let currentEmployeeObject = _this.singleEmployeeObject(_this.current_employee_id);
                let reportingManagerId = null;
                if (currentEmployeeObject) {
                    reportingManagerId = currentEmployeeObject.reporting_to;
                }
                return (
                    employee.id != _this.current_employee_id 
                    &&
                    employee.id != reportingManagerId
                    && 
                    _this.reviewerIsInAppraisal(employee.id, _this.current_appraisal_id) === false
                );
            });
            return result;
        },
        availableReviewerNamesForAppraisal() {
            let _this = this,
                result = [];
            result = _this.availableReviewersInAppraisal.map((employee) => `${employee.employee_fristname} ${employee.employee_lastname}`);
            return result;
        },
        filteredAvailableReviewerNamesForAppraisal() {
            const _this = this;

            // Search and filter by department name
            let result = _this.availableReviewerNamesForAppraisal.filter(EMPLOYEE_NAME => {
                if (_this.addingReviewerFilter.departmentName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by department name
                    let employee = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employee) {
                        return false;
                    }
                    
                    let primaryEmployeeDepartment = _this.get_dept_name(employee.department_id);
                    let secondaryEmployeeDepartments = _this.authCompanyDepartmentEmployees.filter(EMPLOYEE_DEPARTMENT => EMPLOYEE_DEPARTMENT.employee_id == employee.id);
                    let secondaryEmployeeDepartmentNames = secondaryEmployeeDepartments.map(EMPLOYEE_DEPARTMENT => _this.get_dept_name(EMPLOYEE_DEPARTMENT.department_id));
                    let allEmployeeDepartments = [
                        primaryEmployeeDepartment,
                        ...secondaryEmployeeDepartmentNames
                    ];
                    return allEmployeeDepartments.indexOf(_this.filters.departmentName) !== -1;
                }
            });
            // Search and filter by department name

            // Search and filter by designation name
            result = result.filter(EMPLOYEE_NAME => {
                if (_this.addingReviewerFilter.designationName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by designation name
                    let employeeObject = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employeeObject) {
                        return false;
                    }
                    let designationName = employeeObject.employee_designation;
                    return designationName === _this.addingReviewerFilter.designationName;
                }
            });
            // Search and filter by designation name

            // Search and filter by committee name
            result = result.filter(EMPLOYEE_NAME => {
                if (_this.addingReviewerFilter.committeeName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by committee name
                    let employeeObject = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employeeObject) {
                        return false;
                    }
                    let committeeNames = _this.getEmployeeCommitteeNames(employeeObject.id);
                    return committeeNames.indexOf(_this.addingReviewerFilter.committeeName) !== -1;
                }
            });
            // Search and filter by committee name

            return result;
        },
        reviewersToAddToAppraisals() {
            let _this = this,
                result = [],
                reviewer_id;
            result = _this.selectedReviewersForAppraisal.map((sr) => {
                reviewer_id = _this.get_employee_id(sr);
                return {
                    company_id: _this.authCompanyData.id,
                    reviewer_id,
                    employee_id: _this.current_employee_id,
                    appraisal_id: _this.current_appraisal_id
                };
            });
            return result;
        },
        currentAppraisal() {
            let _this = this, result = null;
            result = _this.authCompanyAppraisals.filter(appraisal => appraisal.id == _this.current_appraisal_id);
            if (result.length >= 1) {
                result = result[0];
            } else {
                result = null;
            }
            return result;
        },
        filteredQuestionnaires() {
            let _this = this, result = [];
            result = _this.authCompanyQuestionnaires.filter(q => q.appraisal_id == _this.current_appraisal_id);
            return result;
        },
        availableEmployeesInAppraisal() {
            let _this = this,
                result = [];
            result = _this.mixinBranchEmployees(_this.$route.name).filter((employee) => {
                return _this.employeeIsInAppraisal(employee.id, _this.current_appraisal_id) === false;
            });
            return result;
        },
        filteredAvailableEmployeeNamesForAppraisal() {
            const _this = this;

            // Search and filter by department name
            let result = _this.availableEmployeeNamesForAppraisal.filter(EMPLOYEE_NAME => {
                if (_this.addingAppraisalFilter.departmentName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by department name
                    let employee = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employee) {
                        return false;
                    }

                    let primaryEmployeeDepartment = _this.get_dept_name(employee.department_id);
                    let secondaryEmployeeDepartments = _this.authCompanyDepartmentEmployees.filter(EMPLOYEE_DEPARTMENT => EMPLOYEE_DEPARTMENT.employee_id == employee.id);
                    let secondaryEmployeeDepartmentNames = secondaryEmployeeDepartments.map(EMPLOYEE_DEPARTMENT => _this.get_dept_name(EMPLOYEE_DEPARTMENT.department_id));
                    let allEmployeeDepartments = [
                        primaryEmployeeDepartment,
                        ...secondaryEmployeeDepartmentNames
                    ];
                    return allEmployeeDepartments.indexOf(_this.filters.departmentName) !== -1;
                }
            });
            // Search and filter by department name

            // Search and filter by designation name
            result = result.filter(EMPLOYEE_NAME => {
                if (_this.addingAppraisalFilter.designationName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by designation name
                    let employeeObject = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employeeObject) {
                        return false;
                    }
                    let designationName = employeeObject.employee_designation;
                    return designationName === _this.addingAppraisalFilter.designationName;
                }
            });
            // Search and filter by designation name

            // Search and filter by committee name
            result = result.filter(EMPLOYEE_NAME => {
                if (_this.addingAppraisalFilter.committeeName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by committee name
                    let employeeObject = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employeeObject) {
                        return false;
                    }
                    let committeeNames = _this.getEmployeeCommitteeNames(employeeObject.id);
                    return committeeNames.indexOf(_this.addingAppraisalFilter.committeeName) !== -1;
                }
            });
            // Search and filter by committee name

            return result;
        },
        availableEmployeeNamesForAppraisal() {
            let _this = this,
                result = [];
            result = _this.availableEmployeesInAppraisal.map((employee) => `${employee.employee_fristname} ${employee.employee_lastname}`);
            return result;
        },
        employeesToAddToAppraisals() {
            let _this = this,
                result = [],
                employee_id;
            result = _this.selectedEmployeesForAppraisal.map((se) => {
                employee_id = _this.get_employee_id(se);
                return {
                    company_id: _this.authCompanyData.id,
                    employee_id: employee_id,
                    appraisal_id: _this.current_appraisal_id,
                    department_id: _this.employeeObject(employee_id).department_id,
                    designation_id: _this.designationObject(_this.employeeObject(employee_id).employee_designation) ? _this.designationObject(_this.employeeObject(employee_id).employee_designation).id : null,
                };
            });
            return result;
        },
        getAvailableGoalsInEditingAppraisal() {
            let _this = this,
                result = [];
            result = _this.authCompanyGoals.filter((goal) => {
                return _this.goalIsInAppraisal(goal.id, _this.edit_appraisal_form.id) === true;
            });
            return result;
        },
        goalsToAddToAppraisals() {
            let _this = this,
                result = [],
                goal_id;
            result = _this.selectedGoalsForAppraisal.map((sg) => {
                goal_id = _this.getGoalId(sg);
                return {
                    goal_id,
                    company_id: _this.authCompanyData.id
                };
            });
            return result;
        },
        goalsToAddToAppraisalsForEdit() {
            let _this = this,
                result = [],
                goal_id;
            result = _this.editSelectedGoalsForAppraisal.map((sg) => {
                goal_id = _this.getGoalId(sg);
                return {
                    goal_id,
                    company_id: _this.authCompanyData.id
                };
            });
            return result;
        },
        availableGoalNamesForAppraisal() {
            let _this = this,
                result = [];
            result = _this.authCompanyGoals.map((goal) => `${goal.goal_name}`);
            return result;
        },
        filteredCompanyAppraisals() {
            const _this = this;
            
            // Search and filter by appraisal name
            let result = _this.allEmployeeAppraisals.filter(APPRAISAL => {
                if (_this.filters.appraisalName === 'All') {
                    // If all appraisals should show
                    return true;
                } else {
                    // If filtering by appraisal name
                    return APPRAISAL.appraisal_name.indexOf(_this.filters.appraisalName) !== -1;
                }
            });
            // Search and filter by appraisal name

            return result;
        },
        availableAppraisalNames() {
            const _this = this;
            let appraisalNames = ['All'];
            _this.authCompanyAppraisals.forEach(APPRAISAL => {
                if (appraisalNames.indexOf(APPRAISAL.appraisal_name) === -1) {
                    appraisalNames.splice(appraisalNames.length, 0, APPRAISAL.appraisal_name);
                }
            });
            return appraisalNames;
        },
    },
    watch: {
        menu1(val) {
            val && setTimeout(() => (this.$refs.picker1.activePicker = "YEAR"));
        },
        menu2(val) {
            val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
        },
        menu3(val) { 
            val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
        },
        menu4(val) {
            val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
        },
    },
    methods: {
        ...mapActions([
            'fetch_company_appraisals',
            'fetch_company_employees_in_appraisals',
            'fetch_company_questionnaires',
            'fetch_company_goals_in_appraisals',
            'fetch_company_reviewers'
        ]),
        add_questionnaire() {
            let _this = this;
            _this.add_questionnaire_button_text = "";
            _this.add_questionnaire_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}createquestionnaire`, {
                    company_id: _this.authCompanyData.id,
                    appraisal_id: _this.current_appraisal_id,
                    question: _this.add_questionnaire_form.question,
                    employee_id: _this.validEmployeeId
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The questionnaire was added successfully",
                    });
                    _this.add_questionnaire_dialog = false;
                    _this.add_questionnaire_form.question = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.appraisal_id
                                ? errors.appraisal_id[0]
                                : errors.question
                                ? errors.question[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The questionnaire couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_questionnaire_button_loading = false;
                    _this.add_questionnaire_button_text = "Submit";
                    _this.fetch_company_questionnaires();
                });
        },
        update_questionnaire() {
            let _this = this;
            _this.edit_questionnaire_button_text = "";
            _this.edit_questionnaire_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}updatequestionnaire`, {
                    company_id: _this.authCompanyData.id,
                    appraisal_id: _this.current_appraisal_id,
                    questionnaire_id: _this.edit_questionnaire_form.id,
                    question: _this.edit_questionnaire_form.question,
                    employee_id: _this.validEmployeeId
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The questionnaire was updated successfully",
                    });
                    _this.edit_questionnaire_dialog = false;
                    _this.edit_questionnaire_form.question = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.appraisal_id
                                ? errors.appraisal_id[0]
                                : errors.question
                                ? errors.question[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The questionnaire couldn't be updated",
                    });
                })
                .then(function () {
                    _this.edit_questionnaire_form_questionnaire_button_loading = false;
                    _this.edit_questionnaire_form_questionnaire_button_text = "Update";
                    _this.fetch_company_questionnaires();
                });
        },
        delete_questionnaire(questionnaire) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this questionnaire?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deletequestionnaire`, {
                                company_id: _this.authCompanyData.id,
                                questionnaire_id: questionnaire.id,
                                employee_id: _this.validEmployeeId,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Questionnaire removed successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Questionnaire couldn't be removed",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_questionnaires();
                            });
                    }
                });
        },
        add_appraisal() {
            let _this = this;
            _this.add_appraisal_button_text = "";
            _this.add_appraisal_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}createappraisal`, {
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    appraisal_name: _this.add_appraisal_form.appraisal_name,
                    start_date: _this.add_appraisal_form.start_date,
                    end_date: _this.add_appraisal_form.end_date,
                    multi_rater: 1,//_this.add_appraisal_form.multi_rater == true ? 1 : 0,
                    reviewer_selected_by: _this.add_appraisal_form.multi_rater == true ? _this.add_appraisal_form.reviewer_selected_by : null,
                    appraisal_url: _this.add_appraisal_form.appraisal_url,
                    goalcollections: _this.goalsToAddToAppraisals
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The appraisal was added successfully",
                    });
                    _this.add_appraisal_dialog = false;
                    _this.add_appraisal_form.appraisal_name = "";
                    _this.add_appraisal_form.start_date = "";
                    _this.add_appraisal_form.end_date = "";
                    _this.add_appraisal_form.multi_rater = true;
                    _this.add_appraisal_form.reviewer_selected_by = "";
                    _this.add_appraisal_form.appraisal_url = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.appraisal_name
                                ? errors.appraisal_name[0]
                                : errors.start_date
                                ? errors.start_date[0]
                                : errors.end_date
                                ? errors.end_date[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The appraisal couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_appraisal_button_loading = false;
                    _this.add_appraisal_button_text = "Submit";
                    _this.fetch_company_appraisals();
                    _this.fetch_company_goals_in_appraisals();
                });
        },
        update_appraisal() {
            let _this = this;
            _this.edit_appraisal_button_text = "";
            _this.edit_appraisal_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}updateappraisal`, {
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    appraisal_id: _this.edit_appraisal_form.id,
                    appraisal_name: _this.edit_appraisal_form.appraisal_name,
                    start_date: _this.edit_appraisal_form.start_date,
                    end_date: _this.edit_appraisal_form.end_date,
                    multi_rater: 1,//_this.edit_appraisal_form.multi_rater == true ? 1 : 0,
                    reviewer_selected_by: _this.edit_appraisal_form.multi_rater ? _this.edit_appraisal_form.reviewer_selected_by : null,
                    appraisal_url: _this.edit_appraisal_form.appraisal_url,
                    goalcollections: _this.goalsToAddToAppraisalsForEdit
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The appraisal was updated successfully",
                    });
                    _this.edit_appraisal_dialog = false;
                    _this.edit_appraisal_form.id = "";
                    _this.edit_appraisal_form.appraisal_name = "";
                    _this.edit_appraisal_form.start_date = "";
                    _this.edit_appraisal_form.end_date = "";
                    _this.edit_appraisal_form.multi_rater = true;
                    _this.edit_appraisal_form.reviewer_selected_by = "";
                    _this.edit_appraisal_form.appraisal_url = "";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.goal_id
                                ? errors.goal_id[0]
                                : errors.kra
                                ? errors.kra[0]
                                : errors.from_date
                                ? errors.from_date[0]
                                : errors.to_date
                                ? errors.to_date[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The appraisal couldn't be updated",
                    });
                })
                .then(function () {
                    _this.edit_appraisal_button_loading = false;
                    _this.edit_appraisal_button_text = "Update";
                    _this.fetch_company_appraisals();
                    _this.fetch_company_goals_in_appraisals();
                });
        },
        delete_appraisal(appraisal) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this appraisal?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deleteappraisal`, {
                                company_id: _this.authCompanyData.id,
                                appraisal_id: appraisal.id,
                                employee_id: _this.validEmployeeId,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Appraisal removed successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Appraisal couldn't be removed",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_appraisals();
                            });
                    }
                });
        },
        add_employees_to_appraisal() {
            let _this = this;
            _this.add_employees_to_appraisal_text = "";
            _this.add_employees_to_appraisal_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addemployeestoappraisal`, {
                    appraisal_id: _this.current_appraisal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    employeecollections: _this.employeesToAddToAppraisals,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Employees added successfully",
                    });
                    _this.fetch_company_employees_in_appraisals();
                    _this.add_appraisal_assignees_dialog = false;
                    _this.selectedEmployeesForAppraisal = [];
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Employees couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_employees_to_appraisal_submit = false;
                    _this.add_employees_to_appraisal_text = "Submit";
                });
        },
        remove_appraisal_assignee(appraisal_id, employee_id) {
            let _this = this,
                assignee_id = _this.getAppraisalAssigneeId(appraisal_id, employee_id);

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this assignee?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}removefromappraisal `, {
                                employee_id: _this.validEmployeeId,
                                company_id: _this.authCompanyData.id,
                                idtoremove: assignee_id,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Assignee removed successfully",
                                });
                                _this.fetch_company_employees_in_appraisals();
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Could not remove the assignee",
                                });
                                _this.fetch_company_employees_in_appraisals();
                                console.log(error);
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        start_appraisal(appraisal_id, starting) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: `Are you sure you want to ${starting ? 'start' : 'stop'} this appraisal?`,
                    showCancelButton: true,
                    confirmButtonText: `Yes, ${starting ? 'Start' : 'Stop'}`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}startappraisal`, {
                                company_id: _this.authCompanyData.id,
                                employee_id: _this.validEmployeeId,
                                appraisal_id
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: `Appraisal ${starting ? 'started' : 'stopped'} successfully`,
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: `Appraisal couldn't be ${starting ? 'started' : 'stopped'}`,
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_appraisals();
                                _this.fetch_company_employees_in_appraisals();
                            });
                    }
                });
        },
        remove_appraisal_reviewer(id) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this reviewer?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}removereviwer `, {
                                employee_id: _this.validEmployeeId,
                                company_id: _this.authCompanyData.id,
                                idtoremove: id,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Reviewer removed successfully",
                                });
                                _this.fetch_company_reviewers();
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Could not remove the reviewer",
                                });
                                _this.fetch_company_reviewers();
                                console.log(error);
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        upload_file_attachment() {
            let _this = this;
            _this.file_attachment_text = "";
            _this.file_attachment_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            let formData = new FormData();
            formData.append("appraisal_id", _this.current_appraisal_id);
            formData.append("file", document.querySelector(".file-attachment div div div input").files[0]);

            axios
                .post(`${BASE_URL}appraisalattachment`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Appraisal attachment uploaded successfully",
                    });
                    document.querySelector('[class="v-icon notranslate v-icon--link theme--light"]').click();
                    _this.fetch_company_appraisals();
                    _this.file_attachment_dialog = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors ? (typeof errors === "string" ? errors : null || errors.file ? errors.file[0] : null) : null || error.response.data.error || error.response.data.message || "Guided document could not be uploaded",
                    });
                })
                .then(function () {
                    _this.file_attachment_submit = false;
                    _this.file_attachment_text = "Upload";
                });
        },
        add_reviewers_to_appraisal() {
            let _this = this;
            _this.add_reviewers_to_appraisal_text = "";
            _this.add_reviewers_to_appraisal_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addreviwers`, {
                    appraisal_id: _this.current_appraisal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    reviewers: _this.reviewersToAddToAppraisals
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Reviewers added successfully",
                    });
                    _this.fetch_company_reviewers();
                    _this.add_appraisal_reviewers_dialog = false;
                    _this.selectedReviewersForAppraisal = [];
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Reviewers couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_reviewers_to_appraisal_submit = false;
                    _this.add_reviewers_to_appraisal_text = "Submit";
                });
        },
        preview_appraisal(appraisal) {
            this.edit_appraisal_form.id = appraisal.id;
            this.edit_appraisal_form.appraisal_name = appraisal.appraisal_name;
            this.edit_appraisal_form.start_date = appraisal.start_date;
            this.edit_appraisal_form.end_date = appraisal.end_date;
            this.edit_appraisal_form.multi_rater = !!appraisal.multi_rater;
            this.edit_appraisal_form.reviewer_selected_by = appraisal.reviewer_selected_by;
            this.edit_appraisal_form.appraisal_url = appraisal.appraisal_url;
            this.editSelectedGoalsForAppraisal = this.getAvailableGoalsInEditingAppraisal.map(goal => goal.goal_name);
            this.preview_appraisal_dialog = true;
        },
        launch_edit_appraisal(appraisal) {
            this.edit_appraisal_form.id = appraisal.id;
            this.edit_appraisal_form.appraisal_name = appraisal.appraisal_name;
            this.edit_appraisal_form.start_date = appraisal.start_date;
            this.edit_appraisal_form.end_date = appraisal.end_date;
            this.edit_appraisal_form.multi_rater = !!appraisal.multi_rater;
            this.edit_appraisal_form.reviewer_selected_by = appraisal.reviewer_selected_by;
            this.edit_appraisal_form.appraisal_url = appraisal.appraisal_url;
            this.editSelectedGoalsForAppraisal = this.getAvailableGoalsInEditingAppraisal.map(goal => goal.goal_name);
            this.edit_appraisal_dialog = true;
        },
        launch_edit_questionnaire(questionnaire) {
            this.edit_questionnaire_form.id = questionnaire.id;
            this.edit_questionnaire_form.question = questionnaire.question;
            this.edit_questionnaire_dialog = true;
        },
        getAppraisalName(appraisal_id) {
            let result = this.authCompanyAppraisals.filter((item) => {
                return item.id == appraisal_id;
            });
            if (result.length >= 1) {
                return result[0].appraisal_name;
            }
            return "";
        },
        employeesInAppraisal(appraisal_id) {
            let _this = this,
                result = [];
            result = _this.mixinBranchEmployees(_this.$route.name).filter((employee) => {
                return _this.employeeIsInAppraisal(employee.id, appraisal_id) === true;
            });
            return result;
        },
        employeeIsInAppraisal(employee_id, appraisal_id) {
            let _this = this,
                truth = false;
            _this.authCompanyEmployeesInAppraisals.forEach((item) => {
                if (item.appraisal_id == appraisal_id && item.employee_id == employee_id) {
                    truth = true;
                }
            });
            return truth;
        },
        getGoalId(goal_name) {
            let result = this.authCompanyGoals.filter((item) => {
                return item.goal_name == goal_name;
            });
            if (result.length >= 1) {
                return result[0].id;
            }
            return "";
        },
        getQuestionnaireByAppraisalId(appraisalId) {
            let _this = this, result = [];
            result = _this.authCompanyQuestionnaires.filter(q => q.appraisal_id == appraisalId);
            return result;
        },
        getAppraisalAssigneeId(appraisal_id, employee_id) {
            let _this = this,
                value = 0,
                object = _this.authCompanyEmployeesInAppraisals.filter((ea) => {
                    return ea.appraisal_id == appraisal_id && ea.employee_id == employee_id;
                });
            if (object.length) {
                value = object[0].employeegoal_id;
            }
            return value;
        },
        reviewerIsInAppraisal(reviewer_id, appraisal_id) {
            let _this = this,
                truth = false;
            _this.authCompanyReviewers.forEach((item) => {
                if (item.appraisal_id == appraisal_id && item.reviewer_id == reviewer_id) {
                    truth = true;
                }
            });
            return truth;
        },

        save1(date) {
            this.$refs.menu1.save(date);
        },
        save2(date) {
            this.$refs.menu2.save(date);
        },
        save3(date) {
            this.$refs.menu3.save(date);
        },
        save4(date) {
            this.$refs.menu4.save(date);
        },
    }
}
</script>

<style>

</style>