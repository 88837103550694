<template>
    <div class="card-body row py-4">
        <div class="col-sm-4" style="align-self: center;">
            <h4>Self Appraisal</h4>
        </div>
        <div class="col-sm-8">
            <v-row>
                <v-col md="9">
                </v-col>
                <v-col md="3">
                    <v-select v-model="filters.appraisalName" :items="availableAppraisalNames"
                        :menu-props="{ maxHeight: '400' }" label="Select" solo dense hint="Select appraisal"
                        persistent-hint></v-select>
                </v-col>
            </v-row>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Appraisal Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Duration
                                    </th>
                                    <th class="text-left" scope="col">
                                        Final Score
                                    </th>
                                    <th class="text-left" scope="col">
                                        Rating
                                    </th>
                                    <th class="text-center" scope="col">
                                        Reviewers
                                    </th>
                                    <th scope="col" class="text-center">
                                        Begin Appraisal
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(EA, index) in filteredAllEmployeeAppraisals" :key="'employee-appraisal-' + index">
                                    <td class="text-left">{{ EA.appraisal_name }}</td>
                                    <td v-if="getAppraisalById(EA.appraisal_id)" class="text-left">
                                        {{
                                            new Date(getAppraisalById(EA.appraisal_id).start_date)
                                                .toUTCString()
                                                .substring(0, 16)
                                            + ' - ' +
                                            new Date(getAppraisalById(EA.appraisal_id).end_date)
                                                .toUTCString()
                                                .substring(0, 16)
                                        }}
                                        <!-- <br />
                                        <i>Locked in 2 days</i> -->
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No duration specified</i>
                                    </td>
                                    <td class="text-left">
                                        {{ getEmployeeFinalScore(validEmployeeId, EA.appraisal_id) }}
                                    </td>
                                    <td class="text-left">
                                        {{ getEmployeeFinalRating(validEmployeeId, EA.appraisal_id) }}
                                    </td>
                                    <td v-if="employeeAppraisalIsEnded(EA.id) === false" class="text-center">
                                        <button
                                            v-if="(getAppraisalById(EA.appraisal_id) ? getAppraisalById(EA.appraisal_id).multi_rater == 1 : false)"
                                            @click="(current_appraisal_id = EA.appraisal_id, manage_reviewers_dialog = true)"
                                            type="button" class="btn btn-secondary btn-sm ripple">
                                            Manage
                                        </button>
                                        <div v-else>
                                            <span
                                                v-if="getReportingManagerName(singleEmployeeObject(validEmployeeId) ? singleEmployeeObject(validEmployeeId).reporting_to : null).status == 'found'">
                                                {{ `Reporting Manager:
                                                ${getReportingManagerName(singleEmployeeObject(validEmployeeId) ?
                                                    singleEmployeeObject(validEmployeeId).reporting_to : null).value}` }}
                                            </span>
                                            <span v-else>
                                                {{ `${getReportingManagerName(singleEmployeeObject(validEmployeeId) ?
                                                    singleEmployeeObject(validEmployeeId).reporting_to : null).value}` }}
                                            </span>
                                        </div>
                                    </td>
                                    <td v-else class="text-center">
                                        <i>
                                            Appraisal Ended
                                        </i>
                                    </td>
                                    <td class="text-center">
                                        <button v-if="employeeAppraisalIsEnded(EA.id) === false"
                                            @click.prevent="beginAppraisal(EA, validEmployeeId, null)" type="button"
                                            class="btn btn-sm btn-secondary text-white btn-raised ripple"
                                            :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null">
                                            Begin Appraisal
                                        </button>
                                        <i v-else>
                                            Appraisal Ended
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <!-- Dialog for managing reviewers -->
        <v-dialog v-model="manage_reviewers_dialog" max-width="900px">
            <v-card>
                <v-card-title class="text-left d-block">
                    <span class="headline">Your Reviewers {{ getAppraisalById(current_appraisal_id) ?
                        `[${getAppraisalById(current_appraisal_id).appraisal_name}]` : '' }}</span>
                    <button
                        v-if="(getAppraisalById(current_appraisal_id) ? getAppraisalById(current_appraisal_id).multi_rater == 1 : false)"
                        @click="(current_employee_id = validEmployeeId, add_appraisal_reviewers_dialog = true)"
                        style="float: right;" type="button"
                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                        Add Reviewer
                    </button>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-left">Reviewer</th>
                                    <th class="text-center">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(reviewer, index) in getEmployeeReviewers(current_appraisal_id, validEmployeeId)"
                                    :key="'reviewer' + index">
                                    <td class="text-left">
                                        {{ reviewer.employee_fristname + ' ' + reviewer.employee_lastname }}
                                    </td>
                                    <td class="text-center">
                                        <i v-if='reviewer.id' @click="remove_appraisal_reviewer(reviewer.id)"
                                            class="col-sm-12 py-1 text-center fas fa-user-minus ml-0 px-0"
                                            style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="manage_reviewers_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for managing reviewers -->

        <!-- Dialog for adding employee appraisal reviewers -->
        <v-dialog v-model="add_appraisal_reviewers_dialog" max-width="600px">
            <v-card>
                <v-card-title class="text-left d-block">
                    <span class="headline">Assign reviewers to appraisal</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols='12' sm='6'>
                                        <v-select v-model="addingReviewerFilter.departmentName"
                                            :items="availableDepartmentsNames" :menu-props="{ maxHeight: '400' }"
                                            label="Select" solo dense hint="Select department" persistent-hint></v-select>
                                    </v-col>
                                    <v-col cols='12' sm='6'>
                                        <v-select v-model="addingReviewerFilter.designationName"
                                            :items="availableDesignationNames" :menu-props="{ maxHeight: '400' }"
                                            label="Select" solo dense hint="Select designation" persistent-hint></v-select>
                                    </v-col>
                                    <v-col cols='12' sm='6'>
                                        <v-select v-model="addingReviewerFilter.committeeName"
                                            :items="availableCommitteeNames" :menu-props="{ maxHeight: '400' }"
                                            label="Select" solo dense hint="Select committee" persistent-hint></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select v-model="selectedReviewersForAppraisal"
                                            :items="filteredAvailableReviewerNamesForAppraisal"
                                            :menu-props="{ maxHeight: '400' }" label="Select" multiple solo dense
                                            hint="Select Employees" persistent-hint></v-select>
                                    </v-col>
                                    <v-col cols="12" offset-md="3" offset-sm="3" sm="6" md="6">
                                        <button
                                            @click="add_reviewers_to_appraisal_submit ? null : add_reviewers_to_appraisal()"
                                            style="width: 99%;" type="button"
                                            class="btn btn-secondary m-1 text-white btn-raised ripple">
                                            {{ add_reviewers_to_appraisal_text }}
                                            <v-icon :style="!add_reviewers_to_appraisal_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                ">
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_appraisal_reviewers_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding employee appraisal reviewers -->

        <!-- Dialog for main appraisal -->
        <v-dialog v-model="run_appraisal_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">{{ currentEmployeeAppraisal.appraisal_name }}</span>
                    <span v-if="getAppraisalById(currentEmployeeAppraisal.appraisal_id)" style="float: right;">{{ new
                        Date(getAppraisalById(currentEmployeeAppraisal.appraisal_id).start_date).toUTCString().substring(0,
                            16) }} - {{ new
            Date(getAppraisalById(currentEmployeeAppraisal.appraisal_id).end_date).toUTCString().substring(0,
                16) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" class="remove-hint pb-0">
                                    <h4 style="color: #111d5e;">Self Appraisal </h4>
                                </v-col>
                                <v-col
                                    v-if="getAppraisalById(currentEmployeeAppraisal.appraisal_id) ? getAppraisalById(currentEmployeeAppraisal.appraisal_id).appraisal_url : null"
                                    cols="12">
                                    <p>
                                        Visit
                                        <a :href="getAppraisalById(currentEmployeeAppraisal.appraisal_id).appraisal_url"
                                            target="_blank">Appraisal URL <i
                                                class="fal fa-arrow-up-right-from-square"></i></a>
                                    </p>
                                </v-col>
                                <v-col v-else v-for="(questionnaire, index) in selfAppraisalQuestionnaires"
                                    :key="'questionnaire-to-reply' + index" cols="12">
                                    <span style="color: #111d5e;">{{ questionnaire.question }}</span>
                                    <v-row>
                                        <v-col cols="12" md="9">
                                            <v-textarea placeholder="Your answer" rows="1" v-model="questionnaire.response"
                                                solo class="mt-2"></v-textarea>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <button
                                                @click="questionnaire.buttonLoading ? null : save_response(questionnaire)"
                                                type="button" class="btn btn-secondary mt-4 ripple">
                                                {{ questionnaire.buttonText }}
                                                <v-icon
                                                    :style="!questionnaire.buttonLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                                    fal fa-circle-notch fa-spin
                                                </v-icon>
                                            </button>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <span style="color: #111d5e;">Rating</span>
                                    <div class="mt-2">
                                        <v-rating background-color="warning lighten-1" color="warning" dense
                                            empty-icon="far fa-star-sharp" full-icon="fas fa-star-sharp"
                                            half-icon="fas fa-star-sharp-half-stroke" hover length="5" size="30"
                                            v-model="selfAppraisalRating"></v-rating>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <span style="color: #111d5e;">Comment</span>
                                    <v-textarea v-model="selfAppraisalComment" placeholder="Enter comment" solo
                                        class="mt-2"></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <button @click="selfAppraisalButtonLoading ? null : save_appraisal_review()"
                                        type="button" class="btn btn-secondary mt-2 ripple w-large-device-button w-100">
                                        {{ selfAppraisalButtonText }}
                                        <v-icon
                                            :style="!selfAppraisalButtonLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                            <v-row v-if="employeeGoalsInfo.length >= 1">
                                <v-col cols="12" class="remove-hint pb-0 mt-8">
                                    <h4 style="color: #111d5e;">Goals </h4>
                                </v-col>
                                <v-col class="mb-10" cols="12" v-for="(goal, index) in employeeGoalsInfo"
                                    :key="'goal-review-' + index">
                                    <v-row>
                                        <v-col cols="12" sm="9">
                                            <h5 style="color: #111d5e;" class="mb-5">Goal #{{ index + 1 }}: {{
                                                goal.goal_name }}</h5>
                                            <v-row>

                                                <v-col v-if="goal.KRAs.length >= 1" cols="12">
                                                    <v-row v-for="(kra, index) in goal.KRAs" :key="'goal-kra' + index">
                                                        <v-col cols="6">{{ kra.kra }}</v-col>
                                                        <v-col cols="6">
                                                            <span
                                                                v-if="getEmployeeKRAInfo(kra.id, validEmployeeId) && getEmployeeKRAInfo(kra.id, validEmployeeId).is_completed == 1"
                                                                class="badge badge-success text-white">COMPLETED</span>
                                                            <span v-else
                                                                class="badge badge-warning text-white">INCOMPLETE</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <div class="ul-widget-s5__progress pr-0">
                                                <div class="progress">
                                                    <div class="progress-bar bg-secondary" role="progressbar" :style="'width: '
                                                        +
                                                        getGoalCompletionPercentage(goal.goal_id, validEmployeeId)
                                                        +
                                                        '%'"
                                                        :aria-valuenow="getGoalCompletionPercentage(goal.goal_id, validEmployeeId)"
                                                        aria-valuemin="0" aria-valuemax="100">{{
                                                            getGoalCompletionPercentage(goal.goal_id, validEmployeeId) }}%</div>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <span style="color: #111d5e;">Rating</span>
                                            <div class="mt-2">
                                                <v-rating background-color="warning lighten-1" color="warning" dense
                                                    empty-icon="far fa-star-sharp" full-icon="fas fa-star-sharp"
                                                    half-icon="fas fa-star-sharp-half-stroke" hover length="5" size="30"
                                                    v-model="goal.rating"></v-rating>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <span style="color: #111d5e;">Comment</span>
                                            <v-textarea v-model="goal.comment" placeholder="Enter comment" solo
                                                class="mt-2"></v-textarea>
                                        </v-col>
                                        <v-col cols="12">
                                            <button @click="goal.buttonLoading ? null : save_goal_review(goal)"
                                                type="button"
                                                class="btn btn-secondary mt-2 ripple w-large-device-button w-100">
                                                {{ goal.buttonText }}
                                                <v-icon
                                                    :style="!goal.buttonLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                                    fal fa-circle-notch fa-spin
                                                </v-icon>
                                            </button>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-10">
                                <v-col cols="12">
                                    <h5
                                        v-if="singleEmployeeObject(currentEmployeeAppraisal ? currentEmployeeAppraisal.employee_id : validEmployeeId)">
                                        Overall score for {{ singleEmployeeObject(currentEmployeeAppraisal ?
                                            currentEmployeeAppraisal.employee_id : validEmployeeId).employee_fristname + ' ' +
                                            singleEmployeeObject(currentEmployeeAppraisal ? currentEmployeeAppraisal.employee_id
                                                : validEmployeeId).employee_lastname }}</h5>
                                    <h5 v-else>Overall score</h5>
                                </v-col>
                                <v-col cols="12">
                                    <div class="main-content mt-0 px-0 py-0">
                                        <div class="table-responsive">
                                            <table class="table mb-2">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" scope="col">
                                                            Module
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Score
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-left">Appraisal</td>
                                                        <td class="text-left">{{ selfAppraisalRating }} of 5</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">Goal</td>
                                                        <td class="text-left">{{ averageGoalScore }} of 5</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">Final Score</td>
                                                        <td class="text-left">{{ (parseFloat(selfAppraisalRating) +
                                                            averageGoalScore) / 2 }} of 5</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="text-center">
                                    <button @click="submitAppraisalLoading ? null : submit_appraisal()" style="width: 20%;"
                                        type="button" class="btn btn-secondary btn-lg m-1 ripple">
                                        {{ submitAppraisalText }}
                                        <v-icon
                                            :style="!submitAppraisalLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="run_appraisal_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for main appraisal -->
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import { BASE_URL } from '@/main'

export default {
    props: {
        getTrueEmployeeAppraisals: Function,
        getAppraisalById: Function,
        getEmployeeFinalScore: Function,
        getEmployeeFinalRating: Function,
        getReportingManagerName: Function,
        singleEmployeeObject: Function,
        get_employee_id: Function,
        getEmployeeReviewers: Function,
        getEmployeeAppraisalReview: Function,
        getResponseByQuestionId: Function,
        getEmployeeGoalsInfo: Function,
        getEmployeeKRAInfo: Function,
        employeeAppraisalIsEnded: Function,
        goalIsInAppraisal: Function,
        getEmployeeByName: Function,
        get_dept_name: Function,
        getEmployeeCommitteeNames: Function,
        getGoalCompletionPercentage: Function,
        availableDepartmentsNames: Array,
        availableDesignationNames: Array,
        availableCommitteeNames: Array
    },
    data() {
        return {
            current_appraisal_id: null,
            current_employee_id: null,
            manage_reviewers_dialog: false,
            add_appraisal_reviewers_dialog: false,
            run_appraisal_dialog: false,

            add_reviewers_to_appraisal_text: "Submit",
            add_reviewers_to_appraisal_submit: false,
            selfAppraisalButtonText: 'Submit Review',
            selfAppraisalButtonLoading: false,
            submitAppraisalText: 'Submit Appraisal',
            submitAppraisalLoading: false,

            selectedReviewersForAppraisal: [],
            selfAppraisalQuestionnaires: [],
            employeeGoalsInfo: [],

            selfAppraisalComment: '',
            selfAppraisalRating: 0,
            currentEmployeeAppraisal: {},

            addingReviewerFilter: {
                designationName: 'All',
                departmentName: 'All',
                committeeName: 'All',
            },

            filters: {
                appraisalName: ''
            }
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'validEmployeeId',
            'authCompanyReviewers',
            'authCompanyDepartmentEmployees',
            'authCompanyQuestionnaires'
        ]),
        reviewersToAddToAppraisals() {
            let _this = this,
                result = [],
                reviewer_id;
            result = _this.selectedReviewersForAppraisal.map((sr) => {
                reviewer_id = _this.get_employee_id(sr);
                return {
                    company_id: _this.authCompanyData.id,
                    reviewer_id,
                    employee_id: _this.current_employee_id,
                    appraisal_id: _this.current_appraisal_id
                };
            });
            return result;
        },
        availableReviewersInAppraisal() {
            let _this = this,
                result = [];
            result = _this.mixinBranchEmployees(_this.$route.name).filter((employee) => {
                let currentEmployeeObject = _this.singleEmployeeObject(_this.current_employee_id);
                let reportingManagerId = null;
                if (currentEmployeeObject) {
                    reportingManagerId = currentEmployeeObject.reporting_to;
                }
                return (
                    employee.id != _this.current_employee_id
                    &&
                    employee.id != reportingManagerId
                    &&
                    _this.reviewerIsInAppraisal(employee.id, _this.current_appraisal_id) === false
                );
            });
            return result;
        },
        availableReviewerNamesForAppraisal() {
            let _this = this,
                result = [];
            result = _this.availableReviewersInAppraisal.map((employee) => `${employee.employee_fristname} ${employee.employee_lastname}`);
            return result;
        },
        filteredAvailableReviewerNamesForAppraisal() {
            const _this = this;

            // Search and filter by department name
            let result = _this.availableReviewerNamesForAppraisal.filter(EMPLOYEE_NAME => {
                if (_this.addingReviewerFilter.departmentName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by department name
                    let employee = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employee) {
                        return false;
                    }

                    let primaryEmployeeDepartment = _this.get_dept_name(employee.department_id);
                    let secondaryEmployeeDepartments = _this.authCompanyDepartmentEmployees.filter(EMPLOYEE_DEPARTMENT => EMPLOYEE_DEPARTMENT.employee_id == employee.id);
                    let secondaryEmployeeDepartmentNames = secondaryEmployeeDepartments.map(EMPLOYEE_DEPARTMENT => _this.get_dept_name(EMPLOYEE_DEPARTMENT.department_id));
                    let allEmployeeDepartments = [
                        primaryEmployeeDepartment,
                        ...secondaryEmployeeDepartmentNames
                    ];
                    return allEmployeeDepartments.indexOf(_this.filters.departmentName) !== -1;
                }
            });
            // Search and filter by department name

            // Search and filter by designation name
            result = result.filter(EMPLOYEE_NAME => {
                if (_this.addingReviewerFilter.designationName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by designation name
                    let employeeObject = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employeeObject) {
                        return false;
                    }
                    let designationName = employeeObject.employee_designation;
                    return designationName === _this.addingReviewerFilter.designationName;
                }
            });
            // Search and filter by designation name

            // Search and filter by committee name
            result = result.filter(EMPLOYEE_NAME => {
                if (_this.addingReviewerFilter.committeeName === 'All') {
                    // If all employee names should show
                    return true;
                } else {
                    // If filtering by committee name
                    let employeeObject = _this.getEmployeeByName(EMPLOYEE_NAME);
                    if (!employeeObject) {
                        return false;
                    }
                    let committeeNames = _this.getEmployeeCommitteeNames(employeeObject.id);
                    return committeeNames.indexOf(_this.addingReviewerFilter.committeeName) !== -1;
                }
            });
            // Search and filter by committee name

            return result;
        },
        filteredQuestionnaires() {
            let _this = this, result = [];
            result = _this.authCompanyQuestionnaires.filter(q => q.appraisal_id == _this.current_appraisal_id);
            return result;
        },
        averageGoalScore() {
            let _this = this, value = 0;
            _this.employeeGoalsInfo.forEach(goal => {
                value += parseFloat(goal.rating);
            });
            value = value / _this.employeeGoalsInfo.length;
            return value || 0;
        },
        allEmployeeAppraisals() {
            return this.getTrueEmployeeAppraisals(this.validEmployeeId)
        },
        filteredAllEmployeeAppraisals() {
            const _this = this;

            // Search and filter by appraisal name
            let result = _this.allEmployeeAppraisals.filter(EMPLOYEE_APPRAISAL => {
                if (_this.filters.appraisalName === 'All') {
                    // If all appraisals should show
                    return true;
                } else {
                    // If filtering by appraisal name
                    return EMPLOYEE_APPRAISAL.appraisal_name.indexOf(_this.filters.appraisalName) !== -1;
                }
            });
            // Search and filter by appraisal name

            return result;
        },
        availableAppraisalNames() {
            const _this = this;
            let appraisalNames = ['All'];
            _this.allEmployeeAppraisals.forEach(EMPLOYEE_APPRAISAL => {
                if (appraisalNames.indexOf(EMPLOYEE_APPRAISAL.appraisal_name) === -1) {
                    appraisalNames.splice(appraisalNames.length, 0, EMPLOYEE_APPRAISAL.appraisal_name);
                }
            });
            return appraisalNames;
        },
    },
    methods: {
        ...mapActions([
            'fetch_company_reviewers',
            'fetch_company_questionnaire_responses',
            'fetch_employee_appraisal_reviews',
            'fetch_employee_goal_reviews',
            'fetch_company_appraisals'
        ]),
        save_response(questionnaire) {
            let _this = this;
            questionnaire.buttonText = "";
            questionnaire.buttonLoading = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addappriasalresponse`, {
                    appraisal_id: _this.current_appraisal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    question_id: questionnaire.questionnaireId,
                    response: questionnaire.response
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Response added successfully",
                    });
                    _this.fetch_company_questionnaire_responses();
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Response couldn't be added",
                    });
                })
                .then(function () {
                    questionnaire.buttonLoading = false;
                    questionnaire.buttonText = "Submit";
                });
        },
        save_goal_review(goal) {
            let _this = this;
            goal.buttonText = "";
            goal.buttonLoading = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addgoalreviews`, {
                    goal_id: goal.goal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: goal.employee_id,
                    reviewer_id: _this.validEmployeeId,
                    rating: goal.rating,
                    comment: goal.comment
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Review added successfully",
                    });
                    _this.fetch_employee_goal_reviews();
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Review couldn't be submitted",
                    });
                })
                .then(function () {
                    goal.buttonLoading = false;
                    goal.buttonText = "Submit";
                });
        },
        save_appraisal_review() {
            let _this = this;
            _this.selfAppraisalButtonText = "";
            _this.selfAppraisalButtonLoading = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addappraisalreviews`, {
                    appraisal_id: _this.current_appraisal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.currentEmployeeAppraisal.employee_id,
                    reviewer_id: _this.validEmployeeId,
                    rating: _this.selfAppraisalRating,
                    comment: _this.selfAppraisalComment
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Review added successfully",
                    });
                    _this.fetch_employee_appraisal_reviews();
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Review couldn't be submitted",
                    });
                })
                .then(function () {
                    _this.selfAppraisalButtonLoading = false;
                    _this.selfAppraisalButtonText = "Submit Review";
                });
        },
        add_reviewers_to_appraisal() {
            let _this = this;
            _this.add_reviewers_to_appraisal_text = "";
            _this.add_reviewers_to_appraisal_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addreviwers`, {
                    appraisal_id: _this.current_appraisal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    reviewers: _this.reviewersToAddToAppraisals
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Reviewers added successfully",
                    });
                    _this.fetch_company_reviewers();
                    _this.add_appraisal_reviewers_dialog = false;
                    _this.selectedReviewersForAppraisal = [];
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Reviewers couldn't be added",
                    });
                })
                .then(function () {
                    _this.add_reviewers_to_appraisal_submit = false;
                    _this.add_reviewers_to_appraisal_text = "Submit";
                });
        },
        remove_appraisal_reviewer(id) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this reviewer?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}removereviwer `, {
                                employee_id: _this.validEmployeeId,
                                company_id: _this.authCompanyData.id,
                                idtoremove: id,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Reviewer removed successfully",
                                });
                                _this.fetch_company_reviewers();
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Could not remove the reviewer",
                                });
                                _this.fetch_company_reviewers();
                                console.log(error);
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        reviewerIsInAppraisal(reviewer_id, appraisal_id) {
            let _this = this,
                truth = false;
            _this.authCompanyReviewers.forEach((item) => {
                if (item.appraisal_id == appraisal_id && item.reviewer_id == reviewer_id) {
                    truth = true;
                }
            });
            return truth;
        },
        beginAppraisal(EA, validEmployeeId, reviewerId) {
            const _this = this;
            _this.selfAppraisalComment = "";
            _this.selfAppraisalRating = 0;
            _this.current_appraisal_id = EA.appraisal_id,
                _this.currentEmployeeAppraisal = EA;
            let employeeAppraisalReview = _this.getEmployeeAppraisalReview(EA.appraisal_id, EA.employee_id, validEmployeeId);
            if (employeeAppraisalReview) {
                _this.selfAppraisalComment = employeeAppraisalReview.comment;
                _this.selfAppraisalRating = employeeAppraisalReview.rating ? parseInt(employeeAppraisalReview.rating) : 0;
            }
            _this.selfAppraisalQuestionnaires = _this.filteredQuestionnaires.map(FQ => {
                let responseObj = _this.getResponseByQuestionId(FQ.id, EA.employee_id);
                return {
                    questionnaireId: FQ.id,
                    question: FQ.question,
                    response: responseObj ? responseObj.response : '',
                    buttonText: 'Submit',
                    buttonLoading: false
                }
            });
            _this.employeeGoalsInfo = _this.getEmployeeGoalsInfo(_this.current_appraisal_id, EA.employee_id, reviewerId);
            _this.run_appraisal_dialog = true;
        },
        submit_appraisal() {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: `Are you sure you want to submit this appraisal?`,
                    showCancelButton: true,
                    confirmButtonText: `Yes, Submit`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        _this.submitAppraisalText = "";
                        _this.submitAppraisalLoading = true;

                        axios
                            .post(`${BASE_URL}employeeappraisalsubmit`, {
                                employee_id: _this.validEmployeeId,
                                appraisal_id: _this.current_appraisal_id
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: `Appraisal submitted successfully`,
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: `Appraisal couldn't be submitted`,
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_appraisals();
                                _this.submitAppraisalLoading = false;
                                _this.submitAppraisalText = "Submit Appraisal";
                            });
                    }
                });
        },
    }
}
</script>

<style></style>