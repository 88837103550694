<template>
    <div class="tab-pane fade" id="list-settings500" role="tabpanel" aria-labelledby="list-settings-list500">
        <div v-if="payGradeView == 0">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Pay Grade Management
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div v-if="permissionIsAssigned('payroll-approve')" class="col-lg-7 offset-5 py-4">
                                <button @click="payGradeView = 1" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary ml-4 m-1 text-white btn-raised ripple">
                                    Create Pay Grade
                                </button>
                                <v-text-field v-model="searchInputs.gradeName" placeholder="Search grade levels" solo
                                    class="mt-0" dense></v-text-field>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Grade Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Currency
                                    </th>
                                    <th class="text-left" scope="col">
                                        Minimum Currency
                                    </th>
                                    <th class="text-left" scope="col">
                                        Maximum Currency
                                    </th>
                                    <th class="text-left" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="pg in searchedPayGrades" :key="pg.id">
                                    <td class="text-left">{{ pg.grade_name }}</td>
                                    <td class="text-left">{{ pg.currency }}</td>
                                    <td class="text-left">
                                        {{ pg.minimum_salary | number_with_commas }}
                                    </td>
                                    <td class="text-left">
                                        {{ pg.maximum_salary | number_with_commas }}
                                    </td>
                                    <td style="padding-top: 13px;" class="employee-options text-left">
                                        <div class="row ml-0">
                                            <i @click="launch_edit_pay_grade(pg)" class="py-4 text-center fal fa-edit mr-2"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                            <i @click="delete_pay_grade(pg)" class="py-4 text-center fal fa-trash ml-2"
                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="payGradeView == 1">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Create Pay Grade
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="payGradeView = 0" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    View Pay Grades
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0 pt-0">
                <div>
                    <v-form ref='form1' @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Grade Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="payGradeForm.grade_name" :rules="[
                                        v => !!v || 'Grade name is required'
                                    ]" placeholder="Enter grade name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Currency <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select class="mt-2" :rules="[
                                        v => !!v || 'Currency is required'
                                    ]" :items="currencies" placeholder="Select currency" solo dense :full-width="true"
                                        v-model="payGradeForm.currency"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Minimum Salary <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="payGradeForm.minimum_salary" :rules="[
                                        v => !!v || 'Minimum salary is required',
                                        v => parseFloat(v) > 0 || 'Minimum salary must be more than 0'
                                    ]" placeholder="Enter minimum salary" solo type="number" class="mt-2"
                                        dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Maximum Salary <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="payGradeForm.maximum_salary" :rules="[
                                        v => !!v || 'Maximum salary is required',
                                        v => parseFloat(v) > 0 || 'Maximum salary must be more than 0',
                                        v => parseFloat(v) > payGradeForm.minimum_salary || 'Maximum salary must be more than the minimum salary'
                                    ]" placeholder="Enter maximum salary" solo type="number" class="mt-2"
                                        dense></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                                    <button @click="paygrade_create_submit ? null : paygrade_create()" style="width: 99%;"
                                        type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ paygrade_create_text }}
                                        <v-icon
                                            :style="!paygrade_create_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Edit Pay Grade
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="payGradeView = 0" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    View Pay Grades
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form ref='form2' @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Grade Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="editPayGradeForm.grade_name" :rules="[
                                        v => !!v || 'Grade name is required'
                                    ]" placeholder="Enter grade name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Currency <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select class="mt-2" :items="currencies" :rules="[
                                        v => !!v || 'Currency is required'
                                    ]" placeholder="Select currency" solo dense :full-width="true"
                                        v-model="editPayGradeForm.currency"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Minimum Salary <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="editPayGradeForm.minimum_salary" :rules="[
                                        v => !!v || 'Minimum salary is required',
                                        v => parseFloat(v) > 0 || 'Minimum salary must be more than 0'
                                    ]" placeholder="Enter minimum salary" solo type="number" class="mt-2" dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <span style="color: #111d5e;">Maximum Salary <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="editPayGradeForm.maximum_salary" :rules="[
                                        v => !!v || 'Maximum salary is required',
                                        v => parseFloat(v) > 0 || 'Maximum salary must be more than 0',
                                        v => parseFloat(v) > editPayGradeForm.minimum_salary || 'Maximum salary must be more than the minimum salary'
                                    ]" placeholder="Enter maximum salary" solo type="number" class="mt-2" dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4">
                                    <button @click="paygrade_update_submit ? null : paygrade_update()" style="width: 99%;"
                                        type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ paygrade_update_text }}
                                        <v-icon
                                            :style="!paygrade_update_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import currencies from "@/plugins/currencies";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            searchInputs: {
                gradeName: ""
            },
            payGradeView: 0,
            paygrade_create_text: "Submit",
            paygrade_create_submit: false,
            payGradeForm: {
                grade_name: "",
                currency: "",
                minimum_salary: "",
                maximum_salary: "",
            },
            paygrade_update_text: "Update",
            paygrade_update_submit: false,
            editPayGradeForm: {
                paygrade_id: "",
                grade_name: "",
                currency: "",
                minimum_salary: "",
                maximum_salary: "",
            },
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanyPayGrades'
        ]),
        currencies() {
            let all_currencies = currencies.map((currency) => {
                return currency.code + " (" + currency.name + ")";
            });
            return all_currencies;
        },
        searchedPayGrades() {
            let _this = this,
                result = _this.authCompanyPayGrades;
            if (_this.searchInputs.gradeName) {
                result = result.filter((grade) => grade.grade_name.toLowerCase().indexOf(_this.searchInputs.gradeName.toLowerCase()) !== -1);
            }
            return result;
        },
    },
    methods: {
        ...mapActions([
            'fetch_company_pay_grades'
        ]),
        launch_edit_pay_grade(pg) {
            this.editPayGradeForm.paygrade_id = pg.id;
            this.editPayGradeForm.grade_name = pg.grade_name;
            this.editPayGradeForm.currency = pg.currency;
            this.editPayGradeForm.minimum_salary = pg.minimum_salary;
            this.editPayGradeForm.maximum_salary = pg.maximum_salary;
            this.payGradeView = 2;
        },
        async paygrade_create() {
            let _this = this;
            const valid = await _this.$refs.form1.validate();
            if (!valid) {
                return;
            }
            _this.paygrade_create_text = "";
            _this.paygrade_create_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}addpaygrade`, {
                company_id: _this.authCompanyData.id,
                grade_name: _this.payGradeForm.grade_name,
                currency: _this.payGradeForm.currency,
                minimum_salary: _this.payGradeForm.minimum_salary,
                maximum_salary: _this.payGradeForm.maximum_salary,
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Pay grade added successfully",
                    });
                    _this.payGradeForm.grade_name = "";
                    _this.payGradeForm.currency = "";
                    _this.payGradeForm.minimum_salary = "";
                    _this.payGradeForm.maximum_salary = "";
                    _this.fetch_company_pay_grades();
                    _this.payGradeView = 0;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors,
                    }) ||
                        Toast.fire({
                            icon: "error",
                            title: errors
                                ? typeof errors === "string"
                                    ? errors
                                    : null || errors.grade_name
                                        ? errors.grade_name[0]
                                        : null || errors.currency
                                            ? errors.currency[0]
                                            : null || errors.minimum_salary
                                                ? errors.minimum_salary[0]
                                                : null || errors.maximum_salary
                                                    ? errors.maximum_salary[0]
                                                    : null
                                : null || error.response.data.error || error.response.data.message || "The pay grade details couldn't be added",
                        });
                })
                .then(function () {
                    _this.paygrade_create_submit = false;
                    _this.paygrade_create_text = "Submit";
                });
        },
        async paygrade_update() {
            let _this = this;
            const valid = await _this.$refs.form2.validate();
            if (!valid) {
                return;
            }
            _this.paygrade_update_text = "";
            _this.paygrade_update_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}editpaygrade`, {
                paygrade_id: _this.editPayGradeForm.paygrade_id,
                company_id: _this.authCompanyData.id,
                grade_name: _this.editPayGradeForm.grade_name,
                currency: _this.editPayGradeForm.currency,
                minimum_salary: _this.editPayGradeForm.minimum_salary,
                maximum_salary: _this.editPayGradeForm.maximum_salary,
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Pay grade updated successfully",
                    });
                    _this.editPayGradeForm.grade_name = "";
                    _this.editPayGradeForm.currency = "";
                    _this.editPayGradeForm.minimum_salary = "";
                    _this.editPayGradeForm.maximum_salary = "";
                    _this.fetch_company_pay_grades();
                    _this.payGradeView = 0;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors,
                    }) ||
                        Toast.fire({
                            icon: "error",
                            title: errors
                                ? typeof errors === "string"
                                    ? errors
                                    : null || errors.grade_name
                                        ? errors.grade_name[0]
                                        : null || errors.currency
                                            ? errors.currency[0]
                                            : null || errors.minimum_salary
                                                ? errors.minimum_salary[0]
                                                : null || errors.maximum_salary
                                                    ? errors.maximum_salary[0]
                                                    : null
                                : null || error.response.data.error || error.response.data.message || "The pay grade details couldn't be added",
                        });
                })
                .then(function () {
                    _this.paygrade_update_submit = false;
                    _this.paygrade_update_text = "Update";
                });
        },
        delete_pay_grade(pg) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this pay grade?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}deletepaygrade`, {
                            paygrade_id: pg.id,
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Pay grade deleted successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Pay grade couldn't be deleted",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_pay_grades();
                            });
                    }
                });
        },
    },
    mounted() {
        this.fetch_company_pay_grades();
    }
}
</script>

<style></style>