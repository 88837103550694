<template>
  <div class="container pl-0">
    <v-app>
      <div>
        <div style="padding: 40px 0px;">
          <v-breadcrumbs
            :items="breadcrumbs"
            large
            class="main-breadcrumbs"
            divider="//"
            style="display: inline-block;"
          ></v-breadcrumbs>

          <v-row class="container pl-6 pt-10">
            <v-col cols="7" md="7">
              <v-row>
                <v-col cols="3" md="3">
                  <img
                    style="width: 135px; height: 125px;"
                    class="img-fluid"
                    :src="imgUrl + currentEmployee.profile_image_url"
                    :alt="
                      currentEmployee.employee_fristname +
                        ' ' +
                        currentEmployee.employee_lastname +
                        ' Profile Image'
                    "
                  />
                </v-col>
                <v-col cols="5" md="5" class="pa-0 mt-4 pl-6">
                  <h3 class="card-h3 text-left mb-4">
                    {{ currentEmployee.employee_fristname }}
                    {{ currentEmployee.employee_lastname }}
                  </h3>
                  <p class="mb-0" style="font-size: 0.85rem;">
                    {{ currentEmployee.employee_position }}
                  </p>
                </v-col>

                <v-col cols="3" md="3" class="mt-1 text-center">
                  <v-chip
                    style="font-size: 12px; padding: 0px 12%;"
                    class="ma-0"
                    color="#3fc66d"
                    text-color="white"
                    label
                  >
                    FULL-TIME
                  </v-chip>
                  <v-btn
                    type="button"
                    x-large
                    dark
                    class="headButton"
                    style="height: 45px !important; font-size: 0.85rem !important; margin-top: 30%;"
                    @click="editing = !editing"
                  >
                    {{ editing ? "Stop Editing" : "Edit Profile" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" class="text-center">
              <ul class="fetch">
                <li
                  v-for="(item, index) in recruitmentFeatures"
                  :key="index"
                  class="nav-item"
                  style="display: inline-block;"
                  :class="index === active ? 'active' : ''"
                  @click="linkClicked(index)"
                >
                  <a class="nav-link">{{ item }}</a>
                </li>
              </ul>
            </v-col>
          </v-row>

          <component
            :editing="editing"
            :is="displayComp"
            @updated="updated"
          ></component>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeProfile from "@/components/EmployeeProfile";
import EmployeeAbsenceLeave from "@/components/EmployeeAbsenceLeave";
import EmployeeKPIs from "@/components/EmployeeKPIs";
import EmployeeBenefits from "@/components/EmployeeBenefits";
import { ASSETS_URL } from "@/main";
export default {
  data() {
    return {
      active: 0,
      editing: false,
      breadcrumbs: [
        {
          text: "All Apps",
          disabled: false,
          exact: true,
          to: { name: "ClientArea" }
        },
        {
          text: "Human Resources",
          disabled: false,
          exact: true,
          to: { name: "HumanResources" }
        },
        {
          text: "Employees",
          disabled: false,
          exact: true,
          to: { name: "Employees" }
        }
      ],
      recruitmentFeatures: ["Profile", "Absence/Leave", "KPI's", "Benefits"],
      displayComp: "employee-profile"
    };
  },
  computed: {
    ...mapGetters(["authCompanyEmployees"]),
    currentEmployee() {
      let _this = this;
      return this.authCompanyEmployees.filter(function(employee) {
        return employee.id === parseInt(_this.$route.params.employee_id);
      })[0];
    },
    imgUrl() {
      return ASSETS_URL;
    }
  },
  components: {
    "employee-profile": EmployeeProfile,
    "employee-absence-leave": EmployeeAbsenceLeave,
    "employee-kpis": EmployeeKPIs,
    "employee-benefits": EmployeeBenefits
  },
  methods: {
    updated() {
      this.editing = false;
    },
    linkClicked(index) {
      this.active = index;
      switch (index) {
        case 0:
          this.displayComp = "employee-profile";
          break;
        case 1:
          this.displayComp = "employee-absence-leave";
          break;
        case 2:
          this.displayComp = "employee-kpis";
          break;
        default:
          this.displayComp = "employee-benefits";
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.main-breadcrumbs li a {
  color: #111d5e !important;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: none;
}
</style>
<style lang="scss" scoped>
.card-h3 {
  font-size: 20px !important;
}
$primary-color: #069;
button.headButton {
  background-color: #fff !important;
  color: $primary-color;
  border: 1px solid $primary-color;
  width: 100%;
  height: 60px !important;
  border-radius: 7px;
  font-size: 15px !important;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: capitalize;
  box-shadow: none !important;
}
.nav-link {
  color: #111d5e;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
ul.fetch li.active {
  border-bottom: 2px solid $primary-color;
}

ul.fetch li:hover {
  border-bottom: 2px solid $primary-color;
}

ul.fetch li {
  margin: 0 20px;
}

ul.fetch li a {
  letter-spacing: -1px;
  font-weight: 500;
}
</style>
