<template>
  <div class="container">
    <v-app v-if="show" class="space-top mt-10">
      <h3 class="mt-10">Verification {{ successful ? "Successful" : "Failed" }}</h3>
      <p
      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pt-0 pb-5' : 'pt-10 pb-10'"
      class="underh3 text-center">
        {{
          successful
            ? "Email verification successful. Login to continue your setup."
            : "For some reason, your email verification didn’t go through."
        }}
        {{ 1 > 2 ? 'For some reason, your email verification didn’t go through. Try to resend the verification email.' : null }}
      </p>
      <div class="text-center">
        <svg
          v-if="successful"
          :width="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? '150' : '201'"
          :height="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? '150' : '201'"
          viewBox="0 0 201 201"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M141.444 50.1075V59.4825C141.444 66.3857 135.847 71.9825 128.944 71.9825H91.4438V103.233H103.438C108.263 92.2013 119.259 84.4825 132.069 84.4825C144.878 84.4825 155.878 92.2013 160.7 103.233H166.444V50.1075C166.444 43.2044 160.847 37.6075 153.944 37.6075C147.041 37.6075 141.444 43.2044 141.444 50.1075Z"
            fill="#C7C7C5"
          />
          <path
            d="M128.944 71.9825C135.847 71.9825 141.444 66.3857 141.444 59.4825V50.1075C141.444 43.2044 147.041 37.6075 153.944 37.6075H116.444V59.4825C116.444 66.3857 122.041 71.9825 128.944 71.9825Z"
            fill="#F2EFE9"
          />
          <path
            d="M91.4438 21.9825V71.9825H128.944C122.041 71.9825 116.444 66.3857 116.444 59.4825V37.6075V21.9825C116.444 15.0794 110.847 9.48254 103.944 9.48254C97.0407 9.48254 91.4438 15.0794 91.4438 21.9825Z"
            fill="#E0E0DE"
          />
          <path
            d="M28.9438 103.233H172.694C174.419 103.233 175.819 104.633 175.819 106.358V187.608C175.819 189.333 174.419 190.733 172.694 190.733H28.9438C27.2188 190.733 25.8188 189.333 25.8188 187.608V106.358C25.8188 104.633 27.2188 103.233 28.9438 103.233Z"
            fill="#4C63DE"
          />
          <path
            d="M175.819 158.258C175.378 157.545 174.875 156.864 174.256 156.245L166.444 148.433L151.163 133.151C152.934 129.229 153.944 124.892 153.944 120.308C153.944 114.001 152.059 108.142 148.847 103.233H96.5407C93.3282 108.142 91.4438 114.001 91.4438 120.308C91.4438 137.567 105.434 151.558 122.694 151.558C127.275 151.558 131.616 150.548 135.538 148.776L158.631 171.87C160.788 174.029 163.616 175.108 166.444 175.108C169.272 175.108 172.1 174.029 174.256 171.87C174.875 171.251 175.378 170.57 175.819 169.858V158.258Z"
            fill="#1C2F97"
          />
          <path
            d="M25.8188 103.233V165.733V175.108H85.1938C93.822 175.108 100.819 168.111 100.819 159.483V103.233H25.8188Z"
            fill="#1C2F97"
          />
          <path
            d="M22.6938 165.733H25.8188H82.0688C76.8907 165.733 72.6938 161.536 72.6938 156.358V146.983H16.4438H10.1938V153.233C10.1938 160.136 15.7907 165.733 22.6938 165.733Z"
            fill="#C7C7C5"
          />
          <path
            d="M28.9438 9.48254C22.0407 9.48254 16.4438 15.0794 16.4438 21.9825V146.983H72.6938V156.358C72.6938 161.536 76.8907 165.733 82.0688 165.733C87.247 165.733 91.4438 161.536 91.4438 156.358V103.233V71.9825V21.9825C91.4438 15.0794 97.0407 9.48254 103.944 9.48254H28.9438Z"
            fill="#E0E0DE"
          />
          <path
            d="M144.913 144.201L168.006 167.295C170.163 169.451 172.991 170.533 175.819 170.533C178.647 170.533 181.475 169.454 183.631 167.295C187.947 162.979 187.947 155.986 183.631 151.67L175.819 143.858L160.538 128.576C157.406 135.501 151.838 141.07 144.913 144.201Z"
            fill="#94A1EB"
          />
          <path
            d="M163.319 131.358L160.538 128.576C157.409 135.501 151.838 141.073 144.913 144.201L163.319 162.608V131.358Z"
            fill="#4C63DE"
          />
          <path
            d="M132.069 146.983C149.328 146.983 163.319 132.991 163.319 115.733C163.319 98.4736 149.328 84.4825 132.069 84.4825C114.81 84.4825 100.819 98.4736 100.819 115.733C100.819 132.991 114.81 146.983 132.069 146.983Z"
            fill="#94A1EB"
          />
          <path
            d="M132.069 140.733C145.876 140.733 157.069 129.54 157.069 115.733C157.069 101.925 145.876 90.7325 132.069 90.7325C118.262 90.7325 107.069 101.925 107.069 115.733C107.069 129.54 118.262 140.733 132.069 140.733Z"
            fill="#CBE6FE"
          />
          <path
            d="M82.0688 96.9825H66.4438C64.7157 96.9825 63.3188 95.5825 63.3188 93.8575C63.3188 92.1325 64.7157 90.7325 66.4438 90.7325H82.0688C83.797 90.7325 85.1938 92.1325 85.1938 93.8575C85.1938 95.5825 83.797 96.9825 82.0688 96.9825Z"
            fill="#C7C7C5"
          />
          <path
            d="M53.9438 96.9825H25.8188C24.0907 96.9825 22.6938 95.5825 22.6938 93.8575C22.6938 92.1325 24.0907 90.7325 25.8188 90.7325H53.9438C55.672 90.7325 57.0688 92.1325 57.0688 93.8575C57.0688 95.5825 55.672 96.9825 53.9438 96.9825Z"
            fill="#C7C7C5"
          />
          <path
            d="M32.0688 37.6075H25.8188C24.0907 37.6075 22.6938 36.2075 22.6938 34.4825C22.6938 32.7575 24.0907 31.3575 25.8188 31.3575H32.0688C33.797 31.3575 35.1938 32.7575 35.1938 34.4825C35.1938 36.2075 33.797 37.6075 32.0688 37.6075Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 109.483H25.8188C24.0907 109.483 22.6938 108.083 22.6938 106.358C22.6938 104.633 24.0907 103.233 25.8188 103.233H82.0688C83.797 103.233 85.1938 104.633 85.1938 106.358C85.1938 108.083 83.797 109.483 82.0688 109.483Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 121.983H25.8188C24.0907 121.983 22.6938 120.583 22.6938 118.858C22.6938 117.133 24.0907 115.733 25.8188 115.733H82.0688C83.797 115.733 85.1938 117.133 85.1938 118.858C85.1938 120.583 83.797 121.983 82.0688 121.983Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 134.483H25.8188C24.0907 134.483 22.6938 133.083 22.6938 131.358C22.6938 129.633 24.0907 128.233 25.8188 128.233H82.0688C83.797 128.233 85.1938 129.633 85.1938 131.358C85.1938 133.083 83.797 134.483 82.0688 134.483Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 50.1075H66.4438C64.7157 50.1075 63.3188 48.7075 63.3188 46.9825C63.3188 45.2575 64.7157 43.8575 66.4438 43.8575H82.0688C83.797 43.8575 85.1938 45.2575 85.1938 46.9825C85.1938 48.7075 83.797 50.1075 82.0688 50.1075Z"
            fill="#C7C7C5"
          />
          <path
            d="M53.9438 50.1075H25.8188C24.0907 50.1075 22.6938 48.7075 22.6938 46.9825C22.6938 45.2575 24.0907 43.8575 25.8188 43.8575H53.9438C55.672 43.8575 57.0688 45.2575 57.0688 46.9825C57.0688 48.7075 55.672 50.1075 53.9438 50.1075Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 62.6075H66.4438C64.7157 62.6075 63.3188 61.2075 63.3188 59.4825C63.3188 57.7575 64.7157 56.3575 66.4438 56.3575H82.0688C83.797 56.3575 85.1938 57.7575 85.1938 59.4825C85.1938 61.2075 83.797 62.6075 82.0688 62.6075Z"
            fill="#C7C7C5"
          />
          <path
            d="M53.9438 62.6075H25.8188C24.0907 62.6075 22.6938 61.2075 22.6938 59.4825C22.6938 57.7575 24.0907 56.3575 25.8188 56.3575H53.9438C55.672 56.3575 57.0688 57.7575 57.0688 59.4825C57.0688 61.2075 55.672 62.6075 53.9438 62.6075Z"
            fill="#C7C7C5"
          />
          <path
            d="M32.0688 84.4825H25.8188C24.0907 84.4825 22.6938 83.0825 22.6938 81.3575C22.6938 79.6325 24.0907 78.2325 25.8188 78.2325H32.0688C33.797 78.2325 35.1938 79.6325 35.1938 81.3575C35.1938 83.0825 33.797 84.4825 32.0688 84.4825Z"
            fill="#C7C7C5"
          />
          <path
            d="M125.819 131.358C125.019 131.358 124.219 131.051 123.609 130.442L114.234 121.067C113.012 119.845 113.012 117.87 114.234 116.648C115.456 115.426 117.431 115.426 118.653 116.648L125.819 123.814L145.484 104.148C146.706 102.926 148.681 102.926 149.903 104.148C151.125 105.37 151.125 107.345 149.903 108.567L128.028 130.442C127.419 131.051 126.619 131.358 125.819 131.358Z"
            fill="#FEBE07"
          />
        </svg>

        <svg
          v-else
          :width="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? '150' : '201'"
          :height="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? '150' : '201'"
          viewBox="0 0 201 201"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M141.444 50.1075V59.4825C141.444 66.3857 135.847 71.9825 128.944 71.9825H91.4438V103.233H103.438C108.263 92.2013 119.259 84.4825 132.069 84.4825C144.878 84.4825 155.878 92.2013 160.7 103.233H166.444V50.1075C166.444 43.2044 160.847 37.6075 153.944 37.6075C147.041 37.6075 141.444 43.2044 141.444 50.1075Z"
            fill="#C7C7C5"
          />
          <path
            d="M128.944 71.9825C135.847 71.9825 141.444 66.3857 141.444 59.4825V50.1075C141.444 43.2044 147.041 37.6075 153.944 37.6075H116.444V59.4825C116.444 66.3857 122.041 71.9825 128.944 71.9825Z"
            fill="#F2EFE9"
          />
          <path
            d="M91.4438 21.9825V71.9825H128.944C122.041 71.9825 116.444 66.3857 116.444 59.4825V37.6075V21.9825C116.444 15.0794 110.847 9.48254 103.944 9.48254C97.0407 9.48254 91.4438 15.0794 91.4438 21.9825Z"
            fill="#E0E0DE"
          />
          <path
            d="M28.9438 103.233H172.694C174.419 103.233 175.819 104.633 175.819 106.358V187.608C175.819 189.333 174.419 190.733 172.694 190.733H28.9438C27.2188 190.733 25.8188 189.333 25.8188 187.608V106.358C25.8188 104.633 27.2188 103.233 28.9438 103.233Z"
            fill="#4C63DE"
          />
          <path
            d="M175.819 158.258C175.378 157.545 174.875 156.864 174.256 156.245L166.444 148.433L151.163 133.151C152.934 129.229 153.944 124.892 153.944 120.308C153.944 114.001 152.059 108.142 148.847 103.233H96.5407C93.3282 108.142 91.4438 114.001 91.4438 120.308C91.4438 137.567 105.434 151.558 122.694 151.558C127.275 151.558 131.616 150.548 135.538 148.776L158.631 171.87C160.788 174.029 163.616 175.108 166.444 175.108C169.272 175.108 172.1 174.029 174.256 171.87C174.875 171.251 175.378 170.57 175.819 169.858V158.258Z"
            fill="#1C2F97"
          />
          <path
            d="M25.8188 103.233V165.733V175.108H85.1938C93.822 175.108 100.819 168.111 100.819 159.483V103.233H25.8188Z"
            fill="#1C2F97"
          />
          <path
            d="M22.6938 165.733H25.8188H82.0688C76.8907 165.733 72.6938 161.536 72.6938 156.358V146.983H16.4438H10.1938V153.233C10.1938 160.136 15.7907 165.733 22.6938 165.733Z"
            fill="#C7C7C5"
          />
          <path
            d="M28.9438 9.48254C22.0407 9.48254 16.4438 15.0794 16.4438 21.9825V146.983H72.6938V156.358C72.6938 161.536 76.8907 165.733 82.0688 165.733C87.247 165.733 91.4438 161.536 91.4438 156.358V103.233V71.9825V21.9825C91.4438 15.0794 97.0407 9.48254 103.944 9.48254H28.9438Z"
            fill="#E0E0DE"
          />
          <path
            d="M144.913 144.201L168.006 167.295C170.163 169.451 172.991 170.533 175.819 170.533C178.647 170.533 181.475 169.454 183.631 167.295C187.947 162.979 187.947 155.986 183.631 151.67L175.819 143.858L160.538 128.576C157.406 135.501 151.838 141.07 144.913 144.201Z"
            fill="#94A1EB"
          />
          <path
            d="M163.319 131.358L160.538 128.576C157.409 135.501 151.838 141.073 144.913 144.201L163.319 162.608V131.358Z"
            fill="#4C63DE"
          />
          <path
            d="M132.069 146.983C149.328 146.983 163.319 132.991 163.319 115.733C163.319 98.4736 149.328 84.4825 132.069 84.4825C114.81 84.4825 100.819 98.4736 100.819 115.733C100.819 132.991 114.81 146.983 132.069 146.983Z"
            fill="#94A1EB"
          />
          <path
            d="M82.0688 96.9825H66.4438C64.7157 96.9825 63.3188 95.5825 63.3188 93.8575C63.3188 92.1325 64.7157 90.7325 66.4438 90.7325H82.0688C83.797 90.7325 85.1938 92.1325 85.1938 93.8575C85.1938 95.5825 83.797 96.9825 82.0688 96.9825Z"
            fill="#C7C7C5"
          />
          <path
            d="M53.9438 96.9825H25.8188C24.0907 96.9825 22.6938 95.5825 22.6938 93.8575C22.6938 92.1325 24.0907 90.7325 25.8188 90.7325H53.9438C55.672 90.7325 57.0688 92.1325 57.0688 93.8575C57.0688 95.5825 55.672 96.9825 53.9438 96.9825Z"
            fill="#C7C7C5"
          />
          <path
            d="M32.0688 37.6075H25.8188C24.0907 37.6075 22.6938 36.2075 22.6938 34.4825C22.6938 32.7575 24.0907 31.3575 25.8188 31.3575H32.0688C33.797 31.3575 35.1938 32.7575 35.1938 34.4825C35.1938 36.2075 33.797 37.6075 32.0688 37.6075Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 109.483H25.8188C24.0907 109.483 22.6938 108.083 22.6938 106.358C22.6938 104.633 24.0907 103.233 25.8188 103.233H82.0688C83.797 103.233 85.1938 104.633 85.1938 106.358C85.1938 108.083 83.797 109.483 82.0688 109.483Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 121.983H25.8188C24.0907 121.983 22.6938 120.583 22.6938 118.858C22.6938 117.133 24.0907 115.733 25.8188 115.733H82.0688C83.797 115.733 85.1938 117.133 85.1938 118.858C85.1938 120.583 83.797 121.983 82.0688 121.983Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 134.483H25.8188C24.0907 134.483 22.6938 133.083 22.6938 131.358C22.6938 129.633 24.0907 128.233 25.8188 128.233H82.0688C83.797 128.233 85.1938 129.633 85.1938 131.358C85.1938 133.083 83.797 134.483 82.0688 134.483Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 50.1075H66.4438C64.7157 50.1075 63.3188 48.7075 63.3188 46.9825C63.3188 45.2575 64.7157 43.8575 66.4438 43.8575H82.0688C83.797 43.8575 85.1938 45.2575 85.1938 46.9825C85.1938 48.7075 83.797 50.1075 82.0688 50.1075Z"
            fill="#C7C7C5"
          />
          <path
            d="M53.9438 50.1075H25.8188C24.0907 50.1075 22.6938 48.7075 22.6938 46.9825C22.6938 45.2575 24.0907 43.8575 25.8188 43.8575H53.9438C55.672 43.8575 57.0688 45.2575 57.0688 46.9825C57.0688 48.7075 55.672 50.1075 53.9438 50.1075Z"
            fill="#C7C7C5"
          />
          <path
            d="M82.0688 62.6075H66.4438C64.7157 62.6075 63.3188 61.2075 63.3188 59.4825C63.3188 57.7575 64.7157 56.3575 66.4438 56.3575H82.0688C83.797 56.3575 85.1938 57.7575 85.1938 59.4825C85.1938 61.2075 83.797 62.6075 82.0688 62.6075Z"
            fill="#C7C7C5"
          />
          <path
            d="M53.9438 62.6075H25.8188C24.0907 62.6075 22.6938 61.2075 22.6938 59.4825C22.6938 57.7575 24.0907 56.3575 25.8188 56.3575H53.9438C55.672 56.3575 57.0688 57.7575 57.0688 59.4825C57.0688 61.2075 55.672 62.6075 53.9438 62.6075Z"
            fill="#C7C7C5"
          />
          <path
            d="M32.0688 84.4825H25.8188C24.0907 84.4825 22.6938 83.0825 22.6938 81.3575C22.6938 79.6325 24.0907 78.2325 25.8188 78.2325H32.0688C33.797 78.2325 35.1938 79.6325 35.1938 81.3575C35.1938 83.0825 33.797 84.4825 32.0688 84.4825Z"
            fill="#C7C7C5"
          />
          <path
            d="M106 158C139.137 158 166 131.137 166 98C166 64.8629 139.137 38 106 38C72.8629 38 46 64.8629 46 98C46 131.137 72.8629 158 106 158Z"
            fill="white"
          />
          <path
            d="M98.0343 72.5988L103.011 105.484C103.545 108.683 107.633 108.683 108.166 105.484L112.966 72.5988C113.855 59.8004 97.1455 59.8004 98.0343 72.5988Z"
            fill="#069"
          />
          <path
            d="M105.499 126.281C109.623 126.281 112.965 122.939 112.965 118.815C112.965 114.692 109.623 111.35 105.499 111.35C101.376 111.35 98.0337 114.692 98.0337 118.815C98.0337 122.939 101.376 126.281 105.499 126.281Z"
            fill="#069"
          />
        </svg>
        <v-col class="pt-10 mx-auto" md="4" sm="12">
          <v-btn 
          style="background: #0B54C0 !important; height: 40px; border-radius: 4px !important;" 
          @click="successful ? $router.push('/login') : $router.push('/')"
          type="button" 
          x-large 
          dark 
          class="new-create-submit w-100" 
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'w-100' : 'w-50'">
            {{ btnTxt }}
          </v-btn>
        </v-col>
      </div>
    </v-app>
    <v-app>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL } from "@/main";
import axios from "axios";
export default {
  data() {
    return {
      btnTxt: "Go Home",
      successful: false,
      show: false,
      overlay: true
    };
  },
  props: {
    ver_token: {
      type: String,
      required: true
    }
  },
  methods: {
    verify_account() {
      let _this = this;
      axios
        .get(`${BASE_URL}verify/${_this.ver_token}`)
        .then(() => {
          _this.successful = true;
          _this.btnTxt = "Login";
        })
        .catch(() => {
          _this.successful = false;
          _this.btnTxt = "Go Home";
        })
        .then(() => {
          // AFTER AXIOS CALL
          _this.show = true;
          _this.overlay = false;
        });
    }
  },
  mounted() {
    document.title = "Kylian ERP - Verify Account";
    this.verify_account();
  }
};
</script>

<style lang="scss" scoped>
$new-button-color: #2081FF;
.space-top {
  padding-top: 50px;
}
.underh3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}
.create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;
}
@media (max-width: 991px) {
  .create-submit {
    height: 45px !important;
  }
}
.new-create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: $new-button-color !important;
  text-transform: initial !important;
  font-weight: 600 !important;
  span {
    text-transform: initial !important;
  }
}
</style>
