<template>
  <div>
    <!-- <SideMenu /> -->
    <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('travel-read'))"
      class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('travel-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                    employeeView ? "Your Travel Records" : "All Employees' Records"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('travel-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Records" : "Your Travel Records" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('travel-read'))"
            class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-3' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-2 row pb-0">
              <span v-if="employee_name.toLowerCase() != 'all travels'" class="col-lg-5 col-md-5 col-12">Travels for {{
                  employee_name
              }}</span>
              <span v-else class="col-lg-5 col-md-5 col-12">All Travels</span>
              <v-row class="col-md-7 col-lg-7 col-12" style="float: right;">
                <v-col :offset-lg="employee_name.toLowerCase() != 'all travels' ? null : '2'"
                  :offset-md="employee_name.toLowerCase() != 'all travels' ? null : '2'" cols="6" lg="3" md="3"
                  class="pt-0 text-center">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="dateRangeText"
                        placeholder="Select dates" solo dense readonly v-bind="attrs" v-on="on"
                        :messages="date_range.length == 0 ? '*Select date range' : date_range.length == 1 ? '*Please select an end date' : '*Showing results for ' + new Date(date_range[0]).toUTCString().substring(0, 16) + ' to ' + new Date(date_range[1]).toUTCString().substring(0, 16)">
                      </v-text-field>
                    </template>
                    <v-date-picker color="#069" range v-model="date_range" :min="
                      new Date(new Date().getTime() - 3.156e+10)
                        .toISOString()
                        .substring(0, 10)
                    " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" lg="4" md="4" class="pt-0 text-right">
                  <v-select class="remove-mb" :items="allEmployees" label="All Travels" dense solo
                    messages="*Select Employees" :full-width="true" v-model="employee_name"></v-select>
                </v-col>
                <v-col v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('travel-read'))"
                  cols="6" lg="3" xl="3" md="3" class="pt-0"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'">
                  <download-excel v-if="excel_filtered_travels.length >= 1" :data="json_data" :fields="json_fields"
                    :worksheet="employee_name.toLowerCase() != 'all travels' ? employee_name : 'All Employees'"
                    :name="'Travel History for ' + (employee_name.toLowerCase() != 'all travels' ? employee_name : 'All Employees') + '.xls'"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                    Export to Excel
                  </download-excel>
                  <button v-else style="cursor: disabled; opacity: 0.5;"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                    Export to Excel
                  </button>
                </v-col>
                <v-col
                  v-if="(employee_name.toLowerCase() != 'all travels' && userType == 1) || (userType != 1 && !employeeView && permissionIsAssigned('travel-read'))"
                  cols="6" lg="2" xl="2" md="2" class="pt-0">
                  <button @click="reset_filter()"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Reset
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div v-if="employee_name.toLowerCase() != 'all travels'" class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">Travel Means</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-left">From</th>
                          <th scope="col" class="text-left">To</th>
                          <th scope="col" class="text-left">Travel Date</th>
                          <th scope="col" class="text-left">Return Date</th>
                          <th scope="col" class="text-left">Currency</th>
                          <th scope="col" class="text-left">Proposed Amount</th>
                          <th scope="col" class="text-left">File Attachment</th>
                          <th scope="col" class="text-center">Status</th>
                          <th scope="col" class="text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="travel in filtered_travels" :key="travel.id">
                          <td class="text-left">
                            {{ get_employee_name(travel.employee_id) }}
                          </td>
                          <td class="text-left" scope="row">{{ travel.travel_means }}
                          </td>

                          <td class="text-left">
                            {{ travel.travel_purpose }}
                          </td>
                          <td class="text-left">
                            {{ travel.travel_from }}
                          </td>
                          <td class="text-left">
                            {{ travel.travel_to }}
                          </td>
                          <td class="text-left">
                            {{ new Date(travel.travel_date).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ new Date(travel.return_date).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ travel.currency }}
                          </td>
                          <td class="text-left" scope="row">₦{{ travel.propose_amount | number_with_commas }}
                          </td>
                          <td class="text-left" v-if="travel.fileurl">
                            <a :href="assets_url + travel.fileurl" target="_blank" rel="noopener noreferrer">
                              View Attachment
                            </a>
                          </td>
                          <td class="text-left" v-else><i>No file attachment</i></td>
                          <td v-if="travel.status == 0">
                            <span style="width: 60px;" class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="travel.status == 1">
                            <span style="width: 60px;" class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else>
                            <span style="width: 60px;" class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td style="padding-top: 25px;" class="employee-options">
                            <div class="row">
                              <i @click="view_comment(travel)" class="col-2 py-0 text-left fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('travel-approve')" @click="approve_travel(travel)"
                                class="col-2 py-0 text-left fal fa-check-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('travel-approve')" @click="disapprove_travel(travel)"
                                class="col-2 py-0 text-left fal fa-times-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">Travel Means</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-left">From</th>
                          <th scope="col" class="text-left">To</th>
                          <th scope="col" class="text-left">Travel Date</th>
                          <th scope="col" class="text-left">Return Date</th>
                          <th scope="col" class="text-left">Currency</th>
                          <th scope="col" class="text-left">Proposed Amount</th>
                          <th scope="col" class="text-left">File Attachment</th>
                          <th scope="col" class="text-center">Status</th>
                          <th scope="col" class="text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="travel in filtered_travels" :key="travel.id">
                          <td class="text-left">
                            {{ get_employee_name(travel.employee_id) }}
                          </td>
                          <td class="text-left" scope="row">{{ travel.travel_means }}
                          </td>

                          <td class="text-left">
                            {{ travel.travel_purpose }}
                          </td>
                          <td class="text-left">
                            {{ travel.travel_from }}
                          </td>
                          <td class="text-left">
                            {{ travel.travel_to }}
                          </td>
                          <td class="text-left">
                            {{ new Date(travel.travel_date).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ new Date(travel.return_date).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ travel.currency }}
                          </td>
                          <td class="text-left" scope="row">₦{{ travel.propose_amount | number_with_commas }}
                          </td>
                          <td class="text-left" v-if="travel.fileurl">
                            <a :href="assets_url + travel.fileurl" target="_blank" rel="noopener noreferrer">
                              View Attachment
                            </a>
                          </td>
                          <td class="text-left" v-else><i>No file attachment</i></td>
                          <td v-if="travel.status == 0">
                            <span style="width: 60px;" class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="travel.status == 1">
                            <span style="width: 60px;" class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else>
                            <span style="width: 60px;" class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td style="padding-top: 25px;" class="text-left employee-options">
                            <div class="row">
                              <i @click="view_comment(travel)" class="col-2 py-0 text-left fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('travel-approve')" @click="approve_travel(travel)"
                                class="col-2 py-0 text-left fal fa-check-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('travel-approve')" @click="disapprove_travel(travel)"
                                class="col-2 py-0 text-left fal fa-times-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('travel-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                    employeeView ? "Your Travel Records" : "All Employees' Records"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('travel-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Records" : "Your Travel Records" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-lg-5 pr-md-5">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != 1" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-2 row pb-0">
              <span class="col-lg-5 col-md-5 col-12">Your Travel Records</span>
              <!-- <span class="col-lg-5 col-md-5 col-12" v-else>Your Travel Records (No data to display)</span> -->
              <v-row class="col-md-7 col-lg-7 col-12">
                <v-col cols="5" lg="4" md="4"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
                  class="pt-0 pr-0">

                </v-col>
                <v-col cols="7" lg="4" md="4"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
                  class="pt-0 pr-0">

                </v-col>
                <v-col cols="6" lg="4" md="4" class="text-right pt-0">
                  <button @click="create_travel = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 mt-0 text-white">
                    Create Travel
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Travel Means</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-left">From</th>
                          <th scope="col" class="text-left">To</th>
                          <th scope="col" class="text-left">Travel Date</th>
                          <th scope="col" class="text-left">Return Date</th>
                          <th scope="col" class="text-left">Currency</th>
                          <th scope="col" class="text-left">Proposed Amount</th>
                          <th scope="col" class="text-left">File Attachment</th>
                          <th scope="col" class="text-center">Status</th>
                          <th scope="col" class="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="travel in filtered_travels" :key="travel.id">
                          <td class="text-left" scope="row">{{ travel.travel_means }}
                          </td>

                          <td class="text-left">
                            {{ travel.travel_purpose }}
                          </td>
                          <td class="text-left">
                            {{ travel.travel_from }}
                          </td>
                          <td class="text-left">
                            {{ travel.travel_to }}
                          </td>
                          <td class="text-left">
                            {{ new Date(travel.travel_date).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ new Date(travel.return_date).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ travel.currency }}
                          </td>
                          <td class="text-left" scope="row">₦{{ travel.propose_amount | number_with_commas }}
                          </td>
                          <td class="text-left" v-if="travel.fileurl">
                            <a :href="assets_url + travel.fileurl" target="_blank" rel="noopener noreferrer">
                              View Attachment
                            </a>
                          </td>
                          <td class="text-left" v-else><i>No file attachment</i></td>
                          <td v-if="travel.status == 0">
                            <span style="width: 60px;" class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="travel.status == 1">
                            <span style="width: 60px;" class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else>
                            <span style="width: 60px;" class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td style="padding-top: 25px;" class="employee-options">
                            <div class="row">
                              <i @click="(attachment_travel_id = travel.id, fileAttachmentDialog = true)"
                                class="col-3 py-0 text-center fal fa-file-plus"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i @click="view_comment(travel)" class="col-3 py-0 text-center fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="travel.status == 1" class="col-3 py-0 text-center fal fa-edit"
                                style="color: #069 !important; font-size: 125%; opacity: 0.3; cursor: default;"></i>
                              <i v-else @click="make_current_travel(travel)" class="col-3 py-0 text-center fal fa-edit"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="travel.status == 1" class="col-3 py-0 text-center fal fa-trash-alt"
                                style="color: #069 !important; font-size: 125%; opacity: 0.3; cursor: default;"></i>
                              <i v-else @click="delete_travel(travel)" class="col-3 py-0 text-center fal fa-trash-alt"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <v-dialog v-model="edit_travel" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Travel</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Travel Means <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="editFormData.travel_means" item-color="#069"
                      :items="['Air', 'Road', 'Rail', 'Sea']" placeholder="Select mode of travel" solo dense
                      class="mt-2" color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Travel Purpose <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.travel_purpose" placeholder="Enter travel purpose" solo
                      class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Travelling From <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.travel_from" placeholder="Enter where you're travelling from"
                      solo class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Travelling To <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.travel_to" placeholder="Enter where you're travelling to" solo
                      class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Travel Date <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="editFormData.travel_date" placeholder="Select your travel date" solo class="mt-2"
                          dense readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker" v-model="editFormData.travel_date" :min="
                        new Date(new Date().getTime() - 3.156e+10)
                          .toISOString()
                          .substring(0, 10)
                      " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save2"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Return Date <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="editFormData.return_date" placeholder="Select return date" solo class="mt-2" dense
                          readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker2" v-model="editFormData.return_date" :min="
                        new Date(new Date().getTime() - 3.156e+10)
                          .toISOString()
                          .substring(0, 10)
                      " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save2"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Currency <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select disabled :items="currencies" placeholder="Select a currency to pay in" dense solo
                      class="mt-2" :full-width="true" v-model="editFormData.currency"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Proposed Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.propose_amount"
                      placeholder="Enter the proposed amount for travel" solo class="mt-2" dense type="number">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="update_travel_submit ? null : update_travel()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ update_travel_text }}
                      <v-icon :style="
                        !update_travel_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="edit_travel = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="create_travel" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Travel</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Travel Means <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select v-model="formData.travel_means" item-color="#069" :items="['Air', 'Road', 'Rail', 'Sea']"
                      placeholder="Select mode of travel" solo dense class="mt-2" color="#069"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Travel Purpose <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.travel_purpose" placeholder="Enter travel purpose" solo class="mt-2"
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Travelling From <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.travel_from" placeholder="Enter where you're travelling from" solo
                      class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Travelling To <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.travel_to" placeholder="Enter where you're travelling to" solo
                      class="mt-2" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Travel Date <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="formData.travel_date" placeholder="Select your travel date" solo class="mt-2" dense
                          readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker" v-model="formData.travel_date" :min="
                        new Date(new Date().getTime() - 3.156e+10)
                          .toISOString()
                          .substring(0, 10)
                      " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Return Date <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="formData.return_date" placeholder="Select return date" solo class="mt-2" dense
                          readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker2" v-model="formData.return_date" :min="
                        new Date(new Date().getTime() - 3.156e+10)
                          .toISOString()
                          .substring(0, 10)
                      " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
" @change="save2"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Currency <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-select disabled :items="currencies" placeholder="Select a currency to pay in" dense solo
                      class="mt-2" :full-width="true" v-model="formData.currency"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="remove-hint">
                    <span style="color: #111d5e;">Proposed Amount <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.propose_amount" placeholder="Enter the proposed amount for travel"
                      solo class="mt-2" dense type="number"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="add_travel_submit ? null : add_travel()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_travel_text }}
                      <v-icon :style="
                        !add_travel_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="create_travel = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="flex-grow-1"></div>
    <v-dialog v-model="comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ approval ? 'Approve' : 'Disapprove' }} Travel with Comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Travel Comment <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="comment"
                      :placeholder="(userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('travel-read'))) ? 'Travel Comment' : 'No comment'"
                      solo class="mt-2" :disabled="userType != 1 ? true : false"></v-textarea>
                  </v-col>
                  <v-col v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('travel-read'))"
                    cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="add_comment_submit ? null : travel_action()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ add_comment_text }}
                      <v-icon :style="
                        !add_comment_submit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="travel_comment_dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Travel Comments</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-left">Date Sent</th>
                  <th class="text-left">Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="comment in all_travel_comments" :key="comment.id">
                  <td class="text-left">
                    {{ new Date(comment.created_at).toUTCString().substring(0, 25) }}
                  </td>
                  <td class="text-left">
                    {{ comment.comments }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="travel_comment_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileAttachmentDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">File Attachment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col style="cursor: pointer;" cols="12" sm="12" md="12" class="remove-hint">
                    <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                      label="Upload File Attachment" solo style="text-indent: 5px;" color="#069" class="fileAttachment"
                      messages="File Attachment">
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                    <button @click="fileAttachmentBtnSubmit ? null : upload_file_attachment()" style="width: 100%;"
                      type="button" class="btn btn-secondary m-1 ripple">
                      {{ fileAttachmentBtnText }}
                      <v-icon :style="
                        !fileAttachmentBtnSubmit
                          ? 'display: none;'
                          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                      ">
                        fas fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="fileAttachmentDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
import currencies from "@/plugins/currencies";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      attachment_travel_id: null,
      fileAttachmentBtnSubmit: false,
      fileAttachmentBtnText: 'Upload',
      fileAttachmentDialog: false,
      employeeView: true,
      date_range: [],
      menu: false,
      menu2: false,
      formData: {
        travel_means: "",
        travel_purpose: "",
        travel_from: "",
        travel_to: "",
        travel_date: "",
        return_date: "",
        currency: "NGN (Nigerian naira)",
        propose_amount: ""
      },
      editFormData: {
        id: null,
        travel_means: "",
        travel_purpose: "",
        travel_from: "",
        travel_to: "",
        travel_date: "",
        return_date: "",
        currency: "NGN (Nigerian naira)",
        propose_amount: ""
      },
      add_travel_text: "Submit",
      add_travel_submit: false,
      edit_travel: false,
      create_travel: false,
      update_travel_text: "Update",
      update_travel_submit: false,
      employee_name: "All Travels",
      employee: null,
      day_selected: "",
      view: "date",
      monthSelected: "",
      yearSelected: "",
      checkingDetails: null,
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      firstYear: "",
      allEmployees: [],
      allEmployees2: [],
      hospitals: [
        'Wuse General Hospital',
        'Cypress Eye Centre',
        'Garki General Hospital',
        'Maitama General Hospital',
        'Kings Care Hospital',
        'The Limi Hospital'
      ],
      current_travel: null,
      comment_dialog: false,
      approval: false,
      add_comment_text: 'Submit',
      add_comment_submit: false,
      comment: '',
      travel_comment_dialog: false,
      all_travel_comments: []
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "auth_company_travels",
      "auth_employee_travels",
      "authCompanyHolidays",
      "authCompanyGeoLocations",
      "authCompanyAttendanceConfig"
    ]),
    validGeoLocation() {
      const [value] = this.authCompanyGeoLocations;
      return value;
    },
    dateRangeText() {
      if (this.date_range) {
        return this.date_range.join(' ~ ')
      }
      return null
    },
    json_data: function () {
      return this.excel_filtered_travels;
    },
    json_fields: function () {
      let _this = this;
      if (_this.employee_name.toLowerCase() != 'all travels') {
        return {
          "Travel Means": "travel_means",
          "Purpose": "travel_purpose",
          "Travelling From": "travel_from",
          "Travelling To": "travel_to",
          "Travel Date": "travel_date",
          "Return Date": "return_date",
          "Currency": "currency",
          "Proposed Amount": "propose_amount",
          "Status": "status"
        }
      } else {
        return {
          "Employee": "employee",
          "Travel Means": "travel_means",
          "Purpose": "travel_purpose",
          "Travelling From": "travel_from",
          "Travelling To": "travel_to",
          "Travel Date": "travel_date",
          "Return Date": "return_date",
          "Currency": "currency",
          "Proposed Amount": "propose_amount",
          "Status": "status"
        }
      }

    },
    excel_filtered_travels() {
      let _this = this;
      return _this.filtered_travels.map(t => {
        return {
          employee: _this.get_employee_name(t.employee_id),
          travel_means: t.travel_means,
          travel_purpose: t.travel_purpose,
          travel_from: t.travel_from,
          travel_to: t.travel_to,
          travel_date: t.travel_date,
          return_date: t.return_date,
          currency: 'NGN (Nigerian naira)',
          propose_amount: t.propose_amount,
          status: t.status == 0 ? 'Pending' : t.status == 1 ? 'Approved' : 'Rejected'
        }
      })
    },
    filtered_travels() {
      let _this = this;
      let travels = _this.auth_company_travels;
      if (_this.userType == 1 || (_this.userType != 1 && !_this.employeeView && _this.permissionIsAssigned('travel-read'))) {
        if (_this.employee_id) {
          travels = _this.auth_company_travels.filter(travel => {
            return travel.employee_id == _this.employee_id
          });
        }
        if (_this.date_range.length == 2) {
          travels = travels.filter(t => {
            return (new Date(t.travel_date).getTime() >= new Date(_this.date_range[0]).getTime()) && (new Date(t.return_date).getTime() <= new Date(_this.date_range[1]).getTime())
          });
        }
        return travels;
      } else {
        travels = _this.auth_company_travels.filter(travel => {
          return travel.employee_id == _this.validEmployeeId
        });
        if (_this.date_range.length == 2) {
          travels = travels.filter(t => {
            return (new Date(t.travel_date).getTime() >= new Date(_this.date_range[0]).getTime()) && (new Date(t.return_date).getTime() <= new Date(_this.date_range[1]).getTime())
          });
        }
        return travels;
      }
    },
    currencies() {
      let all_currencies = currencies.map(currency => {
        return currency.code + ' (' + currency.name + ')';
      });
      return all_currencies;
    },
    assets_url() {
      return ASSETS_URL;
    },
    employee_id: function () {
      var t = this;
      return t.mixinBranchEmployees(t.$route.name) && t.employee_name.toLowerCase() != 'all travels'
        ? t.mixinBranchEmployees(t.$route.name).filter(function (e) {
          return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
        })[0].id
        : null;
    },
  },
  methods: {
    ...mapActions([
      "fetch_employee_travels",
      "fetch_company_travels",
      "fetch_all_holidays"
    ]),
    singleEmployeeObject(id) {
      let _this = this,
          result = 0;
      if (!id) {
          return result;
      }
      if (_this.mixinBranchEmployees(_this.$route.name).length >= 1) {
          result = _this.mixinBranchEmployees(_this.$route.name).filter((te) => te.id == id)[0];
      }
      return result;
    },
    datesBetweenTravelStartAndEnd(travelDetails) {
      const _this = this;
      const travelStartDate = new Date(travelDetails.travel_date || "1");
      const travelEndDate = new Date(travelDetails.return_date || "1").addHours(1).addMinutes(1);
      const travelDates = _this.getDatesBetweenDates(travelStartDate, travelEndDate);
      let workDates = travelDates.filter((date) => {
          const weekDay = _this.getDayOfWeek(date.getDay());
          return _this.mixinCompanyWorkDays.indexOf(weekDay) !== -1;
      });
      workDates = workDates.map((workDate) => workDate.toLocaleDateString());

      let holidayDates = [];
      _this.authCompanyHolidays.forEach((holiday) => {
          const holidayStartDate = new Date(holiday.start || "1");
          const holidayEndDate = new Date(holiday.end || "1").addHours(1).addMinutes(1);
          holidayDates = [...holidayDates, ..._this.getDatesBetweenDates(holidayStartDate, holidayEndDate)];
      });
      holidayDates = holidayDates.map((holidayDate) => holidayDate.toLocaleDateString());

      const holidayFreeWorkDates = workDates.filter((workDate) => {
          return holidayDates.indexOf(workDate) === -1;
      });

      return holidayFreeWorkDates.map(date => {
        const fixedDateArray = date.split('/');
        const fixedDate = `${fixedDateArray[1]}/${fixedDateArray[0]}/${fixedDateArray[2]}`;
        return fixedDate;
      });
    },
    upload_file_attachment() {
      let _this = this;
      _this.fileAttachmentBtnText = "";
      _this.fileAttachmentBtnSubmit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let formData = new FormData();
      formData.append(
        "travel_id",
        _this.attachment_travel_id
      );
      formData.append(
        "file",
        document.querySelector(".fileAttachment div div div input").files[0]
      );

      axios
        .post(`${BASE_URL}travelattachment`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Travel attachment uploaded successfully"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.fetch_company_travels();
          _this.fetch_employee_travels();
          _this.fileAttachmentDialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.file ? errors.file[0] : null) : null
              || error.response.data.error || error.response.data.message || 'File attachment could not be uploaded'
          });
        })
        .then(function () {
          _this.fileAttachmentBtnSubmit = false;
          _this.fileAttachmentBtnText = "Upload";
        });
    },
    reset_filter() {
      this.employee_name = 'All Travels';
      this.date_range = '';
    },
    add_travel_days_on_attendance(travel) {
      const _this = this, validTravelDays = _this.datesBetweenTravelStartAndEnd(travel);
      validTravelDays.forEach(travelDate => {
        const employeeObject = _this.singleEmployeeObject(travel.employee_id);

        let exDate = new Date(travelDate);
        let offset = '+1', utc = exDate.getTime() + (exDate.getTimezoneOffset() * 60000);
        let mainDate = new Date(utc + (3600000 * offset));

        let d = new Date(mainDate);

        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        month = month <= 9 ? "0" + month.toString() : month;
        let day = d.getDate();
        day = day <= 9 ? "0" + day.toString() : day;

        let today = `${year.toString()}-${month.toString()}-${day.toString()}`;

        let hour = d.getHours();
        hour = hour <= 9 ? "0" + hour.toString() : hour;
        let minutes = d.getMinutes();
        minutes = minutes <= 9 ? "0" + minutes.toString() : minutes;
        let seconds = d.getSeconds();
        seconds = seconds <= 9 ? "0" + seconds.toString() : seconds;

        let time = `${today} ${hour.toString()}:${minutes.toString()}:${seconds.toString()}`;

        axios.post(`${BASE_URL}checkin`, {
          company_id: _this.authCompanyData.id,
          employee_id: travel.employee_id,
          check_in: time,
          punch_in_note: `Travel: ${travel.travel_purpose}; From: ${travel.travel_from}; To: ${travel.travel_to};`,
          day: today,
          email: employeeObject.employee_email,
          ms_time: new Date(travelDate).toString(),
          latitude: _this.validGeoLocation ? _this.validGeoLocation.latitude : 0,
          longitude: _this.validGeoLocation ? _this.validGeoLocation.longitude : 0,
          address:  _this.validGeoLocation ? _this.validGeoLocation.address : '.'
        })
        .then(function () {})
        .catch(function () {})
        .then(function () {});
      });
    },
    travel_action() {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.add_comment_text = '';
      e.add_comment_submit = true;
      if (e.approval) {
        axios
          .post(`${BASE_URL}approvetravel`, {
            company_id: e.authCompanyData.id,
            employee_id: e.current_travel.employee_id,
            travel_id: e.current_travel.id,
            comment: e.comment
          })
          .then(function () {
            a.fire({ icon: "success", title: "Travel approved successfully" });
            e.comment = '';
            e.comment_dialog = false;
            e.add_travel_days_on_attendance(e.current_travel);
          })
          .catch(function () {
            a.fire({ icon: "error", title: "Travel couldn't be approved" });
          })
          .then(function () {
            e.fetch_company_travels(), e.fetch_employee_travels();
            e.add_comment_submit = false;
            e.add_comment_text = 'Submit';
          });
      } else {
        axios
          .post(`${BASE_URL}rejecttravel`, {
            company_id: e.authCompanyData.id,
            employee_id: e.current_travel.employee_id,
            travel_id: e.current_travel.id,
            comment: e.comment
          })
          .then(function () {
            a.fire({ icon: "success", title: "Travel rejected successfully" });
            e.comment = '';
            e.comment_dialog = false;
          })
          .catch(function () {
            a.fire({ icon: "error", title: "Travel couldn't be rejected" });
          })
          .then(function () {
            e.fetch_company_travels(), e.fetch_employee_travels();
            e.add_comment_submit = false;
            e.add_comment_text = 'Submit';
          });
      }
    },
    approve_travel(t) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to approve this travel?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Approve",
        })
        .then(function (s) {
          e.approval = true;
          e.current_travel = t;
          s.isConfirmed ? e.comment_dialog = true : '';
        });
    },
    disapprove_travel(t) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to reject this travel?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Reject",
        })
        .then(function (s) {
          e.approval = false;
          e.current_travel = t;
          s.isConfirmed ? e.comment_dialog = true : '';
        });
    },
    save: function (t) {
      this.$refs.menu.save(t);
    },
    save2: function (t) {
      this.$refs.menu2.save(t);
    },
    make_current_travel(travel) {
      this.editFormData.id = travel.id;
      this.editFormData.travel_means = travel.travel_means;
      this.editFormData.travel_purpose = travel.travel_purpose;
      this.editFormData.travel_from = travel.travel_from;
      this.editFormData.travel_to = travel.travel_to;
      this.editFormData.travel_date = travel.travel_date;
      this.editFormData.return_date = travel.return_date;
      this.editFormData.currency = 'NGN (Nigerian naira)';
      this.editFormData.propose_amount = travel.propose_amount;
      this.edit_travel = true;
    },
    view_comment(travel) {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchtravelcomment`, {
          company_id: travel.company_id,
          employee_id: travel.employee_id,
          travel_id: travel.id,
        })
        .then(function (response) {
          _this.all_travel_comments = response.data.comments
          _this.travel_comment_dialog = true;
        })
        .catch(function () {
          //
        })
        .then(function () {
          //
        });
    },
    hasHistory: function () {
      return window.history.length > 2;
    },
    delete_travel(t) {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.$swal
        .fire({
          title: "Are you sure you want to delete this travel?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Delete",
        })
        .then(function (s) {
          s.isConfirmed &&
            axios
              .post(`${BASE_URL}deletetravel`, {
                ids: [
                  {
                    id: t.id
                  }
                ]
              })
              .then(function () {
                a.fire({ icon: "success", title: "Travel deleted successfully" });
              })
              .catch(function () {
                a.fire({ icon: "error", title: "Travel couldn't be deleted" });
              })
              .then(function () {
                e.fetch_company_travels(), e.fetch_employee_travels();
              });
        });
    },
    add_travel: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      let form_data = new FormData();
      form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
      form_data.append("employee_id", t.authEmployeeDataForCompany.id);
      form_data.append("travel_means", t.formData.travel_means);
      form_data.append("travel_purpose", t.formData.travel_purpose);
      form_data.append("travel_from", t.formData.travel_from);
      form_data.append("travel_to", t.formData.travel_to);
      form_data.append("travel_date", t.formData.travel_date);
      form_data.append("return_date", t.formData.return_date);
      form_data.append("currency", 'NGN (Nigerian naira)');
      form_data.append("propose_amount", t.formData.propose_amount);

      t.add_travel_submit ||
        ((t.add_travel_text = ""),
          (t.add_travel_submit = !0),
          axios
            .post(`${BASE_URL}createtravel`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              e.fire({ icon: "success", title: "Travel request sent successfully" }),
                // ABOVE SHOULD FETCH ALL TRAVELS
                (t.formData.travel_means = ""),
                (t.formData.travel_purpose = ""),
                (t.formData.travel_from = ""),
                (t.formData.travel_to = ""),
                (t.formData.travel_date = ""),
                (t.formData.return_date = ""),
                (t.formData.currency = ""),
                (t.formData.propose_amount = "");
              t.fetch_employee_travels();
              t.fetch_company_travels();
              t.create_travel = false;
            })
            .catch(function () {
              e.fire({ icon: "error", title: "Travel request couldn't be sent" });
            })
            .then(function () {
              (t.add_travel_submit = !1), (t.add_travel_text = "Submit");
            }));
    },
    update_travel: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      let form_data = new FormData();
      form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
      form_data.append("employee_id", t.authEmployeeDataForCompany.id);
      form_data.append("travel_id", t.editFormData.id);
      form_data.append("travel_means", t.editFormData.travel_means);
      form_data.append("travel_purpose", t.editFormData.travel_purpose);
      form_data.append("travel_from", t.editFormData.travel_from);
      form_data.append("travel_to", t.editFormData.travel_to);
      form_data.append("travel_date", t.editFormData.travel_date);
      form_data.append("return_date", t.editFormData.return_date);
      form_data.append("currency", 'NGN (Nigerian naira)');
      form_data.append("propose_amount", t.editFormData.propose_amount);

      t.update_travel_submit ||
        ((t.update_travel_text = ""),
          (t.update_travel_submit = !0),
          axios
            .post(`${BASE_URL}edittravel`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              e.fire({ icon: "success", title: "Travel has been updated successfully" }), t.fetch_employee_travels(), t.fetch_company_travels(), (t.edit_travel = !1);
            })
            .catch(function () {
              e.fire({ icon: "error", title: "Travel couldn't be updated" });
            })
            .then(function () {
              (t.update_travel_submit = !1), (t.update_travel_text = "Update");
            }));
    },
    get_employee_name: function (t) {
      var e = this;
      if (e.mixinBranchEmployees(e.$route.name)) {
        var a = e.mixinBranchEmployees(e.$route.name).filter(function (e) {
          return e.id == t;
        })[0];
        return a ? a.employee_fristname + " " + a.employee_lastname : 'Not found';
      }
      return "";
    }
  },
  mounted() {
    document.title = "Kylian ERP - Travel Management";
    1 != this.userType, (document.title = "Kylian ERP - Travel Management");
    var t = this;
    t.allEmployees.push('All Travels')
    t.userType &&
      t.mixinBranchEmployees(t.$route.name).forEach(function (e) {
        t.allEmployees.push(e.employee_fristname + " " + e.employee_lastname), t.allEmployees2.push(e.employee_fristname + " " + e.employee_lastname);
      });
    this.fetch_company_travels();
    this.fetch_employee_travels();
    this.fetch_all_holidays();
  },
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
