var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-pane fade",attrs:{"id":"list-settings11","role":"tabpanel","aria-labelledby":"list-settings-list11"}},[_c('h4',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6",staticStyle:{"align-self":"center"}},[_vm._v(" User Roles ")]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"item-text":"department_name","item-value":"id","items":[
                  {
                    department_name: 'All departments', 
                    id: ''
                  } ].concat( _vm.authCompanyDepartments
                ),"label":"Selected Departments","solo":"","dense":""},model:{value:(_vm.filters.department),callback:function ($$v) {_vm.$set(_vm.filters, "department", $$v)},expression:"filters.department"}})],1)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"item-text":"committee_name","item-value":"id","items":[
                  {
                    committee_name: 'All committees', 
                    id: ''
                  } ].concat( _vm.authCompanyCommittees
                ),"label":"Selected Committees","solo":"","dense":""},model:{value:(_vm.filters.committee),callback:function ($$v) {_vm.$set(_vm.filters, "committee", $$v)},expression:"filters.committee"}})],1)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"px-2 pe-auto",staticStyle:{"cursor":"pointer"},attrs:{"label":"Search...","solo":"","dense":"","hide-details":"true","placeholder":"Search..."},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],1)],1)],1)])])])]),_c('div',{staticClass:"main-content mt-0 px-0"},[_c('div',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.filteredEmployees),function(employee){return _c('tr',{key:employee.id},[_c('td',{staticClass:"text-left",staticStyle:{"vertical-align":"middle"}},[_vm._v(" "+_vm._s(employee.employee_fristname)+" "+_vm._s(employee.employee_lastname)+" ")]),_c('td',{staticClass:"text-left",staticStyle:{"vertical-align":"middle"}},[_vm._v(" "+_vm._s(_vm.getRoleName(employee.user_type))+" ")]),_c('td',{staticClass:"employee-options text-center"},[_c('i',{staticClass:"col-3 py-4 text-center fal fa-edit",staticStyle:{"color":"#069 !important","font-size":"125%"},style:(employee.user_type != 1
              ? 'cursor: pointer;'
              : 'opacity: 0.5;'),on:{"click":function($event){employee.user_type != 1
                ? _vm.launch_update_role(employee)
                : null}}})])])}),0)])])])]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.update_role_dialog),callback:function ($$v) {_vm.update_role_dialog=$$v},expression:"update_role_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Update User Role")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"item-color":"#069","items":_vm.role_names,"placeholder":"New Role","solo":"","dense":"","color":"#069"},model:{value:(_vm.change_role_to),callback:function ($$v) {_vm.change_role_to=$$v},expression:"change_role_to"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","offset-md":"3","offset-sm":"3","sm":"6","md":"6"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.update_role_submit ? null : _vm.update_role()}}},[_vm._v(" "+_vm._s(_vm.update_role_text)+" "),_c('v-icon',{style:(!_vm.update_role_submit
              ? 'display: none;'
              : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.update_role_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Employee Name ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Role")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"110px"},attrs:{"scope":"col"}},[_vm._v(" Update Role ")])])])}]

export { render, staticRenderFns }