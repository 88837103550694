<template>
  <div>
    <!-- <SideMenu /> -->
    <Home />
    <div class="search-ui">
      <div class="search-header">
        <img src="/assets/images/logo.png" alt="" class="logo" />
        <button class="search-close btn btn-icon bg-transparent float-right mt-2">
          <i class="i-Close-Window text-22 text-muted"></i>
        </button>
      </div>
      <input type="text" placeholder="Type here" class="search-input" autofocus />
      <div class="search-title">
        <span class="text-muted">Search results</span>
      </div>
      <div class="search-results list-horizontal">
        <div class="list-item col-md-12 p-0">
          <div class="card o-hidden flex-row mb-4 d-flex">
            <div class="list-thumb d-flex">
              <!-- TUMBNAIL -->
              <img src="/assets/images/products/headphone-1.jpg" alt="" />
            </div>
            <div class="flex-grow-1 pl-2 d-flex">
              <div
                class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                <!-- OTHER DATA -->
                <a href="#" class="w-40 w-sm-100">
                  <div class="item-title">Headphone 1</div>
                </a>
                <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                <p class="m-0 text-muted text-small w-15 w-sm-100">
                  $300
                  <del class="text-secondary">$400</del>
                </p>
                <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                  <span class="badge badge-danger">Sale</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="list-item col-md-12 p-0">
          <div class="card o-hidden flex-row mb-4 d-flex">
            <div class="list-thumb d-flex">
              <!-- TUMBNAIL -->
              <img src="/assets/images/products/headphone-2.jpg" alt="" />
            </div>
            <div class="flex-grow-1 pl-2 d-flex">
              <div
                class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                <!-- OTHER DATA -->
                <a href="#" class="w-40 w-sm-100">
                  <div class="item-title">Headphone 1</div>
                </a>
                <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                <p class="m-0 text-muted text-small w-15 w-sm-100">
                  $300
                  <del class="text-secondary">$400</del>
                </p>
                <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                  <span class="badge badge-primary">New</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="list-item col-md-12 p-0">
          <div class="card o-hidden flex-row mb-4 d-flex">
            <div class="list-thumb d-flex">
              <!-- TUMBNAIL -->
              <img src="/assets/images/products/headphone-3.jpg" alt="" />
            </div>
            <div class="flex-grow-1 pl-2 d-flex">
              <div
                class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                <!-- OTHER DATA -->
                <a href="#" class="w-40 w-sm-100">
                  <div class="item-title">Headphone 1</div>
                </a>
                <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                <p class="m-0 text-muted text-small w-15 w-sm-100">
                  $300
                  <del class="text-secondary">$400</del>
                </p>
                <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                  <span class="badge badge-primary">New</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="list-item col-md-12 p-0">
          <div class="card o-hidden flex-row mb-4 d-flex">
            <div class="list-thumb d-flex">
              <!-- TUMBNAIL -->
              <img src="/assets/images/products/headphone-4.jpg" alt="" />
            </div>
            <div class="flex-grow-1 pl-2 d-flex">
              <div
                class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                <!-- OTHER DATA -->
                <a href="#" class="w-40 w-sm-100">
                  <div class="item-title">Headphone 1</div>
                </a>
                <p class="m-0 text-muted text-small w-15 w-sm-100">Gadget</p>
                <p class="m-0 text-muted text-small w-15 w-sm-100">
                  $300
                  <del class="text-secondary">$400</del>
                </p>
                <p class="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                  <span class="badge badge-primary">New</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGINATION CONTROL -->
      <div class="col-md-12 mt-5 text-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination d-inline-flex">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <v-app>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import SideMenu from "../../components/dashboard/SideMenu";
import Home from "../../components/dashboard/Home";
import { BASE_URL } from "@/main";
import axios from "axios";
export default {
  components: {
    // SideMenu,
    Home
  },
  data() {
    return {
      calledAPIs: 0,
      btnTxt: "Take Tour",
      isLoading: false,
      apps: [],
      overlay: true
    };
  },
  watch: {
    calledAPIs(count) {
      let _this = this;
      if (count == 102) {
        _this.overlay = false;
        if (_this.$route.query.addon == 'first-time') {
          _this.$router.push({ name: 'Settings' });
        }
      }
    },
    authCompanyServices(newValue) {
      if (newValue && this.authCompanyEmployees.length >= 1 && this.userType != 1) {
        this.overlay = false;
        if (this.humanResources.length >= 1) {
          this.apps.push({
            icon: "mdi-account-group-outline",
            header: "Human Resources",
            linkName: "HumanResources"
          });
        }
        if (this.accounts.length >= 1) {
          this.apps.push({
            icon: "mdi-cash",
            header: "Accounts",
            linkName: "Accounts"
          });
        }
      }
    },
    // authCompanyEmployees(newValue) {
    //   if (newValue && this.authCompanyServices.length >= 1) {
    //     this.overlay = false;
    //   }
    // }
  },
  methods: {
    ...mapActions([
      "fetch_active_company_employees",
      "fetch_company_appraisals",
      "fetch_company_departments",
      "fetch_company_KRAs",
      "fetch_company_goals",
      "fetch_candidates",
      "fetch_company_assets",
      "fetch_all_approved_leave",
      "fetch_all_leave",
      "fetch_all_leave_type",
      "fetch_all_asset_types",
      "fetch_all_check_in",
      "fetch_all_vendors",
      "fetch_all_company_announcements",
      "fetch_all_company_employee_announcements",
      "fetch_all_company_events",
      "fetch_all_company_billing",
      "fetch_company_attendance_configuration",
      "fetch_employee_timesheets",
      "fetch_all_timesheets",
      "fetch_all_roles",
      "fetch_available_services",
      "fetch_company_file_types",
      "fetch_all_company_files",
      "fetch_all_medicals",
      "fetch_all_company_jobs",
      "fetch_employee_travels",
      "fetch_company_travels",
      "fetch_all_countries",
      "fetch_all_states",
      "fetch_all_applicants",
      "fetch_all_check_in_all_time",
      "fetch_all_designations",
      "fetch_all_shifts",
      "fetch_all_employee_shifts",
      "fetch_all_company_projects",
      "fetch_all_company_tasks",
      "fetch_all_holidays",
      "fetch_all_permissions",
      "fetch_assigned_permissions",
      "fetch_all_pay_calendar",
      "fetch_all_employees_pay_calendar",
      "fetch_all_fixed_allowances",
      "fetch_all_fixed_deductions",
      "fetch_all_employees_tax",
      "fetch_all_company_payrun",
      "fetch_all_flexible_allowances",
      "fetch_all_flexible_deductions",
      "fetch_all_employee_payslips",
      "fetch_all_payroll_configuration",
      "fetch_all_employees_assigned_to_projects",
      "fetch_all_company_hospitals",
      "fetch_all_company_loans",
      "fetch_all_liquidation_requests",
      "fetch_company_geo_locations",
      "fetch_company_pay_grades",
      "fetch_company_pay_grade_histories",
      "fetch_company_job_documents",
      "fetch_company_grade_levels",
      "fetch_company_emoluments",
      "fetch_statutory_deductions",
      "fetch_company_statutory_deductions",
      "fetch_company_tasksheets",
      "fetch_employee_tasksheets",
      "fetch_company_logs",
      "fetch_company_requisitions",
      "fetch_company_requisition_types",
      "fetch_company_dockets",
      "fetch_company_department_employees",
      "fetch_company_committees",
      "fetch_company_employee_committees",
      "fetch_company_employee_dockets",
      "fetch_company_employees_in_goals",
      "fetch_company_employees_in_appraisals",
      "fetch_company_questionnaires",
      "fetch_employee_assigned_goals",
      "fetch_company_goals_in_appraisals",
      "fetch_employee_appraisals",
      "fetch_company_employee_kras",
      "fetch_company_reviewers",
      "fetch_company_questionnaire_responses",
      "fetch_employee_appraisal_reviews",
      "fetch_employee_goal_reviews",
      "fetch_employee_reporters",
      "fetch_company_feedbacks",
      "fetch_company_appraisal_feedbacks",
      "fetch_company_loan_repayments",
      "fetch_employee_loan_repayments",
      "fetch_employee_skill_sets",
      "fetch_company_skill_sets",
      "fetch_company_branches",
      "fetch_banks",
      "fetch_company_subaccount",
      "fetch_company_transactions",
      "fetch_company_attendance_deduction_settings",
      "fetch_company_exit_requests",
      "fetch_employee_exit_requests",
      "fetch_company_exit_checklists",
      "fetch_company_course_categories",
      "fetch_company_workflows",
      "fetch_company_approval_levels"
    ]),
    sendLoginNotification() {
      if (!this.checkedIn) {
        let _this = this;
        axios
          .post(`${BASE_URL}checkinemailnotification`, {
            employee_name:
              _this.authEmployeeDataForCompany.employee_fristname +
              " " +
              _this.authEmployeeDataForCompany.employee_lastname,
            employee_email: _this.authEmployeeDataForCompany.employee_email,
            company_id: _this.authEmployeeDataForCompany.company_id,
            employee_id: _this.authEmployeeDataForCompany.id
          })
          .then(function () {
            _this.fetch_all_company_employee_announcements();
          })
          .catch(function (error) {
            console.log(error.response);
          })
          .then(function () {
            //
          });
      }
    }
  },
  computed: {
    ...mapGetters([
      "userType",
      "isAuth",
      "authUserData",
      "authCompanyData",
      "authCompanyServices",
      "humanResources",
      "accounts",
      "checkedIn",
      "authEmployeeDataForCompany",
      "authCompanyEmployees",
      "authCompanyAllBilling"
    ])
  },
  mounted() {
    let _this = this;

    const date = new Date();
    const year = date.getFullYear();
    const month = _this.monthNames[date.getMonth()];

    setTimeout(() => {
      if (_this.overlay == true) {
        _this.$swal.fire({
          title: "Network Anomaly",
          text: "Please check your internet connection and refresh the page",
          icon: "warning",
          confirmButtonColor: "#3085d6",
        }).then(() => {
          _this.overlay = false;
        })
      }
    }, 60000);
    if (!_this.checkedIn && _this.userType != 1) {
      setTimeout(() => {
        _this.sendLoginNotification();
      }, 600000);
    }
    if (_this.userType == 1) {
      if (!_this.authCompanyServices.length) {
        _this.overlay = true;
      } else {
        if (_this.humanResources.length >= 1) {
          _this.apps.push({
            icon: "mdi-account-group-outline",
            header: "Human Resources",
            linkName: "HumanResources"
          });
        }
        if (_this.accounts.length >= 1) {
          _this.apps.push({
            icon: "mdi-cash",
            header: "Accounts",
            linkName: "Accounts"
          });
        }
      }
    } else {
      if (!_this.authCompanyServices) {
        _this.overlay = true;
      }
    }
    if (_this.authCompanyAllBilling?.companyservices) {
      _this.overlay = false;
    }
    _this.fetch_assigned_permissions().then(() => _this.calledAPIs += 1);
    _this.fetch_all_check_in_all_time().then(() => _this.calledAPIs += 1);
    _this.fetch_all_check_in().then(() => _this.calledAPIs += 1);
    _this.fetch_company_pay_grades().then(() => _this.calledAPIs += 1);
    _this.fetch_all_approved_leave().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_announcements().then(() => _this.calledAPIs += 1);
    _this.fetch_company_appraisals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_goals().then(() => _this.calledAPIs += 1);
    _this.fetch_candidates().then(() => _this.calledAPIs += 1);
    _this.fetch_company_assets().then(() => _this.calledAPIs += 1);
    _this.fetch_all_asset_types().then(() => _this.calledAPIs += 1);
    _this.fetch_all_vendors().then(() => _this.calledAPIs += 1);
    _this.fetch_all_timesheets({ month, year }).then(() => _this.calledAPIs += 1);
    _this.fetch_available_services().then(() => _this.calledAPIs += 1);
    _this.fetch_company_file_types().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_jobs().then(() => _this.calledAPIs += 1);
    _this.fetch_company_travels().then(() => _this.calledAPIs += 1);
    _this.fetch_all_applicants().then(() => _this.calledAPIs += 1);

    _this.fetch_company_departments().then(() => _this.calledAPIs += 1);
    _this.fetch_all_leave().then(() => _this.calledAPIs += 1);
    _this.fetch_active_company_employees().then(() => _this.calledAPIs += 1);
    _this.fetch_all_leave_type().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_events().then(() => _this.calledAPIs += 1);
    _this.fetch_all_roles().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_billing().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_files().then(() => _this.calledAPIs += 1);
    _this.fetch_all_medicals().then(() => _this.calledAPIs += 1);
    _this.fetch_all_countries().then(() => _this.calledAPIs += 1);
    _this.fetch_all_states().then(() => _this.calledAPIs += 1);
    _this.fetch_all_designations().then(() => _this.calledAPIs += 1);
    _this.fetch_all_shifts().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employee_shifts().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_projects().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_tasks().then(() => _this.calledAPIs += 1);
    _this.fetch_all_holidays().then(() => _this.calledAPIs += 1);
    _this.fetch_company_attendance_configuration().then(() => _this.calledAPIs += 1);
    _this.fetch_all_permissions().then(() => _this.calledAPIs += 1);

    _this.fetch_all_company_employee_announcements().then(() => _this.calledAPIs += 1);

    _this.fetch_employee_timesheets({ month, year }).then(() => _this.calledAPIs += 1);
    _this.fetch_employee_travels().then(() => _this.calledAPIs += 1);

    _this.fetch_company_KRAs().then(() => _this.calledAPIs += 1);
    _this.fetch_all_pay_calendar().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employees_pay_calendar().then(() => _this.calledAPIs += 1);
    _this.fetch_all_fixed_allowances().then(() => _this.calledAPIs += 1);
    _this.fetch_all_fixed_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employees_tax().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_payrun().then(() => _this.calledAPIs += 1);
    _this.fetch_all_flexible_allowances().then(() => _this.calledAPIs += 1);
    _this.fetch_all_flexible_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employee_payslips().then(() => _this.calledAPIs += 1);
    _this.fetch_all_payroll_configuration().then(() => _this.calledAPIs += 1);
    _this.fetch_all_employees_assigned_to_projects().then(() => _this.calledAPIs += 1);

    _this.fetch_all_company_hospitals().then(() => _this.calledAPIs += 1);
    _this.fetch_all_company_loans().then(() => _this.calledAPIs += 1);
    _this.fetch_company_job_documents().then(() => _this.calledAPIs += 1);
    _this.fetch_all_liquidation_requests().then(() => _this.calledAPIs += 1);

    _this.fetch_company_pay_grade_histories().then(() => _this.calledAPIs += 1);

    _this.fetch_company_geo_locations().then(() => _this.calledAPIs += 1);
    _this.fetch_company_grade_levels().then(() => _this.calledAPIs += 1);
    _this.fetch_company_emoluments().then(() => _this.calledAPIs += 1);
    _this.fetch_statutory_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_statutory_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_statutory_deductions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_tasksheets().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_tasksheets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_logs().then(() => _this.calledAPIs += 1);
    _this.fetch_company_requisitions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_requisition_types().then(() => _this.calledAPIs += 1);
    _this.fetch_company_dockets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_department_employees().then(() => _this.calledAPIs += 1);
    _this.fetch_company_committees().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employee_committees().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employee_dockets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employees_in_goals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employee_kras().then(() => _this.calledAPIs += 1);
    _this.fetch_company_employees_in_appraisals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_questionnaires().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_assigned_goals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_goals_in_appraisals().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_appraisals().then(() => _this.calledAPIs += 1);
    _this.fetch_company_reviewers().then(() => _this.calledAPIs += 1);
    _this.fetch_company_questionnaire_responses().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_appraisal_reviews().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_goal_reviews().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_reporters().then(() => _this.calledAPIs += 1);
    _this.fetch_company_feedbacks().then(() => _this.calledAPIs += 1);
    _this.fetch_company_appraisal_feedbacks().then(() => _this.calledAPIs += 1);
    _this.fetch_company_loan_repayments().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_loan_repayments().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_skill_sets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_skill_sets().then(() => _this.calledAPIs += 1);
    _this.fetch_company_branches().then(() => _this.calledAPIs += 1);
    _this.fetch_banks().then(() => _this.calledAPIs += 1);
    _this.fetch_company_subaccount().then(() => _this.calledAPIs += 1);
    _this.fetch_company_transactions().then(() => _this.calledAPIs += 1);
    _this.fetch_company_attendance_deduction_settings().then(() => _this.calledAPIs += 1);
    _this.fetch_company_exit_requests().then(() => _this.calledAPIs += 1);
    _this.fetch_employee_exit_requests().then(() => _this.calledAPIs += 1);
    _this.fetch_company_exit_checklists().then(() => _this.calledAPIs += 1);
    _this.fetch_company_course_categories().then(() => _this.calledAPIs += 1);
    _this.fetch_company_workflows().then(() => _this.calledAPIs += 1);
    _this.fetch_company_approval_levels().then(() => _this.calledAPIs += 1);
  }
};
</script>

<style lang="scss" scoped>
.mdi-all-apps-size {
  font-size: 90px !important;
}

p {
  color: #111d5e;
  font-weight: 400;
}

.jumbotron {
  border-radius: 8px;
  background: #dbe0f975; //#111d5e75;
  // box-shadow: 0px 0px 50px 0px #00000014 !important;
  padding-left: 7%;
}

h1.white-h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: -2.5010533332824707px;
  text-align: left;
  color: #111d5e !important;
}

p.sub-h1 {
  color: #111d5e !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.create-submit {
  font-weight: 600;
  width: 40%;
  margin-right: 5px;
  height: 60px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial;
}

.right-card {
  padding: 60px 0px;
  box-shadow: 0px 0px 50px 0px #00000014 !important;
  border-radius: 10px !important;
}

.shift-left {
  margin-left: 5%;
}

.card-p-icon {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: #111d5ea3 !important;
}

.card-p-icon i {
  color: #111d5ea3 !important;
}

.small-card-h3 {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
}
</style>