<template>
  <div class="container">
    <v-app>
      <div class="mt-5 container">
        <h5 class="text-left mb-7">Notifications</h5>
        <v-card
          v-for="(notification, index) in notifications"
          :key="index"
          class="right-card pt-0 pb-0 mb-5"
        >
          <v-row>
            <v-col cols="9" md="9">
              <v-row style="margin-top: 4px;">
                <v-col cols="1" md="1" class="text-right">
                  <v-icon v-if="notification.type == 'info'" color="#2196F3">
                    mdi-information
                  </v-icon>
                  <v-icon
                    v-else-if="notification.type == 'warning'"
                    color="#FFCE31"
                  >
                    mdi-alert
                  </v-icon>
                  <v-icon
                    v-else-if="notification.type == 'success'"
                    color="#55C400"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="#069"> mdi-close-circle </v-icon>
                </v-col>
                <v-col cols="11" md="11" style="margin-top: 2px;">
                  <p class="my-auto" style="font-size: 14px;">
                    {{ notification.message }}
                  </p>
                </v-col>
              </v-row>
            </v-col>

            <!-- IF CHOICE IS TRUE -->
            <v-col cols="3" md="3" class="text-center">
              <v-row v-if="notification.choice == 2">
                <v-col cols="5" md="5">
                  <v-btn
                    type="button"
                    x-large
                    dark
                    class="create-submit-notifications"
                  >
                    Accept
                  </v-btn>
                </v-col>
                <v-col cols="5" md="5">
                  <v-btn type="button" x-large dark class="headButton">
                    Reject
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else-if="notification.choice == 1">
                <v-col cols="5" md="5">
                  <v-btn
                    type="button"
                    x-large
                    dark
                    class="create-submit-notifications"
                  >
                    Review
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else> </v-row>
            </v-col>
            <!-- END CHOICE IS TRUE -->
          </v-row>
        </v-card>
        <v-row>
          <v-col cols="2" md="2" offset="5">
            <v-btn
              type="button"
              x-large
              dark
              class="headButton"
              style="height: 45px !important"
            >
              Clear All
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-app>
    <!-- <v-app>
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
        </v-overlay>
  </v-app> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      notifications: [
        {
          id: 1,
          type: "info",
          message:
            "Mobolaji Banks has just applied for 20 days leave starting from 23-Jul- 2018 to 19 Aug -2018.",
          choice: 2
        },
        {
          id: 2,
          type: "success",
          message: "You have just been added to the project Green World.",
          choice: 0
        },
        {
          id: 3,
          type: "warning",
          message: "Your task deadline expires in two days, 21-June-2018.",
          choice: 1
        },
        {
          id: 4,
          type: "info",
          message: "You have 12 leave request waiting to be reviewed.",
          choice: 1
        },
        {
          id: 5,
          type: "danger",
          message: "You have failed to meet up to deadline.",
          choice: 1
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
$primary-color: #069;

button.headButton {
  background-color: #fff !important;
  color: $primary-color;
  border: 1px solid $primary-color;
  width: 90%;
  height: 34px !important;
  border-radius: 7px;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: capitalize;
  box-shadow: none !important;
}

.mdi-all-apps-size {
  font-size: 90px !important;
}
p {
  color: #111d5e;
  font-weight: 400;
}
.jumbotron {
  border-radius: 8px;
  background: #dbe0f975; //#111d5e75;
  // box-shadow: 0px 0px 50px 0px #00000014 !important;
  padding-left: 7%;
}
h1.white-h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: -2.5010533332824707px;
  text-align: left;
  color: #111d5e !important;
}
p.sub-h1 {
  color: #111d5e !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.create-submit-notifications {
  font-size: 12px !important;
  font-weight: 600;
  width: 90%;
  margin-right: 5px;
  height: 34px !important;
  border-radius: 7px !important;
  background-color: #069 !important;
  text-transform: initial;
}
.right-card {
  padding: 60px 0px;
  box-shadow: 0px 5px 10px 0px #00000014 !important;
  border-radius: 10px !important;
}
.shift-left {
  margin-left: 5%;
}
.card-p-icon {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: #111d5ea3 !important;
}
.card-p-icon i {
  color: #111d5ea3 !important;
}
.small-card-h3 {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
}
</style>
