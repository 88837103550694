<template>
  <div class="" style="margin-top: 100px;">
    <v-app class="">

      <v-row>
        <v-col class="px-0 d-none d-md-block" cols="12" md="6">
          <img src="/img/auth.png" style="height: 88vh; width: 100%;" />
        </v-col>
        <v-col class="px-0 py-0" cols="12" md="6" style="background: #FFF; border-radius: 10px;" :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? '' : 'position: absolute; top: 150px; right: 200px;'">
          <div class="card" style="background: #FFFFFF; box-shadow: 2px 6px 50px rgba(0, 0, 43, 0.15); border-radius: 10px;">
            <div class="card-body py-0">
              <v-form @submit.prevent="isLoading ? null : reset_pwd()" class="mt-10 mt-md-0">
                <v-container :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'padding: 20px 0px;' : 'padding: 80px 12px;'">
                  <v-row :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'px-4' : null">
                    
                    <v-col cols="12" class="text-center mt-10 mt-md-0">
                      <h3 class="text-new-primary" style="color: #111023; font-weight: 500; font-size: 33.18px;">Reset your password</h3>
                      <p style="font-size: 19.2px; color: #6B6A7D; font-weight: 500;">Enter your new password and confirm</p>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="formData.password"
                        :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        :rules="[rules.required, rules.min]"
                        :type="show ? 'text' : 'password'"
                        label="New password"
                        outlined
                        dense
                        placeholder="Enter your new password"
                        class="remove-bottom-hint"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="formData.confirm_password"
                        :append-icon="qShow ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        :rules="[qRulesRequired, qRulesMin]"
                        :type="qShow ? 'text' : 'password'"
                        label="Confirm new password"
                        outlined
                        dense
                        placeholder="Confirm your new password"
                        class="remove-bottom-hint"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-2 pt-6 text-center" cols="12" sm="12">
                      <v-btn  style="background: #0B54C0 !important; height: 40px; border-radius: 4px !important;" @click.prevent="isLoading ? null : reset_pwd()" type="submit" x-large dark class="new-create-submit w-100" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'w-100' : 'w-50'">
                        {{ btnTxt }}
                        <v-icon :style="!isLoading ? 'display: none' : ''"
                          >fas fa-circle-notch fa-spin</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img
                  src="@/assets/graphics/cross-check.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                {{ authFailed }}
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="dialog = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center">
        <v-dialog v-model="dialogSuccess" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img
                  src="@/assets/graphics/check.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                Password reset successful
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="dialogSuccess = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL } from "@/main";
import axios from "axios";
export default {
  props: {
    recovery_code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      dialogSuccess: false,
      formData: {
        password: "",
        confirm_password: ""
      },
      btnTxt: "Reset password",
      isLoading: false,
      show: false,
      qShow: false,
      password: "Password",
      rules: {
        required: value => !!value || "Enter a password here",
        //   required (value) {
        //       return !!value || Enter a password here
        //   }
        min: value => value.length >= 8 || "Min 8 characters"
        //   min (value) {
        //       return value.length >= 8 || 'Min 8 characters'
        //   }
      }
    };
  },
  computed: {
    authFailed() {
      if (window.navigator.onLine) {
        return 'Error reseting password';
      } else {
        return 'Please check your internet connectivity';
      }
    }
  },
  methods: {
    closeDialogSuccess() {
      this.dialogSuccess = false;
      this.$router.push("/login");
    },
    qRulesRequired(value) {
      return !!value || "Confirm your password here";
    },
    qRulesMin(value) {
      return value == this.formData.password || "Passwords don't match";
    },
    reset_pwd() {
      let _this = this;
      _this.isLoading = !_this.isLoading;
      _this.btnTxt = "";
      axios
        .post(`${BASE_URL}resetpassword`, {
          password: _this.formData.password,
          recovery_code: _this.recovery_code
        })
        .then(response => {
          console.log(response);
          if (response.data.success) {
            _this.dialogSuccess = true;
            setTimeout(function() {
              _this.$router.push("/login");
            }, 5000);
          } else {
            _this.dialog = true;
          }
        })
        .catch(error => {
          console.log("Error:" + error);
        })
        .then(() => {
          // AFTER AXIOS CALL
          _this.isLoading = !_this.isLoading;
          _this.btnTxt = "Send reset details";
        });
    }
  },
  mouted() {
    document.title = "Kylian ERP - Recover Password";
  }
};
</script>

<style lang="scss">
$primary-color: #069;
$text-field-color: #4e4e52;

.v-application .primary--text {
  color: $primary-color !important;
}
.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
  label {
    margin-left: 2%;
  }
  input {
    text-indent: 3%;
  }
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0px 0px 50px 0px #00000014 !important;
}
.v-text-field__details {
  padding-left: 0 !important;
}
.theme--light.v-messages {
  color: $primary-color !important;
}
.company-name
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}
h3 {
  font-family: Work Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: center;
  color: #111d5e;
}
label {
  color: #999 !important;
}
</style>
<style lang="scss" scoped>
$new-button-color: #2081FF;
$primary-color: #069;
.v-form {
  margin: 0 20%;
}
.svg-reset {
  position: absolute;
  top: 50%;
  left: 25%;
}
#small-link {
  color: $primary-color !important;
}
.space-top {
  padding-top: 60px;
}
.create-submit {
  width: 100%;
  height: 60px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600;
  span {
    text-transform: initial !important;
  }
}
.new-create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: $new-button-color !important;
  text-transform: initial !important;
  font-weight: 600 !important;
  span {
    text-transform: initial !important;
  }
}
</style>
