<template>
  <div class="container pt-0"
    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'mt-5' : 'mt-2'">
    <v-app>
      <!-- <h3>Setup your company account</h3>
            <p class="lead text-center">Make payment</p> -->
      <v-form @submit.prevent="auth_request()">
        <v-container>
          <v-row>
            <v-col md="4" lg="4" cols="12">
              <v-card class="right-card pt-1 pb-1 pl-4 pr-2">
                <h3 style="font-size: 16px !important;" class="text-left mb-3 mt-3">
                  Selected Apps: {{ selectedServices.length }}
                </h3>
                <v-row v-for="service in selectedServices" :key="service.service_id">
                  <v-col class="pt-0" md="1" lg="1" cols="2">
                    <i style="color: #111d5e;" :class="getIcon(service.service_name) + ' selected_icons'"></i>
                  </v-col>
                  <v-col class="pt-0 my-auto" md="10" lg="10" cols="8">
                    <span style="font-size: 11px;">{{
                        service.service_name
                    }}</span>
                  </v-col>
                </v-row>
                <!-- <v-row class="pt-7">
                  <v-col md="9" offset="1" class="pb-0">
                    <v-radio-group v-model="timeFrame" row>
                      <v-row>
                        <v-col md="4" lg="4" cols="4">
                          <v-radio
                            label="Monthly"
                            value="monthly"
                            color="#069"
                            :disabled="true"
                          ></v-radio>
                        </v-col>
                        <v-col md="4" lg="4" offset-lg="2" offset-md="2" cols="4" offset="1">
                          <v-radio
                            label="Annually"
                            value="annually"
                            color="#069"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                </v-row> -->
                <div class="pb-2 mt-4">
                  <h3 style="color: #069 !important;">
                    Free for 7 days
                  </h3>
                </div>
              </v-card>
            </v-col>
            <v-col md="7" lg="7" offset-lg="1" offset-md="1" cols="12">
              <v-row class="mt-5">
                <v-col class="pb-6 text-right" cols="6" md="6">
                  <v-btn type="submit" x-large dark class="create-submit"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                    @click="goBack(2)">
                    Previous
                  </v-btn>
                </v-col>
                <v-col class="pb-6 text-left" cols="6" md="6">
                  <v-btn type="submit" x-large dark class="create-submit"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                    @click.prevent="getRefs()">
                    Confirm trial
                    <v-icon :style="!isLoading ? 'display: none' : ''">fas fa-circle-notch fa-spin</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <!-- <v-col
                        md="3"
                        sm="2"
                        offset="1"
                        >
                            <transition
                            mode="out-in" 
                            type="animation"
                            enter-active-class="animate__animated animate__fadeInRight"
                            leave-active-class="animate__animated animate__fadeOutRight">
                                <component :is="cardName"></component>
                            </transition>
                        </v-col> -->
          </v-row>
        </v-container>
      </v-form>
    </v-app>
  </div>


</template>

<script>
export default {
  data() {
    return {
      overlay: false
    }
  },
  props: {
    amount: {
      type: Number,
      required: true
    },
    selectedServices: {
      type: Array,
      required: true
    },
    company_subscription: {
      type: Number,
      required: true
    }
  },
  methods: {
    goBack(step) {
      this.$emit("goBack", {
        step
      })
    },
    getRefs() {
      let _this = this;
      _this.overlay = true;
      let letters = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9"
      ];
      let ref = "";
      for (let i = 0; i <= 10; i++) {
        if (i === 5) {
          ref += new Date().getTime();
          continue;
        }
        // ref += Math.floor(letters[Math.random() * letters.length])
        ref += letters[
          Math.floor(Math.random() * letters.length)
        ].toUpperCase();
      }
      if (_this.$emit("paid", ref)) {
        // _this.changeStep(4);
        _this.overlay = false;
      }
    },
    changeStep(step) {
      this.$emit("step", step);
    },
    getIcon(name) {
      if (name.toLowerCase() == "onboarding") {
        return "fal fa-users";
      } else if (name.toLowerCase() == "attendance") {
        return "fal fa-clipboard-user";
      } else if (name.toLowerCase() == "timesheets") {
        return "fal fa-file-lines";
      } else if (name.toLowerCase() == "absence or leave") {
        return "fal fa-island-tropical";
      } else if (name.toLowerCase() == "medicals") {
        return "fal fa-notes-medical";
      } else if (name.toLowerCase() == "assets") {
        return "fal fa-computer-speaker";
      } else if (name.toLowerCase() == "file management") {
        return "fal fa-paste";
      } else if (name.toLowerCase() == "payroll") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "payslip") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "travel management") {
        return "fal fa-plane-departure";
      } else if (name.toLowerCase() == "recruitment") {
        return "fal fa-handshake";
      } else if (name.toLowerCase() == "projects") {
        return "fal fa-tasks";
      } else if (name.toLowerCase() == "loan") {
        return "fal fa-coins";
      } else if (name.toLowerCase() == "tasksheets") {
        return "fal fa-file-spreadsheet";
      } else if (name.toLowerCase() == "requisitions") {
        return "fal fa-money-check";
      } else if (name.toLowerCase() == "dockets") {
        return "fal fa-scale-balanced";
      } else {
        return "fal fa-question";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-submit {
  width: fit-content;
  height: 60px;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600;

  span {
    text-transform: initial;
  }
}
</style>
