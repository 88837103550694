<template>
  <div style="padding:30px;">
    <div class="row justify-content-between ">
      <div>
        <h4 class="my-0 row pb-0">
          <span class="col-lg-122 col-md-12 col-12">Documents</span>
        </h4>
      </div>
      <div>
        <button v-if="upload_document_form" @click="upload_document_form = false" style="float: right;" type="button"
          class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
          Cancel
        </button>
        <button v-if="!upload_document_form && authUserData.user_type === 1" @click="upload_document_form = true"
          type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
          Upload Document
        </button>
      </div>
    </div>
    <div v-if="upload_document_form" class="main-content mt-0 px-0">
      <div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Name
                  <i class="fal fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field v-model="document_upload_data.name" placeholder="Enter Document title" solo class="mt-2"
                  dense></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Select File
                  <i class="fal fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>

                <v-file-input v-model="document_upload_data.file" label="Choose a file" accept=".pdf, .doc, .docx" solo
                  class="mt-2" dense></v-file-input>

                <div v-if="document_upload_data.file">
                  {{ document_upload_data.file.name }}
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Issue Date</span>
                <v-menu ref="issueDateForCreation" v-model="issueDateForCreation" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-1" color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="document_upload_data.issue_date" placeholder="Issue Date" dense solo readonly
                      v-bind="attrs" v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" :min="(new Date().getFullYear()).toString()"
                    v-model="document_upload_data.issue_date" @change="saveIssueDateForCreation">
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Expiry Date</span>
                <v-menu ref="expiryDateForCreation" v-model="expiryDateForCreation" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mt-1" color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="document_upload_data.expiry_date" placeholder="Expiry Date" dense solo readonly
                      v-bind="attrs" v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" :min="(new Date().getFullYear()).toString()"
                    v-model="document_upload_data.expiry_date" @change="saveExpiryDateForCreation">
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                <button @click="upload_document_submit ? null : uploadDocument()" style="width: 99%;" type="button"
                  class="btn btn-secondary m-1 text-white btn-raised ripple">
                  {{ upload_document_text }}
                  <v-icon :style="!upload_document_submit
          ? 'display: none;'
          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
          ">
                    fal fa-circle-notch fa-spin
                  </v-icon>
                </button>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
    </div>
    <div v-else class="row">
      <div v-if="authEmployeeDocuments.length" class="col-12">
        <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
          <div class="row card-body text-center py-0 mt-0">
            <table class="table" :class="$vuetify.breakpoint.name == 'xs'
          ? 'table-responsive'
          : null
          ">
              <thead>
                <tr>
                  <th scope="col" class="text-left">File</th>
                  <th scope="col" class="text-left">Issue Date</th>
                  <th scope="col" class="text-left">Expiry Date</th>
                  <th v-if="userType == 1" scope="col" class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="doc in authEmployeeDocuments" :key="doc.id">
                  <td scope="col" class="text-left">
                    <a :href="assets_url + doc.document_file" target="_blank" rel="noopener noreferrer">
                      {{ doc.document_title }}
                      <span class="fal fa-arrow-up-right-from-square ml-1"></span>
                    </a>
                  </td>
                  <td v-if="doc.issue_date" scope="col" class="text-left">
                    {{
          new Date(doc.issue_date)
            .toUTCString()
            .substring(0, 16)
        }}
                  </td>
                  <td v-else scope="col" class="text-left">
                    <i>Not indicated</i>
                  </td>
                  <td v-if="doc.expiry_date" scope="col" class="text-left">
                    {{
          new Date(doc.expiry_date)
            .toUTCString()
            .substring(0, 16)
        }}
                  </td>
                  <td v-else scope="col" class="text-left">
                    <i>Not indicated</i>
                  </td>
                  <td v-if="userType == 1" class="text-left">
                    <div class="ul-widget4__actions">
                      <button class="btn _r_btn border-0" type="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <span class="_dot _inline-dot bg-primary"></span>
                        <span class="_dot _inline-dot bg-primary"></span>
                        <span class="_dot _inline-dot bg-primary"></span>
                      </button>
                      <div class="dropdown-menu" x-placement="top-start"
                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                        <a class="dropdown-item" style="cursor: pointer;" @click="launch_update_document_dialog(doc)">
                          <i class="fal fa-edit" style="color: inherit !important;"></i> Edit
                        </a>
                        <a class="dropdown-item" style="cursor: pointer;" @click="delete_employee_document(doc)">
                          <i class="fal fa-trash"></i> Delete
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>No Documents</div>
    </div>
    <v-dialog v-model="update_document_dialog" max-width="600px">
      <v-card><v-card-title>
          <div style="display:flex; justify-content:space-between; width:100%">
            <v-col cols="6">
              <span class="headline">Update Document</span>
            </v-col>
            <v-col cols="2"> </v-col>
          </div>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Document Name
                      <i class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="document_to_update.document_title" placeholder="Enter name" solo class="mt-2"
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Select File
                      <i class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>

                    <v-file-input v-model="document_to_update.file" label="Choose a file" accept=".pdf, .doc, .docx"
                      solo class="mt-2" dense></v-file-input>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Issue Date</span>
                    <v-menu ref="issueDateForUpdate" v-model="issueDateForUpdate" :close-on-content-click="false"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="mt-1" color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="document_to_update.issue_date" placeholder="Issue Date" dense solo readonly
                          v-bind="attrs" v-on="on">
                        </v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker" :min="(new Date().getFullYear()).toString()"
                        v-model="document_to_update.issue_date" @change="saveIssueDateForUpdate">
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="remove-hint">
                    <span style="color: #111d5e;">Expiry Date</span>
                    <v-menu ref="expiryDateForUpdate" v-model="expiryDateForUpdate" :close-on-content-click="false"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="mt-1" color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                          v-model="document_to_update.expiry_date" placeholder="Expiry Date" dense solo readonly
                          v-bind="attrs" v-on="on">
                        </v-text-field>
                      </template>
                      <v-date-picker color="#069" ref="picker" :min="(new Date().getFullYear()).toString()"
                        v-model="document_to_update.expiry_date" @change="saveExpiryDateForUpdate">
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="
          update_document_submit ? null : updateEmployeeDocument()
          " style="width: 99%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ update_document_text }}
                      <v-icon :style="!update_document_submit
          ? 'display: none;'
          : 'color: #fff; padding: 0px 10px; font-size: 16px;'
          ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="update_document_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, ASSETS_URL } from "@/main";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    employee: Object
  },
  data() {
    return {
      employee_documents: [],
      document_upload_data: { name: "", file: null, issue_date: "", expiry_date: "" },
      upload_document_form: false,
      update_document_dialog: false,
      document_to_update: { name: "", file: null, issue_date: "", expiry_date: "" },
      upload_document_text: "Upload Document",
      update_document_text: "Update Document",
      upload_document_submit: false,
      update_document_submit: false,
      issueDateForCreation: false,
      expiryDateForCreation: false,
      issueDateForUpdate: false,
      expiryDateForUpdate: false,
    };
  },
  methods: {
    ...mapActions(["fetch_all_employee_documents"]),
    saveIssueDateForCreation: function (t) {
      this.$refs.issueDateForCreation.save(t);
    },
    saveExpiryDateForCreation: function (t) {
      this.$refs.expiryDateForCreation.save(t);
    },
    saveIssueDateForUpdate: function (t) {
      this.$refs.issueDateForUpdate.save(t);
    },
    saveExpiryDateForUpdate: function (t) {
      this.$refs.expiryDateForUpdate.save(t);
    },
    delete_employee_document(employee_document) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.$swal
        .fire({
          title:
            "Are you sure you want to delete " +
            employee_document.document_title,
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Delete`
        })
        .then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}deletedocument`, {
                document_id: employee_document.id,
                company_id: _this.authCompanyData.id,
                employee_id: _this.employee.id
              })
              .then(function () {
                _this.fetch_company_roles().then(() => {
                  Toast.fire({
                    icon: "success",
                    title: "Document could has been deleted"
                  });
                  _this.update_custom_role_dialog = false;
                });
              })
              .catch(function (error) {
                let errors = error.response.data.error || null;

                Toast.fire({
                  icon: "error",
                  title: errors
                    ? typeof errors === "string"
                      ? errors
                      : null || errors.id
                        ? errors.id[0]
                        : null || errors.title
                          ? errors.title[0]
                          : null || errors.description
                            ? errors.description[0]
                            : null || errors.start
                              ? errors.start[0]
                              : null || errors.end
                                ? errors.end[0]
                                : null
                    : null ||
                    error.response.data.error ||
                    error.response.data.message ||
                    "Document could not be deleted"
                });
              });
          }
        });
    },
    launch_update_document_dialog(document) {
      this.document_to_update = document;
      this.update_document_dialog = true;
    },
    uploadDocument() {
      const _this = this;
      _this.upload_document_text = "";
      _this.upload_document_submit = true;
      const formData = new FormData();
      formData.append("company_id", _this.authCompanyData.id);
      formData.append("file", _this.document_upload_data.file);
      formData.append("document_title", _this.document_upload_data.name);
      formData.append("issue_date", _this.document_upload_data.issue_date);
      formData.append("expiry_date", _this.document_upload_data.expiry_date);
      formData.append("employee_id", this.employee.id);

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}addemployeedocument`, formData)
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Document has been uploaded sccessfully"
          });
          if (_this.employee || _this.userType != 1) {
            _this.fetch_all_employee_documents(_this.employee.id);
          } else {
            _this.fetch_all_employee_documents();
          }
          _this.document_upload_data.name = '';
          _this.document_upload_data.issue_date = '';
          _this.document_upload_data.expiry_date = '';
          _this.document_upload_data.file = null;

          _this.upload_document_text = "Upload Document";
          _this.upload_document_submit = false;
          _this.upload_document_form = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.id
                  ? errors.id[0]
                  : null || errors.title
                    ? errors.title[0]
                    : null || errors.description
                      ? errors.description[0]
                      : null || errors.start
                        ? errors.start[0]
                        : null || errors.end
                          ? errors.end[0]
                          : null
              : null ||
              error.response.data.error ||
              error.response.data.message ||
              "Document couldn't be uploaded"
          });
        })
        .then(function () {
          _this.upload_document_text = "Upload Document";
          _this.upload_document_submit = false;
        });
    },
    updateEmployeeDocument() {
      const _this = this;
      _this.update_document_text = "";
      _this.update_document_submit = true;

      const formData = new FormData();

      formData.append("company_id", _this.authCompanyData.id);
      formData.append("file", _this.document_to_update.file);
      formData.append(
        "document_title",
        _this.document_to_update.document_title
      );
      formData.append("employee_id", _this.employee.id);
      formData.append("document_id", _this.document_to_update.id);
      formData.append("issue_date", _this.document_to_update.issue_date);
      formData.append("expiry_date", _this.document_to_update.expiry_date);

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}updateemployeedocument`, formData)
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Document has been updated sccessfully"
          });
          _this.update_document_dialog = false;
          _this.document_to_update = { name: "", file: null };
          _this.update_document_text = "Update Document";
          _this.update_document_submit = false;

          if (_this.employee || _this.userType != 1) {
            _this.fetch_all_employee_documents(this.employee.id);
          } else {
            _this.fetch_all_employee_documents();
          }
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.id
                  ? errors.id[0]
                  : null || errors.title
                    ? errors.title[0]
                    : null || errors.description
                      ? errors.description[0]
                      : null || errors.start
                        ? errors.start[0]
                        : null || errors.end
                          ? errors.end[0]
                          : null
              : null ||
              error.response.data.error ||
              error.response.data.message ||
              "Document couldn't be uploaded"
          });
        })
        .then(function () {
          _this.upload_document_text = "Upload Document";
          _this.upload_document_submit = false;
        });
    }
  },
  computed: {
    assets_url() {
      return ASSETS_URL;
    },
    ...mapState(["weekDays"]),
    ...mapGetters([
      "userType",
      "authEmployeeDocuments",
      "authCompanyData",
      "authCompanyPermissions",
      "authUserData",
      "authEmployeeDataForCompany",
    ])
  },
  mounted() {
    var t = this;
    if (t.employee || t.userType != 1) {
      t.fetch_all_employee_documents(t.employee.id);
    } else {
      t.fetch_all_employee_documents();
    }
  }
};
</script>

<style lang="scss" scoped></style>
