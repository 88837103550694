<template>
  <div class="">
    <v-overlay v-if="fetching">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-app v-else class="">
      <v-row style="margin-top: 60px;">
        <v-col cols="12"
          style="background: url(/img/contact-banner.png); background-size: cover; background-repeat: round;" :style="$vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm' ||
              $vuetify.breakpoint.name == 'md'
              ? ''
              : ''
            ">
          <div :style="$vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm' ||
              $vuetify.breakpoint.name == 'md'
              ? 'padding:100px 60px 20px; display:flex; justify-content:center; align-items:center;'
              : 'padding:100px 60px 20px; display:flex; justify-content:center; align-items:center;'
            ">
            <h2 class="text-left"
              style="font-family: Poppins !important; font-size: 38px; line-height: 57px; font-weight: 600; color: #FFF !important; max-width: 1300px;">
              Blog Post
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="margin-top: 20px; height: max-content;" :style="$vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
            ? 'width: 100vw; display:flex; flex-direction:column; justify-content:center; align-items:flex-start;'
            : 'padding:0px 50px; width: 100vw; display:flex;justify-content:center; flex-direction:row; align-items:flex-start;'
          ">
          <div :style="$vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm' ||
              $vuetify.breakpoint.name == 'md'
              ? 'max-width: 100vw; width: 100vw; display:flex; flex-direction:column; justify-content:center; align-items:center'
              : 'max-width: 1500px; width: 100vw; display:flex;justify-content:space-between; flex-direction:row; align-items:flex-start;'
            ">
            <div :style="$vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm' ||
                $vuetify.breakpoint.name == 'md'
                ? 'display:flex; flex-direction:column; justify-content:center; align-items:center;'
                : 'display:flex; flex-direction:row; justify-content:center; gap:80px; align-items:flex-start;'
              ">
              <v-col cols="12" lg="7">
                <v-row :style="$vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'// ||
                    // $vuetify.breakpoint.name == 'md'
                    ? ''
                    : 'margin: 0px 0px 30px 0px; padding:0px 40px; display:flex; flex-direction:column; justify-content:center; align-items:flex-start;'
                  ">
                  <v-col cols="12">
                    <h2 style="color: #2D2D2D; 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 38px; 
                        margin-bottom: 20px;">
                      {{ post.title }}
                    </h2>
                    <div style="height: auto; margin-top: 13px; ">
                      <div>
                        <img :src="`${blog_url + post.featured_img}`" />
                      </div>
                      <div class="" style="margin-top: 30px; padding: 0 30px; ">
                        <v-row>
                          <div v-html="post.body + '<br /><i>Written by ' + post.editor + '</i>'" class="blogBody" style="color: #0e0b33;
                              margin-bottom: 10px;
                              margin-top: 10px;
                                font-size: 16px;
                                
                                line-height: 24px" :style="$vuetify.breakpoint.name == 'xs' ||
                                    $vuetify.breakpoint.name == 'sm' ||
                                    $vuetify.breakpoint.name == 'md'
                                    ? 'width: 100%;'
                                    : 'width: 100%;'
                                  ">
                          </div>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="8" class="ml-lg-5">
                    <v-row class="mt-1">
                      <v-col cols="12" lg="3" class="text-left">
                        <p style="font-size: 16px;">Share on </p>
                      </v-col>
                      <v-col cols="12" lg="9">
                        <v-row>
                          <v-col cols="2" class="text-left">
                            <a :href="`https://twitter.com/intent/tweet?url=https://kylianerp.com/blog/${currentPostSlug}&text=${post.title}`"
                              target="_blank">
                              <img src="/img/twitter-footer.svg" alt="">
                            </a>
                          </v-col>
                          <v-col cols="2" class="text-left">
                            <a :href="`https://www.facebook.com/sharer/sharer.php?u=https://kylianerp.com/blog/${currentPostSlug}`"
                              target="_blank">
                              <img src="/img/facebook-footer.svg" alt="">
                            </a>
                          </v-col>
                          <v-col cols="2" class="text-left">
                            <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=https://kylianerp.com/blog/${currentPostSlug}`"
                              target="_blank">
                              <img src="/img/linkedin-footer.svg" alt="">
                            </a>
                          </v-col>
                          <v-col cols="2" class="text-left">
                            <a :href="`https://api.whatsapp.com/send?text=New blog post: ${post.title} https://kylianerp.com/blog/${currentPostSlug}`"
                              target="_blank">
                              <img src="/img/whatsapp-footer.svg" alt="">
                            </a>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="3" :style="$vuetify.breakpoint.name == 'xs' ||
                  $vuetify.breakpoint.name == 'sm' ||
                  $vuetify.breakpoint.name == 'md'
                  ? 'padding:0px 40px;  display:flex; flex-direction:column; justify-content:center; gap:20px;'
                  : ''
                ">
                <div :style="$vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm' ||
                    $vuetify.breakpoint.name == 'md'
                    ? 'padding-left:20px; border-radius: 20px; display: flex; justify-content: space-between;align-items: center; margin-bottom: 16px; border: 2px solid #e8e8e8; '
                    : 'padding-left:20px; border-radius: 20px; display: flex; justify-content: center;align-items: center; margin-bottom: 16px; border: 2px solid #e8e8e8; '
                  " style="">
                  <input style="width: 90%; 
                       padding: 6px; padding-right: 20px; font-size: 16px;" placeholder="Search" type="text"
                    class="searchInput" v-model="searchInput" />
                  <div @click="searchBlog()"
                    style="cursor: pointer; text-align-last: center; border-top-right-radius: 20px;border-bottom-right-radius: 20px;  top: 0;background: #0B54C0; width: 60px;padding-left: 20px; padding: 10px;">
                    <img src="../assets/graphics/search.svg" alt="" />
                  </div>
                </div>
                <div :style="$vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                    ? 'padding:0px 40px;  display:flex; flex-direction:column; justify-content:center; gap:40px; align-items:center;'
                    : $vuetify.breakpoint.name == 'md'
                      ? 'padding:0px 40px;  display:flex; flex-direction:row; justify-content:center; gap:40px;'
                      : ''
                  ">
                  <div class="recent-articles" style="background: rgba(218, 218, 218, 0.38);border-radius: 10px; padding:30px; '
                    " :style="$vuetify.breakpoint.name == 'xs'
                        ? 'width: 100%;'
                        : $vuetify.breakpoint.name == 'sm' ||
                          $vuetify.breakpoint.name == 'md'
                          ? ' margin-top: 0px;'
                          : ' margin-top: 42px; width: 303px;'
                      ">
                    <h3 style="font-size: 24px;
                      padding:0;
                      line-height: 36px;
                      text-transform: capitalize;
                      color: #1A3F78;
                      text-align:left;">
                      Recent Post
                    </h3>
                    <ul style="padding:0;
                      display: flex;
                       flex-direction: column; 
                       gap: 6px; ">
                      <li v-for="(post, index) in topFivePosts" :key="index"
                        :style="post.slug == currentPostSlug && 'color: red;'">
                        <router-link :to="{ name: 'BlogDetails', params: { slug: post.slug } }"
                          :style="post.slug == currentPostSlug && 'color: red;'">
                          {{ post.title }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <!-- <a href="https://bit.ly/julyhrwebinar" target="_blank">
                    <div
                      style=" width: 300px; height: 330px;
                      "
                      class="addImage"
                      :style="
                        $vuetify.breakpoint.name == 'xs'
                          ? 'width: 100%;'
                          : $vuetify.breakpoint.name == 'sm' ||
                            $vuetify.breakpoint.name == 'md'
                          ? ' margin-top: 0px;'
                          : ' margin-top: 42px;'
                      "
                    >
                    </div>
                  </a> -->
                </div>
              </v-col>
            </div>
          </div>
        </v-col>
      </v-row>
      <div>
        <GuestNewsletter />
        <GuestFooter />
      </div>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL, BLOG_URL } from "@/main";
import GuestNewsletter from "@/components/GuestNewsletter";
import GuestFooter from "@/components/GuestFooter";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    GuestFooter,
    GuestNewsletter
  },
  methods: {
    ...mapActions([
      'fetch_company_blog_posts'
    ]),
    searchBlog() {
      const _this = this;
      if (_this.searchInput) {
        _this.$router.push({
          name: 'Blog',
          query: {
            s: _this.searchInput
          }
        });
      }
      return;
    },
    fetchSinglePost(slug) {
      const _this = this;
      axios
        .get(`${BASE_URL}fetchsinglepost/${slug}`)
        .then(function (RESPONSE) {
          _this.post = RESPONSE.data.post;
          _this.fetching = false;
        })
        .catch(function () {
          //
        })
        .then(function () {
          //
        });
    }
  },
  data() {
    return {
      fetching: true,
      currentPage: 1,
      totalPages: 5,
      blogs: [
        {
          title: "5 Tips for Crafting a Winning Resume2",
          image: "blog1",
          body:
            "Our advice on how to create the winning resume in your current job application process to eliminate your competitions.",
          author: "Usman Musa",
          date: "May 17, 2023"
        }
      ],
      articles: [
        {
          title: "The Future of Work: HR Trends to Watch in Year 2022 ",
          image: "article2",

          author: "Usman Musa",
          date: "May 17, 2023"
        },
        {
          title: "The Future of Work: HR Trends to Watch in Year 2025 ",
          image: "article2",

          author: "Usman Musa",
          date: "May 17, 2023"
        }
      ],
      post: {},
      searchInput: ''
    };
  },
  computed: {
    ...mapGetters([
      'blogPosts'
    ]),
    topFivePosts() {
      return this.blogPosts.filter((post, index) => index <= 4);
    },
    blog_url() {
      return BLOG_URL;
    },
    currentPostSlug() {
      return this.$route.params.slug;
    }
  },
  watch: {
    currentPostSlug(newVal) {
      const _this = this;
      if (newVal) {
        _this.fetchSinglePost(_this.currentPostSlug);
      }
    }
  },
  mounted() {
    const _this = this;
    document.title = "Kylian ERP - Blog Post";
    _this.fetchSinglePost(_this.currentPostSlug);
    _this.fetch_company_blog_posts();
  }
};
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
$new-button-color: #2081ff;
$primary-color: #069;

.blogImg {
  width: 100%;
  height: 468px;
  background-size: cover;
  background-position-x: 0px;
  background-position-y: 0px;
}

.addImage {
  border-radius: 10px;
  background: linear-gradient(0deg,
      rgba(26, 64, 120, 0.031),
      rgba(26, 63, 120, 0.47)),
    url("/img/Webinar.jpg");
  // url("../assets/graphics/ad.jpeg");
  justify-content: center;
  align-items: center;
  display: flex;
  background-position: center;
  background-size: cover;
}

.searchInput:focus {
  outline: none;
}

.blogTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #2d2d2d;
}

.blogSub {
  width: 415px;
  color: #0e0b33;
  font-weight: 500;
  font-size: 16px;
}

.articlesImg {
  width: auto;
  height: 170px;
  background-size: cover;
  border-radius: 10px;
  background-position-x: 0px;
  background-position-y: 0px;
}

.articlesTitle {
  color: #0e0b33;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.popular-articles-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
  text-align: left;
}

.articlesSub {
  color: #0e0b33;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@media (max-width: 991px) {}
</style>

<style lang="scss"></style>
