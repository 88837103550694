<template>
    <div class="tab-pane fade" id="list-outsourcing-companies" role="tabpanel"
        aria-labelledby="list-outsourcing-companies-list">
        <div v-if="create_company_form">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Create Outsourcing Company
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div v-if="create_company_form" class="col-lg-3 offset-9 py-4 text-right">
                                <button @click="create_company_form = false" style="float: right;" type="button"
                                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Company Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="company_form.organization_name"
                                        placeholder="Enter the company name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Address <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="company_form.address" placeholder="Enter the company address"
                                        solo class="mt-2" dense> </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Contact Number <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="company_form.contact_number"
                                        placeholder="Enter the company contact number" solo class="mt-2" dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" offset-md="3" class="remove-hint">
                                    <button @click="create_company_submit ? null : create_company()" style="width: 99%;"
                                        type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ create_company_text }}
                                        <v-icon
                                            :style="!create_company_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Outsourcing Companies Management
                    </div>
                    <!-- permissionIsAssigned('department-create') -->
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <!-- permissionIsAssigned('department-create') -->
                                <button @click="create_company_form = true" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Create Outsourcing Company
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Company Name
                            </th>
                            <th scope="col" class="text-left">
                                Address
                            </th>
                            <th scope="col" class="text-left">
                                Contact Number
                            </th>
                            <!-- permissionIsAssigned('department-update') || permissionIsAssigned('department-delete') -->
                            <th scope="col" style="width: 10px;">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="company in authCompanyOutsourcingCompanies" :key="company.id">
                            <td class="text-left">
                                {{ company.organization_name }}
                            </td>
                            <td class="text-left">{{ company.address }}</td>
                            <td class="text-left">{{ company.contact_number }}</td>
                            <!-- permissionIsAssigned('department-update') || permissionIsAssigned('department-delete') -->
                            <td class="pt-1">
                                <div class="ul-widget4__actions">
                                    <button class="btn _r_btn border-0" type="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="_dot _inline-dot bg-primary"></span>
                                        <span class="_dot _inline-dot bg-primary"></span>
                                        <span class="_dot _inline-dot bg-primary"></span>
                                    </button>
                                    <div class="dropdown-menu" x-placement="top-start"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);">
                                        <!-- permissionIsAssigned('department-update') -->
                                        <a class="dropdown-item" style="cursor: pointer;"
                                            @click="launch_edit_company(company)">
                                            <i class="fal fa-edit" style="color: inherit !important;"></i> Edit
                                        </a>
                                        <!-- permissionIsAssigned('department-delete') -->
                                        <a class="dropdown-item" style="cursor: pointer;"
                                            @click="delete_company(company)">
                                            <i class="fal fa-trash"></i> Delete
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <v-dialog v-model="company_edit_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Outsourcing Company</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Company Name <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="edit_company_form.organization_name"
                                            placeholder="Enter the company name" solo class="mt-2" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Address <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="edit_company_form.address"
                                            placeholder="Enter the company address" solo class="mt-2" dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Contact Number <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="edit_company_form.contact_number"
                                            placeholder="Enter the company contact number" solo class="mt-2" dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button @click="edit_company_submit ? null : update_company()"
                                            style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ edit_company_text }}
                                            <v-icon
                                                :style="!edit_company_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="company_edit_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            create_company_form: false,
            company_form: {
                company_id: '',
                organization_name: '',
                address: '',
                contact_number: '',
            },
            edit_company_form: {
                id: null,
                company_id: '',
                organization_name: '',
                address: '',
                contact_number: '',
            },
            edit_company_text: "Update",
            edit_company_submit: false,
            create_company_text: "Submit",
            create_company_submit: false,
            company_edit_dialog: false,
        }
    },
    computed: {
        ...mapState([
            'userToken'
        ]),
        ...mapGetters([
            'authCompanyData',
            'authCompanyOutsourcingCompanies'
        ])
    },
    methods: {
        ...mapActions([
            'fetch_company_outsourcing_companies'
        ]),
        launch_edit_company(company) {
            this.edit_company_form.id = company.id;
            this.edit_company_form.company_id = company.company_id;
            this.edit_company_form.organization_name = company.organization_name;
            this.edit_company_form.address = company.address;
            this.edit_company_form.contact_number = company.contact_number;
            this.company_edit_dialog = true;
        },
        delete_company(company) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this outsourcing company?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}deleteoutsourcingcompany`, {
                            id: company.id
                        }, {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`
                            }
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Outsourcing company deleted successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Outsourcing company couldn't be deleted",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_outsourcing_companies();
                            });
                    }
                });
        },
        update_company() {
            const _this = this;
            _this.edit_company_text = "";
            _this.edit_company_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updateoutsourcingcompany`, {
                company_id: _this.authCompanyData.id,
                id: _this.edit_company_form.id,
                organization_name: _this.edit_company_form.organization_name,
                address: _this.edit_company_form.address,
                contact_number: _this.edit_company_form.contact_number,
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The outsourcing company has been updated successfully",
                    });
                    _this.fetch_company_outsourcing_companies();
                    _this.company_edit_dialog = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.organization_name
                                    ? errors.organization_name[0]
                                    : null || errors.address
                                        ? errors.address[0]
                                        : null || errors.contact_number
                                            ? errors.contact_number[0]
                                            : null
                            : null || error.response.data.error || error.response.data.message || "The outsourcing company could not be updated",
                    });
                })
                .then(function () {
                    _this.edit_company_submit = false;
                    _this.edit_company_text = "Update";
                });
        },
        create_company() {
            const _this = this;
            _this.create_company_text = "";
            _this.create_company_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios.post(`${BASE_URL}storeoutsourcingcompany`, {
                company_id: _this.authCompanyData.id,
                organization_name: _this.company_form.organization_name,
                address: _this.company_form.address,
                contact_number: _this.company_form.contact_number,
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Outsourcing company was added successfully",
                    });
                    _this.company_form.organization_name = "";
                    _this.company_form.address = "";
                    _this.company_form.contact_number = "";
                    _this.create_company_form = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.organization_name
                                    ? errors.organization_name[0]
                                    : null || errors.address
                                        ? errors.address[0]
                                        : null || errors.contact_number
                                            ? errors.contact_number[0]
                                            : null
                            : null || error.response.data.error || error.response.data.message || "The outsourcing company could not be added",
                    });
                })
                .then(function () {
                    _this.fetch_company_outsourcing_companies();
                    _this.create_company_submit = false;
                    _this.create_company_text = "Submit";
                });
        },

    }
}
</script>

<style></style>