<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div class="dropdown">
            <button class="btn ripple m-1" type="button" @click="refresh_page()"
              :class="refreshing_page ? 'rotate-spinner' : ''">
              <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
            </button>
          </div>
          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button"
              class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row">
            <h1 class="my-0 col-md-12">
              <span>Billing History</span>
              <v-row style="float: right;"> </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th class="text-left" scope="col">Payment Ref.</th>
                          <th class="text-left" scope="col">Payment Purpose</th>
                          <th class="text-left" scope="col">Start Date</th>
                          <th class="text-left" scope="col">End Date</th>
                          <th class="text-left" scope="col">Amount Paid</th>
                          <th scope="col">Subscription</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="detail in authCompanyAllBilling.paymenthistory" :key="detail.id">
                          <td class="text-left">{{ detail.payment_ref }}</td>
                          <td class="text-left">{{ detail.payment_type }}</td>
                          <td class="text-left">
                            {{ new Date(detail.created_at)
    .toUTCString()
    .substring(0, 16)
}}
                          </td>
                          <td v-if="detail.date_expires" class="text-left">
                            {{ new Date(detail.date_expires)
    .toUTCString()
    .substring(0, 16)
}}
                          </td>
                          <td v-else class="text-left">
                            <i>None specified</i>
                          </td>

                          <td v-if="detail.subscription_type == 2" class="text-left">₦{{ detail.amount |
    number_with_commas
}}</td>
                          <td v-else class="text-left">₦{{ (detail.amount || '0') | number_with_commas }}</td>

                          <td v-if="detail.subscription_type == 2 || !detail.subscription_type">
                            <span style="width: 60px;" class="badge badge-success">ACTIVE</span>
                          </td>
                          <td v-else>
                            <span style="width: 60px;" class="badge badge-warning text-white">TRIAL</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/main";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      refreshing_page: false,
      employee: null,
      menu: false,
      day_selected: "",
      view: "date", // also day and employee
      day_attendance: [],
      checkingSingleDetails: [],
      monthSelected: "",
      yearSelected: "",
      checkingDetails: null,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      firstYear: ""
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authCompanyAllCheckin",
      "authCompanyAllBilling"
    ]),
    attendance() {
      if (!this.checkingDetails) {
        return this.authCompanyAllCheckin.attendance;
      } else {
        return this.checkingDetails;
      }
    },
    month() {
      let d = new Date();
      return this.monthNames[d.getMonth()];
    },
    allYears() {
      let yearsArray = [],
        currentYear = new Date().getFullYear();
      yearsArray.push(this.firstYear);
      for (let yearX = this.firstYear; yearX <= currentYear; yearX++) {
        yearsArray.push(yearX);
      }
      return yearsArray;
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_company_billing"
    ]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    refresh_page() {
      //
    },
    viewAll() {
      this.monthSelected = this.monthNames[new Date().getMonth()];
      this.yearSelected = new Date().getFullYear().toString();
      this.checkingDetails = null;
      this.view = "date";
    },
    save(date) {
      this.$refs.menu.save(date);
      let _this = this;
      axios
        .post(`${BASE_URL}fetchcheckingbyday`, {
          company_id: _this.authCompanyData.id,
          day: _this.day_selected
        })
        .then(function (response) {
          _this.day_attendance = response.data.attendance;
          _this.view = "day";
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .then(function () {
          //
        });
    },
    load_day(day) {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchcheckingbyday`, {
          company_id: _this.authCompanyData.id,
          day
        })
        .then(function (response) {
          _this.day_attendance = response.data.attendance;
          _this.view = "day";
          _this.day_selected = day;
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .then(function () {
          //
        });
    },
    update_checking_details() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchallcompanychecking`, {
          company_id: _this.authCompanyData.id,
          month: _this.monthSelected,
          year: _this.yearSelected
        })
        .then(function (response) {
          _this.checkingDetails = response.data.attendance;
          _this.firstYear = new Date(_this.authCompanyData.created_at).getFullYear().toString();
          _this.yearSelected = _this.firstYear;
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .then(function () {
          //
        });
    }
  },
  mounted() {
    document.title = "Kylian ERP - Billing History";
    this.fetch_all_company_billing();
    this.firstYear = new Date(this.authCompanyData.created_at).getFullYear().toString();
    this.monthSelected = this.monthNames[new Date().getMonth()];
    this.yearSelected = new Date().getFullYear().toString();
  }
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
