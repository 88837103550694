<template>
  <v-card class="right-card pt-10 pb-10 pl-2 pr-2">
    <h3 style="color: #069 !important;">Start now</h3>
    <p class="card-p text-center mb-0" style="margin-top: 16px;">
      Select services here
    </p>
    <!-- <p class="card-p text-center">From</p>
        <h3 style="color: #069 !important;">₦5000/month</h3> -->
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
