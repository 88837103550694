<template>
    <div class="">
        <v-app class="">
            <v-row style="margin-top: 60px;">
                <v-col cols="12" style="background: url(/img/contact-banner.png); background-repeat: round;">
                    <div class="px-10" style="padding-top: 100px; padding-bottom: 20px;">
                        <h2 class="py-5 text-left"
                            style="font-family: Poppins !important; font-size: 38px; line-height: 57px; font-weight: 600; color: #FFF !important;">
                        </h2>
                    </div>
                </v-col>
                <v-col cols="12" style="background-color: #FBFBFB;">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <div style="margin-top: 40px;">
                                    <v-row>
                                        <v-col cols="12">
                                            <h2 class="text-center"
                                                style="font-family: Poppins !important; font-size: 32px; line-height: 48px; font-weight: 600; color: #000 !important;">
                                                KylianERP Core Features
                                            </h2>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row class="mb-3">
                                                <v-col cols="12" md="2">
                                                    <button @click="coreFeature = 'all'"
                                                        :class="coreFeature == 'all' ? 'active' : ''"
                                                        class="core-features-button w-100">
                                                        All
                                                    </button>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <button @click="coreFeature = 'eis'"
                                                        :class="coreFeature == 'eis' ? 'active' : ''"
                                                        class="core-features-button w-100">
                                                        Employee Information Systems
                                                    </button>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <button @click="coreFeature = 'ess'"
                                                        :class="coreFeature == 'ess' ? 'active' : ''"
                                                        class="core-features-button w-100">
                                                        Employee Self Services
                                                    </button>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col v-for="(feature, featureIndex) in filteredCoreFeatures"
                                                    :key="`feature-${featureIndex}`" cols="12" md="4">
                                                    <div class="core-features-card p-4 mb-0">
                                                        <div class="text-center">
                                                            <img class="mb-2" :src="feature.image" :alt="feature.name" />
                                                        </div>
                                                        <h5 class="text-center">{{ feature.name }}</h5>
                                                        <p>
                                                            {{ feature.description }}
                                                        </p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>

                <GuestNewsletter />

                <GuestFooter />
            </v-row>

            <div class="text-center">
                <v-dialog v-model="dialog" width="500">
                    <v-card>
                        <v-card-text class="pt-5">
                            <div class="col-md-8 mx-auto text-center pb-5">
                                <img src="@/assets/graphics/check.png" alt="" class="img-fluid" />
                            </div>
                            <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                                {{ responseForDialog }}
                            </h3>
                        </v-card-text>

                        <v-divider class="mb-0"></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#069" text @click="dialog = false">
                                <strong>Close</strong>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <div class="text-center">
                <v-dialog v-model="dialog_2" width="500">
                    <v-card>
                        <v-card-text class="pt-5">
                            <div class="col-md-8 mx-auto text-center pb-5">
                                <img src="@/assets/graphics/cross-check.png" alt="" class="img-fluid" />
                            </div>
                            <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                                {{ responseForDialog }}
                            </h3>
                        </v-card-text>

                        <v-divider class="mb-0"></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#069" text @click="dialog_2 = false">
                                <strong>Close</strong>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

        </v-app>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { BASE_URL } from "@/main";
import GuestFooter from "@/components/GuestFooter";
import GuestNewsletter from "@/components/GuestNewsletter";
export default {
    components: {
        GuestFooter,
        GuestNewsletter
    },
    data() {
        return {
            coreFeature: 'all',
            coreFeaturesList: [
                {
                    name: 'Onboarding',
                    description: 'Onboarding: Streamlines the process of welcoming and integrating new employees into the organization.',
                    image: '/assets/images/core-features/onboarding.png',
                    feature: 'eis'
                },
                {
                    name: 'Employee Database',
                    description: 'KylianERP stores the comprehensive information about all staff members for easy access and management.',
                    image: '/assets/images/core-features/vector.png',
                    feature: 'eis'
                },
                {
                    name: 'Attendance Management',
                    description: 'Attendance management: Tracks and records employee attendance to ensure punctuality and productivity.',
                    image: '/assets/images/core-features/check.png',
                    feature: 'ess'
                },
                {
                    name: 'Personal Information Management',
                    description: 'Personnel information management: Stores and manages essential personnel data securely and efficiently.',
                    image: '/assets/images/core-features/employees.png',
                    feature: 'ess'
                },
                {
                    name: 'Timesheet Management',
                    description: 'Timesheet management: Manages work hours and project timelines for effective task allocation and monitoring.',
                    image: '/assets/images/core-features/timesheets.png',
                    feature: 'ess'
                },
                {
                    name: 'Leave Management',
                    description: 'Leave management: Tracks employee leave applications, approvals, and balances for smooth workflow planning.',
                    image: '/assets/images/core-features/leave.png',
                    feature: 'ess'
                },
                {
                    name: 'Requisition Management',
                    description: 'Facilitates the process of requesting and approving necessary resources within the organization.',
                    image: '/assets/images/core-features/requisition.png',
                    feature: 'ess'
                },
                {
                    name: 'Travel Management',
                    description: 'Travel management: Streamlines and organizes employee travel arrangements for seamless business trips.',
                    image: '/assets/images/core-features/travel.png',
                    feature: 'ess'
                },
                {
                    name: 'Medicals',
                    description: 'Medicals: Manages employee health records and medical benefits for a healthier workforce',
                    image: '/assets/images/core-features/medicals.png',
                    feature: 'ess'
                },
                {
                    name: 'Payroll Management',
                    description: 'Payroll management: Handles employee salary processing and tax deductions accurately and efficiently.',
                    image: '/assets/images/core-features/payroll.png',
                    feature: 'ess'
                },
                {
                    name: 'Recruitment',
                    description: 'Streamlines the hiring process by managing job postings, applicant tracking, and candidate evaluations.',
                    image: '/assets/images/core-features/recruitment.png',
                    feature: 'ess'
                },
                {
                    name: 'Dockets',
                    description: 'Dockets for law firms: Organizes legal documents and case-related information for law firms\' smooth operations.',
                    image: '/assets/images/core-features/dockets.png',
                    feature: 'ess'
                }
            ],
            menu: false,
            dialog: false,
            dialog_2: false,
            responseForDialog: "",
            formData: {
                name: "",
                organization: "",
                email: "",
                phone: "",
                // date: "",
                // source: "",
                // company_size: "",
                message: "",
                // services: []
            },
            btnTxt: "Submit",
            isLoading: false
        };
    },
    computed: {
        ...mapGetters([
            'authAllAvailableServices'
        ]),
        filteredCoreFeatures() {
            const _this = this;
            let coreFeatures = [..._this.coreFeaturesList];
            if (_this.coreFeature !== 'all') {
                coreFeatures = coreFeatures.filter(coreFeature => coreFeature.feature == _this.coreFeature);
            }
            return coreFeatures;
        },
        servicesNames() {
            let _this = this, humanResourcesServices = [], humanResourcesServicesNames = [], accountsServices = [], accountsServicesNames = [], result = [];
            if (_this.authAllAvailableServices) {
                humanResourcesServices = _this.authAllAvailableServices.human_resources_services;
                accountsServices = _this.authAllAvailableServices.accounting_services;
                humanResourcesServicesNames = humanResourcesServices.map(service => service.service_name);
                accountsServicesNames = accountsServices.map(service => service.service_name);
                result = [...humanResourcesServicesNames, ...accountsServicesNames];
            }
            return result;
        },
        productFocus() {
            let _this = this, value = '';
            _this.formData.services.forEach((service, index) => {
                value += index === (_this.formData.services.length - 1) ? `${service}.` : `${service}, `
            });
            return value;
        },
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
        }
    },
    methods: {
        ...mapActions([
            'fetch_available_services'
        ]),
        save(date) {
            this.$refs.menu.save(date);
        },
        request_demo() {
            let _this = this;
            _this.btnTxt = "";
            _this.isLoading = true;
            axios.post(`${BASE_URL}demo`, {
                contact_person: _this.formData.name,
                organization: _this.formData.organization,
                email: _this.formData.email,
                phone: _this.formData.phone,
                // date: _this.formData.date,
                // source: _this.formData.source,
                // company_size: _this.formData.company_size,
                message: _this.formData.message,
                // services: _this.productFocus
            })
                .then(function () {
                    _this.responseForDialog = 'Your contact message has been sent and received successfully. You\'ll be contacted shortly'
                    _this.dialog = true;
                    _this.formData.name = '';
                    _this.formData.organization = '';
                    _this.formData.email = '';
                    _this.formData.phone = '';
                    // _this.formData.date = '';
                    // _this.formData.source = '';
                    // _this.formData.company_size = '';
                    _this.formData.message = '';
                    // _this.formData.services = [];
                    _this.isLoading = false;
                    _this.btnTxt = "Submit";
                })
                .catch(function () {
                    _this.isLoading = false;
                    _this.btnTxt = "Submit";
                    // let errors = error.response.data.error || null;
                    _this.responseForDialog = /*errors ? (typeof errors === 'string' ? errors : null
              || error.response.data.error || error.response.data.message
              || errors.contact_person ? errors.contact_person[0] : null
                || errors.email ? errors.email[0] : null
                  || errors.phone ? errors.phone[0] : null
                    || errors.date ? errors.date[0] : null
                      || errors.company_size ? errors.company_size[0] : null
                        || errors.message ? errors.message[0] : null
                          || errors.services ? errors.services[0] : null
                            || errors.source ? errors.source[0] : null) : null
            || */'Your request couldn\'t be sent';
                    _this.dialog_2 = true;
                })
                .then(function () {
                    _this.isLoading = false;
                    _this.btnTxt = "Submit";
                });
        }
    },
    mounted() {
        document.title = "Kylian ERP - Core Features";
        this.fetch_available_services();
    }
};
</script>
  
<style lang="scss">
$new-button-color: #225BA6;
$primary-color: #069;
$text-field-color: #4e4e52;

.new-create-submit {
    span {
        font-family: Inter !important;
    }
}

.text-new-primary {
    color: $new-button-color !important;
}

.v-application .primary--text {
    color: $primary-color !important;
}

.fa-eye,
.fa-eyeslash {
    color: $primary-color !important;
}

// .v-text-field__suffix, .v-text-field__prefix, input {
//     color: $text-field-color !important;
//     background-color: #00669914;
//     padding: 10px;
//     height: 48px;
// }
.v-text-field__suffix {
    border-radius: 0px 5px 5px 0px;
    padding-top: 13px;
    padding-left: 8px !important;
}

.v-text-field__prefix {
    border-radius: 5px 0px 0px 5px;
    padding-top: 13px;
    padding-right: 8px !important;
    text-decoration: underline;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
    label {
        margin-left: 2%;
    }

    input {
        text-indent: 3%;
    }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.v-text-field__details {
    padding-left: 0 !important;
}

.theme--light.v-messages {
    color: $primary-color !important;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

.remove-bottom-hint div .v-text-field__details {
    display: none;
}
</style>
<style lang="scss" scoped>
$new-button-color: #2081FF;
$primary-color: #069;

@media (max-width: 991px) {
    .create-submit {
        height: 45px !important;
    }

    .v-form {
        margin: 0 !important;
    }
}

.v-form {
    margin: 0 20%;
}

.svg-login {
    position: absolute;
    top: 8%;
    right: 22%;
}

#small-link {
    color: $primary-color !important;
}

.space-top {
    padding-top: 40px;
}

.create-submit {
    width: 100%;
    height: 60px;
    border-radius: 10px !important;
    background-color: #069 !important;
    text-transform: initial !important;
    font-weight: 600 !important;

    span {
        text-transform: initial !important;
    }
}

.new-create-submit {
    width: 100%;
    height: 60px;
    border-radius: 10px !important;
    background-color: $new-button-color !important;
    text-transform: initial !important;
    font-weight: 600 !important;

    span {
        text-transform: initial !important;
        font-family: Inter !important;
    }
}

h3 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.6252632141113281px;
    text-align: center;
    color: #111d5e;
}

label {
    color: #999 !important;
}
</style>

<style lang="scss">
.home-page-subscriber .v-text-field--outlined .v-label,
.home-page-subscriber .theme--light.v-input input,
.theme--light.v-input textarea {
    color: #FFFFFF !important;
}

.home-page-subscriber .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
    color: #FFFFFF !important;
}
</style>
  