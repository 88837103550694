<template>
  <div>
    <v-row class="container pb-0">
      <v-col cols="12" md="12">
        <h3 class="pt-0 text-left" style="font-weight: 400; font-size: 24px;">
          Work Information <span v-if="editing">(editing...)</span>
        </h3>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :value="currentEmployee.employee_fristname" solo :disabled="!editing"
            style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.firstname" :label="currentEmployee.employee_fristname + ''" solo
            :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :label="currentEmployee.employee_middle_name ? '' : 'Add middle name'
            " :value="currentEmployee.employee_middle_name
              ? currentEmployee.employee_middle_name
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.middlename" :placeholder="currentEmployee.employee_middle_name ? '' : 'Enter middle name'
            " :label="currentEmployee.employee_middle_name
              ? currentEmployee.employee_middle_name
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :value="currentEmployee.employee_lastname" solo :disabled="!editing"
            style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.lastname" :label="currentEmployee.employee_lastname + ''" solo
            :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :value="currentEmployee.employee_email" solo :disabled="!editing"
            style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.email" :label="currentEmployee.employee_email + ''" solo
            :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :label="currentEmployee.employee_phone1 ? '' : 'Add Phone 1'" :value="currentEmployee.employee_phone1
              ? currentEmployee.employee_phone1
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.phone1" :placeholder="currentEmployee.employee_phone1 ? '' : 'Enter phone 1'
            " :label="currentEmployee.employee_phone1
              ? currentEmployee.employee_phone1
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <!-- <v-text-field
                v-else-if=""
                label="Phone 1"
                solo
                ></v-text-field> -->
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :label="currentEmployee.employee_phone2 ? '' : 'Add Phone 2'" :value="currentEmployee.employee_phone2
              ? currentEmployee.employee_phone2
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.phone2" :placeholder="currentEmployee.employee_phone2 ? '' : 'Enter phone 2'
            " :label="currentEmployee.employee_phone2
              ? currentEmployee.employee_phone2
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Department"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.employee_department ? '' : 'Add department'" :value="currentEmployee.employee_department
              ? currentEmployee.employee_department
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-select v-else v-model="formData.department" item-color="#069" :items="departmentName" :label="currentEmployee.employee_department
              ? currentEmployee.employee_department
              : 'Select department'
            " solo color="#069" hint="*Select department"></v-select>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :label="currentEmployee.employee_manager ? '' : 'Add manager'" :value="currentEmployee.employee_manager
              ? currentEmployee.employee_manager
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.manager" :placeholder="currentEmployee.employee_manager ? '' : 'Enter manager name'
            " :label="currentEmployee.employee_manager
              ? currentEmployee.employee_manager
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <!-- <v-text-field
                v-else-if=""
                label="Phone 1"
                solo
                ></v-text-field> -->
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :value="currentEmployee.employee_position" solo :disabled="!editing"
            style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.position" :label="currentEmployee.employee_position + ''" solo
            :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-row class="container pb-0">
      <v-col cols="12" md="12">
        <h3 class="pt-0 text-left" style="font-weight: 400; font-size: 24px;">
          Personal Information
        </h3>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Nationality"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.employee_nationality ? '' : 'Add nationality'
            " :value="currentEmployee.employee_nationality
              ? currentEmployee.employee_nationality
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.nationality" :placeholder="currentEmployee.employee_nationality ? '' : 'Enter nationality'
            " :label="currentEmployee.employee_nationality
              ? currentEmployee.employee_nationality
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="State"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.employee_state ? '' : 'Add state'" :value="currentEmployee.employee_state
              ? currentEmployee.employee_state
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.state"
            :placeholder="currentEmployee.employee_state ? '' : 'Enter state'" :label="currentEmployee.employee_state
              ? currentEmployee.employee_state
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Local Government Area"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.employee_local_government
              ? ''
              : 'Add local government'
            " :value="currentEmployee.employee_local_government
              ? currentEmployee.employee_local_government
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.local_government" :placeholder="currentEmployee.employee_local_government
              ? ''
              : 'Enter local government'
            " :label="currentEmployee.employee_local_government
              ? currentEmployee.employee_local_government
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field v-if="!editing" :value="currentEmployee.employee_date_of_birth" solo :disabled="!editing"
            style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.dob" :label="currentEmployee.employee_date_of_birth + ''" solo
            :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Place of Birth"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.employee_place_of_birth
              ? ''
              : 'Add place of birth'
            " :value="currentEmployee.employee_place_of_birth
              ? currentEmployee.employee_place_of_birth
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.place_of_birth" :placeholder="currentEmployee.employee_place_of_birth
              ? ''
              : 'Enter place of birth'
            " :label="currentEmployee.employee_place_of_birth
              ? currentEmployee.employee_place_of_birth
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                :value="currentEmployee.employee_sex"
                :disabled="!editing"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :value="currentEmployee.employee_sex" solo :disabled="!editing"
            style="color: #111D5E !important;"></v-text-field>
          <v-select v-else v-model="formData.sex" item-color="#069" :items="sex" :label="currentEmployee.employee_sex"
            solo color="#069" hint="*Select sex"></v-select>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <v-text-field label="Means of Identification" solo></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Identity Card Number"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.employee_identity_card_number
              ? ''
              : 'Add identity card number'
            " :value="currentEmployee.employee_identity_card_number
              ? currentEmployee.employee_identity_card_number
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.identity_card_number" :placeholder="currentEmployee.employee_identity_card_number
              ? ''
              : 'Enter identity card number'
            " :label="currentEmployee.employee_identity_card_number
              ? currentEmployee.employee_identity_card_number
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4"> </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Marital Status"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.employee_marital_status
              ? ''
              : 'Add marital status'
            " :value="currentEmployee.employee_marital_status
              ? currentEmployee.employee_marital_status
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-select v-else v-model="formData.marital_status" item-color="#069" :items="marital_status" :label="currentEmployee.employee_marital_status
              ? currentEmployee.employee_marital_status
              : 'Select marital status'
            " solo color="#069" hint="*Select marital status"></v-select>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Number of Children"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.employee_number_of_children
              ? ''
              : 'Add number of children'
            " :value="currentEmployee.employee_number_of_children
              ? currentEmployee.employee_number_of_children
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-select v-else v-model="formData.number_of_children" item-color="#069" :items="number_of_children" :label="currentEmployee.employee_number_of_children
              ? currentEmployee.employee_number_of_children
              : 'Select number of children'
            " solo color="#069" hint="*Select number of children"></v-select>
        </v-col>
        <v-col class="pb-0 pt-0" cols="12" sm="12">
          <v-textarea v-if="!editing" class="textarea-for-package-type" solo name="input-7-4" style="color: #111D5E;"
            :value="currentEmployee.employee_address" :disabled="!editing"></v-textarea>
          <v-textarea v-else v-model="formData.address" class="textarea-for-package-type" solo name="input-7-4"
            style="color: #111D5E;" :label="currentEmployee.employee_address + ''" :disabled="!editing"></v-textarea>
        </v-col>
      </v-row>
    </v-container>

    <v-row class="container pb-0">
      <v-col cols="12" md="12">
        <h3 class="pt-0 text-left" style="font-weight: 400; font-size: 24px;">
          Guarantor
        </h3>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="First Name"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.guarantor_frist_Name
              ? ''
              : 'Add guarantor first name'
            " :value="currentEmployee.guarantor_frist_Name
              ? currentEmployee.guarantor_frist_Name
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.guarantor_firstname" :placeholder="currentEmployee.guarantor_frist_Name
              ? ''
              : 'Enter guarantor first name'
            " :label="currentEmployee.guarantor_frist_Name
              ? currentEmployee.guarantor_frist_Name
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Last Name"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.guarantor_last_Name
              ? ''
              : 'Add guarantor last name'
            " :value="currentEmployee.guarantor_last_Name
              ? currentEmployee.guarantor_last_Name
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.guarantor_lastname" :placeholder="currentEmployee.guarantor_last_Name
              ? ''
              : 'Enter guarantor last name'
            " :label="currentEmployee.guarantor_last_Name
              ? currentEmployee.guarantor_last_Name
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Phone Number"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.guarantor_phone_number
              ? ''
              : 'Add guarantor phone number'
            " :value="currentEmployee.guarantor_phone_number
              ? currentEmployee.guarantor_phone_number
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.guarantor_phone" :placeholder="currentEmployee.guarantor_phone_number
              ? ''
              : 'Enter guarantor phone number'
            " :label="currentEmployee.guarantor_phone_number
              ? currentEmployee.guarantor_phone_number
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="4" md="4">
          <!-- <v-text-field
                label="Email Address"
                solo
                ></v-text-field> -->
          <v-text-field v-if="!editing" :label="currentEmployee.guarantor_email_address
              ? ''
              : 'Add guarantor email address'
            " :value="currentEmployee.guarantor_email_address
              ? currentEmployee.guarantor_phone_number
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
          <v-text-field v-else v-model="formData.guarantor_email" :placeholder="currentEmployee.guarantor_email_address
              ? ''
              : 'Enter guarantor email address'
            " :label="currentEmployee.guarantor_email_address
              ? currentEmployee.guarantor_email_address
              : ''
            " solo :disabled="!editing" style="color: #111D5E !important;"></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="12" sm="12">
          <v-textarea v-if="!editing" class="textarea-for-package-type" solo name="input-7-4" style="color: #111D5E;"
            :label="currentEmployee.guarantor_address ? '' : 'Add guarantor address'
            " :value="currentEmployee.guarantor_address
              ? currentEmployee.guarantor_address
              : ''
            " :disabled="!editing"></v-textarea>
          <v-textarea v-else v-model="formData.guarantor_address" class="textarea-for-package-type" solo
            name="input-7-4" style="color: #111D5E;" :placeholder="currentEmployee.guarantor_address ? '' : 'Enter guarantor address'
            " :label="currentEmployee.guarantor_address
              ? currentEmployee.guarantor_address
              : ''
            " :disabled="!editing"></v-textarea>
        </v-col>
        <v-col class="pb-0 pt-0 text-center" cols="12" sm="12">
          <v-btn v-if="editing" type="button" x-large dark class="create-submit-package" @click="submitUpdate">
            {{ btnTxt }}
            <v-icon :style="!isLoading ? 'display: none' : ''">fas fa-circle-notch fa-spin</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters, mapActions } from "vuex";
import { ASSETS_URL } from "@/main";
export default {
  props: {
    editing: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      btnTxt: "Save Update",
      isLoading: false,
      sex: ["Male", "Female", "Other"],
      marital_status: ["Single", "Married", "Other"],
      number_of_children: ["None", "1", "2", "3", "4", "5+"],
      formData: {
        firstname: "",
        lastname: "",
        middlename: "",
        email: "",
        phone1: "",
        phone2: "",
        department: "",
        position: "",
        manager: "",
        nationality: "",
        state: "",
        local_government: "",
        dob: "",
        place_of_birth: "",
        sex: "",
        means_of_identification: "",
        identity_card_number: "",
        marital_status: "",
        number_of_children: "",
        address: "",
        guarantor_firstname: "",
        guarantor_lastname: "",
        guarantor_phone: "",
        guarantor_email: "",
        guarantor_address: ""
      },
      departmentName: []
    };
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyEmployees",
      "authCompanyDepartments",
      "authCompanyData"
    ]),
    currentEmployee() {
      let _this = this;
      return this.authCompanyEmployees.filter(function (employee) {
        return employee.id === parseInt(_this.$route.params.employee_id);
      })[0];
    },
    imgUrl() {
      return ASSETS_URL;
    }
  },
  methods: {
    ...mapActions(["fetch_company_employees"]),
    submitUpdate() {
      let _this = this;
      _this.isLoading = !_this.isLoading;
      if (_this.isLoading) {
        _this.btnTxt = "";
        let updateData = new FormData();

        updateData.append("id", _this.$route.params.employee_id);
        updateData.append("company_id", _this.authCompanyData.id);
        updateData.append("employee_fristname", _this.formData.firstname);
        updateData.append("employee_lastname", _this.formData.lastname);
        updateData.append("employee_middle_name", _this.formData.middlename);
        updateData.append(
          "employee_Identity_cardnumber",
          _this.formData.identity_card_number
        );
        updateData.append(
          "employee_maritalstatus",
          _this.formData.marital_status
        );
        updateData.append(
          "employee_place_of_birth",
          _this.formData.place_of_birth
        );
        updateData.append("employee_email", _this.formData.email);
        updateData.append("employee_phone1", _this.formData.phone1);
        updateData.append("employee_phone2", _this.formData.phone2);
        updateData.append("employee_date_of_birth", _this.formData.dob);
        updateData.append("employee_sex", _this.formData.sex);
        updateData.append("employee_department", _this.formData.department);
        updateData.append(
          "employee_number_of_children",
          _this.formData.number_of_children
        );
        updateData.append("employee_nationality", _this.formData.nationality);
        updateData.append("employee_state", _this.formData.state);
        updateData.append(
          "employee_local_government",
          _this.formData.local_government
        );
        updateData.append(
          "employee_means_identification",
          _this.formData.means_of_identification
        );
        updateData.append("employee_manager", _this.formData.manager);
        updateData.append(
          "guarantor_first_Name",
          _this.formData.guarantor_firstname
        );
        updateData.append(
          "guarantor_last_Name",
          _this.formData.guarantor_lastname
        );
        updateData.append(
          "guarantor_email_address",
          _this.formData.guarantor_email
        );
        // updateData.append('profile_image_url', _this.formData.);
        updateData.append(
          "guarantor_address",
          _this.formData.guarantor_address
        );
        updateData.append("employee_position", _this.formData.position);
        updateData.append(
          "guarantor_phone_number",
          _this.formData.guarantor_phone
        );
        updateData.append(
          "company_unique_id",
          _this.authCompanyData.company_unique_id
        );

        const Toast = _this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", _this.$swal.stopTimer);
            toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
          }
        });

        axios
          .post(`${BASE_URL}editemployee`, updateData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(function (response) {
            const RESPONSE = response.data;
            console.log(RESPONSE);
            _this.fetch_company_employees();
            Toast.fire({
              icon: "success",
              title: "Employee details updated successfully"
            });
            _this.formData.firstname = "";
            _this.formData.middlename = "";
            _this.formData.lastname = "";
            _this.formData.email = "";
            _this.formData.phone1 = "";
            _this.formData.phone2 = "";
            _this.formData.department = "";
            _this.formData.manager = "";
            _this.formData.position = "";
            _this.formData.nationality = "";
            _this.formData.state = "";
            _this.formData.local_government = "";
            _this.formData.dob = "";
            _this.formData.place_of_birth = "";
            _this.formData.sex = "";
            _this.formData.means_of_identification = "";
            _this.formData.identity_card_number = "";
            _this.formData.marital_status = "";
            _this.formData.number_of_children = "";
            _this.formData.address = "";
            _this.formData.guarantor_firstname = "";
            _this.formData.guarantor_lastname = "";
            _this.formData.guarantor_phone = "";
            _this.formData.guarantor_email = "";
            _this.formData.guarantor_address = "";

            _this.$emit("updated");
          })
          .catch(function (error) {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: "Error: There was an error updating the employee's details"
            });
          })
          .then(function () {
            _this.btnTxt = "Save Update";
            _this.isLoading = false;
          });
      } else {
        _this.btnTxt = "Save Update";
      }
    }
  },
  watch: {
    editing(newValue) {
      if (!newValue) {
        this.isLoading = false;
        this.btnTxt = "Save Update";

        this.formData.firstname = "";
        this.formData.middlename = "";
        this.formData.lastname = "";
        this.formData.email = "";
        this.formData.phone1 = "";
        this.formData.phone2 = "";
        this.formData.department = "";
        this.formData.manager = "";
        this.formData.position = "";
        this.formData.nationality = "";
        this.formData.state = "";
        this.formData.local_government = "";
        this.formData.dob = "";
        this.formData.place_of_birth = "";
        this.formData.sex = "";
        this.formData.means_of_identification = "";
        this.formData.identity_card_number = "";
        this.formData.marital_status = "";
        this.formData.number_of_children = "";
        this.formData.address = "";
        this.formData.guarantor_firstname = "";
        this.formData.guarantor_lastname = "";
        this.formData.guarantor_phone = "";
        this.formData.guarantor_email = "";
        this.formData.guarantor_address = "";
      }
    }
  },
  mounted() {
    this.authCompanyDepartments.forEach(department => {
      this.departmentName.push(department.department_name);
    });
  }
};
</script>

<style scoped lang="scss">
.create-submit-package {
  width: 30%;
  height: 60px !important;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
  }
}
</style>
<style>
.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: #111d5e !important;
}

.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #111d5e !important;
}
</style>
