<template>
    <div class="card-body row py-4">
        <div class="col-sm-6" style="align-self: center;">
            <h4>Your Goals</h4>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Goal Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Goal Description
                                    </th>
                                    <th class="text-center" scope="col">Progress</th>
                                    <th class="text-center" scope="col">Goal Achievement</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="goal in getTrueEmployeeAssignedGoals(validEmployeeId)" :key="goal.id">
                                    <td class="text-left">{{ goal.goal_name }}</td>
                                    <td v-if="goal.description" class="text-left">{{ goal.description }}</td>
                                    <td v-else class="text-left"><i>No description</i></td>
                                    <td class="text-center">
                                        <div class="progress mb-3">
                                            <div 
                                            class="progress-bar bg-secondary" 
                                            role="progressbar" 
                                            :style="
                                            'width: ' + (
                                                getGoalCompletionPercentage(goal.goal_id, validEmployeeId)
                                            ) + '%'" 
                                            :aria-valuenow="getGoalCompletionPercentage(goal.goal_id, validEmployeeId)" 
                                            aria-valuemin="0" 
                                            aria-valuemax="100">
                                            {{ 
                                                getGoalCompletionPercentage(goal.goal_id, validEmployeeId)
                                            }}%</div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <button 
                                        @click="(
                                            current_goal_id = goal.goal_id, 
                                            view_goal_achievement_dialog = true
                                            )" 
                                            type="button" 
                                            class="btn btn-secondary btn-sm ripple">
                                            Manage
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        
        <!-- Dialog for viewing goal achievement -->
        <v-dialog v-model="view_goal_achievement_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <div class="row">
                        <div class="col-9 pb-0">
                            <span class="headline">{{ currentAssignedGoal.goal_name }}</span>
                        </div>
                        <div class="col-3 pb-0">
                            <div style="float: right;">
                                <span>Target Progress</span>
                                <span class="progress mb-3 w-100">
                                    <span 
                                    class="progress-bar bg-secondary" 
                                    role="progressbar" 
                                    :style="'width: ' + (
                                        getGoalCompletionPercentage(currentAssignedGoal.goal_id, validEmployeeId)
                                    ) + '%'" 
                                    :aria-valuenow="getGoalCompletionPercentage(currentAssignedGoal.goal_id, validEmployeeId)" 
                                    aria-valuemin="0" 
                                    aria-valuemax="100"
                                    >
                                    {{ 
                                        getGoalCompletionPercentage(currentAssignedGoal.goal_id, validEmployeeId)
                                    }}%</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" class="remove-hint pb-0">
                                    <h4 style="color: #111d5e;">KRAs 
                                        <span  class="headline float-right">
                                            <button @click="add_kra_dialog = true" style="float: right;" type="button"
                                            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                                Add KRA
                                            </button>
                                        </span>
                                    </h4>
                                </v-col>
                                <v-col v-if="getKRAsInGoalWithUnapproved(currentAssignedGoal.goal_id).length >= 1" cols="12" >
                                    <div class="table-responsive">
                                        <table class="table mb-2">
                                            <thead>
                                                <tr>
                                                    <th class="text-left" scope="col">
                                                        S/N
                                                    </th>
                                                    <th class="text-left" scope="col">
                                                        KRA Name
                                                    </th>
                                                    <th class="text-left" scope="col">
                                                        KRA Weightage (%)
                                                    </th>
                                                    <th class="text-left" scope="col">
                                                        Start Date
                                                    </th>
                                                    <th class="text-left" scope="col">
                                                        End Date
                                                    </th>
                                                    <th class="text-center" scope="col">
                                                        Completion Status
                                                    </th>
                                                    <th class="text-center" scope="col">
                                                        Approval Status
                                                    </th>
                                                    <th class="text-center" scope="col">
                                                        Update
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(kra, index) in getKRAsInGoalWithUnapproved(currentAssignedGoal.goal_id)" :key="'goal-kra-' + index">
                                                    <td class="text-left">{{ index + 1 }}</td>
                                                    <td class="text-left">{{ kra.kra }}</td>
                                                    <td class="text-left">{{ `${kra.weightage}%` }}</td>
                                                    <td v-if="kra.from_date" class="text-left">{{ kra.from_date }}</td>
                                                    <td v-else class="text-left">
                                                        <i>No date added</i>
                                                    </td>
                                                    <td v-if="kra.to_date" class="text-left">{{ kra.to_date }}</td>
                                                    <td v-else class="text-left">
                                                        <i>No date added</i>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="getEmployeeKRAInfo(kra.id, validEmployeeId) && getEmployeeKRAInfo(kra.id, validEmployeeId).is_completed == 1" class="badge badge-success text-white">COMPLETED</span>
                                                        <span v-else class="badge badge-warning text-white">INCOMPLETE</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="kra.status == 1" class="badge badge-success text-white">APPROVED</span>
                                                        <span v-else class="badge badge-warning text-white">PENDING</span>
                                                    </td>
                                                    <td v-if="kra.status == 1" class="text-center">
                                                        <span v-if="getEmployeeKRAInfo(kra.id, validEmployeeId) && getEmployeeKRAInfo(kra.id, validEmployeeId).is_completed == 1">
                                                            <i @click="markAsUncompleted(getEmployeeKRAInfo(kra.id, validEmployeeId).employee_kra_id)" class="py-0 text-center fas fa-times mr-2 text-danger" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                        </span>
                                                        <span v-else>
                                                            <i @click="markAsCompleted(kra.id, currentAssignedGoal.goal_id)" class="py-0 text-center fas fa-check mr-2 text-success" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                        </span>
                                                    </td>
                                                    <td v-else class="text-center">
                                                        <i>KRA unapproved</i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </v-col>
                                <!-- <v-col cols="12" sm="3" class="remove-hint mt-10" style="align-self: center;">
                                    <button
                                        @click="setAchievedTarget()"
                                        type="button"
                                        class="btn btn-secondary btn-sm m-1 ripple"
                                        style="width: 50%;"
                                    >
                                        {{ achieved_target_button_text }}
                                        <v-icon
                                            :style="
                                            !achieved_target_button_loading
                                                ? 'display: none;'
                                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                            "
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col> -->
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="view_goal_achievement_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for viewing goal achievement -->

        <!-- Dialog for adding KRAs -->
        <v-dialog v-model="add_kra_dialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add KRA</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">KRA <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="add_kra_form.kra" placeholder="Enter the KRA name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Weightage (%) <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="add_kra_form.weightage" placeholder="Enter the KRA weightage" type="number" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Start Date </span>
                                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="add_kra_form.from_date"
                                                    placeholder="Enter KRA start date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker1" v-model="add_kra_form.from_date" @change="save1"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">End Date </span>
                                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="add_kra_form.to_date"
                                                    placeholder="Enter KRA end date"
                                                    solo
                                                    dense
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker color="#069" ref="picker2" v-model="add_kra_form.to_date" @change="save2"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="add_kra_button_loading ? null : add_kra()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            {{ add_kra_button_text }}
                                            <v-icon
                                                :style="
                                                !add_kra_button_loading
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_kra_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding KRAs -->

    </div>
</template>

<script>
import axios from 'axios';
import { 
    mapActions, 
    mapGetters 
} from 'vuex';
import { BASE_URL } from '@/main';
export default {
    props: {
        getTrueEmployeeAssignedGoals: Function,
        getGoalName: Function,
        getKRAsInGoal: Function,
        getKRAsInGoalWithUnapproved: Function,
        getEmployeeKRAInfo: Function,
        getGoalCompletionPercentage: Function
    },
    data() {
        return {
            current_goal_id: null,
            achieved_target_button_text: "Save",
            achieved_target_button_loading: false,
            view_goal_achievement_dialog: false,

            add_kra_dialog: false,
            add_kra_form: {
                goal: "",
                kra: "",
                weightage: "",
                from_date: "",
                to_date: "",
            },
            add_kra_button_text: "Submit",
            add_kra_button_loading: false,


            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
        }
    },
    computed: {
        ...mapGetters([
            'validEmployeeId',
            'authCompanyData'
        ]),
        currentAssignedGoal() {
            let _this = this, object = null;
            object = _this.getTrueEmployeeAssignedGoals(_this.validEmployeeId).filter(EAG => EAG.goal_id == _this.current_goal_id);
            if (object.length >= 1) {
                object = object[0];
            }
            return object;
        },
    },
    watch: {
        menu1(val) {
            val && setTimeout(() => (this.$refs.picker1.activePicker = "YEAR"));
        },
        menu2(val) {
            val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
        },
        menu3(val) { 
            val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
        },
        menu4(val) {
            val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
        },
    },
    methods: {
        ...mapActions([
            'fetch_company_employee_kras',
            'fetch_employee_assigned_goals'
        ]),
        add_kra() {
            let _this = this;
            if (_this.add_kra_form.weightage < 0) {
                Toast.fire({
                    icon: "error",
                    title: `KRA weightage must be 0 or higher`,
                });
                return;
            }
            if (_this.add_kra_form.weightage > 100) {
                Toast.fire({
                    icon: "error",
                    title: `KRA weightage must be 100 or less`,
                });
                return;
            }
            _this.add_kra_button_text = "";
            _this.add_kra_button_loading = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios
                .post(`${BASE_URL}createkra`, {
                    company_id: _this.authCompanyData.id,
                    goal_id: _this.current_goal_id || _this.currentAssignedGoal.goal_id,
                    kra: _this.add_kra_form.kra,
                    weightage: _this.add_kra_form.weightage,
                    employee_id: _this.validEmployeeId,
                    added_by: _this.validEmployeeId,
                    from_date: _this.add_kra_form.from_date,
                    to_date: _this.add_kra_form.to_date,
                    status: 0
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The KRA was added successfully",
                    });
                    _this.add_kra_dialog = false;
                    _this.add_kra_form.goal = "";
                    _this.add_kra_form.kra = "";
                    _this.add_kra_form.from_date = "";
                    _this.add_kra_form.to_date = "";
                    _this.fetch_company_KRAs();
                    _this.add_kra_button_loading = false;
                    _this.add_kra_button_text = "Submit";
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.goal_id
                                ? errors.goal_id[0]
                                : errors.kra
                                ? errors.kra[0]
                                : errors.from_date
                                ? errors.from_date[0]
                                : errors.to_date
                                ? errors.to_date[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The KRA couldn't be added",
                    });
                })
                .then(function () {
                    //
                });
        },
        markAsUncompleted(employee_kra_id) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: `Are you sure you want unmark this KRA as complete?`,
                    showCancelButton: true,
                    confirmButtonText: `Yes, Proceed`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}unmarkascompleted`, {
                                employee_kra_id
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: `KRA unmarked complete`,
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: `KRA couldn't be unmarked complete`,
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_employee_kras();
                            });
                    }
                });
        },
        markAsCompleted(kra_id, goal_id) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: `Are you sure you want mark this KRA as complete?`,
                    showCancelButton: true,
                    confirmButtonText: `Yes, Proceed`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}markascompleted`, {
                                company_id: _this.authCompanyData.id,
                                employee_id: _this.validEmployeeId,
                                kra_id, 
                                goal_id
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: `KRA marked complete`,
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: `KRA couldn't be marked complete`,
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_employee_kras();
                            });
                    }
                });
        },


        save1(date) {
            this.$refs.menu1.save(date);
        },
        save2(date) {
            this.$refs.menu2.save(date);
        },
        save3(date) {
            this.$refs.menu3.save(date);
        },
        save4(date) {
            this.$refs.menu4.save(date);
        },
    }
}
</script>

<style>

</style>