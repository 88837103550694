<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">
          <div class="dropdown animate__animated animate__fadeIn">
            <button v-if="create_project == 1 || (create_project == 2 && permissionIsAssigned('project-create'))"
              @click="tweak_display()" style="float: right;" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              {{
                  create_project == 1
                    ? "View Projects"
                    : create_project == 2
                      ? "Create Project"
                      : ""
              }}
            </button>
            <template v-else>
              <button v-if="create_project == 1" @click="create_project = 2" style="float: right;" type="button"
                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                {{ "View Projects" }}
              </button>
            </template>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div v-if="create_project == 1" class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb"
          :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null"
          style="margin-top: 1rem;">
          <h1>
            <span class="col-4">Create a Project</span>
          </h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Project Title <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field v-model="formData.title" placeholder="Enter project title" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Project Description <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field v-model="formData.description" placeholder="Enter project description" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Project Status <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select v-model="formData.status" item-color="#069" :items="['Starting', 'In Progress', 'Completed']"
                  placeholder="Select project status" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Budget</span>
                <v-text-field v-model="formData.budget" placeholder="Enter project budget" solo class="mt-2"
                  type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Estimated Completion Date <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="formData.est_completion_date" placeholder="Estimated completion date" solo class="mt-2"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" v-model="formData.est_completion_date" :min="
                    new Date(new Date().getTime() - 2678000000)
                      .toISOString()
                      .substr(0, 10)
                  " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substr(0, 10)
" @change="save"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Color <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select v-model="formData.color_code" item-color="#069"
                  :items="['red', 'blue', 'green', 'purple', 'darkorange', 'brown', 'black', 'darkgrey']"
                  placeholder="Select project color" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Date of Completion</span>
                <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="formData.completed_at" placeholder="Date of completion" solo class="mt-2" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker3" v-model="formData.completed_at" :min="
                    new Date(new Date().getTime() - 2678000000)
                      .toISOString()
                      .substr(0, 10)
                  " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substr(0, 10)
" @change="save3"></v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6" class="remove-hint">
              <span style="color: #111d5e;">Unit Cost <i class="fas fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field
                  v-model="formData.unit_cost"
                  placeholder="Enter unit cost"
                  solo
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
              <span style="color: #111d5e;">Acquisition Cost <i class="fas fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field
                  v-model="formData.acquisition_cost"
                  placeholder="Enter acquisition cost"
                  solo
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
              <span style="color: #111d5e;">Acquisition Date <i class="fas fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="#069"
                      prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="formData.acquisition_date"
                      placeholder="Select acquisition date"
                      solo
                  class="mt-2"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#069"
                    ref="picker"
                    v-model="formData.acquisition_date"
                    :min="
                      new Date(new Date().getTime() + 86400000)
                        .toISOString()
                        .substr(0, 10)
                    "
                    :max="
                      new Date(new Date().getTime() + 86400000000)
                        .toISOString()
                        .substr(0, 10)
                    "
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
              <span style="color: #111d5e;">Location Assigned To <i class="fas fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field
                  v-model="formData.location_assigned_to"
                  placeholder="Enter location assigned to"
                  solo
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
              <span style="color: #111d5e;">Additional Comments</span>
                <v-text-field
                  v-model="formData.additional_comments"
                  placeholder="Enter additonal comments"
                  solo
                  class="mt-2"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" class="remove-hint text-center">
                <button @click="create_project_submit ? null : add_project()" type="button"
                  class="btn btn-secondary m-1 ripple w-50 py-3">
                  {{ create_project_text }}
                  <v-icon :style="
                    !create_project_submit
                      ? 'display: none;'
                      : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                  ">
                    fas fa-circle-notch fa-spin
                  </v-icon>
                </button>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div v-else-if="create_project == 2" class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb mb-0"
          :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null"
          style="margin-top: 1rem;">
          <h1>
            <span class="col-4">Company Projects</span>
          </h1>
        </div>
        <div class="main-content mt-0 row pr-0"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
          <template v-for="p in authCompanyProjects">
            <div
              v-if="userType == 1 || authEmployeeDataForCompany.id == p.created_by || isAssignedProject(authEmployeeDataForCompany.id, p.id)"
              :key="p.id" class="col-md-4 col-sm-6 col-lg-4">
              <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div class="card-header pb-2 pt-4 row" :style="'background: ' + p.color_code">
                  <div class="col-lg-8 col-md-8 col-12 py-0">
                    <h3 style="font-size: 120% !important; font-weight: 100; text-align: left; color: #fff !important;"
                      class="mb-0">
                      {{ p.title }}
                    </h3>
                  </div>
                  <div v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'"
                    class="col-lg-4 col-md-4 col-sm-4 py-0">
                    <div style="font-size: 120% !important; font-weight: 100; text-align: left; color: #fff !important;"
                      class="mb-0">
                      <v-tooltip
                        v-if="permissionIsAssigned('project-delete') && (adminEmployeeData.id == p.created_by || authEmployeeDataForCompany.id == p.created_by)"
                        bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" class="float-right py-0 text-center far fa-trash mt-1"
                            @click="delete_project(p)"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>Delete project</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="permissionIsAssigned('project-update') && (adminEmployeeData.id == p.created_by || authEmployeeDataForCompany.id == p.created_by)"
                        bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" class="float-right py-0 text-center far fa-edit mr-2 mt-1"
                            @click="edit_project(p)"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>Edit project</span>
                      </v-tooltip>
                      <v-tooltip v-if="permissionIsAssigned('tasks-create')" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" @click="add_task_launcher(p)"
                            class="float-right py-0 text-center fas fa-plus mr-2 mt-1"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>Add task</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" @click="view_tasks(p)"
                            class="float-right py-0 text-center far fa-eye mr-2 mt-1"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>View tasks</span>
                      </v-tooltip>
                      <v-tooltip v-if="permissionIsAssigned('project-update')" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" @click="add_to_project_launcher(p)"
                            class="float-right py-0 text-center far fa-user-plus mr-2 mt-1"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>Add employees to project</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <p class="text-muted mb-0 col-lg-12 col-md-12 col-sm-12 py-0"
                    style="font-size: 80%; font-weight: 100; color: #f3f3f3 !important;">{{ p.description }}</p>
                  <div v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'" class="col-12 py-0">
                    <div style="font-size: 120% !important; font-weight: 100; text-align: left; color: #fff !important;"
                      class="mb-0">
                      <v-tooltip
                        v-if="permissionIsAssigned('project-delete') && (adminEmployeeData.id == p.created_by || authEmployeeDataForCompany.id == p.created_by)"
                        bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" class="float-right py-0 text-center far fa-trash mt-1"
                            @click="delete_project(p)"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>Delete project</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="permissionIsAssigned('project-update') && (adminEmployeeData.id == p.created_by || authEmployeeDataForCompany.id == p.created_by)"
                        bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" class="float-right py-0 text-center far fa-edit mr-2 mt-1"
                            @click="edit_project(p)"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>Edit project</span>
                      </v-tooltip>
                      <v-tooltip v-if="permissionIsAssigned('tasks-create')" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" @click="add_task_launcher(p)"
                            class="float-right py-0 text-center fas fa-plus mr-2 mt-1"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>Add task</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" @click="view_tasks(p)"
                            class="float-right py-0 text-center far fa-eye mr-2 mt-1"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>View tasks</span>
                      </v-tooltip>
                      <v-tooltip v-if="permissionIsAssigned('project-update')" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" @click="add_to_project_launcher(p)"
                            class="float-right py-0 text-center far fa-user-plus mr-2 mt-1"
                            style="color: #fff !important; font-size: 70%; cursor: pointer;"></i>
                        </template>
                        <span>Add employees to project</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div class="row card-body text-center py-2">
                  <i v-if="p.completed_at && (p.status ? p.status.toLowerCase() == 'completed' : false)"
                    class="fa-3x far fa-check text-success" style="
                  position: absolute;
                  font-size: 1500%;
                  left: 22%;
                  top: 20%;
                  opacity: 0.3;"></i>
                  <ul class="col-md-6 col-sm-6 col-lg-6">
                    <li class="text-left">{{ getTotalTasks(p.id) }} Task(s)</li>
                    <li class="text-left">{{ getCompleteTasks(p.id) }} Completed Task(s)</li>
                    <li class="text-left">{{ getRunningTasks(p.id) }} Running Task(s)</li>
                    <li class="text-left">{{ getTeamMembers(p).length == 0 ? 1 : getTeamMembers(p).length + 1 }} Team
                      Member(s)</li>
                    <li class="text-left">Created {{ new Date(p.created_at).toUTCString().substring(0, 16) }}</li>
                  </ul>
                  <ul class="col-md-6 col-sm-6 col-lg-6">
                    <li class="text-left" v-if="p.budget"><span>Budget: </span>₦{{ parseInt(p.budget) |
                        number_with_commas
                    }}</li>
                    <li class="text-left" v-else><span>Budget: </span><i>No budget</i></li>
                    <li class="text-left"><span>Status: </span>{{ p.status }}</li>
                    <li class="text-left"><span>Est. Date: </span>{{ new
                        Date(p.est_completion_date).toUTCString().substring(0, 16)
                    }}</li>
                    <li class="text-left" v-if="p.completed_at"><span>Completed At: </span>{{ new
                        Date(p.completed_at).toUTCString().substring(0, 16)
                    }}</li>
                    <li class="text-left" v-else><span>Completed At: </span><i>In progress...</i></li>
                  </ul>
                </div>
                <div class="card-footer py-2 text-left">
                  <div class="ul-widget4__img">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" id="userDropdown" :src="
                          getEmployeeDetails(p.created_by) ? (getEmployeeDetails(p.created_by).profile_image_url
                            ? ASSETS_URL + getEmployeeDetails(p.created_by).profile_image_url
                            : '/assets/images/default_profile_img.png') : '/assets/images/default_profile_img.png'
                        " alt=""
                          style="width: 30px; height: 30px; border-radius: 100%; margin-right: -15px !important;"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                      </template>
                      <span>{{ getEmployeeName(p.created_by) || "Employee not found" }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-for="e in getTeamMembers(p)" :key="e.id">
                      <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" id="userDropdown" :src="
                          e.profile_image_url
                            ? ASSETS_URL + e.profile_image_url
                            : '/assets/images/default_profile_img.png'
                        " alt=""
                          style="width: 30px; height: 30px; border-radius: 100%; margin-right: -15px !important;"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                      </template>
                      <span>{{ getEmployeeName(e.id) || "Employee not found" }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- <div class="card w-100" style="border-radius: 10px;">
              <div class="card-body px-1 py-1 pt-0 pr-0">
                <div
                  class="table-responsive" style="border-radius: 10px; height: 75vh;
                                      overflow-y: scroll;
                                      padding-top: 0;
                                      padding-bottom: 0;"
                >
                  <table class="table">
                    <thead>
                      <tr>
                          <th class="text-left" style="border-top: none;" scope="col">Title</th>
                          <th class="text-left" style="border-top: none;" scope="col">Description</th>
                          <th class="text-left" style="border-top: none;" scope="col">Status</th>
                          <th class="text-left" style="border-top: none;" scope="col">Budget</th>
                          <th class="text-left" style="border-top: none;" scope="col">Estimated Completion Date</th>
                          <th class="text-left" style="border-top: none;" scope="col">Completion Date</th>
                          <th class="text-left" style="border-top: none;" scope="col">Created By</th>
                          <th class="text-left" style="border-top: none;" scope="col">Updated By</th>
                          <th class="text-left" style="border-top: none;" scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="p in authCompanyProjects" :key="p.id">
                        <td class="text-left">{{ p.title }}</td>
                        <td class="text-left">{{ p.description }}</td>
                        <td class="text-left">{{ p.status }}</td>
                        <td class="text-left">₦{{ parseInt(p.budget) | number_with_commas }}</td>
                        <td class="text-left">{{ new Date(p.est_completion_date).toUTCString().substring(0, 16) }}</td>
                        <td class="text-left">{{ new Date(p.completed_at).toUTCString().substring(0, 16) }}</td>
                        <td class="text-left">{{ p.created_by }}</td>
                        <td class="text-left">{{ p.updated_by }}</td>
                        <td class="text-left">
                          <div class="row">
                              <i
                              v-bind="attrs"
                              v-on="on"
                              class="col-3 py-0 text-center fas fa-edit"
                              @click="edit_project(p)"
                              style="color: #069 !important; font-size: 125%; cursor: pointer;"
                              ></i>
                              <i
                              v-bind="attrs"
                              v-on="on"
                              class="col-3 py-0 text-center fas fa-trash"
                              @click="delete_project(p)"
                              style="color: #069 !important; font-size: 125%; cursor: pointer;"
                              ></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div> -->
        </div>
      </div>
      <div v-else class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;" :style="$vuetify.breakpoint.name != 'xs' ? null : null">
          <h1>
            <span>Edit Project</span>
          </h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Project Title <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field v-model="editFormData.title" placeholder="Enter project title" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Project Description <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-text-field v-model="editFormData.description" placeholder="Enter project description" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Project Status <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select v-model="editFormData.status" item-color="#069"
                  :items="['Starting', 'In Progress', 'Completed']" placeholder="Select project status" solo
                  class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Budget</span>
                <v-text-field v-model="editFormData.budget" placeholder="Enter project budget" solo class="mt-2"
                  type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Estimated Completion Date <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                      v-model="editFormData.est_completion_date" placeholder="Estimated completion date" solo
                      class="mt-2" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker" v-model="editFormData.est_completion_date" :min="
                    new Date(new Date().getTime() - 2678000000)
                      .toISOString()
                      .substr(0, 10)
                  " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substr(0, 10)
" @change="save"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Color <i class="fas fa-asterisk"
                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                <v-select v-model="editFormData.color_code" item-color="#069"
                  :items="['red', 'blue', 'green', 'purple', 'darkorange', 'brown', 'black', 'darkgrey']"
                  placeholder="Select project color" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Date of Completion</span>
                <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field append-icon="fas fa-times" @click:append="editFormData.completed_at = ''" color="#069"
                      prepend-inner-icon="mdi-calendar-month-outline" v-model="editFormData.completed_at"
                      placeholder="Date of completion" solo class="mt-2" readonly v-bind="attrs" v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker color="#069" ref="picker3" v-model="editFormData.completed_at" :min="
                    new Date(new Date().getTime() - 2678000000)
                      .toISOString()
                      .substr(0, 10)
                  " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substr(0, 10)
" @change="save3"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="remove-hint text-center">
                <button @click="update_project_submit ? null : update_project()" type="button"
                  class="btn btn-secondary m-1 ripple w-50 py-3">
                  {{ update_project_text }}
                  <v-icon :style="
                    !update_project_submit
                      ? 'display: none;'
                      : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                  ">
                    fas fa-circle-notch fa-spin
                  </v-icon>
                </button>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="flex-grow-1"></div>
      <v-row justify="center">
        <v-dialog v-model="edit_task_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Task - {{ current_project.title }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="remove-hint">
                        <span style="color: #111d5e;">Task Title <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="editTaskForm.title" placeholder="Enter task title" solo class="mt-2">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="remove-hint">
                        <span style="color: #111d5e;">Task Description <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-textarea v-model="editTaskForm.task_description" placeholder="Enter task description" solo
                          class="mt-2"></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Due Date <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                          offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                              v-model="editTaskForm.task_duedate" placeholder="Due date" solo class="mt-2" readonly
                              v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker color="#069" ref="picker" v-model="editTaskForm.task_duedate" :min="
                            new Date(new Date().getTime() - 2678000000)
                              .toISOString()
                              .substr(0, 10)
                          " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substr(0, 10)
" @change="save"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Task Status <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-select v-model="editTaskForm.task_status" item-color="#069"
                          :items="['Starting', 'In Progress', 'Completed']" placeholder="Select task status" solo
                          class="mt-2" color="#069"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="edit_task_submit ? null : update_task()" style="width: 100%;" type="button"
                          class="btn btn-secondary m-1 ripple">
                          {{ edit_task_text }}
                          <v-icon :style="
                            !edit_task_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fas fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="edit_task_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="add_task_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Add Task to {{ current_project.title }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="remove-hint">
                        <span style="color: #111d5e;">Task Title <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-text-field v-model="addTaskForm.title" placeholder="Enter task title" solo class="mt-2">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="remove-hint">
                        <span style="color: #111d5e;">Task Description <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-textarea v-model="addTaskForm.task_description" placeholder="Enter task description" solo
                          class="mt-2"></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Due Date <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                          offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                              v-model="addTaskForm.task_duedate" placeholder="Due date" solo class="mt-2" readonly
                              v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker color="#069" ref="picker" v-model="addTaskForm.task_duedate" :min="
                            new Date(new Date().getTime() - 2678000000)
                              .toISOString()
                              .substr(0, 10)
                          " :max="
  new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substr(0, 10)
" @change="save"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="remove-hint">
                        <span style="color: #111d5e;">Task Status <i class="fas fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                        <v-select v-model="addTaskForm.task_status" item-color="#069"
                          :items="['Starting', 'In Progress', 'Completed']" placeholder="Select task status" solo
                          class="mt-2" color="#069"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="add_task_submit ? null : add_task()" style="width: 100%;" type="button"
                          class="btn btn-secondary m-1 ripple">
                          {{ add_task_text }}
                          <v-icon :style="
                            !add_task_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fas fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="add_task_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="view_tasks_dialog" max-width="600px">
          <v-card>
            <v-card-title class="d-block">
              <span class="headline">Tasks ({{ current_project.title }})</span>
              <span v-if="permissionIsAssigned('tasks-create')" class="headline float-right">
                <button @click="add_task_launcher(current_project)" style="float: right;" type="button"
                  class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                  Add Task
                </button>
              </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <table class="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th class="text-left">Employee</th>
                      <th class="text-left">Title</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Due Date</th>
                      <th class="text-left">Status</th>
                      <th v-if="permissionIsAssigned('tasks-update') || permissionIsAssigned('tasks-delete')"
                        class="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="t in current_tasks" :key="t.id">
                      <td class="text-left">
                        {{ getEmployeeName(t.employee_id) || "Employee not found" }}
                      </td>
                      <td class="text-left">
                        {{ t.title }}
                      </td>
                      <td class="text-left">
                        {{ t.task_description }}
                      </td>
                      <td class="text-left">
                        {{ new Date(t.task_duedate).toUTCString().substring(0, 16) }}
                      </td>
                      <td class="text-left">
                        {{ t.task_status }}
                      </td>
                      <td v-if="permissionIsAssigned('tasks-update') || permissionIsAssigned('tasks-delete')"
                        class="text-left">
                        <template
                          v-if="adminEmployeeData.id == current_project.created_by || authEmployeeDataForCompany.id == current_project.created_by || t.employee_id == authEmployeeDataForCompany.id || t.employee_id == adminEmployeeData.id">
                          <i v-if="permissionIsAssigned('tasks-delete')"
                            class="float-right py-0 text-center far fa-trash mt-1" @click="delete_task(t)"
                            style="color: #069 !important; font-size: 100%; cursor: pointer;"></i>
                          <i v-if="permissionIsAssigned('tasks-update')"
                            class="float-right py-0 text-center far fa-edit mr-2 mt-1" @click="edit_task(t)"
                            style="color: #069 !important; font-size: 100%; cursor: pointer;"></i>
                        </template>
                        <i v-else>Cannot alter</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="view_tasks_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="add_employees_dialog" max-width="1200px">
          <v-card>
            <v-card-title class="d-block">
              <span class="headline">Add/Remove employees to this project</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form @submit.prevent>
                  <div class="container-fluid">
                    <v-row>
                      <template v-for="e in selectableEmployees">
                        <v-col v-if="e.id != current_project.created_by" :key="e.id" cols="12" lg="4" md="4">
                          <div class="font-bold col-12 pb-0 mb-0">
                            <v-checkbox class="mt-0 d-inline-block mr-5" v-model="e.checked"></v-checkbox>
                            <span style="bottom: 5px; position: relative;">{{ e.employee_name }}</span>
                          </div>
                        </v-col>
                      </template>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="add_employees_to_project_submit ? null : add_to_project()" style="width: 100%;"
                          type="button" class="btn btn-secondary m-1 ripple">
                          {{ add_employees_to_project_text }}
                          <v-icon :style="
                            !add_employees_to_project_submit
                              ? 'display: none;'
                              : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                          ">
                            fas fa-circle-notch fa-spin
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="add_employees_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ASSETS_URL, BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      add_employees_to_project_text: 'Update',
      add_employees_to_project_submit: false,
      ASSETS_URL,
      current_project: {
        title: ''
      },
      addTaskForm: {
        title: '',
        project_id: '',
        task_description: '',
        task_duedate: '',
        task_status: '',
      },
      editTaskForm: {
        id: '',
        title: '',
        project_id: '',
        task_description: '',
        task_duedate: '',
        task_status: '',
      },
      edit_task_dialog: false,
      edit_task_text: 'Update',
      edit_task_submit: false,
      view_tasks_dialog: false,
      add_employees_dialog: false,
      add_task_dialog: false,
      add_task_text: "Submit",
      add_task_submit: false,
      create_project_text: "Submit",
      create_project_submit: false,
      update_project_text: "Update",
      update_project_submit: false,
      assign_project_text: "Assign",
      assign_project_submit: false,
      create_project: 2,
      employee_projects: [],
      menu: false,
      menu3: false,
      menu4: false,
      // menu: false,
      formData: {
        title: "",
        description: "",
        status: "",
        budget: "",
        est_completion_date: "",
        color_code: "",
        completed_at: "",
      },
      editFormData: {
        id: "",
        title: "",
        description: "",
        status: "",
        budget: "",
        est_completion_date: "",
        color_code: "",
        completed_at: "",
      },
      allEmployees: [],
      // all_project_types: [],
      // apply_for_leave_text: 'Submit',
      // apply_for_leave_submit: false,
      selectableEmployees: []
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu3(val) {
      val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
    },
    menu4(val) {
      val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
    }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "adminEmployeeData",
      "authUserData",
      "userType",
      "authCompanyProjects",
      "authCompanyTasks",
      "authEmployeesAssignedToProjects"
    ]),
    assignedEmployees() {
      let _this = this, employees = [], result = [];
      employees = _this.selectableEmployees.filter(e => e.checked);
      result = employees.map(e => {
        return {
          employee_id: e.id
        }
      });
      return result;
    },
    current_tasks() {
      let _this = this, tasks = [];
      if (_this.authCompanyTasks && _this.current_project) {
        tasks = _this.authCompanyTasks.filter(t => {
          return t.project_id == _this.current_project.id
        });
      }
      return tasks;
    },
    edit_project_type_id() {
      let _this = this;
      if (_this.editFormData.type_name) {
        return _this.authCompanyAllProjectType.filter(type => {
          return _this.editFormData.type_name == type.projecttype_name;
        })[0].id;
      } else {
        return "";
      }
    },
    project_type_id() {
      let _this = this;
      if (_this.formData.type_name) {
        return _this.authCompanyAllProjectType.filter(type => {
          return _this.formData.type_name == type.projecttype_name;
        })[0].id;
      } else {
        return "";
      }
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_company_projects",
      "fetch_all_company_tasks",
      "fetch_all_employees_assigned_to_projects"
    ]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    save3(date) {
      this.$refs.menu3.save(date);
    },
    save4(date) {
      this.$refs.menu4.save(date);
    },
    tweak_display() {
      if (this.create_project == 1) {
        this.create_project = 2;
      } else if (this.create_project == 2) {
        this.create_project = 1;
      } else {
        ("");
      }
    },
    isAssignedProject(employee_id, project_id) {
      let _this = this, searchResult = [], result = false;
      searchResult = _this.authEmployeesAssignedToProjects.filter(p => {
        return p.assigned_to == employee_id && p.project_id == project_id
      });
      if (searchResult.length >= 1) {
        result = true;
      }
      return result
    },
    add_to_project() {
      let _this = this;
      _this.add_employees_to_project_text = "";
      _this.add_employees_to_project_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      axios
        .post(`${BASE_URL}assignproject`, {
          employee_id: _this.authEmployeeDataForCompany.id || _this.adminEmployeeData.id,
          company_id: _this.authEmployeeDataForCompany.company_id || _this.authCompanyData.id,
          status: _this.current_project.status,
          completed_at: _this.current_project.completed_at,
          created_by: _this.current_project.created_by,
          assigned_at: new Date(),
          project_id: _this.current_project.id,
          updated_at: _this.current_project.updated_at,
          assigned_to: _this.assignedEmployees
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Employee assignment successful"
          });
          // FETCH EMPLOYEE IN PROJECT
          _this.fetch_all_employees_assigned_to_projects();
          _this.add_employees_dialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.assigned_to ? errors.assigned_to[0] : null) : null
                || error.response.data.error || error.response.data.message || _this.employeeIdsToAdd.length == 1 ? "The employee couldn't be added" : "The employees couldn't be added"
          });
          _this.add_employees_to_project_submit = false;
          _this.add_employees_to_project_text = "Update";
        })
        .then(function () {
          _this.add_employees_to_project_submit = false;
          _this.add_employees_to_project_text = "Update";
        });
    },
    getTotalTasks(id) {
      let _this = this, tasks = [];
      if (_this.authCompanyTasks) {
        tasks = _this.authCompanyTasks.filter(t => {
          return t.project_id == id
        });
        return tasks.length;
      }
      return 0;
    },
    getCompleteTasks(id) {
      let _this = this, tasks = [];
      if (_this.authCompanyTasks) {
        tasks = _this.authCompanyTasks.filter(t => {
          return t.project_id == id && (t.task_status ? t.task_status.toLowerCase() == 'completed' : false)
        });
        return tasks.length;
      }
      return 0
    },
    getRunningTasks(id) {
      let _this = this, tasks = [];
      if (_this.authCompanyTasks) {
        tasks = _this.authCompanyTasks.filter(t => {
          return t.project_id == id && (t.task_status ? t.task_status.toLowerCase() == 'in progress' : false)
        });
        return tasks.length;
      }
      return 0
    },
    getEmployeeName(id) {
      let _this = this, person = null;
      if (_this.mixinBranchEmployees(_this.$route.name)) {
        person = _this.mixinBranchEmployees(_this.$route.name).filter(person => {
          return person.id == id;
        })[0];
        if (!person) {
          return null;
        }
        return person.employee_fristname + ' ' + person.employee_lastname;
      } else {
        return "";
      }
    },
    getEmployeeDetails(id) {
      let _this = this;
      if (_this.mixinBranchEmployees(_this.$route.name)) {
        let person = _this.mixinBranchEmployees(_this.$route.name).filter(person => {
          return person.id == id;
        })[0];
        if (!person) {
          return null;
        }
        return person;
      } else {
        return "";
      }
    },
    getTeamMembers(p) {
      let _this = this, teamMembers = []//, tasks = [];

      // if (_this.authCompanyTasks) {
      //   tasks = _this.authCompanyTasks.filter(t => {
      //     return t.project_id == p.id
      //   });
      // }

      // tasks.forEach(t => {
      //   if (teamMembers.indexOf(_this.getEmployeeDetails(t.employee_id)) == -1) {
      //     if (p.created_by != t.employee_id) {
      //       teamMembers.push(_this.getEmployeeDetails(t.employee_id));
      //     }
      //   }
      // });

      teamMembers = _this.mixinBranchEmployees(_this.$route.name).filter(te => _this.isAssignedProject(te.id, p.id))

      return teamMembers;
    },
    view_tasks(p) {
      let _this = this;
      _this.current_project = p;
      _this.view_tasks_dialog = true;
      _this.add_employees_dialog = true;
    },
    add_to_project_launcher(p) {
      let _this = this;
      _this.current_project = p;
      _this.add_employees_dialog = true;
      _this.selectableEmployees.forEach(e => {
        if (_this.isAssignedProject(e.id, _this.current_project.id)) {
          e.checked = true;
        } else {
          e.checked = false;
        }
      });
    },
    add_task_launcher(p) {
      let _this = this;
      _this.view_tasks_dialog = false;
      // const Toast = _this.$swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 2000,
      //   timerProgressBar: true,
      //   didOpen: toast => {
      //     toast.addEventListener("mouseenter", _this.$swal.stopTimer);
      //     toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
      //   }
      // });
      // if (p.completed_at || p.status.toLowerCase() == 'completed') {
      //   Toast.fire({
      //     icon: "warning",
      //     title: "The project is completed. You cannot add further tasks"
      //   });
      //   return;
      // }
      _this.current_project = p;
      _this.addTaskForm.project_id = p.id;
      _this.add_task_dialog = true;
    },
    update_task() {
      let _this = this;
      _this.edit_task_text = "";
      _this.edit_task_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}edittask`, {
          id: _this.editTaskForm.id,
          company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id,
          title: _this.editTaskForm.title,
          project_id: _this.editTaskForm.project_id,
          task_description: _this.editTaskForm.task_description,
          employee_id: _this.authEmployeeDataForCompany.id || _this.adminEmployeeData.id,
          task_duedate: _this.editTaskForm.task_duedate,
          task_status: _this.editTaskForm.task_status
        })
        .then(function () {
          _this.editTaskForm.title = "";
          _this.editTaskForm.task_description = "";
          _this.editTaskForm.task_duedate = "";
          _this.editTaskForm.task_status = "";
          _this.fetch_all_company_projects();
          _this.fetch_all_company_tasks();

          Toast.fire({
            icon: "success",
            title: "The task has been updated"
          });
          _this.edit_task_dialog = false;
          _this.view_tasks_dialog = true;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.title ? errors.title[0] : null
                || errors.project_id ? errors.project_id[0] : null
                  || errors.task_description ? errors.task_description[0] : null
                    || errors.employee_id ? errors.employee_id[0] : null
                      || errors.task_duedate ? errors.task_duedate[0] : null
                        || errors.task_status ? errors.task_status[0] : null) : null
                        || error.response.data.error || error.response.data.message || 'The task could not be updated'
          });
        })
        .then(function () {
          _this.edit_task_submit = false;
          _this.edit_task_text = "Update";
        });
    },
    add_task() {
      let _this = this;
      _this.add_task_text = "";
      _this.add_task_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}createtask`, {
          company_id: _this.authCompanyData.id,
          title: _this.addTaskForm.title,
          project_id: _this.addTaskForm.project_id,
          task_description: _this.addTaskForm.task_description,
          employee_id: _this.adminEmployeeData.id || _this.authEmployeeDataForCompany.id,
          task_duedate: _this.addTaskForm.task_duedate,
          task_status: _this.addTaskForm.task_status
        })
        .then(function () {
          _this.addTaskForm.title = "";
          _this.addTaskForm.project_id = "";
          _this.addTaskForm.task_description = "";
          _this.addTaskForm.task_duedate = "";
          _this.addTaskForm.task_status = "";

          _this.fetch_all_company_projects();
          _this.fetch_all_company_tasks();

          _this.add_task_dialog = false;

          _this.view_tasks_dialog = true;

          Toast.fire({
            icon: "success",
            title: "The task was added successfully"
          });
          // OPEN TASKS VIEW
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.title ? errors.title[0] : null
                || errors.project_id ? errors.project_id[0] : null
                  || errors.task_description ? errors.task_description[0] : null
                    || errors.employee_id ? errors.employee_id[0] : null
                      || errors.task_duedate ? errors.task_duedate[0] : null
                        || errors.task_status ? errors.task_status[0] : null) : null
                        || error.response.data.error || error.response.data.message || 'The task could not be added'
          });
        })
        .then(function () {
          _this.add_task_submit = false;
          _this.add_task_text = "Submit";
        });
    },
    get_type_name(type_id) {
      let _this = this;
      if (type_id && _this.authCompanyAllProjectType) {
        let type = _this.authCompanyAllProjectType.filter(
          type => type.id == type_id
        );
        // console.log(type[0].leave_name);
        if (type.length >= 1) {
          return type[0].projecttype_name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    fetch_employee_projects() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchemployeeproject`, {
          company_id: _this.authEmployeeDataForCompany.company_id
        })
        .then(function (response) {
          console.log(RESPONSE);
          const RESPONSE = response.data;
          RESPONSE.Employees.forEach(project => {
            if (project.employee_id != _this.authEmployeeDataForCompany.id) {
              ("");
            } else {
              _this.employee_projects.push(project);
            }
          });
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .then(function () {
          //
        });
    },
    edit_task(t) {
      let _this = this;
      _this.editTaskForm.id = t.id;
      _this.editTaskForm.title = t.title;
      _this.editTaskForm.project_id = t.project_id;
      _this.editTaskForm.task_description = t.task_description;
      _this.editTaskForm.task_duedate = t.task_duedate;
      _this.editTaskForm.task_status = t.task_status;
      _this.edit_task_dialog = true;
    },
    edit_project(p) {
      let _this = this;
      _this.create_project = 3;
      _this.editFormData.id = p.id;
      _this.editFormData.title = p.title;
      _this.editFormData.description = p.description;
      _this.editFormData.status = p.status;
      _this.editFormData.budget = p.budget;
      _this.editFormData.est_completion_date = p.est_completion_date;
      _this.editFormData.color_code = p.color_code;
      _this.editFormData.completed_at = p.completed_at;
    },
    delete_task(t) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title: "Are you sure you want to delete this task?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}deletetask`, {
                ids: [
                  {
                    id: t.id
                  }
                ]
              })
              .then(function () {
                _this.fetch_all_company_projects();
                _this.fetch_all_company_tasks();
                Toast.fire({
                  icon: "success",
                  title: "The task has been deleted successfully"
                });
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title: "The task couldn't be deleted"
                });
                console.log(error);
              })
              .then(function () {
                //
              });
          }
        });
    },
    delete_project(p) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title:
            "Are you sure you want to delete " + p.title + " from company projects?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6 !important",
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          if (result.isConfirmed) {
            axios
              .post(`${BASE_URL}deleteproject`, {
                ids: [
                  {
                    id: p.id
                  }
                ]
              })
              .then(function () {
                _this.fetch_all_company_projects();
                Toast.fire({
                  icon: "success",
                  title: "Project deleted successfully"
                });
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title: p.title + " couldn't be deleted"
                });
                console.log(error);
              })
              .then(function () {
                //
              });
          }
        });
    },
    update_project() {
      let _this = this;
      _this.update_project_text = "";
      _this.update_project_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}editproject`, {
          id: _this.editFormData.id,
          company_id: _this.authCompanyData.id,
          title: _this.editFormData.title,
          description: _this.editFormData.description,
          status: _this.editFormData.status,
          budget: _this.editFormData.budget,
          est_completion_date: _this.editFormData.est_completion_date,
          color_code: _this.editFormData.color_code,
          completed_at: _this.editFormData.completed_at,
          updated_by: _this.authUserData.name
        })
        .then(function () {
          _this.editFormData.title = "";
          _this.editFormData.description = "";
          _this.editFormData.status = "";
          _this.editFormData.budget = "";
          _this.editFormData.est_completion_date = "";
          _this.editFormData.color_code = "";
          _this.editFormData.completed_at = "";
          _this.fetch_all_company_projects();

          Toast.fire({
            icon: "success",
            title: "The project has been updated"
          });
          _this.create_project = 2;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.title ? errors.title[0] : null
                || errors.status ? errors.status[0] : null
                  || errors.description ? errors.description[0] : null
                    || errors.budget ? errors.budget[0] : null
                      || errors.est_completion_date ? errors.est_completion_date[0] : null
                        || errors.color_code ? errors.color_code[0] : null
                          || errors.completed_at ? errors.completed_at[0] : null
                            || errors.created_by ? errors.created_by[0] : null
                              || errors.updated_by ? errors.updated_by[0] : null) : null
                              || error.response.data.error || error.response.data.message || 'The project could not be updated'
          });
        })
        .then(function () {
          _this.update_project_submit = false;
          _this.update_project_text = "Update";
        });
    },
    add_project() {
      let _this = this;
      _this.create_project_text = "";
      _this.create_project_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}createproject`, {
          company_id: _this.authCompanyData.id,
          title: _this.formData.title,
          description: _this.formData.description,
          status: _this.formData.status,
          budget: _this.formData.budget,
          est_completion_date: _this.formData.est_completion_date,
          color_code: _this.formData.color_code,
          completed_at: _this.formData.completed_at,
          created_by: _this.authEmployeeDataForCompany.id || _this.adminEmployeeData.id,
          updated_by: _this.authUserData.name
        })
        .then(function () {
          _this.formData.title = "";
          _this.formData.description = "";
          _this.formData.status = "";
          _this.formData.budget = "";
          _this.formData.est_completion_date = "";
          _this.formData.color_code = "";
          _this.formData.completed_at = "";
          _this.fetch_all_company_projects();

          Toast.fire({
            icon: "success",
            title: "The project was created successfully"
          });
          _this.create_project = 2;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.title ? errors.title[0] : null
                || errors.status ? errors.status[0] : null
                  || errors.description ? errors.description[0] : null
                    || errors.budget ? errors.budget[0] : null
                      || errors.est_completion_date ? errors.est_completion_date[0] : null
                        || errors.color_code ? errors.color_code[0] : null
                          || errors.completed_at ? errors.completed_at[0] : null
                            || errors.created_by ? errors.created_by[0] : null
                              || errors.updated_by ? errors.updated_by[0] : null) : null
                              || error.response.data.error || error.response.data.message || 'The project could not be created'
          });
        })
        .then(function () {
          _this.create_project_submit = false;
          _this.create_project_text = "Submit";
        });
    }
  },
  mounted() {
    document.title = "Kylian ERP - Projects";
    let _this = this;
    _this.fetch_all_company_projects();
    _this.fetch_all_company_tasks();
    _this.fetch_all_employees_assigned_to_projects();
    if (_this.mixinBranchEmployees(_this.$route.name).length >= 1) {
      _this.selectableEmployees = _this.mixinBranchEmployees(_this.$route.name).map(e => {
        return {
          id: e.id,
          employee_name: e.employee_fristname + ' ' + e.employee_lastname,
          checked: false
        }
      });
    }
  }
};
</script>

<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}
</style>
