<template>
    <div class="tab-pane fade" id="list-settings56" role="tabpanel" aria-labelledby="list-settings-list56">
        <v-radio-group v-model="isFlexiblePayroll" row class="row">
            <v-radio @click="updatePayrollType(true)" :disabled="flexiblePayrollUpdating" label="Flexible Payroll" :value="true" class="col-md-5"></v-radio>
            <v-radio @click="updatePayrollType(false)" :disabled="flexiblePayrollUpdating" label="Tax-Compliant Payroll" :value="false" class="col-md-5"></v-radio>
        </v-radio-group>
        <template v-if="isFlexiblePayroll">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Pay Items
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="offset-lg-9 col-lg-3 py-4">
                                <button
                                    @click="create_pay_item_dialog = true"
                                    style="float: right;"
                                    type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                    :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                                >
                                    Add Pay Item
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <div class="table-responsive" style="border-radius: 10px; height: 75vh; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Payment Item
                                    </th>
                                    <th class="text-left" scope="col">
                                        Payment Type
                                    </th>
                                    <th class="text-left" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="pr in authPayrollConfig" :key="pr.id">
                                    <td class="text-left">
                                        {{ pr.payment_item }}
                                    </td>
                                    <td class="text-left">
                                        {{ pr.payment_type }}
                                    </td>
                                    <td style="padding-top: 13px;" class="employee-options text-left">
                                        <div class="row ml-0">
                                            <i @click="launch_edit_pay_item(pr)" class="py-4 text-center fal fa-edit mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                            <i @click="delete_pay_item(pr)" class="py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Pay Items
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="offset-lg-9 col-lg-3 py-4">
                                <button
                                    @click="create_pay_item_dialog = true"
                                    style="float: right;"
                                    type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                    :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                                >
                                    Add Pay Item
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <div class="table-responsive" style="border-radius: 10px; height: 35vh; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Payment Item
                                    </th>
                                    <th class="text-left" scope="col">
                                        Payment Type
                                    </th>
                                    <th class="text-left" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="pr in authPayrollConfig" :key="pr.id">
                                    <td class="text-left">
                                        {{ pr.payment_item }}
                                    </td>
                                    <td class="text-left">
                                        {{ pr.payment_type }}
                                    </td>
                                    <td style="padding-top: 13px;" class="employee-options text-left">
                                        <div class="row ml-0">
                                            <i @click="launch_edit_pay_item(pr)" class="py-4 text-center fal fa-edit mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                            <i @click="delete_pay_item(pr)" class="py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <v-row>
                <v-col cols="12" md="9">
                    <h4>Select Statutory Deductions</h4>
                </v-col>
                <v-col>
                    <button
                        @click="displayEmolumentsDialog()"
                        style="float: right;"
                        type="button"
                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                        :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                    >
                        Manage Emoluments
                    </button>
                </v-col>
            </v-row>
            <!-- Tax-compliant payroll -->
            <v-row class="pt-4">
                <v-col class="py-0" v-for="deduction in authStatutoryDeductions" :key="deduction.id" cols="12">
                    <h4 class="font-bold col-12 pb-0 mb-0">
                        <v-checkbox
                            :disabled="savingStatutoryDeduction"
                            @change="handleStatutoryDeduction(deduction.id)"
                            :value="deduction.id"
                            class="mt-0 d-inline-block mr-5"
                            v-model="selectedStatutoryDeductions"
                        >
                        </v-checkbox>
                        <span style="font-size: 15px; bottom: 5px; position: relative;">{{ deduction.name + ' (' + deduction.description + ')' }} </span>
                        <sup v-if="totalEmolumentPercentage != 100 && (selectedStatutoryDeductions.indexOf(deduction.id) != -1)" class="text-danger">
                            The total emoluments' percentage is {{ totalEmolumentPercentage > 100 ? 'greater' : 'less' }} than 100%. Kindly rectify.
                        </sup>
                    </h4>
                </v-col>
            </v-row>
        </template>
        <v-dialog v-model="create_pay_item_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Add Pay Item</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref='form1' @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <span style="color: #111d5e;">Payment Type <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-select :rules="[
                                        v => !!v || 'Payment type is required'
                                    ]" :items="['Allowance', 'Deduction']" placeholder="Select payment type" solo dense :full-width="true" v-model="payItemForm.payment_type"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <span style="color: #111d5e;">Pay Item Name <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field :rules="[
                                        v => !!v || 'Payment type name is required'
                                    ]" v-model="payItemForm.payment_item" placeholder="Enter payment item name" solo dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" >
                                        <button
                                            @click="pay_item_create_submit ? null : add_pay_item()"
                                            style="width: 99%;"
                                            type="button"
                                            class="btn btn-secondary m-1 text-white btn-raised ripple"
                                        >
                                            {{ pay_item_create_text }}
                                            <v-icon
                                                :style="!pay_item_create_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="create_pay_item_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="edit_pay_item_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Pay Item</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref='form2' @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <span style="color: #111d5e;">Payment Type <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-select :rules="[
                                        v => !!v || 'Payment type is required'
                                    ]" :items="['Allowance', 'Deduction']" placeholder="Select payment type" solo dense :full-width="true" v-model="editPayItemForm.payment_type"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <span style="color: #111d5e;">Pay Item Name <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field :rules="[
                                        v => !!v || 'Pay item is required'
                                    ]" v-model="editPayItemForm.payment_item" placeholder="Enter payment item name" solo dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" >
                                        <button
                                            @click="pay_item_update_submit ? null : edit_pay_item()"
                                            style="width: 99%;"
                                            type="button"
                                            class="btn btn-secondary m-1 text-white btn-raised ripple"
                                        >
                                            {{ pay_item_update_text }}
                                            <v-icon
                                                :style="!pay_item_update_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="edit_pay_item_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="emolumentsDialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Emoluments</span>
                    <span class="headline float-right">
                        <button @click="addEmolument()" style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                            Add Emolument
                        </button>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <h4 v-if="totalEmolumentPercentageInit != 100" class="text-danger">
                            The total emoluments' percentage is {{ totalEmolumentPercentageInit > 100 ? 'greater' : 'less' }} than 100%. Kindly rectify.
                        </h4>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-left">Emolument Name</th>
                                    <th class="text-left">Percentage Value (%)</th>
                                    <th class="text-center">Approve</th>
                                    <th class="text-center">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="remove-message" v-for="(item, index) in emolumentsFormData" :key="item.id">
                                    <td class="text-left">
                                        <v-text-field v-model="item.emolument_name" :disabled="index < 3" solo></v-text-field>
                                    </td>
                                    <td class="text-left">
                                        <v-text-field v-model="item.emolument_percentage" type="number" solo append-icon="fas fa-percent"></v-text-field>
                                    </td>
                                    <td class="text-center">
                                        <i v-if="emolumentsUpdating" class="py-0 text-center far fa-check mt-1 fa-2x" style="color: #069 !important; opacity: 0.3;"></i>
                                        <i v-else class="py-0 text-center far fa-check mt-1 fa-2x" @click="handleEmolument(item)" style="color: #069 !important; cursor: pointer;"></i>
                                    </td>
                                    <td class="text-center">
                                        <i v-if="item.is_default == 0 || emolumentsUpdating" class="py-0 text-center far fa-trash mt-1 fa-2x" style="color: #069 !important; opacity: 0.3;"></i>
                                        <i v-else class="py-0 text-center far fa-trash mt-1 fa-2x" @click="deleteEmolument(item, index)" style="color: #069 !important; cursor: pointer;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="emolumentsDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            isFlexiblePayroll: true,
            flexiblePayrollUpdating: false,
            create_pay_item_dialog: false,
            edit_pay_item_dialog: false,
            savingStatutoryDeduction: false,
            emolumentsDialog: false,
            selectedStatutoryDeductions: [],
            emolumentsFormData: [],
            payItemForm: {
                payment_type: "",
                payment_item: "",
            },
            editPayItemForm: {
                id: "",
                payment_type: "",
                payment_item: "",
            },
            pay_item_create_text: "Submit",
            pay_item_create_submit: false,
            pay_item_update_text: "Update",
            pay_item_update_submit: false,
            emolumentsUpdating: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authUserData',
            'authPayrollConfig',
            'authStatutoryDeductions',
            'authCompanyEmoluments',
            'authCompanyStatutoryDeductions'
        ]),
        totalEmolumentPercentage() {
            let _this = this,
                result = 0;
            _this.authCompanyEmoluments.forEach((item) => {
                result += parseFloat(item.emolument_percentage) || 0;
            });
            return result;
        },
        totalEmolumentPercentageInit() {
            let _this = this,
                result = 0;
            _this.emolumentsFormData.forEach((item) => {
                result += parseFloat(item.emolument_percentage) || 0;
            });
            return result;
        },
    },
    methods: {
        ...mapActions([
            'fetch_company_emoluments',
            'fetch_company_statutory_deductions',
            'fetch_all_payroll_configuration',
            'refresh_company_data'
        ]),
        addEmolument() {
            let newObject = {
                id: "",
                emolument_name: "",
                emolument_percentage: 0,
                is_default: 1,
                isAdded: false,
            };
            this.emolumentsFormData.splice(3, 0, newObject);
        },
        handleEmolument(emolument) {
            const _this = this;
            _this.emolumentsUpdating = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}${emolument.isAdded ? "editemolument" : "addemolument"}`, {
                company_id: _this.authCompanyData.id,
                emolument_name: emolument.emolument_name,
                emolument_percentage: emolument.emolument_percentage || 0,
                emolument_id: emolument.id,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: `Emolument ${emolument.isAdded ? "updated" : "added"} successfully`,
                });
                _this.fetch_company_emoluments().then(() => {
                    _this.emolumentsFormData = [];
                    _this.authCompanyEmoluments.forEach((emolument) => {
                        let newObject = {
                            id: emolument.id,
                            emolument_name: emolument.emolument_name,
                            emolument_percentage: emolument.emolument_percentage || 0,
                            is_default: emolument.is_default,
                            isAdded: true,
                        };
                        _this.emolumentsFormData.splice(_this.emolumentsFormData.length, 0, newObject);
                    });
                });
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.emolument_name
                            ? errors.emolument_name[0]
                            : null || errors.emolument_percentage
                            ? errors.emolument_percentage[0]
                            : null || errors.emolument_id
                            ? errors.emolument_id[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || `The emolument couldn't be ${emolument.isAdded ? "updated" : "added"}`,
                });
            })
            .then(function () {
                _this.emolumentsUpdating = false;
            });
        },
        deleteEmolument(emolument, index) {
            const _this = this;
            _this.emolumentsUpdating = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (emolument.isAdded) {
                axios.post(`${BASE_URL}deleteemolument`, {
                    emolument_id: emolument.id,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: `Emolument deleted successfully`,
                    });
                    _this.fetch_company_emoluments().then(() => {
                        _this.emolumentsFormData = [];
                        _this.authCompanyEmoluments.forEach((emolument) => {
                            let newObject = {
                                id: emolument.id,
                                emolument_name: emolument.emolument_name,
                                emolument_percentage: emolument.emolument_percentage || 0,
                                is_default: emolument.is_default,
                                isAdded: true,
                            };
                            _this.emolumentsFormData.splice(_this.emolumentsFormData.length, 0, newObject);
                        });
                    });
                })
                .catch(function () {
                    Toast.fire({
                        icon: "error",
                        title: "The emolument couldn't be deleted",
                    });
                })
                .then(function () {
                    _this.emolumentsUpdating = false;
                });
            } else {
                _this.emolumentsFormData.splice(index, 1);
                _this.emolumentsUpdating = false;
                Toast.fire({
                    icon: "success",
                    title: "Emolument deleted successfully",
                });
            }
        },
        handleStatutoryDeduction(id) {
            const _this = this, checked = _this.selectedStatutoryDeductions.indexOf(id) != -1;
            _this.savingStatutoryDeduction = true;
            
            const companyDeductionObject = _this.authCompanyStatutoryDeductions.filter((d) => d.statutory_deduction_id == id);
            let index;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}${checked ? "addcompanystatutorydeduction" : "removecompanystatutorydeduction"}`, {
                company_id: _this.authCompanyData.id,
                statutorydeduction_id: id,
                companystatutorydeduction_id: companyDeductionObject.length ? companyDeductionObject[0].id : null,
            })
            .then(function () {
                _this.fetch_company_statutory_deductions();
            })
            .catch(function () {
                if (checked) {
                    index = _this.selectedStatutoryDeductions.indexOf(id);
                    _this.selectedStatutoryDeductions.splice(index, 1);
                } else {
                    index = _this.selectedStatutoryDeductions.length;
                    _this.selectedStatutoryDeductions.splice(index, 0, 1);
                }
                Toast.fire({
                    icon: "error",
                    title: `Statutory deduction couldn't be ${checked ? "enabled" : "disabled"}`,
                });
            })
            .then(function () {
                _this.savingStatutoryDeduction = false;
            });
        },
        displayEmolumentsDialog() {
            const _this = this;
            _this.emolumentsFormData = [];
            _this.authCompanyEmoluments.forEach((emolument) => {
                let newObject = {
                    id: emolument.id,
                    emolument_name: emolument.emolument_name,
                    emolument_percentage: emolument.emolument_percentage || 0,
                    is_default: emolument.is_default,
                    isAdded: true,
                };
                _this.emolumentsFormData.splice(_this.emolumentsFormData.length, 0, newObject);
            });
            _this.emolumentsDialog = true;
        },
        delete_pay_item(pr) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this pay item?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${BASE_URL}deletepayrollconfiguration`, {
                        payrollconfiguration_id: pr.id,
                    })
                    .then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "Pay item deleted successfully",
                        });
                    })
                    .catch(function (error) {
                        Toast.fire({
                            icon: "error",
                            title: "Pay item couldn't be deleted",
                        });
                        console.log(error);
                    })
                    .then(function () {
                        _this.fetch_all_payroll_configuration();
                    });
                }
            });
        },
        async edit_pay_item() {
            const _this = this;
            const valid = await _this.$refs.form2.validate();
            if (!valid) {
                return;
            }
            _this.pay_item_update_text = "";
            _this.pay_item_update_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (_this.editPayItemForm.payment_item.toLowerCase() === "loan repayment") {
                Toast.fire({
                    icon: "warning",
                    title: "Please enter a different pay item name",
                });
                return;
            }
            axios.post(`${BASE_URL}updatepayrollconfiguration`, {
                payrollconfiguration_id: _this.editPayItemForm.id,
                payment_type: _this.editPayItemForm.payment_type,
                payment_item: _this.editPayItemForm.payment_item,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "Pay item updated successfully",
                });
                _this.fetch_all_payroll_configuration();
                _this.editPayItemForm.id = "";
                _this.editPayItemForm.payment_type = "";
                _this.editPayItemForm.payment_item = "";
                _this.edit_pay_item_dialog = false;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.payment_type
                            ? errors.payment_type[0]
                            : null || errors.payment_item
                            ? errors.payment_item[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The pay item couldn't be added",
                });
            })
            .then(function () {
                _this.pay_item_update_submit = false;
                _this.pay_item_update_text = "Update";
            });
        },
        async add_pay_item() {
            const _this = this;
            const valid = await _this.$refs.form1.validate();
            if (!valid) {
                return;
            }
            _this.pay_item_create_text = "";
            _this.pay_item_create_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (_this.payItemForm.payment_item.toLowerCase() === "loan repayment") {
                Toast.fire({
                    icon: "warning",
                    title: "Please enter a different pay item name",
                });
            }
            axios.post(`${BASE_URL}createpayitem`, {
                company_id: _this.authCompanyData.id,
                payment_type: _this.payItemForm.payment_type,
                payment_item: _this.payItemForm.payment_item,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "Pay item added successfully",
                });
                _this.fetch_all_payroll_configuration();
                _this.payItemForm.payment_type = "";
                _this.payItemForm.payment_item = "";
                _this.create_pay_item_dialog = false;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.payment_type
                            ? errors.payment_type[0]
                            : null || errors.payment_item
                            ? errors.payment_item[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The pay item couldn't be added",
                });
            })
            .then(function () {
                _this.pay_item_create_submit = false;
                _this.pay_item_create_text = "Submit";
            });
        },
        updatePayrollType(isFlexible) {
            const _this = this;
            _this.flexiblePayrollUpdating = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}${isFlexible ? "setflexiblepayroll" : "settaxcompliancepayroll"}`, {
                company_id: _this.authCompanyData.id,
            })
            .then(function () {
                _this.refresh_company_data({
                    user_id: _this.authUserData.id,
                    company_id: _this.authCompanyData.id,
                })
                .then(() => {
                    _this.isFlexiblePayroll = _this.authCompanyData.company_payroll == 1;
                });
            })
            .catch(function () {
                Toast.fire({
                    icon: "error",
                    title: `The payroll type couldn't be updated`,
                });
                _this.isFlexiblePayroll = isFlexible;
            })
            .then(function () {
                _this.flexiblePayrollUpdating = false;
            });
        },
        launch_edit_pay_item(pr) {
            this.editPayItemForm.id = pr.id;
            this.editPayItemForm.payment_item = pr.payment_item;
            this.editPayItemForm.payment_type = pr.payment_type;
            this.edit_pay_item_dialog = true;
        },
    },
    mounted() {
        const _this = this;
        _this.refresh_company_data({
            user_id: _this.authUserData.id,
            company_id: _this.authCompanyData.id,
        }).then(() => {
            _this.isFlexiblePayroll = _this.authCompanyData.company_payroll == 1;
        });
        
        _this.fetch_company_statutory_deductions().then(() => {
            _this.authCompanyStatutoryDeductions.forEach((sd) => {
                _this.selectedStatutoryDeductions.splice(_this.selectedStatutoryDeductions.length, 0, sd.statutory_deduction_id);
            });
        });

        _this.fetch_company_emoluments().then(() => {
            _this.authCompanyEmoluments.forEach((emolument) => {
                let newObject = {
                    id: emolument.id,
                    emolument_name: emolument.emolument_name,
                    emolument_percentage: emolument.emolument_percentage || 0,
                    is_default: emolument.is_default,
                    isAdded: true,
                };
                _this.emolumentsFormData.splice(_this.emolumentsFormData.length, 0, newObject);
            });
        });

        _this.fetch_all_payroll_configuration();
    }
}
</script>

<style>

</style>