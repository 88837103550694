<template>
    <div class="tab-pane fade" id="list-settings-course-categories" role="tabpanel"
        aria-labelledby="list-settings-course-categories-list">
        <div v-if="create_course_category_form">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        {{ create_course_category_form ? "Create Course Category" : "All Course Categories" }}
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div v-if="create_course_category_form" class="col-lg-3 offset-9 py-4 text-right">
                                <button @click="create_course_category_form = false" style="float: right;" type="button"
                                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Category Name <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="course_category_form.category_name"
                                        placeholder="Enter the course category name" solo class="mt-2"
                                        dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" offset-md="3" class="remove-hint">
                                    <button @click="createCourseCategoryLoading ? null : create_course_category()"
                                        style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ createCourseCategoryText }}
                                        <v-icon
                                            :style="!createCourseCategoryLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Course Categories Management
                    </div>
                    <!-- <div class="col-lg-6" v-if="permissionIsAssigned('designation-create')"> -->
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button @click="create_course_category_form = true" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Create Course Category
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Course Category Name
                            </th>
                            <!-- <th
                                v-if="permissionIsAssigned('designation-update') || permissionIsAssigned('designation-delete')"
                                scope="col"
                                style="width: 10px;"
                            > -->
                            <th scope="col" style="width: 10px;">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="category in authCompanyCourseCategories" :key="category.id">
                            <td class="text-left">{{ category.category_name }}</td>
                            <!-- <td
                                v-if="permissionIsAssigned('designation-update') || permissionIsAssigned('designation-delete')"
                                style="padding-top: 13px;"
                                class="employee-options"
                            > -->
                            <td style="padding-top: 13px;" class="employee-options">
                                <div class="row">
                                    <!-- <i
                                        v-if="permissionIsAssigned('designation-update')"
                                        @click="launch_edit_course_category(d)"
                                        class="col-3 py-4 text-center fal fa-edit mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                    <i
                                        v-if="permissionIsAssigned('designation-delete')"
                                        @click="delete_course_category(d)"
                                        class="col-3 py-4 text-center fal fa-trash ml-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i> -->
                                    <i @click="launch_edit_course_category(category)"
                                        class="col-3 py-4 text-center fal fa-edit mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                    <i @click="delete_course_category(category)"
                                        class="col-3 py-4 text-center fal fa-trash ml-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <v-dialog v-model="course_category_edit_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Course Category</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <v-text-field dense v-model="edit_course_category_form.category_name"
                                            placeholder="Designation Title" solo class="mt-2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button @click="edit_course_category_submit ? null : update_course_category()"
                                            style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ edit_course_category_text }}
                                            <v-icon
                                                :style="!edit_course_category_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="course_category_edit_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            create_course_category_form: false,
            course_category_form: {
                category_name: ""
            },
            edit_course_category_form: {
                id: "",
                category_name: ""
            },
            edit_course_category_text: "Update",
            edit_course_category_submit: false,
            createCourseCategoryText: "Create",
            createCourseCategoryLoading: false,
            course_category_edit_dialog: false,
        }
    },
    computed: {
        ...mapState([
            'userToken'
        ]),
        ...mapGetters([
            'authCompanyData',
            'authCompanyCourseCategories'
        ])
    },
    methods: {
        ...mapActions([
            'fetch_company_course_categories'
        ]),
        launch_edit_course_category(category) {
            this.edit_course_category_form.id = category.id;
            this.edit_course_category_form.designation_title = category.category_name;
            this.course_category_edit_dialog = true;
        },
        delete_course_category(category) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this course category?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}deletecoursecategory`, {
                            company_id: _this.authCompanyData.id,
                            category_id: category.id
                        }, {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`
                            }
                        })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "The course category deleted successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Course category couldn't be deleted",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_course_categories();
                            });
                    }
                });
        },
        update_course_category() {
            const _this = this;
            _this.edit_course_category_text = "";
            _this.edit_course_category_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updatecoursecategory`, {
                company_id: _this.authCompanyData.id,
                category_id: _this.edit_course_category_form.id,
                category_name: _this.edit_course_category_form.category_name
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The course category has been updated successfully",
                    });
                    _this.edit_course_category_form.id = null;
                    _this.edit_course_category_form.category_name = "";
                    _this.fetch_company_course_categories();
                    _this.course_category_edit_dialog = false;
                })
                .catch(function (error) {
                    const errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : "The course category couldn't be updated"
                            : null || error.response.data.error || error.response.data.message || "The course category couldn't be updated",
                    });
                })
                .then(function () {
                    _this.edit_course_category_submit = false;
                    _this.edit_course_category_text = "Update";
                });
        },
        create_course_category() {
            const _this = this;
            _this.createCourseCategoryText = "";
            _this.createCourseCategoryLoading = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios.post(`${BASE_URL}storecoursecategory`, {
                company_id: _this.authCompanyData.id,
                category_name: _this.course_category_form.category_name
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The course category " + _this.course_category_form.category_name + " was added successfully",
                    });
                    _this.course_category_form.category_name = "";
                    _this.fetch_company_course_categories();
                    _this.create_course_category_form = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.category_name
                                    ? errors.category_name[0]
                                    : null
                            : null || error.response.data.error || error.response.data.message || "The course category could not be added",
                    });
                })
                .then(function () {
                    _this.createCourseCategoryLoading = false;
                    _this.createCourseCategoryText = "Submit";
                });
        }
    }
}
</script>

<style></style>