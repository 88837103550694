<template>
  <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'mt-5' : null"
    class="padTop15 container">
    <div>
      <h2>Welcome to KylianERP</h2>
      <p class="lead">
        This solution engenders organizational efficiency
        of your organization by managing and improving
        how your company resources are utilized.
      </p>
      <router-link
        :to="{ name: 'ClientArea', params: { subdomain: authCompanyData.subdomain }, query: { addon: 'first-time' } }"
        class="mt-7 mx-auto text-center" style="display: block;">
        <v-btn type="submit" x-large dark class="create-submit1">
          Go to Clientarea
        </v-btn>
      </router-link>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img src="@/assets/graphics/attention.png" alt="" class="img-fluid" />
              </div>
              <span class="msg pl-5 pr-5 text-center" style="display: block;">Welcome to Kylian ERP. Proceed to add
                employees and take charge
                of your business</span>
              <router-link to="javascript: void(0);" class="mt-7 mx-auto text-center" style="display: block;">
                <v-btn type="submit" x-large dark class="create-submit">
                  {{ btnTxt }}
                </v-btn>
              </router-link>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="dialog = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      btnTxt: "Add Employees",
      isLoading: false,
      dialog: false,
      hovered: 0
    };
  },
  computed: {
    ...mapGetters([
      'validEmployeeId',
      'authCompanyData',
      'authSubscriptionExpired'
    ])
  },
  methods: {
    //
  }
};
</script>

<style scoped lang="scss">
@media (max-width: 991px) {
  .create-submit1 {
    width: 60% !important;
    height: 45px !important;
  }
}

.msg {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #111d5e;
}

.create-submit {
  width: 50%;
  height: 60px;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
  }
}

.create-submit1 {
  width: 35%;
  height: 60px;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
  }
}

a {
  color: #069;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5314737558364868px;
  text-align: left;
}

a:hover {
  text-decoration: none;
}

.padTop15 {
  padding-top: 3%;
}

h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 57px;
  letter-spacing: -1.563158392906189px;
  text-align: left;
}

.lead {
  padding: 2% 0 0 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: left;
}
</style>
