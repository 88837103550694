<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4"
      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null">
      <div class="main-header pl-4 px-2" style="z-index: 60;"
        :class="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'mt-2' : null">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-4 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="permissionIsAssigned('leave-read') || authCompanyAllLeave.length >= 1" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
        employeeView ? "Your Leave" : "All Employees' Leave"
      }}</span>
            </button>
            <div v-if="permissionIsAssigned('leave-read') || authCompanyAllLeave.length >= 1" class="dropdown-menu"
              style="min-width: 13rem;">
              <a @click="(employeeView = !employeeView, employeeView == false ? $router.push({ name: 'Absence or Leave' }) : null)"
                class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Employees' Leave" : "Your Leave" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div :class="$vuetify.breakpoint.name == 'xs' ? 'pr-0' : 'pr-5'" class="header-part-right">
          <div class="dropdown animate__animated animate__fadeIn">
            <button v-if="(view_leave_status != 3)" @click="(view_leave_status = 3)" style="float: right;" type="button"
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              View Policies
            </button>
            <button v-if="(view_leave_status == 1 || view_leave_status == 3)" @click="(view_leave_status = 2)"
              style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              Apply
            </button>
            <button v-if="view_leave_status == 2 || view_leave_status == 3" @click="(view_leave_status = 1)"
              style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
              All Leave Requests
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <template v-if="(view_leave_status == 1)">
        <!-- ======== Body content start ========= -->
        <div class="main-content px-1 pt-0">
          <div class="breadcrumb mb-0"
            :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'margin-left: 1rem;' : 'margin-left: 2rem;'"
            style="margin-top: 1rem;">
            <h1 class="my-0 row pb-0">
              <span class="col-12 col-lg-4 col-md-4 px-0">Leave Status</span>
              <v-row class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col offset-md="6" offset-lg="6" md="3" lg="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="allYears" placeholder="Year" dense solo
                    messages=" *Filter by year" :full-width="true" v-model="yearSelected"></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select class="remove-mb" :items="monthNames" placeholder="Month" dense solo
                    messages="*Filter by month" :full-width="true" v-model="monthSelected"></v-select>
                </v-col>
                <!-- <v-col v-if="(monthSelected && yearSelected && monthly_status == 'pending') || (monthSelected && yearSelected && monthly_status == 'disapproved')" lg="2" md="2" cols="12" class="pt-0">
                  <button
                    type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                  >
                    Print Payslip
                  </button>
                </v-col> -->
              </v-row>
            </h1>
          </div>
          <div class="main-content mt-0"
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
            <div class="card" style="border-radius: 10px;">
              <div class="card-body px-1 py-1 pt-0 pr-0">
                <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style="border-top: none;" scope="col" class="text-left">Start Date</th>
                          <th style="border-top: none;" scope="col" class="text-left">End Date</th>
                          <th style="border-top: none;" scope="col" class="text-left">Leave Type</th>
                          <th style="border-top: none;" class="text-left" scope="col">Attached Document</th>
                          <th style="border-top: none;" class="text-center" scope="col">Status</th>
                          <th style="border-top: none;" scope="col" class="text-left">Purpose of Leave</th>
                          <th style="border-top: none;" scope="col" class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="leave in allLeaveFiltered">
                          <tr v-if="allLeaveFiltered.length >= 1" :key="leave.id">
                            <td class="text-left">{{ leave.leave_start }}</td>
                            <td class="text-left">{{ leave.leave_end }}</td>
                            <td class="text-left">{{ get_type_name(leave.leave_type) }}</td>
                            <td v-if="leave.file" class="text-left">
                              <a :href="assets_url + leave.file" target="_blank" rel="noopener noreferrer">
                                View Document
                              </a>
                            </td>
                            <td v-else class="text-left">
                              <i>No attached document</i>
                            </td>
                            <td class="text-center" v-if="leave.status == 0">
                              <span style="width: 60px;" class="badge badge-danger text-white">DENIED</span>
                            </td>
                            <td class="text-center" v-else-if="leave.status == 1">
                              <span style="width: 60px;" class="badge badge-success">APPROVED</span>
                            </td>
                            <td class="text-center" v-else>
                              <span style="width: 60px;" class="badge badge-warning text-white">PENDING</span>
                            </td>
                            <td class="text-left" v-if="leave.additional_comments">
                              {{ leave.additional_comments }}
                            </td>
                            <td class="text-left" v-else><i>No comment</i></td>
                            <td style="padding-top: 25px;" class="employee-options px-0">
                              <div class="row" v-if="leave.status == 0 || leave.status == null">
                                <i @click="(current_leave_id = leave.id, fileAttachmentDialog = true)"
                                  class="col-lg-2 col-xl-2 col-1 py-0 text-center fal fa-file-plus"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                <i @click="view_comment(leave)"
                                  class="col-lg-2 col-xl-2 col-1 py-0 text-center fal fa-message-dots"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                <i :style="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'margin-left: 30%;' : null"
                                  @click="launch_edit_leave(leave)"
                                  class="col-lg-2 col-xl-2 col-1 py-0 text-center fal fa-edit ml-2"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                <i @click="delete_leave(leave)"
                                  class="col-lg-2 col-xl-2 col-1 py-0 text-center fal fa-trash ml-2"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              </div>
                              <div class="row" v-else>
                                <i @click="view_comment(leave)"
                                  class="col-lg-2 col-xl-2 col-1 py-0 text-center fal fa-message-dots"
                                  style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                <i :style="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'margin-left: 30%;' : null"
                                  class="col-lg-2 col-xl-2 col-1 py-0 text-center fal fa-edit ml-2"
                                  style="color: #069 !important; font-size: 125%; opacity: 0.5;"></i>
                                <i class="col-lg-2 col-xl-2 col-1 py-0 text-center fal fa-trash ml-2"
                                  style="color: #069 !important; font-size: 125%; opacity: 0.5;"></i>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Footer Start -->
        <div class="flex-grow-1"></div>
        <!-- <div class="main-header pl-5" style="position: fixed; bottom: 0px; z-index: 60;">
                    <div class="row">
                        <div class="logo col-md-4 py-0">
                            <div class="dropdown">
                                <button @click="apply_for_leave()" type="button" class="btn btn-secondary m-1 ripple"> 
                                    {{ apply_for_leave_text }} 
                                    <v-icon :style="!apply_for_leave_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                        fas fa-circle-notch fa-spin
                                    </v-icon> 
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style="margin: auto"></div>
                    <div class="header-part-right pr-5">
                    </div>
                </div> -->
        <!-- fotter end -->
      </template>
      <template v-else-if="(view_leave_status == 2)">
        <!-- ======== Body content start ========= -->
        <div class="main-content">
          <div class="breadcrumb mb-0" style="margin-top: 1rem; margin-left: 1rem;">
            <h1>
              <strong>Apply for Leave</strong>
            </h1>
          </div>
          <v-form ref='form1' @submit.prevent>
            <div class="container-fluid">
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <span style="color: #111d5e;">Select Leave Type <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-select v-model="formData.leave_type" item-color="#069" :items="leave_type_names"
                    placeholder="Select the leave type" solo class="mt-2"
                    :messages="main_current_message ? '*' + main_current_message : null" :rules="[
        v => !!v || 'Leave type is required'
      ]" color="#069"></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <span style="color: #111d5e;">Leave Start Date <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                        v-model="formData.leave_start" placeholder="Enter the leave start date" solo class="mt-2"
                        readonly v-bind="attrs" v-on="on" :rules="[
        v => !!v || 'Date is required'
      ]"></v-text-field>
                    </template>
                    <v-date-picker color="#069" ref="picker" v-model="formData.leave_start" :min="new Date(new Date().getTime())
        .toISOString()
        .substring(0, 10)
        " :max="new Date(new Date().getTime() + 86400000000)
        .toISOString()
        .substring(0, 10)
        " @change="save"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <span style="color: #111d5e;">Leave End Date <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                        v-model="formData.leave_end" placeholder="Enter the leave end date" solo class="mt-2" readonly
                        v-bind="attrs" v-on="on" :rules="[
        v => !!v || 'Date is required'
      ]"></v-text-field>
                    </template>
                    <v-date-picker color="#069" ref="picker2" v-model="formData.leave_end" :min="new Date(new Date().getTime())
        .toISOString()
        .substring(0, 10)
        " :max="new Date(new Date().getTime() + 86400000000)
        .toISOString()
        .substring(0, 10)
        " @change="save2"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <span style="color: #111d5e;">Purpose of Leave <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-textarea v-model="formData.additional_comments" placeholder="Add additonal comments" solo
                    class="mt-2" :rules="[
        v => !!v || 'Purpose of leave is required',
        v => v.length >= 5 || 'Purpose of leave must be at least 5 characters long',
      ]"></v-textarea>
                </v-col>
                <v-col style="cursor: pointer;" cols="12">
                  <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                    label="Upload File Attachment" solo style="text-indent: 5px;" color="#069" class="leave-file"
                    messages="Leave file attachment">
                  </v-file-input>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </div>
        <!-- Footer Start -->
        <div class="flex-grow-1"></div>
        <div class="main-header pl-5 px-2" style="position: fixed; bottom: 0px; z-index: 60;">
          <div class="row">
            <div class="logo col-md-4 py-0">
              <div class="dropdown">
                <button @click="apply_for_leave_submit ? null : apply_for_leave()" type="button"
                  class="btn btn-secondary m-1 ripple">
                  {{ apply_for_leave_text }}
                  <v-icon :style="!apply_for_leave_submit
        ? 'display: none;'
        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
        ">
                    fas fa-circle-notch fa-spin
                  </v-icon>
                </button>
              </div>
            </div>
          </div>
          <div style="margin: auto"></div>
          <div class="header-part-right pr-5">
            <!-- Notificaiton End -->
            <!-- User avatar dropdown -->
          </div>
        </div>
        <!-- fotter end -->
      </template>
      <template v-else>
        <!-- ======== Body content start ========= -->
        <div class="main-content px-1 pt-0">
          <div class="breadcrumb mb-0"
            :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'margin-left: 1rem;' : 'margin-left: 2rem;'"
            style="margin-top: 1rem;">
            <h1 class="my-0 row pb-0">
              <span class="col-12 col-lg-4 col-md-4 px-0">All Leave Policies</span>
            </h1>
          </div>
          <div class="main-content mt-0"
            :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
            <div class="card" style="border-radius: 10px;">
              <div class="card-body px-1 py-1 pt-0 pr-0">
                <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style="border-top: none;" scope="col" class="text-left">Leave policy name</th>
                          <th style="border-top: none;" scope="col" class="text-left">Leave policy description</th>
                          <th style="border-top: none;" scope="col" class="text-left">Days left</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="leave in all_leave_remaining_calc" :key="leave.id">
                          <td class="text-left">{{ leave.leave_name }}</td>
                          <td class="text-left">{{ leave.leave_description }}</td>
                          <td class="text-left">{{ leave.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Footer Start -->
        <div class="flex-grow-1"></div>
        <!-- <div class="main-header pl-5" style="position: fixed; bottom: 0px; z-index: 60;">
                    <div class="row">
                        <div class="logo col-md-4 py-0">
                            <div class="dropdown">
                                <button @click="apply_for_leave()" type="button" class="btn btn-secondary m-1 ripple"> 
                                    {{ apply_for_leave_text }} 
                                    <v-icon :style="!apply_for_leave_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                        fas fa-circle-notch fa-spin
                                    </v-icon> 
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style="margin: auto"></div>
                    <div class="header-part-right pr-5">
                    </div>
                </div> -->
        <!-- fotter end -->
      </template>
    </div>
    <v-row justify="center">
      <v-dialog v-model="leave_edit_dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{
        "Edit Leave Application"
      }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref='form2' @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <span style="color: #111d5e;">Select Leave Type <i class="fas fa-asterisk"
                          style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                      <v-select v-model="leave_edit_form.leave_type" item-color="#069" :items="leave_type_names"
                        placeholder="Select the leave type" solo class="mt-2"
                        :messages="main_current_message_2 ? '*' + main_current_message_2 : null" :rules="[
        v => !!v || 'Leave type is required'
      ]" color="#069">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <span style="color: #111d5e;">Leave Start Date <i class="fas fa-asterisk"
                          style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                            v-model="leave_edit_form.leave_start" placeholder="Enter the leave start date" solo
                            class="mt-2" readonly v-bind="attrs" v-on="on" :rules="[
        v => !!v || 'Date is required'
      ]"></v-text-field>
                        </template>
                        <v-date-picker color="#069" ref="picker" v-model="leave_edit_form.leave_start" :min="new Date(new Date().getTime())
        .toISOString()
        .substring(0, 10)
        " :max="new Date(new Date().getTime() + 86400000000)
        .toISOString()
        .substring(0, 10)
        " @change="save"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <span style="color: #111d5e;">Leave End Date <i class="fas fa-asterisk"
                          style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                      <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                            v-model="leave_edit_form.leave_end" placeholder="Enter the leave end date" solo class="mt-2"
                            readonly v-bind="attrs" v-on="on" :rules="[
        v => !!v || 'Date is required'
      ]"></v-text-field>
                        </template>
                        <v-date-picker color="#069" ref="picker2" v-model="leave_edit_form.leave_end" :min="new Date(new Date().getTime())
        .toISOString()
        .substring(0, 10)
        " :max="new Date(new Date().getTime() + 86400000000)
        .toISOString()
        .substring(0, 10)
        " @change="save2"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <span style="color: #111d5e;">Purpose of Leave <i class="fas fa-asterisk"
                          style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                      <v-textarea v-model="leave_edit_form.additional_comments" placeholder="Add additonal comments"
                        solo class="mt-2" :rules="[
        v => !!v || 'Purpose of leave is required',
        v => v.length >= 5 || 'Purpose of leave must be at least 5 characters long',
      ]"></v-textarea>
                    </v-col>

                    <v-col style="cursor: pointer;" cols="12">
                      <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                        label="Upload File Attachment" solo style="text-indent: 5px;" color="#069"
                        class="leave-file-update" messages="Leave file attachment">
                      </v-file-input>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3">
                      <button @click="leave_edit_submit ? null : update_leave()" style="width: 100%;" type="button"
                        class="btn btn-secondary m-1 ripple">
                        {{ leave_edit_text }}
                        <v-icon :style="!leave_edit_submit
        ? 'display: none;'
        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
        ">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="leave_edit_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="leave_comment_dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Leave Comments</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="text-left">Date Sent</th>
                    <th class="text-left">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="comment in all_leave_comments" :key="comment.id">
                    <td class="text-left">
                      {{ new Date(comment.created_at).toUTCString().substring(0, 25) }}
                    </td>
                    <td class="text-left">
                      {{ comment.comments }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="leave_comment_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="fileAttachmentDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Attaching Document</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref='form3' @submit.prevent>
                <div class="container-fluid">
                  <v-row>
                    <v-col style="cursor: pointer;" cols="12" sm="12" md="12">
                      <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                        label="Upload Attaching Document" solo style="text-indent: 5px;" color="#069"
                        class="fileAttachment" messages="Attaching Document" :rules="[
        v => !!v || 'Document is required'
      ]">
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3">
                      <button @click="fileAttachmentBtnSubmit ? null : upload_file_attachment()" style="width: 100%;"
                        type="button" class="btn btn-secondary m-1 ripple">
                        {{ fileAttachmentBtnText }}
                        <v-icon :style="!fileAttachmentBtnSubmit
        ? 'display: none;'
        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
        ">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="fileAttachmentDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { ASSETS_URL, BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      fileAttachmentBtnSubmit: false,
      fileAttachmentBtnText: 'Upload',
      fileAttachmentDialog: false,
      firstYear: "",
      monthSelected: "",
      yearSelected: "",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      current_leave_id: '',
      employeeView: true,
      current_message: '',
      current_message_2: '',
      all_leave_remaining: [],
      all_leave_remaining_calc: [],
      leave_edit_dialog: false,
      leave_edit_form: {
        id: null,
        leave_type: "",
        leave_start: "",
        leave_end: "",
        additional_comments: ""
      },
      leave_edit_text: "Update",
      leave_edit_submit: false,
      all_leave: [],
      view_leave_status: 1,
      menu: false,
      menu2: false,
      formData: {
        leave_type: "",
        leave_start: "",
        leave_end: "",
        additional_comments: ""
      },
      all_leave_type: [],
      apply_for_leave_text: "Submit",
      apply_for_leave_submit: false,
      all_leave_comments: [],
      leave_comment_dialog: false
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    }
    // all_leave_type(newVal) {
    //     this.all_leave_type.forEach(type => {
    //         this.leave_type_names.push(type.leave_name);
    //     });
    // }
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "authUserData",
      "authCompanyAllLeaveType",
      "authCompanyAllCheckin",
      "authCompanyAllLeave",
    ]),
    assets_url() {
      return ASSETS_URL;
    },
    allLeaveFiltered() {
      let _this = this, result = [];
      if (_this.all_leave.length >= 1) {
        if (_this.yearSelected && _this.monthSelected) {
          result = _this.all_leave.filter(r => {
            return r.year == _this.yearSelected && (r.start_month == _this.monthSelected || r.end_month == _this.monthSelected)
          })
        } else {
          result = _this.all_leave.filter(r => {
            return r.year == _this.yearSelected
          })
        }
        return result;
      } else {
        return result;
      }
    },
    allYears: function () {
      var t = [],
        e = new Date().getFullYear();
      t.push(this.firstYear);
      for (var a = this.firstYear; a <= e; a++) if (t.indexOf(a) == -1) { t.push(a.toString()); }
      return t.reverse();
    },
    main_current_message() {
      let _this = this, type_id;
      if (_this.formData.leave_type && _this.all_leave_remaining_calc) {
        type_id = _this.get_leave_type_id(_this.formData.leave_type);
        return _this.all_leave_remaining_calc.filter(l => {
          return type_id == l.id
        })[0].message;
      } else {
        return "";
      }
    },
    main_current_message_2() {
      let _this = this, type_id;
      if (_this.leave_edit_form.leave_type && _this.all_leave_remaining_calc) {
        type_id = _this.get_leave_type_id(_this.leave_edit_form.leave_type);
        return _this.all_leave_remaining_calc.filter(l => {
          return type_id == l.id
        })[0].message;
      } else {
        return "";
      }
    },
    leave_type_id() {
      let _this = this;
      if (_this.formData.leave_type) {
        return _this.all_leave_type.filter(type => {
          return _this.formData.leave_type == type.leave_name;
        })[0].id;
      } else {
        return "";
      }
    },
    leave_edit_type_id() {
      let _this = this;
      if (_this.leave_edit_form.leave_type) {
        return _this.all_leave_type.filter(type => {
          return _this.leave_edit_form.leave_type == type.leave_name;
        })[0].id;
      } else {
        return "";
      }
    },
    leave_type_names() {
      let _this = this,
        all = [];
      _this.all_leave_type.forEach(type => {
        all.push(type.leave_name);
      });
      return all;
    }
  },
  methods: {
    ...mapActions(["fetch_all_leave"]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    async upload_file_attachment() {
      let _this = this;
      const valid = await _this.$refs.form3.validate();
      if (!valid) {
        return;
      }
      _this.fileAttachmentBtnText = "";
      _this.fileAttachmentBtnSubmit = true;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      let formData = new FormData();
      formData.append(
        "leave_id",
        _this.current_leave_id
      );
      formData.append(
        "file",
        document.querySelector(".fileAttachment div div div input").files[0]
      );

      axios
        .post(`${BASE_URL}uploadleavedoc`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Attaching document uploaded successfully"
          });
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
          _this.fetch_all_employee_leave();
          _this.fileAttachmentDialog = false;
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.file ? errors.file[0] : null) : null
              || error.response.data.error || error.response.data.message || 'Attaching document could not be uploaded'
          });
        })
        .then(function () {
          _this.fileAttachmentBtnSubmit = false;
          _this.fileAttachmentBtnText = "Upload";
        });
    },
    fetch_leave_left() {
      let _this = this;
      if (_this.authCompanyAllLeaveType.length >= 1) {
        _this.all_leave_remaining_calc = [];
        _this.authCompanyAllLeaveType.forEach(t => {
          axios
            .post(`${BASE_URL}leaveremain`, {
              company_id: _this.authEmployeeDataForCompany.company_id,
              employee_id: _this.authEmployeeDataForCompany.id,
              leavetypes: [
                {
                  id: t.id,
                  year: new Date().getFullYear()
                }
              ]
            })
            .then(function (response) {
              _this.all_leave_remaining_calc.push({
                id: t.id,
                leave_description: t.description,
                message: response.data.message,
                leave_name: t.leave_name
              });
            })
            .catch(function () {
              _this.all_leave_remaining_calc.push({
                id: t.id,
                message: 'Error in evaluation',
                leave_name: t.leave_name
              });
            })
            .then(function () {
              //
            })
        });
      }
    },
    get_leave_type_id(name) {
      let _this = this;
      if (name) {
        return _this.all_leave_type.filter(type => {
          return name == type.leave_name;
        })[0].id;
      } else {
        return "";
      }
    },
    view_comment(leave) {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchallcommentbyleaveid`, {
          company_id: leave.company_id,
          leave_id: leave.id
        })
        .then(function (response) {
          _this.all_leave_comments = response.data.comments
          _this.leave_comment_dialog = true;
        })
        .catch(function () {
          //
        })
        .then(function () {
          //
        });
    },
    fetch_type_name(id) {
      let _this = this;
      if (_this.all_leave_type) {
        return _this.all_leave_type.filter(type => {
          return type.id == id
        })[0].leave_name;
      } else {
        return '';
      }
    },
    fetch_days_left() {
      let _this = this;
      if (_this.all_leave_remaining) {
        _this.current_message = _this.all_leave_remaining.filter(lr => {
          return lr.leave_type == _this.formData.leave_type
        })[0].message;
      } else {
        _this.current_message = '';
      }
    },
    fetch_days_left_2() {
      let _this = this;
      if (_this.all_leave_remaining) {
        _this.current_message_2 = _this.all_leave_remaining.filter(lr => {
          return lr.leave_type == _this.leave_edit_form.leave_type
        })[0].message;
      } else {
        _this.current_message_2 = '';
      }
    },
    async update_leave() {
      let _this = this;
      const valid = await _this.$refs.form2.validate();
      if (!valid) {
        return;
      }
      _this.leave_edit_text = "";
      _this.leave_edit_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      if (new Date(_this.leave_edit_form.leave_start).getTime() > new Date(_this.leave_edit_form.leave_end).getTime()) {
        Toast.fire({
          icon: "warning",
          title: "Please ensure the start date comes before the end date"
        });
        return;
      }

      let form_data = new FormData();
      form_data.append("company_id", _this.authEmployeeDataForCompany.company_id);
      form_data.append("id", _this.leave_edit_form.id);
      form_data.append("employee_id", _this.authEmployeeDataForCompany.id);
      form_data.append("leave_type", _this.leave_edit_type_id);
      form_data.append("leave_start", _this.leave_edit_form.leave_start);
      form_data.append("leave_end", _this.leave_edit_form.leave_end);
      form_data.append("additional_comments", _this.leave_edit_form.additional_comments);
      form_data.append("file", document.querySelector(".leave-file-update div div div input").files[0]);

      axios
        .post(`${BASE_URL}editapplyleave`, form_data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Your leave application has been updated successfully"
          });
          _this.leave_edit_form.id = null;
          _this.leave_edit_form.leave_type = "";
          _this.leave_edit_form.leave_start = "";
          _this.leave_edit_form.leave_end = "";
          _this.leave_edit_form.additional_comments = "";
          _this.fetch_all_employee_leave();
          _this.fetch_all_leave_type();
          _this.fetch_leave_left();
          _this.leave_edit_dialog = false;
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
        })
        .catch(function (error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors ? (typeof errors === 'string' ? errors : null
              || errors.leave_type ? errors.leave_type[0] : null
                || errors.leave_start ? errors.leave_start[0] : null
                  || errors.leave_end ? errors.leave_end[0] : null
                    || errors.additional_comments ? errors.additional_comments[0] : null) : null
                    || error.response.data.error || error.response.data.message || 'Your leave application couldn\'t be updated'
          });
        })
        .then(function () {
          _this.leave_edit_submit = false;
          _this.leave_edit_text = "Update";
        });
    },
    launch_edit_leave(leave) {
      this.leave_edit_form.id = leave.id;
      this.leave_edit_form.leave_type = this.get_type_name(leave.leave_type);
      this.leave_edit_form.leave_start = leave.leave_start;
      this.leave_edit_form.leave_end = leave.leave_end;
      this.leave_edit_form.additional_comments = leave.additional_comments;
      this.leave_edit_dialog = true;
      this.fetch_days_left_2();
    },
    delete_leave(leave) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      _this.$swal
        .fire({
          title: "Are you sure you want to delete this leave application?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deleteapplyleave`, {
                ids: [
                  {
                    id: leave.id
                  }
                ]
              })
              .then(function () {
                _this.fetch_all_employee_leave();
                _this.fetch_leave_left();
                Toast.fire({
                  icon: "success",
                  title: "Leave application deleted successfully"
                });
              })
              .catch(function () {
                Toast.fire({
                  icon: "error",
                  title: "Leave application couldn't be deleted"
                });
              })
              .then(function () {
                //
              });
          }
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    get_type_name(type_id) {
      let _this = this;
      if (_this.all_leave_type) {
        let type = _this.all_leave_type.filter(type => type.id == type_id);
        return type.length ? type[0].leave_name : null;
      } else {
        return "";
      }
    },
    fetch_all_employee_leave() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchallleavebyempid`, {
          company_id: _this.authEmployeeDataForCompany.company_id,
          employee_id: _this.authEmployeeDataForCompany.id
        })
        .then(function (response) {
          const RESPONSE = response.data;
          _this.all_leave = RESPONSE.Employees;
        })
        .catch(function () {
          //
        })
        .then(function () {
          //
        });
    },
    fetch_all_leave_type() {
      let _this = this;
      axios
        .post(`${BASE_URL}fetchleavetype`, {
          company_id: _this.authEmployeeDataForCompany.company_id
        })
        .then(function (response) {
          const RESPONSE = response.data;
          let temp_leave_type = RESPONSE.Employees.map(leave => {
            return {
              company_id: leave.company_id,
              created_at: leave.created_at,
              department_id: leave.department_id,
              description: leave.description,
              id: leave.id,
              leave_duration: leave.leave_duration,
              leave_name: leave.leave_name,
              unpaid_timeoff: leave.unpaid_timeoff,
              updated_at: leave.updated_at
            };
          });
          _this.all_leave_type = temp_leave_type;
        })
        .catch(function () {
          //
        })
        .then(function () {
          //
        });
    },
    async apply_for_leave() {
      let _this = this;
      const valid = await _this.$refs.form1.validate();
      if (!valid) {
        return;
      }
      _this.apply_for_leave_text = "";
      _this.apply_for_leave_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      if (new Date(_this.formData.leave_start).getTime() > new Date(_this.formData.leave_end).getTime()) {
        Toast.fire({
          icon: "warning",
          title: "Please ensure the start date comes before the end date"
        });
        return;
      }

      let form_data = new FormData();
      form_data.append("company_id", _this.authEmployeeDataForCompany.company_id);
      form_data.append("leave_type", _this.leave_type_id);
      form_data.append("employee_id", _this.authEmployeeDataForCompany.id);
      form_data.append("employee_name", _this.authUserData.name);
      form_data.append("leave_start", _this.formData.leave_start);
      form_data.append("leave_end", _this.formData.leave_end);
      form_data.append("additional_comments", _this.formData.additional_comments);
      form_data.append("file", document.querySelector(".leave-file div div div input").files[0]);

      axios
        .post(`${BASE_URL}applyleave`, form_data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function () {
          _this.formData.leave_type = "";
          _this.formData.leave_start = "";
          _this.formData.leave_end = "";
          _this.formData.additional_comments = "";
          _this.fetch_all_employee_leave();
          _this.fetch_all_leave_type();
          _this.fetch_leave_left();
          Toast.fire({
            icon: "success",
            title: "Leave Application Sent"
          });
          _this.view_leave_status = 1;
          document
            .querySelector(
              '[class="v-icon notranslate v-icon--link theme--light"]'
            )
            .click();
        })
        .catch(function (error) {
          if (window.navigator.onLine) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors ? (typeof errors === 'string' ? errors : null
                || errors.leave_type ? errors.leave_type[0] : null
                  || errors.leave_start ? errors.leave_start[0] : null
                    || errors.leave_end ? errors.leave_end[0] : null
                      || errors.additional_comments ? errors.additional_comments[0] : null) : null
                      || error.response.data.error || error.response.data.message || 'Your leave application couldn\'t be sent'
            });
            console.log(error.response);
          } else {
            Toast.fire({
              icon: "error",
              title: "Please check your internet connectivity"
            });
          }
        })
        .then(function () {
          _this.apply_for_leave_submit = false;
          _this.apply_for_leave_text = "Submit";
        });
    }
  },
  mounted() {
    let _this = this;
    _this.fetch_all_leave();
    _this.fetch_all_employee_leave();
    _this.fetch_all_leave_type();
    _this.fetch_leave_left();
    document.title = "Kylian ERP - All Leave";
    this.yearSelected = new Date().getFullYear().toString();

    _this.firstYear = new Date(_this.authCompanyData.created_at).getFullYear().toString();

    if (_this.all_leave.length >= 1 && _this.all_leave_type.length >= 1) {
      console.log(_this.all_leave_type);
      console.log(_this.all_leave);
      _this.all_leave.forEach(lv => {
        axios
          .post(`${BASE_URL}leaveremain`, {
            company_id: lv.company_id,
            employee_id: lv.employee_id,
            leavetypes: [
              {
                id: lv.id,
                year: new Date().getFullYear()
              }
            ]
          })
          .then(function (response) {
            _this.all_leave_remaining.push({
              id: lv.id,
              message: response.data.message,
              leave_type: _this.fetch_type_name(lv.leave_type)
            });
          })
          .catch(function () {
            _this.all_leave_remaining.push({
              id: lv.id,
              message: 'Error in evaluation',
              leave_type: lv.leave_type
            });
          })
          .then(function () {
            //
          });
      });
    } else {
      _this.$watch('all_leave', new_value => {
        if (new_value) {
          _this.$watch('all_leave_type', new_value_2 => {
            if (new_value_2) {
              _this.all_leave.forEach(lv => {
                axios
                  .post(`${BASE_URL}leaveremain`, {
                    company_id: lv.company_id,
                    employee_id: lv.employee_id,
                    leavetypes: [
                      {
                        id: lv.leave_type,
                        year: new Date().getFullYear()
                      }
                    ]
                  })
                  .then(function (response) {
                    _this.all_leave_remaining.push({
                      id: lv.id,
                      message: response.data.message,
                      leave_type: _this.fetch_type_name(lv.leave_type)
                    });
                  })
                  .catch(function () {
                    _this.all_leave_remaining.push({
                      id: lv.id,
                      message: 'Error in evaluation',
                      leave_type: lv.leave_type
                    });
                  })
                  .then(function () {
                    //
                  });
              });
            }
          })
        }
      })
    }
  }
};
</script>

<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}
</style>
