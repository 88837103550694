<template>
    <div class="tab-pane fade" id="list-settings12" role="tabpanel" aria-labelledby="list-settings-list12">
        <h4>
            <div class="row">
                <div class="col-lg-6" style="align-self: center;">
                    Attendance Configuration
                </div>
            </div>
        </h4>
        <div class="main-content mt-0 px-0">
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Resumption Time</th>
                                <th scope="col">Close Time</th>
                                <th scope="col" class="text-left">
                                    Grace Period
                                </th>
                                <th scope="col" class="px-3 text-left">
                                    Shift Enabled
                                </th>
                                <th scope="col" class="px-3 text-left">
                                    Working Days
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <v-time-picker ampm-in-title format="ampm" v-model="resumption_time" :disabled="!permissionIsAssigned('attendance-config-update')"></v-time-picker>
                                </td>
                                <td>
                                    <v-time-picker ampm-in-title format="ampm" v-model="close_time" :disabled="!permissionIsAssigned('attendance-config-update')"></v-time-picker>
                                </td>
                                <td class="text-left">
                                    <v-select
                                        v-model="grace_period"
                                        item-color="#069"
                                        :items="grace_period_list"
                                        placeholder="Grace period"
                                        value="None"
                                        solo
                                        class="mt-2"
                                        dense
                                        color="#069"
                                        :disabled="!permissionIsAssigned('attendance-config-update')"
                                    ></v-select>
                                </td>
                                <td style="width: 20%;" class="text-left">
                                    <v-checkbox class="mt-3" v-model="shiftEnabled" label="Shift enabled" :disabled="!permissionIsAssigned('attendance-config-update')"></v-checkbox>
                                </td>
                                <td style="width: 20%;" class="text-left">
                                    <v-select
                                        class="mt-1"
                                        placeholder="Work days"
                                        v-model="workDays"
                                        :items="daysOfWeek"
                                        :menu-props="{ maxHeight: '400' }"
                                        label="Select"
                                        multiple
                                        item-color="#069"
                                        solo
                                        dense
                                        color="#069"
                                        :disabled="!permissionIsAssigned('attendance-config-update')"
                                    ></v-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <v-col v-if="permissionIsAssigned('attendance-config-update')" cols="12" sm="4" md="4" offset-md="3" offset-sm="3" class="remove-hint">
                        <button @click="update_checkin_submit ? null : update_checking_details()" style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                            {{ update_checkin_text }}
                            <v-icon :style="!update_checkin_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                fal fa-circle-notch fa-spin
                            </v-icon>
                        </button>
                    </v-col>
                </div>
            </div>
        </div>

        <v-dialog v-model="shift_manager_dialog" max-width="1000px">
            <v-card v-if="create_shift_form == 1">
                <v-card-title class="d-block">
                    <span class="headline">Add a shift</span>
                    <button @click="create_shift_form = 0" style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                        View All Shifts
                    </button>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" class="remove-hint">
                                        <span style="color: #111d5e;">Shift Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="shiftForm.shift_name" placeholder="Enter the shift's name" solo> </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="5" md="5" class="remove-hint">
                                        <v-time-picker v-model="shiftForm.from" ampm-in-title format="ampm"></v-time-picker>
                                    </v-col>
                                    <v-col cols="12" sm="5" md="5" class="remove-hint">
                                        <v-time-picker v-model="shiftForm.to" ampm-in-title format="ampm"></v-time-picker>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" class="remove-hint">
                                        <span style="color: #111d5e;">Grace Period</span>
                                        <v-select v-model="shift_grace_period" item-color="#069" :items="grace_period_list" placeholder="Grace period" value="None" solo class="mt-2" dense color="#069"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button @click="create_shift_submit ? null : create_shift()" style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ create_shift_text }}
                                            <v-icon
                                                :style="!create_shift_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="shift_manager_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-else-if="create_shift_form == 2">
                <v-card-title class="d-block">
                    <span class="headline">Edit shift</span>
                    <button @click="create_shift_form = 0" style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                        View All Shifts
                    </button>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" class="remove-hint">
                                        <span style="color: #111d5e;">Shift Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="editShiftForm.shift_name" placeholder="Enter the shift's name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="5" md="5" class="remove-hint">
                                        <v-time-picker v-model="editShiftForm.from" ampm-in-title format="ampm"></v-time-picker>
                                    </v-col>
                                    <v-col cols="12" sm="5" md="5" class="remove-hint">
                                        <v-time-picker v-model="editShiftForm.to" ampm-in-title format="ampm"></v-time-picker>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" class="remove-hint">
                                        <span style="color: #111d5e;">Grace Period</span>
                                        <v-select v-model="editShiftForm.grace_period" item-color="#069" :items="grace_period_list" placeholder="Grace period" value="None" solo class="mt-2" dense color="#069"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button @click="edit_shift_submit ? null : update_shift()" style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ edit_shift_text }}
                                            <v-icon
                                                :style="!edit_shift_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="shift_manager_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-else>
                <v-card-title class="d-block">
                    <span class="headline">All Shifts</span>
                    <button @click="create_shift_form = 1" style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                        Add Shift
                    </button>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-left" scope="col">Shift Name</th>
                                        <th class="text-left" scope="col">Resumption Time</th>
                                        <th class="text-left" scope="col">Closing Time</th>
                                        <th class="text-left" scope="col">Grace Period</th>
                                        <th scope="col" style="width: 10px;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="shift in authShifts" :key="shift.id">
                                        <td class="text-left">{{ shift.shift_name }}</td>
                                        <td class="text-left">{{ shift.from }}</td>
                                        <td class="text-left">{{ shift.to }}</td>
                                        <td v-if="shift.grace_period" class="text-left">
                                            {{ shift.grace_period == "60" ? "1 Hour" : shift.grace_period + " Minutes" }}
                                        </td>
                                        <td class="text-left" v-else>None</td>
                                        <td style="padding-top: 13px;" class="employee-options">
                                            <div class="row">
                                                <i @click="edit_shift(shift)" class="col-3 py-4 text-center fal fa-edit mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                <i @click="delete_shift(shift)" class="col-3 py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-container>
                </v-card-text>
                <v-row>
                    <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                        <button
                            @click="update_checkin_submit2? null : update_checking_details2(true)"
                            style="width: 100%;"
                            type="button"
                            class="btn btn-secondary m-1 ripple"
                        >
                            {{ update_checkin_text2 }}
                            <v-icon
                                :style="!update_checkin_submit2 ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                            >
                                fal fa-circle-notch fa-spin
                            </v-icon>
                        </button>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="shift_manager_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters } from 'vuex';
export default {
    props: {
        fetch_all_shifts: Function,
        fetch_company_attendance_configuration: Function
    },
    data() {
        return {
            resumption_time: null,
            close_time: null,
            grace_period: "None",
            update_checkin_text: "Update",
            update_checkin_submit: false,
            update_checkin_text2: "Update Configuration",
            update_checkin_submit2: false,
            shift_grace_period: "None",
            grace_period_list: ["None", "5 Minutes", "10 Minutes", "15 Minutes", "20 Minutes", "30 Minutes", "45 Minutes", "1 Hour"],
            shift_manager_dialog: false,
            create_shift_form: 0,
            shiftForm: {
                shift_name: "",
                from: "14:00",
                to: "22:00",
            },
            editShiftForm: {
                id: "",
                shift_name: "",
                from: "",
                to: "",
                grace_period: "None",
            },
            shiftEnabled: false,
            workDays: [],
            daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            create_shift_text: "Submit",
            create_shift_submit: false,
            edit_shift_text: "Update",
            edit_shift_submit: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authShifts',
            'authCompanyAttendanceConfig'
        ]),
        trueWorkDays() {
            let _this = this;
            return _this.workDays.join();
        },
    },
    methods: {
        edit_shift(shift) {
            let _this = this;
            _this.editShiftForm.id = shift.id;
            _this.editShiftForm.shift_name = shift.shift_name;
            _this.editShiftForm.from = shift.from;
            _this.editShiftForm.to = shift.to;
            if (shift.grace_period == "60") {
                _this.editShiftForm.grace_period = "1 Hour";
            } else {
                _this.editShiftForm.grace_period = shift.grace_period + " Minutes";
            }
            if (!shift.grace_period) {
                _this.editShiftForm.grace_period = "None";
            }
            _this.create_shift_form = 2;
        },
        update_shift() {
            let _this = this;
            _this.edit_shift_text = "";
            _this.edit_shift_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            let grace_period_raw;
            if (_this.editShiftForm.grace_period == "1 Hour") {
                grace_period_raw = 60;
            } else {
                grace_period_raw = parseInt(_this.editShiftForm.grace_period.split(" ")[0]);
            }
            axios
            .post(`${BASE_URL}editshift`, {
                shift_id: _this.editShiftForm.id,
                shift_name: _this.editShiftForm.shift_name,
                from: _this.editShiftForm.from,
                to: _this.editShiftForm.to,
                grace_period: grace_period_raw,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "The shift has updated successfully",
                });
                _this.fetch_all_shifts();
                _this.create_shift_form = 0;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.shift_id
                            ? errors.shift_id[0]
                            : null || errors.shift_name
                            ? errors.shift_name[0]
                            : null || errors.from
                            ? errors.from[0]
                            : null || errors.grace_period
                            ? errors.grace_period[0]
                            : null || errors.to
                            ? errors.to[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The shift couldn't be updated",
                });
            })
            .then(function () {
                _this.edit_shift_submit = false;
                _this.edit_shift_text = "Submit";
            });
        },
        delete_shift(shift) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this shift?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                    axios.post(`${BASE_URL}deleteshift`, {
                        shift_id: shift.id,
                    })
                    .then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "Shift deleted successfully",
                        });
                    })
                    .catch(function (error) {
                        Toast.fire({
                            icon: "error",
                            title: "Shift couldn't be deleted",
                        });
                        console.log(error);
                    })
                    .then(function () {
                        _this.fetch_all_shifts();
                    });
                }
            });
        },
        update_checking_details() {
            let _this = this;
            _this.update_checkin_text = "";
            _this.update_checkin_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (_this.workDays.length == 0) {
                Toast.fire({
                    icon: "error",
                    title: "Please enter at least a single work day",
                });
                _this.update_checkin_submit = false;
                _this.update_checkin_text = "Update";
                return;
            }
            let grace_period_raw;
            if (_this.grace_period == "1 Hour") {
                grace_period_raw = 60;
            } else {
                grace_period_raw = parseInt(_this.grace_period.split(" ")[0]);
            }
            if (_this.shiftEnabled) {
                // alert('DO SOME ADVANCED WORK HERE');
                _this.shift_manager_dialog = true;
                _this.update_checkin_submit = false;
                _this.update_checkin_text = "Update";
            } else {
                axios.post(`${BASE_URL}editattendantconfig`, {
                    attendantconfig_id: _this.authCompanyAttendanceConfig.id,
                    resumption_time: _this.resumption_time,
                    close_time: _this.close_time,
                    grace_period: grace_period_raw,
                    shift: _this.shiftEnabled,
                    working_days: _this.trueWorkDays,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The attendance configuration has been updated successfully",
                    });
                    _this.fetch_company_attendance_configuration();
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.resumption_time
                                ? errors.resumption_time[0]
                                : null || errors.close_time
                                ? errors.close_time[0]
                                : null || errors.grace_period
                                ? errors.grace_period[0]
                                : null || errors.shift
                                ? errors.shift[0]
                                : null
                            : null || error.response.data.error || error.response.data.message || "The attendance configuration couldn't be updated",
                    });
                })
                .then(function () {
                    _this.update_checkin_submit = false;
                    _this.update_checkin_text = "Update";
                });
            }
        },
        update_checking_details2(truth) {
            let _this = this;
            _this.update_checkin_text2 = "";
            _this.update_checkin_submit2 = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (_this.workDays.length == 0) {
                Toast.fire({
                    icon: "error",
                    title: "Please enter at least a single work day",
                });
                _this.update_checkin_submit2 = false;
                _this.update_checkin_text2 = "Update Configuration";
                return;
            }
            let grace_period_raw;
            if (_this.grace_period == "1 Hour") {
                grace_period_raw = 60;
            } else {
                grace_period_raw = parseInt(_this.grace_period.split(" ")[0]);
            }
            axios.post(`${BASE_URL}editattendantconfig`, {
                attendantconfig_id: _this.authCompanyAttendanceConfig.id,
                resumption_time: _this.resumption_time,
                close_time: _this.close_time,
                grace_period: grace_period_raw,
                shift: _this.shiftEnabled,
                working_days: _this.trueWorkDays,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "The attendance configuration has been updated successfully",
                });
                _this.fetch_company_attendance_configuration();
                if (truth) {
                    _this.shift_manager_dialog = false;
                }
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.resumption_time
                            ? errors.resumption_time[0]
                            : null || errors.close_time
                            ? errors.close_time[0]
                            : null || errors.grace_period
                            ? errors.grace_period[0]
                            : null || errors.shift
                            ? errors.shift[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The attendance configuration couldn't be updated",
                });
            })
            .then(function () {
                _this.update_checkin_submit2 = false;
                _this.update_checkin_text2 = "Update Configuration";
            });
        },
        create_shift() {
            let _this = this;
            _this.create_shift_text = "";
            _this.create_shift_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            let grace_period_raw;
            if (_this.shift_grace_period == "1 Hour") {
                grace_period_raw = 60;
            } else {
                grace_period_raw = parseInt(_this.grace_period.split(" ")[0]);
            }
            axios.post(`${BASE_URL}createshift`, {
                company_id: _this.authCompanyData.id,
                shift_name: _this.shiftForm.shift_name,
                from: _this.shiftForm.from,
                to: _this.shiftForm.to,
                grace_period: grace_period_raw,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "The shift was added successfully",
                });
                _this.shiftForm.shift_name = "";
                _this.shiftForm.from = "";
                _this.shiftForm.to = "";
                _this.create_shift_form = 0;
                _this.update_checking_details();
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.shift_name
                            ? errors.shift_name[0]
                            : null || errors.from
                            ? errors.from[0]
                            : null || errors.to
                            ? errors.to[0]
                            : null || errors.grace_period
                            ? errors.grace_period[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The shift couldn't be added",
                });
            })
            .then(function () {
                _this.create_shift_submit = false;
                _this.create_shift_text = "Submit";
                _this.fetch_all_shifts();
            });
        },
    },
    mounted() {
        const _this = this;

        if (_this.authCompanyAttendanceConfig.resumption_time) {
            _this.resumption_time = _this.authCompanyAttendanceConfig.resumption_time;
        }

        if (_this.authCompanyAttendanceConfig.close_time) {
            _this.close_time = _this.authCompanyAttendanceConfig.close_time;
        }

        if (_this.authCompanyAttendanceConfig.grace_period) {
            if (_this.grace_period != 60) {
                _this.grace_period = _this.authCompanyAttendanceConfig.grace_period + " Minutes";
            } else {
                _this.grace_period = "1 Hour";
            }
        }

        if (_this.authCompanyAttendanceConfig.enable_shift) {
            if (_this.authCompanyAttendanceConfig.enable_shift == 0) {
                _this.shiftEnabled = false;
            } else {
                _this.shiftEnabled = true;
            }
        }

        _this.workDays = _this.authCompanyAttendanceConfig.working_days.split(",");
    }
}
</script>

<style>

</style>