<template>
    <div class="card-body row py-4">
        <div class="col-sm-4" style="align-self: center;">
            <h4>Publish Appraisals</h4>
        </div>
        <div class="col-sm-8">
            <v-row>
                <v-col md="3">
                    <v-select
                        v-model="filters.departmentName"
                        :items="availableDepartmentsNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select department"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-select
                        v-model="filters.designationName"
                        :items="availableDesignationNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select designation"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-select
                        v-model="filters.appraisalName"
                        :items="availableAppraisalNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select appraisal"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-text-field
                    v-model="filters.searchText" 
                    placeholder="Search"
                    messages="Search by employee name" 
                    class="search-employees"
                    solo 
                    dense
                    ></v-text-field>
                </v-col>
            </v-row>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Appraisal Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Employee Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Department
                                    </th>
                                    <th class="text-left" scope="col">
                                        Designation
                                    </th>
                                    <th class="text-left" scope="col">
                                        Duration
                                    </th>
                                    <th class="text-left" scope="col">
                                        Final Score
                                    </th>
                                    <th class="text-left" scope="col">
                                        Rating
                                    </th>
                                    <th class="text-center" scope="col">
                                        Publish Appraisal
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(EA, index) in filteredReportingManagerReviewableAppraisals" :key="'employee-appraisal-'+index">
                                    <td class="text-left">{{ EA.appraisal_name }}</td>
                                    <td v-if="singleEmployeeObject(EA.employee_id)" class="text-left">{{ singleEmployeeObject(EA.employee_id).employee_fristname + ' ' + singleEmployeeObject(EA.employee_id).employee_lastname }}</td>
                                    <td v-else class="text-left">
                                        <i>Employee not found</i>
                                    </td>
                                    <td v-if="get_dept_name(EA.department_id)" class="text-left">{{ get_dept_name(EA.department_id) }}</td>
                                    <td v-else class="text-left">
                                        <i>Department not found</i>
                                    </td>
                                    <td v-if="singleEmployeeObject(EA.employee_id).employee_designation" class="text-left">{{ singleEmployeeObject(EA.employee_id).employee_designation }}</td>
                                    <td v-else class="text-left">
                                        <i>Designation not found</i>
                                    </td>
                                    <td v-if="getAppraisalById(EA.appraisal_id)" class="text-left">
                                        {{ 
                                            new Date(getAppraisalById(EA.appraisal_id).start_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                            + ' - ' +
                                            new Date(getAppraisalById(EA.appraisal_id).end_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                        }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No duration specified</i>
                                    </td>
                                    <td class="text-left">
                                        {{ getEmployeeFinalScore(EA.employee_id, EA.appraisal_id) }}
                                    </td>
                                    <td class="text-left">
                                        {{ getEmployeeFinalRating(EA.employee_id, EA.appraisal_id) }}
                                    </td>
                                    <td v-if="getAppraisalByEmployeeId(EA.appraisal_id, EA.employee_id)" class="text-center">
                                        <button v-if="getAppraisalByEmployeeId(EA.appraisal_id, EA.employee_id).status == 1" @click="publishAppraisalLoading ? null : publish_employee_appraisal(EA.id, getEmployeeFinalScore(validEmployeeId, EA.appraisal_id), getEmployeeFinalRating(validEmployeeId, EA.appraisal_id))" type="button" class="btn btn-secondary ripple btn-sm w-75 px-0">
                                            {{ publishAppraisalText }}
                                            <v-icon
                                                :style="!publishAppraisalLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                        <i v-else>
                                            Appraisal Published
                                        </i>
                                    </td>
                                    <td v-else class="text-center">
                                        <i>
                                            Publish Appraisal
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { 
    mapActions, 
    mapGetters 
} from 'vuex';
import { BASE_URL } from '@/main';
export default {
    props: {
        employeeIdsUnderReportingManager: Array,
        getTrueEmployeeAppraisals: Function,
        singleEmployeeObject: Function,
        getAppraisalById: Function,
        getEmployeeFinalScore: Function,
        getEmployeeFinalRating: Function,
        getAppraisalByEmployeeId: Function,
        get_dept_name: Function,
        availableDepartmentsNames: Array,
        availableDesignationNames: Array
    },
    data() {
        return {
            publishAppraisalText: 'Publish',
            publishAppraisalLoading: false,

            filters: {
                departmentName: 'All',
                designationName: 'All',
                appraisalName: 'All',
                searchText: ''
            }
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authEmployeeDataForCompany',
            'authDesignations',
            'authCompanyDepartmentEmployees',
            'validEmployeeId'
        ]),
        reportingManagerReviewableAppraisals() {
            let _this = this, result = [];
            _this.employeeIdsUnderReportingManager.forEach(id => {
                result = [...result, ..._this.getTrueEmployeeAppraisals(id)];
            });
            return result;
        },
        filteredReportingManagerReviewableAppraisals() {
            const _this = this;

            // Search and filter by department name
            let result = _this.reportingManagerReviewableAppraisals.filter(REVIEWABLE_APPRAISAL => {
                if (_this.filters.departmentName === 'All') {
                    // If all department should show
                    return true;
                } else {
                    // If filtering by department name
                    let employee = _this.singleEmployeeObject(REVIEWABLE_APPRAISAL.employee_id);
                    let primaryEmployeeDepartment = _this.get_dept_name(employee.department_id);
                    let secondaryEmployeeDepartments = _this.authCompanyDepartmentEmployees.filter(EMPLOYEE_DEPARTMENT => EMPLOYEE_DEPARTMENT.employee_id == REVIEWABLE_APPRAISAL.employee_id);
                    let secondaryEmployeeDepartmentNames = secondaryEmployeeDepartments.map(EMPLOYEE_DEPARTMENT => _this.get_dept_name(EMPLOYEE_DEPARTMENT.department_id));
                    let allEmployeeDepartments = [
                        primaryEmployeeDepartment,
                        ...secondaryEmployeeDepartmentNames
                    ];
                    return allEmployeeDepartments.indexOf(_this.filters.departmentName) !== -1;
                }
            });
            // Search and filter by department name

            // Search and filter by designation name
            result = result.filter(REVIEWABLE_APPRAISAL => {
                if (_this.filters.designationName === 'All') {
                    // If all designation should show
                    return true;
                } else {
                    // If filtering by designation name
                    let employee = _this.singleEmployeeObject(REVIEWABLE_APPRAISAL.employee_id);
                    let employeeDesignation = employee.employee_designation;
                    return employeeDesignation === _this.filters.designationName;
                }
            });
            // Search and filter by designation name

            // Search and filter by appraisal name
            result = result.filter(REVIEWABLE_APPRAISAL => {
                if (_this.filters.appraisalName === 'All') {
                    // If all appraisals should show
                    return true;
                } else {
                    // If filtering by appraisal name
                    return REVIEWABLE_APPRAISAL.appraisal_name.indexOf(_this.filters.appraisalName) !== -1;
                }
            });
            // Search and filter by appraisal name
            
            // Search and filter by employee name
            result = result.filter(REVIEWABLE_APPRAISAL => {
                let employee = _this.singleEmployeeObject(REVIEWABLE_APPRAISAL.employee_id);
                let employeeName = employee ? `${employee.employee_fristname} ${employee.employee_lastname}` : null;
                return employeeName.toLowerCase().indexOf(_this.filters.searchText.toLowerCase()) !== -1;
            });
            // Search and filter by employee name

            return result;
        },
        availableAppraisalNames() {
            const _this = this;
            let appraisalNames = ['All'];
            _this.reportingManagerReviewableAppraisals.forEach(REVIEWABLE_APPRAISAL => {
                if (appraisalNames.indexOf(REVIEWABLE_APPRAISAL.appraisal_name) === -1) {
                    appraisalNames.splice(appraisalNames.length, 0, REVIEWABLE_APPRAISAL.appraisal_name);
                }
            });
            return appraisalNames;
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_appraisals',
            'fetch_employee_appraisals'
        ]),
        publish_employee_appraisal(employeeappriasal_id, final_rating, total_score) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: `Are you sure you want to publish this employee appraisal?`,
                    showCancelButton: true,
                    confirmButtonText: `Yes, Publish`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        _this.publishAppraisalText = "";
                        _this.publishAppraisalLoading = true;
                        
                        axios
                            .post(`${BASE_URL}publishemployeeappraisalresult`, {
                                company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id, 
                                employee_id: _this.validEmployeeId, 
                                final_rating, 
                                total_score, 
                                employeeappriasal_id
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: `Appraisal published successfully`,
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: `Appraisal couldn't be published`,
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_appraisals();
                                _this.fetch_employee_appraisals();
                                _this.publishAppraisalLoading = false;
                                _this.publishAppraisalText = "Publish";
                            });
                    }
                });
        },
    }
}
</script>

<style>

</style>