<template>
    <div class="tab-pane fade" id="list-messages-reset" role="tabpanel" aria-labelledby="list-messages-list-reset">
        <h4>
            <div class="row">
                <div class="col-lg-6" style="align-self: center;">
                    Leave Policy Reset
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3 offset-9 py-4"></div>
                    </div>
                </div>
            </div>
        </h4>
        <div class="row">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Leave Policy Name
                            </th>
                            <th scope="col" class="text-left">
                                Description
                            </th>
                            <th scope="col" class="text-left">
                                Leave Policy Duration
                            </th>
                            <th scope="col" class="text-left">
                                Unpaid Timeoff
                            </th>
                            <th
                                v-if="
                                    permissionIsAssigned('leave-type-update')
                                "
                                scope="col"
                            >
                                Reset for all employees
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="leave_type in all_leave_type" :key="leave_type.id">
                            <td class="text-left">
                                {{ leave_type.leave_name }}
                            </td>
                            <td class="text-left">
                                {{ leave_type.description }}
                            </td>
                            <td class="text-left">
                                {{ leave_type.leave_duration }} days
                            </td>
                            <td class="text-left">
                                {{ leave_type.unpaid_timeoff }}
                            </td>
                            <td
                                v-if="permissionIsAssigned('leave-type-update')"
                                class="py-1"
                            >
                                <button @click="resetLeave(leave_type)" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Reset
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters } from 'vuex';
export default {
    props: {
        all_leave_type: Array
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData'
        ])
    },
    methods: {
        resetLeave(leave) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to reset this leave policy (" + leave.leave_name + ")?",
                showCancelButton: true,
                confirmButtonText: `Yes, Reset`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${BASE_URL}leavetypereset`, {
                        company_id: _this.authCompanyData.id,
                        leave_type: leave.id,
                    })
                    .then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "The leave policy (" + leave.leave_name + ") reset successfully",
                        });
                    })
                    .catch(function (error) {
                        Toast.fire({
                            icon: "error",
                            title: "The leave policy (" + leave.leave_name + ") couldn't be reset",
                        });
                        console.log(error);
                    })
                    .then(function () {
                        // NO API TO CALL?
                    });
                }
            });
        }
    }
}
</script>

<style>

</style>