<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-2 col-lg-2 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="
                $router.push({
                  name: 'Onboarding',
                  query: { view: 'employee' }
                })
              " style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Employees</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div class="dropdown animate__animated animate__fadeIn">
            <i @click="
              $router.push({
                name: 'Onboarding',
                query: { view: 'employee' }
              })
            " style="cursor: pointer; font-size: 120%; position: relative; top: 2px;" class="i-Close mr-1"></i>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-0' : null"
        style="margin-bottom: 100px;">
        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>
            <span>Edit Employee</span>
          </h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-app>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">First Name <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-text-field v-model="formData.firstname" placeholder="Enter first name" solo class="mt-2">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">Last Name <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-text-field v-model="formData.lastname" placeholder="Enter last name" solo class="mt-2">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">Middle Name <!--<i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i>--></span>
                  <v-text-field v-model="formData.middlename" placeholder="Enter middle name" solo class="mt-2">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">Date of Birth</span>
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="formData.dob"
                        placeholder="Select date of birth" solo class="mt-2" readonly v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker color="#069" ref="picker" v-model="formData.dob" :max="
                      new Date(new Date().getTime() - 567600000000)
                        .toISOString()
                        .substr(0, 10)
                    " @change="save"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">Gender</span>
                  <v-select v-model="formData.sex" item-color="#069" :items="sex" placeholder="Select gender" solo
                    class="mt-2" color="#069"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">Department</span>
                  <v-select v-model="formData.department" item-color="#069" :items="departmentName"
                    placeholder="Assign to department" solo class="mt-2" color="#069"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">Designation</span>
                  <v-select v-model="formData.employee_designation" item-color="#069" :items="trueDesignations"
                    placeholder="Select employee designation" solo class="mt-2" color="#069"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">E-mail Address <i class="fas fa-asterisk"
                      style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                  <v-row>
                    <v-text-field class="mt-2"
                      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'col-12' : 'col-8'"
                      v-model="formData.email" placeholder="Enter e-mail address" solo></v-text-field>
                    <div
                      :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'col-12' : 'col-4'"
                      class="pt-0">
                      <button type="button" @click="resendLoading ? null : resend_email()"
                        class="btn btn-secondary ripple" style="width: 100%; height: 100%; margin-top: 2px;">
                        {{ resendText }}
                        <v-icon :style="
                          !resendLoading
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                          fas fa-circle-notch fa-spin
                        </v-icon>
                      </button>
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">Reporting Manager</span>
                  <v-select class="mt-2" color="#069" item-color="#069" :items="allEmployees" placeholder="Reporting Manager" solo
                    messages="Select reporting manager" v-model="formData.reporting_manager_name"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <span style="color: #111d5e;">Phone Number</span>
                  <vue-tel-input-vuetify style="box-shadow: 0px 0px 50px 0px #00000014 !important;"
                    v-model="formData.phone" filled light shaped flat background-color="white" class="mt-2">
                  </vue-tel-input-vuetify>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="remove-hint">
                  <!-- <span style="color: #111d5e;">Official E-mail</span> -->
                  <!-- <v-text-field v-model="formData.officialEmail" placeholder="Enter official e-mail" solo class="mt-2">
                  </v-text-field> -->
                  <span style="color: #111d5e;">Branch</span>
                  <v-select class="mt-2" v-model="formData.branch" :items="allBranchNames" label="Assign to branch" solo></v-select>
                </v-col>
              </v-row>
            </v-app>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Address Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Address</span>
                <v-text-field v-model="formData.address" placeholder="Enter address" solo class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Country</span>
                <v-select v-model="formData.country" item-color="#069" :items="all_countries"
                  placeholder="Select country" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">State</span>
                <v-select v-model="formData.state" item-color="#069" :items="all_states" :disabled="!formData.country"
                  placeholder="Select state" solo class="mt-2" color="#069" @change="get_cities()"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">City</span>
                <v-select v-model="formData.city" item-color="#069" :items="cities" :disabled="!formData.state"
                  placeholder="Select LGA/city" solo class="mt-2" color="#069"></v-select>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Professional Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Years of Experience</span>
                <v-text-field v-model="formData.experience" placeholder="Enter years of xperience" solo type="number"
                  class="mt-2"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Salary</span>
                <v-text-field
                  v-model="formData.salary"
                  placeholder="Enter current salary"
                  solo
                  class="mt-2"
                  type="number"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Source of Hire</span>
                <v-select v-model="formData.sourceOfHire" item-color="#069" :items="sourceOfHire"
                  placeholder="Enter source of hire" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Highest Qualification</span>
                <v-select v-model="formData.highestQualification" item-color="#069"
                  :items="['Ph.D', 'M.Sc', 'LLB', 'LLM', 'BL', 'B.Sc', 'HND', 'OND', 'O-Level', 'First School Leaving Certificate']"
                  placeholder="Enter highest qualification" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Skill Set</span>
                <v-textarea v-model="formData.skillSet" placeholder="Enter skill set" solo class="mt-2"></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Additional Information</span>
                <v-textarea v-model="formData.additionalInformation" placeholder="Enter additional information" solo
                  class="mt-2"></v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Personal Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Father's Name</span>
                <v-text-field v-model="formData.fathers_name" placeholder="Enter father's name" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Mother's Name</span>
                <v-text-field v-model="formData.mothers_name" placeholder="Enter mother's name" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Marital Status</span>
                <v-select v-model="formData.marital_status" item-color="#069"
                  :items="['Single', 'Married', 'Separated', 'Divorced']" placeholder="Select marital status" solo
                  class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Spouse's Name</span>
                <v-text-field v-model="formData.spouses_name" placeholder="Enter spouse's name" solo class="mt-2">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Blood Group</span>
                <v-select v-model="formData.blood_group" item-color="#069"
                  :items="['A', 'B', 'O', 'AB', 'A+', 'B+', 'AB+', 'O+', 'A-', 'B-', 'AB-', 'O-']"
                  placeholder="Select blood group" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Next of Kin's Name</span>
                <v-text-field v-model="formData.name_of_next_of_kin" placeholder="Enter next of kin's name" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Relationship with next of kin</span>
                <v-select v-model="formData.relationship_nok" item-color="#069" :items="[
                  'Husband',
                  'Wife',
                  'Father',
                  'Mother',
                  'Brother',
                  'Sister',
                  'Son',
                  'Daughter',
                  'Uncle',
                  'Aunty',
                  'Cousin',
                ]" placeholder="Relationship with next of kin" solo class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Next of Kin's Address</span>
                <v-text-field v-model="formData.address_nok" placeholder="Enter next of kin's address" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <span style="color: #111d5e;">Next of Kin's Phone Number</span>
                <v-text-field v-model="formData.mobile_no_nok" placeholder="Enter next of kin's phone number" solo
                  class="mt-2"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Guarantor Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor First Name</span>
                <v-text-field v-model="formData.guarantor_frist_Name" placeholder="Enter guarantor's first name" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Last Name</span>
                <v-text-field v-model="formData.guarantor_last_Name" placeholder="Enter guarantor's last name" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor E-mail Address</span>
                <v-text-field v-model="formData.guarantor_email" placeholder="Enter guarantor's e-mail address" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Address</span>
                <v-text-field v-model="formData.guarantor_address" placeholder="Enter guarantor's address" solo
                  class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Guarantor Phone Number</span>
                <v-text-field v-model="formData.guarantor_phone_number" placeholder="Enter guarantor's phone number"
                  solo class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Candidate-Guarantor Relationship</span>
                <v-text-field v-model="formData.employee_grelationship"
                  placeholder="Enter guarantor's relationship with the candidate" solo class="mt-2"></v-text-field>
              </v-col>

            </v-row>
          </div>
        </v-form>

        <div class="breadcrumb" style="margin-top: 1rem; margin-left: 1rem;">
          <h1>Payment Details</h1>
        </div>
        <v-form @submit.prevent>
          <div class="container-fluid">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Basic Salary</span>
                <v-text-field :value="
              ((currentGradeLevel(employee.id) ?
                currentGradeLevel(employee.id).salary : 0)
                || 'No salary specified')" :disabled="1 > 0" solo class="mt-2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Payment Method</span>
                <v-select v-model="formData.payment_method" item-color="#069"
                  :items="['Bank Transfer', 'Cash', 'Other']" placeholder="No payment method specified" solo
                  class="mt-2" color="#069"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Account Number</span>
                <v-text-field v-model="formData.account_number" placeholder="No account number specified" solo class="mt-2" type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="remove-hint">
                <span style="color: #111d5e;">Bank Name</span>
                <v-select v-model="formData.bank_name" item-color="#069" :items="bankNames" placeholder="No bank specified"
                  solo class="mt-2" color="#069"></v-select>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <div class="main-header pl-5" style="position: fixed; bottom: 0px; z-index: 60;">
        <div class="row">
          <div class="logo col-lg-4 col-md-4 col-12 py-0">
            <div class="dropdown">
              <button @click="editLoading ? null : editEmployee()" type="button" class="btn btn-secondary m-1 ripple">
                {{ editText }}
                <v-icon :style="
                  !editLoading
                    ? 'display: none;'
                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                ">
                  fas fa-circle-notch fa-spin
                </v-icon>
              </button>
              <button @click="
  $router.push({
    name: 'Onboarding',
    query: { view: 'employee' }
  })
              " type="button" class="btn btn-outline-secondary m-1 ripple">
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
export default {
  components: {
    VueTelInputVuetify
  },
  data() {
    return {
      allEmployees: [],
      menu: false,
      departmentName: [],
      editText: "Submit",
      editLoading: false,
      resendText: "Send onboarding mail",
      resendLoading: false,
      formData: {
        department: "",
        email: "",
        phone: "",
        firstname: "",
        middlename: "",
        lastname: "",
        dob: "",
        sex: "",
        officialEmail: "",
        address: "",
        state: "",
        city: "",
        country: "",
        experience: "",
        salary: "",
        sourceOfHire: "",
        skillSet: "",
        highestQualification: "",
        additionalInformation: "",
        fathers_name: "",
        mothers_name: "",
        marital_status: "",
        spouses_name: "",
        blood_group: "",
        guarantor_frist_Name: "",
        guarantor_last_Name: "",
        guarantor_email: "",
        guarantor_address: "",
        guarantor_phone_number: "",
        employee_grelationship: "",
        basic_salary: "",
        account_number: "",
        bank_name: "",
        payment_method: "",
        employee_designation: "",
        name_of_next_of_kin: "",
        relationship_nok: "",
        address_nok: "",
        mobile_no_nok: "",
        reporting_manager_name: "",
        branch: ""
      },
      sourceOfHire: [
        "Direct",
        "Newspaper",
        "Advertisement",
        "Employee Referral",
        "External Referral"
      ],
      work_here: ["Yes", "No"],
      sex: ["Male", "Female"],
      cities: [],
      designations: []
    };
  },
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "authCompanyData",
      "authCompanyDepartments",
      "auth_countries",
      "auth_states",
      "bankNames",
      "authDesignations",
      "authCompanyPayGradeHistories",
      "authCompanyPayGrades",
      "authCompanyGradeLevels",
      "authCompanyBranches",
      "allBanks"
    ]),
    bankCode() {
      let _this = this, bankCode = null, result = [];
      result = _this.allBanks.filter(bank => bank.bank_name == _this.formData.bank_name);
      if (result.length > 0) {
        bankCode = result[0].bank_code.slice(0, 3);
      }
      return bankCode;
    },
    allBranchNames() {
      const _this = this;
      let names = [];
      names = _this.authCompanyBranches.map(branch => branch.branch_name);
      return names;
    },
    selectedBranchId() {
      const _this = this;
      let foundBranch = [], id = null;
      foundBranch = _this.authCompanyBranches.filter(branch => branch.branch_name == _this.formData.branch);
      if (foundBranch.length > 0) {
        id = foundBranch[0].id;
      }
      return id;
    },
    reporting_manager_id: function () {
      var t = this, result = [];
      if (t.mixinBranchEmployees(t.$route.name)) {
        result = t.mixinBranchEmployees(t.$route.name).filter(function (e) {
          return e.employee_fristname + " " + e.employee_lastname == t.formData.reporting_manager_name;
        });
        if (result.length > 0) {
          return result[0].id
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    currentPayGrade() {
      let _this = this, result = null;
      result = _this.authCompanyPayGrades.filter(pg => {
        return _this.employee.paygrade_id == pg.id
      });
      if (result.length) {
        return result[0];
      }
      return result;
    },
    all_countries() {
      let _this = this;
      if (_this.auth_countries) {
        return _this.auth_countries.map(country => {
          return country.countryName
        });
      }
      return null;
    },
    all_states() {
      let _this = this;
      if (_this.formData.country && _this.all_countries) {
        let country_obj = _this.auth_countries.filter(country => {
          return country.countryName == _this.formData.country
        })[0];
        let states = _this.auth_states.filter(state => {
          return state.countryID == country_obj.countryID
        });
        return states.map(state => {
          return state.stateName
        });
      }
      return [];
    },
    trueDesignations() {
      let _this = this, designations = [];
      if (_this.authDesignations) {
        designations = _this.authDesignations.map(d => {
          return d.title;
        })
      }
      return designations;
    },
    department_id() {
      let _this = this;
      if (_this.formData.department) {
        return _this.authCompanyDepartments.filter(department => {
          return _this.formData.department == department.department_name;
        })[0].id;
      } else {
        return "";
      }
    },
    employee() {
      let _this = this;
      return _this.mixinBranchEmployees(_this.$route.name).filter(employee => {
        return employee.id == parseInt(_this.$route.params.id);
      })[0];
    }
  },
  methods: {
    ...mapActions([
      "fetch_company_employees",
      "fetch_company_pay_grade_histories",
      "fetch_company_pay_grades",
      "fetch_company_grade_levels",
      "fetch_company_branches"
    ]),
    currentGradeLevel(employee_id) {
      let _this = this, result = null;
      result = _this.authCompanyGradeLevels.filter(level => {
        return _this.singleEmployeeObject(employee_id).gradelevel_id == level.id
      });
      if (result.length) {
        return result[0];
      }
      return result;
    },
    singleEmployeeObject(id) {
      let _this = this, result = 0;
      if (!id) {
        return result;
      }
      if (_this.mixinBranchEmployees(_this.$route.name).length >= 1) {
        result = _this.mixinBranchEmployees(_this.$route.name).filter(te => te.id == id)[0];
      }
      return result;
    },
    get_dept_name(dept_id) {
      let _this = this, dept;
      if (!dept_id) {
        return null;
      }
      if (_this.authCompanyDepartments) {
        dept = _this.authCompanyDepartments.filter(dept => {
          return dept.id == dept_id;
        });
      }
      if (dept) {
        return dept[0].department_name;
      }
      return null;
    },
    get_cities() {
      let _this = this;
      let state_id = _this.auth_states.filter(state => {
        return state.stateName == _this.formData.state
      })[0].stateID;

      axios
        .post(`${BASE_URL}fetchcities`, {
          state_id
        })
        .then(response => {
          _this.cities = response.data.cities.map(city => {
            return city.cityName
          });
        })
        .catch(() => {
          //
        })
        .then(() => {
          // AFTER AXIOS CALL
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    resend_email: function () {
      var t = this;
      (t.resendText = ""), (t.resendLoading = !0);
      var e = t.$swal.mixin({
        toast: !0,
        position: "top-end",
        showConfirmButton: false,
        timer: 2e3,
        timerProgressBar: !0,
        didOpen: function (e) {
          e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
        },
      });
      axios
        .post(`${BASE_URL}onboardingemail`, { employee_email: t.formData.email })
        .then(function () {
          e.fire({ icon: "success", title: "Onboarding mail has been resent successfully" });
        })
        .catch(function (error) {
          if (window.navigator.onLine) {
            let errors = error.response.data.error || null;
            e.fire({
              icon: "error",
              title: errors ? (typeof errors === 'string' ? errors : null
                || errors.employee_email ? errors.employee_email[0] : null) : null
                || error.response.data.error || error.response.data.message || 'Onboarding mail could not be sent'
            });
          } else {
            e.fire({ icon: "error", title: "Please check your internet connectivity" });
          }
        })
        .then(function () {
          (t.resendLoading = false), (t.resendText = "Send onboarding mail");
        });
    },
    editEmployee() {
      let _this = this;
      _this.editText = "";
      _this.editLoading = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });

      axios
        .post(`${BASE_URL}editemployee`, {
          id: _this.employee.id,
          company_id: _this.authCompanyData.id,
          company_unique_id: _this.authCompanyData.company_unique_id,
          user_id: _this.employee.user_id,
          department_id: _this.department_id,
          employee_fristname: _this.formData.firstname,
          employee_lastname: _this.formData.lastname,
          employee_middle_name: _this.formData.middlename,
          employee_date_of_birth: _this.formData.dob,
          employee_email: _this.formData.email,
          employee_phone1: _this.formData.phone,
          employee_state: _this.formData.state,
          employee_nationality: _this.formData.country,
          employee_address: _this.formData.address,
          experience: _this.formData.experience,
          current_salary: _this.formData.salary,
          source_of_hire: _this.formData.sourceOfHire,
          skill_set: _this.formData.skillSet,
          Highest_qualification: _this.formData.highestQualification,
          paddtional_info: _this.formData.additionalInformation,
          employee_sex: _this.formData.sex,
          employee_education: _this.educationForSubmission,
          employee_workexperience: _this.experienceForSubmission,
          fathers_name: _this.formData.fathers_name,
          mothers_name: _this.formData.mothers_name,
          spouses_name: _this.formData.spouses_name,
          blood_group: _this.formData.blood_group,
          guarantor_frist_Name: _this.formData.guarantor_frist_Name,
          guarantor_last_Name: _this.formData.guarantor_last_Name,
          guarantor_email: _this.formData.guarantor_email,
          guarantor_address: _this.formData.guarantor_address,
          guarantor_phone_number: _this.formData.guarantor_phone_number,
          employee_grelationship: _this.formData.employee_grelationship,
          basic_salary: _this.formData.basic_salary,
          account_number: _this.formData.account_number,
          bank_name: _this.formData.bank_name,
          payment_method: _this.formData.payment_method,
          employee_designation: _this.formData.employee_designation,
          employee_maritalstatus: _this.formData.marital_status,
          name_of_next_of_kin: _this.formData.name_of_next_of_kin,
          relationship_nok: _this.formData.relationship_nok,
          address_nok: _this.formData.address_nok,
          mobile_no_nok: _this.formData.mobile_no_nok,
          reporting_to: _this.reporting_manager_id,
          branch: _this.selectedBranchId,
          bank_code: _this.bankCode
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Employee updated successfully"
          });
          _this.fetch_company_employees();
          _this.$router.push({
            name: 'Onboarding',
            query: { view: 'employee' }
          });
        })
        .catch(function (error) {
          if (window.navigator.onLine) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors ? (typeof errors === 'string' ? errors : null
                || errors.employee_fristname ? errors.employee_fristname[0] : null
                  || errors.employee_lastname ? errors.employee_lastname[0] : null
                    || errors.employee_middle_name ? errors.employee_middle_name[0] : null
                      || errors.employee_email ? errors.employee_email[0] : null
                        || errors.employee_phone1 ? errors.employee_phone1[0] : null) : null
                        || error.response.data.error || error.response.data.message || 'There was an error in updating the employee'
            });
            console.log(error.response);
          } else {
            Toast.fire({
              icon: "error",
              title: "Please check your internet connectivity"
            });
          }
        })
        .then(function () {
          _this.editLoading = false;
          _this.editText = "Submit";
        });
    },
    getBranchNameById(branchId) {
      const _this = this;
      let name = '', foundBranch = [];
      foundBranch = _this.authCompanyBranches.filter(branch => branch.id == branchId);
      if (foundBranch.length > 0) {
        name = foundBranch[0].branch_name
      }
      return name;
    },
  },
  mounted() {
    let _this = this;
    // window.addEventListener("click",() => {
    //    this.$refs.select.blur();
    // });
    _this.mixinBranchEmployees(_this.$route.name).forEach(function (e) {
      if (e.id != _this.$route.params.id) {
        _this.allEmployees.push(e.employee_fristname + " " + e.employee_lastname);
      }
    });
    document.title = "Kylian ERP - Edit Employee";
    this.fetch_company_pay_grade_histories();
    this.fetch_company_pay_grades();
    this.fetch_company_grade_levels();
    this.fetch_company_branches();
    setTimeout(() => {
      if (this.formData.state) {
        let state_id = _this.auth_states.filter(state => {
          return state.stateName == _this.formData.state
        })[0].stateID;

        axios
          .post(`${BASE_URL}fetchcities`, {
            state_id
          })
          .then(response => {
            _this.cities = response.data.cities.map(city => {
              return city.cityName
            });
          })
          .catch(() => {
            //
          })
          .then(() => {
            // AFTER AXIOS CALL
          });
      }
    }, 1000);
    this.authCompanyDepartments.forEach(department => {
      this.departmentName.push(department.department_name);
    });
    this.formData.city = this.employee.city;
    this.formData.email = this.employee.employee_email;
    this.formData.firstname = this.employee.employee_fristname;
    this.formData.phone = this.employee.employee_phone1;
    this.formData.lastname = this.employee.employee_lastname;
    this.formData.middlename = this.employee.employee_middle_name
    this.formData.dob = this.employee.employee_date_of_birth;
    this.formData.sex = this.employee.employee_sex;
    this.formData.department = this.get_dept_name(this.employee.department_id);
    this.formData.officialEmail = this.employee.employee_officialemail;
    this.formData.address = this.employee.employee_address;
    this.formData.state = this.employee.employee_state;
    this.formData.country = this.employee.employee_nationality;
    this.formData.experience = this.employee.experience;
    this.formData.salary = this.employee.current_salary;
    this.formData.sourceOfHire = this.employee.source_of_hire;
    this.formData.highestQualification = this.employee.Highest_qualification;
    this.formData.skillSet = this.employee.skill_set;
    this.formData.additionalInformation = this.employee.paddtional_info;
    this.formData.fathers_name = this.employee.fathers_name;
    this.formData.mothers_name = this.employee.mothers_name;
    this.formData.marital_status = this.employee.employee_maritalstatus;
    this.formData.spouses_name = this.employee.spouses_name;
    this.formData.blood_group = this.employee.blood_group;
    this.formData.guarantor_frist_Name = this.employee.guarantor_frist_Name;
    this.formData.guarantor_last_Name = this.employee.guarantor_last_Name;
    this.formData.guarantor_email = this.employee.guarantor_email;
    this.formData.guarantor_address = this.employee.guarantor_address;
    this.formData.guarantor_phone_number = this.employee.guarantor_phone_number;
    this.formData.employee_grelationship = this.employee.employee_grelationship;
    this.formData.basic_salary = this.employee.basic_salary;
    this.formData.account_number = this.employee.account_number;
    this.formData.bank_name = this.employee.bank_name;
    this.formData.payment_method = this.employee.payment_method;
    this.formData.employee_designation = this.employee.employee_designation;
    this.formData.name_of_next_of_kin = this.employee.name_of_next_of_kin;
    this.formData.relationship_nok = this.employee.relationship_nok;
    this.formData.address_nok = this.employee.address_nok;
    this.formData.mobile_no_nok = this.employee.mobile_no_nok;
    this.formData.branch = this.getBranchNameById(this.employee.branch);
    let reportingManager = this.singleEmployeeObject(this.employee.reporting_to);
    this.formData.reporting_manager_name = reportingManager ? reportingManager.employee_fristname + " " + reportingManager.employee_lastname : "";
  }
};
</script>

<style>
.remove-hint div div div.v-text-field__details {
  display: none !important;
}

.educationField .v-text-field__details {
  display: none !important;
}

.educationField .v-input__slot {
  margin: 0;
}
</style>
