<template>
    <div class="tab-pane fade" id="list-settings13" role="tabpanel" aria-labelledby="list-settings-list13">
        <div v-if="check_all_services('Attendance')">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Holiday Management
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button v-if="create_holiday_form" @click="create_holiday_form = false" style="float: right;" type="button" class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                                <button v-else @click="create_holiday_form = true" style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Add Holiday
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div v-if="create_holiday_form" class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Holiday Title <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="holiday_creator.title" placeholder="Enter the holiday title" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Holiday Description <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="holiday_creator.description" placeholder="Enter the holiday description" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Holiday Start Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                color="#069"
                                                prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="holiday_creator.start"
                                                placeholder="Enter the holiday start date"
                                                solo
                                                class="mt-2"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            color="#069"
                                            ref="picker"
                                            v-model="holiday_creator.start"
                                            :min="
                                                new Date(
                                                    new Date().getTime() - 86400000000
                                                )
                                                .toISOString()
                                                .substr(0, 10)
                                            "
                                            :max="
                                                new Date(
                                                    new Date().getTime() + 86400000000
                                                )
                                                .toISOString()
                                                .substr(0, 10)
                                            "
                                            @change="save"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Holiday End Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                color="#069"
                                                prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="holiday_creator.end"
                                                placeholder="Enter the holiday end date"
                                                solo
                                                class="mt-2"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            color="#069"
                                            ref="picker2"
                                            v-model="holiday_creator.end"
                                            :min="
                                                new Date(
                                                    new Date().getTime() - 86400000000
                                                )
                                                .toISOString()
                                                .substr(0, 10)
                                            "
                                            :max="
                                                new Date(
                                                    new Date().getTime() + 86400000000
                                                )
                                                .toISOString()
                                                .substr(0, 10)
                                            "
                                            @change="save2"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                                    <button
                                        @click="
                                        holiday_create_submit
                                            ? null
                                            : holiday_create()
                                        "
                                        style="width: 99%;"
                                        type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple"
                                    >
                                        {{ holiday_create_text }}
                                        <v-icon
                                            :style="!holiday_create_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
            <div v-else class="main-content mt-0 px-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-left">Title</th>
                                    <th scope="col" class="text-left">
                                        Description
                                    </th>
                                    <th scope="col" class="text-left">
                                        Start Date
                                    </th>
                                    <th scope="col" class="text-left">
                                        End Date
                                    </th>
                                    <th scope="col" class="text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="holiday in authCompanyHolidays" :key="holiday.id">
                                    <td class="text-left">
                                        {{ holiday.title }}
                                    </td>
                                    <td class="text-left">
                                        {{ holiday.description }}
                                    </td>
                                    <td class="text-left">
                                        {{ new Date(holiday.start.substring(0, 10)) .toUTCString() .substring(0, 16) }}
                                    </td>
                                    <td style="width: 20%;" class="text-left">
                                        {{ new Date(holiday.end.substring(0, 10)) .toUTCString() .substring(0, 16) }}
                                    </td>
                                    <td style="padding-top: 13px;" class="employee-options">
                                        <div class="row">
                                            <i @click="launch_edit_holiday(holiday)" class="col-3 py-4 text-center fal fa-edit mr-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                            <i @click="delete_holiday(holiday)" class="col-3 py-4 text-center fal fa-trash ml-2" style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="edit_holiday_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Holiday Details</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" class="remove-hint">
                                        <span style="color: #111d5e;">Holiday Title <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="holiday_updater.title" placeholder="Enter the holiday title" solo class="mt-2" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="remove-hint">
                                        <span style="color: #111d5e;">Holiday Description <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="holiday_updater.description" placeholder="Enter the holiday description" solo class="mt-2" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Holiday Start Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="holiday_updater.start"
                                                    placeholder="Enter the holiday start date"
                                                    solo
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                color="#069"
                                                ref="picker3"
                                                v-model="holiday_updater.start"
                                                :min="
                                                    new Date(new Date().getTime() - 86400000000)
                                                    .toISOString()
                                                    .substr(0, 10)
                                                "
                                                :max="
                                                    new Date(new Date().getTime() + 86400000000)
                                                    .toISOString()
                                                    .substr(0, 10)
                                                "
                                                @change="save3"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <span style="color: #111d5e;">Holiday End Date <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    color="#069"
                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                    v-model="holiday_updater.end"
                                                    placeholder="Enter the holiday end date"
                                                    solo
                                                    class="mt-2"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                color="#069"
                                                ref="picker4"
                                                v-model="holiday_updater.end"
                                                :min="
                                                    new Date(new Date().getTime() - 86400000000)
                                                    .toISOString()
                                                    .substr(0, 10)
                                                "
                                                :max="
                                                    new Date(new Date().getTime() + 86400000000)
                                                    .toISOString()
                                                    .substr(0, 10)
                                                "
                                                @change="save4"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                                        <button
                                            @click="holiday_update_submit ? null : update_holiday()"
                                            style="width: 99%;"
                                            type="button"
                                            class="btn btn-secondary m-1 text-white btn-raised ripple"
                                        >
                                            {{ holiday_update_text }}
                                            <v-icon
                                                :style="!holiday_update_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="edit_holiday_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            create_holiday_form: false,
            holiday_creator: {
                id: "",
                title: "",
                start: "",
                end: "",
                description: "",
            },
            holiday_updater: {
                id: "",
                title: "",
                start: "",
                end: "",
                description: "",
            },
            menu: false,
            menu2: false,
            menu3: false,
            menu4: false,
            holiday_update_text: "Update",
            holiday_update_submit: false,
            holiday_create_text: "Submit",
            holiday_create_submit: false,
            edit_holiday_dialog: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanyHolidays'
        ])
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
        },
        menu2(val) {
            val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
        },
        menu3(val) {
            val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
        },
        menu4(val) {
            val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
        },
    },
    methods: {
        ...mapActions([
            'fetch_all_holidays'
        ]),
        save(date) {
              this.$refs.menu.save(date);
          },
          save2(date) {
              this.$refs.menu2.save(date);
          },
          save3(date) {
              this.$refs.menu3.save(date);
          },
          save4(date) {
              this.$refs.menu4.save(date);
          },
          launch_edit_holiday(holiday) {
              this.holiday_updater.id = holiday.id;
              this.holiday_updater.title = holiday.title;
              this.holiday_updater.start = holiday.start;
              this.holiday_updater.end = holiday.end;
              this.holiday_updater.description = holiday.description;
              this.edit_holiday_dialog = true;
          },
          holiday_create() {
              let _this = this;
              _this.holiday_create_text = "";
              _this.holiday_create_submit = true;
              const Toast = _this.$swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                      toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                  },
              });
              axios
                  .post(`${BASE_URL}createholiday`, {
                      company_id: _this.authCompanyData.id,
                      title: _this.holiday_creator.title,
                      description: _this.holiday_creator.description,
                      start: _this.holiday_creator.start,
                      end: _this.holiday_creator.end,
                  })
                  .then(function () {
                      Toast.fire({
                          icon: "success",
                          title: "Holiday details added successfully",
                      });
                      _this.holiday_creator.title = "";
                      _this.holiday_creator.description = "";
                      _this.holiday_creator.start = "";
                      _this.holiday_creator.end = "";
                      _this.fetch_all_holidays();
                      _this.create_holiday_form = false;
                  })
                  .catch(function (error) {
                      let errors = error.response.data.error || null;
                      Toast.fire({
                          icon: "error",
                          title: errors
                              ? typeof errors === "string"
                                  ? errors
                                  : null || errors.id
                                  ? errors.id[0]
                                  : null || errors.title
                                  ? errors.title[0]
                                  : null || errors.description
                                  ? errors.description[0]
                                  : null || errors.start
                                  ? errors.start[0]
                                  : null || errors.end
                                  ? errors.end[0]
                                  : null
                              : null || error.response.data.error || error.response.data.message || "The holiday details couldn't be updated",
                      });
                  })
                  .then(function () {
                      _this.holiday_create_submit = false;
                      _this.holiday_create_text = "Submit";
                  });
          },
          update_holiday() {
              let _this = this;
              _this.holiday_update_text = "";
              _this.holiday_update_submit = true;
              const Toast = _this.$swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                      toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                  },
              });
              axios
                  .post(`${BASE_URL}editholiday`, {
                      company_id: _this.authCompanyData.id,
                      id: _this.holiday_updater.id,
                      title: _this.holiday_updater.title,
                      description: _this.holiday_updater.description,
                      start: _this.holiday_updater.start,
                      end: _this.holiday_updater.end,
                  })
                  .then(function () {
                      Toast.fire({
                          icon: "success",
                          title: "The holiday details have been updated successfully",
                      });
                      _this.holiday_updater.id = null;
                      _this.holiday_updater.title = "";
                      _this.holiday_updater.description = "";
                      _this.holiday_updater.start = "";
                      _this.holiday_updater.end = "";
                      _this.fetch_all_holidays();
                      _this.edit_holiday_dialog = false;
                  })
                  .catch(function (error) {
                      let errors = error.response.data.error || null;
                      Toast.fire({
                          icon: "error",
                          title: errors
                              ? typeof errors === "string"
                                  ? errors
                                  : null || errors.id
                                  ? errors.id[0]
                                  : null || errors.title
                                  ? errors.title[0]
                                  : null || errors.description
                                  ? errors.description[0]
                                  : null || errors.start
                                  ? errors.start[0]
                                  : null || errors.end
                                  ? errors.end[0]
                                  : null
                              : null || error.response.data.error || error.response.data.message || "The holiday details couldn't be updated",
                      });
                  })
                  .then(function () {
                      _this.holiday_update_submit = false;
                      _this.holiday_update_text = "Update";
                  });
          },
          delete_holiday(holiday) {
              let _this = this;

              const Toast = _this.$swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                      toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                  },
              });

              _this.$swal
                  .fire({
                      title: "Are you sure you want to delete this holiday?",
                      showCancelButton: true,
                      confirmButtonText: `Yes, Delete`,
                  })
                  .then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                          // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                          axios
                              .post(`${BASE_URL}deleteholiday`, {
                                  ids: [
                                      {
                                          id: holiday.id,
                                      },
                                  ],
                              })
                              .then(function () {
                                  Toast.fire({
                                      icon: "success",
                                      title: "Holiday details deleted successfully",
                                  });
                              })
                              .catch(function (error) {
                                  Toast.fire({
                                      icon: "error",
                                      title: "Holiday details couldn't be deleted",
                                  });
                                  console.log(error);
                              })
                              .then(function () {
                                  _this.fetch_all_holidays();
                              });
                      }
                  });
          },

    }
}
</script>

<style>

</style>