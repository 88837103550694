<template>
  <div class="side-content-wrap noShowOnPrint">
    <!--<template v-if="allServices.length >= 1 || userType == 2">-->
    <div class="sidebar-left open rtl-ps-none">
      <!-- <div class="sidebar-left open rtl-ps-none" data-perfect-scrollbar="" data-suppress-scroll-x="true"> -->
      <ul v-if="authCompanyPermissions.length === 0" class="navigation-left"></ul>
      <ul class="navigation-left" v-else-if="allServices.length >= 1 && userType == 1">
        <li v-if="authCompanyServices.length === 2" class="nav-item text-left"
          :class="$route.name == 'AuthRequestDemo' ? 'nav-item-active' : ''" data-item="#"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'AuthRequestDemo' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-paper-plane"></i><span class="nav-text d-inline-block">Request
              Demo</span></span>
          <!-- <div class="triangle"></div> -->
        </li>
        <li class="nav-item text-left" :class="$route.name == 'Services' ? 'nav-item-active' : ''" data-item="#"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'Services' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-folders"></i><span
              class="nav-text d-inline-block">Services</span></span>
          <!-- <div class="triangle"></div> -->
        </li>
        <li class="nav-item text-left" :class="$route.name == 'ClientArea' ? 'nav-item-active' : ''" data-item="#"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'ClientArea' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-home"></i><span
              class="nav-text d-inline-block">Home</span></span>
          <!-- <div class="triangle"></div> -->
        </li>
        <li class="nav-item text-left" :class="$route.name == 'Profile' ? 'nav-item-active' : ''" data-item="#"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'Profile' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-id-card"></i><span
              class="nav-text d-inline-block">Profile</span></span>
          <!-- <div class="triangle"></div> -->
        </li>
        <li class="nav-item text-left" :class="$route.name == 'Onboarding' ? 'nav-item-active' : ''" data-item="uikits"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'Onboarding' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-users"></i><span
              class="nav-text d-inline-block">Onboarding</span></span>
          <!-- <div class="triangle"></div> -->
        </li>
        <!-- <li class="nav-item text-left" :class="$route.name == 'ExitManagement' ? 'nav-item-active' : ''"
          data-item="uikits" @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'ExitManagement' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-door-open"></i><span class="nav-text d-inline-block">{{ userType
        == 1 ? 'Exit Management' : 'Exits' }}</span></span>
        </li> -->
        <template v-for="service in servicesPart1">
          <template v-if="service.service_name.toLowerCase() == 'payroll'">
            <li class="nav-item text-left" :class="$route.name == service.service_name ? 'nav-item-active' : ''"
              data-item="extrakits" @mouseenter="showSection = false" :key="service.service_id">
              <span class="nav-item-hold py-4" @click="$router.push({ name: service.service_name })"><i
                  :class="'nav-icon ml-5 d-inline-block ' + getIcon(service.service_name)"></i><span
                  class="nav-text d-inline-block">Payrun</span></span>
              <!-- <div class="triangle"></div> -->
            </li>
          </template>
          <template v-else-if="service.service_name.toLowerCase() != 'onboarding'">
            <li class="nav-item text-left" :class="$route.name == service.service_name ? 'nav-item-active' : ''"
              data-item="extrakits" @mouseenter="showSection = false" :key="service.service_id">
              <span class="nav-item-hold py-4" @click="$router.push({ name: service.service_name })"><i
                  :class="'nav-icon ml-5 d-inline-block ' + getIcon(service.service_name)"></i><span
                  class="nav-text d-inline-block">{{
        service.service_name
      }}</span></span>
              <!-- <div class="triangle"></div> -->
            </li>
            <!-- <li
                v-if="service.service_name.toLowerCase() == 'loan' && (userType == 1 || !permissionIsAssigned('loan-approve'))"
                class="nav-item text-left" data-item="extrakits" @mouseenter="showSection = false"
                :key="service.service_id + '-loans'">
                <span class="nav-item-hold py-4" @click="$router.push({ name: service.service_name })"><i
                    :class="'nav-icon ml-5 d-inline-block ' + getIcon(service.service_name)"></i><span class="nav-text d-inline-block">Loan
                    (Admin)</span></span>
                <div class="triangle"></div>
              </li>
              <li v-if="service.service_name.toLowerCase() == 'loan' && userType != 1" class="nav-item text-left"
                data-item="extrakits" @mouseenter="showSection = false" :key="service.service_id + '-loans'">
                <span class="nav-item-hold py-4" @click="$router.push({ name: service.service_name })"><i
                    :class="'nav-icon ml-5 d-inline-block ' + getIcon(service.service_name)"></i><span class="nav-text d-inline-block">Loan
                    (Employee)</span></span>
                <div class="triangle"></div>
              </li> -->
          </template>
        </template>
        <li v-if="authCompanyServices.length > 2" :class="$route.name == 'Reports' ? 'nav-item-active' : ''"
          class="nav-item text-left" data-item="forms" @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'Reports' })">
            <i class="nav-icon ml-5 d-inline-block fal fa-chart-pie"></i>
            <span class="nav-text d-inline-block">
              Reports
            </span>
          </span>
        </li>
        <li :class="$route.name == 'Settings' ? 'nav-item-active' : ''" class="nav-item text-left" data-item="forms"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'Settings' })">
            <i class="nav-icon ml-5 d-inline-block fal fa-cog"></i>
            <span class="nav-text d-inline-block">
              Settings
            </span>
          </span>
        </li>
      </ul>
      <ul class="navigation-left" v-else-if="allServices.length >= 1 && userType != 1">
        <li class="nav-item text-left" :class="$route.name == 'ClientArea' ? 'nav-item-active' : ''" data-item="#"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'ClientArea' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-home"></i><span
              class="nav-text d-inline-block">Home</span></span>
          <!-- <div class="triangle"></div> -->
        </li>
        <li class="nav-item text-left" :class="$route.name == 'Profile' ? 'nav-item-active' : ''" data-item="#"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'Profile' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-id-card"></i><span
              class="nav-text d-inline-block">Profile</span></span>
          <!-- <div class="triangle"></div> -->
        </li>
        <li v-if="permissionIsAssigned('candidate-read') || permissionIsAssigned('employee-read')"
          class="nav-item text-left" data-item="uikits" :class="$route.name == 'Onboarding' ? 'nav-item-active' : ''"
          @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'Onboarding' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-users"></i><span
              class="nav-text d-inline-block">Onboarding</span></span>
          <!-- <div class="triangle"></div> -->
        </li>
        <!-- <li class="nav-item text-left" :class="$route.name == 'ExitManagement' ? 'nav-item-active' : ''"
          data-item="uikits" @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'ExitManagement' })"><i
              class="nav-icon ml-5 d-inline-block fal fa-door-open"></i><span class="nav-text d-inline-block">
              {{ userType == 1 ? 'Exit Management' : 'Exits' }} </span></span>
        </li> -->
        <template v-for="service in servicesPart11">
          <template
            v-if="service.service_name.toLowerCase() != 'onboarding' && service.service_name.toLowerCase() == 'payroll'">
            <li class="nav-item text-left" :class="$route.name == service.service_name ? 'nav-item-active' : ''"
              data-item="extrakits" @mouseenter="showSection = false" :key="service.service_id">
              <span class="nav-item-hold py-4"
                @click="service.service_name.toLowerCase() == 'payroll' && !permissionIsAssigned('payroll-read') ? visitModuleNotAdmin('Payslip') : visitModuleNotAdmin(service.service_name)"><i
                  :class="'nav-icon ml-5 d-inline-block ' + getIcon(service.service_name)"></i>
                <span v-if="service.service_name.toLowerCase() == 'payroll' && !permissionIsAssigned('payroll-read')"
                  class="nav-text d-inline-block">Payslip</span>
                <span v-else class="nav-text d-inline-block">Payrun</span>
                <!-- <div class="triangle"></div> -->
              </span>
            </li>
          </template>
          <template
            v-else-if="service.service_name.toLowerCase() != 'onboarding' && (service.service_name.toLowerCase() != 'recruitment' || permissionIsAssigned('recruitment-read') == true)">
            <li class="nav-item text-left" :class="$route.name == service.service_name ? 'nav-item-active' : ''"
              data-item="extrakits" @mouseenter="showSection = false" :key="service.service_id">
              <span class="nav-item-hold py-4"
                @click="service.service_name.toLowerCase() == 'payroll' && !permissionIsAssigned('payroll-read') ? visitModuleNotAdmin('Payslip') : visitModuleNotAdmin(service.service_name)"><i
                  :class="'nav-icon ml-5 d-inline-block ' + getIcon(service.service_name)"></i>
                <span v-if="service.service_name.toLowerCase() == 'payroll' && !permissionIsAssigned('payroll-read')"
                  class="nav-text d-inline-block">Payslip</span>
                <span v-else class="nav-text d-inline-block">{{ service.service_name }}</span>
                <!-- <div class="triangle"></div> -->
              </span>
            </li>
            <!-- <li
                v-if="service.service_name.toLowerCase() == 'loan' && (userType == 1 || !permissionIsAssigned('loan-approve'))"
                class="nav-item text-left" data-item="extrakits" @mouseenter="showSection = false"
                :key="service.service_id + '-loans'">
                <span class="nav-item-hold py-4" @click="$router.push({ name: service.service_name })"><i
                    :class="'nav-icon ml-5 d-inline-block ' + getIcon(service.service_name)"></i><span class="nav-text d-inline-block">Loan
                    (Admin)</span></span>
                <div class="triangle"></div>
              </li>
              <li v-if="service.service_name.toLowerCase() == 'loan' && userType != 1" class="nav-item text-left"
                data-item="extrakits" @mouseenter="showSection = false" :key="service.service_id + '-loans'">
                <span class="nav-item-hold py-4" @click="$router.push({ name: service.service_name })"><i
                    :class="'nav-icon ml-5 d-inline-block ' + getIcon(service.service_name)"></i><span class="nav-text d-inline-block">Loan
                    (Employee)</span></span>
                <div class="triangle"></div>
              </li> -->
          </template>
        </template>
        <li v-if="canSeeSettings" :class="$route.name == 'Settings' ? 'nav-item-active' : ''" class="nav-item text-left"
          data-item="forms" @mouseenter="showSection = false">
          <span class="nav-item-hold py-4" @click="$router.push({ name: 'Settings' })">
            <i class="nav-icon ml-5 d-inline-block fal fa-cog"></i>
            <span class="nav-text d-inline-block">
              Settings
            </span>
          </span>
        </li>
      </ul>
      <ul class="navigation-left" v-else></ul>
    </div>
    <!-- <div class="sidebar-overlay"></div> -->
    <!--</template>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showSection: true
    };
  },
  computed: {
    ...mapGetters([
      "userType",
      "humanResources",
      "accounts",
      "authStatus",
      "authCompanyAllBilling",
      "authCompanyServices",
      "authCompanyPermissions"
    ]),
    canSeeSettings() {
      let truth = false;
      // if (
      //   this.permissionIsAssigned('leave-type-read')
      //   ||
      //   this.permissionIsAssigned('assets-read')
      //   ||
      //   this.permissionIsAssigned('vendor-read')
      //   ||
      //   this.permissionIsAssigned('department-read')
      //   ||
      //   this.permissionIsAssigned('designation-read')
      //   ||
      //   this.permissionIsAssigned('ip-address-read')
      //   ||
      //   this.permissionIsAssigned('attendance-config-read')
      //   ||
      //   this.permissionIsAssigned('paycalendar-read')
      // ) {
      //   truth = true;
      // }
      truth = true;
      return truth;
    },
    allServices() {
      // THIS IS FOR REMOVING ONBOARDING FROM ALL SERVICES
      // let joined = this.humanResources.concat(this.accounts);
      // console.log('joined v');
      // console.log(joined);
      let _this = this;
      if (_this.authCompanyAllBilling) {
        if (_this.authCompanyAllBilling.companyservices.length >= 1) {
          return _this.authCompanyAllBilling.companyservices;
        } else {
          return [];
        }
      } else {
        return "";
      }
    },
    servicesPart1() {
      let all_services_copy = [...this.allServices];
      // if (all_services_copy.length <= 3) {
      //   return all_services_copy;
      // }
      return all_services_copy;
    },
    servicesPart11() {
      let all_services_copy = [...this.allServices];

      // PAYROLL AND PAYSLIP CONFIG
      if (this.check_all_services('Payroll') && this.permissionIsAssigned('payroll-read')) {
        let payrollService = all_services_copy.filter(s => s.service_name.toLowerCase() == 'payroll')[0];
        let payrollIndex = all_services_copy.indexOf(payrollService);
        let newPayrollService = { ...payrollService };
        newPayrollService.id = 'Payslip';
        newPayrollService.service_name = 'Payslip';
        all_services_copy.splice(payrollIndex + 1, 0, newPayrollService);
      }
      // PAYROLL AND PAYSLIP CONFIG

      return all_services_copy;
    }
    // servicesPart2() {
    //   let all_services_copy = [...this.allServices];
    //   if (all_services_copy.length <= 3) {
    //     return null;
    //   }
    //   return all_services_copy.splice(2, all_services_copy.length);
    // },
    // servicesPart22() {
    //   let all_services_copy = [...this.allServices];
    //   if (all_services_copy.length <= 5) {
    //     return null;
    //   }
    //   return all_services_copy.splice(4, all_services_copy.length);
    // }
  },
  methods: {
    // is_subscribed(name) {
    //   for (let index = 0; index < store.getters['authCompanyAllBilling'].companyservices.length; index++) {
    //     if (store.getters['authCompanyAllBilling'].companyservices[index].service_name == name) {
    //       return true;
    //     } else {
    //       return false
    //     }
    //   }
    // },
    visitModuleNotAdmin(service) {
      if (service.toLowerCase() == 'absence or leave') {
        this.$router.push({ name: 'LeaveApply' })
      } else if (service.toLowerCase() == 'attendance') {
        this.$router.push({ name: 'LogReport' })
      } else {
        this.$router.push({ name: service })
      }
    },
    getIcon(name) {
      if (name.toLowerCase() == "onboarding") {
        return "fal fa-users";
      } else if (name.toLowerCase() == "attendance") {
        return "fal fa-clipboard-user";
      } else if (name.toLowerCase() == "timesheets") {
        return "fal fa-file-lines";
      } else if (name.toLowerCase() == "absence or leave") {
        return "fal fa-island-tropical";
      } else if (name.toLowerCase() == "medicals") {
        return "fal fa-notes-medical";
      } else if (name.toLowerCase() == "assets") {
        return "fal fa-computer-speaker";
      } else if (name.toLowerCase() == "file management") {
        return "fal fa-paste";
      } else if (name.toLowerCase() == "payroll") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "payslip") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "travel management") {
        return "fal fa-plane-departure";
      } else if (name.toLowerCase() == "recruitment") {
        return "fal fa-handshake";
      } else if (name.toLowerCase() == "projects") {
        return "fal fa-tasks";
      } else if (name.toLowerCase() == "loan") {
        return "fal fa-coins";
      } else if (name.toLowerCase() == "tasksheets") {
        return "fal fa-file-spreadsheet";
      } else if (name.toLowerCase() == "requisitions") {
        return "fal fa-money-check";
      } else if (name.toLowerCase() == "dockets") {
        return "fal fa-scale-balanced";
      } else if (name.toLowerCase() == "performance") {
        return "fal fa-chart-line-up";
      } else if (name.toLowerCase() == "trainings") {
        return "fal fa-book";
      } else if (name.toLowerCase() == "outsourcing") {
        return "fal fa-buildings";
      } else {
        return "fal fa-question";
      }
    }
  },
  mounted() {
    // let perfect_scroll_js = document.createElement('script')
    // perfect_scroll_js.setAttribute('src', '/assets/js/perfect-scrollbar.min.js')
    // document.body.appendChild(perfect_scroll_js)

    let sidemenu_js = document.createElement('script')
    sidemenu_js.setAttribute('src', '/assets/js/sidebar.large.script.min.js')
    document.head.appendChild(sidemenu_js)

    new window.PerfectScrollbar('.sidebar-left');
  }
};
</script>

<style scoped>
.nav-item {
  text-align: left !important;
  background: #001026 !important;
  color: #FFFFFF !important;
}

.nav-item:hover,
.nav-item-active {
  background: #FFFFFF !important;
  color: #001026 !important;
}

.nav-item-hold {
  color: inherit !important;
}
</style>