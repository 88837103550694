<template>
  <div class="">
    <v-overlay v-if="fetching">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-app v-else class="">
      <v-row style="margin-top: 60px;">
        <v-col cols="12" style="background: url(/img/contact-banner.png); background-repeat: round;">
          <div
            style="padding :60px 140px;"
            :style="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm' ||
              $vuetify.breakpoint.name == 'md'
                ? 'padding :60px 38px;'
                : 'padding :60px 140px;'
            "
          >
            <h2
              class="text-left"
              style="font-family: Poppins !important; font-size: 38px; line-height: 57px; font-weight: 600; color: #FFF !important;"
            >
              Blog
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="fetching === false">
        <v-col
          cols="12"
          style="margin-top: 30px;"
          :style="
            $vuetify.breakpoint.name == 'xs' ||
            $vuetify.breakpoint.name == 'sm' ||
            $vuetify.breakpoint.name == 'md'
              ? ''
              : 'display:flex;justify-content:center; flex-direction:row;'
          "
        >
          <div
            :style="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm' ||
              $vuetify.breakpoint.name == 'md'
                ? 'max-width: 100vw; width: 100vw; display:flex; flex-direction:column; justify-content:center; align-items:flex-start;'
                : 'max-width: 1300px; width: 100vw; display:flex;justify-content:center; flex-direction:row; align-items:flex-start;'
            "
          >
            <v-col
              cols="12"
              lg="8"
              :style="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm' ||
                $vuetify.breakpoint.name == 'md'
                  ? 'display: flex; width:100%; gap: 10px; justify-content: center;  flex-wrap:wrap;  align-items:center;'
                  : ''
              "
            >
              <div class="blogContainer">
                <h2 v-if="searchInput && filteredBlogPosts.length === 0"
                  style="font-style: normal;font-weight: 600;font-size: 38px; color: #0E0B33; margin-bottom: 20px;"
                >
                  No Search Results
                </h2>
                <h2 v-else-if="searchInput"
                  style="font-style: normal;font-weight: 600;font-size: 38px; color: #0E0B33; margin-bottom: 20px;"
                >
                  Search Results
                </h2>
                <h2 v-else
                  style="font-style: normal;font-weight: 600;font-size: 38px; color: #0E0B33; margin-bottom: 20px;"
                >
                  Featured Articles
                </h2>
                <div class="blogsBox">
                  <div
                    class="blogItem"
                    v-for="post in filteredBlogPosts"
                    :key="post.title"
                  >
                    <div class="blogContent">
                      <router-link :to="{ name: 'BlogDetails', params: { slug: post.slug } }">
                        <div
                          :style="`background-image: url('${blog_url + post.featured_img}')`"
                          class="blogImg"
                        ></div>
                        <div class="blogTitle">
                          {{ post.title }}
                        </div>
                      </router-link>
                      <div
                        class="blogBody"
                        style="color: #0e0b33;"
                        v-html="post.body.slice(0, 300) + '...'"
                      >
                      </div>
                      <router-link :to="{ name: 'BlogDetails', params: { slug: post.slug } }">Read More</router-link>
                      <div class="blogSub" style="margin-top: 10px;">
                        {{ post.editor }}
                        • {{ post.created_at | blogDateFilter }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :style="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm' ||
                    $vuetify.breakpoint.name == 'md'
                      ? ''
                      : ' display: flex; justify-content: center; align-items: center; gap:60px; margin-top: 40px;'
                  "
                  class="pagination-box"
                >
                  <div style="cursor: pointer;" @click="paginateCount !== 1 && paginateBlogs(paginateCount-1)">
                    <img
                      :style="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm' ||
                        $vuetify.breakpoint.name == 'md'
                          ? 'width:10px'
                          : ''
                      "
                      src="../assets/graphics/arrow.svg"
                      alt=""
                    />
                  </div>
                  <!-- <div
                    v-for="n in totalPages"
                    :key="n"
                    :style="
                      n === currentPage
                        ? 'background: #0B54C0;  color: #fff;'
                        : 'background: #fff;  color: #0B54C0; '
                    "
                    class="pagination"
                  >
                    {{ n > 9 ? n : "0" + n }}
                  </div> -->
                  <div
                    v-for="(number, index) in paginationNumbers"
                    :key="index"
                    :style="
                      number === paginateCount
                      ? 'background: #0B54C0;  color: #fff;'
                      : 'background: #fff;  color: #0B54C0; '
                    "
                    class="pagination"
                    @click="paginateBlogs(number)"
                  >
                    {{ number }}
                  </div>
                  <div style="cursor: pointer;" @click="paginateCount !== paginationMaxNumber && paginateBlogs(paginateCount+1)">
                    <img
                      :style="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm' ||
                        $vuetify.breakpoint.name == 'md'
                          ? 'width:10px'
                          : ''
                      "
                      src="../assets/graphics/rightArrow.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="4" style="padding: 0;">
              <div class="articlesContainer">
                <div class="popular-articles-title">Popular Articles</div>
                <div class="search-box" style="">
                  <input
                    style="width: 90%; 
                      
                       padding: 6px; padding-right: 20px; font-size: 16px;"
                    placeholder="Search"
                    type="text"
                    class="searchInput"
                    v-model="searchInput"
                  />
                  <div
                    @click="searchBlog()"
                    style="cursor: pointer; text-align-last: center; border-top-right-radius: 20px;border-bottom-right-radius: 20px;  top: 0;background: #0B54C0; width: 60px;padding-left: 20px; padding: 10px;"
                  >
                    <img src="../assets/graphics/search.svg" alt="" />
                  </div>
                </div>

                <div
                  :style="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm' ||
                    $vuetify.breakpoint.name == 'md'
                      ? ''
                      : 'display: flex;justify-content: center;align-items: center;flex-direction: column;'
                  "
                  class="popular-articles"
                >
                  <router-link 
                    v-for="post in topFivePosts"
                    :to="{ name: 'BlogDetails', params: { slug: post.slug } }" 
                    :key="post.title">
                    <div
                      class="articleItem"
                    >
                      <img
                        class="articlesImg"
                        :src="`${blog_url + post.featured_img}`"
                        alt=""
                      />
                      <div class="" style="padding: 10px; margin-top:10px;">
                        <v-row class="articlesTitle">
                          {{ post.title }}
                        </v-row>

                        <v-row class="articlesSub" style="margin-top: 10px;">
                          {{ post.editor }}•{{ post.created_at | blogDateFilter }}
                        </v-row>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </v-col>
          </div>
        </v-col>
        <GuestNewsletter />
        <GuestFooter />
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { BLOG_URL } from "@/main";
import GuestNewsletter from "@/components/GuestNewsletter";
import GuestFooter from "@/components/GuestFooter";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    GuestFooter,
    GuestNewsletter
  },
  methods: {
    ...mapActions([
      'fetch_company_blog_posts'
    ]),
    searchBlog() {
      const _this = this;
      if (_this.searchInput) {
        _this.$router.push({
          name: 'Blog',
          query: {
            s: _this.searchInput
          }
        });
      } 
      return;
    },
    paginateBlogs(paginateNumber) {
      const _this = this; 
      _this.paginateCount = paginateNumber;
      let newBlogPosts = [];
      if (_this.searchInput) {
        newBlogPosts = _this.searchedBlogs.slice((_this.showingCount * paginateNumber) - _this.showingCount, _this.showingCount * paginateNumber);
      } else {
        newBlogPosts = _this.blogPosts.slice((_this.showingCount * paginateNumber) - _this.showingCount, _this.showingCount * paginateNumber);
      }
      _this.filteredBlogPosts = newBlogPosts;
    },
  },
  data() {
    return {
      
      articles: [
        {
          title: "The Future of Work: HR Trends to Watch in Year 2022 ",
          image: "article2",

          author: "Usman Musa",
          date: "May 17, 2023"
        },
        {
          title: "The Future of Work: HR Trends to Watch in Year 2025 ",
          image: "article2",

          author: "Usman Musa",
          date: "May 17, 2023"
        },
        {
          title: "The Future of Work: HR Trends to Watch in Year 2025 ",
          image: "article",

          author: "Usman Musa",
          date: "May 17, 2023"
        }
      ],
      fetching: true,
      showingCount: 10,
      paginateCount: 1,
      filteredBlogPosts: [],
      searchInput: '',

      currentPage: 1,
      totalPages: 3
    };
  },
  computed: {
    ...mapGetters([
      'blogPosts'
    ]),
    searchedBlogs() {
      let _this = this, result = [];
      result = _this.blogPosts.filter(post => post.title.toLowerCase().indexOf(_this.searchInput.toLowerCase()) !== -1);
      return result;
    },
    paginationMaxNumber() {
      return Math.ceil((this.searchInput ? this.searchedBlogs.length : this.blogPosts.length)/this.showingCount)
    },
    paginationNumbers() {
      const _this = this;
      let allNumbers = [];
      for (let num = 1; num <= _this.paginationMaxNumber; num++) {
          allNumbers.splice(allNumbers.length, 0, num);
      }
      return _this.getSubsetAroundValue(allNumbers, _this.paginateCount);
    },
    blog_url() {
      return BLOG_URL;
    },
    topFivePosts() {
      return this.blogPosts.filter((post, index) => index <= 6);
    }
  },
  watch: {
    searchInput() {
      const _this = this;
      _this.paginateBlogs(1);
      if (_this.searchInput) {
        _this.$router.push({name: 'Blog', query: { s: _this.searchInput }});
      } else {
        _this.$router.push({name: 'Blog'});
      }
    }
  },
  mounted() {
    const _this = this;
    _this.searchInput = _this.$route.query.s;
    document.title = "Kylian ERP - Blog";
    _this.fetch_company_blog_posts().then(() => {
      _this.fetching = false;
      _this.paginateBlogs(_this.paginateCount)
    });
  }
};
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
$new-button-color: #2081ff;
$primary-color: #069;
.blogImg {
  width: 350px;
  height: 300px;
  background-size: cover;
  border-radius: 10px;
  background-position-x: 0px;
  background-position-y: 0px;
}
.blogContainer {
  width: 100%;
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.blogsBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.pagination {
  border-radius: 8px;
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}
.pagination-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 500px;
  margin-top: 40px;
}
.search-box {
  width: 100%;
  padding-left: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;

  margin-bottom: 16px;
  border: 2px solid #e8e8e8;
}
.articlesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
  padding: 0 30px;
  margin-top: 50px;
}
.articleItem {
  height: max-content;
  width: 100%;
  gap: 20px;
  max-width: 420px !important;
  margin-bottom: 15px;
  display: flex;
}
.popular-articles {
  display: flex;
  gap: 30px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 95% !important;
}
.blogItem {
  width: 350px;
  height: max-content;
}

.blogContent {
  width: 100%;
  height: max-content;
}
.blogTitle {
  color: #0e0b33;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 19px;
}
.blogSub {
  width: 100%;
  color: #0e0b33;
  font-weight: 500;
  font-size: 16px;
}
.articlesImg {
  width: 146px !important;
  height: 116px !important;
  background-size: cover;
  border-radius: 6px;
  background-position: center;
}
.articlesTitle {
  color: #0e0b33;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.popular-articles-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
  text-align: left;
}
.articlesSub {
  color: #0e0b33;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 814px) {
  .search-box {
    height: 43px;
  }
  .articlesContainer {
    margin-top: 0;
  }
  .blogsBox {
    gap: 30px;
    max-width: 1300px;
  }
  .blogContainer {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .pagination-box {
    margin-bottom: 70px;
  }
  .popular-articles {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
  }
  .articleItem {
    height: max-content;
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .blogsBox {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (min-width: 1216px) {
  .blogsBox {
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 30px;
  }
}
</style>

<style lang="scss"></style>
