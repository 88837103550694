<template>
  <div
    class="tab-pane fade"
    id="list-settings-logs"
    role="tabpanel"
    aria-labelledby="list-settings-list-logs"
  >
    <div class="d-flex justify-content-between align-item-center">
      <div class="col-lg-6" style="align-self: center;">
        <h4>Audit Trail</h4>
      </div>
      <div class="d-flex ">
        <v-text-field
          v-model="auditTrailsSearch"
          label="Search Audit"
          solo
          hide-details="true"
          placeholder="Audit Trails"
          class="search-audit-trails pe-auto"
          style="cursor:pointer;"
        />
      </div>
    </div>
    <div class="main-content mt-0 px-0">
      <div>
        <div class="table-responsive">
          <table class="table mb-2">
            <thead>
              <tr>
                <th scope="col" class="text-left">
                  Employee Name
                </th>
                <th scope="col" class="text-left">Action</th>
                <th scope="col" class="text-left">
                  IP Address
                </th>
                <th scope="col" class="text-left">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="log in filteredLogs /*paginationLogs*/" :key="log.id">
                <td style="vertical-align: middle;" class="text-left">
                  {{ log.emplyee_name }}
                </td>
                <td style="vertical-align: middle;" class="text-left">
                  {{ log.action }}
                </td>
                <td class="text-left">
                  {{ log.user_ip }}
                </td>
                <td class="text-left">
                  {{ new Date(log.created_at).toUTCString().substring(0, 25) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-between align-item-center ">
          <div>
            <button
              @click="decreasePage"
              type="button"
              :class="
                'btn btn-sm btn-secondary m-1 text-white btn-raised ripple'
              "
              :disabled="currentPage + 1 > totalItems / itemsPerPage"
              :style="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? ''
                  : 'margin-right:50px;'
              "
            >
              <i
                class="col-2 py-0 text-center fa-solid fa-arrow-left"
                style="color: #fff !important; color:white; "
              ></i>
            </button>
            <div
              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
              style="background-color: #069"
            >
              {{ currentPage }}
            </div>
            <button
              @click="increasePage"
              type="button"
              :disabled="!currentPage + 1 > filteredLogs.length / itemsPerPage"
              class="
                    btn btn-sm btn-secondary m-1 text-white btn-raised ripple
                  "
              :style="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? ''
                  : 'margin-right:50px;'
              "
            >
              <i
                class="col-2 py-0 text-center fa-solid fa-arrow-right"
                style="font-size: 125%; cursor: pointer; color: #fff !important;"
              ></i>
            </button>
          </div>

          <div>
            <v-select
              v-model="itemsPerPage"
              @change="itemsPerPageChange"
              hide-details="true"
              solo
              :items="[5, 10, 15, 20]"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {},
  data() {
    return {
      currentPage: 1,
      auditTrailsSearch: "",
      itemsPerPage: 5,
      auditLogs: []
    };
  },
  computed: {
    ...mapGetters(["authCompanyData", "authCompanyLogs"]),
    totalItems() {
      return this.authCompanyLogs.length;
    },
    filteredLogs() {
      let filtered = this.authCompanyLogs;

      if (this.auditTrailsSearch.trim()) {
        const searchTerm = this.auditTrailsSearch.trim().toLowerCase();
        filtered = filtered.filter(
          (logs) =>
            logs.emplyee_name.toLowerCase().includes(searchTerm) ||
            logs.action.toLowerCase().includes(searchTerm) ||
            logs.user_ip.toLowerCase().includes(searchTerm) ||
            new Date(logs.created_at)
              .toUTCString()
              .substring(0, 25)
              .toLowerCase()
              .includes(searchTerm)
        );
      }

      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      return filtered.slice(startIndex, endIndex);
    }
  },
  methods: {
    ...mapActions(["fetch_company_logs"]),
    increasePage() {
      const a = this.currentPage + 1 > this.totalItems / this.itemsPerPage;
      if (!a) {
        this.currentPage = this.currentPage + 1;
      } else return;
    },
    decreasePage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    itemsPerPageChange() {
      this.currentPage = 1;
    }
  },
  mounted() {
    const _this = this;
    _this.fetch_company_logs();
  }
};
</script>

<style></style>
