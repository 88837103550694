<template>
  <div>
    <!-- <SideMenu /> -->
    <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('employee-read'))"
      class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('employee-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                  employeeView ? "Your Exit Requests" : "All Exit Requests"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('employee-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Exit Requests" : "Your Exit Requests" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right"
          :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('employee-read'))"
            class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button" class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-3' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-2 row pb-0">
              <span v-if="employee_name.toLowerCase() != 'all exit requests'" class="col-lg-5 col-md-5 col-12">Exit
                Requests for {{
                  employee_name
                }}</span>
              <span v-else class="col-lg-5 col-md-5 col-12">All Exit Requests</span>
              <v-row class="col-md-7 col-lg-7 col-12" style="float: right;">
                <v-col :offset-lg="employee_name.toLowerCase() != 'all exit requests' ? null : '1'"
                  :offset-md="employee_name.toLowerCase() != 'all exit requests' ? null : '1'" cols="6" lg="3" md="3"
                  class="pt-0 text-center">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline" v-model="dateRangeText"
                        placeholder="Select dates" solo dense readonly v-bind="attrs" v-on="on"
                        :messages="date_range.length == 0 ? '*Select date range' : date_range.length == 1 ? '*Please select an end date' : '*Showing results for ' + new Date(date_range[0]).toUTCString().substring(0, 16) + ' to ' + new Date(date_range[1]).toUTCString().substring(0, 16)">
                      </v-text-field>
                    </template>
                    <v-date-picker color="#069" range v-model="date_range" :min="new Date(new Date().getTime() - 3.156e+10)
                      .toISOString()
                      .substring(0, 10)
                      " :max="new Date(new Date().getTime() + 86400000000)
    .toISOString()
    .substring(0, 10)
    "></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" lg="4" md="4" class="pt-0 text-right">
                  <v-select class="remove-mb" :items="allEmployees" label="All Travels" dense solo
                    messages="*Select Employees" :full-width="true" v-model="employee_name"></v-select>
                </v-col>
                <v-col v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('employee-read'))"
                  cols="6" lg="3" xl="3" md="3" class="pt-0"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'">
                  <download-excel v-if="excel_filtered_exit_requests.length >= 1" :data="json_data" :fields="json_fields"
                    :worksheet="employee_name.toLowerCase() != 'all exit requests' ? employee_name : 'All Employees'"
                    :name="'Exit Requests for ' + (employee_name.toLowerCase() != 'all exit requests' ? employee_name : 'All Employees') + '.xls'"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                    Export to Excel
                  </download-excel>
                  <button v-else style="cursor: disabled; opacity: 0.5;"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                    Export to Excel
                  </button>
                </v-col>
                <v-col
                  v-if="(employee_name.toLowerCase() != 'all exit requests' && userType == 1) || (userType != 1 && !employeeView && permissionIsAssigned('employee-read'))"
                  cols="6" lg="1" xl="1" md="1" class="pt-0">
                  <button @click="reset_filter()"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                    class="btn btn-raised ripple btn-raised-secondary m-1 text-white">
                    Reset
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <!-- <div v-if="employee_name.toLowerCase() != 'all exit requests'" class="col-md-12"> -->
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">Reason</th>
                          <th scope="col" class="text-left">Application Date</th>
                          <th scope="col" class="text-left">Status</th>
                          <th scope="col" class="text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="exitRequest in filtered_exit_requests" :key="exitRequest.id">
                          <td class="text-left">
                            {{ get_employee_name(exitRequest.employee_id) }}
                          </td>
                          <td class="text-left" scope="row">{{ exitRequest.reason }}
                          </td>
                          <td class="text-left">
                            {{ new Date(exitRequest.created_at).toUTCString().substring(0, 16) }}
                          </td>
                          <td v-if="exitRequest.status == 0" class="text-left">
                            <span style="width: 60px;" class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="exitRequest.status == 1" class="text-left">
                            <span style="width: 60px;" class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else class="text-left">
                            <span style="width: 60px;" class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td style="padding-top: 25px;" class="employee-options">
                            <div class="row">
                              <!--<i @click="view_comment(exitRequest)" class="col-2 py-0 text-left fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>-->
                              <i v-if="permissionIsAssigned('travel-approve')" @click="approveExitRequest(exitRequest)"
                                class="col-2 py-0 text-left fal fa-check-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('travel-approve')" @click="disapproveExitRequest(exitRequest)"
                                class="col-2 py-0 text-left fal fa-times-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-else class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Employee</th>
                          <th scope="col" class="text-left">Travel Means</th>
                          <th scope="col" class="text-left">Purpose</th>
                          <th scope="col" class="text-left">From</th>
                          <th scope="col" class="text-left">To</th>
                          <th scope="col" class="text-left">Travel Date</th>
                          <th scope="col" class="text-left">Return Date</th>
                          <th scope="col" class="text-left">Currency</th>
                          <th scope="col" class="text-left">Proposed Amount</th>
                          <th scope="col" class="text-left">File Attachment</th>
                          <th scope="col" class="text-center">Status</th>
                          <th scope="col" class="text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="travel in filtered_exit_requests" :key="travel.id">
                          <td class="text-left">
                            {{ get_employee_name(travel.employee_id) }}
                          </td>
                          <td class="text-left" scope="row">{{ travel.travel_means }}
                          </td>

                          <td class="text-left">
                            {{ travel.travel_purpose }}
                          </td>
                          <td class="text-left">
                            {{ travel.travel_from }}
                          </td>
                          <td class="text-left">
                            {{ travel.travel_to }}
                          </td>
                          <td class="text-left">
                            {{ new Date(travel.travel_date).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ new Date(travel.return_date).toUTCString().substring(0, 16) }}
                          </td>
                          <td class="text-left">
                            {{ travel.currency }}
                          </td>
                          <td class="text-left" scope="row">₦{{ travel.propose_amount | number_with_commas }}
                          </td>
                          <td class="text-left" v-if="travel.fileurl">
                            <a :href="assets_url + travel.fileurl" target="_blank" rel="noopener noreferrer">
                              View Attachment
                            </a>
                          </td>
                          <td class="text-left" v-else><i>No file attachment</i></td>
                          <td v-if="travel.status == 0">
                            <span style="width: 60px;" class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="travel.status == 1">
                            <span style="width: 60px;" class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else>
                            <span style="width: 60px;" class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td style="padding-top: 25px;" class="text-left employee-options">
                            <div class="row">
                              <i @click="view_comment(travel)" class="col-2 py-0 text-left fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('travel-approve')" @click="approveExitRequest(travel)"
                                class="col-2 py-0 text-left fal fa-check-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                              <i v-if="permissionIsAssigned('travel-approve')" @click="disapproveExitRequest(travel)"
                                class="col-2 py-0 text-left fal fa-times-circle"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'pr-1' : 'pr-5'">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
          <div v-if="userType != 1 && permissionIsAssigned('employee-read')" class="input-group-prepend">
            <button class="btn dropdown-toggle w-100" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <span
                :style="$vuetify.breakpoint.name == 'xs' ? 'margin-left: 1.5rem;' : $vuetify.breakpoint.name == 'sm' ? 'margin-left: 5rem;' : null">{{
                  employeeView ? "Your Exit Requests" : "All Exit Requests"
                }}</span>
            </button>
            <div v-if="permissionIsAssigned('employee-read')" class="dropdown-menu" style="min-width: 13rem;">
              <a @click="employeeView = !employeeView" class="dropdown-item" style="cursor: pointer;">
                {{ employeeView ? "All Exit Requests" : "Your Exit Requests" }}
              </a>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-lg-5 pr-md-5">

          <!-- Grid menu Dropdown -->
          <div v-if="userType == 1" class="dropdown">
            <button @click="$router.push({ name: 'BillingHistory' })" type="button" class="btn btn-outline-secondary m-1">
              Billing History
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != 1" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0"
        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'">
            <h1 class="my-2 row pb-0">
              <span class="col-lg-5 col-md-5 col-12">Your Exit Requests</span>
              <!-- <span class="col-lg-5 col-md-5 col-12" v-else>Your Travel Records (No data to display)</span> -->
              <v-row class="col-md-7 col-lg-7 col-12">
                <v-col cols="5" lg="4" md="4"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
                  class="pt-0 pr-0">

                </v-col>
                <v-col cols="7" lg="4" md="4"
                  :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
                  class="pt-0 pr-0">

                </v-col>
                <v-col cols="6" lg="4" md="4" class="text-right pt-0">
                  <button @click="initiateExitRequest = true" type="button"
                    class="btn btn-raised ripple btn-raised-secondary m-1 mt-0 text-white">
                    Create Exit Request
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Reason</th>
                          <th scope="col" class="text-left">Application Date</th>
                          <th scope="col" class="text-left">Status</th>
                          <th scope="col" class="text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="exitRequest in filtered_exit_requests" :key="exitRequest.id">
                          <td class="text-left" scope="row">{{ exitRequest.reason }}
                          </td>
                          <td class="text-left">
                            {{ new Date(exitRequest.created_at).toUTCString().substring(0, 16) }}
                          </td>
                          <td v-if="exitRequest.status == 0" class="text-left">
                            <span style="width: 60px;" class="badge badge-warning text-center text-white">PENDING</span>
                          </td>
                          <td v-else-if="exitRequest.status == 1" class="text-left">
                            <span style="width: 60px;" class="badge text-center badge-success">APPROVED</span>
                          </td>
                          <td v-else class="text-left">
                            <span style="width: 60px;" class="badge text-center badge-danger">REJECTED</span>
                          </td>
                          <td style="padding-top: 25px;" class="employee-options">
                            <div class="row">
                              <!--<i @click="view_comment(exitRequest)" class="col-3 py-0 text-center fal fa-message-dots"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>-->
                              <i v-if="exitRequest.status == 1" class="col-3 py-0 text-center fal fa-edit"
                                style="color: #069 !important; font-size: 125%; opacity: 0.3; cursor: default;"></i>
                              <!-- <i v-else @click="makeCurrentExitRequest(exitRequest)" class="col-3 py-0 text-center fal fa-edit"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i> -->
                              <i v-if="exitRequest.status == 1" class="col-3 py-0 text-center fal fa-trash-alt"
                                style="color: #069 !important; font-size: 125%; opacity: 0.3; cursor: default;"></i>
                              <i v-else @click="deleteExitRequest(exitRequest)"
                                class="col-3 py-0 text-center fal fa-trash-alt"
                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <v-dialog v-model="editExitRequest" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Exit Request</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Exit Reason <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="editFormData.reason" placeholder="Enter exit reason" solo class="mt-2"
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="exitRequestUpdating ? null : updateExitRequest()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ exitRequestUpdateText }}
                      <v-icon :style="!exitRequestUpdating
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editExitRequest = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="initiateExitRequest" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Exit Request</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Exit Reason <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-text-field v-model="formData.reason" placeholder="Enter exit reason" solo class="mt-2"
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="exitRequestCreating ? null : createExitRequest()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ exitRequestCreateText }}
                      <v-icon :style="!exitRequestCreating
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="initiateExitRequest = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="flex-grow-1"></div>
    <v-dialog v-model="commentDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ approval ? 'Approve' : 'Disapprove' }} Exit Request with Comment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;">Comment <i class="fas fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                    <v-textarea v-model="comment"
                      :placeholder="(userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('employee-read'))) ? 'Comment' : 'No comment'"
                      solo class="mt-2" :disabled="userType != 1 ? true : false"></v-textarea>
                  </v-col>
                  <v-col v-if="userType == 1 || (userType != 1 && !employeeView && permissionIsAssigned('employee-read'))"
                    cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                    <button @click="commentCreating ? null : exitRequestAction()" style="width: 99%;" type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple">
                      {{ commentText }}
                      <v-icon :style="!commentCreating
                        ? 'display: none;'
                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        ">
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="commentDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="allCommentsDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Comments</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-left">Date Sent</th>
                  <th class="text-left">Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="comment in allComments" :key="comment.id">
                  <td class="text-left">
                    {{ new Date(comment.created_at).toUTCString().substring(0, 25) }}
                  </td>
                  <td class="text-left">
                    {{ comment.comments }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="allCommentsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      employeeView: true,
      date_range: [],
      formData: {
        reason: ""
      },
      editFormData: {
        id: null,
        reason: ""
      },
      exitRequestCreateText: "Submit",
      exitRequestCreating: false,
      editExitRequest: false,
      initiateExitRequest: false,
      exitRequestUpdateText: "Update",
      exitRequestUpdating: false,
      employee_name: "All Exit Requests",
      employee: null,
      day_selected: "",
      view: "date",
      monthSelected: "",
      yearSelected: "",
      checkingDetails: null,
      firstYear: "",
      allEmployees: [],
      allEmployees2: [],
      currentExitRequest: null,
      commentDialog: false,
      approval: false,
      commentText: 'Submit',
      commentCreating: false,
      comment: '',
      allCommentsDialog: false,
      allComments: []
    };
  },
  computed: {
    ...mapState(["userToken"]),
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authEmployeeDataForCompany",
      "authCompanyExitRequests",
      "authEmployeeExitRequests"
    ]),
    dateRangeText() {
      if (this.date_range) {
        return this.date_range.join(' ~ ')
      }
      return null
    },
    json_data: function () {
      return this.excel_filtered_exit_requests;
    },
    json_fields: function () {
      let _this = this;
      if (_this.employee_name.toLowerCase() != 'all exit requests') {
        return {
          "Reason": "reason",
          "Application Date": "created_at",
          "Status": "status"
        }
      } else {
        return {
          "Employee": "employee",
          "Reason": "reason",
          "Application Date": "created_at",
          "Status": "status"
        }
      }

    },
    excel_filtered_exit_requests() {
      let _this = this;
      return _this.filtered_exit_requests.map(t => {
        return {
          employee: _this.get_employee_name(t.employee_id),
          reason: t.reason,
          created_at: new Date(t.created_at).toUTCString().substring(0, 16),
          status: t.status == 0 ? 'Pending' : t.status == 1 ? 'Approved' : 'Rejected'
        }
      })
    },
    filtered_exit_requests() {
      let _this = this;
      let exitRequests = _this.authCompanyExitRequests;
      if (_this.userType == 1 || (_this.userType != 1 && !_this.employeeView && _this.permissionIsAssigned('employee-read'))) {
        if (_this.employee_id) {
          exitRequests = _this.authCompanyExitRequests.filter(travel => {
            return travel.employee_id == _this.employee_id
          });
        }
        if (_this.date_range.length == 2) {
          exitRequests = exitRequests.filter(t => {
            return (new Date(t.created_at).getTime() >= new Date(_this.date_range[0]).getTime()) && (new Date(t.created_at).getTime() <= new Date(_this.date_range[1]).getTime())
          });
        }
        return exitRequests;
      } else {
        exitRequests = _this.authCompanyExitRequests.filter(exitRequest => {
          return exitRequest.employee_id == _this.validEmployeeId
        });
        if (_this.date_range.length == 2) {
          exitRequests = exitRequests.filter(t => {
            return (new Date(t.created_at).getTime() >= new Date(_this.date_range[0]).getTime()) && (new Date(t.created_at).getTime() <= new Date(_this.date_range[1]).getTime())
          });
        }
        return exitRequests;
      }
    },
    assets_url() {
      return ASSETS_URL;
    },
    employee_id: function () {
      var t = this;
      return t.mixinBranchEmployees(t.$route.name) && t.employee_name.toLowerCase() != 'all exit requests'
        ? t.mixinBranchEmployees(t.$route.name).filter(function (e) {
          return e.employee_fristname + " " + e.employee_lastname == t.employee_name;
        })[0].id
        : null;
    },
  },
  methods: {
    ...mapActions([
      "fetch_employee_exit_requests",
      "fetch_company_exit_requests"
    ]),
    singleEmployeeObject(id) {
      let _this = this,
        result = 0;
      if (!id) {
        return result;
      }
      if (_this.mixinBranchEmployees(_this.$route.name).length >= 1) {
        result = _this.mixinBranchEmployees(_this.$route.name).filter((te) => te.id == id)[0];
      }
      return result;
    },
    datesBetweenTravelStartAndEnd(travelDetails) {
      const _this = this;
      const travelStartDate = new Date(travelDetails.travel_date || "1");
      const travelEndDate = new Date(travelDetails.return_date || "1").addHours(1).addMinutes(1);
      const travelDates = _this.getDatesBetweenDates(travelStartDate, travelEndDate);
      let workDates = travelDates.filter((date) => {
        const weekDay = _this.getDayOfWeek(date.getDay());
        return _this.mixinCompanyWorkDays.indexOf(weekDay) !== -1;
      });
      workDates = workDates.map((workDate) => workDate.toLocaleDateString());

      return workDates.map(date => {
        const fixedDateArray = date.split('/');
        const fixedDate = `${fixedDateArray[1]}/${fixedDateArray[0]}/${fixedDateArray[2]}`;
        return fixedDate;
      });
    },
    reset_filter() {
      this.employee_name = 'All Exit Requests';
      this.date_range = '';
    },
    exitRequestAction() {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.commentText = '';
      e.commentCreating = true;
      if (e.approval) {
        axios
          .post(`${BASE_URL}approveexit`, {
            company_id: e.authCompanyData.id,
            employee_id: e.currentExitRequest.employee_id,
            exit_id: e.currentExitRequest.id,
            comment: e.comment
          }, {
            headers: {
              Authorization: `Bearer ${e.userToken}`
            }
          })
          .then(function () {
            a.fire({ icon: "success", title: "Exit request approved successfully" });
            e.comment = '';
            e.commentDialog = false;
          })
          .catch(function () {
            a.fire({ icon: "error", title: "Exit request couldn't be approved" });
          })
          .then(function () {
            e.fetch_company_exit_requests(), e.fetch_employee_exit_requests();
            e.commentCreating = false;
            e.commentText = 'Submit';
          });
      } else {
        axios
          .post(`${BASE_URL}rejectexit`, {
            company_id: e.authCompanyData.id,
            employee_id: e.currentExitRequest.employee_id,
            exit_id: e.currentExitRequest.id,
            comment: e.comment
          }, {
            headers: {
              Authorization: `Bearer ${e.userToken}`
            }
          })
          .then(function () {
            a.fire({ icon: "success", title: "Exit request rejected successfully" });
            e.comment = '';
            e.commentDialog = false;
          })
          .catch(function () {
            a.fire({ icon: "error", title: "Exit request couldn't be rejected" });
          })
          .then(function () {
            e.fetch_company_exit_requests(), e.fetch_employee_exit_requests();
            e.commentCreating = false;
            e.commentText = 'Submit';
          });
      }
    },
    approveExitRequest(t) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to approve this exit request?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Approve",
        })
        .then(function (s) {
          e.approval = true;
          e.currentExitRequest = t;
          s.isConfirmed ? e.commentDialog = true : '';
        });
    },
    disapproveExitRequest(t) {
      var e = this;
      e.$swal
        .fire({
          title: "Are you sure you want to reject this exit request?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Reject",
        })
        .then(function (s) {
          e.approval = false;
          e.currentExitRequest = t;
          s.isConfirmed ? e.commentDialog = true : '';
        });
    },
    makeCurrentExitRequest(exitRequest) {
      this.editFormData.id = exitRequest.id;
      this.editFormData.reason = exitRequest.reason;
      this.editExitRequest = true;
    },
    // view_comment(travel) {
    //   let _this = this;
    //   axios
    //     .post(`${BASE_URL}fetchtravelcomment`, {
    //       company_id: travel.company_id,
    //       employee_id: travel.employee_id,
    //       travel_id: travel.id,
    //     })
    //     .then(function (response) {
    //       _this.allComments = response.data.comments
    //       _this.allCommentsDialog = true;
    //     })
    //     .catch(function () {
    //       //
    //     })
    //     .then(function () {
    //       //
    //     });
    // },
    hasHistory: function () {
      return window.history.length > 2;
    },
    deleteExitRequest(t) {
      var e = this,
        a = e.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (t) {
            t.addEventListener("mouseenter", e.$swal.stopTimer), t.addEventListener("mouseleave", e.$swal.resumeTimer);
          },
        });
      e.$swal
        .fire({
          title: "Are you sure you want to delete this exit request?",
          showCancelButton: !0,
          confirmButtonText: "Yes, Delete",
        })
        .then(function (s) {
          s.isConfirmed &&
            axios
              .post(`${BASE_URL}deleteexit`, {
                ids: [
                  {
                    id: t.id
                  }
                ]
              }, {
                headers: {
                  Authorization: `Bearer ${e.userToken}`
                }
              })
              .then(function () {
                a.fire({ icon: "success", title: "Exit request deleted successfully" });
              })
              .catch(function () {
                a.fire({ icon: "error", title: "Exit request couldn't be deleted" });
              })
              .then(function () {
                e.fetch_company_exit_requests(), e.fetch_employee_exit_requests();
              });
        });
    },
    createExitRequest: function () {
      var t = this,
        e = t.$swal.mixin({
          toast: !0,
          position: "top-end",
          showConfirmButton: !1,
          timer: 2e3,
          timerProgressBar: !0,
          didOpen: function (e) {
            e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
          },
        });
      let form_data = new FormData();
      form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
      form_data.append("employee_id", t.authEmployeeDataForCompany.id);
      form_data.append("reason", t.formData.reason);

      t.exitRequestCreating ||
        ((t.exitRequestCreateText = ""),
          (t.exitRequestCreating = !0),
          axios
            .post(`${BASE_URL}postexitrequest`, form_data, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${t.userToken}`
              }
            })
            .then(function () {
              e.fire({ icon: "success", title: "Exit request sent successfully" }),
                // ABOVE SHOULD FETCH ALL TRAVELS
                (t.formData.reason = "");
              t.fetch_employee_exit_requests();
              t.fetch_company_exit_requests();
              t.initiateExitRequest = false;
            })
            .catch(function () {
              e.fire({ icon: "error", title: "Exit request couldn't be sent" });
            })
            .then(function () {
              (t.exitRequestCreating = !1), (t.exitRequestCreateText = "Submit");
            }));
    },
    // updateExitRequest: function () {
    //   var t = this,
    //     e = t.$swal.mixin({
    //       toast: !0,
    //       position: "top-end",
    //       showConfirmButton: !1,
    //       timer: 2e3,
    //       timerProgressBar: !0,
    //       didOpen: function (e) {
    //         e.addEventListener("mouseenter", t.$swal.stopTimer), e.addEventListener("mouseleave", t.$swal.resumeTimer);
    //       },
    //     });
    //   let form_data = new FormData();
    //   form_data.append("company_id", t.authEmployeeDataForCompany.company_id);
    //   form_data.append("employee_id", t.authEmployeeDataForCompany.id);
    //   form_data.append("travel_id", t.editFormData.id);
    //   form_data.append("travel_means", t.editFormData.travel_means);
    //   form_data.append("travel_purpose", t.editFormData.travel_purpose);
    //   form_data.append("travel_from", t.editFormData.travel_from);
    //   form_data.append("travel_to", t.editFormData.travel_to);
    //   form_data.append("travel_date", t.editFormData.travel_date);
    //   form_data.append("return_date", t.editFormData.return_date);
    //   form_data.append("currency", 'NGN (Nigerian naira)');
    //   form_data.append("propose_amount", t.editFormData.propose_amount);

    //   t.exitRequestUpdating ||
    //     ((t.exitRequestUpdateText = ""),
    //       (t.exitRequestUpdating = !0),
    //       axios
    //         .post(`${BASE_URL}edittravel`, form_data, {
    //           headers: {
    //             "Content-Type": "multipart/form-data"
    //           }
    //         })
    //         .then(function () {
    //           e.fire({ icon: "success", title: "Travel has been updated successfully" }), t.fetch_employee_exit_requests(), t.fetch_company_exit_requests(), (t.editExitRequest = !1);
    //         })
    //         .catch(function () {
    //           e.fire({ icon: "error", title: "Travel couldn't be updated" });
    //         })
    //         .then(function () {
    //           (t.exitRequestUpdating = !1), (t.exitRequestUpdateText = "Update");
    //         }));
    // },
    get_employee_name: function (t) {
      var e = this;
      if (e.mixinBranchEmployees(e.$route.name)) {
        var a = e.mixinBranchEmployees(e.$route.name).filter(function (e) {
          return e.id == t;
        })[0];
        return a ? a.employee_fristname + " " + a.employee_lastname : 'Not found';
      }
      return "";
    }
  },
  mounted() {
    document.title = "Kylian ERP - Exit Management";
    1 != this.userType, (document.title = "Kylian ERP - Exit Management");
    var t = this;
    t.allEmployees.push('All Exit Requests')
    t.userType &&
      t.mixinBranchEmployees(t.$route.name).forEach(function (e) {
        t.allEmployees.push(e.employee_fristname + " " + e.employee_lastname), t.allEmployees2.push(e.employee_fristname + " " + e.employee_lastname);
      });
    this.fetch_company_exit_requests();
    this.fetch_employee_exit_requests();
  },
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
