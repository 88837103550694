<template>
    <v-col style="padding: 50px 0; background: #F8FAFC; z-index: 1;" cols="12">
        <div :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'margin: 0px 20px;' : 'margin: 50px 100px;'"
            style="background: #011534; border-radius: 20px;">
            <svg width="129" height="92" viewBox="0 0 129 92" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;">
                <path opacity="0.2"
                    d="M115.699 -83.577C140.135 -32.9265 114.883 35.9752 57.9032 69.9601C0.923437 103.945 -64.6705 89.2275 -89.1065 38.577C-113.543 -12.0735 -88.2902 -80.9752 -31.3105 -114.96C25.6693 -148.945 91.2632 -134.227 115.699 -83.577Z"
                    stroke="#F1F5F9" stroke-width="7"></path>
            </svg>
            <v-row>
                <v-col style="margin-top: 60px;" cols="12" class="mb-0">
                    <h2 class="text-center"
                        style="font-family: Poppins !important; font-size: 38px; line-height: 57px; font-weight: 700; color: #FFF !important;">
                        Subscribe to our newsletter
                    </h2>
                </v-col>
                <v-col style="margin-bottom: 40px; margin" cols="12">
                    <v-row
                        :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'margin: 0px;' : 'margin: 0px 200px;'"
                        class="home-page-subscriber">
                        <v-col cols="12" md="4" class="py-0">
                            <v-text-field v-model="formData.firstName" style="text-indent: 10px;" label="First name"
                                outlined color="#FFFFFF" bg-color="#FFFFFF"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="py-0">
                            <v-text-field v-model="formData.email" style="text-indent: 10px;" label="Email address" outlined
                                color="#FFFFFF" bg-color="#FFFFFF"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="py-0">
                            <button @click="newsletterSubscriptionSubmitting ? null : subscribeToNewsletter()"
                                style="color: #0B54C0; font-weight: 600; background: #FFF; border-radius: 9px; height: 60px;"
                                class="btn-lg w-100">
                                {{ newsletterSubscriptionText }}
                                <v-icon :style="!newsletterSubscriptionSubmitting
                                    ? 'display: none;'
                                    : 'color: #0b54c0; padding: 0px 10px; font-size: 16px;'
                                    ">
                                    fas fa-circle-notch fa-spin
                                </v-icon>
                            </button>
                        </v-col>
                    </v-row>
                </v-col>
                <svg width="136" height="73" viewBox="0 0 136 73" fill="none" xmlns="http://www.w3.org/2000/svg"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'bottom: 35px; right: -60px;' : 'bottom: 85px; right: 100px;'"
                    style="position: absolute;">
                    <path
                        d="M246.915 114.5C246.915 175.521 192.808 225.5 125.402 225.5C57.9964 225.5 3.88892 175.521 3.88892 114.5C3.88892 53.4795 57.9964 3.5 125.402 3.5C192.808 3.5 246.915 53.4795 246.915 114.5Z"
                        stroke="white" stroke-width="7"></path>
                </svg>
            </v-row>
        </div>
    </v-col>
</template>

<script>
import { BASE_URL } from '@/main';
import axios from 'axios';

export default {
    data() {
        return {
            formData: {
                firstName: '',
                email: ''
            },
            newsletterSubscriptionText: 'Subscribe Now',
            newsletterSubscriptionSubmitting: false
        }
    },
    methods: {
        subscribeToNewsletter() {
            const _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            if (!_this.formData.firstName || !_this.formData.email) {
                Toast.fire({
                    icon: "error",
                    title: "Subscription could not be processed"
                });
                return;
            }

            _this.newsletterSubscriptionText = "";
            _this.newsletterSubscriptionSubmitting = true;

            axios
                .post(`${BASE_URL}subscribetonewsletter`, {
                    first_name: _this.formData.firstName,
                    email: _this.formData.email,
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Subscribed successfully"
                    });
                    _this.formData.firstName = "";
                    _this.formData.email = "";
                })
                .catch(function (error) {
                    let errorMessage = '';
                    if (error.response.data) {
                        errorMessage = error.response.data.message;
                    }
                    Toast.fire({
                        icon: "error",
                        title: errorMessage || "Subscription could not be processed"
                    });
                })
                .then(function () {
                    _this.newsletterSubscriptionSubmitting = false;
                    _this.newsletterSubscriptionText = "Subscribe Now";
                });
        }
    }
}
</script>

<style></style>