<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div :class="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'pr-5' : null"
          class="header-part-right">
          <!-- <div class="dropdown">
          <button class="btn ripple m-1" type="button">
            <span class="ul-btn__icon"
              ><i style="font-weight: bold;" class="i-Repeat-21"></i
            ></span>
          </button>
        </div> -->
          <!-- Grid menu Dropdown -->
          <!-- <div class="dropdown">
            <button
              v-if="userType == 1 && check_all_services('Absence or Leave')"
              type="button"
              class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Absence or Leave' })"
            >
              All Leave
            </button>
          </div> -->
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType == 1 && check_all_services('Attendance')" type="button"
              class="btn btn-outline-secondary m-1" @click="$router.push({ name: 'LogReport' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-1' : null"
        class="main-content">
        <div v-if="humanResources.length" class="breadcrumb" style="margin-top: 1rem;">
          <h1>Human Resources</h1>
        </div>
        <div v-if="humanResources.length" class="row">
          <!-- ICON BG-->
          <div v-for="(service, index) in humanResources" :key="service.service_id" class="col-lg-2 col-md-3 col-sm-6">
            <span style="cursor: pointer;" @click="$router.push({ name: service.service_name })">
              <div class="card card-icon-big mb-4" :style="getStyleByPattern(index)">
                <div class="card-body text-center" style="color: #111d5e;">
                  <i :class="'nav-icon ' + getIcon(service.service_name)" style="font-size: 300%;"></i>
                  <p class="lead text-18 mt-2 mb-0">
                    {{ service.service_name }}
                  </p>
                </div>
              </div>
            </span>
          </div>
          <div v-if="!trial_version && hr_services_to_add.length" class="col-lg-2 col-md-3 col-sm-6">
            <span style="cursor: pointer;" @click="$router.push({ name: 'AddServices' })">
              <div class="card card-icon-big mb-4">
                <div class="card-body text-center" style="color: #111d5e;">
                  <i :class="'nav-icon fal fa-folder-plus'" style="font-size: 300%;"></i>
                  <p class="lead text-18 mt-2 mb-0">
                    Add Services
                  </p>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div v-if="accounts.length" class="breadcrumb" style="margin-top: 1rem;">
          <h1>Accounts</h1>
        </div>
        <div v-if="accounts.length" class="row">
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="card card-icon-big mb-4">
              <div class="card-body text-center">
                <i class="nav-icon i-Calculator-3" style="font-size: 300%;"></i>
                <p class="lead text-18 mt-2 mb-0">Expenses</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="card card-icon-big mb-4">
              <div class="card-body text-center">
                <i class="nav-icon i-File-Clipboard-File--Text" style="font-size: 300%;"></i>
                <p class="lead text-18 mt-2 mb-0">Invoicing</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="card card-icon-big mb-4">
              <div class="card-body text-center">
                <i class="nav-icon i-Billing" style="font-size: 300%;"></i>
                <p class="lead text-18 mt-2 mb-0">Bills</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="card card-icon-big mb-4">
              <div class="card-body text-center">
                <i class="nav-icon i-Folder-Archive" style="font-size: 300%;"></i>
                <p class="lead text-18 mt-2 mb-0">Projects</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="card card-icon-big mb-4">
              <div class="card-body text-center">
                <i class="nav-icon i-Cash-register-2" style="font-size: 300%;"></i>
                <p class="lead text-18 mt-2 mb-0">Inventory</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="card card-icon-big mb-4">
              <div class="card-body text-center">
                <i class="nav-icon i-Tag" style="font-size: 300%;"></i>
                <p class="lead text-18 mt-2 mb-0">Sales Order</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="card card-icon-big mb-4">
              <div class="card-body text-center">
                <i class="nav-icon i-Bag-Coins" style="font-size: 300%;"></i>
                <p class="lead text-18 mt-2 mb-0">Purchase Order</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-6">
            <div class="card card-icon-big mb-4">
              <div class="card-body text-center">
                <i class="nav-icon i-Newspaper" style="font-size: 300%;"></i>
                <p class="lead text-18 mt-2 mb-0">Reporting</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "humanResources",
      "accounts",
      "userType",
      "trial_version",
      "authAllAvailableServices"
    ]),
    hr_services_to_add() {
      let arr = [],
        _this = this,
        temp_all = [..._this.authAllAvailableServices.human_resources_services];

      temp_all.forEach(x => {
        _this.humanResources.forEach(y => {
          if (x.id == y.service_id) {
            arr.push(x);
          }
        });
      });

      let arr2 = _this.authAllAvailableServices.human_resources_services.map(
        service => {
          return service;
        }
      );

      let dummy = [...arr2];

      arr2.forEach(item => {
        arr.forEach(item2 => {
          if (item == item2) {
            dummy[arr2.indexOf(item)] = null;
          }
        });
      });

      // return dummy;
      dummy = dummy.filter(service => {
        return service != null;
      });

      return dummy;
    }
  },
  methods: {
    hasHistory: function () {
      return window.history.length > 2;
    },
    getIcon(name) {
      if (name.toLowerCase() == "onboarding") {
        return "fal fa-users";
      } else if (name.toLowerCase() == "attendance") {
        return "fal fa-clipboard-user";
      } else if (name.toLowerCase() == "timesheets") {
        return "fal fa-file-lines";
      } else if (name.toLowerCase() == "absence or leave") {
        return "fal fa-island-tropical";
      } else if (name.toLowerCase() == "medicals") {
        return "fal fa-notes-medical";
      } else if (name.toLowerCase() == "assets") {
        return "fal fa-computer-speaker";
      } else if (name.toLowerCase() == "file management") {
        return "fal fa-paste";
      } else if (name.toLowerCase() == "payroll") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "payslip") {
        return "fal fa-money-check-edit";
      } else if (name.toLowerCase() == "travel management") {
        return "fal fa-plane-departure";
      } else if (name.toLowerCase() == "recruitment") {
        return "fal fa-handshake";
      } else if (name.toLowerCase() == "projects") {
        return "fal fa-tasks";
      } else if (name.toLowerCase() == "loan") {
        return "fal fa-coins";
      } else if (name.toLowerCase() == "tasksheets") {
        return "fal fa-file-spreadsheet";
      } else if (name.toLowerCase() == "requisitions") {
        return "fal fa-money-check";
      } else if (name.toLowerCase() == "dockets") {
        return "fal fa-scale-balanced";
      } else if (name.toLowerCase() == "performance") {
        return "fal fa-chart-line-up";
      } else if (name.toLowerCase() == "trainings") {
        return "fal fa-book";
      } else if (name.toLowerCase() == "outsourcing") {
        return "fal fa-buildings";
      } else {
        return "fal fa-question";
      }
    }
  },
  mounted() {
    document.title = "Kylian ERP - Services";
  }
};
</script>

<style scoped>
.lead {
  font-size: 130%;
}
</style>
