<template>
    <div 
        v-if="edit_leave_type_form && check_all_services('Absence or Leave')"
        class="tab-pane fade"
        id="list-messages"
        role="tabpanel"
        aria-labelledby="list-messages-list"
    >
        <h4>
            <div class="row">
                <div class="col-lg-6 pt-0" style="align-self: center;">
                    Edit Leave Policy
                </div>
                <div class="col-lg-6 pt-2">
                    <div class="row">
                        <div class="col-lg-3 py-4 offset-lg-9 text-right">
                            <button @click="edit_leave_type_form = false" style="float: right;" type="button" class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </h4>
        <div>
            <v-form @submit.prevent>
                <div class="container-fluid">
                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="remove-hint">
                            <span style="color: #111d5e;">Leave Policy Name <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <v-text-field v-model="leave_type_edit.leave_name" placeholder="Enter the leave policy name" solo class="mt-2" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="remove-hint">
                            <span style="color: #111d5e;">Leave Description <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <v-text-field v-model="leave_type_edit.description" placeholder="Enter the leave description" solo class="mt-2" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="remove-hint">
                            <span style="color: #111d5e;">Unpaid Timeoff <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <v-select v-model="leave_type_edit.unpaid_timeoff" item-color="#069" :items="unpaid_status" placeholder="Enter the unpaid timeoff" solo class="mt-2" dense color="#069"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="remove-hint">
                            <span style="color: #111d5e;">Leave Duration <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <v-text-field v-model="leave_type_edit.leave_duration" placeholder="Enter the leave duration in days" solo class="mt-2" dense type="number"> </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" offset-sm="3" offset-md="3" class="remove-hint">
                            <span style="color: #111d5e; opacity: 0;">Leave Duration <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <button
                                @click="editLeaveTypeSubmit ? null : edit_leave_type()"
                                style="width: 99%;"
                                type="button"
                                class="mt-2 btn btn-secondary m-1 text-white btn-raised ripple"
                            >
                                {{ editLeaveTypeText }}
                                <v-icon
                                    :style="
                                    !editLeaveTypeSubmit
                                        ? 'display: none;'
                                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                    "
                                >
                                    fal fa-circle-notch fa-spin
                                </v-icon>
                            </button>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
    </div>
    <div
        v-else-if="!edit_leave_type_form && check_all_services('Absence or Leave')"
        class="tab-pane fade"
        id="list-messages"
        role="tabpanel"
        aria-labelledby="list-messages-list"
    >
        <h4 v-if="create_leave_type_form">
            <div class="row">
                <div class="col-lg-6 pt-0" style="align-self: center;">
                    {{ create_leave_type_form ? "Add Leave Policy" : "Leave Policies" }}
                </div>
                <div class="col-lg-6 pt-2">
                    <div class="row">
                        <div class="col-lg-3 py-4 offset-lg-9 text-right">
                            <button @click="create_leave_type_form = false" style="float: right;" type="button" class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </h4>
        <h4 v-else>
            <div class="row">
                <div class="col-lg-6" style="align-self: center;">
                    Leave Policies
                </div>
                <div v-if="permissionIsAssigned('leave-type-create')" class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3 offset-9 py-4">
                            <button @click="create_leave_type_form = true" style="float: right;" type="button" class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                Create Leave Policy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </h4>
        <div v-if="create_leave_type_form">
            <v-form @submit.prevent>
                <div class="container-fluid">
                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="remove-hint">
                            <span style="color: #111d5e;">Leave Policy Name <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <v-text-field v-model="leave_type.leave_name" placeholder="Enter leave policy name" solo class="mt-2" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="remove-hint">
                            <span style="color: #111d5e;">Leave Description <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <v-text-field v-model="leave_type.description" placeholder="Enter leave description" solo class="mt-2" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="remove-hint">
                            <span style="color: #111d5e;">Unpaid Timeoff <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <v-select v-model="leave_type.unpaid_timeoff" item-color="#069" :items="unpaid_status" placeholder="Select unpaid timeoff" solo class="mt-2" dense color="#069"> </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="remove-hint">
                            <span style="color: #111d5e;">Leave Duration <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <v-text-field v-model="leave_type.leave_duration" placeholder="Enter leave duration in days" solo class="mt-2" dense type="number"> </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" offset-sm="3" offset-md="3" class="remove-hint">
                            <span style="color: #111d5e; opacity: 0;">Leave Duration <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                            <button
                                @click="createLeaveTypeSubmit ? null : create_leave_type()"
                                style="width: 99%;"
                                type="button"
                                class="mt-2 btn btn-secondary m-1 text-white btn-raised ripple"
                            >
                                {{ createLeaveTypeText }}
                                <v-icon
                                    :style="
                                    !createLeaveTypeSubmit
                                        ? 'display: none;'
                                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                    "
                                >
                                    fal fa-circle-notch fa-spin
                                </v-icon>
                            </button>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <div v-else class="row">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Leave Policy Name
                            </th>
                            <th scope="col" class="text-left">
                                Description
                            </th>
                            <th scope="col" class="text-left">
                                Leave Policy Duration
                            </th>
                            <th scope="col" class="text-left">
                                Unpaid Timeoff
                            </th>
                            <th
                                class="text-left"
                                v-if="permissionIsAssigned('leave-type-update') || permissionIsAssigned('leave-type-delete')"
                                scope="col"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="leave_type in all_leave_type" :key="leave_type.id">
                            <td class="text-left">
                                {{ leave_type.leave_name }}
                            </td>
                            <td class="text-left">
                                {{ leave_type.description }}
                            </td>
                            <td class="text-left">
                                {{ leave_type.leave_duration }} days
                            </td>
                            <td class="text-left">
                                {{ leave_type.unpaid_timeoff }}
                            </td>
                            <td style="padding-top: 13px;" class="employee-options">
                                <div class="row">
                                    <i
                                        v-if="permissionIsAssigned('leave-type-update')"
                                        @click="edit_change_leave_type(leave_type)"
                                        class="col-3 py-4 text-center fal fa-edit mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                    <i
                                        v-if="permissionIsAssigned('leave-type-delete')"
                                        @click="delete_leave_type(leave_type)"
                                        class="col-3 py-4 text-center fal fa-trash ml-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters } from 'vuex';
export default {
    props: {
        fetch_all_leave_type: Function,
        get_dept_name: Function,
        all_leave_type: Array
    },
    data() {
        return {
            createLeaveTypeText: "Submit",
            createLeaveTypeSubmit: false,
            create_leave_type_form: false,
            unpaid_status: ["Yes", "No"],
            edit_leave_type_form: false,
            leave_type: {
                leave_name: "",
                description: "",
                leave_duration: "",
                unpaid_timeoff: "",
                department: "",
            },
            leave_type_edit: {
                leave_type_id: "",
                leave_name: "",
                description: "",
                leave_duration: "",
                unpaid_timeoff: "",
                department: "",
            },
            editLeaveTypeText: "Update",
            editLeaveTypeSubmit: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanyDepartments'
        ]),
        department_id() {
            let _this = this;
            if (_this.leave_type.department) {
                return _this.authCompanyDepartments.filter((department) => {
                    return _this.leave_type.department == department.department_name;
                })[0].id;
            } else {
                return "";
            }
        },
        edit_department_id() {
            let _this = this;
            if (_this.leave_type_edit.department) {
                return _this.authCompanyDepartments.filter((department) => {
                    return _this.leave_type_edit.department == department.department_name;
                })[0].id;
            } else {
                return "";
            }
        },
    },
    methods: {
        delete_leave_type(type) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this leave policy [" + type.leave_name + "]?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${BASE_URL}deleteleavetype`, {
                        ids: [
                            {
                                id: type.id,
                            },
                        ],
                    })
                    .then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "Leave policy [" + type.leave_name + "] deleted successfully",
                        });
                    })
                    .catch(function (error) {
                        Toast.fire({
                            icon: "error",
                            title: "Leave policy [" + type.leave_name + "] couldn't be deleted",
                        });
                        console.log(error);
                    })
                    .then(function () {
                        _this.fetch_all_leave_type();
                    });
                }
            });
        },
        edit_change_leave_type(leave_type) {
            let _this = this;
            _this.leave_type_edit.leave_type_id = leave_type.id;
            _this.leave_type_edit.leave_name = leave_type.leave_name;
            _this.leave_type_edit.description = leave_type.description;
            _this.leave_type_edit.leave_duration = leave_type.leave_duration;
            _this.leave_type_edit.unpaid_timeoff = leave_type.unpaid_timeoff;
            _this.leave_type_edit.department = _this.get_dept_name(leave_type.department_id);
            _this.edit_leave_type_form = true;
        },
        create_leave_type() {
            let _this = this;
            _this.createLeaveTypeText = "";
            _this.createLeaveTypeSubmit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}createleavetype`, {
                company_id: _this.authCompanyData.id,
                department_id: _this.department_id,
                leave_name: _this.leave_type.leave_name,
                description: _this.leave_type.description,
                unpaid_timeoff: _this.leave_type.unpaid_timeoff,
                leave_duration: _this.leave_type.leave_duration,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "Leave policy created successfully",
                });
                _this.leave_type.department = "";
                _this.leave_type.leave_name = "";
                _this.leave_type.description = "";
                _this.leave_type.unpaid_timeoff = "";
                _this.leave_type.leave_duration = "";
                _this.fetch_all_leave_type();
                _this.create_leave_type_form = false;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.leave_name
                            ? errors.leave_name[0]
                            : null || errors.unpaid_timeoff
                            ? errors.unpaid_timeoff[0]
                            : null || errors.leave_duration
                            ? errors.leave_duration[0]
                            : null || errors.description
                            ? errors.description[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The leave policy couldn't be created",
                });
            })
            .then(function () {
                _this.createLeaveTypeSubmit = false;
                _this.createLeaveTypeText = "Submit";
            });
        },
        edit_leave_type() {
            let _this = this;
            _this.editLeaveTypeText = "";
            _this.editLeaveTypeSubmit = true;
            let INIT_PROPS = _this.leave_type_edit.leave_name;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}editleavetype`, {
                company_id: _this.authCompanyData.id,
                department_id: _this.edit_department_id,
                id: _this.leave_type_edit.leave_type_id,
                leave_name: _this.leave_type_edit.leave_name,
                description: _this.leave_type_edit.description,
                unpaid_timeoff: _this.leave_type_edit.unpaid_timeoff,
                leave_duration: _this.leave_type_edit.leave_duration,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "Leave policy [" + INIT_PROPS + "] updated successfully",
                });
                _this.leave_type_edit.department = "";
                _this.leave_type_edit.leave_name = "";
                _this.leave_type_edit.description = "";
                _this.leave_type_edit.unpaid_timeoff = "";
                _this.leave_type_edit.leave_duration = "";
                _this.fetch_all_leave_type();
                _this.edit_leave_type_form = false;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.department_id
                            ? errors.department_id[0]
                            : null || errors.leave_name
                            ? errors.leave_name[0]
                            : null || errors.unpaid_timeoff
                            ? errors.unpaid_timeoff[0]
                            : null || errors.leave_duration
                            ? errors.leave_duration[0]
                            : null || errors.description
                            ? errors.description[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "Leave policy [" + INIT_PROPS + "] couldn't be updated",
                });
            })
            .then(function () {
                _this.editLeaveTypeSubmit = false;
                _this.editLeaveTypeText = "Update";
            });
        }
    }
}
</script>

<style>

</style>
