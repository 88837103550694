<template>
    <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">
        <div v-if="create_dept_form">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        {{ create_dept_form ? "Create Department" : "All Departments" }}
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div v-if="create_dept_form" class="col-lg-3 offset-9 py-4 text-right">
                                <button @click="create_dept_form = false" style="float: right;" type="button" class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Department Name <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="dept_form.name" placeholder="Enter the department name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Department Description <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="dept_form.description" placeholder="Enter the department's description" solo class="mt-2" dense> </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" offset-md="3" class="remove-hint">
                                    <button
                                        @click="create_dept_submit ? null : create_department()"
                                        style="width: 99%;"
                                        type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple"
                                    >
                                        {{ create_dept_text }}
                                        <v-icon
                                            :style="!create_dept_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Departments Management
                    </div>
                    <div class="col-lg-6" v-if="permissionIsAssigned('department-create')">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button
                                    v-if="permissionIsAssigned('department-create')"
                                    @click="create_dept_form = true"
                                    style="float: right;"
                                    type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                >
                                    Create Department
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Department Name
                            </th>
                            <th scope="col" class="text-left">
                                Department Description
                            </th>
                            <th
                                v-if="permissionIsAssigned('department-update') || permissionIsAssigned('department-delete')"
                                scope="col"
                                style="width: 10px;"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dept in authCompanyDepartments" :key="dept.id">
                            <td class="text-left">
                                {{ dept.department_name }}
                            </td>
                            <td class="text-left">{{ dept.description }}</td>
                            <td
                                v-if="permissionIsAssigned('department-update') || permissionIsAssigned('department-delete')"
                                style="padding-top: 13px;"
                                class="employee-options"
                            >
                                <div class="row">
                                    <i
                                        v-if="permissionIsAssigned('department-update')"
                                        @click="launch_edit_dept(dept)"
                                        class="col-3 py-4 text-center fal fa-edit mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                    <i
                                        v-if="permissionIsAssigned('department-delete')"
                                        @click="delete_dept(dept)"
                                        class="col-3 py-4 text-center fal fa-trash ml-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <v-dialog v-model="dept_edit_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ "Edit Department" }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <v-text-field dense v-model="edit_dept_form.name" placeholder="Department Name" solo class="mt-2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <v-text-field dense v-model="edit_dept_form.description" placeholder="Department Description" solo class="mt-2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button @click="edit_dept_submit ? null : update_dept()" style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ edit_dept_text }}
                                            <v-icon
                                                :style="!edit_dept_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dept_edit_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters } from 'vuex';
export default {
    props: {
        fetch_company_departments: Function
    },
    data() {
        return {
            create_dept_form: false,
            dept_form: {
                name: "",
                description: "",
            },
            edit_dept_form: {
                id: null,
                name: "",
                description: "",
            },
            edit_dept_text: "Update",
            edit_dept_submit: false,
            create_dept_text: "Submit",
            create_dept_submit: false,
            dept_edit_dialog: false
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanyDepartments'
        ])
    },
    methods: {
        launch_edit_dept(dept) {
            this.edit_dept_form.id = dept.id;
            this.edit_dept_form.name = dept.department_name;
            this.edit_dept_form.description = dept.description;
            this.dept_edit_dialog = true;
        },
        delete_dept(dept) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this department [" + dept.department_name + "]?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${BASE_URL}deletedepartment`, {
                        ids: [
                            {
                                id: dept.id,
                            },
                        ],
                    })
                    .then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "Department [" + dept.department_name + "] deleted successfully",
                        });
                    })
                    .catch(function (error) {
                        Toast.fire({
                            icon: "error",
                            title: "Department [" + dept.department_name + "] couldn't be deleted",
                        });
                        console.log(error);
                    })
                    .then(function () {
                        _this.fetch_company_departments();
                    });
                }
            });
        },
        update_dept() {
            const _this = this;
            _this.edit_dept_text = "";
            _this.edit_dept_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}editdepartment`, {
                company_id: _this.authCompanyData.id,
                id: _this.edit_dept_form.id,
                department_name: _this.edit_dept_form.name,
                description: _this.edit_dept_form.description,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "The department has been updated successfully",
                });
                _this.edit_dept_form.id = null;
                _this.edit_dept_form.name = "";
                _this.edit_dept_form.description = "";
                _this.fetch_company_departments();
                _this.dept_edit_dialog = false;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.id
                            ? errors.id[0]
                            : null || errors.department_name
                            ? errors.department_name[0]
                            : null || errors.description
                            ? errors.description[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The department couldn't be updated",
                });
            })
            .then(function () {
                _this.edit_dept_submit = false;
                _this.edit_dept_text = "Update";
            });
        },
        create_department() {
            const _this = this;
            _this.create_dept_text = "";
            _this.create_dept_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios.post(`${BASE_URL}createdepartment`, {
                company_id: _this.authCompanyData.id,
                department_name: _this.dept_form.name,
                description: _this.dept_form.description,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: _this.dept_form.name + " was added successfully",
                });
                _this.dept_form.name = "";
                _this.dept_form.description = "";
                _this.fetch_company_departments();
                _this.create_dept_form = false;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.department_name
                            ? errors.department_name[0]
                            : null || errors.description
                            ? errors.description[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The department could not be added",
                });
                _this.fetch_company_departments();
            })
            .then(function () {
                _this.create_dept_submit = false;
                _this.create_dept_text = "Submit";
            });
        },

    }
}
</script>

<style>

</style>