var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"container pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"pt-0 text-left",staticStyle:{"font-weight":"400","font-size":"24px"}},[_vm._v(" Work Information "),(_vm.editing)?_c('span',[_vm._v("(editing...)")]):_vm._e()])])],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"value":_vm.currentEmployee.employee_fristname,"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_fristname + '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.firstname),callback:function ($$v) {_vm.$set(_vm.formData, "firstname", $$v)},expression:"formData.firstname"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_middle_name ? '' : 'Add middle name',"value":_vm.currentEmployee.employee_middle_name
            ? _vm.currentEmployee.employee_middle_name
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_middle_name ? '' : 'Enter middle name',"label":_vm.currentEmployee.employee_middle_name
            ? _vm.currentEmployee.employee_middle_name
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.middlename),callback:function ($$v) {_vm.$set(_vm.formData, "middlename", $$v)},expression:"formData.middlename"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"value":_vm.currentEmployee.employee_lastname,"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_lastname + '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.lastname),callback:function ($$v) {_vm.$set(_vm.formData, "lastname", $$v)},expression:"formData.lastname"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"value":_vm.currentEmployee.employee_email,"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_email + '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_phone1 ? '' : 'Add Phone 1',"value":_vm.currentEmployee.employee_phone1
            ? _vm.currentEmployee.employee_phone1
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_phone1 ? '' : 'Enter phone 1',"label":_vm.currentEmployee.employee_phone1
            ? _vm.currentEmployee.employee_phone1
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.phone1),callback:function ($$v) {_vm.$set(_vm.formData, "phone1", $$v)},expression:"formData.phone1"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_phone2 ? '' : 'Add Phone 2',"value":_vm.currentEmployee.employee_phone2
            ? _vm.currentEmployee.employee_phone2
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_phone2 ? '' : 'Enter phone 2',"label":_vm.currentEmployee.employee_phone2
            ? _vm.currentEmployee.employee_phone2
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.phone2),callback:function ($$v) {_vm.$set(_vm.formData, "phone2", $$v)},expression:"formData.phone2"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_department ? '' : 'Add department',"value":_vm.currentEmployee.employee_department
            ? _vm.currentEmployee.employee_department
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-select',{attrs:{"item-color":"#069","items":_vm.departmentName,"label":_vm.currentEmployee.employee_department
            ? _vm.currentEmployee.employee_department
            : 'Select department',"solo":"","color":"#069","hint":"*Select department"},model:{value:(_vm.formData.department),callback:function ($$v) {_vm.$set(_vm.formData, "department", $$v)},expression:"formData.department"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_manager ? '' : 'Add manager',"value":_vm.currentEmployee.employee_manager
            ? _vm.currentEmployee.employee_manager
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_manager ? '' : 'Enter manager name',"label":_vm.currentEmployee.employee_manager
            ? _vm.currentEmployee.employee_manager
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.manager),callback:function ($$v) {_vm.$set(_vm.formData, "manager", $$v)},expression:"formData.manager"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"value":_vm.currentEmployee.employee_position,"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_position + '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.position),callback:function ($$v) {_vm.$set(_vm.formData, "position", $$v)},expression:"formData.position"}})],1)],1)],1),_c('v-row',{staticClass:"container pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"pt-0 text-left",staticStyle:{"font-weight":"400","font-size":"24px"}},[_vm._v(" Personal Information ")])])],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_nationality ? '' : 'Add nationality',"value":_vm.currentEmployee.employee_nationality
            ? _vm.currentEmployee.employee_nationality
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_nationality ? '' : 'Enter nationality',"label":_vm.currentEmployee.employee_nationality
            ? _vm.currentEmployee.employee_nationality
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.nationality),callback:function ($$v) {_vm.$set(_vm.formData, "nationality", $$v)},expression:"formData.nationality"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_state ? '' : 'Add state',"value":_vm.currentEmployee.employee_state
            ? _vm.currentEmployee.employee_state
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_state ? '' : 'Enter state',"label":_vm.currentEmployee.employee_state
            ? _vm.currentEmployee.employee_state
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.state),callback:function ($$v) {_vm.$set(_vm.formData, "state", $$v)},expression:"formData.state"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_local_government
            ? ''
            : 'Add local government',"value":_vm.currentEmployee.employee_local_government
            ? _vm.currentEmployee.employee_local_government
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_local_government
            ? ''
            : 'Enter local government',"label":_vm.currentEmployee.employee_local_government
            ? _vm.currentEmployee.employee_local_government
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.local_government),callback:function ($$v) {_vm.$set(_vm.formData, "local_government", $$v)},expression:"formData.local_government"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"value":_vm.currentEmployee.employee_date_of_birth,"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_date_of_birth + '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.dob),callback:function ($$v) {_vm.$set(_vm.formData, "dob", $$v)},expression:"formData.dob"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_place_of_birth
            ? ''
            : 'Add place of birth',"value":_vm.currentEmployee.employee_place_of_birth
            ? _vm.currentEmployee.employee_place_of_birth
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_place_of_birth
            ? ''
            : 'Enter place of birth',"label":_vm.currentEmployee.employee_place_of_birth
            ? _vm.currentEmployee.employee_place_of_birth
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.place_of_birth),callback:function ($$v) {_vm.$set(_vm.formData, "place_of_birth", $$v)},expression:"formData.place_of_birth"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"value":_vm.currentEmployee.employee_sex,"solo":"","disabled":!_vm.editing}}):_c('v-select',{attrs:{"item-color":"#069","items":_vm.sex,"label":_vm.currentEmployee.employee_sex,"solo":"","color":"#069","hint":"*Select sex"},model:{value:(_vm.formData.sex),callback:function ($$v) {_vm.$set(_vm.formData, "sex", $$v)},expression:"formData.sex"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Means of Identification","solo":""}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_identity_card_number
            ? ''
            : 'Add identity card number',"value":_vm.currentEmployee.employee_identity_card_number
            ? _vm.currentEmployee.employee_identity_card_number
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.employee_identity_card_number
            ? ''
            : 'Enter identity card number',"label":_vm.currentEmployee.employee_identity_card_number
            ? _vm.currentEmployee.employee_identity_card_number
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.identity_card_number),callback:function ($$v) {_vm.$set(_vm.formData, "identity_card_number", $$v)},expression:"formData.identity_card_number"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}}),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_marital_status
            ? ''
            : 'Add marital status',"value":_vm.currentEmployee.employee_marital_status
            ? _vm.currentEmployee.employee_marital_status
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-select',{attrs:{"item-color":"#069","items":_vm.marital_status,"label":_vm.currentEmployee.employee_marital_status
            ? _vm.currentEmployee.employee_marital_status
            : 'Select marital status',"solo":"","color":"#069","hint":"*Select marital status"},model:{value:(_vm.formData.marital_status),callback:function ($$v) {_vm.$set(_vm.formData, "marital_status", $$v)},expression:"formData.marital_status"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.employee_number_of_children
            ? ''
            : 'Add number of children',"value":_vm.currentEmployee.employee_number_of_children
            ? _vm.currentEmployee.employee_number_of_children
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-select',{attrs:{"item-color":"#069","items":_vm.number_of_children,"label":_vm.currentEmployee.employee_number_of_children
            ? _vm.currentEmployee.employee_number_of_children
            : 'Select number of children',"solo":"","color":"#069","hint":"*Select number of children"},model:{value:(_vm.formData.number_of_children),callback:function ($$v) {_vm.$set(_vm.formData, "number_of_children", $$v)},expression:"formData.number_of_children"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12","sm":"12"}},[(!_vm.editing)?_c('v-textarea',{staticClass:"textarea-for-package-type",staticStyle:{"color":"#111D5E"},attrs:{"solo":"","name":"input-7-4","value":_vm.currentEmployee.employee_address,"disabled":!_vm.editing}}):_c('v-textarea',{staticClass:"textarea-for-package-type",staticStyle:{"color":"#111D5E"},attrs:{"solo":"","name":"input-7-4","label":_vm.currentEmployee.employee_address + '',"disabled":!_vm.editing},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})],1)],1)],1),_c('v-row',{staticClass:"container pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"pt-0 text-left",staticStyle:{"font-weight":"400","font-size":"24px"}},[_vm._v(" Guarantor ")])])],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.guarantor_frist_Name
            ? ''
            : 'Add guarantor first name',"value":_vm.currentEmployee.guarantor_frist_Name
            ? _vm.currentEmployee.guarantor_frist_Name
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.guarantor_frist_Name
            ? ''
            : 'Enter guarantor first name',"label":_vm.currentEmployee.guarantor_frist_Name
            ? _vm.currentEmployee.guarantor_frist_Name
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.guarantor_firstname),callback:function ($$v) {_vm.$set(_vm.formData, "guarantor_firstname", $$v)},expression:"formData.guarantor_firstname"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.guarantor_last_Name
            ? ''
            : 'Add guarantor last name',"value":_vm.currentEmployee.guarantor_last_Name
            ? _vm.currentEmployee.guarantor_last_Name
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.guarantor_last_Name
            ? ''
            : 'Enter guarantor last name',"label":_vm.currentEmployee.guarantor_last_Name
            ? _vm.currentEmployee.guarantor_last_Name
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.guarantor_lastname),callback:function ($$v) {_vm.$set(_vm.formData, "guarantor_lastname", $$v)},expression:"formData.guarantor_lastname"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.guarantor_phone_number
            ? ''
            : 'Add guarantor phone number',"value":_vm.currentEmployee.guarantor_phone_number
            ? _vm.currentEmployee.guarantor_phone_number
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.guarantor_phone_number
            ? ''
            : 'Enter guarantor phone number',"label":_vm.currentEmployee.guarantor_phone_number
            ? _vm.currentEmployee.guarantor_phone_number
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.guarantor_phone),callback:function ($$v) {_vm.$set(_vm.formData, "guarantor_phone", $$v)},expression:"formData.guarantor_phone"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"4","md":"4"}},[(!_vm.editing)?_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"label":_vm.currentEmployee.guarantor_email_address
            ? ''
            : 'Add guarantor email address',"value":_vm.currentEmployee.guarantor_email_address
            ? _vm.currentEmployee.guarantor_phone_number
            : '',"solo":"","disabled":!_vm.editing}}):_c('v-text-field',{staticStyle:{"color":"#111D5E !important"},attrs:{"placeholder":_vm.currentEmployee.guarantor_email_address
            ? ''
            : 'Enter guarantor email address',"label":_vm.currentEmployee.guarantor_email_address
            ? _vm.currentEmployee.guarantor_email_address
            : '',"solo":"","disabled":!_vm.editing},model:{value:(_vm.formData.guarantor_email),callback:function ($$v) {_vm.$set(_vm.formData, "guarantor_email", $$v)},expression:"formData.guarantor_email"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12","sm":"12"}},[(!_vm.editing)?_c('v-textarea',{staticClass:"textarea-for-package-type",staticStyle:{"color":"#111D5E"},attrs:{"solo":"","name":"input-7-4","label":_vm.currentEmployee.guarantor_address ? '' : 'Add guarantor address',"value":_vm.currentEmployee.guarantor_address
            ? _vm.currentEmployee.guarantor_address
            : '',"disabled":!_vm.editing}}):_c('v-textarea',{staticClass:"textarea-for-package-type",staticStyle:{"color":"#111D5E"},attrs:{"solo":"","name":"input-7-4","placeholder":_vm.currentEmployee.guarantor_address ? '' : 'Enter guarantor address',"label":_vm.currentEmployee.guarantor_address
            ? _vm.currentEmployee.guarantor_address
            : '',"disabled":!_vm.editing},model:{value:(_vm.formData.guarantor_address),callback:function ($$v) {_vm.$set(_vm.formData, "guarantor_address", $$v)},expression:"formData.guarantor_address"}})],1),_c('v-col',{staticClass:"pb-0 pt-0 text-center",attrs:{"cols":"12","sm":"12"}},[(_vm.editing)?_c('v-btn',{staticClass:"create-submit-package",attrs:{"type":"button","x-large":"","dark":""},on:{"click":_vm.submitUpdate}},[_vm._v(" "+_vm._s(_vm.btnTxt)+" "),_c('v-icon',{style:(!_vm.isLoading ? 'display: none' : '')},[_vm._v("fas fa-circle-notch fa-spin")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }