<template>
  <div>
    <v-app>
      <a v-if="!isAuth && !two_fa_confirmed" href="https://wa.me/+2347080593338" target="_blank"
        class="floating-button">
        <i class="fa-3x fab fa-whatsapp"></i>
      </a>
      <img style="position: fixed; opacity: 0;" src="@/assets/graphics/cross-check.png" alt="" class="img-fluid" />
      <img style="position: fixed; opacity: 0;" src="@/assets/graphics/check.png" alt="" class="img-fluid" />
      <div class="app-admin-wrap layout-sidebar-large">
        <template>
          <Menu />
          <SideMenu v-if="isAuth && two_fa_confirmed &&
        (authCompanyData.is_service_selected == 1 || userType != 1) &&
        $route.name !== 'SetupCompany'
        " />
        </template>
        <router-view></router-view>
      </div>
    </v-app>

  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Menu from "../src/components/Menu";
import SideMenu from "../src/components/dashboard/SideMenu2";

export default {
  data() {
    return {
      name: "Daniel",
      // modalDialog: false,
      intervalHandler: null
    };
  },
  components: {
    Menu,
    SideMenu
  },
  methods: {
    ...mapActions([
      "refresh_company_data",
      "fetch_all_roles",
      "fetch_available_services",
      "logout_request",
      "fetch_all_countries",
      "fetch_all_states"
    ]),
    // startInterval() {
    //   const _this = this;
    //   _this.intervalHandler = setInterval(() => {
    //     if (!_this.isAuth) {
    //       _this.modalDialog = true;
    //     }
    //   }, 300000);
    // }
  },
  watch: {
    subscription_expired(newValue) {
      if (newValue == true) {
        // alert('Subscription Expired');
      }
    },
    isAuth(newValue) {
      const _this = this;
      if (newValue == true) {
        clearInterval(_this.intervalHandler);
      } else {
        console.log('Hi');
        _this.startInterval();
      }
    }
  },
  computed: {
    ...mapState([
      "subscription_expired",
      "loggedInTime"
    ]),
    ...mapGetters([
      "isAuth",
      "authUserData",
      "authCompanyData",
      "userType",
      "authAllRoles",
      "authAllAvailableServices",
      "two_fa_confirmed"
    ])
  },
  onIdle() {
    if (this.isAuth) {
      this.logout_request();
      this.$router.push({ name: "Login" });
    }
  },
  mounted() {
    // document.title = "Kylian ERP";
    let _this = this;
    if (_this.isAuth && _this.two_fa_confirmed === false) {
      _this.$store.dispatch("logout_request");
    }
    setInterval(() => {
      if (_this.isAuth) {
        let diffInTime = new Date().getTime() - parseInt(_this.loggedInTime);
        if (diffInTime >= 43200000) {
          _this.logout_request();
        }
      }
    }, 1000);
    if (!_this.authAllRoles) {
      _this.fetch_all_roles();
    }
    _this.fetch_all_countries();
    _this.fetch_all_states();

    /** Display the modal every 5 minutes */
    // if (!_this.isAuth) {
    //   _this.modalDialog = true;
    //   _this.startInterval();
    // }
    /** Display the modal every 5 minutes */
  }
};
</script>

<style lang="scss"></style>
