<template>
    <div>
        <!-- <SideMenu /> -->
        <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
            <div class="main-header pl-4 mt-3" style="z-index: 60;">
                <div class="row">
                    <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
                        <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
                            <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"></i>
                            <span class="ml-2" style="top: 4%; position: relative;">Back</span>
                        </div>
                    </div>
                </div>
                <div style="margin: auto"></div>
                <div class="header-part-right"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : 'pr-5'">
                    <div v-if="userType == 1 || permissionIsAssigned('docket-create')"
                        class="dropdown animate__animated animate__fadeIn">
                        <template v-if="create_docket != 2">
                            <button v-if="userType == 1 || permissionIsAssigned('docket-create')"
                                @click="create_docket = 2" style="float: right;" type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                {{ "View Dockets" }}
                            </button>
                        </template>
                    </div>
                    <!-- Notificaiton End -->
                    <!-- User avatar dropdown -->
                </div>
            </div>
            <!-- ======== Body content start ========= -->
            <div v-if="create_docket == 1" class="main-content"
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
                <div class="breadcrumb mb-0"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null"
                    style="margin-top: 1rem;">
                    <h1 class="my-2 row pb-0">
                        <span class="col-lg-5 col-md-5 col-12">Create Docket</span>
                    </h1>
                </div>
                <v-form @submit.prevent>
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Case <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="formData.case" placeholder="Case" solo dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Date <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-1" color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline" v-model="formData.date"
                                            placeholder="Date" dense solo readonly v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="#069" ref="picker"
                                        :min="(new Date().getFullYear()).toString()"
                                        :max="(new Date().getFullYear() + 1).toString()" v-model="formData.date"
                                        @change="save">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Court <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="formData.court" placeholder="Court" solo dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Position <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="formData.position" placeholder="Position" solo
                                    dense>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" class="remove-hint text-center">
                                <button @click="create_docket_submit ? null : add_docket()" type="button"
                                    class="btn btn-secondary m-1 ripple w-25">
                                    {{ create_docket_text }}
                                    <v-icon :style="
                                        !create_docket_submit
                                            ? 'display: none;'
                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                    ">
                                        fas fa-circle-notch fa-spin
                                    </v-icon>
                                </button>
                                <button @click="create_docket = 2" type="button" class="btn btn-outline-secondary m-1">
                                    Cancel
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
            <div v-else-if="create_docket == 2" class="main-content"
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
                <div class="breadcrumb mb-0"
                    :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? null : null"
                    style="margin-top: 1rem;">
                    <div class="row pb-0 my-2">
                        <div class="col-12 col-md-3">
                            <h1>All Dockets</h1>
                        </div>
                        <div class="col-12 col-md-9">
                            <v-row v-if="userType == 1 || permissionIsAssigned('docket-create')" style="float: right;">
                                <v-col cols="6" md="2" class="pt-0 text-right">
                                    <button
                                        v-if="(create_docket == 1 || (create_docket == 2 && (userType == 1 || permissionIsAssigned('docket-create'))))"
                                        @click="tweak_display()" style="float: right;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{
                                                create_docket == 1
                                                    ? "View Dockets"
                                                    : create_docket == 2
                                                        ? "Create Docket"
                                                        : "View Dockets"
                                        }}
                                    </button>
                                </v-col>
                                <v-col cols="6" md="3" class="pt-0 text-center">
                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="dateRangeText" placeholder="Select dates" solo dense readonly
                                                v-bind="attrs" v-on="on"
                                                :messages="date_range.length == 0 ? '*Select date range' : date_range.length == 1 ? '*Please select an end date' : '*Showing results for ' + new Date(date_range[0]).toUTCString().substring(0, 16) + ' to ' + new Date(date_range[1]).toUTCString().substring(0, 16)">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker color="#069" range v-model="date_range" :min="
                                            new Date(new Date().getTime() - 3.156e+10)
                                                .toISOString()
                                                .substring(0, 10)
                                        " :max="
    new Date(new Date().getTime() + 86400000000)
        .toISOString()
        .substring(0, 10)
"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" md="2" class="pt-0 text-right">
                                    <button @click="print()"
                                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                                        class="btn btn-outline-secondary w-100 m-1">
                                        Print
                                    </button>
                                </v-col>
                                <v-col cols="6" md="3" class="pt-0"
                                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'">
                                    <download-excel v-if="excel_filtered_dockets.length >= 1" :data="json_data"
                                        :fields="json_fields" worksheet="All Dockets"
                                        :name="'DOCKET FOR ' + excel_filtered_dockets[0].date + ' TO ' + excel_filtered_dockets[excel_filtered_dockets.length - 1].date + '.xls'"
                                        class="btn btn-raised ripple btn-raised-secondary m-1 text-white w-100"
                                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                                        Export to Excel
                                    </download-excel>
                                    <button v-else style="cursor: disabled; opacity: 0.5;"
                                        class="btn btn-raised ripple btn-raised-secondary m-1 text-white w-100"
                                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                                        Export to Excel
                                    </button>
                                </v-col>
                                <v-col cols="6" md="2" class="pt-0 text-right">
                                    <button @click="reset_filter()"
                                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                                        class="btn btn-raised ripple btn-raised-secondary w-100 m-1 text-white">
                                        Reset
                                    </button>
                                </v-col>
                            </v-row>
                            <v-row v-else style="float: right;">
                                <v-col cols="6" md="8" class="pt-0 text-center">
                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field color="#069" prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="dateRangeText" placeholder="Select dates" solo dense readonly
                                                v-bind="attrs" v-on="on"
                                                :messages="date_range.length == 0 ? '*Select date range' : date_range.length == 1 ? '*Please select an end date' : '*Showing results for ' + new Date(date_range[0]).toUTCString().substring(0, 16) + ' to ' + new Date(date_range[1]).toUTCString().substring(0, 16)">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker color="#069" range v-model="date_range" :min="
                                            new Date(new Date().getTime() - 3.156e+10)
                                                .toISOString()
                                                .substring(0, 10)
                                        " :max="
    new Date(new Date().getTime() + 86400000000)
        .toISOString()
        .substring(0, 10)
"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!-- <v-col cols="6" lg="2" xl="2" md="2" class="pt-0 text-right">
                                    <button @click="print()"
                                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                                        class="btn btn-outline-secondary w-100 m-1">
                                        Print
                                    </button>
                                </v-col> -->
                                <!-- <v-col v-if="/* && permissionIsAssigned('docket-read') */" cols="6" lg="3"
                                    xl="3" md="3" class="pt-0"
                                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'">
                                    <download-excel v-if="excel_filtered_dockets.length >= 1" :data="json_data"
                                        :fields="json_fields" worksheet="All Dockets"
                                        :name="'DOCKET FOR ' + excel_filtered_dockets[0].date + ' TO ' + excel_filtered_dockets[excel_filtered_dockets.length - 1].date + '.xls'"
                                        class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                                        Export to Excel
                                    </download-excel>
                                    <button v-else style="cursor: disabled; opacity: 0.5;"
                                        class="btn btn-raised ripple btn-raised-secondary m-1 text-white"
                                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null">
                                        Export to Excel
                                    </button>
                                </v-col> -->
                                <v-col cols="6" md="4" class="pt-0 text-right">
                                    <button @click="reset_filter()"
                                        :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'w-100' : null"
                                        class="btn btn-raised ripple btn-raised-secondary w-100 m-1 text-white">
                                        Reset
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
                <div class="main-content mt-0 row pr-0"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
                    <div id="printMe" class="card w-100" style="border-radius: 10px;">
                        <div class="card-header px-3 d-print-block d-none">
                            <h4 v-if="excel_filtered_dockets.length >= 1">
                                {{ 'DOCKET FOR ' + excel_filtered_dockets[0].date + ' TO ' +
                                        excel_filtered_dockets[excel_filtered_dockets.length - 1].date
                                }}
                            </h4>
                        </div>
                        <div class="card-body px-1 py-1 pt-0 pr-0">
                            <div class="table-responsive table-scroller">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-left" style="border-top: none;" scope="col">DATE</th>
                                            <th class="text-left" style="border-top: none;" scope="col">CASE/ACTIVITY
                                            </th>
                                            <th class="text-left" style="border-top: none;" scope="col">COURT/CORAM</th>
                                            <th class="text-left" style="border-top: none;" scope="col">POSITION</th>
                                            <th class="text-left" style="border-top: none;" scope="col">COUNSEL</th>
                                            <th v-if="userType == 1 || permissionIsAssigned('docket-add-employees')"
                                                class="text-center d-print-none" style="border-top: none;" scope="col">
                                                Manage Counsel
                                            </th>
                                            <th v-if="userType == 1 || (permissionIsAssigned('docket-update') || permissionIsAssigned('docket-delete'))"
                                                class="text-left d-print-none" style="border-top: none;" scope="col">
                                                Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="docket in filtered_dockets" :key="docket.id">
                                            <td class="text-left">{{ get_docket_date(docket.date) }}</td>
                                            <td class="text-left">{{ docket.case }}</td>
                                            <td class="text-left">{{ docket.court }}</td>
                                            <td class="text-left">{{ docket.position }}</td>
                                            <td v-if="employeesInDocket(docket.id).length" class="text-left">
                                                <span style="text-transform: uppercase;">
                                                    {{
                                                            namesOfEmployeesInDocket(docket.id)
                                                    }}
                                                </span>
                                            </td>
                                            <td v-else class="text-left">
                                                <i>NO IDENTIFIED COUNSEL</i>
                                            </td>
                                            <td v-if="userType == 1 || permissionIsAssigned('docket-add-employees')"
                                                class="text-center py-2 d-print-none">
                                                <button
                                                    @click="(current_docket_id = docket.id, view_counsels_dialog = true)"
                                                    type="button" class="btn btn-secondary btn-sm ripple">
                                                    Manage
                                                </button>
                                            </td>
                                            <td v-if="userType == 1 || (permissionIsAssigned('docket-update') || permissionIsAssigned('docket-delete'))"
                                                class="text-left d-print-none">
                                                <div class="row" style="padding-top: 13px;">
                                                    <i v-if="userType == 1 || permissionIsAssigned('docket-update')"
                                                        class="col-3 py-0 text-center fal fa-edit"
                                                        @click="edit_docket(docket)"
                                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                    <i v-if="userType == 1 || permissionIsAssigned('docket-delete')"
                                                        class="col-3 offset-2 py-0 text-center fal fa-trash"
                                                        @click="delete_docket(docket.id)"
                                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="main-content"
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-2' : null">
                <div class="breadcrumb mb-0" style="margin-top: 1rem;"
                    :style="$vuetify.breakpoint.name != 'xs' ? null : null">
                    <h1 class="my-2 row pb-0">
                        <span class="col-lg-5 col-md-5 col-12">Edit Docket</span>
                    </h1>
                </div>
                <v-form @submit.prevent>
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Case <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="editFormData.case" placeholder="Case" solo dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Date <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-1" color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline" v-model="editFormData.date"
                                            placeholder="Date" dense solo readonly v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="#069" ref="picker"
                                        :min="(new Date().getFullYear()).toString()"
                                        :max="(new Date().getFullYear() + 1).toString()" v-model="editFormData.date"
                                        @change="save">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Court <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="editFormData.court" placeholder="Court" solo dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;">Position <i class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                <v-text-field class="mt-1" v-model="editFormData.position" placeholder="Position" solo
                                    dense>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" class="remove-hint text-center">
                                <button @click="update_docket_submit ? null : update_docket()" type="button"
                                    class="btn btn-secondary m-1 ripple w-25">
                                    {{ update_docket_text }}
                                    <v-icon :style="
                                        !update_docket_submit
                                            ? 'display: none;'
                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                    ">
                                        fas fa-circle-notch fa-spin
                                    </v-icon>
                                </button>
                                <button @click="create_docket = 2" type="button" class="btn btn-outline-secondary m-1">
                                    Cancel
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
            <div class="flex-grow-1"></div>
        </div>
        <v-row justify="center">
            <v-dialog v-model="view_counsels_dialog" max-width="600px">
                <v-card>
                    <v-card-title class="text-left d-block">
                        <span class="headline">Docket Counsels</span>
                        <button @click="(view_counsels_dialog = false, add_counsels_dialog = true)"
                            style="float: right;" type="button"
                            class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                            Add Counsel
                        </button>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="text-left">Counsel</th>
                                        <th v-if="userType == 1 || permissionIsAssigned('docket-remove-employees')"
                                            class="text-left">Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(counsel, index) in employeesInDocket(current_docket_id)"
                                        :key="'counsel' + index">
                                        <td class="text-left">
                                            {{ get_employee_name(counsel.id) }}
                                        </td>
                                        <td v-if="userType == 1 || permissionIsAssigned('docket-remove-employees')"
                                            class="text-left">
                                            <div class="row">
                                                <i @click="remove_counsel(current_docket_id, counsel.id)"
                                                    class="col-sm-12 py-4 text-left fas fa-user-minus ml-2"
                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="view_counsels_dialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="add_counsels_dialog" max-width="600px">
                <v-card>
                    <v-card-title class="text-left d-block">
                        <span class="headline">Add counsels to docket</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form @submit.prevent>
                                <div class="container-fluid">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" class="remove-hint">
                                            <v-select v-model="selectedEmployees" :items="availableEmployeeNames"
                                                :menu-props="{ maxHeight: '400' }" label="Select" multiple solo dense
                                                hint="Select Employees" persistent-hint></v-select>
                                        </v-col>
                                        <v-col cols="12" offset-md="3" offset-sm="3" sm="6" md="6" class="remove-hint">
                                            <button @click="add_employees_submit ? null : add_employees_to_docket()"
                                                style="width: 99%" type="button" class="
                                                btn btn-secondary
                                                m-1
                                                text-white
                                                btn-raised
                                                ripple
                                                ">
                                                {{ add_employees_text }}
                                                <v-icon :style="
                                                    !add_employees_submit
                                                        ? 'display: none;'
                                                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                ">
                                                    fal fa-circle-notch fa-spin
                                                </v-icon>
                                            </button>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="add_counsels_dialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
  
<script>
import axios from "axios";
import { ASSETS_URL, BASE_URL } from "@/main";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
    data() {
        return {
            current_docket: {
                case: '',
                date: ''
            },
            add_employees_text: 'Submit',
            add_employees_submit: false,
            selectedEmployees: [],
            date_range: [],
            current_docket_id: null,
            add_counsels_dialog: false,
            view_counsels_dialog: false,
            menu: false,
            menu2: false,
            create_docket_text: "Submit",
            create_docket_submit: false,
            update_docket_text: "Update",
            update_docket_submit: false,
            create_docket: 2,
            formData: {
                case: '',
                date: '',
                court: '',
                position: ''
            },
            editFormData: {
                id: '',
                case: '',
                date: '',
                court: '',
                position: ''
            },
            allEmployees: []
        };
    },
    watch: {
        // menu(val) {
        //     val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
        // },
        // menu2(val) {
        //     val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
        // }
    },
    computed: {
        ...mapState([
            'weekDays'
        ]),
        ...mapGetters([
            "validEmployeeId",
            "authCompanyData",
            "authEmployeeDataForCompany",
            "authUserData",
            "userType",
            "authEmployeeDockets",
            "authCompanyDockets",
            "authCompanyAllEvents"
        ]),
        docketEventId() {
            let _this = this, result = [];
            result = _this.authCompanyAllEvents.filter(event => {
                return event.title == _this.current_docket.case && event.date == _this.current_docket.start_date && event.date == _this.current_docket.end_date
            });
            if (result.length) {
                return result[0].id;
            }
            return 0;
        },
        employeesToAdd() {
            let _this = this, result = [];
            result = _this.selectedEmployees.map(se => {
                return {
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.get_employee_id(se),
                    docket_id: _this.current_docket_id
                }
            })
            return result;
        },
        assets_url() {
            return ASSETS_URL;
        },
        dateRangeText() {
            if (this.date_range) {
                return this.date_range.join(' ~ ')
            }
            return null
        },
        json_data: function () {
            return this.excel_filtered_dockets;
        },
        json_fields: function () {
            return {
                "DATE": "date",
                // "S/N": "sn",
                "CASE/ACTIVITY": "case",
                "COURT/CORAM": "court",
                "POSITION": "position",
                "COUNSEL": "counsel"
            }
        },
        filtered_dockets() {
            let _this = this, dockets = _this.authCompanyDockets;

            if (_this.date_range.length == 2) {
                dockets = dockets.filter(docket => {
                    return (new Date(docket.date).getTime() >= new Date(_this.date_range[0]).getTime()) && (new Date(docket.date).getTime() <= new Date(_this.date_range[1]).getTime())
                });
            }

            return dockets;
        },
        excel_filtered_dockets() {
            let _this = this, counsel = [];
            return _this.filtered_dockets.map(docket => {
                // counsel = _this.employeesInDocket(docket.id).map(e => _this.get_employee_name(e.employee_id).toUpperCase());
                // counsel = counsel.join('\n');
                counsel = _this.namesOfEmployeesInDocket(docket.id);
                if (counsel.length === 0) {
                    counsel = 'NO IDENTIFIED COUNSEL'
                }
                return {
                    date: _this.get_docket_date(docket.date),
                    // sn: index + 1,
                    case: docket.case,
                    court: docket.court,
                    position: docket.position,
                    counsel
                }
            })
        },
        edit_docket_type_id() {
            let _this = this;
            if (_this.editFormData.type_name) {
                return _this.authCompanyAllAssetType.filter(type => {
                    return _this.editFormData.type_name == type.assettype_name;
                })[0].id;
            } else {
                return "";
            }
        },
        availableEmployees() {
            let _this = this, result = [];
            result = _this.mixinBranchEmployees(_this.$route.name).filter(employee => {
                return _this.employeeIsInDocket(employee.id, _this.current_docket_id) === false;
            });
            return result;
        },
        availableEmployeeNames() {
            let _this = this, result = [];
            result = _this.availableEmployees.map(employee => `${employee.employee_fristname} ${employee.employee_lastname}`);
            return result;
        }
    },
    methods: {
        ...mapActions([
            "fetch_company_employee_dockets",
            "fetch_company_dockets",
            "fetch_all_company_events"
        ]),
        create_event(title, date) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            axios
                .post(`${BASE_URL}createevent`, {
                    company_id: _this.authCompanyData.id,
                    title,
                    start_date: date,
                    end_date: date
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Docket created successfully"
                    });
                    _this.fetch_all_company_events();

                    _this.create_docket = 2;
                    _this.formData.case = "";
                    _this.formData.date = "";
                    _this.formData.court = "";
                    _this.formData.position = "";
                })
                .catch(function () {
                    _this.create_docket = 2;
                    Toast.fire({
                        icon: "error",
                        title: "Docket created successfully but event creation failed"
                    });
                    _this.fetch_all_company_events();
                })
                .then(function () {
                    _this.create_docket_submit = false;
                    _this.create_docket_text = "Submit";
                });
        },
        edit_event(title, date) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            if (_this.docketEventId) {
                axios
                    .post(`${BASE_URL}editevent`, {
                        event_id: _this.docketEventId,
                        title,
                        start_date: date,
                        end_date: date
                    })
                    .then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "Docket updated successfully"
                        });
                        _this.fetch_all_company_events();

                        _this.create_docket = 2;
                        _this.editFormData.case = "";
                        _this.editFormData.date = "";
                        _this.editFormData.court = "";
                        _this.editFormData.position = "";
                    })
                    .catch(function () {
                        _this.create_docket = 2;
                        Toast.fire({
                            icon: "error",
                            title: "Docket updated successfully but event update failed"
                        });
                        _this.fetch_all_company_events();
                    })
                    .then(function () {
                        _this.update_docket_submit = false;
                        _this.update_docket_text = "Update";
                    });
            } else {
                Toast.fire({
                    icon: "error",
                    title: "Docket updated successfully"
                });
            }
        },
        async print() {
            // Pass the element id here
            await this.$htmlToPaper('printMe');
        },
        getCounselId(docket_id, employee_id) {
            let _this = this, value = 0, object = _this.authEmployeeDockets.filter(ed => {
                return ed.docket_id == docket_id && ed.employee_id == employee_id
            });
            if (object.length) {
                value = object[0].id
            }
            return value;
        },
        hasHistory: function () {
            return window.history.length > 2;
        },
        reset_filter() {
            this.date_range = [];
        },
        get_docket_date(date) {
            let _this = this, value = '', dateToArray = date.split('-'), gmtString = new Date(date).toGMTString(), gmtDay = gmtString.substring(0, 3);
            let weekDay = _this.weekDays.filter(day => {
                return day.indexOf(gmtDay) !== -1
            })[0];
            value = `${weekDay} ${dateToArray[2]}/${dateToArray[1]}/${dateToArray[0]}`;
            value = value.toUpperCase();
            return value;
        },
        save: function (t) {
            this.$refs.menu.save(t);
        },
        save2: function (t) {
            this.$refs.menu2.save(t);
        },
        get_employee_id(employee_name) {
            let _this = this, result = [], value = null;
            result = _this.mixinBranchEmployees(_this.$route.name).filter(te => (te.employee_fristname + ' ' + te.employee_lastname) == employee_name);
            if (result.length) {
                value = result[0].id;
            }
            return value;
        },
        add_employees_to_docket() {
            let _this = this;
            _this.add_employees_text = "";
            _this.add_employees_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            axios
                .post(`${BASE_URL}addemployeestodocket`, {
                    employees: _this.employeesToAdd
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Employees added successfully"
                    });
                    _this.fetch_company_employee_dockets();
                    _this.add_counsels_dialog = false;
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: 'Employees couldn\'t be added'
                    });
                })
                .then(function () {
                    _this.add_employees_submit = false;
                    _this.add_employees_text = "Submit";
                });
        },
        employeesInDocket(docket_id) {
            let _this = this, result = [];
            result = _this.mixinBranchEmployees(_this.$route.name).filter(employee => {
                return _this.employeeIsInDocket(employee.id, docket_id) === true;
            });
            return result;
        },
        namesOfEmployeesInDocket(docket_id) {
            let _this = this, employeesInDocket = _this.employeesInDocket(docket_id), value = '';
            employeesInDocket.forEach((employee, index) => {
                if (index == (employeesInDocket.length - 1)) {
                    value += `${_this.get_employee_name(employee.id).toUpperCase()}.`;
                } else {
                    value += `${_this.get_employee_name(employee.id).toUpperCase()}, `;
                }
            });
            return value;
        },
        employeeIsInDocket(employee_id, docket_id) {
            let _this = this, truth = false;
            _this.authEmployeeDockets.forEach(item => {
                if (item.docket_id == docket_id && item.employee_id == employee_id) {
                    truth = true;
                }
            });
            return truth;
        },
        get_employee_name: function (t) {
            var e = this;
            if (e.mixinBranchEmployees(e.$route.name)) {
                var a = e.mixinBranchEmployees(e.$route.name).filter(function (e) {
                    return e.id == t;
                })[0];
                return a ? a.employee_fristname + " " + a.employee_lastname : 'Not found';
            }
            return "";
        },
        docketEmployees() {
            let _this = this, result = [];
            result = _this.authEmployeeDockets.filter(docketEmployee => docketEmployee.docket_id == _this.current_docket_id);
            return result;
        },
        remove_counsel(docket_id, employee_id) {
            let _this = this, counsel_id = _this.getCounselId(docket_id, employee_id);

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            _this.$swal
                .fire({
                    title:
                        "Are you sure you want to remove this counsel?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`
                })
                .then(result => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deleteemployeedocket `, {
                                employee_id: _this.validEmployeeId,
                                company_id: _this.authCompanyData.id,
                                docket_id: counsel_id
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Counsel removed successfully"
                                });
                                _this.fetch_company_employee_dockets();
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Could not remove the counsel"
                                });
                                _this.fetch_company_employee_dockets();
                                console.log(error);
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        tweak_display() {
            if (this.create_docket == 1) {
                this.create_docket = 2;
            } else if (this.create_docket == 2) {
                this.create_docket = 1;
            } else {
                ("");
            }
        },
        edit_docket(docket) {
            let _this = this;
            _this.current_docket = docket;
            _this.create_docket = 3;
            _this.editFormData.id = docket.id;
            _this.editFormData.case = docket.case;
            _this.editFormData.date = docket.date;
            _this.editFormData.court = docket.court;
            _this.editFormData.position = docket.position;
        },
        delete_docket(docket_id) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                company_id: _this.authCompanyData.id,
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            _this.$swal
                .fire({
                    title:
                        "Are you sure you want to delete this docket?",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6 !important",
                    confirmButtonText: `Yes, Delete`
                })
                .then(result => {
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deletedocket`, {
                                docket_id: docket_id,
                                employee_id: _this.validEmployeeId,
                                company_id: _this.authCompanyData.id
                            })
                            .then(function () {

                                _this.fetch_company_dockets();
                                Toast.fire({
                                    icon: "success",
                                    title: "Docket deleted successfully"
                                });
                            })
                            .catch(function () {
                                Toast.fire({
                                    icon: "error",
                                    title: "The docket couldn't be deleted"
                                });
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
        update_docket() {
            let _this = this;
            _this.update_docket_text = "";
            _this.update_docket_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            axios
                .post(`${BASE_URL}editdocket`, {
                    docket_id: _this.editFormData.id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    case: _this.editFormData.case,
                    date: _this.editFormData.date,
                    court: _this.editFormData.court,
                    position: _this.editFormData.position
                })
                .then(function () {
                    _this.fetch_company_employee_dockets();
                    _this.fetch_company_dockets();
                    _this.edit_event(
                        _this.editFormData.case,
                        _this.editFormData.date
                    );
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors ? (typeof errors === 'string' ? errors : null
                            || errors.case ? errors.case[0] : null
                                || errors.date ? errors.date[0] : null
                                    || errors.court ? errors.court[0] : null
                                        || errors.position ? errors.position[0] : null) : null
                                        || error.response.data.error || error.response.data.message || 'Docket could not be created'
                    });
                })
                .then(function () {
                    //
                });
        },
        add_docket() {
            let _this = this;
            _this.create_docket_text = "";
            _this.create_docket_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                }
            });

            axios
                .post(`${BASE_URL}createdocket`, {
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.validEmployeeId,
                    case: _this.formData.case,
                    date: _this.formData.date,
                    court: _this.formData.court,
                    position: _this.formData.position
                })
                .then(function () {
                    _this.fetch_company_employee_dockets();
                    _this.fetch_company_dockets();
                    _this.create_event(
                        _this.formData.case,
                        _this.formData.date
                    );
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors ? (typeof errors === 'string' ? errors : null
                            || errors.case ? errors.case[0] : null
                                || errors.date ? errors.date[0] : null
                                    || errors.court ? errors.court[0] : null
                                        || errors.position ? errors.position[0] : null) : null
                                        || error.response.data.error || error.response.data.message || 'Docket could not be created'
                    });
                })
                .then(function () {
                    //
                });
        }
    },
    mounted() {
        document.title = "Kylian ERP - Dockets";
        let _this = this;
        _this.fetch_company_employee_dockets();
        _this.fetch_company_dockets();
        _this.fetch_all_company_events();
        if (_this.userType) {
            _this.mixinBranchEmployees(_this.$route.name).forEach(person => {
                _this.allEmployees.push(
                    person.employee_fristname + " " + person.employee_lastname
                );
            });
        } else {
            //
        }
    }
};
</script>
  
<style>
.ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;
}

.editor-opener {
    border-radius: 5px;
    box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.ql-container.ql-snow {
    border: none !important;
}

.remove-hint div div div.v-text-field__details {
    display: none !important;
}

.educationField .v-text-field__details {
    display: none !important;
}

.educationField .v-input__slot {
    margin: 0;
}

.supportingDocs div div div .v-file-input__text {
    cursor: pointer;
}
</style>
  