<template>
    <div class="">
        <v-app class="">
            <v-row style="margin-top: 60px;">
                <v-col cols="12" style="background-color: #C2DAFE;">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <div style="margin-top: 40px;">
                                    <v-row>
                                        <v-col cols="12">
                                            <h2 class="text-center"
                                                style="font-family: Poppins !important; font-size: 32px; line-height: 48px; font-weight: 600; color: #000 !important;">
                                                Tailored Subscriptions: Your Path to Flexibility and Fairness
                                            </h2>
                                            <h5 class="text-center">
                                                Our subscription plans are designed to be flexible and fair, offering
                                                options that cater to your individual needs. Whether you're a casual
                                                user or a power user, we have a plan that suits you.
                                            </h5>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <div class="package-card">
                                                        <div class="package-header">
                                                            <h4 class="text-white my-2">Starter</h4>
                                                        </div>
                                                        <div class="package-body">
                                                            <ul class="pl-0" style="list-style: none;">
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Human
                                                                    resource information system</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Employee
                                                                    self-services</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Leave
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Attendance
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Payroll
                                                                    management</li>
                                                            </ul>
                                                        </div>
                                                        <div class="package-footer mb-4">
                                                            <router-link
                                                                :to="{ name: 'RequestDemo', query: { tier: 1 } }">
                                                                <button class="package-button">
                                                                    <span style="font-size: 17px;">
                                                                        Request for Demo
                                                                    </span>
                                                                </button>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <div class="package-card-alt">
                                                        <div class="package-header">
                                                            <h4 class="text-white my-2">Standard</h4>
                                                        </div>
                                                        <div class="package-body-alt">
                                                            <ul class="pl-0" style="list-style: none;">
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Human
                                                                    resource information system</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Employee
                                                                    self-services</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Leave
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Attendance
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Payroll
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Performance
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Requisition
                                                                </li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Recruitment
                                                                </li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-white.svg" />Workflow
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="package-footer mb-4">
                                                            <router-link
                                                                :to="{ name: 'RequestDemo', query: { tier: 2 } }">
                                                                <button class="package-button-alt">
                                                                    <span style="font-size: 17px;">
                                                                        Request for Demo
                                                                    </span>
                                                                </button>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <div class="package-card">
                                                        <div class="package-header">
                                                            <h4 class="text-white my-2">Premium</h4>
                                                        </div>
                                                        <div class="package-body">
                                                            <ul class="pl-0" style="list-style: none;">
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Human
                                                                    resource information system</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Employee
                                                                    self-services</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Leave
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Attendance
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Payroll
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Performance
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Requisition
                                                                </li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Recruitment
                                                                </li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Workflow
                                                                </li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Loan
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Exit
                                                                    management</li>
                                                                <li class="mb-3"><img
                                                                        src="/assets/svg/checkbox-black.svg" />Timesheet
                                                                    management</li>
                                                            </ul>
                                                        </div>
                                                        <div class="package-footer mb-4">
                                                            <router-link
                                                                :to="{ name: 'RequestDemo', query: { tier: 3 } }">
                                                                <button class="package-button">
                                                                    <span style="font-size: 17px;">
                                                                        Request for Demo
                                                                    </span>
                                                                </button>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>

                <GuestNewsletter />

                <GuestFooter />
            </v-row>

        </v-app>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GuestFooter from "@/components/GuestFooter";
import GuestNewsletter from "@/components/GuestNewsletter";
export default {
    components: {
        GuestFooter,
        GuestNewsletter
    },
    data() {
        return {
            coreFeature: 'all',
            coreFeaturesList: [
                {
                    name: 'Onboarding',
                    description: 'Onboarding: Streamlines the process of welcoming and integrating new employees into the organization.',
                    image: '/assets/images/core-features/onboarding.png',
                    feature: 'eis'
                },
                {
                    name: 'Employee Database',
                    description: 'KylianERP stores the comprehensive information about all staff members for easy access and management.',
                    image: '/assets/images/core-features/vector.png',
                    feature: 'eis'
                },
                {
                    name: 'Attendance Management',
                    description: 'Attendance management: Tracks and records employee attendance to ensure punctuality and productivity.',
                    image: '/assets/images/core-features/check.png',
                    feature: 'ess'
                },
                {
                    name: 'Personal Information Management',
                    description: 'Personnel information management: Stores and manages essential personnel data securely and efficiently.',
                    image: '/assets/images/core-features/employees.png',
                    feature: 'ess'
                },
                {
                    name: 'Timesheet Management',
                    description: 'Timesheet management: Manages work hours and project timelines for effective task allocation and monitoring.',
                    image: '/assets/images/core-features/timesheets.png',
                    feature: 'ess'
                },
                {
                    name: 'Leave Management',
                    description: 'Leave management: Tracks employee leave applications, approvals, and balances for smooth workflow planning.',
                    image: '/assets/images/core-features/leave.png',
                    feature: 'ess'
                },
                {
                    name: 'Requisition Management',
                    description: 'Facilitates the process of requesting and approving necessary resources within the organization.',
                    image: '/assets/images/core-features/requisition.png',
                    feature: 'ess'
                },
                {
                    name: 'Travel Management',
                    description: 'Travel management: Streamlines and organizes employee travel arrangements for seamless business trips.',
                    image: '/assets/images/core-features/travel.png',
                    feature: 'ess'
                },
                {
                    name: 'Medicals',
                    description: 'Medicals: Manages employee health records and medical benefits for a healthier workforce',
                    image: '/assets/images/core-features/medicals.png',
                    feature: 'ess'
                },
                {
                    name: 'Payroll Management',
                    description: 'Payroll management: Handles employee salary processing and tax deductions accurately and efficiently.',
                    image: '/assets/images/core-features/payroll.png',
                    feature: 'ess'
                },
                {
                    name: 'Recruitment',
                    description: 'Streamlines the hiring process by managing job postings, applicant tracking, and candidate evaluations.',
                    image: '/assets/images/core-features/recruitment.png',
                    feature: 'ess'
                },
                {
                    name: 'Dockets',
                    description: 'Dockets for law firms: Organizes legal documents and case-related information for law firms\' smooth operations.',
                    image: '/assets/images/core-features/dockets.png',
                    feature: 'ess'
                }
            ]
        };
    },
    computed: {
        ...mapGetters([
            'authAllAvailableServices'
        ]),
        servicesNames() {
            let _this = this, humanResourcesServices = [], humanResourcesServicesNames = [], accountsServices = [], accountsServicesNames = [], result = [];
            if (_this.authAllAvailableServices) {
                humanResourcesServices = _this.authAllAvailableServices.human_resources_services;
                accountsServices = _this.authAllAvailableServices.accounting_services;
                humanResourcesServicesNames = humanResourcesServices.map(service => service.service_name);
                accountsServicesNames = accountsServices.map(service => service.service_name);
                result = [...humanResourcesServicesNames, ...accountsServicesNames];
            }
            return result;
        },
    },
    methods: {
        ...mapActions([
            'fetch_available_services'
        ]),
    },
    mounted() {
        document.title = "Kylian ERP - Pricing";
        this.fetch_available_services();
    }
};
</script>

<style lang="scss">
$new-button-color: #225BA6;
$primary-color: #069;
$text-field-color: #4e4e52;

.package-card {
    background-color: #FFFFFF;
    border-radius: 0px 30px 0px 30px;
}

.package-card-alt {
    background-color: #0B54C0 !important;
    color: #FFFFFF !important;
    border-radius: 0px 30px 0px 30px;
}

.package-body,
.package-body-alt {
    padding: 15px;
}

.package-body ul li,
.package-body-alt ul li {
    font-size: 16px;
}

.package-body-alt ul li {
    color: #FFFFFF;
}

.package-header {
    background-color: #0B54C0;
    padding: 10px;
    border-radius: 0px 30px 0px 30px;
    text-align: center;
}

.package-button {
    padding: 10px 20px;
    color: #FFFFFF;
    background-color: #0B54C0;
    border-radius: 5px;
}

.package-button:hover {
    background-color: #063882;
}

.package-button-alt {
    padding: 10px 20px;
    color: #0B54C0;
    background-color: #FFFFFF;
    border-radius: 5px;
}

.package-button-alt:hover {
    background-color: #dcdcdc;
}

.package-footer {
    margin-bottom: 20px;
    padding: 40px 20px;
}

.new-create-submit {
    span {
        font-family: Inter !important;
    }
}

.text-new-primary {
    color: $new-button-color !important;
}

.v-application .primary--text {
    color: $primary-color !important;
}

.fa-eye,
.fa-eyeslash {
    color: $primary-color !important;
}

// .v-text-field__suffix, .v-text-field__prefix, input {
//     color: $text-field-color !important;
//     background-color: #00669914;
//     padding: 10px;
//     height: 48px;
// }
.v-text-field__suffix {
    border-radius: 0px 5px 5px 0px;
    padding-top: 13px;
    padding-left: 8px !important;
}

.v-text-field__prefix {
    border-radius: 5px 0px 0px 5px;
    padding-top: 13px;
    padding-right: 8px !important;
    text-decoration: underline;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
    label {
        margin-left: 2%;
    }

    input {
        text-indent: 3%;
    }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.v-text-field__details {
    padding-left: 0 !important;
}

.theme--light.v-messages {
    color: $primary-color !important;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

.remove-bottom-hint div .v-text-field__details {
    display: none;
}
</style>
<style lang="scss" scoped>
$new-button-color: #2081FF;
$primary-color: #069;

body {
    background-color: #C2DAFE;
}

@media (max-width: 991px) {
    .create-submit {
        height: 45px !important;
    }

    .v-form {
        margin: 0 !important;
    }
}

.v-form {
    margin: 0 20%;
}

.svg-login {
    position: absolute;
    top: 8%;
    right: 22%;
}

#small-link {
    color: $primary-color !important;
}

.space-top {
    padding-top: 40px;
}

.create-submit {
    width: 100%;
    height: 60px;
    border-radius: 10px !important;
    background-color: #069 !important;
    text-transform: initial !important;
    font-weight: 600 !important;

    span {
        text-transform: initial !important;
    }
}

.new-create-submit {
    width: 100%;
    height: 60px;
    border-radius: 10px !important;
    background-color: $new-button-color !important;
    text-transform: initial !important;
    font-weight: 600 !important;

    span {
        text-transform: initial !important;
        font-family: Inter !important;
    }
}

h3 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.6252632141113281px;
    text-align: center;
    color: #111d5e;
}

label {
    color: #999 !important;
}
</style>

<style lang="scss">
.home-page-subscriber .v-text-field--outlined .v-label,
.home-page-subscriber .theme--light.v-input input,
.theme--light.v-input textarea {
    color: #FFFFFF !important;
}

.home-page-subscriber .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
    color: #FFFFFF !important;
}
</style>