<template>
  <div>
    <!-- <SideMenu /> -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div style="align-self: center;" class="logo col-md-1 col-lg-1 col-12 py-0">
            <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
              <i @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"></i>
              <span class="ml-2" style="top: 4%; position: relative;">Back</span>
            </div>
          </div>
        </div>
        <div style="margin: auto"></div>
        <div class="header-part-right pr-5">
          <div class="dropdown">
            <button class="btn ripple m-1" type="button" @click="refresh_page()"
              :class="refreshing_page ? 'rotate-spinner' : ''">
              <span class="ul-btn__icon"><i style="font-weight: bold;" class="i-Repeat-21"></i></span>
            </button>
          </div>
          <!-- Grid menu Dropdown -->
          <div v-if="userType != 1" class="dropdown">
            <button type="button" class="btn btn-outline-secondary m-1">
              Apply Leave
            </button>
          </div>
          <!-- Notificaiton -->
          <div class="dropdown">
            <button v-if="userType != '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })">
              Log Time
            </button>
            <button v-if="userType == '1'" type="button" class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'Attendance' })">
              Log History
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div class="main-content pt-0">
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div class="row">
            <h1 class="my-0 col-md-12 pb-3">
              <span>Announcements</span>
              <v-row style="float: right;">
                <v-col cols="12" class="pt-0">
                  <button v-if="ids.length >= 1 && permissionIsAssigned('announcement-delete')"
                    @click="delete_announcement()" style="float: right;" type="button"
                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                    {{ ids.length == 1 ? 'Delete Announcement' : 'Delete Announcements' }}
                  </button>
                  <button v-if="permissionIsAssigned('announcement-create')" @click="launch_dialog()"
                    style="float: right;" type="button"
                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                    Create Announcement
                  </button>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div class="table-responsive" style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;">
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th v-if="permissionIsAssigned('announcement-delete')"
                            style="width: 80px; padding-left: 2.4%;">
                            <label class="checkbox checkbox-secondary mb-3">
                              <input type="checkbox"
                                :checked="ids.length == authCompanyAllAnnouncements.length && authCompanyAllAnnouncements.length != 0"
                                @change="check_all()" />
                              <span class="checkmark"></span>
                            </label>
                          </th>
                          <th class="text-left" scope="col">Title</th>
                          <th class="text-left" scope="col">Description</th>
                          <th scope="col">Publish Date</th>
                          <th scope="col">Publish Time</th>
                          <th scope="col">Status</th>
                          <th v-if="permissionIsAssigned('announcement-delete')" scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="announcement in authCompanyAllAnnouncements" :key="announcement.id">
                          <th v-if="permissionIsAssigned('announcement-delete')" scope="row"
                            style="padding-left: 2.5%;">
                            <label class="checkbox checkbox-secondary">
                              <input type="checkbox" :checked="ids.indexOf(announcement.id) != -1"
                                @change="ids.indexOf(announcement.id) == -1 ? ids.splice(ids.length - 1, 0, announcement.id) : ids.splice(ids.indexOf(announcement.id), 1)" />
                              <span class="checkmark"></span>
                            </label>
                          </th>
                          <td class="text-left">{{ announcement.title }}</td>
                          <td class="text-left">
                            {{ announcement.description }}
                          </td>
                          <td>{{ announcement.publish_date }}</td>
                          <td>{{ announcement.publish_time }}</td>
                          <td>
                            <span v-if="announcement.status == 1" class="badge badge-round-success sm"
                              style="width: 10px; height: 10px;">
                              <span></span>
                            </span>
                            <span v-else class="badge badge-round-danger sm" style="width: 10px; height: 10px;">
                              <span></span>
                            </span>
                          </td>
                          <td v-if="permissionIsAssigned('announcement-delete')">
                            <div class="row">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <i v-bind="attrs" v-on="on" class="col-4 offset-4 py-0 text-center fas fa-trash"
                                    @click="delete_announcement2(announcement.id)"
                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                </template>
                                <span>Delete announcement</span>
                              </v-tooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
          <v-row justify="center">
            <v-dialog v-model="create_announcement_dialog" max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{
                      "Create Announcement"
                  }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form @submit.prevent>
                      <div class="container-fluid">
                        <v-row>
                          <v-col cols="12" class="remove-hint">
                            <v-text-field v-model="formData.title" label="Announcement Title" solo dense></v-text-field>
                          </v-col>
                          <v-col cols="12" class="remove-hint">
                            <v-textarea v-model="formData.description" label="Announcement Description" solo>
                            </v-textarea>
                          </v-col>
                          <v-col cols="12" class="remove-hint">
                            <v-radio-group label="Recipients" v-model="formData.mode" inline>
                              <v-radio label="All Staff" value="3"></v-radio>
                              <v-radio label="Department" value="1"></v-radio>
                              <v-radio label="Branch" value="2"></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col v-if="formData.mode == 1" cols="12" class="remove-hint">
                            <v-select v-model="formData.department" :items="allDepartmentNames" label="Selected Departments" solo dense></v-select>
                          </v-col>
                          <v-col v-if="formData.mode == 2" cols="12" class="remove-hint">
                            <v-select v-model="formData.branch" :items="allBranchNames" label="Selected Branches" solo dense></v-select>
                          </v-col>
                          <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                            <button @click="create_announcement_submit ? null : create_announcement()"
                              style="width: 99%;" type="button"
                              class="btn btn-secondary m-1 text-white btn-raised ripple">
                              {{ create_announcement_text }}
                              <v-icon :style="
                                !create_announcement_submit
                                  ? 'display: none;'
                                  : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                              ">
                                fas fa-circle-notch fa-spin
                              </v-icon>
                            </button>
                          </v-col>
                        </v-row>
                      </div>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="create_announcement_dialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/main";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      refreshing_page: false,
      create_announcement_dialog: false,
      formData: {
        title: "",
        description: "",
        branch: "",
        department: "",
        mode: "3"
      },
      create_announcement_text: "Submit",
      create_announcement_submit: false,
      ids: [],
    };
  },
  watch: {},
  computed: {
    ...mapGetters([
      "validEmployeeId",
      "userType",
      "authCompanyData",
      "authCompanyAllAnnouncements",
      "authCompanyDepartments",
      "authCompanyBranches"
    ]),
    ids_to_submit() {
      let _this = this;
      return _this.ids.map(id => {
        return {
          id
        }
      })
    },
    allDepartmentNames() {
      const _this = this;
      let names = [];
      names = _this.authCompanyDepartments.map(department => department.department_name);
      return names;
    },
    selectedDepartmentId() {
      const _this = this;
      let foundDepartment = [], id = null;
      foundDepartment = _this.authCompanyDepartments.filter(department => department.department_name == _this.formData.department);
      if (foundDepartment.length > 0) {
        id = foundDepartment[0].id;
      }
      return id;
    },
    allBranchNames() {
      const _this = this;
      let names = [];
      names = _this.authCompanyBranches.map(branch => branch.branch_name);
      return names;
    },
    selectedBranchId() {
      const _this = this;
      let foundBranch = [], id = null;
      foundBranch = _this.authCompanyBranches.filter(branch => branch.branch_name == _this.formData.branch);
      if (foundBranch.length > 0) {
        id = foundBranch[0].id;
      }
      return id;
    }
  },
  methods: {
    ...mapActions([
      "fetch_all_company_announcements",
      "fetch_all_company_employee_announcements",
      "fetch_company_departments",
      "fetch_company_branches"
    ]),
    hasHistory: function () {
      return window.history.length > 2;
    },
    check_all() {
      let _this = this;

      if (_this.ids.length != _this.authCompanyAllAnnouncements.length) {
        _this.ids = []
        _this.authCompanyAllAnnouncements.forEach(announcement => {
          _this.ids.splice(_this.ids.length - 1, 0, announcement.id)
        })
      } else {
        _this.authCompanyAllAnnouncements.forEach(announcement => {
          _this.ids.splice(_this.ids.indexOf(announcement.id), 1)
        })
      }
    },
    refresh_page() {
      this.refreshing_page = true;
      this.fetch_all_company_announcements();
      this.refreshing_page = false;
    },
    launch_dialog() {
      this.formData.title = "";
      this.formData.description = "";
      this.create_announcement_dialog = true;
    },
    delete_announcement() {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.$swal
        .fire({
          title: _this.ids.length == 1 ? "Are you sure you want to delete this announcement?" : "Are you sure you want to delete these announcements?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deletemultipleannouncement`, {
                ids: _this.ids_to_submit
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: _this.ids.length == 1 ? "Announcement deleted successfully" : "Announcements deleted successfully"
                });
                _this.ids = [];
              })
              .catch(function (error) {
                Toast.fire({
                  icon: "error",
                  title: _this.ids.length == 1 ? "Announcement couldn't be deleted" : "Announcements couldn't be deleted"
                });
                console.log(error);
              })
              .then(function () {
                _this.fetch_all_company_announcements();
                _this.fetch_all_company_employee_announcements();
              });
          }
        });
    },
    delete_announcement2(id) {
      let _this = this;

      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      _this.$swal
        .fire({
          title: "Are you sure you want to delete this announcement?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deletemultipleannouncement`, {
                ids: [
                  {
                    id
                  }
                ]
              })
              .then(function () {
                Toast.fire({
                  icon: "success",
                  title: "Announcement deleted successfully"
                });
              })
              .catch(function () {
                Toast.fire({
                  icon: "error",
                  title: "Announcement couldn't be deleted"
                });
              })
              .then(function () {
                _this.fetch_all_company_announcements();
                _this.fetch_all_company_employee_announcements();
              });
          }
        });
    },
    create_announcement() {
      let _this = this;
      _this.create_announcement_text = "";
      _this.create_announcement_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        }
      });
      let d = new Date();
      axios
        .post(`${BASE_URL}createannouncement`, {
          company_id: _this.authCompanyData.id,
          title: _this.formData.title,
          description: _this.formData.description,
          publish_date: d.toISOString().substring(0, 10),
          publish_time: d.toTimeString().substring(0, 8),
          branch_id: _this.selectedBranchId,
          department_id: _this.selectedDepartmentId,
          mode: _this.formData.mode
        })
        .then(function () {
          Toast.fire({
            icon: "success",
            title: "Accouncement created successfully"
          });
          _this.formData.title = "";
          _this.formData.description = "";
          _this.fetch_all_company_announcements();
          _this.fetch_all_company_employee_announcements();
          _this.create_announcement_dialog = false;
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: "Announcement couldn't be created"
          });
          console.log(error);
        })
        .then(function () {
          _this.create_announcement_submit = false;
          _this.create_announcement_text = "Submit";
        });
    }
  },
  mounted() {
    document.title = "Kylian ERP - Announcements";
    this.fetch_all_company_announcements();
    this.fetch_all_company_employee_announcements();
    this.fetch_company_departments();
    this.fetch_company_branches();
  }
};
</script>

<style scoped>
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.lead {
  font-size: 130%;
}

th,
td {
  text-align: center;
}
</style>

<style>
.mdi-calendar-month-outline {
  margin-right: 10px;
  color: #069 !important;
}

.remove-mb div .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
