<template>
    <div class="tab-pane fade" id="list-settings-branches" role="tabpanel" aria-labelledby="list-branches-list">
        <div v-if="create_branch_form">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Create Branch
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4 text-right">
                                <button @click="create_branch_form = false" style="float: right;" type="button" class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Branch Name <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="branch_form.name" placeholder="Enter the branch name" solo class="mt-2" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" offset-md="3" class="remove-hint">
                                    <button
                                        @click="
                                        create_branch_submit
                                            ? null
                                            : create_branch()
                                        "
                                        style="width: 99%;"
                                        type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple"
                                    >
                                        {{ create_branch_text }}
                                        <v-icon
                                            :style="
                                            !create_branch_submit
                                            ? 'display: none;'
                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                        "
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <div v-else>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Branches Management
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button
                                    @click="create_branch_form = true"
                                    style="float: right;"
                                    type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                >
                                    Create Branch
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">
                                Branch Name
                            </th>
                            <th
                                scope="col"
                                style="width: 10px;"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="branch in authCompanyBranches" :key="branch.id">
                            <td class="text-left">
                                {{ branch.branch_name }}
                            </td>
                            <td
                                style="padding-top: 13px;"
                                class="employee-options"
                            >
                                <div class="row">
                                    <i
                                        @click="launch_edit_branch(branch)"
                                        class="col-3 py-4 text-center fal fa-edit mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                    <i
                                        @click="delete_branch(branch)"
                                        class="col-3 py-4 text-center fal fa-trash ml-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                    ></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <v-dialog v-model="branch_edit_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Branch</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="remove-hint">
                                        <v-text-field dense v-model="edit_branch_form.name" placeholder="Branch Name" solo class="mt-2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" class="remove-hint">
                                        <button @click="edit_branch_submit ? null : update_branch()" style="width: 100%;" type="button" class="btn btn-secondary m-1 ripple">
                                            {{ edit_branch_text }}
                                            <v-icon
                                                :style="
                                                !edit_branch_submit
                                                ? 'display: none;'
                                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                            "
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="branch_edit_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            create_branch_form: false,
            branch_form: {
                name: "",
            },
            edit_branch_form: {
                id: null,
                name: "",
            },
            create_branch_text: "Submit",
            create_branch_submit: false,
            edit_branch_text: "Update",
            edit_branch_submit: false,
            branch_edit_dialog: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'validEmployeeId',
            'authCompanyBranches'
        ])
    },
    methods: {
        ...mapActions([
            'fetch_company_branches'
        ]),
        launch_edit_branch(branch) {
            this.edit_branch_form.id = branch.id;
            this.edit_branch_form.name = branch.branch_name;
            // CONTINUE
            this.branch_edit_dialog = true;
            // CONTINUE
        },
        delete_branch(branch) {
            const _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal.fire({
                title: "Are you sure you want to delete this branch [" + branch.branch_name + "]?",
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    axios.post(`${BASE_URL}deletebranch`, {
                        ids: [
                            {
                                id: branch.id,
                            },
                        ],
                    })
                    .then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "Branch [" + branch.branch_name + "] deleted successfully",
                        });
                    })
                    .catch(function (error) {
                        console.log(error)
                        Toast.fire({
                            icon: "error",
                            title: "Branch [" + branch.branch_name + "] couldn't be deleted",
                        });
                    })
                    .then(function () {
                        _this.fetch_company_branches();
                    });
                }
            });
        },
        create_branch() {
            const _this = this;
            _this.create_branch_text = "";
            _this.create_branch_submit = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios.post(`${BASE_URL}createbranch`, {
                company_id: _this.authCompanyData.id,
                branch_name: _this.branch_form.name,
                employee_id: _this.validEmployeeId,
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: _this.branch_form.name + " was added successfully",
                });
                _this.branch_form.name = "";
                _this.fetch_company_branches();
                _this.create_branch_form = false;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.name
                            ? errors.name[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The branch could not be added",
                });
                _this.fetch_company_branches();
            })
            .then(function () {
                _this.create_branch_submit = false;
                _this.create_branch_text = "Submit";
            });
        },
        update_branch() {
            const _this = this;
            _this.edit_branch_text = "";
            _this.edit_branch_submit = true;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            axios.post(`${BASE_URL}updatebranch`, {
                company_id: _this.authCompanyData.id,
                branch_id: _this.edit_branch_form.id,
                branch_name: _this.edit_branch_form.name,
                employee_id: _this.validEmployeeId
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: "The branch has been updated successfully",
                });
                _this.edit_branch_form.id = null;
                _this.edit_branch_form.name = "";
                _this.fetch_company_branches();
                _this.branch_edit_dialog = false;
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors
                        ? typeof errors === "string"
                            ? errors
                            : null || errors.name
                            ? errors.name[0]
                            : null
                        : null || error.response.data.error || error.response.data.message || "The branch could not be added",
                });
            })
            .then(function () {
                _this.edit_branch_submit = false;
                _this.edit_branch_text = "Update";
            });
        }
    },
    mounted() {
        this.fetch_company_branches();
    }
}
</script>

<style>

</style>