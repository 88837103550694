<template>
    <div class="card-body row py-4">
        <div class="col-sm-6" style="align-self: center;">
            <h4>Your Skill Sets</h4>
        </div>
        <div class="col-sm-6">
            <div class="row">
                <div class="col-lg-3 offset-9 py-4">
                    <button
                        @click="launchCreateSkillSetDialog()"
                        style="float: right;"
                        type="button"
                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                        :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                    >
                        Add Skill Set
                    </button>
                </div>
            </div>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Skill Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Skill Description
                                    </th>
                                    <th class="text-left" scope="col">
                                        Skill Level (%)
                                    </th>
                                    <th class="text-left" scope="col">
                                        Supporting Document
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                    <th class="text-left" scope="col">
                                        Approval
                                    </th>
                                    <th class='text-enter' scope="col">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr 
                                v-for='(skillSet, index) in authEmployeeSkillSets'
                                :key='index'
                                >
                                    <td class="text-left">{{ skillSet.skill_name }}</td>
                                    <td v-if="skillSet.description" class="text-left">{{ skillSet.description }}</td>
                                    <td v-else class="text-left"><i>No description</i></td>
                                    <td class="text-left">{{ skillSet.skill_level + '%' }}</td>
                                    <td v-if="skillSet.support_document" class="text-left">
                                        <a :href="assets_url + skillSet.support_document" target="_blank" rel="noopener noreferrer">
                                            View Support Document
                                        </a> 
                                        <i
                                        @click="launchUploadDocumentDialog(skillSet.id)"
                                        class="col-3 py-0 text-center fal fa-file-plus mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                        ></i>
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No Support Document </i> 
                                        <i
                                        @click="launchUploadDocumentDialog(skillSet.id)"
                                        class="col-3 py-0 text-center fal fa-file-plus mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                        ></i>
                                    </td>
                                    <td class="text-center">
                                        <span v-if="skillSet.approval_status == 1" class="badge badge-success text-white">APPROVED</span>
                                        <span v-else-if="skillSet.approval_status == 2" class="badge badge-danger text-white">DISAPPROVED</span>
                                        <span v-else class="badge badge-warning text-white">PENDING</span>
                                    </td>
                                    <td v-if="skillSet.approve_by" class="text-left">
                                        {{
                                            (
                                                skillSet.approval_status == 1
                                                ?
                                                'Approved by '
                                                :
                                                'Disapproved by '
                                            )
                                            +
                                            skillSet.approve_by
                                        }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No approval/disapproval</i>
                                    </td>
                                    <td class="text-center">
                                        <i
                                        @click="launchEditSkillSetDialog(skillSet)"
                                        class="col-3 py-0 text-center fal fa-edit mr-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                        ></i>
                                        <i
                                        @click="deleteSkillSet(skillSet)"
                                        class="col-3 py-0 text-center fal fa-trash ml-2"
                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                        ></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <!-- Dialog for creating a skill set -->
        <v-dialog v-model="skillSetFormData.createSkillSetDialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add Skill Set</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Skill Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="skillSetFormData.skillName" placeholder="Enter the skill name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Skill Level (%) <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="skillSetFormData.skillLevel" placeholder="Enter the skill level" solo type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Description </span>
                                        <v-textarea v-model="skillSetFormData.description" placeholder="Enter the skill description" solo></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="skillSetFormData.creatingSkillSet ? null : createSkillSet()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            <span v-if="skillSetFormData.creatingSkillSet === false">
                                                Submit
                                            </span>
                                            <v-icon
                                                v-if="skillSetFormData.creatingSkillSet"
                                                style="color: #fff; padding: 0px 10px; font-size: 16px;"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="skillSetFormData.createSkillSetDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for creating a skill set -->

        <!-- Dialog for creating a skill set -->
        <v-dialog v-model="skillSetFormData.editSkillSetDialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add Skill Set</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Skill Name <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="skillSetFormData.skillName" placeholder="Enter the skill name" solo></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <span style="color: #111d5e;">Skill Level (%) <i class="fal fa-asterisk" style="font-size: 8px; color: #ff0000; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field dense v-model="skillSetFormData.skillLevel" placeholder="Enter the skill level" solo type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" >
                                        <span style="color: #111d5e;">Description </span>
                                        <v-textarea v-model="skillSetFormData.description" placeholder="Enter the skill description" solo></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="skillSetFormData.updatingSkillSet ? null : updateSkillSet()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            <span v-if="skillSetFormData.updatingSkillSet === false">
                                                Update
                                            </span>
                                            <v-icon
                                                v-if="skillSetFormData.updatingSkillSet"
                                                style="color: #fff; padding: 0px 10px; font-size: 16px;"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="skillSetFormData.editSkillSetDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for creating a skill set -->

        <!-- Dialog for uploading a skill set document -->
        <v-dialog v-model="skillSetFormData.updloadDocumentDialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Upload Support Document</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col style="cursor: pointer;" cols="12" >
                                        <v-file-input prepend-icon="" prepend-inner-icon="mdi-attachment mdi-rotate-270"
                                            label="Support Document" solo style="text-indent: 5px;" color="#069"
                                            class="support-document" messages="Skill set support document">
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" offset-md="3" offset-sm="3" >
                                        <button
                                            @click="skillSetFormData.uploadingDocument ? null : uploadSupportDocument()"
                                            style="width: 100%;"
                                            type="button"
                                            class="btn btn-secondary m-1 ripple"
                                        >
                                            <span v-if="skillSetFormData.uploadingDocument === false">
                                                Upload
                                            </span>
                                            <v-icon
                                                v-if="skillSetFormData.uploadingDocument"
                                                style="color: #fff; padding: 0px 10px; font-size: 16px;"
                                            >
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="skillSetFormData.updloadDocumentDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for uploading a skill set document -->
    </div>
</template>

<script>
import axios from 'axios';
import { 
    mapActions, 
    mapGetters 
} from 'vuex';
import { 
    BASE_URL,
    ASSETS_URL
} from '@/main';

export default {
    props: {},
    data() {
        return {
            skillSetFormData: {
                id: null,
                skillLevel: '',
                skillName: '',
                description: '',
                createSkillSetDialog: false,
                creatingSkillSet: false,
                editSkillSetDialog: false,
                updatingSkillSet: false,
                updloadDocumentDialog: false,
                uploadingDocument: false
            }
        }
    },
    computed: {
        ...mapGetters([
            'authEmployeeSkillSets',
            'authCompanyData',
            'authEmployeeDataForCompany',
            'validEmployeeId'
        ]),
        assets_url() {
            return ASSETS_URL;
        }
    },
    methods: {
        ...mapActions([
            'fetch_employee_skill_sets'
        ]),
        launchCreateSkillSetDialog() {
            const _this = this;
            _this.skillSetFormData.id = '';
            _this.skillSetFormData.skillLevel = '';
            _this.skillSetFormData.skillName = '';
            _this.skillSetFormData.description = '';
            _this.skillSetFormData.createSkillSetDialog = true
        },
        launchEditSkillSetDialog(skillSet) {
            const _this = this;
            _this.skillSetFormData.id = skillSet.id;
            _this.skillSetFormData.skillLevel = skillSet.skill_level;
            _this.skillSetFormData.skillName = skillSet.skill_name;
            _this.skillSetFormData.description = skillSet.description;
            _this.skillSetFormData.editSkillSetDialog = true;
        },
        launchUploadDocumentDialog(id) {
            const _this = this;
            _this.skillSetFormData.id = id;
            _this.skillSetFormData.updloadDocumentDialog = true;
        },
        createSkillSet() {
            const _this = this, Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (_this.skillSetFormData.skillLevel < 0) {
                Toast.fire({
                    icon: "error",
                    title: `Skill level must be 0 or higher`,
                });
                return;
            }
            if (_this.skillSetFormData.skillLevel > 100) {
                Toast.fire({
                    icon: "error",
                    title: `Skill level must be 100 or less`,
                });
                return;
            }
            _this.skillSetFormData.creatingSkillSet = true;
            
            axios.post(`${BASE_URL}createskillset`, {
                company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id, 
                employee_id: _this.validEmployeeId,
                skill_level: _this.skillSetFormData.skillLevel, 
                skill_name: _this.skillSetFormData.skillName,
                description: _this.skillSetFormData.description
            })
            .then(function () {
                _this.skillSetFormData.createSkillSetDialog = false;
                _this.skillSetFormData.skillLevel = '';
                _this.skillSetFormData.skillName = '';
                _this.skillSetFormData.description = '';
                Toast.fire({
                    icon: "success",
                    title: `Skill set created successfully`,
                });
            })
            .catch(function () {
                Toast.fire({
                    icon: "error",
                    title: `Skill set couldn't be created`,
                });
            })
            .then(function () {
                _this.skillSetFormData.creatingSkillSet = false;
                _this.fetch_employee_skill_sets();
            });
        },
        updateSkillSet() {
            const _this = this, Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.skillSetFormData.editSkillSetDialog = true;
            
            axios.post(`${BASE_URL}editskillset`, {
                id: _this.skillSetFormData.id,
                company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id, 
                employee_id: _this.validEmployeeId,
                skill_level: _this.skillSetFormData.skillLevel, 
                skill_name: _this.skillSetFormData.skillName,
                description: _this.skillSetFormData.description
            })
            .then(function () {
                _this.skillSetFormData.editSkillSetDialog = false;
                _this.skillSetFormData.id = null;
                _this.skillSetFormData.skillLevel = '';
                _this.skillSetFormData.skillName = '';
                _this.skillSetFormData.description = '';
                Toast.fire({
                    icon: "success",
                    title: `Skill set updated successfully`,
                });
            })
            .catch(function () {
                Toast.fire({
                    icon: "error",
                    title: `Skill set couldn't be updated`,
                });
            })
            .then(function () {
                _this.skillSetFormData.updatingSkillSet = false;
                _this.fetch_employee_skill_sets();
            });
        },
        deleteSkillSet(id) {
            const _this = this, Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.$swal.fire({
                title: `Are you sure you want to delete this skill set?`,
                showCancelButton: true,
                confirmButtonText: `Yes, Delete`,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    axios.post(`${BASE_URL}deleteskillset`, {
                        ids: [
                            id
                        ]
                    }).then(function () {
                        Toast.fire({
                            icon: "success",
                            title: "The skill set was deleted successfully",
                        });
                    }).catch(function (error) {
                        let errors = error.response.data.error || null;
                        Toast.fire({
                            icon: "error",
                            title: errors
                                ? typeof errors === "string"
                                    ? errors
                                    : null
                                : null || error.response.data.error || error.response.data.message || "The skill set couldn't be deleted",
                        });
                    }).then(function () {
                        _this.fetch_employee_skill_sets();
                    });
                }
            });
        },
        uploadSupportDocument() {
            const _this = this, Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.skillSetFormData.uploadingDocument = true;
            
            const FORM_DATA = new FormData();
            FORM_DATA.append("id", _this.skillSetFormData.id);
            FORM_DATA.append("support_document", document.querySelector(".support-document div div div input").files[0]);
            
            axios.post(`${BASE_URL}uploadSkillsetdoc`, FORM_DATA, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(function () {
                _this.skillSetFormData.updloadDocumentDialog = false;
                _this.skillSetFormData.id = null;
                document.querySelector('[class="v-icon notranslate v-icon--link theme--light"]').click();
                Toast.fire({
                    icon: "success",
                    title: `Support document uploaded successfully`,
                });
            })
            .catch(function () {
                Toast.fire({
                    icon: "error",
                    title: `Support document couldn't be uploaded`,
                });
            })
            .then(function () {
                _this.skillSetFormData.uploadingDocument = false;
                _this.fetch_employee_skill_sets();
            });
        },
    }
}
</script>

<style>

</style>