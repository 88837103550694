<template>
  <div class="padTop15 container">
    <div>
      <h2>Welcome to Kylian ERP</h2>
      <p class="lead mb-0">
        Please take the following steps to setup your company account
      </p>
      <div class="row" style="padding: 0;">
        <div class="col-md-4 col-sm-12">
          <v-list disabled>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon class="mr-3" large v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="item-title-name"
                    style="color: rgba(0,0,0,.87);"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'" class="col-md-8 text-center">
          <img
            src="@/assets/graphics/welcome-to-barianERP.png"
            alt=""
            class="img-fluid"
          />
        </div>
        <div class="col-md-4 text-center mx-auto">
          <router-link :to="{ name: 'SetupCompany' }">
            <v-btn type="submit" x-large dark class="create-submit">
              {{ btnTxt }}
              <v-icon :style="!isLoading ? 'display: none' : ''"
                >fas fa-circle-notch fa-spin</v-icon
              >
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import homeServices from "@/assets/graphics/homeServices";
export default {
  data() {
    return {
      btnTxt: "Let's go",
      isLoading: false,
      items: [
        {
          text: "Select preferred features",
          icon: "mdi-numeric-1-circle-outline"
        },
        { text: "Select packages", icon: "mdi-numeric-2-circle-outline" },
        { text: "Make payment", icon: "mdi-numeric-3-circle-outline" }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
@media (max-width: 991px) {
  .create-submit {
    height: 45px !important;
  }
}
.item-title-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}
.create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;
  span {
    text-transform: initial !important;
  }
}
a {
  color: #069;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5314737558364868px;
  text-align: left;
}
a:hover {
  text-decoration: none;
}
.padTop15 {
  padding-top: 1.5%;
}
h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 57px;
  letter-spacing: -1.563158392906189px;
  text-align: left;
}
.lead {
  padding: 1% 0 0 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: left;
}
</style>
