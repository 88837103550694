<template>
    <div class="tab-pane fade" id="list-2-fa" role="tabpanel" aria-labelledby="list-2-fa-list">
        <div>
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Two-Factor Authentication Management
                    </div>
                    <div class="col-lg-6" style="align-self: center;">
                        <label class="switch switch-secondary mr-3">
                            <span v-if="two_fa_checked">Enabled</span>
                            <span v-else>Disabled</span>
                            <input @change="handleAction()" type="checkbox" :checked="two_fa_checked"
                                v-model="two_fa_checked">
                            <span class="slider"></span>
                        </label>
                    </div>
                </div>
            </h4>
            <v-overlay v-if="overlay" :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <div class="main-content mt-20 px-0">
                <div class="container-fluid">
                    <v-row>
                        <v-col v-if="two_fa_enabled" cols="12" class="text-center">
                            <i class="fas fa-lock mb-5" style="color: #069; font-size: 200px;"></i>
                            <p class="lead">
                                Two-Factor Authentication enabled
                            </p>
                        </v-col>
                        <v-col v-else cols="12" class="text-center">
                            <i class="fas fa-lock-open mb-5" style="color: #069; font-size: 200px;"></i>
                            <p class="lead">
                                Two-Factor Authentication disabled
                            </p>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <v-dialog v-model="set_two_fa_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Confirm your password</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" class="remove-hint">
                                            <span style="color: #111d5e;">Current password <i class="fal fa-asterisk"
                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                            <v-text-field v-model="password" solo dense
                                                :append-icon="showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
                                                :type="showPassword ? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword" class="remove-bottom-hint"
                                                placeholder="Enter your current password"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint mt-5">
                                    <button @click="
                                twofa_create_submit
                                    ? null
                                    : twofa_create()
                                " style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ twofa_create_text }}
                                        <v-icon
                                            :style="!twofa_create_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="set_two_fa_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="remove_two_fa_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Confirm password proceed</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" class="remove-hint">
                                            <span style="color: #111d5e;">Current password <i class="fal fa-asterisk"
                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                            <v-text-field v-model="password" solo dense
                                                :append-icon="showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
                                                :type="showPassword ? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword" class="remove-bottom-hint"
                                                placeholder="Enter your current password"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint mt-5">
                                    <button @click="
                                twofa_removal_submit
                                    ? null
                                    : twofa_removal()
                                " style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ twofa_removal_text }}
                                        <v-icon
                                            :style="!twofa_removal_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="remove_two_fa_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        overlay: Boolean
    },
    data() {
        return {
            showPassword: false,
            two_fa_checked: false,
            password: '',

            twofa_create_text: "Submit",
            twofa_create_submit: false,
            twofa_removal_text: "Submit",
            twofa_removal_submit: false,

            set_two_fa_dialog: false,
            remove_two_fa_dialog: false,
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'two_fa_enabled',
            'authUserData'
        ])
    },
    watch: {
        set_two_fa_dialog(newValue) {
            const _this = this;
            if (newValue == false) {
                _this.two_fa_checked = _this.two_fa_enabled;
            }
        },
        remove_two_fa_dialog(newValue) {
            const _this = this;
            if (newValue == false) {
                _this.two_fa_checked = _this.two_fa_enabled;
            }
        }
    },
    methods: {
        ...mapActions([
            'set_two_factor_auth'
        ]),
        handleAction() {
            const _this = this;
            _this.password = '';
            _this.showPassword = false;
            if (_this.two_fa_checked) {
                _this.set_two_fa_dialog = true;
            } else {
                _this.remove_two_fa_dialog = true;
            }
        },
        twofa_create() {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.twofa_create_text = "";
            _this.twofa_create_submit = true;
            axios.post(`${BASE_URL}settwofa`, {
                company_id: _this.authCompanyData.id,
                user_id: _this.authUserData.id,
                password: _this.password
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Two-Factor Authentication added successfully",
                    });
                    // SAVE TO STORE
                    _this.set_two_factor_auth(true);
                    _this.two_fa_checked = true;

                    _this.password = '';
                    _this.set_two_fa_dialog = false;
                })
                .catch(function (error) {
                    _this.two_fa_checked = false;
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.user_id
                                    ? errors.user_id[0]
                                    : null || errors.password
                                        ? errors.password[0]
                                        : null
                            : null || error.response.data.error || error.response.data.message || "Two-Factor Authentication couldn't be added",
                    });
                })
                .then(function () {
                    _this.twofa_create_submit = false;
                    _this.twofa_create_text = "Submit";
                });
        },
        twofa_removal() {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.twofa_removal_text = "";
            _this.twofa_removal_submit = true;
            axios.post(`${BASE_URL}removetwofa`, {
                user_id: _this.authUserData.id,
                password: _this.password
            })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Two-Factor Authentication removed successfully",
                    });
                    // SAVE TO STORE
                    _this.set_two_factor_auth(false);
                    _this.two_fa_checked = false;

                    _this.password = '';
                    _this.remove_two_fa_dialog = false;
                })
                .catch(function (error) {
                    _this.two_fa_checked = true;
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.user_id
                                    ? errors.user_id[0]
                                    : null || errors.password
                                        ? errors.password[0]
                                        : null
                            : null || error.response.data.error || error.response.data.message || "Two-Factor Authentication couldn't be removed",
                    });
                })
                .then(function () {
                    _this.twofa_removal_submit = false;
                    _this.twofa_removal_text = "Submit";
                });
        },
    },
    mounted() {
        this.two_fa_checked = this.two_fa_enabled;
    }
}
</script>

<style></style>