<template>
  <div class="container">
    <div v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1">
          <h2><i class="fad fa-users pr-2" style="font-size: 50%; position: relative; bottom: 15px;"></i>Onboarding</h2>
          <p class="lead pt-2 pb-0 mb-0">
              Introduce your newly hired employees into your organization 
              by helping them  understand their new position and job 
              requirements. This process helps them integrate seamlessly 
              with the rest of the company.
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1">
          <h2><i class="fad fa-clipboard-user pr-2" style="font-size: 50%; position: relative; bottom: 15px;"></i>Attendance</h2>
          <p class="lead pt-2 pb-0 mb-0">
              Attendance Management will help you  keep track of your employee hours. 
              It is the system you use to document the time your employees work and 
              the time they take off. This is done as they sign in when they come 
              into the office and sign out as they close for the day with a punch 
              of a button.
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1">
          <h2><i class="fad fa-island-tropical pr-2" style="font-size: 50%; position: relative; bottom: 15px;"></i>Leave Management</h2>
          <p class="lead pt-2 pb-0 mb-0">
              Keep track of the leave days taken by every employee. 
              Employees enter their requests and managers approve and 
              validate them, all in just a few clicks. The agenda of 
              each employee is updated accordingly.
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1">
          <h2><i class="fad fa-computer-speaker pr-2" style="font-size: 50%; position: relative; bottom: 15px;"></i>Assets Management</h2>
          <p class="lead pt-2 pb-0 mb-0">
              Keeps track of company’s assets with employees
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1">
          <h2><i class="fad fa-file-spreadsheet pr-2" style="font-size: 50%; position: relative; bottom: 15px;"></i>Timesheets Management</h2>
          <p class="lead pt-2 pb-0 mb-0">
              Choose to log your activities at the beginning of the day, 
              on the fly or after your work is done.
              Get an overview of your employees timesheet and 
              approve them and monitor employees productivity.
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1">
          <h2>Onboarding</h2>
          <p class="lead pt-2">
              Introduce your newly hired employees into your organization 
              by helping them  understand their new position and job 
              requirements. This process helps them integrate seamlessly 
              with the rest of the company.
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
        <div class="col-md-5 text-center">
          <i class="fal fa-users" style="font-size: 1500%; color: #111d5e;"></i>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1 text-center">
          <i class="fal fa-clipboard-user" style="font-size: 1500%; color: #111d5e;"></i>
        </div>
        <div class="col-md-5">
          <h2>Attendance</h2>
          <p class="lead pt-2">
              Attendance Management will help you  keep track of your employee hours. 
              It is the system you use to document the time your employees work and 
              the time they take off. This is done as they sign in when they come 
              into the office and sign out as they close for the day with a punch 
              of a button.
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1">
          <h2>Leave Management</h2>
          <p class="lead pt-2">
              Keep track of the leave days taken by every employee. 
              Employees enter their requests and managers approve and 
              validate them, all in just a few clicks. The agenda of 
              each employee is updated accordingly.
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
        <div class="col-md-5 text-center">
          <i class="fal fa-island-tropical" style="font-size: 1500%; color: #111d5e;"></i>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1 text-center">
          <i class="fal fa-computer-speaker" style="font-size: 1500%; color: #111d5e;"></i>
        </div>
        <div class="col-md-5">
          <h2>Assets Management</h2>
          <p class="lead pt-2">
              Keeps track of company’s assets with employees
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-5 offset-md-1">
          <h2>Timesheets Management</h2>
          <p class="lead pt-2">
              Choose to log your activities at the beginning of the day, 
              on the fly or after your work is done.
              Get an overview of your employees timesheet and 
              approve them and monitor employees productivity.
          </p>
          <!-- <router-link :to="{ name: service.link }">Learn more</router-link> -->
        </div>
        <div class="col-md-5 text-center">
          <i class="fal fa-file-spreadsheet" style="font-size: 1500%; color: #111d5e;"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Features",
};
</script>
<style lang="scss" scoped>
.padTop7 {
  padding-top: 3%;
}
h2 {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 57px;
  letter-spacing: -1.563158392906189px;
  text-align: left;
}
.lead {
  padding: 5% 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: justify;
}
</style>
