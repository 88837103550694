// src/plugins/vuetify.js

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {};

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#069',
        // secondary: '#b0bec5',
        accent: '#006699ad',
        // error: '#b71c1c',
      },
    },
  },
  opts,
  icons: {
    iconfont: "mdiSvg"
  }
});
