import { render, staticRenderFns } from "./SetupCompany.vue?vue&type=template&id=f60037ac&scoped=true&"
import script from "./SetupCompany.vue?vue&type=script&lang=js&"
export * from "./SetupCompany.vue?vue&type=script&lang=js&"
import style0 from "./SetupCompany.vue?vue&type=style&index=0&lang=css&"
import style1 from "./SetupCompany.vue?vue&type=style&index=1&id=f60037ac&lang=scss&scoped=true&"
import style2 from "./SetupCompany.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f60037ac",
  null
  
)

export default component.exports