<template>
    <div class="tab-pane fade" id="list-settings14" role="tabpanel" aria-labelledby="list-settings-list14">
        <div v-if="check_all_services('Attendance')">
            <h4>
                <div class="row">
                    <div class="col-lg-6" style="align-self: center;">
                        Attendance Deductions Management
                    </div>
                    <div v-if="!authCompanyAttendanceDeductionSettings" class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-3 offset-9 py-4">
                                <button v-if="create_deduction_form" @click="create_deduction_form = false"
                                    style="float: right;" type="button"
                                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple">
                                    Cancel
                                </button>
                                <button v-else @click="create_deduction_form = true" style="float: right;" type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                    Add Deductions
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </h4>
            <div v-if="create_deduction_form" class="main-content mt-0 px-0">
                <div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Deduction Type <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select v-model="deduction_creator.fixed_deduction" item-color="#069"
                                        :items="['Fixed', 'Flexible (%)']" placeholder="Select deduction type" solo
                                        class="mt-2" dense color="#069"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Lateness Penalty {{ deduction_creator.fixed_deduction ===
                                        'Fixed' ? '(₦)' : '(%)' }} <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="deduction_creator.lateness_penalty"
                                        placeholder="Enter the lateness penalty" solo class="mt-2" dense type="number"
                                        min="0"
                                        :max="deduction_creator.fixed_deduction === 'Fixed' ? undefined : 100"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="remove-hint">
                                    <span style="color: #111d5e;">Absence Penalty {{ deduction_creator.fixed_deduction ===
                                        'Fixed' ? '(₦)' : '(%)' }} <i class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-text-field v-model="deduction_creator.absence_penalty"
                                        placeholder="Enter the absence penalty" solo class="mt-2" dense type="number"
                                        min="0"
                                        :max="deduction_creator.fixed_deduction === 'Fixed' ? undefined : 100"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                                    <button @click="
                                        deduction_create_submit
                                            ? null
                                            : create_deduction_settings()
                                        " style="width: 99%;" type="button"
                                        class="btn btn-secondary m-1 text-white btn-raised ripple">
                                        {{ deduction_create_text }}
                                        <v-icon
                                            :style="!deduction_create_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
            </div>
            <div v-else class="main-content mt-0 px-0">
                <div v-if="authCompanyAttendanceDeductionSettings">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-left">Lateness Penalty</th>
                                    <th scope="col" class="text-left">Absence Penalty</th>
                                    <th scope="col" class="text-left">Deduction Type</th>
                                    <th scope="col" class="text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-if="authCompanyAttendanceDeductionSettings.fixed_deduction == 1"
                                        class="text-left">
                                        ₦{{ authCompanyAttendanceDeductionSettings.lateness_penalty | number_with_commas }}
                                    </td>
                                    <td v-else class="text-left">
                                        {{ authCompanyAttendanceDeductionSettings.lateness_penalty + '%' }}
                                    </td>
                                    <td v-if="authCompanyAttendanceDeductionSettings.fixed_deduction == 1"
                                        class="text-left">
                                        ₦{{ authCompanyAttendanceDeductionSettings.absence_penalty | number_with_commas }}
                                    </td>
                                    <td v-else class="text-left">
                                        {{ authCompanyAttendanceDeductionSettings.absence_penalty + '%' }}
                                    </td>
                                    <td class="text-left">
                                        {{ authCompanyAttendanceDeductionSettings.fixed_deduction ? 'Fixed' : 'Flexible' }}
                                    </td>
                                    <td style="padding-top: 13px;" class="text-left">
                                        <i @click="launch_edit_deduction_settings(authCompanyAttendanceDeductionSettings)"
                                            class="text-center fal fa-edit mr-2"
                                            style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                        <i @click="delete_deduction(authCompanyAttendanceDeductionSettings)"
                                            class="text-center fal fa-trash ml-2"
                                            style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div v-else class="text-center"> -->
                <!-- <h1>No deduction settings</h1>
                    <img src="/img/no-data-3.svg" class="mt-5 w-25" /> -->
                <div v-else class="container-fluid">
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <img src="/img/no-data-3.svg" class="mt-5 w-25" />
                            <p class="lead mt-5">
                                No deduction settings
                            </p>
                        </v-col>
                    </v-row>
                </div>
                <!-- </div> -->
            </div>
        </div>
        <v-dialog v-model="edit_deduction_settings_dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Holiday Details</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent>
                            <div class="container-fluid">
                                <v-row>
                                    <v-col cols="12" class="remove-hint">
                                        <span style="color: #111d5e;">Deduction Type <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-select v-model="deduction_updater.fixed_deduction" item-color="#069"
                                            :items="['Fixed', 'Flexible (%)']" placeholder="Select deduction type" solo
                                            class="mt-2" dense color="#069"></v-select>
                                    </v-col>
                                    <v-col cols="12" class="remove-hint">
                                        <span style="color: #111d5e;">Lateness Penalty {{ deduction_updater.fixed_deduction
                                            === 'Fixed' ? '(₦)' : '(%)' }} <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="deduction_updater.lateness_penalty"
                                            placeholder="Enter the lateness penalty" solo class="mt-2" dense type="number"
                                            min="0"
                                            :max="deduction_updater.fixed_deduction === 'Fixed' ? undefined : 100"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="remove-hint">
                                        <span style="color: #111d5e;">Absence Penalty {{ deduction_updater.fixed_deduction
                                            === 'Fixed' ? '(₦)' : '(%)' }} <i class="fal fa-asterisk"
                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                        <v-text-field v-model="deduction_updater.absence_penalty"
                                            placeholder="Enter the absence penalty" solo class="mt-2" dense type="number"
                                            min="0"
                                            :max="deduction_updater.fixed_deduction === 'Fixed' ? undefined : 100"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" offset-md="4" offset-sm="4" class="remove-hint">
                                        <button @click="deduction_update_submit ? null : update_deduction_settings()"
                                            style="width: 99%;" type="button"
                                            class="btn btn-secondary m-1 text-white btn-raised ripple">
                                            {{ deduction_update_text }}
                                            <v-icon
                                                :style="!deduction_update_submit ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'">
                                                fal fa-circle-notch fa-spin
                                            </v-icon>
                                        </button>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="edit_deduction_settings_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            create_deduction_form: false,
            deduction_creator: {
                lateness_penalty: 1,
                absence_penalty: 1,
                fixed_deduction: 'Fixed'
            },
            deduction_updater: {
                id: "",
                lateness_penalty: 1,
                absence_penalty: 1,
                fixed_deduction: 'Fixed'
            },
            deduction_update_text: "Update",
            deduction_update_submit: false,
            deduction_create_text: "Submit",
            deduction_create_submit: false,
            edit_deduction_settings_dialog: false
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authCompanyAttendanceDeductionSettings',
            'validEmployeeId'
        ]),
        creatingFixedDeduction() {
            return this.deduction_creator.fixed_deduction === 'Fixed';
        },
        updatingFixedDeduction() {
            return this.deduction_updater.fixed_deduction === 'Fixed';
        }
    },
    methods: {
        ...mapActions([
            'fetch_company_attendance_deduction_settings'
        ]),
        launch_edit_deduction_settings(deduction_settings) {
            this.deduction_updater.id = deduction_settings.id;
            this.deduction_updater.lateness_penalty = deduction_settings.lateness_penalty;
            this.deduction_updater.absence_penalty = deduction_settings.absence_penalty;
            this.deduction_updater.fixed_deduction = deduction_settings.fixed_deduction == 1 ? 'Fixed' : 'Flexible (%)';
            this.edit_deduction_settings_dialog = true;
        },
        create_deduction_settings() {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (_this.deduction_creator.fixed_deduction !== 'Fixed') {
                if (_this.deduction_creator.lateness_penalty >= 100) {
                    Toast.fire({
                        icon: "warning",
                        title: "Lateness penalty rate must be less than 100%",
                    });
                    return;
                }
                if (_this.deduction_creator.absence_penalty >= 100) {
                    Toast.fire({
                        icon: "warning",
                        title: "Absence penalty rate must be less than 100%",
                    });
                    return;
                }
            }
            _this.deduction_create_text = "";
            _this.deduction_create_submit = true;
            axios
                .post(`${BASE_URL}createattendancedeductionsettings`, {
                    company_id: _this.authCompanyData.id,
                    settings_id: _this.deduction_creator.id,
                    employee_id: _this.validEmployeeId,
                    lateness_penalty: _this.deduction_creator.lateness_penalty,
                    absence_penalty: _this.deduction_creator.absence_penalty,
                    fixed_deduction: Number(_this.deduction_creator.fixed_deduction === 'Fixed')
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Deduction settings added successfully",
                    });
                    _this.deduction_creator.lateness_penalty = 1;
                    _this.deduction_creator.absence_penalty = 1;
                    _this.deduction_creator.fixed_deduction = 'Fixed';
                    _this.fetch_company_attendance_deduction_settings();
                    _this.create_deduction_form = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.settings_id
                                    ? errors.settings_id[0]
                                    : null || errors.lateness_penalty
                                        ? errors.lateness_penalty[0]
                                        : null || errors.absence_penalty
                                            ? errors.absence_penalty[0]
                                            : null || errors.fixed_deduction
                                                ? errors.fixed_deduction[0]
                                                : null
                            : null || error.response.data.error || error.response.data.message || "The deduction settings couldn't be updated",
                    });
                })
                .then(function () {
                    _this.deduction_create_submit = false;
                    _this.deduction_create_text = "Submit";
                });
        },
        update_deduction_settings() {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            if (_this.deduction_updater.fixed_deduction !== 'Fixed') {
                if (_this.deduction_updater.lateness_penalty >= 100) {
                    Toast.fire({
                        icon: "warning",
                        title: "Lateness penalty rate must be less than 100%",
                    });
                    return;
                }
                if (_this.deduction_updater.absence_penalty >= 100) {
                    Toast.fire({
                        icon: "warning",
                        title: "Absence penalty rate must be less than 100%",
                    });
                    return;
                }
            }
            _this.deduction_update_text = "";
            _this.deduction_update_submit = true;
            axios
                .post(`${BASE_URL}updateattendancedeductionsettings`, {
                    settings_id: _this.deduction_updater.id,
                    employee_id: _this.validEmployeeId,
                    lateness_penalty: _this.deduction_updater.lateness_penalty,
                    absence_penalty: _this.deduction_updater.absence_penalty,
                    fixed_deduction: Number(_this.deduction_updater.fixed_deduction === 'Fixed')
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "The deduction settings have been updated successfully",
                    });
                    _this.deduction_updater.id = null;
                    _this.deduction_updater.lateness_penalty = 1;
                    _this.deduction_updater.absence_penalty = 1;
                    _this.deduction_updater.fixed_deduction = 'Fixed';
                    _this.fetch_company_attendance_deduction_settings();
                    _this.edit_deduction_settings_dialog = false;
                })
                .catch(function (error) {
                    let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: errors
                            ? typeof errors === "string"
                                ? errors
                                : null || errors.settings_id
                                    ? errors.settings_id[0]
                                    : null || errors.lateness_penalty
                                        ? errors.lateness_penalty[0]
                                        : null || errors.absence_penalty
                                            ? errors.absence_penalty[0]
                                            : null || errors.fixed_deduction
                                                ? errors.fixed_deduction[0]
                                                : null
                            : null || error.response.data.error || error.response.data.message || "The deduction settings couldn't be updated",
                    });
                })
                .then(function () {
                    _this.deduction_update_submit = false;
                    _this.deduction_update_text = "Update";
                });
        },
        delete_deduction(deduction) {
            let _this = this;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to remove this deduction settings?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Remove`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post(`${BASE_URL}deleteattendancedeductionsettings`, {
                                settings_id: deduction.id,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Deduction settings removed successfully",
                                });
                            })
                            .catch(function (error) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Deduction settings couldn't be removed",
                                });
                                console.log(error);
                            })
                            .then(function () {
                                _this.fetch_company_attendance_deduction_settings();
                            });
                    }
                });
        }
    }
}
</script>

<style></style>